<ng-container *ngIf="form" [formGroup]="form" >
  <div class="row">
    <div class="col-md-6 pl-0 pr-15">
      <div class="report-input-form-field">
        <p class="report-input-label report-input-radio-label">Units</p>
        <mat-radio-group formControlName="exteriorUnits">
          <mat-radio-button [value]="true" class="report-input-radio-button">One</mat-radio-button>
          <mat-radio-button [value]="false" class="report-input-radio-button"
            >One with Accessory Unit</mat-radio-button
          >
        </mat-radio-group>
      </div>

      <mat-form-field appearance="outline">
        <mat-label># of Stories</mat-label>
        <input
          matInput
          formControlName="exteriorNumberOfStories"
          id="exteriorNumberOfStories"
          autocomplete="off"
          mask="separator.0"
          thousandSeparator=""
          separatorLimit="9999"
          [allowNegativeNumbers]="false"
        />
      </mat-form-field>
      <jaro-kit-validation-message [form]="form" [controlName]="'exteriorNumberOfStories'" [isRequired]="true"></jaro-kit-validation-message>

      <div class="report-input-form-field">
        <p class="report-input-label report-input-radio-label">Type</p>
        <mat-radio-group formControlName="exteriorType" (change)="onChangeType($event)">
          <mat-radio-button
            *ngFor="let option of attachmentTypeOptions"
            [value]="option.value"
            class="report-input-radio-button"
            >{{ option.name }}</mat-radio-button
          >
        </mat-radio-group>
      </div>

      <div class="report-input-form-field">
        <p class="report-input-label report-input-radio-label">Stage of Construction</p>
        <mat-radio-group formControlName="exteriorStageOfConstruction">
          <mat-radio-button
            *ngFor="let option of stageOfConstructionOptions"
            [value]="option.value"
            class="report-input-radio-button"
          >
            {{ option.name }}
          </mat-radio-button>
        </mat-radio-group>
      </div>

      <mat-form-field appearance="outline">
        <mat-label class="report-input-label">Design (Style)</mat-label>
        <mat-select
          formControlName="exteriorDesignStyle"
          disableOptionCentering
          panelClass="report-input-drop-down"
          placeholder="Select from list"
          floatLabel="never"
        >
          <mat-option *ngFor="let item of designStyleOptions" [value]="item.value">{{
            item.name
          }}</mat-option>
        </mat-select>
      </mat-form-field>

      <mat-form-field appearance="outline">
        <mat-label>Effective Age (Yrs.)</mat-label>
        <input
          matInput
          formControlName="exteriorEffectiveAge"
          autocomplete="off"
          mask="0*-000"
          [allowNegativeNumbers]="false"
          [dropSpecialCharacters]="false"
          maxlength="5"
        />
      </mat-form-field>
    </div>

    <div class="col-md-6 pl-15 pr-0">
      <div class="pt-15 pb-15">
        <jaro-kit-material-combo-box-condition
          [formGroup]="form"
          title="Foundation Walls (Material)"
          condition="Foundation Walls (Condition)"
          [materialOptions]="foundationWallsOptions"
          [conditionOptions]="conditionOptions"
          [value]="foundationWalls"
          materialFormControlName="exteriorFoundationWalls"
          conditionFormControlName="exteriorFoundationWallsCondition"
        >
        </jaro-kit-material-combo-box-condition>
      </div>

      <div class="pt-15 pb-15">
        <jaro-kit-material-combo-box-condition
          [formGroup]="form"
          title="Exterior Walls (Material)"
          condition="Exterior Walls (Condition)"
          [materialOptions]="exteriorWallsOptions"
          [conditionOptions]="conditionOptions"
          [value]="exteriorWalls"
          materialFormControlName="exteriorWalls"
          conditionFormControlName="exteriorWallsCondition"
        >
        </jaro-kit-material-combo-box-condition>
      </div>

      <div class="pt-15 pb-15">
        <jaro-kit-material-combo-box-condition
          [formGroup]="form"
          title="Roof Surface (Material)"
          condition="Roof Surface (Condition)"
          [materialOptions]="roofSurfaceOptions"
          [conditionOptions]="conditionOptions"
          [value]="roofSurface"
          materialFormControlName="exteriorRoofSurface"
          conditionFormControlName="exteriorRoofSurfaceCondition"
        >
        </jaro-kit-material-combo-box-condition>
      </div>

      <div class="pt-15 pb-15">
        <jaro-kit-material-combo-box-condition
          [formGroup]="form"
          title="Gutters and Downspouts (Material)"
          condition="Gutters and Downspouts (Condition)"
          [materialOptions]="guttersAndDownspoutsOptions"
          [conditionOptions]="conditionOptions"
          [value]="guttersAndDownspouts"
          materialFormControlName="exteriorGuttersAndDownspouts"
          conditionFormControlName="exteriorGuttersAndDownspoutsCondition"
        >
        </jaro-kit-material-combo-box-condition>
      </div>

      <div class="pt-15 pb-15">
        <jaro-kit-material-combo-box-condition
          [formGroup]="form"
          title="Window Type (Material)"
          condition="Window Type (Condition)"
          [materialOptions]="windowTypeOptions"
          [conditionOptions]="conditionOptions"
          [value]="windowType"
          materialFormControlName="exteriorWindowType"
          conditionFormControlName="exteriorWindowTypeCondition"
        >
        </jaro-kit-material-combo-box-condition>
      </div>

      <div class="pt-15 pb-15">
        <jaro-kit-material-combo-box-condition
          [formGroup]="form"
          title="Storm Sash/Insulated (Material)"
          condition="Storm Sash/Insulated (Condition)"
          [materialOptions]="stormSashInsulatedOptions"
          [conditionOptions]="conditionOptions"
          [value]="stormSashInsulated"
          materialFormControlName="exteriorStormSashInsulated"
          conditionFormControlName="exteriorStormSashInsulatedCondition"
        >
        </jaro-kit-material-combo-box-condition>
      </div>

      <div class="pt-15 pb-15">
        <p class="report-input-title">Screens</p>

        <div class="pt-0 report-input-radio">
          <mat-radio-group
            formControlName="exteriorScreens"
            class="mt-15 mb-15"
            (change)="onChangeHasScreens($event)"
          >
            <mat-radio-button value="yes" class="report-input-radio-button">Yes</mat-radio-button>
            <mat-radio-button value="no" class="report-input-radio-button">No</mat-radio-button>
          </mat-radio-group>
        </div>

        <div class="pt-15" *ngIf="isShowScreensCondition">
          <p>Screens (Condition)</p>
          <mat-radio-group
            formControlName="exteriorScreensCondition"
            
          >
            <mat-radio-button
              *ngFor="let option of conditionOptions"
              [value]="option.value"
              class="report-input-radio-button"
              >{{ option.name }}</mat-radio-button
            >
          </mat-radio-group>
        </div>
      </div>
    </div>
  </div>
</ng-container>
