<ng-container *ngIf="form" [formGroup]="form">
  <div class="row certification-form">
    <div class="col-md-12 pl-0 pr-15">
      <div class="report-input-title">
        <p class="report-input-label">Subject Property</p>
      </div>
    </div>
    <div class="col-md-6 pl-0 pr-15">
      <div class="report-input-form-field">
        <p class="report-input-label report-input-radio-label">Property Inspected</p>
        <mat-radio-group
          formControlName="certificationPropertyInspected"
          class="mt-15 mb-15"
          (change)="onChangePropertyInspected($event)"
        >
          <mat-radio-button [value]="true" class="report-input-radio-button">Yes</mat-radio-button>
          <mat-radio-button [value]="false" class="report-input-radio-button">No</mat-radio-button>
        </mat-radio-group>
      </div>

      <div class="report-input-form-field" *ngIf="form.value.certificationPropertyInspected">
        <p class="report-input-label report-input-radio-label">Inspection Type</p>
        <mat-radio-group formControlName="certificationInspectionType" class="mt-15 mb-15">
          <mat-radio-button [value]="'Exterior Only'" class="report-input-radio-button"
            >Exterior Only</mat-radio-button
          >
          <mat-radio-button [value]="'Interior and Exterior'" class="report-input-radio-button"
            >Interior and Exterior</mat-radio-button
          >
        </mat-radio-group>
      </div>
    </div>

    <div class="col-md-6 pl-15 pr-0">
      <div class="col-md-12 pl-0" *ngIf="form.value.certificationPropertyInspected">
        <jaro-kit-date-picker
          [form]="form"
          dateControl="certificationInspectionDate"
          titleDate="Inspection Date"
        ></jaro-kit-date-picker>
      </div>
    </div>
  </div>

  <div class="row certification-form mt-15">
    <div class="col-md-12 pl-0 pr-15">
      <div class="report-input-title">
        <p class="report-input-label">Comparable Sales</p>
      </div>
    </div>
    <div class="col-md-6 pl-0 pr-15">
      <div class="report-input-form-field">
        <p class="report-input-label report-input-radio-label">Property Inspected</p>
        <mat-radio-group
          formControlName="certificationComparableSalesInspected"
          class="mt-15 mb-15"
          (change)="onChangeComparableSalesInspected($event)"
        >
          <mat-radio-button [value]="true" class="report-input-radio-button">Yes, exterior</mat-radio-button>
          <mat-radio-button [value]="false" class="report-input-radio-button">No</mat-radio-button>
        </mat-radio-group>
      </div>
    </div>

    <div class="col-md-6 pl-15 pr-0">
      <div class="col-md-12 pl-0" *ngIf="form.value.certificationComparableSalesInspected">
        <jaro-kit-date-picker
          [form]="form"
          dateControl="certificationComparableSalesInspectionDate"
          titleDate="Inspection Date"
        ></jaro-kit-date-picker>
      </div>
    </div>
  </div>
</ng-container>
