import { RestService } from '@abp/ng.core';
import { Injectable } from '@angular/core';
import type { CreateReportVersionDto } from '../../../../appraisal/report/reports/v1/models';
import type { PartialUpdateReportDto } from '../../../../appraisal/report/v1/models';
import type { ReportVersionWithLinksDto } from '../../reports/v1/models';

@Injectable({
  providedIn: 'root',
})
export class ReportService {
  apiName = 'Activity';

  appendVersion = (input: CreateReportVersionDto) =>
    this.restService.request<any, ReportVersionWithLinksDto>({
      method: 'POST',
      url: `/api/v1/activities/reports`,
      body: input,
    },
    { apiName: this.apiName });

  public getLatestReport = (orderAssignmentId: string) =>
    this.restService.request<any, ReportVersionWithLinksDto>({
      method: 'GET',
      url: `/api/v1/activities/reports/order-assignment/${orderAssignmentId}/latest`,
    },
    { apiName: this.apiName });


  public partialUpdateReport = (reportId: string, reportVersion: number, partialUpdateRequest: PartialUpdateReportDto) =>
    this.restService.request<any, ReportVersionWithLinksDto>({
      method: 'PATCH',
      url: `/api/v1/activities/reports/report/${reportId}/report-version/${reportVersion}`,
      body: partialUpdateRequest,
    },
    { apiName: this.apiName });

  constructor(private restService: RestService) {}
}
