import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { GeoLocation } from '@proxy/property/property-job/property-jobs';
import { PropertyRecordDataDto } from '@proxy/property/property-record/property-records';
import { CleanedListingDto, PropertyRecordDto } from '@proxy/property/property-record/property-records/v1';
import { AdjustableAttributeModel } from '../../models/property-attribute/adjustable-attribute-model';
import { AttributeStyleModel } from '../../models/property-attribute/attribute-style-model';
import { AttributeValueType } from '../../models/property-attribute/attribute-value-type';
import { CleanedListingModel } from '../../models/property-attribute/cleaned-listing-model';
import { CommentaryAttributeModel } from '../../models/property-attribute/commentary-attribute-model';
import { AttributeConfig, AttributeRecordFactory } from '../market-cleanup-property-attribute-config';

@Component({
  selector: 'jaro-kit-market-cleanup-property-attribute-table',
  templateUrl: './market-cleanup-property-attribute-table.component.html',
  styleUrls: ['./market-cleanup-property-attribute-table.component.scss'],
})
export class MarketCleanupPropertyAttributeTableComponent implements OnInit {
  @Input() property: PropertyRecordDto;
  attributeRecord: Record<keyof CleanedListingDto, AttributeConfig> = AttributeRecordFactory.getConfig();
  @Output() onChangeAttributeValue = new EventEmitter<AdjustableAttributeModel>();

  editableAttributes: AdjustableAttributeModel[];
  readonly nameof = <T>(name: Extract<keyof T, string>): string => name;

  ngOnInit(): void {
    const listing = this.property.cleanedListing;
    this.editableAttributes = Object.entries(this.attributeRecord)
                            .filter(attr => attr[1].isAdjustableValue && attr[1].isEditable)
                            .map<AdjustableAttributeModel>(attr => {
                              return this.mapListingToAdjustableAttributeModel(listing, attr[0] as keyof CleanedListingDto, attr[1].title, attr[1].isEditable,attr[1].isMultiSelect,attr[1].isDropdown, attr[1].isDate, attr[1].key,attr[1].isSingleField, attr[1].validationPatterns, attr[1].validationErrorMessage, attr[1].toolTip, attr[1].valueTypeName, attr[1].formatFunc);
                            });   
  }

  changeAttributeValue(attribute: AdjustableAttributeModel) {
    this.onChangeAttributeValue.emit(attribute);
  }

  protected mapListingToCommentaryAttributeModel(listing: CleanedListingDto, name: keyof CleanedListingDto, title: string, valueTypeName: AttributeValueType, formatFunc: (...args: any[]) => string = null): CommentaryAttributeModel {
    const data = formatFunc ? formatFunc(listing[name]) : this.convertObjectToAttributeValue(listing[name], valueTypeName);
    const model = new CommentaryAttributeModel(this.nameof<CleanedListingDto>(name), title || name.toString(), data);
    return model;
  }

  protected mapListingToAdjustableAttributeModel(listing: CleanedListingDto, name: keyof CleanedListingDto, title: string, isEditable: boolean, isMultiSelect:boolean,isDropdown: boolean, isDate:boolean, key: string,isSingleField:boolean | null, validationPatterns : RegExp[], validationErrorMessage : string, toolTip: string, valueTypeName: "string" | "string[]" | "integer" | "float" | "boolean" | "GeoLocation", formatFunc: (...args: any[]) => string = null): AdjustableAttributeModel {
    const data = listing[name] as PropertyRecordDataDto;

    const attributeStyle = {
      title: title || name.toString(),
      isDate: isDate,
      isEditable: isEditable,
      isDropdown: isDropdown,
      isSingleField: isSingleField,
      validationErrorMessage : validationErrorMessage,
      validationPatterns: validationPatterns,
      toolTip: toolTip,
      isMultiSelect:isMultiSelect
    } as AttributeStyleModel;

    const transformFunc = isDate ? (date: string) => this.transformToValidDateFormat(date) : (str: string) => str;

      if (["quality", "condition"].indexOf(this.nameof<CleanedListingDto>(name)) != -1) {
          valueTypeName = "float";
      }

      const cleanedListing = {
      mls: transformFunc(this.convertObjectToAttributeValue(data?.mls ?? '', valueTypeName)),
      pubrec: transformFunc(this.convertObjectToAttributeValue(data?.pubrec ?? '', valueTypeName)),
      custom: transformFunc(this.convertObjectToAttributeValue(data?.custom ?? '', valueTypeName)),
      selectedDataSource: data?.selected ?? 'custom'
    } as CleanedListingModel;

    return new AdjustableAttributeModel(this.nameof<CleanedListingDto>(name), key, cleanedListing, attributeStyle, valueTypeName, formatFunc);
  }

  protected convertObjectToAttributeValue(obj: Object, valueTypeName: AttributeValueType): string {
    switch(valueTypeName) {
      case "string":
        return (typeof obj === "string") ? obj : "";
      case "string[]":
        return this.isStringArray(obj) ?  JSON.stringify(obj) : null;
      case "float":
      case "integer":
        return (this.isNumber(obj) ? Number(obj) : "").toString();
      case "boolean":
        return (this.isNumber(obj) ? Number(obj) : "").toString();
      case "GeoLocation":
        return JSON.stringify(obj as GeoLocation || {} as Partial<GeoLocation>);
    }
  }

  protected transformToValidDateFormat(value:string): string{
    if(value  && value.length >= 6)
    {
      const year=value.substring(0,4);
      const month=value.substring(4,6);
      const formattedDate= `${month}/${year.substring(2,4)}`;
      return formattedDate;

    }
  }

  protected isStringArray(value?: Object): boolean {
    if (Array.isArray(value)) {
      if (value.length < 1) {
        return false;
      }
      value.forEach(function(item){
         if(typeof item !== 'string'){
          return false;
         }
      });
      return true;
   }
  }

  protected isNumber(value?: Object): boolean
  {
    return ((value != null) &&
            (value !== '') &&
            !isNaN(Number(value.toString())));
  }
}
