<ng-container *ngIf="form" [formGroup]="form">
  <div class="row">
    <div class="col-md-6 pl-0 pr-15">
      <div class="report-input-form-field">
        <p class="report-input-label report-input-radio-label">
          Is the developer/builder in control of the Homeowners' Association (HOA)
        </p>

        <mat-radio-group
          id="PUDIsDeveloperInControlOfHoa"
          formControlName="PUDIsDeveloperInControlOfHoa"
          class="mt-15"
          (change)="onChangePUDIsDeveloperInControlOfHoa($event)"
        >
          <mat-radio-button [value]="true" class="report-input-radio-button">Yes</mat-radio-button>
          <mat-radio-button [value]="false" class="report-input-radio-button">No</mat-radio-button>
        </mat-radio-group>
        <jaro-kit-validation-message [form]="form" [controlName]="'PUDIsDeveloperInControlOfHoa'" [isRequired]="true"></jaro-kit-validation-message>
      </div>
      <div *ngIf="isShowPUDInformationTotal">
        <div class="report-input-form-field">
          <p class="report-input-label mat-subheading-2">
            Provide the following information for PUDs ONLY if the developer/builder is in control
            of the HOA and the subject property is an attached dwelling unit
          </p>
        </div>

        <mat-form-field appearance="outline">
          <mat-label>Legal name of project</mat-label>
          <input matInput formControlName="PUDProjectName" autocomplete="off" />
        </mat-form-field>

        <div class="row">
          <div class="col-md-12 col-lg-6 pl-0 pr-15">
            <jaro-kit-pud-information-total
              [form]="form"
              [isEditMode]="true"
            ></jaro-kit-pud-information-total>
          </div>

          <div class="col-md-12 col-lg-6 pl-0 pr-0 data-source mt-15">
            <mat-form-field appearance="outline" class="jaro-kit-textarea">
              <mat-label>Data source(s)</mat-label>
              <textarea
                type="text"
                formControlName="PUDProjectDataSource"
                placeholder="Data source(s)"
                matInput
                rows="4"
              ></textarea>
            </mat-form-field>
            <jaro-kit-validation-message [form]="form" [controlName]="'PUDProjectDataSource'" [isRequired]="true"></jaro-kit-validation-message>
          </div>
        </div>

        <div class="report-input-form-field mt-15">
          <p class="report-input-label report-input-radio-label">
            Was the project created by the conversion of an existing building(s) into a PUD?
          </p>

          <mat-radio-group
            formControlName="PUDIsCreatedByBuildingConversion"
            class="mt-15"
            (change)="onChangePUDIsCreatedByBuildingConversion($event)"
          >
            <mat-radio-button [value]="true" class="report-input-radio-button"
              >Yes</mat-radio-button
            >
            <mat-radio-button [value]="false" class="report-input-radio-button"
              >No</mat-radio-button
            >
          </mat-radio-group>
        </div>

        <mat-form-field *ngIf="form.value['PUDIsCreatedByBuildingConversion']" appearance="outline">
          <mat-label>If Yes, date of conversion</mat-label>
          <input
            matInput
            formControlName="PUDBuildingConversionDate"
            autocomplete="off"
            (change)="onChangeBuildingConversionDate($event)"
            placeholder="MM/DD/YYYY or MM/YYYY or YYYY"
          />
        </mat-form-field>

        <div
          *ngIf="hasError('PUDBuildingConversionDate', 'inValidDate')"
          class="row col-12 validation-error"
        >
          Please enter a valid date (MM/DD/YYYY or MM/YYYY or YYYY)
        </div>

        <div class="report-input-form-field">
          <p class="report-input-label report-input-radio-label">
            Does the project contain any multi-dwelling units?
          </p>

          <mat-radio-group formControlName="PUDIsAnyMultipleDwellingUnits" class="mt-15">
            <mat-radio-button [value]="true" class="report-input-radio-button"
              >Yes</mat-radio-button
            >
            <mat-radio-button [value]="false" class="report-input-radio-button"
              >No</mat-radio-button
            >
          </mat-radio-group>
        </div>

        <mat-form-field appearance="outline">
          <mat-label>Data source(s)</mat-label>
          <input matInput formControlName="PUDMultipleDwellingUnitsDataSource" autocomplete="off" />
        </mat-form-field>
      </div>
    </div>

    <div class="col-md-6 pl-15 pr-0" *ngIf="isShowPUDInformationTotal">
      <div class="report-input-form-field">
        <p class="report-input-label report-input-radio-label">
          Are the units, common elements, and recreation facilities complete?
        </p>

        <mat-radio-group
          formControlName="PUDAreUnitsCommonElementsAndRecreationFacilitiesCompleted"
          class="mt-15"
          (change)="onChangePUDAreUnitsCommonElementsAndRecreationFacilitiesCompleted($event)"
        >
          <mat-radio-button [value]="true" class="report-input-radio-button">Yes</mat-radio-button>
          <mat-radio-button [value]="false" class="report-input-radio-button">No</mat-radio-button>
        </mat-radio-group>
      </div>

      <mat-form-field
        *ngIf="form.value['PUDAreUnitsCommonElementsAndRecreationFacilitiesCompleted'] === false"
        appearance="outline"
        class="jaro-kit-textarea"
      >
        <mat-label>If No, describe the status of completion</mat-label>
        <textarea
          type="text"
          formControlName="PUDAreUnitsCommonElementsAndRecreationFacilitiesCompletedComment"
          placeholder="If No, describe the status of completion"
          matInput
          rows="4"
        ></textarea>
      </mat-form-field>

      <div class="report-input-form-field">
        <p class="report-input-label report-input-radio-label">
          Are the common elements leased to or by the Homeowners' Association?
        </p>

        <mat-radio-group
          formControlName="PUDIsAnyCommonElementsLeasedByOrToHoa"
          class="mt-15"
          (change)="onChangePUDIsAnyCommonElementsLeasedByOrToHoa($event)"
        >
          <mat-radio-button [value]="true" class="report-input-radio-button">Yes</mat-radio-button>
          <mat-radio-button [value]="false" class="report-input-radio-button">No</mat-radio-button>
        </mat-radio-group>
      </div>

      <mat-form-field *ngIf="form.value['PUDIsAnyCommonElementsLeasedByOrToHoa']" appearance="outline"  class="jaro-kit-textarea">
        <mat-label>If Yes, describe the rental terms and options</mat-label>
        <textarea
          type="text"
          formControlName="PUDIsAnyCommonElementsLeasedByOrToHoaComment"
          placeholder="If Yes, describe the rental terms and options"
          matInput
          rows="4"
        ></textarea>
      </mat-form-field>

      <div class="mt-15 mb-15">
        <halo-global-multiple-combo-box
          label="Describe common elements and recreational facilities"
          placeholder="Add new items ..."
          [options]="options"
          [formGroup]="form"
          [isAllowAddNewItem]="false"
          formControlName="PUDCommonElementsAndRecreationalFacilities"
        >
        </halo-global-multiple-combo-box>
      </div>
    </div>
  </div>
</ng-container>
