import { Component, Input } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { ReportWithExtraAttributes } from 'src/app/features/report/shared/models/report-input-extra-atributes.model';

@Component({
  selector: 'jaro-kit-inspection-details-fha-hud-92051',
  templateUrl: './inspection-details.component.html',
  styleUrls: ['./inspection-details.component.scss'],
})
export class InspectionDetailsFHAHUD92051Component {
  @Input() form: FormGroup;
  @Input() reportDto: ReportWithExtraAttributes;
}
