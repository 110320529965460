<div class="report-section">
  <span class="report-input-headline mat-headline">{{ title }}</span>
  <span *ngIf="boostEnabled && errorSection?.isError" class="error-title mat-headline"> <i class="fa-light fa-circle-exclamation"></i> {{ errorSection.errorMessage }}</span>
  <div *ngIf="!isCustom">
    <button
      (click)="toggleMode()"
      class="jaro-kit-primary-button btn-edit-section"
      *ngIf="!isEditMode && hasViewMode"
      [disabled]="isDisabled"
    >
      <em class="fas fa-edit" aria-hidden="true"></em>
    </button>

    <button
      (click)="saveButtonClick()"
      class="jaro-kit-primary-button btn-save-section"
      *ngIf="isEditMode"
      [disabled]="isInvalidData"
    >
      Save
    </button>

    <button
      (click)="toggleMode()"
      class="jaro-kit-primary-button btn-cancel-section"
      *ngIf="isEditMode"
    >
      Cancel
    </button>
  </div>
</div>
