import { CdkDragDrop } from '@angular/cdk/drag-drop';
import { Component, EventEmitter, Input, Output } from '@angular/core';
import { ReportDocumentPositionType } from '@proxy/bff/activity/report-documents';
import { ReportDocumentPositionDto, ReportDocumentWithFileDto } from '@proxy/bff/activity/report-documents/v1';
import { PartialUpdatePdfDocumentModel } from 'src/app/features/shared/forms/models/report-input-document/partial-update-pdf-document.model';
import { FileUploading } from 'src/app/shared/services/file-uploading';

@Component({
  selector: 'jaro-kit-document-items',
  templateUrl: './document-items.component.html',
  styleUrls: ['./document-items.component.scss'],
})
export class DocumentItemsComponent {
  @Input() documentsUploading: FileUploading[] = [];
  @Input() documents: PartialUpdatePdfDocumentModel[] = [];
  @Input() orderAssignmentId: string;

  @Output() onRemoveDocument = new EventEmitter<ReportDocumentWithFileDto>();
  @Output() onAddDocument = new EventEmitter<string>();
  @Output() onReselectedPages = new EventEmitter<PartialUpdatePdfDocumentModel>();

  removeDocument(document: ReportDocumentWithFileDto) {
    this.onRemoveDocument.emit(document);
  }

  onDrop(event: CdkDragDrop<ReportDocumentWithFileDto>) {
    const document = event.item.data;
    this.onAddDocument.emit(document.documentId);
  }

  reselectedPages(document: PartialUpdatePdfDocumentModel) {
    const index = this.documents.findIndex((item) => item.id === document.id);
    const position = {
      positionType: ReportDocumentPositionType.Last,
    } as ReportDocumentPositionDto;
    if (index === 0) {
      position.positionType = ReportDocumentPositionType.First;
    } else if (index < this.documents.length - 2) {
      position.positionType = ReportDocumentPositionType.BetweenDocuments;
      position.previousReportDocumentId = this.documents[index - 1].id;
      position.nextReportDocumentId = this.documents[index + 1].id;
    } else {
      // Last is set as the default value in that case.
    }
    document.position = position;
    this.onReselectedPages.emit(document);
  }
}
