import { NgbDateStruct } from '@ng-bootstrap/ng-bootstrap';
import * as moment from 'moment-timezone';
import { ClockServiceInterface } from '../../../interface/shared/utils/clock-service-interface';
import { TimeZoneNamesMapping } from './timezone-names-mapping';

export class DateUtils {
  public static uTCToTimeZoneDate(utcDate: string, timeZoneId: string, format?: string): Date {
    let defaultFormat = 'yyyy-MM-DDTHH:mm:ss';
    if (format) {
      defaultFormat = format;
    }
    // Do not using .toDate() it will apply local timezone to the result
    var timeZoneDateString = moment.utc(utcDate).tz(timeZoneId).format(defaultFormat);
    return new Date(timeZoneDateString);
  }

  public static timeZoneDateToUtc(localDate: string, timeZoneId: string, format?: string): Date {
    let defaultFormat = 'yyyy-MM-DDTHH:mm:ss';
    if (format) {
      defaultFormat = format;
    }
    // Do not using .toDate() it will apply local timezone to the result
    const timeZoneDateString = moment.tz(localDate, timeZoneId).utc().format(defaultFormat);
    return new Date(timeZoneDateString);
  }

  public static getTimeZoneAbbreviationFullString(dateTime: Date, timeZoneId: string): string {
    DateUtils.replaceZoneNameFunction();
    const tzLongName = moment.tz(dateTime, timeZoneId).format('zz');
    const tzShortName = moment.tz(dateTime, timeZoneId).format('z');
    return tzLongName + ' (' + tzShortName + ')';
  }

  public static getTimeZoneAbbreviationShortName(
    dateTime: string | Date,
    timeZoneId: string
  ): string {
    DateUtils.replaceZoneNameFunction();
    return moment.tz(dateTime, timeZoneId).format('z');
  }

  public static getTimeZoneAbbreviation(dateTime: Date, timeZoneId: string): string {
    DateUtils.replaceZoneNameFunction();
    return moment.tz(dateTime, timeZoneId).format('z');
  }

  public static replaceZoneNameFunction(): void {
    moment.fn.zoneName = function () {
      var abbr = this.zoneAbbr();
      return TimeZoneNamesMapping[abbr] || abbr;
    };
  }

  public static getDateTimeWithTimeZoneIdByDateAndTime(
    date: string,
    time: string,
    format: string,
    timeZoneId: string,
    clockService: ClockServiceInterface
  ): Date {
    if (date && time) {
      const dateData = moment(date, format);
      var dateOrigin = new Date(
        dateData.year(),
        dateData.month(),
        dateData.date(),
        Number(time.split(':')[0]),
        Number(time.split(':')[1])
      );
      return DateUtils.getDateTimeWithTimeZoneIdByDateTime(dateOrigin, timeZoneId, clockService);
    }

    return DateUtils.getDateTimeWithTimeZoneIdByDateTime(null, timeZoneId, clockService);
  }

  public static getDateTimeWithTimeZoneIdByDateTime(
    dateTime: Date,
    timeZoneId: string,
    clockService: ClockServiceInterface
  ): Date {
    const localNow = clockService.now();
    const diffMinutes = moment.tz(timeZoneId).utcOffset();
    const diffMinutesLocal = moment(localNow).utcOffset();
    const diffTotal = Math.abs(diffMinutes - diffMinutesLocal);
    const sign = diffMinutesLocal > diffMinutes ? 1 : -1;

    if (dateTime) {
      return moment(dateTime)
        .add(diffTotal * sign, 'm')
        .toDate();
    }

    return moment(localNow).toDate();
  }

  public static getDateTimeWithTimeZoneIdFromUTC(
    dateTimeUtc: string,
    timeZoneId: string
  ): Date {
    return DateUtils.uTCToTimeZoneDate(dateTimeUtc, timeZoneId);
  }

  public static uTCToTimeZoneString(dateTime: Date, timeZoneId: string): string {
    return moment(dateTime).tz(timeZoneId, true).format();
  }

  public static getTimeFromDate(dateTime: Date, format?: string): string {
    let defaultFormat = 'HH:mm';
    if (format) {
      defaultFormat = format;
    }
    return moment(dateTime).format(defaultFormat);
  }

  public static parseStringToDate(value: string, delimiter: string): NgbDateStruct | null {
    if (value) {
      let date = value.split(delimiter);
      return {
        month: parseInt(date[0], 10),
        day: parseInt(date[1], 10),
        year: parseInt(date[2], 10),
      };
    }
    return null;
  }
}
