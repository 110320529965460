
export enum ReportSource {
  All = '',
  Unknown = 0,
  Inspection = 1,
  MLS = 2,
  User = 3,
  Preload = 4,
  Desk = 5,
  JaroAi = 6
 
}
