import { Confirmation } from '@abp/ng.theme.shared';
import { Component, Inject, Input, OnInit } from '@angular/core';
import { ReportVersionDto } from '@proxy/appraisal/report/reports/v1';
import { MarketConditionDto } from '@proxy/appraisal/report/reports/v1/form1004';
import { PartialUpdateReportDto } from '@proxy/appraisal/report/v1';
import { ReportVersionWithLinksDto } from '@proxy/bff/activity/reports/v1';
import { cloneDeep, get, set } from 'lodash-es';
import { ReportsServiceInterface } from 'src/app/interface/bff/activity/reports-service-interface';
import InjectionSymbol from 'src/app/shared/injection/injection-symbol';
import { MessageService } from 'src/app/shared/services/message.service';

@Component({
  selector: 'jaro-kit-market-conditions',
  templateUrl: './market-conditions.component.html',
  styleUrls: ['./market-conditions.component.scss'],
})
export class MarketConditionsComponent implements OnInit {
  @Input() reportVersionDto: ReportVersionWithLinksDto;
  isEditMode: boolean;
  isLoading: boolean;
  isValidData: boolean = true;
  marketCondition: MarketConditionDto;
  constructor(
    @Inject(InjectionSymbol.ActivityReportsService)
    public activityReportsService: ReportsServiceInterface,
    private msgService: MessageService
  ) {}

  ngOnInit(): void {
    this.marketCondition = get(this.reportVersionDto, 'report.property.marketCondition') || {};

    if (!this.marketCondition.medianSalesAnalysis) {
      this.marketCondition.medianSalesAnalysis = {
        medianComparableSalePrice: {},
        medianComparableSaleDaysOnMarket: {},
        medianComparableListPrice: {},
        medianComparableListingDaysOnMarket: {},
        medianSalePriceAsPercentOfListPrice: {},
      };
    }

    if (!this.marketCondition.inventoryAnalysis) {
      this.marketCondition.inventoryAnalysis = {
        totalNumberOfComparableSales: {},
        absorptionRate: {},
        totalNumberOfComparableActiveListings: {},
        monthsOfHousingSupply: {},
      };
    }
  }

  toggleMode() {
    this.marketCondition = cloneDeep(
      get(this.reportVersionDto, 'report.property.marketCondition') || {}
    );
    this.isEditMode = !this.isEditMode;
  }

  saveSection() {
    this.saveMarketConditions(
      this.activityReportsService.getUpdateMarketConditionModel(this.marketCondition)
    );
  }

  private saveMarketConditions(updates: Record<string, any>) {
    this.isLoading = true;
    const updateReportVersionRequest = {
      orderAssignmentId: this.reportVersionDto.orderAssignmentId,
      updates: updates,
    } as PartialUpdateReportDto;
    this.activityReportsService
      .partialUpdate(
        this.reportVersionDto.reportId,
        this.reportVersionDto.version,
        updateReportVersionRequest
      )
      .subscribe({
        next: (_response: ReportVersionDto) => {
          this.isEditMode = !this.isEditMode;
          this.isLoading = false;
          set(this.reportVersionDto, 'report.property.marketCondition', this.marketCondition);
        },
        error: (err) => this.logActionError(err),
      });
  }

  private logActionError(err: any): void {
    this.isLoading = false;
    this.msgService.error(err).subscribe((_status: Confirmation.Status) => {});
  }
}
