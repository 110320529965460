<div class="visual-media-content">
  <div class="image-carousel-panel">
    <div class="image-carousel-container">
      <div class="image-carousel">
        <ngx-gallery
          [options]="galleryOptions"
          [images]="galleryImages"
          class="ngx-gallery"
          (change)="changeSelectPhoto()"
        ></ngx-gallery>
      </div>
    </div>
    <div class="open-image-carousel-modal-button" (click)="openImageCarouselModal()">
      <em class="open-image-carousel-modal-icon fa fa-expand fa-2x"></em>
    </div>
  </div>
  <div class="map-panel">
    <agm-map
      class="map"
      [latitude]="geoLocation.latitude"
      [longitude]="geoLocation.longitude"
      [scrollwheel]="true"
      [fullscreenControl]="true"
      [mapTypeControl]="true"
      [streetViewControl]="true"
      [zoom]="17"
    >
      <agm-marker
        class="map-marker"
        [latitude]="geoLocation.latitude"
        [longitude]="geoLocation.longitude"
        [iconUrl]="markerSymbol"
        [zIndex]="9999"
      >
        <agm-info-window [disableAutoPan]="true">
          <ul class="map-info-attribute-list">
            <li>
              <span class="map-info-attribute-address">{{ address }}</span>
            </li>
            <li>
              <span class="map-info-attribute-name">latitude</span
              ><span class="map-info-attribute-value">{{ geoLocation.latitude }} </span>
            </li>
            <li>
              <span class="map-info-attribute-name">longitude</span
              ><span class="map-info-attribute-value">{{ geoLocation.longitude }}</span>
            </li>
          </ul>
        </agm-info-window>
      </agm-marker>
    </agm-map>
  </div>
</div>
