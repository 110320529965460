import { Component, Input } from '@angular/core';
import { PreviewDocumentComponent } from '../preview-document/preview-document.component';

@Component({
  selector: 'jaro-kit-preview-extract-document',
  templateUrl: './preview-extract-document.component.html',
  styleUrls: ['./preview-extract-document.component.scss'],
})
export class PreviewExtractDocumentComponent extends PreviewDocumentComponent {
  @Input() heightOfPdfViewer: string = "80vh";    

  get pageSelected(): number {
    const pagination = window.JaroKit.getPdfViewerPagination(this.paginationAttributeSuffix);
    return pagination.pageSelected || 1;
  }

  selectAll(isSelectAll: boolean) {
    const radioButtons = document.getElementsByClassName('thumbnail-radio-button');
    if (radioButtons) {
      const thumbnailRadioButtons = Array.from(radioButtons).filter(
        (item) => item.id != 'thumbnailRadioButtonPAGE_NUMBER'
      );
      const pagination = window.JaroKit.getPdfViewerPagination(this.paginationAttributeSuffix);
      pagination.pages = [];
      for (let i = 1; i <= thumbnailRadioButtons.length; i++) {
        const radioButton = document.getElementById(`thumbnailRadioButton${i}`) as HTMLInputElement;

        if (radioButton) {
          radioButton.checked = isSelectAll;
          if (isSelectAll) {
            pagination.pages.push(i);
          }
        }
      }
    }
  }
}
