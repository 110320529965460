<ng-container *ngIf="form" [formGroup]="form">
  <div class="row report-input-sub-section">
    <div class="col-md-6 pl-0 pr-15">
      <div class="report-input-form-field">
        <p class="report-input-label report-input-radio-label">Legally Permissible</p>
        <mat-radio-group formControlName="siteLegallyPermissible" class="mt-15">
          <mat-radio-button [value]="true" class="report-input-radio-button">Yes</mat-radio-button>
          <mat-radio-button [value]="false" class="report-input-radio-button">No</mat-radio-button>
        </mat-radio-group>
      </div>

      <div class="report-input-form-field">
        <p class="report-input-label report-input-radio-label">Physically Possible</p>
        <mat-radio-group formControlName="sitePhysicallyPossible" class="mt-15">
          <mat-radio-button [value]="true" class="report-input-radio-button">Yes</mat-radio-button>
          <mat-radio-button [value]="false" class="report-input-radio-button">No</mat-radio-button>
        </mat-radio-group>
      </div>

      <div class="report-input-form-field">
        <p class="report-input-label report-input-radio-label">Financially Feasible</p>
        <mat-radio-group formControlName="siteFinanciallyFeasible" class="mt-15">
          <mat-radio-button [value]="true" class="report-input-radio-button">Yes</mat-radio-button>
          <mat-radio-button [value]="false" class="report-input-radio-button">No</mat-radio-button>
        </mat-radio-group>
      </div>
    </div>

    <div class="col-md-6 pl-15 pr-0">
      <div class="report-input-form-field">
        <p class="report-input-label report-input-radio-label">Maximally Productive</p>
        <mat-radio-group formControlName="siteMaximallyProductive" class="mt-15">
          <mat-radio-button [value]="true" class="report-input-radio-button">Yes</mat-radio-button>
          <mat-radio-button [value]="false" class="report-input-radio-button">No</mat-radio-button>
        </mat-radio-group>
      </div>

      <mat-form-field appearance="outline" class="jaro-kit-textarea">
        <mat-label>Highest and Best Use Description</mat-label>
        <textarea
          type="text"
          formControlName="siteHighestAndBestUseDescription"
          placeholder="Highest and Best Use Description"
          matInput
          rows="4"
        ></textarea>
      </mat-form-field>
    </div>
  </div>
</ng-container>
