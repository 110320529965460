<div class="sale-history">
  <div class="grid-page-container">
    <div class="grid-description-row" *ngIf="saleHistory.length > 0">
      <ngx-datatable
        class="material"
        [rows]="saleHistory"
        [columnMode]="ColumnMode.force"
        [headerHeight]="50"
        [footerHeight]="50"
        rowHeight="50px"
        [limit]="10"
        [scrollbarH]="true"
      >
        <ngx-datatable-column [name]="'Date'" [prop]="'transactionContractDate'" [sortable]="true" [minWidth]="120">
          <ng-template let-row="row" ngx-datatable-cell-template>
            <div>{{ row.transactionContractDate | dateTimeFormat | defaultValue }}</div>
          </ng-template>
        </ngx-datatable-column>

        <ngx-datatable-column [name]="'Price'" [prop]="'closePrice'" [sortable]="true">
          <ng-template let-row="row" ngx-datatable-cell-template>
            <div>{{ row.closePrice | currency: 'USD':'symbol':'1.0-0' | defaultValue }}</div>
          </ng-template>
        </ngx-datatable-column>

        <ngx-datatable-column [name]="'Source'" [prop]="'source'" [sortable]="true">
          <ng-template let-row="row" ngx-datatable-cell-template>
            <div>{{ row.source | defaultValue }}</div>
          </ng-template>
        </ngx-datatable-column>

        <ngx-datatable-column
          [name]="'Document Number'"
          [prop]="'transactionRecordingDocumentId'"
          [sortable]="true"
          [minWidth]="120"
        >
          <ng-template let-row="row" ngx-datatable-cell-template>
            <div>{{ row.transactionRecordingDocumentId | defaultValue }}</div>
          </ng-template>
        </ngx-datatable-column>

        <ngx-datatable-column
          [name]="'Effective Date Of Source'"
          [prop]="'transactionRecordingDate'"
          [sortable]="true"
          [minWidth]="175"
        >
          <ng-template let-row="row" ngx-datatable-cell-template>
            <div>{{ row.transactionRecordingDate | dateTimeFormat | defaultValue }}</div>
          </ng-template>
        </ngx-datatable-column>

        <ngx-datatable-column
          [name]="'Seller 1'"
          [prop]="'seller1FullName'"
          [sortable]="true"
          [minWidth]="150"
        >
          <ng-template let-row="row" ngx-datatable-cell-template>
            <div>{{ row.seller1FullName | defaultValue }}</div>
          </ng-template>
        </ngx-datatable-column>

        <ngx-datatable-column
          [name]="'Seller 2'"
          [prop]="'seller2FullName'"
          [sortable]="true"
          [minWidth]="150"
        >
          <ng-template let-row="row" ngx-datatable-cell-template>
            <div>{{ row.seller2FullName | defaultValue }}</div>
          </ng-template>
        </ngx-datatable-column>

        <ngx-datatable-column
          [name]="'Buyer 1'"
          [prop]="'buyer1FullName'"
          [sortable]="true"
          [minWidth]="150"
        >
          <ng-template let-row="row" ngx-datatable-cell-template>
            <div>{{ row.buyer1FullName | defaultValue }}</div>
          </ng-template>
        </ngx-datatable-column>

        <ngx-datatable-column
          [name]="'Buyer 2'"
          [prop]="'buyer2FullName'"
          [sortable]="true"
          [minWidth]="150"
        >
          <ng-template let-row="row" ngx-datatable-cell-template>
            <div>{{ row.buyer2FullName | defaultValue }}</div>
          </ng-template>
        </ngx-datatable-column>

        <ngx-datatable-column
          [name]="'Document Type'"
          [prop]="'transactionDocumentType'"
          [sortable]="true"
        >
          <ng-template let-row="row" ngx-datatable-cell-template>
            <div>{{ row.transactionDocumentType | defaultValue }}</div>
          </ng-template>
        </ngx-datatable-column>
      </ngx-datatable>
    </div>

    <div class="empty-panel" *ngIf="!saleHistory || saleHistory.length == 0">
      <em class="fas fa-arrow-right" aria-hidden="true"></em>
      <div class="detail">
        <span class="title">No Sales History</span>
        <span class="description">No sales or transfer history in the last 12 months</span>
      </div>
    </div>
  </div>
</div>
