import { Component, Input, OnInit } from '@angular/core';
import {
  PropertyDetailsDto
} from '@proxy/property/property-job/property-jobs/v1';

@Component({
  selector: 'jaro-kit-raw-data',
  templateUrl: './raw-data.component.html',
  styleUrls: ['./raw-data.component.scss'],
})
export class RawDataComponent implements OnInit {
  @Input() propertyDetails: PropertyDetailsDto;
  @Input() isSubject: boolean;
  private workingArray: JsonDisplayObject[];

  outputArrayPubRec: JsonDisplayObject[];
  outputArrayMls: JsonDisplayObject[];
  outputArrayMaps: JsonDisplayObject[];
  outputArrayZonedata: JsonDisplayObject[];
  outputArrayTransferHistories: JsonDisplayObject[];

  ngOnInit(): void {
    this.workingArray = [];
    this.outputArrayPubRec = [];
    this.outputArrayMls = [];
    this.outputArrayMaps = [];
    this.outputArrayZonedata = [];
    this.outputArrayTransferHistories = [];

    this.buildOutputList();
  }

  // Sorts the grouped array to place child nodes under parent items
  // From:
  // 0. Base Node
  //  1. Child of Base Node 1
  //  1. Child of Base Node 2
  //  2. Child of Child 1
  //  2. Child of Child 2
  // To:
  // 0. Base Node
  //  1. Child of Base Node 1
  //    2. Child of Child 1
  //  1. Child of Base Node 2
  //    2. Child of Child 2

  recursiveGetNodesChildren(
    groupedItems: JsonDisplayObject[],
    baseGroupedItems: JsonDisplayObject[]
  ) {
    let resultArray: JsonDisplayObject[] = [];
    if (!groupedItems) return resultArray;

    for (const key in groupedItems) {
      let node = groupedItems[key];
      if (!node) {
        return resultArray;
      }
      resultArray.push(node);

      let parentPrefix = node.Parent == node.Key ? node.Parent : node.Parent + ',' + node.Key;
      let children = baseGroupedItems.filter(
        (e) => e.Level == node.Level + 1 && e.Parent.startsWith(parentPrefix)
      );
      resultArray = resultArray.concat(this.recursiveGetNodesChildren(children, baseGroupedItems));
    }

    // Last step of the process, no more array modification after the keys change.
    this.displayFormatNodeKeys(resultArray);
    return resultArray;
  }

  // Changes keys on the display objects to be user friendly readable keys.
  displayFormatNodeKeys(resultArray: JsonDisplayObject[]) {
    for (const key in resultArray) {
      let node = resultArray[key];
      // Reformat header items
      if (node.Key == 'pubRec') node.Key = 'Public Record';
      if (node.Key == 'mls') node.Key = 'MLS';
      if (node.Key == 'zoneomics') node.Key = 'Zone Data';
      // Format all key values to be human readable insted of camel case
      const regex = /(?<=[a-z])(?=[A-Z])|(?<=[A-Z])(?=[A-Z][a-z])/;
      node.Key = node.Key.split(regex).join(' ');
      // Capitalize first letter of all keys.
      node.Key = node.Key.charAt(0).toUpperCase() + node.Key.slice(1);
      // Array Items should display as Item: number
      if (node.ArrayChild && node.Key.indexOf('[Item') == -1)
        node.Key = '[Item : ' + node.Key + ']';
    }
  }

  // Convert the PropertyJobResponseDto into flattened ordered arrays of output data.
  public buildOutputList() {
    if (this.propertyDetails?.mls?.historyDetails) this.propertyDetails.mls.historyDetails = [];
    this.recursiveFlattenData(this.propertyDetails, 0, '');
    this.sortArrayByParentStructure();
    this.outputArrayMls = this.flattenedGroupToResults('mls');
    this.outputArrayMaps = this.flattenedGroupToResults('maps');
    this.outputArrayZonedata = this.flattenedGroupToResults('zoneomics');
    this.outputArrayTransferHistories = this.flattenedGroupToResults('transferHistories');
    this.outputArrayPubRec = this.flattenedGroupToResults('pubRec');
  }

  // Recursively process the object and its properties to build an array of display elements.
  recursiveFlattenData(obj: any, iterator: number, parentKey: string, arrayChild: boolean = false) {
    iterator++;
    for (const key in obj) {
      let output = new JsonDisplayObject();
      output.Level = iterator;
      output.Parent = parentKey;
      output.ParentLevel = iterator - 1;
      if (parentKey == '') output.Parent = key;

      if (obj.hasOwnProperty(key)) {
        const value = obj[key];
        if (this.keyDenied(key)) continue;
        if (value === null || value === undefined || value === 'undefined') {
          output.Key = key;
          output.Value = '';
        } else if ((typeof value === 'object' && value) || this.isArrayObject(value)) {
          if (arrayChild) {
            output.ArrayChild = true;
          }
          output.Key = key;
          if (parentKey) {
            this.recursiveFlattenData(
              value,
              iterator,
              parentKey + ',' + key,
              this.isArrayObject(value)
            );
          } else {
            this.recursiveFlattenData(value, iterator, key, this.isArrayObject(value));
          }
        } else if (!this.isObject(value)) {
          output.Key = key;
          output.Value = value;
        }
      }
      this.workingArray.push(output);
    }
  }

  keyDenied(inKey) {
    let allowed = true;
    let denyList = ['pmxPropertyId'];
    allowed = denyList.indexOf(inKey) > -1;
    if (allowed) allowed = inKey.startsWith('pmx');
    return allowed;
  }

  // Sort the flattened array based on the parent key, this groups all node collections
  sortArrayByParentStructure() {
    if (!this.workingArray) return;
    this.workingArray.sort(function (a, b) {
      let keyA = a.Parent.toLowerCase(),
        keyB = b.Parent.toLowerCase();
      if (keyA < keyB) return -1;
      if (keyA > keyB) return 1;
      return 0;
    });
  }

  sortArrayByLevelStructure(inList: JsonDisplayObject[]) {
    if (!this.workingArray) return;

    return inList.sort(function (a, b) {
      return a.Level - b.Level;
    });
  }

  // Filter the flattened list by a particular parent and sort the result array by the node levels.
  flattenedGroupToResults(parentKey: string) {
    let groupedItems: JsonDisplayObject[] = this.sortArrayByLevelStructure(
      this.workingArray.filter((e) => e.Parent.startsWith(parentKey))
    );
    return this.recursiveGetNodesChildren(
      groupedItems.filter((e) => e.Level == 1),
      groupedItems
    );
  }

  isObject(val: any): boolean {
    return typeof val === 'object' && !Array.isArray(val) && val !== null;
  }

  isArrayObject(val: any): boolean {
    return typeof val === 'object' && Array.isArray(val) && val !== null;
  }
}

export class JsonDisplayObject {
  Parent?: string;
  Level: number;
  ParentLevel?: number;
  ParentId: string;
  Key?: string;
  Value?: string;
  ArrayChild?: boolean;
}
