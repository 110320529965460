<tr class="property-attribute-row">
  <td>
    <div class="property-attribute-column-title">
      <span class="title">{{ attribute.title }}</span>
    </div>
  </td>
  <td
    [ngClass]="{
      'property-attribute-column': true,
      'property-attribute-column-selected': attribute.isMlsSelected,
      'property-attribute-column-disabled': !attribute.isEditable
    }"
  >
    <label
      *ngIf="attribute.mls"
      class="selectable-chip"
      for="mls-{{ attribute.id }} "
      (click)="onChange('mls')"
    >
      <span class="selectable-value">{{ attribute.formatFunc(attribute.mls) }}</span>
    </label>
  </td>
  <td
    [ngClass]="{
      'property-attribute-column': true,
      'property-attribute-column-selected': attribute.isPubRecSelected,
      'property-attribute-column-disabled': !attribute.isEditable
    }"
  >
    <label
      *ngIf="attribute.pubrec"
      class="selectable-chip"
      for="pubrec-{{ attribute.id }}"
      (click)="onChange('pubrec')"
    >
      <span class="selectable-value">{{ attribute.formatFunc(attribute.pubrec) }}</span>
    </label>
  </td>
  <td
    [ngClass]="{
      'property-attribute-column': true,
      'property-attribute-column-selected': attribute.isJaroAiSelected,
      'property-attribute-column-disabled': !attribute.isEditable
    }"
  >
    <label
      *ngIf="attribute.jaroAi"
      class="selectable-chip"
      for="pubrec-{{ attribute.id }}"
      (click)="onChange('jaroAi')"
    >
      <span class="selectable-value">{{ attribute.formatFunc(attribute.jaroAi) }}</span>
    </label>
  </td>
  <td
    [ngClass]="{
      'property-attribute-column': true,
      'custom-property-attribute-column': true,
      'property-attribute-column-selected': attribute.isCustomSelected,
      'property-attribute-column-disabled': !attribute.isEditable
    }"
    (click)="onChange('custom')"
  >
    <div class="custom-value-panel" *ngIf="attribute.isEditable">
      <input
        [ngClass]="[attribute.hasErrors ? 'error' : '']"
        *ngIf="attribute.isEditable && !attribute.isDropdown"
        type="text"
        class="editable-value custom-value"
        [(ngModel)]="attribute.custom"
        value="{{ attribute.custom }}"
        (change)="onChangeCustomValue()"
        title="{{ attribute.toolTip }}"
      />

      <select
        *ngIf="
          attribute.isEditable &&
          attribute.isDropdown &&
          !attribute.isSingleField &&
          !attribute.isMultiSelect
        "
        class="editable-value custom-value"
        [(ngModel)]="attribute.custom"
        (change)="onChangeCustomValue()"
      >
        <option *ngFor="let item of options" [value]="item.value">
          {{ item.name }}
        </option>
      </select>

      <input
        *ngIf="attribute.isEditable && attribute.isSingleField"
        class="editable-value custom-value"
        type="text"
        [(ngModel)]="attribute.custom"
        (change)="onChangeCustomValue()"
        matInput
        [formControl]="filterControl"
        [matAutocomplete]="auto"
      />

      <mat-autocomplete #auto="matAutocomplete" (optionSelected)="onOptionSelected($event)">
        <mat-option *ngFor="let item of filteredData | async" [value]="item.value">
          {{ item.name }}
        </mat-option>
      </mat-autocomplete>

      <mat-chip-list #chipList *ngIf="attribute.isMultiSelect" class="editable-value custom-value">
        <mat-chip
          *ngFor="let item of selectedChips"
          [selectable]="selectable"
          [removable]="removable"
          (removed)="removeSelectedItem(item)"
        >
          {{ item }}
          <mat-icon matChipRemove *ngIf="removable">cancel</mat-icon>
        </mat-chip>

        <input
          placeholder="Select"
          #chipInput
          [formControl]="chipControl"
          [matAutocomplete]="autoChip"
          [matChipInputFor]="chipList"
          [matChipInputSeparatorKeyCodes]="separatorKeysCodes"
          (matChipInputTokenEnd)="addSelectedItem($event)"
          [readonly]="selectedChips.length > 1"
        />       
      </mat-chip-list>

      <mat-autocomplete #autoChip="matAutocomplete" (optionSelected)="onChipSelected($event)">
        <mat-option *ngFor="let item of filteredChips | async" [value]="item.value">
          {{ item.name }}
        </mat-option>
      </mat-autocomplete>
    </div>
    <div class="div-error-message">
      <span class="error-message">{{
        attribute.hasErrors ? attribute.validationErrorMessage : ''
      }}</span>
    </div>
  </td>
</tr>
