<div class="listing-history-1004">
  <ng-container *ngIf="form">
    <jaro-kit-listing-history-item
      *ngFor="let listingHistory of listingHistoryGroups"
      [subjectListingHistoryDto]="listingHistory"
    ></jaro-kit-listing-history-item>
  </ng-container>

  <div class="empty-panel" *ngIf="listingHistoryGroups.length === 0">
    <em class="fas fa-arrow-right" aria-hidden="true"></em>
    <div class="detail">
      <span class="title">No Listing History</span>
      <span class="description">No known listing for past 12 months</span>
    </div>
  </div>
</div>
