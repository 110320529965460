<div class="report-input-tools-page">
  <mat-spinner
    *ngIf="isLoading"
    value="100"
    diameter="100"
    strokeWidth="5"
    class="action-loading-spinner"
  ></mat-spinner>

  <ng-container>
    <div>
      <div class="empty-panel">
        <em class="fa-solid fa-arrow-right" aria-hidden="true"></em>
        <div class="detail">
          <span class="title">Manually sign the report</span>
          <div
            class="detail"
            *ngIf="isInspectorAppraiser || isPrimaryAppraiser || isReviewerAppraiser"
          >
            <button (click)="signReport()" class="tools-action-button">
              <em class="fa-solid fa-signature"></em> Sign report
            </button>
          </div>
          <span
            class="description"
            *ngIf="!(isInspectorAppraiser || isPrimaryAppraiser || isReviewerAppraiser)"
            >Unable to sign the report.</span
          >
        </div>
      </div>
    </div>
    <div>
      <div class="empty-panel">
        <em class="fa-solid fa-arrow-right" aria-hidden="true"></em>
        <div class="detail">
          <span class="title">Refresh Appraiser Details</span>
          <div
            class="detail"
          >
            <button (click)="refreshAppraiserDetails()" class="tools-action-button">
              <em class="fa-solid fa-arrows-rotate"></em> Refresh Details
            </button>
          </div>
        </div>
      </div>
    </div>
  </ng-container>
</div>
