import { Confirmation } from '@abp/ng.theme.shared';
import { Component, EventEmitter, Inject, Input, OnInit, Output } from '@angular/core';
import { FormBuilder, FormControl, FormGroup } from '@angular/forms';
import { SubjectPropertyDto } from '@proxy/appraisal/report/reports/v1/form1004/models';
import { ReportInputPhotoSectionDto } from '@proxy/bff/activity/report-input-photos/v1';
import { ReportPhotoWithThumbnailDto } from '@proxy/bff/activity/report-photos/v1';
import { ReportVersionWithLinksDto } from '@proxy/bff/activity/reports/v1';
import { ReportInputPhotoSectionServiceInterface } from 'src/app/interface/bff/activity/report-input-photo-section-interface.service';
import { ReportComparableModel } from 'src/app/proxy-adapter/report/comparables/models';
import InjectionSymbol from 'src/app/shared/injection/injection-symbol';
import { EventService } from 'src/app/shared/services/event.service';
import { MessageService } from 'src/app/shared/services/message.service';

@Component({
  selector: 'jaro-kit-comparables-grid-page-header',
  templateUrl: './comparables-grid-page-header.component.html',
  styleUrls: ['./comparables-grid-page-header.component.scss'],
})
export class ComparablesGridPageHeaderComponent implements OnInit {
  @Input() comparableDto: ReportComparableModel;
  @Input() isViewMore: boolean = true;
  @Input() subjectProperty: SubjectPropertyDto;

  @Input() reportVersionDto: ReportVersionWithLinksDto;
  @Input() orderAssignmentId: string;

  @Output() onHideComparable = new EventEmitter<ReportComparableModel>();
  @Output() onDeleteComparable = new EventEmitter<ReportComparableModel>();
  @Output() onSaveComparable = new EventEmitter<ReportComparableModel>();
  @Output() onEditComparable = new EventEmitter<ReportComparableModel>();
  @Output() onChangeViewMore = new EventEmitter<boolean>();
  @Output() partialUpdateReport = new EventEmitter<Record<string, string>>();

  photo: string;
  isIndicatedValueSelected: boolean = false;
  form: FormGroup;
  indicatedValueBySalesComparisonApproachField = new FormControl();
  isLoading: boolean;

  get salePricePerGrossLivingArea() {
    const salePricePerGrossLivingArea = this.comparableDto.salePricePerGrossLivingArea;
    return salePricePerGrossLivingArea && !isNaN(salePricePerGrossLivingArea)
      ? salePricePerGrossLivingArea
      : null;
  }

  get comparableIndex() {
    return this.comparableDto.comparableIndex;
  }

  constructor(
    private msgService: MessageService,
    fb: FormBuilder,
    @Inject(InjectionSymbol.ReportInputPhotoSectionService)
    public reportInputPhotoSectionService: ReportInputPhotoSectionServiceInterface,
    @Inject(InjectionSymbol.EventService)
    private eventService: EventService
  ) {
    this.form = fb.group({
      indicatedValueBySalesComparisonApproach: this.indicatedValueBySalesComparisonApproachField,
    });
  }

  ngOnInit(): void {
    this.photo = this.comparableDto.photo;
    if (this.comparableDto.isSubject) {
      this.getSubjectPhoto();
      if(!this.subjectProperty.salesComparisonApproach)
        this.subjectProperty.salesComparisonApproach = {};

      this.eventService.onGoToReportField.subscribe((field) => {
        if (field.fieldId === 'indicatedValueBySalesComparisonApproach') {
          this.isIndicatedValueSelected = true;

          setTimeout(() => {
            const elementRef = document.getElementById(field.fieldId);
            if (elementRef) {
              elementRef.scrollIntoView({ behavior: 'smooth', block: 'center' });
              elementRef.focus();
              const formControl = this.form.controls[field.fieldId];
              formControl.updateValueAndValidity();
              formControl.markAsTouched();
            }
          }, 500);
        }
      });
    }
  }

  private getSubjectPhoto() {
    this.isLoading = true;
    this.reportInputPhotoSectionService
      .getReportInputPhotoSectionsByReportIdAndReportVersionAndOrderAssignmentId(
        this.reportVersionDto.reportId,
        this.reportVersionDto.version,
        this.orderAssignmentId
      )
      .subscribe({
        next: (sections: ReportInputPhotoSectionDto[]) => {
          let coverPhoto = null;
          let frontPhoto = null;
          sections = sections || [];
          for (let section of sections) {
            coverPhoto = section.photos.find((photo) => photo.isCoverPhoto);
            if (coverPhoto) {
              break;
            } else if (section.name == 'front') {
              frontPhoto = section?.photos?.length > 0 ? section.photos[0] : null;
            }
          }
          const subjectPhoto = coverPhoto || frontPhoto;
          this.comparableDto.photo = this.getThumbnail(subjectPhoto);
          this.comparableDto.largePhoto = this.getThumbnail(subjectPhoto, 'large');
          this.photo = this.comparableDto.photo;
          this.isLoading = false;
        },
        error: (error) => {
          this.logActionError(error);
        },
      });
  }

  private getThumbnail(photo: ReportPhotoWithThumbnailDto, size = 'small') {
    const thumbnail = (photo?.thumbnails || []).find((item) => item.size == size);
    return thumbnail?.links?.length > 0 ? thumbnail?.links[0].href : null;
  }

  private logActionError(err: any): void {
    this.isLoading = false;
    this.msgService.error(err).subscribe(() => {});
  }

  hideComparable() {
    this.onHideComparable.emit(this.comparableDto);
  }

  deleteComparable() {
    const title = 'Remove Comparable?';
    const msgDelete = `Are you sure want to remove <span class="text-bold">Comparable ${this.comparableIndex}</span>? This action cannot be undone.`;
    const options: Partial<Confirmation.Options> = {
      yesText: 'Remove',
      cancelText: 'Cancel',
    };
    this.msgService.confirm(title, msgDelete, options).subscribe((response) => {
      if (response === Confirmation.Status.confirm) {
        this.onDeleteComparable.emit(this.comparableDto);
      }
    });
  }

  editComparable() {
    this.onEditComparable.emit(this.comparableDto);
  }

  onToggle() {
    this.isViewMore = !this.isViewMore;
    this.onChangeViewMore.emit(this.isViewMore);
  }

  onToggleIndicatedValue() {
    this.isIndicatedValueSelected = !this.isIndicatedValueSelected;
  }

  onChangeIndicatedValue() {
    this.isIndicatedValueSelected = false;
    this.changeValue(
      'property.salesComparisonApproach.indicatedValueBySalesComparisonApproach',
      this.subjectProperty.salesComparisonApproach?.indicatedValueBySalesComparisonApproach?.toString()
    );
  }

  changeValue(fieldPath: string, fieldValue: string) {
    const reportUpdates: Record<string, string> = {};
    reportUpdates[fieldPath] = fieldValue;
    this.partialUpdateReport.emit(reportUpdates);
  }
}
