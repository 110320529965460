<ng-container *ngIf="form" [formGroup]="form">
  <div class="row mb-15">
    <halo-global-utility-card-view-mode
      *ngFor="let item of utilities"
      [form]="form"
      [title]="item.title"
      [icon]="item.icon"
      [controlName]="item.controlName"
      [descriptionControlName]="item.descriptionControlName"
      class="col-md-3"
    ></halo-global-utility-card-view-mode>
  </div>
</ng-container>
