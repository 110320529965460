<div class="workfile-page" cdkDropListGroup>
  <mat-spinner
    *ngIf="isLoading"
    value="100"
    diameter="100"
    strokeWidth="5"
    class="action-loading-spinner"
  ></mat-spinner>

  <ng-container *ngIf="!isLoading">

    <jaro-kit-workfile-items
      [orderAssignmentId]="orderAssignmentId"
      [orderTimezone]="currentOrderAssignment.orderData.timezone"
      [workfiles]="workfiles"
      (onDeleteWorkfile)="deleteWorkfile($event)"
    ></jaro-kit-workfile-items>

    <jaro-kit-upload-workfile
      [orderAssignmentId]="orderAssignmentId"
      [isMultiple]="false"
      title="Workfile"
      (onAddFile)="getAllWorkfiles()"
    ></jaro-kit-upload-workfile>
  </ng-container>

</div>
