<ng-container *ngIf="form" [formGroup]="form" >
  <div class="row pb-15">
    <div class="col-md-6 pl-0 pr-15">
      <div class="row">
        <mat-form-field appearance="outline">
          <mat-label>Lender/Client</mat-label>
          <input matInput formControlName="subjectLenderClient" autocomplete="off" />
        </mat-form-field>
        <jaro-kit-validation-message [form]="form" [controlName]="'subjectLenderClient'" [isRequired]="true"></jaro-kit-validation-message>
  
        <mat-form-field appearance="outline">
          <mat-label>Address</mat-label>
          <input matInput formControlName="subjectLenderAddress" autocomplete="off" />
        </mat-form-field>
      </div>
    </div>
  </div>
</ng-container>
