<div ngbDropdown class="d-inline-block">
  <div class="quality-check-container">
    <button class="jaro-kit-primary-button mt-4 mb-4 quality-check" (click)="openQualityCheck()">
      Quality Check     
      <span class="quality-check-error-count" *ngIf="!isLoading && isQCLoaded && errorCount > 0">{{errorCount}}</span>
      <span class="quality-check-warning-count" *ngIf="!isLoading && isQCLoaded && errorCount == 0 && warningCount > 0">{{warningCount}}</span>
       <em class="fa-solid fa-circle-check" *ngIf="!isLoading && isQCLoaded && errorCount == 0 && warningCount == 0"></em>
       <span class="quality-check-loading" *ngIf="isLoading || !isQCLoaded">&nbsp;</span>
    </button>
  </div>
</div>
