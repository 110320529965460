import { ReportInputDataFormFieldModel } from 'src/app/features/shared/forms/models/report-input-data-field-form-model/report-input-date-field-form-model';
import { YesNoFormatPipe } from 'src/app/shared/pipes/yes-no-format/yes-no-format.pipe';

export const ReportInputTemplateConfigCarStorageSection: ReportInputDataFormFieldModel[] = [
  {
    formControlName: 'carStorage',
    format: {
      pipeType: YesNoFormatPipe,
    },
    mapToFieldFunc: (value) => {
      return !!value;
    },
  },
  {
    formControlName: 'carStorageGarage',
    format: {
      pipeType: YesNoFormatPipe,
    },
  },
  {
    formControlName: 'carStorageDriveway',
    format: {
      pipeType: YesNoFormatPipe,
    },
  },
  {
    formControlName: 'carStorageCarport',
    format: {
      pipeType: YesNoFormatPipe,
    },
  },
];
