import { CoreModule, LocalizationPipe } from '@abp/ng.core';
import { ThemeBasicModule } from '@abp/ng.theme.basic';
import { ThemeSharedModule } from '@abp/ng.theme.shared';
import { AgmCoreModule } from '@agm/core';
import { AgmDrawingModule } from '@agm/drawing';
import { DragDropModule } from '@angular/cdk/drag-drop';
import { CUSTOM_ELEMENTS_SCHEMA, NO_ERRORS_SCHEMA, NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { MatButtonModule } from '@angular/material/button';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatChipsModule } from '@angular/material/chips';
import { MatCommonModule, MatNativeDateModule } from '@angular/material/core';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatDialogModule } from '@angular/material/dialog';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatMenuModule } from '@angular/material/menu';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatRadioModule } from '@angular/material/radio';
import { MatSelectModule } from '@angular/material/select';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { MatSliderModule } from '@angular/material/slider';
import { MatTabsModule } from '@angular/material/tabs';
import { MatTreeModule } from '@angular/material/tree';
import { NgxGalleryModule } from '@kolkov/ngx-gallery';
import { NgbDatepickerModule, NgbDropdownModule } from '@ng-bootstrap/ng-bootstrap';
import { MatCarouselModule } from '@ngbmodule/material-carousel';
import { AngularDualListBoxModule } from 'angular-dual-listbox';
import { NgxExtendedPdfViewerModule } from 'ngx-extended-pdf-viewer';
import { InfiniteScrollModule } from 'ngx-infinite-scroll';
import { IConfig, NgxMaskModule } from 'ngx-mask';
import { environment } from '../../environments/environment';
import { SectionPanelComponent } from '../features/shared/forms/components/section-list/section/section-panel/section-panel.component';
import { ReportServiceAdapter } from '../proxy-adapter/appraisal/report/report-adapter.service';
import { DocumentsServiceAdapter } from '../proxy-adapter/publication/document/documents/documents-adapter.service';
import { BackButtonComponent } from './components/back-button/back-button.component';
import { CreateNewButtonComponent } from './components/create-new-button/create-new-button.component';
import { CustomListboxComponent } from './components/custom-listbox/custom-listbox.component';
import { DatePickerComponent } from './components/date-time/date-picker/date-picker.component';
import { DateTimePickerComponent } from './components/date-time/date-time-picker/date-time-picker.component';
import { DueDateComponent } from './components/date-time/due-date/due-date.component';
import { TimePickerComponent } from './components/date-time/time-picker/time-picker.component';
import { DocumentIconComponent } from './components/document/document-icon/document-icon.component';
import { DocumentItemComponent } from './components/document/document-item/document-item.component';
import { DocumentsListComponent } from './components/document/documents-list/documents-list.component';
import { DocumentsUploaderComponent } from './components/document/documents-uploader/documents-uploader.component';
import { PreviewDocumentComponent } from './components/document/preview-document/preview-document.component';
import { PreviewExtractDocumentComponent } from './components/document/preview-extract-document/preview-extract-document.component';
import { ValidationMessageComponent } from './components/document/validation-message/validation-message.component';
import { DynamicComponent } from './components/dynamic/dynamic.component';
import { EditorComponent } from './components/editor/editor.component';
import { ExpandableTextAreaComponent } from './components/expandable-text-area/expandable-text-area.component';
import { HeaderComponent } from './components/header/header.component';
import { InlineTextAreaComponent } from './components/inline-text-area/inline-text-area.component';
import { MenuLeftComponent } from './components/menu-left/menu-left.component';
import { AlertComponent } from './components/message/confirmation/alert/alert.component';
import { ConfirmationComponent } from './components/message/confirmation/confirmation.component';
import { MultipleComboBoxComponent } from './components/multiple-combo-box/multiple-combo-box.component';
import { MultipleUploaderComponent } from './components/multiple-uploader/multiple-uploader.component';
import { PopupComponent } from './components/popup/popup.component';
import { ProgressBarComponent } from './components/progress-bar/progress-bar.component';
import { ComparableDataReferenceItemComponent } from './components/raw-data/comparable-data-reference-item/comparable-data-reference-item.component';
import { RawDataItemComponent } from './components/raw-data/raw-data-item/raw-data-item.component';
import { RawDataComponent } from './components/raw-data/raw-data.component';
import { ReportInputLabelComponent } from './components/report-input-label/report-input-label.component';
import { RushComponent } from './components/rush/rush.component';
import { SpinnerComponent } from './components/spinner/spinner.component';
import { TextSearchComponent } from './components/text-search/text-search.component';
import { TreeNodeComponent } from './components/tree-node/tree-node.component';
import { TruncatedTextComponent } from './components/truncated-text/truncated-text.component';
import { UploadDocumentComponent } from './components/upload-document/upload-document.component';
import { UploadFileComponent } from './components/upload-file/upload-file.component';
import InjectionSymbol from './injection/injection-symbol';
import { EmptyArrayIfTruePipe } from './pipes/empty-array-if-true/empty-array-if-true.pipe';
import { MainDateTimeFormatPipe } from './pipes/main-date-time-format/main-date-time-format.pipe';
import { PascalCaseToSpaceDelimitedPipe } from './pipes/pascal-case-to-space-delimited/pascal-case-to-space-delimited.pipe';
import { PhotoSourcePipe } from './pipes/photo-source/photo-source.pipe';
import { PipesModule } from './pipes/pipes.module';
import { TimezoneAbbreviations } from './pipes/time-zone-abbreviations/time-zone-abbreviations';
import { MultipleUploaderZoneDirective } from './providers/multiple-uploader-zone/multiple-uploader-zone.directive';
import { ProductService } from './providers/products/products.factory';
import { LocalClockService } from './services/local-clock-service';
import { EmailValidatorDirective } from './validator/email-validator/email-validator.service';
import { PhoneNumberValidatorDirective } from './validator/phone-number-validator/phone-number-validator.service';

export const options: Partial<IConfig> | (() => Partial<IConfig>) = null;

@NgModule({
  declarations: [
    ReportInputLabelComponent,
    DocumentIconComponent,
    DocumentItemComponent,
    DocumentsListComponent,
    DocumentsUploaderComponent,
    DueDateComponent,
    DynamicComponent,
    EditorComponent,
    ExpandableTextAreaComponent,
    InlineTextAreaComponent,
    MenuLeftComponent,
    MultipleComboBoxComponent,
    MultipleUploaderComponent,
    MultipleUploaderZoneDirective,
    PopupComponent,
    ProgressBarComponent,
    TimePickerComponent,
    TruncatedTextComponent,
    DateTimePickerComponent,
    EmailValidatorDirective,
    PhoneNumberValidatorDirective,
    HeaderComponent,
    BackButtonComponent,
    CustomListboxComponent,
    CreateNewButtonComponent,
    TextSearchComponent,
    RushComponent,
    SpinnerComponent,
    DatePickerComponent,
    ConfirmationComponent,
    AlertComponent,
    UploadFileComponent,
    UploadDocumentComponent,
    SectionPanelComponent,
    PreviewDocumentComponent,
    PreviewExtractDocumentComponent,
    TreeNodeComponent,
    ValidationMessageComponent,
    RawDataItemComponent,
    RawDataComponent,
    ComparableDataReferenceItemComponent
  ],
  imports: [
    AgmCoreModule.forRoot({
      apiKey: environment.googleMaps.apiKey,
      libraries: ['places', 'drawing'],
    }),
    AgmDrawingModule,
    CoreModule,
    FormsModule,
    MatAutocompleteModule,
    MatFormFieldModule,
    MatButtonModule,
    MatCommonModule,
    MatChipsModule,
    MatInputModule,
    MatCarouselModule.forRoot(),
    MatCheckboxModule,
    MatRadioModule,
    MatSelectModule,
    MatSliderModule,
    MatSlideToggleModule,
    NgbDatepickerModule,
    MatTabsModule,
    MatTreeModule,
    MatIconModule,
    MatDialogModule,
    MatProgressSpinnerModule,
    NgbDropdownModule,
    PipesModule,
    ThemeBasicModule,
    ThemeSharedModule,
    NgbDatepickerModule,
    MatProgressSpinnerModule,
    AngularDualListBoxModule,
    NgxGalleryModule,
    NgxMaskModule.forRoot(options),
    MatMenuModule,
    InfiniteScrollModule,
    DragDropModule,
    PipesModule,
    MatDatepickerModule,
    MatNativeDateModule,
    NgxExtendedPdfViewerModule,
  ],
  exports: [
    ReportInputLabelComponent,
    CoreModule,
    DocumentsListComponent,
    DocumentsUploaderComponent,
    DueDateComponent,
    DynamicComponent,
    EditorComponent,
    ExpandableTextAreaComponent,
    InlineTextAreaComponent,
    MainDateTimeFormatPipe,
    MultipleComboBoxComponent,
    MultipleUploaderComponent,
    NgbDropdownModule,
    PascalCaseToSpaceDelimitedPipe,
    PopupComponent,
    ProgressBarComponent,
    ThemeBasicModule,
    ThemeSharedModule,
    TimePickerComponent,
    TruncatedTextComponent,
    DateTimePickerComponent,
    EmailValidatorDirective,
    PhoneNumberValidatorDirective,
    HeaderComponent,
    BackButtonComponent,
    CustomListboxComponent,
    CreateNewButtonComponent,
    TextSearchComponent,
    RushComponent,
    SpinnerComponent,
    DatePickerComponent,
    PhotoSourcePipe,
    TimezoneAbbreviations,
    DocumentIconComponent,
    UploadFileComponent,
    UploadDocumentComponent,
    SectionPanelComponent,
    PreviewDocumentComponent,
    PreviewExtractDocumentComponent,
    TreeNodeComponent,
    ValidationMessageComponent,
    RawDataComponent
  ],
  providers: [
    { provide: InjectionSymbol.DocumentsService, useClass: DocumentsServiceAdapter },
    { provide: InjectionSymbol.ClockService, useClass: LocalClockService },
    { provide: InjectionSymbol.ReportService, useClass: ReportServiceAdapter },
    { provide: InjectionSymbol.ProductService, useClass: ProductService },

    LocalizationPipe,
    EmptyArrayIfTruePipe,
  ],
  schemas: [CUSTOM_ELEMENTS_SCHEMA, NO_ERRORS_SCHEMA],
})
export class SharedModule {}
