<jaro-kit-section
  *ngFor="let section of sections"
  [section]="section"
  [(reportDto)]="reportDto"
  [timeZoneId]="timeZoneId"
  [orderAssignmentId]="orderAssignmentId"
  [feature]="feature"
  [reportVersionDto]="reportVersionDto"
  [boostEnabled]="boostEnabled"
  (onSaveSectionForm)="saveSectionForm($event)"
></jaro-kit-section>
