import { Confirmation } from '@abp/ng.theme.shared';
import { Component, Inject, Input, OnDestroy, OnInit } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { SubjectListingHistoryDto } from '@proxy/bff/activity/reports/v1';
import { Guid } from 'guid-typescript';
import { cloneDeep } from 'lodash-es';
import { BehaviorSubject, Subscription } from 'rxjs';
import { ReportInputTemplateService } from 'src/app/features/shared/forms/providers/report-input-template.service';
import { ReportServiceInterface } from 'src/app/interface/appraisal/report/report-service-interface';
import InjectionSymbol from 'src/app/shared/injection/injection-symbol';
import { MessageService } from 'src/app/shared/services/message.service';

@Component({
  selector: 'jaro-kit-listing-history-edit-mode',
  templateUrl: './listing-history-edit-mode.component.html',
  styleUrls: ['./listing-history-edit-mode.component.scss'],
})
export class ListingHistoryEditModeComponent implements OnInit, OnDestroy {
  @Input() form: FormGroup;
  @Input() orderAssignmentId: string;
  private subscription: Subscription;

  listingHistoryGroups: SubjectListingHistoryDto[];

  constructor(
    @Inject(InjectionSymbol.ReportInputTemplateService)
    public reportInputTemplateService: ReportInputTemplateService,
    @Inject(InjectionSymbol.ReportService) public reportService: ReportServiceInterface,
    private msgService: MessageService
  ) {}

  ngOnInit(): void {
    this.listingHistoryGroups = cloneDeep(this.form?.value['listingHistory'] || []);
    this.listingHistoryGroups.forEach((item) => {
      item.guid = item.guid || Guid.create();
    });
    this.filterEmptyData();
    this.initSaveEvent();
  }

  private initSaveEvent() {
    this.reportInputTemplateService.onSave = new BehaviorSubject<string[]>(null);
    this.subscription = this.reportInputTemplateService.onSave.subscribe((eventsOnSave) => {
      this.form.controls['listingHistory'].setValue(this.listingHistoryGroups);
      if (eventsOnSave?.includes('listingHistory')) {
        setTimeout(() => {
          this.saveListingHistory();
        }, 500);
      }
    });
  }

  ngOnDestroy() {
    if (this.subscription) this.subscription.unsubscribe();
  }

  addListingHistoryGroup() {
    const newItem = {
      daysOnMarket: null,
      dataSource: null,
      listingNumber: null,
      guid: Guid.create(),
      listingHistory: [],
    };
    this.listingHistoryGroups = [...this.listingHistoryGroups, newItem];
  }

  trackByGuid(_index, subjectListingHistoryDto: SubjectListingHistoryDto): any {
    return subjectListingHistoryDto.guid;
  }

  removeListingHistory(guid: Guid) {
    this.listingHistoryGroups = this.listingHistoryGroups.filter((item) => item.guid != guid);
  }

  private saveListingHistory() {
    let listingHistoryGroups = cloneDeep(this.listingHistoryGroups);
    listingHistoryGroups.forEach((item) => {
      item.listingHistory = (item.listingHistory || []).filter(
        (item) => item.date || item.event || item.price
      );
    });
    listingHistoryGroups = listingHistoryGroups.filter((item) => item.listingHistory?.length > 0);
    const updateReportListingHistoryDto = {
      orderAssignmentId: this.orderAssignmentId,
      listingHistoryGroups: listingHistoryGroups,
    };

    // wait for update reportDto
    setTimeout(() => {
      this.reportService.updateListingHistory(updateReportListingHistoryDto).subscribe({
        next: (_response) => {
          this.reportInputTemplateService.onSave.unsubscribe();
        },
        error: (err) => this.logActionError(err),
      });
    }, 500);
  }

  private filterEmptyData() {
    this.listingHistoryGroups.forEach((item) => {
      item.listingHistory = (item.listingHistory || []).filter(
        (item) => item.date || item.event || item.price
      );
    });
    this.listingHistoryGroups = this.listingHistoryGroups.filter(
      (item) => item.listingHistory?.length > 0
    );
  }

  private logActionError(err: any): void {
    this.msgService.error(err).subscribe((_status: Confirmation.Status) => {});
    this.initSaveEvent();
  }
}
