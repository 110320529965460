import { Component, ElementRef, Inject, OnInit, ViewChild } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import {
  GenerateReportRequest,
  ReportGenerationRequestResponseDto,
  ReportGenerationStatusDto
} from '@proxy/appraisal/report/reports/v2';
import { ReportVersionWithLinksDto } from '@proxy/bff/activity/reports/v1';
import { ReportGenerationInterface } from 'src/app/interface/appraisal/report/report-generation.interface';
import { ReportFormat } from 'src/app/shared/enums/report-format.enum';
import InjectionSymbol from 'src/app/shared/injection/injection-symbol';
import { MessageService } from 'src/app/shared/services/message.service';

@Component({
  selector: 'jaro-kit-export-report',
  templateUrl: './export-report.component.html',
  styleUrls: ['./export-report.component.scss'],
})

export class ExportReportComponent implements OnInit {
  orderAssignmentId: string;
  productName: string;
  reportVersionDto: ReportVersionWithLinksDto;
  isLoading: boolean;
  exportUrl: string;
  isError: boolean;
  @ViewChild("exportLink") exportLink: ElementRef<HTMLLinkElement>;

  constructor(
    @Inject(InjectionSymbol.ReportGenerationService)
    private reportGenerationService: ReportGenerationInterface,
    @Inject(InjectionSymbol.ReportService)
    private msgService: MessageService,
    public dialogRef: MatDialogRef<ExportReportComponent>,
    @Inject(MAT_DIALOG_DATA)
    public exportDocumentData: {
      orderAssignmentId: string;
      reportVersionDto: ReportVersionWithLinksDto;
      productName;
    }
  ) {}

  ngOnInit(): void {
    this.orderAssignmentId = this.exportDocumentData.orderAssignmentId;
    this.reportVersionDto = this.exportDocumentData.reportVersionDto;
    this.productName = this.exportDocumentData.productName;
    this.exportUrl = "";
    this.isError = false;

    this.generateReport(ReportFormat.Tdcx);
  }

  generateReport(reportFormat: string) {
    this.isLoading = true;
    const reportRequest = {
      reportFormat: reportFormat,
      orderAssignmentId: this.orderAssignmentId,
      concurrencyStamp: null,
    } as GenerateReportRequest;
    this.reportGenerationService.generate(reportRequest).subscribe({
      next: (response: ReportGenerationRequestResponseDto) => {        
        this.setDownloadUrl(response.reportGenerationRequestId);        
      },
      error: (err) =>  {
        this.isError = true;
        this.isLoading = false;
        this.msgService.error(err);
      }
    });
  }

  private setDownloadUrl(reportGenerationRequestId: string) {
    this.reportGenerationService.getGenerateReportFileStatus(reportGenerationRequestId).subscribe({
      next: (response: ReportGenerationStatusDto) => {
        switch (response.status) {
          case 'New':
            if (this.isLoading) {
              setTimeout(() => {
                this.setDownloadUrl(reportGenerationRequestId);                
              }, 1000);
            }

            break;
          case 'Error':
            this.isLoading = false;
            this.isError = true;
            this.msgService.error(response.message);
            break;
          case 'Completed':
            this.isLoading = false;
            this.exportUrl = response.url;
            setTimeout(
              () => {
                this.exportLink.nativeElement.click();
                this.dialogRef.close();
              }, 500);
            break;
        }
      },
      error: (err) => {
        this.isLoading = false;
        this.isError = true;
        this.msgService.error(err);
      }
    });
  }

  cancelButton(){
    this.isLoading = false;
    this.dialogRef.close();
  } 
}
