import { Component, Input } from '@angular/core';
import { SubjectPropertyDto } from '@proxy/appraisal/report/reports/v1/form1004';

@Component({
  selector: 'jaro-kit-commentaries',
  templateUrl: './commentaries.component.html',
  styleUrls: ['./commentaries.component.scss'],
})
export class CommentariesComponent {
  @Input() property: SubjectPropertyDto;

}
