<ng-container *ngIf="form" [formGroup]="form">
  <div class="row">
    <div class="col-md-6 pl-0 pr-15">
      <mat-form-field appearance="outline">
        <mat-label class="report-input-label">Condition Rating</mat-label>
        <mat-select
          id="narrativeCommentsConditionRating"
          formControlName="narrativeCommentsConditionRating"
          disableOptionCentering
          panelClass="report-input-drop-down"
          placeholder="Select from list"
          floatLabel="never"
        >
          <mat-option *ngFor="let item of conditionRatingOptions" [value]="item.value">{{
            item.name
          }}</mat-option>
        </mat-select>
      </mat-form-field>
      <jaro-kit-validation-message [form]="form" [controlName]="'narrativeCommentsConditionRating'" [isRequired]="true"></jaro-kit-validation-message>

      <mat-form-field appearance="outline" class="jaro-kit-textarea">
        <mat-label>Overall Improvements</mat-label>
        <textarea
          type="text"
          id="narrativeCommentsOverallImprovements"
          formControlName="narrativeCommentsOverallImprovements"
          placeholder="Overall Improvements"
          matInput
          rows="4"
        ></textarea>
      </mat-form-field>
      <jaro-kit-validation-message [form]="form" [controlName]="'narrativeCommentsOverallImprovements'" [isRequired]="true"></jaro-kit-validation-message>
    </div>

    <div class="col-md-6 pl-15 pr-0">

      <mat-form-field appearance="outline">
        <mat-label class="report-input-label">Quality Rating</mat-label>
        <mat-select
          formControlName="narrativeCommentsQualityRating"
          id="narrativeCommentsQualityRating"
          disableOptionCentering
          panelClass="report-input-drop-down"
          placeholder="Select from list"
          floatLabel="never"
        >
          <mat-option *ngFor="let item of qualityRatingOptions" [value]="item.value">{{
            item.name
          }}</mat-option>
        </mat-select>
      </mat-form-field>
      <jaro-kit-validation-message [form]="form" [controlName]="'narrativeCommentsQualityRating'" [isRequired]="true"></jaro-kit-validation-message>

      <div class="pt-15">
        <p class="report-input-label">Physical Deficiencies or Adverse Conditions</p>
        <mat-radio-group
          formControlName="narrativeCommentsPhysicalDeficiencies"
          id="narrativeCommentsPhysicalDeficiencies"
          class="mt-15 mb-15"
        >
          <mat-radio-button [value]="true" class="report-input-radio-button">Yes</mat-radio-button>
          <mat-radio-button [value]="false" class="report-input-radio-button">No</mat-radio-button>
        </mat-radio-group>        
      </div>

      <mat-form-field appearance="outline" class="jaro-kit-textarea">
        <mat-label>Physical Deficiencies or Adverse Conditions - Comment</mat-label>
        <textarea
          type="text"
          id="narrativeCommentsPhysicalDeficienciesComment"
          formControlName="narrativeCommentsPhysicalDeficienciesComment"
          placeholder="Physical Deficiencies or Adverse Conditions - Comment"
          matInput
          rows="4"
        ></textarea>
      </mat-form-field>
      <jaro-kit-validation-message [form]="form" [controlName]="'narrativeCommentsPhysicalDeficienciesComment'" [isRequired]="true"></jaro-kit-validation-message>

      <div class="pt-15">
        <p class="report-input-label">Property Conformity</p>
        <mat-radio-group formControlName="narrativeCommentsPropertyConformity" class="mt-15 mb-15" (change)="onChangePropertyConformity($event)" >
          <mat-radio-button [value]="true" class="report-input-radio-button">Yes</mat-radio-button>
          <mat-radio-button [value]="false" class="report-input-radio-button">No</mat-radio-button>
        </mat-radio-group>
      </div>

      <mat-form-field appearance="outline" class="jaro-kit-textarea">
        <mat-label>Property Conformity - Comment</mat-label>
        <textarea
          type="text"
          id="narrativeCommentsPropertyConformityComment"
          formControlName="narrativeCommentsPropertyConformityComment"
          placeholder="Property Conformity - Comment"
          matInput
          rows="4"
        ></textarea>
      </mat-form-field>

      <jaro-kit-validation-message [form]="form" [controlName]="'narrativeCommentsPropertyConformityComment'" [isRequired]="true"></jaro-kit-validation-message>
    </div>
  </div>
</ng-container>
