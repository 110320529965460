import { Component, Inject, Input } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { ContentServiceInterface } from 'src/app/interface/content/content-service-interface';
import { ItemData } from 'src/app/shared/components/multiple-combo-box/multiple-combo-box.component';
import InjectionSymbol from 'src/app/shared/injection/injection-symbol';

@Component({
  selector: 'halo-global-exterior-section1004',
  templateUrl: './exterior-section1004.component.html',
  styleUrls: ['./exterior-section1004.component.scss'],
})
export class ExteriorSection1004Component {
  @Input() form: FormGroup;

  attachmentTypeOptions: ItemData[] = [];

  stageOfConstructionOptions: ItemData[] = [];

  foundationWalls = null;
  foundationWallsOptions: ItemData[] = [];

  exteriorWalls = null;
  exteriorWallsOptions: ItemData[] = [];

  roofSurface = null;
  roofSurfaceOptions: ItemData[] = [];

  guttersAndDownspouts = null;
  guttersAndDownspoutsOptions: ItemData[] = [];

  windowType = null;
  windowTypeOptions: ItemData[] = [];

  stormSashInsulated = null;
  stormSashInsulatedOptions: ItemData[] = [];

  conditionOptions: ItemData[] = [];
  designStyleOptions: ItemData[] = [];

  get isShowScreensCondition() {
    return this.form.value['exteriorScreens'] === 'yes';
  }

  constructor(
    @Inject(InjectionSymbol.ContentService)
    public contentService: ContentServiceInterface
  ) {
    this.attachmentTypeOptions = this.contentService.getContentByKey('attachmentType');
    this.stageOfConstructionOptions = this.contentService.getContentByKey('buildingType');

    this.foundationWallsOptions = this.contentService.getContentByKey(
      'exterior.foundationWallsMaterials'
    );
    this.exteriorWallsOptions = this.contentService.getContentByKey(
      'exterior.exteriorWallsMaterials'
    );
    this.roofSurfaceOptions = this.contentService.getContentByKey('exterior.roofSurfaceMaterials');
    this.guttersAndDownspoutsOptions = this.contentService.getContentByKey(
      'exterior.guttersAndDownspoutsMaterials',
      false,
      true
    );
    this.windowTypeOptions = this.contentService.getContentByKey('exterior.windowTypeMaterials');
    this.stormSashInsulatedOptions = this.contentService.getContentByKey(
      'exterior.stormSashOrInsulatedMaterials',
      false,
      true
    );
    this.conditionOptions = this.contentService.getContentByKey('materialConditions');
    this.designStyleOptions = this.contentService.getContentByKey('dwellingStyle');
  }

  onChangeHasScreens($event) {
    this.form.controls['exteriorScreens'].setValue($event.value);
    if ($event.value === 'no') {
      this.form.controls['exteriorScreensCondition'].setValue(null);
      this.form.controls['exteriorScreensCondition'].markAsDirty();
      this.form.updateValueAndValidity();
    }
  }

  onChangeType($event) {
    if (!['attached', 'semi-detached/end unit'].includes($event.value)) {
      const controlNames = [
        'PUDProjectName',
        'PUDProjectDataSource',
        'PUDNumberOfPhases',
        'PUDNumberOfUnits',
        'PUDNumberOfUnitsSold',
        'PUDNumberOfUnitsRented',
        'PUDNumberOfUnitsForSale',
        'PUDProjectDataSource',
        'PUDIsCreatedByBuildingConversion',
        'PUDBuildingConversionDate',
        'PUDIsAnyMultipleDwellingUnits',
        'PUDMultipleDwellingUnitsDataSource',
        'PUDAreUnitsCommonElementsAndRecreationFacilitiesCompleted',
        'PUDAreUnitsCommonElementsAndRecreationFacilitiesCompletedComment',
        'PUDIsAnyCommonElementsLeasedByOrToHoa',
        'PUDIsAnyCommonElementsLeasedByOrToHoaComment',
        'PUDCommonElementsAndRecreationalFacilities',
      ];
      controlNames.forEach((controlName) => {
        const control = this.form.controls[controlName];
        if (control) {
          control.setValue(null);
          control.markAsDirty();
        }
      });
      this.form.updateValueAndValidity();
    }
  }
}
