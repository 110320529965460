import { RoomDto } from '@proxy/appraisal/report/reports/v1/form1004';
import { ReportDto } from '@proxy/appraisal/report/v1/form1004';
import { get, set } from 'lodash-es';
import { ReportInputTemplateField } from '../../../shared/forms/models/report-input-layout.model';
export class MappingFieldFunc {
  public static roomFieldMapToModelFunc(
    value,
    reportDto: ReportDto,
    formFieldConfig: ReportInputTemplateField
  ) {
    const rooms = get(reportDto, 'property.improvement.interior.rooms') || ([] as RoomDto[]);
    const roomName = formFieldConfig.metadata?.roomName;
    const room = rooms.find((item) => item.areaName === roomName);
    if (room) {
      set(room, formFieldConfig.path, value);
    }
  }
}
