<div
  [ngClass]="{
    'comparable-grid-cell-input-tpl': true,
    'comparable-grid-cell-input-tpl-disabled': isDisabled
  }"
>
  <mat-form-field *ngIf="!isNestedField && (!lineItems || lineItems?.length == 0 || field.adjustmentType === 'totals')" appearance="outline">
    <mat-label>Adjustment</mat-label>
    <input
      matInput
      [(ngModel)]="value"
      autocomplete="off"
      currencyMask
      mask="separator.0"
      thousandSeparator=","
      [allowNegativeNumbers]="true"
      [disabled]="isDisabled"
      (change)="changeAdjustmentValue()"
    />
  </mat-form-field>

  <div *ngIf="isNestedField && nestedItems" class="comparable-grid-cell-line-items">
    <div *ngFor="let item of nestedItems" class="comparable-grid-cell-line-item">
      <mat-form-field appearance="outline">
        <mat-label>{{ item.label }}</mat-label>
        <input matInput
               [(ngModel)]="item.adjustment"
               autocomplete="off"
               currencyMask
               mask="separator.0"
               thousandSeparator=","
               [allowNegativeNumbers]="true"
               [disabled]="isDisabled || item.isDisabled"
               (change)="changeNestedAdjustmentValue(item)" />
      </mat-form-field>
    </div>
  </div>

  <div *ngIf="!isNestedField && (lineItems?.length > 0 && field.adjustmentType != 'totals')" class="comparable-grid-cell-line-items">
    <div *ngFor="let lineItem of lineItems" class="comparable-grid-cell-line-item">
      <mat-form-field appearance="outline">
        <mat-label>{{ lineItem.name }}</mat-label>
        <input
          matInput
          [(ngModel)]="lineItem.adjustment"
          autocomplete="off"
          currencyMask
          mask="separator.0"
          thousandSeparator=","
          [allowNegativeNumbers]="true"
          [disabled]="isDisabled || lineItem.isDisabled"
          (change)="changeItemValue(lineItem)"
        />
      </mat-form-field>
    </div>
  </div>
</div>
