<div *ngIf="form" [formGroup]="form">
  <table class="pud-information-total">
    <caption style="display: none">
      pud-information-total
    </caption>
    <tr>
      <th [colSpan]="2">TOTALS</th>
    </tr>
    <tr>
      <td># of Phases</td>
      <td class="col-value">
        <div *ngIf="isEditMode">
          <input
            matInput
            formControlName="PUDNumberOfPhases"
            autocomplete="off"
            currencyMask
            mask="separator.0"
            thousandSeparator=","
            maxlength="9"
            [allowNegativeNumbers]="false"
          />
        </div>
        <span *ngIf="!isEditMode">{{ form.value['PUDNumberOfPhases'] | number }}</span>
      </td>
    </tr>
    <tr>
      <td># of Units</td>
      <td class="col-value">
        <div *ngIf="isEditMode">
          <input
            matInput
            formControlName="PUDNumberOfUnits"
            autocomplete="off"
            currencyMask
            mask="separator.0"
            thousandSeparator=","
            maxlength="9"
            maxlength="3"
            [allowNegativeNumbers]="false"
          />
        </div>
        <span *ngIf="!isEditMode">{{ form.value['PUDNumberOfUnits'] | number }}</span>
      </td>
    </tr>
    <tr>
      <td># of Units Sold</td>
      <td class="col-value">
        <div *ngIf="isEditMode">
          <input
          matInput
            formControlName="PUDNumberOfUnitsSold"
            autocomplete="off"
            currencyMask
            mask="separator.0"
            thousandSeparator=","
            maxlength="9"
            maxlength="3"
            [allowNegativeNumbers]="false"
          />
        </div>
        <span *ngIf="!isEditMode">{{ form.value['PUDNumberOfUnitsSold'] | number }}</span>
      </td>
    </tr>
    <tr>
      <td># of Units Rented</td>
      <td class="col-value">
        <div *ngIf="isEditMode">
          <input
          matInput
            formControlName="PUDNumberOfUnitsRented"
            autocomplete="off"
            currencyMask
            mask="separator.0"
            thousandSeparator=","
            maxlength="9"
            maxlength="3"
            [allowNegativeNumbers]="false"
          />
        </div>
        <span *ngIf="!isEditMode">{{ form.value['PUDNumberOfUnitsRented'] | number }}</span>
      </td>
    </tr>
    <tr>
      <td># of Units for Sale</td>
      <td class="col-value">
        <div *ngIf="isEditMode">
          <input
          matInput
            formControlName="PUDNumberOfUnitsForSale"
            autocomplete="off"
            currencyMask
            mask="separator.0"
            thousandSeparator=","
            maxlength="9"
            maxlength="3"
            [allowNegativeNumbers]="false"
          />
        </div>
        <span *ngIf="!isEditMode">{{ form.value['PUDNumberOfUnitsForSale'] | number }}</span>
      </td>
    </tr>
  </table>
</div>
