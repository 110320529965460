import { Component, EventEmitter, Inject, OnInit, Output } from '@angular/core';
import { OrderAssignmentDto } from '@proxy/ascent/order/order-assignment/order-assignments';
import { OrderAssignmentSearchDto } from '@proxy/ascent/order/order-assignment/order-assignments/v2/orderAssignmentSearchDto';
import { OrderAssignmentServiceInterface } from 'src/app/interface/ascent/order-assignment-service-interface';
import InjectionSymbol from 'src/app/shared/injection/injection-symbol';

@Component({
  selector: 'jaro-kit-order-search-results',
  templateUrl: './order-search-results.component.html',
  styleUrls: ['./order-search-results.component.scss'],
})
export class OrderSearchResultsComponent implements OnInit {
  @Output() onClick = new EventEmitter<boolean>();
  query: OrderAssignmentSearchDto = {};
  searchResults: OrderAssignmentDto[] = [];
  selectedSearchValue: string;
  isLoading: boolean;

  constructor(
    @Inject(InjectionSymbol.OrderAssignmentService)
    private orderAssignmentService: OrderAssignmentServiceInterface) {}

  ngOnInit(): void {

  }

  searchOrders(searchText: string) {
    this.isLoading = true;
    this.query.customerOrderid = searchText;
    this.orderAssignmentService.search(this.query).subscribe((data) => {
      this.isLoading = false;
      this.searchResults = data;
    });
  }

  applyFilter(value: string) {
    this.searchOrders(value);
  }

  onClickAction(value) {
    this.onClick.emit(value);
  }
}
