import { Component, Inject, Input } from '@angular/core';
import { FormGroup, Validators } from '@angular/forms';
import { MatRadioChange } from '@angular/material/radio';
import { ContentServiceInterface } from 'src/app/interface/content/content-service-interface';
import { ItemData } from 'src/app/shared/components/multiple-combo-box/multiple-combo-box.component';
import InjectionSymbol from 'src/app/shared/injection/injection-symbol';

@Component({
  selector: 'jaro-kit-narrative-comments-section1004',
  templateUrl: './narrative-comments-section1004.component.html',
  styleUrls: ['./narrative-comments-section1004.component.scss'],
})
export class NarrativeCommentsSection1004Component {
  @Input() form: FormGroup;

  conditionRatingOptions: ItemData[] = [];
  qualityRatingOptions: ItemData[] = [];

  constructor(
    @Inject(InjectionSymbol.ContentService)
    public contentService: ContentServiceInterface
  ) {
    this.conditionRatingOptions = this.contentService.getContentByKey('overallCondition');
    this.qualityRatingOptions = this.contentService.getContentByKey('overallQuality');
  }

  // Toggle required validation for the neighborhood property conformity description 
  // to only be required if the property does not conform to the neighborhood.
  onChangePropertyConformity($event: MatRadioChange) {
    const descriptionControl = this.form.controls['narrativeCommentsPropertyConformityComment'];
    if (descriptionControl) {
      if ($event.value) {        
        descriptionControl.setValidators(null);
        descriptionControl.setErrors(null)
      } else {
        descriptionControl.setValidators(Validators.required);        
        descriptionControl.updateValueAndValidity();        
      }
    }
  }
}
