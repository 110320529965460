<ng-container *ngIf="form" [formGroup]="form" >
  <div class="row pb-15">
    <div class="col-md-6 pl-15 pr-0">      
      <div class="report-input-form-field">
        <p class="report-input-label report-input-radio-label">Assignment Type</p>
        <mat-radio-group formControlName="subjectAssignmentType" class="report-input-radio-flex assignment-type">
          <mat-radio-button
            *ngFor="let option of assignmentTypeOptions"
            [value]="option.value"
            (change)="onChangeAssignmentType($event)"
            class="report-input-radio-button"
            >{{ option.name }}</mat-radio-button
          >
        </mat-radio-group>
        <jaro-kit-validation-message [form]="form" [controlName]="'subjectAssignmentType'" [isRequired]="true"></jaro-kit-validation-message>
      </div>
    </div>
    <div class="col-md-6 pl-15 pe-0">&nbsp;</div>
    <div class="col-md-12 pl-15 pr-0">
      <div class="report-input-form-field">
        <mat-form-field *ngIf="isShowAssignmentTypeDescribe" class="jaro-kit-textarea" appearance="outline">
          <mat-label>Assignment Type Other (describe)</mat-label>
          <textarea
            type="text"
            formControlName="subjectAssignmentTypeDescription"
            placeholder="Assignment Type Other (describe)"
            matInput
            rows="4"
          ></textarea>
        </mat-form-field>
        <jaro-kit-validation-message [form]="form" [controlName]="'subjectAssignmentTypeDescription'" [isRequired]="true"></jaro-kit-validation-message>
      </div>
    </div>
  </div>
</ng-container>
