export const TimeZoneNamesMapping = {
    A : 'Alpha Time Zone',
    ACDT : 'Australian Central Daylight Time',
    ACST : 'Australian Central Standard Time',
    ACWT : 'Australian Central Western Time',
    ADT : 'Atlantic Daylight Time',
    ACT : 'Acre Time',
    AEDT : 'Australian Eastern Daylight Time',
    AEST : 'Australian Eastern Standard Time',
    AFT : 'Afghanistan Time',
    AKDT : 'Alaska Daylight Time',
    AKST : 'Alaska Standard Time',
    ALMT : 'Alma-ata Time',
    AMT : 'Armenia Time',
    AMST : 'Armenia Summer Time',
    ANAT : 'Anadyr Time ',
    ANAST : 'Anadyr Summer Time',
    AQTT : 'Aqtobe Time',
    ART : 'Argentina Time',
    AST : 'Atlantic Standard Time',
    AWDT : 'Australian Western Daylight Time',
    AWST : 'Australian Western Standard Time',
    AZOT : 'Azores Time',
    AZOST : 'Azores Summer Time',
    AZT : 'Azerbaijan Time',
    AZST : 'Azerbaijan Summer Time',
    B : 'Bravo Time Zone',
    BNT : 'Brunei Time',
    BDT : 'Bangladesh Time',
    BOT : 'Bolivia Time',
    BRT : 'Brasilia Time',
    BRST : 'Brasilia Summer Time',
    BST : 'British Summer Time',
    BTT : 'Bhutan Time',
    C : 'Charlie Time Zone',
    CAST : 'Casey Time',
    CAT : 'Central Africa Time',
    CCT : 'Cocos Islands Time',
    CDT : 'Central Daylight Time',
    CEDT : 'Central European Daylight Time',
    CEST : 'Central European Summer Time',
    CET : 'Central European Time',
    CHADT : 'Chatham Island Daylight Time',
    CHAST : 'Chatham Island Standard Time',
    CHOT : 'Choibalsan Time',
    CHOST : 'Choibalsan Summer Time',
    CHsT : 'Chamorro Standard Time',
    CHUT : 'Chuuk Time',
    CIT : 'Central Indonesian Time',
    CKT : 'Cook Island Time',
    CLST : 'Chile Summer Time',
    CLT : 'Chile Standard Time',
    COT : 'Columbia Time',
    CST : 'Central Standard Time',
    CVT : 'Cape Verde Time',
    CWST : 'Central Western Australia Time',
    CXT : 'Christmas Island Time',
    D : 'Delta Time Zone',
    DAVT : 'Davis Time',
    DDUT : 'Dumont D \'urville Time',
    DST : 'Daylight Saving Timevaries',
    E : 'Echo Time Zone',
    EASST : 'Eastern Island Summer Time',
    EAST : 'Eastern Island Standard Time',
    EAT : 'East Africa Time',
    ECT : 'Ecuador Time',
    EDT : 'Eastern Daylight Time',
    EEDT : 'Eastern European Daylight Time',
    EEST : 'Eastern European Summer Time',
    EET : 'Eastern European Time',
    EGT : 'Eastern Greenland Time',
    EGST : 'Eastern Greenland Summer Time',
    EST : 'Eastern Standard Time',
    EIT : 'Eastern Indonesian Time',
    F : 'Foxtrot Time Zone',
    FET : 'Further-eastern European Time',
    FJT : 'Fiji Time',
    FJST : 'Fiji Summer Time',
    FKST : 'Falkland Islands Summer Time',
    FKT : 'Falkland Islands Time',
    FNT : 'Fernando De Noronha',
    G : 'Golf Time Zone',
    GALT : 'Galapagos Time',
    GAMT : 'Gambier Time',
    GET : 'Georgia Standard Time',
    GFT : 'French Guiana Time',
    GILT : 'Gilbert Island Time',
    GMT : 'Greenwich Mean Time',
    GST : 'Gulf Standard Time',
    GYT : 'Guyana Time',
    H : 'Hotel Time Zone',
    HADT : 'Hawaii-aleutian Daylight Time',
    HAST : 'Hawaii-aleutian Standard Time',
    HKT : 'Hong Kong Time',
    HOVT : 'Hovd Time ',
    HOVST : 'Hovd Summer Time',
    HST : 'Hawaii Standard Time',
    I : 'India Time Zone',
    ICT : 'Indochina Time',
    IDT : 'Israel Daylight Time',
    IOT : 'Indian Chagos Time',
    IRDT : 'Iran Daylight Time',
    IRKT : 'Irkutsk Time',
    IRKST : 'Irkutsk Summer Time',
    IRST : 'Iran Standard Time',
    IST : 'Indian Standard Time',
    JST : 'Japan Standard Time',
    K : 'Kilo Time Zone',
    KGT : 'Kyrgyzstan Time',
    KOST : 'Kosrae Time',
    KRAT : 'Krasnoyarsk Time',
    KRAST : 'Krasnoyarsk Summer Time',
    KST : 'Korea Standard Time',
    KUYT : 'Kuybyshev Time',
    L : 'Lima Time Zone',
    LHDT : 'Lord Howe Daylight Time',
    LHST : 'Lord Howe Standard Time',
    LINT : 'Line Islands Time',
    M : 'Mike Time Zone',
    MAGT : 'Magadan Time',
    MAGST : 'Magadan Summer Time',
    MART : 'Marquesas Time',
    MAWT : 'Mawson Station Time',
    MDT : 'Mountain Daylight Time',
    MeST : 'Metlakatla',
    MHT : 'Marshall Islands Time',
    MIST : 'Macquarie Island Station Time',
    MMT : 'Myanmar Time',
    MSD : 'Moscow Summer Time',
    MSK : 'Moscow Standard Time',
    MST : 'Mountain Standard Time',
    MUT : 'Mauritius Time',
    MVT : 'Maldives Time',
    MYT : 'Malaysia Time',
    N : 'November Time Zone',
    NCT : 'New Caledonia Time',
    NDT : 'Newfoundland Daylight Time',
    NFT : 'Norfolk Time',
    NOVT : 'Novosibirsk Time',
    NOVST : 'Novosibirsk Summer Time',
    NPT : 'Nepal Time',
    NRT : 'Nauru Time',
    NST : 'Newfoundland Standard Time',
    NT : 'Newfoundland Time',
    NUT : 'Niue Time',
    NZDT : 'New Zealand Daylight Time',
    NZST : 'New Zealand Standard Time',
    O : 'Oscar Time Zone',
    OMST : 'Omsk Standard Time',
    OMSST : 'Omsk Summer Time',
    ORAT : 'Oral Time',
    P : 'Papa Time Zone',
    PDT : 'Pacific Daylight Time',
    PET : 'Peru Time',
    PETT : 'Kamchatka Time',
    PETST : 'Kamchatka Summer Time',
    PGT : 'Papua New Guinea Time',
    PHT : 'Philippine Time',
    PHOT : 'Phoenix Island Time',
    PKT : 'Pakistan Standard Time',
    PMDT : 'Pierre & Miquelon Daylight Time',
    PMST : 'Pierre & Miquelon Standard Time',
    PONT : 'Pohnpei Time',
    PST : 'Pacific Standard Time',
    PWT : 'Palau Time',
    PYT : 'Paraguay Time',
    PYST : 'Paraguay Summer Time',
    Q : 'Quebec Time Zone',
    QYZT : 'Qyzylorda Time',
    R : 'Romeo Time Zone',
    RET : 'Reunion Time',
    ROTT : 'Rothera',
    S : 'Sierra Time Zone',
    SAKT : 'Sakhalin Time',
    SAMT : 'Samara Time',
    SAST : 'South Africa Standard Time',
    SBT : 'Solomon Islands Time',
    SCT : 'Seychelles Time',
    SGT : 'Singapore Time',
    SRT : 'Suriname Time',
    SLT : 'Sri Lanka Time',
    SLST : 'Sri Lanka Time',
    SRET : 'Srednekolymsk Time',
    SST : 'Samoa Standard Time',
    SYOT : 'Syowa',
    T : 'Tango Time Zone',
    TAHT : 'Tahiti Time',
    TFT : 'French Southern and Antarctic Territories Time',
    TJT : 'Tajikistan Time',
    TKT : 'Tokelau Time',
    TLT : 'East Timor Time',
    TMT : 'Turkmenistan Time',
    TOT : 'Tonga Time',
    TRT : 'Turkey Time or Turkish Time',
    TRUT : 'Truk Time',
    TVT : 'Tuvalu Time',
    U : 'Uniform Time Zone',
    ULAT : 'Ulaanbaatar Time',
    ULAST : 'Ulaanbaatar Summer Time',
    UTC : 'Coordinated Universal Time',
    UYST : 'Uruguay Summer Time',
    UYT : 'Uruguay Standard Time',
    UZT : 'Uzbekistan Time',
    V : 'Victor Time Zone',
    VET : 'Venezuelan Standard Time',
    VLAT : 'Vladivostok Time',
    VLAST : 'Vladivostok Summer Time',
    VOLT : 'Volgograd Time',
    VUT : 'Vanuatu Time',
    W : 'Whiskey Time Zone',
    WAKT : 'Wake Island Time',
    WAT : 'West Africa Time',
    WAST : 'West Africa Summer Time',
    WDT : 'Western Daylight Time',
    WEDT : 'Western European Daylight Time',
    WEST : 'Western European Summer Time',
    WET : 'Western European Time',
    WFT : 'Wallis and Futuna Time',
    WGT : 'Western Greenland Time',
    WGST : 'Western Greenland Summer Time',
    WIB : 'Western Indonesian Time',
    WIT : 'Eastern Indonesian Time',
    WITA : 'Central Indonesian Time',
    WST : 'Western Standard Time',
    WT : 'Western Sahara Standard Time',
    X : 'X-ray Time Zone',
    Y : 'Yankee Time Zone',
    YAKT : 'Yakutsk Time',
    YAKST : 'Yakutsk Summer Time',
    YAP : 'Yap Time',
    YEKT : 'Yekaterinburg Time',
    YEKST : 'Yekaterinburg Summer Time',
};