<div
  [ngSwitch]="gridCellType"
  [ngClass]="{
    'comparable-grid-cell': true
  }"
>

  <ng-template [ngSwitchCase]="'Subject'">
    <div class="comparable-grid-cell-subject">
      <ng-container *ngIf="!isSelected || field.type != 'Custom'">
        <jaro-kit-comparable-grid-input-view-mode
          [field]="field"
          [(comparableDto)]="comparableDto"
          [(value)]="value"
          [isSelected]="isSelected"
          [lineItems]="lineItems"
        ></jaro-kit-comparable-grid-input-view-mode>
      </ng-container>

      <ng-container *ngIf="isSelected && field.type == 'Custom'">
        <div class="comparable-grid-cell-subject-edit">
          <mat-form-field appearance="outline">
            <mat-label>Custom Field Name</mat-label>
            <input
              matInput
              [(ngModel)]="field.label"
              autocomplete="off"
              (change)="changeCustomField()"
            />
          </mat-form-field>

          <mat-form-field appearance="outline">
            <mat-label>Custom Field Value</mat-label>
            <input matInput [(ngModel)]="value" autocomplete="off" (change)="changeCustomField()" />
          </mat-form-field>
        </div>
      </ng-container>
    </div>
  </ng-template>

  <ng-template [ngSwitchCase]="'Comparable'">
    <div
      [ngClass]="{
        'comparable-grid-cell-item': true,
        'comparable-grid-cell-item-highlight': isHighlightAdjustment
      }"
    >
      <div class="comparable-grid-cell-description">
        <jaro-kit-comparable-grid-input-view-mode
          [field]="field"
          [(comparableDto)]="comparableDto"
          [(value)]="value"
          [lineItems]="lineItems"
          *ngIf="!isSelected || field.type != 'Custom'"
        ></jaro-kit-comparable-grid-input-view-mode>

        <mat-form-field *ngIf="isSelected && field.type == 'Custom'" appearance="outline">
          <mat-label>{{ field.label }}</mat-label>
          <input matInput [(ngModel)]="value" autocomplete="off" (change)="changeValue()" />
        </mat-form-field>
      </div>

      <div class="comparable-grid-cell-adjustment">
        <ng-container *ngIf="!isSelected; else selectedCell">
          <p class="comparable-grid-cell-label">Adjustment +/-</p>
          <div *ngIf="!isHighlightAdjustment">
              <p
              class="comparable-grid-cell-adjustment-value"
              [ngClass]="{
                'comparable-grid-cell-adjustment-positive-value': totalAdjustmentValue > 0,
                'comparable-grid-cell-adjustment-negative-value': totalAdjustmentValue < 0
              }"
            >
              {{ totalAdjustment | currency: 'USD':'symbol':'1.0-2' }}
            </p>
          </div>
          <div *ngIf="isHighlightAdjustment">
            <p class="validation-error">Adjustment value is required</p>
          </div>
        </ng-container>

        <ng-template #selectedCell>
          <jaro-kit-comparable-grid-input-edit-mode *ngIf="field.type != 'NestedItems'"
            [field]="field"
            [(comparableDto)]="comparableDto"
            [(value)]="totalAdjustmentValue"
            [lineItems]="lineItems"
            [isAdjustment]="true"
            (onChangeAdjustmentValue)="changeAdjustmentValue($event)"
            (partialUpdate)="onPartialUpdate($event)"
          ></jaro-kit-comparable-grid-input-edit-mode>
          <jaro-kit-comparable-grid-input-edit-mode *ngIf="field.type == 'NestedItems'"
            [field]="field"
            [(comparableDto)]="comparableDto"
            [(value)]="totalAdjustmentValue"
            [lineItems]="lineItems"
            [isAdjustment]="true"            
            (onChangeNestedItemAdjustmentValue)="changeNestedItemAdjustmentValue($event)"
            (partialUpdate)="onPartialUpdate($event)"
          ></jaro-kit-comparable-grid-input-edit-mode>
        </ng-template>
      </div>
    </div>
  </ng-template>

  <ng-template [ngSwitchCase]="'ExtraAttribute'">
    <div class="comparable-grid-cell-extra-attribute">
      <jaro-kit-comparable-grid-input-view-mode
        [field]="field"
        [(comparableDto)]="comparableDto"
        [(value)]="value"
      ></jaro-kit-comparable-grid-input-view-mode>
    </div>
  </ng-template>
</div>
