import { Confirmation } from '@abp/ng.theme.shared';
import { HttpClient, HttpEventType } from '@angular/common/http';
import { Component, Inject, OnInit } from '@angular/core';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { ReportVersionDto } from '@proxy/appraisal/report/reports/v1';
import { OrderAssignmentDto } from '@proxy/ascent/order/order-assignment/order-assignments';
import {
  DocumentWithFileCollectionDto,
  FileVersionDto,
  ResponseDto
} from '@proxy/publication/document/documents/v1';
import { isEmpty, isEqual } from 'lodash-es';
import { ReportsServiceInterface } from 'src/app/interface/bff/activity/reports-service-interface';
import { DocumentsServiceInterface } from 'src/app/interface/publication/document/documents/documents-interface.service';
import { FeatureNames } from 'src/app/shared/enums/report-input.enum';
import InjectionSymbol from 'src/app/shared/injection/injection-symbol';
import { MessageService } from 'src/app/shared/services/message.service';
import { SketchDialogComponent } from './sketch-dialog/sketch-dialog.component';

@Component({
  selector: 'jaro-kit-report-input-sketch',
  templateUrl: './sketch.component.html',
  styleUrls: ['./sketch.component.scss'],
})
export class SketchComponent implements OnInit {
  orderAssignmentId: string;
  currentOrderAssignment: OrderAssignmentDto;
  reportVersion: number;
  reportId: string;
  reportVersionDto: ReportVersionDto;
  isLoading: boolean = false;
  feature = FeatureNames.Sketch;

  sketchDto: DocumentWithFileCollectionDto;
  sketchThumbnail: string;
  sketchJson: any;
  downloadSketchThumbnailRetryCount: number = 0;

  constructor(
    public matDialog: MatDialog,
    @Inject(InjectionSymbol.DocumentsService) private documentApiService: DocumentsServiceInterface,
    @Inject(InjectionSymbol.ActivityReportsService)
    public reportsService: ReportsServiceInterface,
    private msgService: MessageService,
    private http: HttpClient
  ) {}

  ngOnInit(): void {
    this.getSketchDocument();
  }

  getSketchDocument() {
    this.isLoading = true;
    this.documentApiService.getAllWithFileVersions(this.orderAssignmentId).subscribe({
      next: (documents: DocumentWithFileCollectionDto[]) => {
        const sketchDto = documents.find((item) => item.fileType === 'sketch');
        this.sketchDto = sketchDto;

        if (!sketchDto || isEmpty(sketchDto.files)) {
          this.isLoading = false;
          return;
        }

        const latestSketchFile = sketchDto.files.reduce(function (prev, current) {
          if (current.version > prev.version) {
            return current;
          } else {
            return prev;
          }
        });
        if (latestSketchFile) {
          const thumbnail = (latestSketchFile.thumbnails || []).find(
            (item) => item.size == 'large'
          );
          this.sketchThumbnail = thumbnail.links[0].href;

          this.http.get(latestSketchFile.links[0].href).subscribe({
            next: (sketchJson) => {
              this.sketchJson = sketchJson;
              this.isLoading = false;
            },
            error: (err) => this.logActionError(err),
          });
        } else {
          this.isLoading = false;
        }
      },
      error: (err) => this.logActionError(err),
    });
  }

  openSketch() {
    if (this.isLoading) {
      return;
    }
    const dialogConfig = new MatDialogConfig();
    dialogConfig.data = {
      sketchJson: this.sketchJson,
    };
    dialogConfig.panelClass = 'sketch-dialog';
    this.downloadSketchThumbnailRetryCount = 0;
    const viewSketchDialogRef = this.matDialog.open(SketchDialogComponent, dialogConfig);
    viewSketchDialogRef.afterClosed().subscribe({
      next: (sketchData: any) => {
        document.onkeydown = null;
        window.onresize = null;
        const sketchJson = JSON.parse(sketchData);
        if (sketchData && !isEqual(sketchJson, this.sketchJson)) {
          const sketchFile = new File([sketchData], 'sketch.json', {
            type: 'application/json',
          });
          this.sketchJson = sketchJson;
          this.saveSketchFile(sketchFile);
        }
      },
    });
  }

  private saveSketchFile(sketchFile: File) {
    this.documentApiService.createNewFileVersion(this.sketchDto.id, this.orderAssignmentId).subscribe({
      next: (fileAdded) => {
        this.uploadFileData(fileAdded, sketchFile);
      },
    });
  }

  private uploadFileData(fileAdded: FileVersionDto, sketchFile: File) {
    this.isLoading = true;
    this.http
      .put(fileAdded.links[0].href, sketchFile, {
        reportProgress: true,
        observe: 'events',
      })
      .subscribe({
        next: (event) => {
          if (event.type === HttpEventType.Response) {
            if (event.ok) {
              this.updateSketchOfReportInput(fileAdded.version);

              this.waitAndGetSketchThumbnail(fileAdded.version, 2000);
            } else {
              this.showErrorMessage();
            }
          }
        },
        error: (error) => {
          this.msgService.error(error).subscribe();
        },
      });
  }

  private updateSketchOfReportInput(fileVersion: number) {
    const sketchUpdate = {};
    sketchUpdate[
      'sketchDocument'
    ] = `{'fileVersion': ${fileVersion}, 'documentId': '${this.sketchDto.id}' }`;
    const partialUpdateReportRequest = {
      orderAssignmentId: this.orderAssignmentId,
      updates: sketchUpdate,
      concurrencyStamp: null
    };
    this.reportsService
      .partialUpdate(this.reportId, this.reportVersion, partialUpdateReportRequest)
      .subscribe({
        next: () => {
          // do nothing
        },
        error: (err) => this.logActionError(err),
      });
  }

  private waitAndGetSketchThumbnail(fileVersion: number, timer?: number) {
    setTimeout(() => {
      this.downloadSketchThumbnailRetryCount++;
      this.documentApiService
        .getFile(this.sketchDto.id, this.orderAssignmentId, fileVersion)
        .subscribe({
          next: (sketchFileDto: ResponseDto) => {
            const thumbnail = (sketchFileDto.thumbnails || []).find((item) => item.size == 'large');

            if (thumbnail && thumbnail.links?.length > 0 && thumbnail.links[0].href) {
              this.sketchThumbnail = thumbnail.links[0].href;
              this.isLoading = false;
            } else {
              if (this.downloadSketchThumbnailRetryCount <= 8) {
                this.waitAndGetSketchThumbnail(
                  fileVersion,
                  this.downloadSketchThumbnailRetryCount * 2000
                );
              } else {
                this.showErrorMessage();
              }
            }
          },
          error: (err) => this.logActionError(err),
        });
    }, timer);
  }

  private showErrorMessage() {
    this.isLoading = false;
    const options: Partial<Confirmation.Options> = {
      hideCancelBtn: true,
      yesText: 'Close',
    };
    this.msgService
      .error('Could not save the sketch - please try again', null, null, options)
      .subscribe((_status: Confirmation.Status) => {
        this.isLoading = false;
        this.sketchThumbnail = null;
      });
  }

  private logActionError(err: any): void {
    this.isLoading = false;
    this.sketchThumbnail = null;
    this.msgService.error(err).subscribe((_status: Confirmation.Status) => {
    });
  }
}
