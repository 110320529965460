import { Component, Input } from '@angular/core';
import { FormGroup } from '@angular/forms';

@Component({
  selector: 'halo-global-one-unit-housing',
  templateUrl: './one-unit-housing.component.html',
  styleUrls: ['./one-unit-housing.component.scss'],
})
export class OneUnitHousingComponent {
  @Input() form: FormGroup;
}
