import { Injectable } from '@angular/core';
import { VirtualKeypadActionPrototypes } from './virtual-keypad.prototype';

@Injectable({
  providedIn: 'root',
})
export class VirtualKeypadService {
  getVirtualKeypadActionItems() {
    return VirtualKeypadActionPrototypes;
  }
}
