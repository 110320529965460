import { Component, EventEmitter, Input, Output } from '@angular/core';
import { OrderAssignmentDto } from '@proxy/ascent/order/order-assignment/order-assignments';

@Component({
  selector: 'jaro-kit-order-search-results-item',
  templateUrl: './order-search-results-item.component.html',
  styleUrls: ['./order-search-results-item.component.scss'],
})
export class OrderSearchResultsItemComponent {
  @Input() orderAssignment: OrderAssignmentDto;
  orderAddress: string;
  @Output() onClick = new EventEmitter<boolean>();

  ngOnInit(): void {

    this.orderAddress = this.orderAssignment.orderData.address.fullAddressFormatted
  }
}
