import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { DataTableModel, PropertySearchResponse, StatisticsModel } from 'src/app/proxy-adapter/property/property-job/models';
import { FacetFilterComponentType, FacetFilterLayoutColumn, FacetFilterModel } from '../models/facet/facet-filter-model';
import { FacetFilterModelTuple } from '../models/facet/facet-filter-model-tuple';
import { FacetRangeModel } from '../models/facet/facet-range-model';
import { FacetScoreModel } from '../models/facet/facet-score-model';

// Shows the subject and search results on the map for   the latitude and longitude coordinates provided
//
// If either the subject latitude or longitude is not zero or is null or undefined
// then the fallback subject address is searched for using a google api and the resultant
// latitude and longitude are used for the subject on the map.
@Component({
  selector: 'jaro-kit-market-facet-filter-panel',
  templateUrl: './market-facet-filter-panel.component.html',
  styleUrls: ['./market-facet-filter-panel.component.scss'],
})
export class MarketFacetFilterPanelComponent implements OnInit {
  @Input() defaultSearchResponse: BehaviorSubject<PropertySearchResponse>;
  @Input() filteredSearchResponse: BehaviorSubject<PropertySearchResponse>;
  @Input() isLoading: boolean = true;
  @Output() onFilterSelectionUpdated = new EventEmitter<Record<string, Partial<FacetFilterModel>>>();
  facetFilterSubject = new BehaviorSubject<FacetFilterModel[]>(null);
  defaultFacetFilterRecord: Record<string, FacetFilterModel>;
  filteredFacetFilterRecord: Record<string, FacetFilterModel>;
  selectedFacetFilterRecord: Record<string, FacetFilterModel>;
  leftColumnFacetFilters : FacetFilterModelTuple[] = [];
  rightColumnFacetFilters: FacetFilterModelTuple[] = [];
  debouncedFilterSelectionUpdated: (this: any, ...args: any[]) => any;

  readonly facetFilters: FacetFilterModel[] = [
    // Left Column
    new FacetFilterModel("condition", "Condition", FacetFilterComponentType.rangeSlider, FacetFilterLayoutColumn.left),
    new FacetFilterModel("quality", "Quality", FacetFilterComponentType.rangeSlider, FacetFilterLayoutColumn.left),
    new FacetFilterModel("bedroomsTotal", "Bedrooms", FacetFilterComponentType.rangeSlider, FacetFilterLayoutColumn.left),
    new FacetFilterModel("bathroomsTotal", "Bathrooms", FacetFilterComponentType.rangeSlider, FacetFilterLayoutColumn.left),
    new FacetFilterModel("livingArea", "Living Area", FacetFilterComponentType.rangeSlider, FacetFilterLayoutColumn.left),
    new FacetFilterModel("lotSizeSquareFeet", "Lot Size", FacetFilterComponentType.rangeSlider, FacetFilterLayoutColumn.left),
    new FacetFilterModel("closePrice", "Close Price", FacetFilterComponentType.rangeSlider, FacetFilterLayoutColumn.left),
    new FacetFilterModel("pricePerSquareFeet", "Price Sq Ft", FacetFilterComponentType.rangeSlider, FacetFilterLayoutColumn.left),
    new FacetFilterModel("yearBuilt", "Year Built", FacetFilterComponentType.rangeSlider, FacetFilterLayoutColumn.left),
    new FacetFilterModel("garageSpaces", "Garage Spaces", FacetFilterComponentType.rangeSlider, FacetFilterLayoutColumn.left),
    new FacetFilterModel("carportSpaces", "Carport Spaces", FacetFilterComponentType.rangeSlider, FacetFilterLayoutColumn.left),
    new FacetFilterModel("openParking", "Open Parking", FacetFilterComponentType.rangeSlider, FacetFilterLayoutColumn.left),
    new FacetFilterModel("listingContractMonthYear", "Contract Month", FacetFilterComponentType.checkboxList, FacetFilterLayoutColumn.left),
    new FacetFilterModel("standardStatus", "Listing Status", FacetFilterComponentType.checkboxList, FacetFilterLayoutColumn.left),
    new FacetFilterModel("storiesTotal", "Stories", FacetFilterComponentType.rangeSlider, FacetFilterLayoutColumn.left),

    // Right Column
    new FacetFilterModel("basementType", "Basement Type", FacetFilterComponentType.checkboxList, FacetFilterLayoutColumn.right),
    new FacetFilterModel("basement", "Basement Finish", FacetFilterComponentType.checkboxList, FacetFilterLayoutColumn.right),
    new FacetFilterModel("basementEntry", "Basement Entry", FacetFilterComponentType.checkboxList, FacetFilterLayoutColumn.right),
    new FacetFilterModel("elementarySchool", "Elementary School", FacetFilterComponentType.checkboxList, FacetFilterLayoutColumn.right),
    new FacetFilterModel("middleSchool", "Middle School", FacetFilterComponentType.checkboxList, FacetFilterLayoutColumn.right),
    new FacetFilterModel("highSchool", "High School", FacetFilterComponentType.checkboxList, FacetFilterLayoutColumn.right),
    new FacetFilterModel("subDivisionName", "Neighborhood", FacetFilterComponentType.checkboxList, FacetFilterLayoutColumn.right),
    new FacetFilterModel("style", "Design (Style)", FacetFilterComponentType.checkboxList, FacetFilterLayoutColumn.right),
    new FacetFilterModel("pool", "Pool", FacetFilterComponentType.checkboxList, FacetFilterLayoutColumn.right),
    new FacetFilterModel("adu", "ADU", FacetFilterComponentType.checkboxList, FacetFilterLayoutColumn.right),
    new FacetFilterModel("view", "View", FacetFilterComponentType.checkboxList, FacetFilterLayoutColumn.right),
    new FacetFilterModel("viewFactors", "View Factors", FacetFilterComponentType.checkboxList, FacetFilterLayoutColumn.right),
    new FacetFilterModel("location", "Location", FacetFilterComponentType.checkboxList, FacetFilterLayoutColumn.right),
    new FacetFilterModel("locationFactors", "Location Factors", FacetFilterComponentType.checkboxList, FacetFilterLayoutColumn.right)
  ];

  ngOnInit(): void {
    this.initFacetFilters();
  }

  public get FacetFilterComponentType() {
    return FacetFilterComponentType;
  }

  scoreToggled(_scoreRecord: Record<string, Partial<FacetScoreModel>>) {
    this.emitModelUpdatedWithDebounce();
  }

  selectedRangeUpdated(_range: FacetRangeModel)
  {
    this.emitModelUpdatedWithDebounce();
  }

  private emitModelUpdatedWithDebounce()
  {
    // Debounce 1.5 seconds in case multiple scores are being selected
    this.debouncedFilterSelectionUpdated = this.debouncedFilterSelectionUpdated || this.debounce(1500, () => this.onFilterSelectionUpdated.emit(this.selectedFacetFilterRecord), false);
    this.debouncedFilterSelectionUpdated();
  }

  private initFacetFilters()
  {
    this.filteredFacetFilterRecord = {};
    this.defaultFacetFilterRecord = {};
    this.selectedFacetFilterRecord = {};
    for(let filter of this.facetFilters)
    {
      this.filteredFacetFilterRecord[filter.id] = filter;
      this.defaultFacetFilterRecord[filter.id] = JSON.parse(JSON.stringify(filter)) as FacetFilterModel;
      this.selectedFacetFilterRecord[filter.id] = JSON.parse(JSON.stringify(filter)) as FacetFilterModel;
    }

    this.initFacetFilterPairs();

    this.defaultSearchResponse.subscribe((response: PropertySearchResponse) =>
    {
        this.pipeDefaultSearchResponse(response);
    });

    this.filteredSearchResponse.subscribe((response: PropertySearchResponse) =>
    {
        this.pipeFilteredSearchResponse(response);
    });
  }

  private pipeDefaultSearchResponse(response: PropertySearchResponse)
  {
    this.mapDefaultDataTablesToFilterRecord(response.attributeAggregations.dataTables);
    this.mapDefaultStatisticsToFilterRecord(response.attributeAggregations.statistics);

    this.facetFilterSubject.next(this.facetFilters);
  }

  private pipeFilteredSearchResponse(response: PropertySearchResponse)
  {
    this.mapFilteredDataTablesToFilterRecord(response.attributeAggregations.dataTables);
    this.mapFilteredStatisticsToFilterRecord(response.attributeAggregations.statistics);

    this.facetFilterSubject.next(this.facetFilters);
  }

  private mapDefaultDataTablesToFilterRecord(tables: DataTableModel[])
  {
    for(let dataTable of tables)
    {
      if(typeof this.defaultFacetFilterRecord[dataTable.id] === "undefined")
      {
        continue;
      }

      this.defaultFacetFilterRecord[dataTable.id].scores = dataTable.scores.map(score => new FacetScoreModel(score.title, score.frequency));

      if(this.selectedFacetFilterRecord[dataTable.id].scores?.length === 0)
      {
        this.selectedFacetFilterRecord[dataTable.id].scores = dataTable.scores.map(score => new FacetScoreModel(score.title, score.frequency));
      }
    }
  }

  private mapDefaultStatisticsToFilterRecord(stats: StatisticsModel[])
  {
    for(let stat of stats)
    {
      if(typeof this.defaultFacetFilterRecord[stat.id] === "undefined")
      {
        continue;
      }

      this.defaultFacetFilterRecord[stat.id].statistics = new FacetRangeModel(stat.min || 0, stat.max || 0);

      if(this.selectedFacetFilterRecord[stat.id].statistics == null)
      {
        this.selectedFacetFilterRecord[stat.id].statistics = new FacetRangeModel(null, null);
      }
    }
  }

  private mapFilteredDataTablesToFilterRecord(tables: DataTableModel[])
  {
    for(let dataTable of tables)
    {
      if(typeof this.filteredFacetFilterRecord[dataTable.id] === "undefined")
      {
        continue;
      }

      this.filteredFacetFilterRecord[dataTable.id].scores = dataTable.scores.map(score => new FacetScoreModel(score.title, score.frequency));
    }
  }

  private mapFilteredStatisticsToFilterRecord(stats: StatisticsModel[])
  {
    for(let stat of stats)
    {
      if(typeof this.filteredFacetFilterRecord[stat.id] === "undefined")
      {
        continue;
      }

      this.filteredFacetFilterRecord[stat.id].statistics = new FacetRangeModel(stat.min || 0, stat.max || 0);
    }
  }

  private initFacetFilterPairs() {
    this.leftColumnFacetFilters = [];
    this.rightColumnFacetFilters = [];
    (this.facetFilters || []).forEach((filter) => {
        const filterTuple: FacetFilterModelTuple = {
          filteredSearchModel: this.filteredFacetFilterRecord[filter.id],
          defaultSearchModel: this.defaultFacetFilterRecord[filter.id],
          selectedSearchModel: this.selectedFacetFilterRecord[filter.id]
        };

        if(filter.column === FacetFilterLayoutColumn.left)
        {
          this.leftColumnFacetFilters.push(filterTuple);
        }
        else
        {
          this.rightColumnFacetFilters.push(filterTuple);
        }
    });
  }

  // https://stackoverflow.com/questions/59104425/typescript-debounce-function-not-calling-function-passed-as-parameter
  readonly debounce = (debounceTime: number, fn: (...params: any[]) => any, immed: boolean = false) => {
    let timer: number | any | undefined = undefined;
    return function (this: any, ...args: any[]) {
      if (timer === undefined && immed) {
        fn.apply(this, args);
      }
      clearTimeout(timer);
      timer = setTimeout(() => fn.apply(this, args), debounceTime);
      return timer;
    }
  };
}
