import { Type } from '@angular/core';
import { SubjectSaleDto } from '@proxy/appraisal/report/reports/v1/form1004/models';
import { ReportComparableWithThumbnailDto } from '@proxy/bff/activity/reports/v1';

export class ReportComparableModel extends ReportComparableWithThumbnailDto {
  isSubject?: boolean;
  isVisible?: boolean = true;
  comparableIndex?: number;
  photo?:string;
  largePhoto?:string;
  netAdjustmentTotal?: number;
  grossAdjustmentTotal?: number;
  netAdjPercent?: number;
  adjustedSalePrice?: number;
  grossAdjPercent?: number;
  nestedItems?: Record<string, LineItemDto[]>;
  saleHistory?: SubjectSaleDto;
  timestamp?:string;
  transactionHistory?: SubjectSaleDto[];
}

export interface ReportComparableDto {
  upi: string;
  comparableSequenceId: number;
  similarityScore: number;
  photo?: ReportComparablePhoto;
  items: ReportComparableDataItemDto;
  customItems?: CustomReportComparableItemDto[];
}
export interface ReportComparableDataItemDto {
  proximityToSubject?: string;
  price?: number;
  salePricePerGrossLivingArea?: number;
  dataSources?: string[];
  verificationSources?: string[];
  time?: ReportComparableItemDto;
  location?: ReportComparableItemDto;
  saleOrFinancingConcession?: ReportComparableItemDto;
  leaseholdFeeSimple?: ReportComparableItemDto;
  site?: ReportComparableItemDto;
  view?: ReportComparableItemDto;
  design?: ReportComparableItemDto;
  qualityOfConstruction?: ReportComparableItemDto;
  actualAge?: ReportComparableItemDto;
  condition?: ReportComparableItemDto;
  aboveGradeRoomCount?: ReportComparableItemDto;
  basementAndFinishedBelowGradeAreaSize?: ReportComparableItemDto;
  functionalUtility?: ReportComparableItemDto;
  daysOnMarket:string;
}

export interface ReportComparablePhoto {
  isFileAvailable: boolean;
  href: string;
  rel: string;
  method: string;
}

export interface ReportComparableItemDto {
  value: string;
  uom?: string;
  adjustment?: number;
  lineItems?: LineItemDto[];
}

export interface LineItemDto {
  name?: string;
  label?: string;
  path?:string;
  value?: string;
  adjustment?: number;
  inputType?: string;
  isDisabled?: boolean;
  isOptional?: boolean;
}

export interface BreakdownDto {
  adjustedValuePerUnit: number;
  numberOfUnits: number;
  uom: string;
}

export interface CustomReportComparableItemDto {
  name?: string;
  displayName?: string;
  value?: string;
  uom?: string;
  adjustment?: number;
  totalAdjustment?: number;
  adjustmentOverridden?: boolean;
  lineItems?: LineItemDto[];
}

export interface ReportComparableFieldConfig {
  key: string;
  type?: ReportComparableFieldType;
  label?: string;
  description?: string;
  path: string;
  adjustmentPath?: string;
  isVisible?: boolean;
  isDisabled?: boolean;
  isAdjustmentRequired?: boolean;
  inputType?: string;
  adjustmentType?: string;
  format?: ReportComparableFieldFormat;
  lineItems?: LineItemDto[];
  nestedItems?: LineItemDto[];
  isCustom?: boolean;
  isNotApplicableSubject?: boolean;
  isExtraAttribute?: boolean;
  indexGroup?:number;
  adjustmentOverridden?: boolean;
}

export enum ReportComparableFieldType {
  ReadOnly = 'ReadOnly',
  Item = 'Items',
  NestedItems = 'NestedItems',
  Custom = 'Custom',
  ExtraAttribute = 'ExtraAttribute',
  TotalAdjustment = 'TotalAdjustment'
}

export interface ReportComparableFieldFormat {
  pipeType?: Type<any>;
  pipeArgs?: any[];
}
