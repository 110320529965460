import { ReportInputDataFormFieldModel } from 'src/app/features/shared/forms/models/report-input-data-field-form-model/report-input-date-field-form-model';
import { YesNoFormatPipe } from 'src/app/shared/pipes/yes-no-format/yes-no-format.pipe';

export const ReportInputTemplateConfigUspapSection: ReportInputDataFormFieldModel[] = [
  {
    formControlName: 'uspap_areTherePerformedServicesLast3Years',
    format: {
      pipeType: YesNoFormatPipe,
    },
  }
];
