<div class="report-input-label-container">
  <span *ngIf="field.label && !isWhitespaceLabel" class="report-input-label">{{ field.label }}</span>
  <span *ngIf="field.label && isWhitespaceLabel" class="report-input-label">&nbsp;</span>
  <div
    [ngClass]="{
      'report-input-value': field.type !== reportInputFieldType.Custom,
      'report-input-value-disable': field.isDisabled
    }"
    [ngSwitch]="field.type"
    *ngIf="field.type !== reportInputFieldType.Label && !isEmptyValue"
  >
    <span *ngSwitchCase="reportInputFieldType.Date">
      {{ field.value | dateTimeFormat: field.dateConfig?.displayFormat ?? 'MM/DD/YYYY' }}
    </span>

    <span *ngSwitchCase="reportInputFieldType.Currency">
      {{ field.value | currency: 'USD':'symbol':'1.0-2' }}
    </span>

    <span *ngSwitchCase="reportInputFieldType.ComboBox">
      {{ field.value | arrayFormat: ', ' }}
    </span>

    <div *ngSwitchCase="reportInputFieldType.Custom">
      <jaro-kit-dynamic-component
        [componentName]="field.componentName"
        [reportDto]="reportDto"
        [reportInputForm]="reportInputForm"
        [section]="section"
        [subSection]="subSection"
        [isEditMode]="false"
        [feature]="feature"
      ></jaro-kit-dynamic-component>
    </div>

    <span *ngSwitchDefault>
      {{ field.value | dynamicPipe: field.format?.pipeType:field.format?.pipeArgs }}
    </span>
  </div>

  <div
    [ngClass]="{
      'report-input-value': true,
      'report-input-value-disable': field.isDisabled
    }"
    *ngIf="field.type !== reportInputFieldType.Label && isEmptyValue"
  >
    <span>-</span>
  </div>
</div>
