import { ReportInputDataFormFieldModel } from 'src/app/features/shared/forms/models/report-input-data-field-form-model/report-input-date-field-form-model';
import { YesNoFormatPipe } from 'src/app/shared/pipes/yes-no-format/yes-no-format.pipe';
import { ReportInputTemplateConfigAdditionalObservationsSection } from './report-input-template-config-additional-observations-section.prototype';
import { ReportInputTemplateConfigAppraisalUpdateSection } from './report-input-template-config-appraisal-update-section.prototype';
import { ReportInputTemplateConfigCarStorageSection } from './report-input-template-config-car-storage-section.prototype';
import { ReportInputTemplateConfigContractSection } from './report-input-template-config-contract-section.prototype';
import { ReportInputTemplateConfigCostApproachSection } from './report-input-template-config-cost-approach-section.prototype';
import { ReportInputTemplateConfigExteriorSection } from './report-input-template-config-exterior-section.prototype';
import { ReportInputTemplateConfigFoundationSection } from './report-input-template-config-foundation-section.prototype';
import { ReportInputTemplateConfigInteriorSection } from './report-input-template-config-interior-section.prototype';
import { ReportInputTemplateConfigNarrativeCommentSection } from './report-input-template-config-narrative-comment-section.prototype';
import { ReportInputTemplateConfigReconciliationSection } from './report-input-template-config-reconciliation-section.prototype';
import { ReportInputTemplateConfigSiteSection } from './report-input-template-config-site-section.prototype';
import { ReportInputTemplateConfigSubjectSection } from './report-input-template-config-subject-section.prototype';
import { ReportInputTemplateConfigSupervisorCertificationSection } from './report-input-template-config-supervisor-certification-section.prototype';
import { ReportInputTemplateConfigUspapSection } from './report-input-template-config-uspap-section.prototype';

export const ReportInputTemplateConfigCommon: ReportInputDataFormFieldModel[] = [
  {
    formControlName: 'saleHistoryIsSaleOrTransferHistoryResearchedDone',
    format: {
      pipeType: YesNoFormatPipe,
    },
  },
  {
    formControlName: 'PUDIsDeveloperInControlOfHoa',
    format: {
      pipeType: YesNoFormatPipe,
    },
  },
  {
    formControlName: 'PUDIsCreatedByBuildingConversion',
    format: {
      pipeType: YesNoFormatPipe,
    },
  },
  {
    formControlName: 'PUDIsAnyMultipleDwellingUnits',
    format: {
      pipeType: YesNoFormatPipe,
    },
  },
  {
    formControlName: 'PUDAreUnitsCommonElementsAndRecreationFacilitiesCompleted',
    format: {
      pipeType: YesNoFormatPipe,
    },
  },
  {
    formControlName: 'PUDIsAnyCommonElementsLeasedByOrToHoa',
    format: {
      pipeType: YesNoFormatPipe,
    },
  },
];

export const ReportInputTemplateFormFieldConfigs: ReportInputDataFormFieldModel[] = [
  ...ReportInputTemplateConfigSubjectSection,
  ...ReportInputTemplateConfigContractSection,
  ...ReportInputTemplateConfigExteriorSection,
  ...ReportInputTemplateConfigAppraisalUpdateSection,
  ...ReportInputTemplateConfigUspapSection,
  ...ReportInputTemplateConfigFoundationSection,
  ...ReportInputTemplateConfigSiteSection,
  ...ReportInputTemplateConfigCarStorageSection,
  ...ReportInputTemplateConfigInteriorSection,
  ...ReportInputTemplateConfigAdditionalObservationsSection,
  ...ReportInputTemplateConfigNarrativeCommentSection,
  ...ReportInputTemplateConfigFoundationSection,
  ...ReportInputTemplateConfigReconciliationSection,
  ...ReportInputTemplateConfigCostApproachSection,
  ...ReportInputTemplateConfigSupervisorCertificationSection,
  ...ReportInputTemplateConfigCommon,
];
