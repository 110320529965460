<ng-container *ngIf="form" [formGroup]="form">
  <div class="row subject-form">
    <div class="col-md-6 pl-0 pr-15 pb-15">
      <jaro-kit-date-picker
        [form]="form"
        dateControl="appraiserInspectionDate"
        titleDate="Inspection Date"
      ></jaro-kit-date-picker>
    </div>
  </div>
</ng-container>
