import { Confirmation } from '@abp/ng.theme.shared';
import { CdkDragDrop } from '@angular/cdk/drag-drop';
import { Component, ElementRef, EventEmitter, Input, Output, ViewChild } from '@angular/core';
import { ReportPhotoOwnerType, ReportPhotoSource } from '@proxy/appraisal/report/report-photos';
import { PartialUpdatePdfDocumentModel } from 'src/app/features/shared/forms/models/report-input-document/partial-update-pdf-document.model';
import { ReportPhotoResourceModel } from 'src/app/shared/dtos/report-input-resource/report-photo-resource.model';
import { ReportResourceType } from 'src/app/shared/enums/report-resource-type.enum';
import { PartialUpdatePhotoModel } from 'src/app/shared/models/photo/partial-update-photo.model';
import { FileUploading } from 'src/app/shared/services/file-uploading';
import { FilesUploaderService } from 'src/app/shared/services/files-uploader.service';
import { MessageService } from 'src/app/shared/services/message.service';

@Component({
  selector: 'jaro-kit-upload-file',
  templateUrl: './upload-file.component.html',
  styleUrls: ['./upload-file.component.scss'],
})
export class UploadFileComponent {
  @ViewChild('fileInput') fileInput: ElementRef;

  @Input() orderAssignmentId: string;
  @Input() section: string;
  @Input() sectionId: string;
  @Input() sectionUploadFiles: Map<string, FileUploading[]> = new Map();
  @Input() reportResourceType: ReportResourceType;
  @Input() reportPhotoOwnerType: ReportPhotoOwnerType = ReportPhotoOwnerType.Subject;
  @Input() externalPropertyId: string;
  @Input() title: string = 'images';
  @Input() fileTypeAccept: string;
  @Input() isMultiple: boolean = true;
  @Input() maximumOfFileToUpload: number = 10;

  @Output() onAddDocumentsToSection = new EventEmitter<PartialUpdatePdfDocumentModel[]>();
  @Output() onUploadPhotosToSection = new EventEmitter<PartialUpdatePhotoModel[]>();
  @Output() onAddPhotoToComparable = new EventEmitter<PartialUpdatePhotoModel>();
  @Output() onMovePhoto = new EventEmitter<PartialUpdatePhotoModel>();

  photosUploading: FileUploading[] = [];
  isUploading: boolean = false;
  documentSource: string = 'User';
  documentCategory: string = '';
  fileType: string = '';
  get typeAccept(): string {
    switch (this.fileTypeAccept) {
      case 'image':
        return 'image/*';
      case 'map':
        return 'application/pdf, image/png, image/jpeg, image/jpg';
      case 'pdf':
        return 'application/pdf';
      default:
        return '*';
    }
  }

  constructor(
    private filesUploaderService: FilesUploaderService,
    private msgService: MessageService
  ) {}


  uploadFileClick() {
    this.fileInput.nativeElement.dispatchEvent(new MouseEvent('click'));
  }

  public uploadFiles(files: FileList) {
    if (files && files.length > 0) {
      const fileUploads = this.getFileUploads(files);
      this.photosUploading = fileUploads;
      this.sectionUploadFiles.set(this.sectionId, this.photosUploading);

      this.filesUploaderService.onUploadingCompletion.subscribe({
        next: (filesUploaded: FileUploading[]) => {
          this.filesUploaderService.onUploadingCompletion.unsubscribe();
          this.filesUploaderService.resetEvent();

          if (this.reportResourceType === ReportResourceType.Document) {
            this.addDocumentToSection(filesUploaded);
          } else {
            this.addPhotoToSection(filesUploaded);
          }
        },
        error: (err) => this.logActionError(err),
      });

      const labels = [
        `document:type=${
          this.reportResourceType === ReportResourceType.Document ? 'document' : 'photo'
        }`,
        'photo:cover=false',
        `photo:section=${
          this.reportPhotoOwnerType === ReportPhotoOwnerType.Comparable
            ? 'comparable'
            : this.section
        }`,
        `photo:source=User`,
        `photo:title=`,
        `photo:externalPropertyId=${this.externalPropertyId ? this.externalPropertyId : null}`,
      ];
      if (this.reportResourceType === ReportResourceType.Document) {
        this.fileType = 'document';
        this.documentCategory = 'Miscellaneous';
      }
      else{
        this.fileType = 'photo';
        this.documentCategory =
          this.reportPhotoOwnerType === ReportPhotoOwnerType.Comparable
            ? 'Comparable Photo'
            : 'Subject Photo';
      }
      this.isUploading = true;
      for(let i = 0; i < fileUploads.length; i++){
        let fileUpload = fileUploads[i];
        this.filesUploaderService.uploadFiles([fileUpload], this.orderAssignmentId, this.getTitle(fileUpload.file), 'User', this.fileType, this.documentCategory, labels);
      }
    }

    this.fileInput.nativeElement.value = null;
  }

  private getFileUploads(files: FileList): FileUploading[] {
    const fileUploads = Array.from(files)
      .filter((file) => this.checkFileType(file))
      .map((file: File) => {
        const fileUpload = {
          file: file,
          isUploading: false,
          progress: 0,
          isSuccessful: false,
          docId: '',
          error: '',
          localUrl: '',
          fileName: file.name,
          title: this.getTitle(file),
        } as FileUploading;
        const reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = (_event) => {
          fileUpload.localUrl = reader.result;
        };
        return fileUpload;
      });
    return this.isMultiple
      ? fileUploads.splice(0, this.maximumOfFileToUpload)
      : fileUploads.splice(0, 1);
  }

  private addDocumentToSection(filesUploaded: FileUploading[]) {
    const documents = filesUploaded
      .filter((item) => item.isSuccessful)
      .map((fileUploaded) => {
        return {
          source: 'User',
          documentCategory: 'Miscellaneous',
          documentId: fileUploaded.docId,
          title: this.getTitle(fileUploaded.file),
          fileExtension: this.getFileExtension(fileUploaded.file),
        } as any;
      });

    this.isUploading = false;
    this.sectionUploadFiles.set(this.sectionId, []);
    this.onAddDocumentsToSection.emit(documents);
  }

  private addPhotoToSection(filesUploaded: FileUploading[]) {
    const photos = filesUploaded
      .filter((item) => item.isSuccessful)
      .map((fileUploaded) => {
        return {
          title: '',
          source: ReportPhotoSource.User,
          documentId: fileUploaded.docId,
          isCoverPhoto: false,
          isInReport: true,
          resourceUrl: fileUploaded.localUrl,
          photoOwnerType: this.reportPhotoOwnerType,
          sectionIds: [this.sectionId],
        } as ReportPhotoResourceModel;
      });

    this.isUploading = false;
    if (this.reportPhotoOwnerType === ReportPhotoOwnerType.Comparable) {
      // a comparable photo has only one photo
      this.onAddPhotoToComparable.emit(photos[0]);
    } else {
      this.sectionUploadFiles.set(this.sectionId, []);
      this.onUploadPhotosToSection.emit(photos);
    }
  }

  private logActionError(err: any): void {
    this.isUploading = false;
    this.msgService.error(err).subscribe((_status: Confirmation.Status) => {});
  }

  onDrop(event: CdkDragDrop<PartialUpdatePhotoModel[]>) {
    if (event.item.boundaryElement === ReportResourceType.Document) {
      const file = event.item.data;
      file.fileExtension = file.fileExtension || 'pdf';
      this.onAddDocumentsToSection.emit([file]);
    } else {
      this.onDropPhoto(event);
    }
  }

  private onDropPhoto(event: CdkDragDrop<PartialUpdatePhotoModel[]>) {
    const photo = event.item.data;
    photo.position = null;
    if (photo && photo.id) {
      if (event.item.boundaryElement === ReportResourceType.Photo) {
        //  Add photo with default position is Last
        this.onUploadPhotosToSection.emit([photo]);
      } else {
        photo.newSectionId = this.sectionId;
        this.onMovePhoto.emit(photo);
      }
    }
  }

  private checkFileType(file: File): boolean {
    if (this.fileTypeAccept) {
      let allowedFileExtensionRegexes = [];
      switch (this.fileTypeAccept) {
        case 'image':
          allowedFileExtensionRegexes.push(/image\//);
          break;
        case 'map':
          allowedFileExtensionRegexes.push(/image\//);
          allowedFileExtensionRegexes.push(/(\application\/pdf)$/i);
          break;
        case 'pdf':
          allowedFileExtensionRegexes.push(/(\application\/pdf)$/i);
          break;
        default:
          return true; // Return true as there is no file type checking required.
      }

      for (let regex of allowedFileExtensionRegexes) {
        if (regex.exec(file.type)) {
          return true;
        }
      }
    }
    return false;
  }

  private getTitle(file: File): string {
    const fileName = file.name;
    return fileName.substring(0, fileName.lastIndexOf('.'));
  }

  private getFileExtension(file: File): string {
    const fileName = file.name;
    return fileName.substring(fileName.lastIndexOf('.') + 1, fileName.length);
  }
}
