import { ErrorHandler, Injectable } from '@angular/core';
import { LogService } from 'src/app/instrumentation/app-insights/log.service';

// See https://devblogs.microsoft.com/premier-developer/angular-how-to-add-application-insights-to-an-angular-spa/
@Injectable({ providedIn: 'root' })
export class GlobalErrorHandlerService extends ErrorHandler {
  constructor(private logService: LogService) {
    super();
  }

  handleError(error: Error) {
    this.logService.logException(error); // Manually log exception
  }
}
