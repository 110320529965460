import { Confirmation } from '@abp/ng.theme.shared';
import { Component, EventEmitter, Inject, Input, OnDestroy, Output } from '@angular/core';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import {
  ReportGenerationRequestResponseDto,
  ReportGenerationStatusDto
} from '@proxy/appraisal/report/reports/v2';
import { ReportVersionWithLinksDto } from '@proxy/bff/activity/reports/v1';
import { ReportGenerationInterface } from 'src/app/interface/appraisal/report/report-generation.interface';
import { ReportFormat } from 'src/app/shared/enums/report-format.enum';
import InjectionSymbol from 'src/app/shared/injection/injection-symbol';
import { MessageService } from 'src/app/shared/services/message.service';
import { PreviewReportComponent } from '../preview-report/preview-report.component';

@Component({
  selector: 'jaro-kit-preview-report-button',
  templateUrl: './preview-report-button.component.html',
  styleUrls: ['./preview-report-button.component.scss'],
})
export class PreviewReportButtonComponent implements OnDestroy {
  @Input() orderAssignmentId: string;
  @Input() productName: string;
  @Input() reportVersionDto: ReportVersionWithLinksDto;
  @Output() onUpdated = new EventEmitter<void>();
  isFileDownloaded: boolean = false;
  totalTimes: number = 0;
  isLoading: boolean = false;

  constructor(
    @Inject(InjectionSymbol.ReportGenerationService)
    private reportGenerationService: ReportGenerationInterface,
    private msgService: MessageService,
    private matDialog: MatDialog
  ) {}

  ngOnDestroy(): void {
    this.isLoading = false;
  }

  private logActionError(err: any): void {
    this.isLoading = false;
    this.msgService.error(err).subscribe((_status: Confirmation.Status) => {});
  }

  generateAndPreviewReportForSubmit() {
    this.isLoading = true;
    const reportRequest = {
      reportFormat: ReportFormat.Pdf,
      orderAssignmentId: this.orderAssignmentId,
      concurrencyStamp: null,
    };
    this.reportGenerationService.generate(reportRequest).subscribe({
      next: (response: ReportGenerationRequestResponseDto) => {
        this.previewReportWhenGenerationCompleted(response.reportGenerationRequestId);
      },
      error: (err) => this.logActionError(err),
    });
  }

  private previewReportWhenGenerationCompleted(reportGenerationRequestId: string) {
    this.reportGenerationService.getGenerateReportFileStatus(reportGenerationRequestId).subscribe({
      next: (response: ReportGenerationStatusDto) => {
        switch (response.status) {
          case 'New':
            // Delay polling
            if (this.isLoading) {
              setTimeout(() => {
                this.previewReportWhenGenerationCompleted(reportGenerationRequestId);
              }, 1000);
            }

            break;
          case 'Error':
            this.isLoading = false;
            this.msgService.error(response.message);
            break;
          case 'Completed':
            this.isLoading = false;
            this.openDocumentPreview(response.url);
            break;
        }
      },
      error: (err) => this.logActionError(err),
    });
  }

  private openDocumentPreview(urlPDF: string) {
    const dialogConfig = new MatDialogConfig();
    dialogConfig.data = {
      orderAssignmentId: this.orderAssignmentId,
      urlPDF: urlPDF,
      reportVersionDto: this.reportVersionDto,
      productName: this.productName,
    };
    dialogConfig.panelClass = 'document-review-modal';
    dialogConfig.disableClose = true;
    let dialogRef = this.matDialog.open(PreviewReportComponent, dialogConfig);
    dialogRef.afterClosed().subscribe({
      next: (_response: any) => {},
    });
  }
}
