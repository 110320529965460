export class FacetRangeModel {
    id: string;

    min?: number;
    max?: number;

    constructor(min?: number, max?: number)
    {
        this.min = min;
        this.max = max;
    }
}