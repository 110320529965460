<ng-container>
  <div class="row">
    <div class="col-md-12 pl-0 pr-0">
      <p class="report-input-label">{{this.subSection.title || 'Additional Comments'}}</p>
      <div class="editor-container">
        <jaro-kit-editor [markDown]="markDown" [form]="form" [formControlName]="'narrativeCommentsAdditionalComments'"></jaro-kit-editor>
      </div>
    </div>
  </div>
</ng-container>
