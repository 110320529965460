import { Injectable } from '@angular/core';
import { DocumentsService } from '@proxy/publication/document/documents/v1';
import { DocumentsServiceInterface } from 'src/app/interface/publication/document/documents/documents-interface.service';

@Injectable({
  providedIn: 'root',
})
export class DocumentsServiceAdapter
  extends DocumentsService
  implements DocumentsServiceInterface {}
