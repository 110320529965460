import { Component, Inject, Input, OnInit } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { SubjectSaleDto } from '@proxy/appraisal/report/reports/v1/form1004';
import { ColumnMode } from '@swimlane/ngx-datatable';
import { ReportInputTemplateService } from 'src/app/features/shared/forms/providers/report-input-template.service';
import InjectionSymbol from 'src/app/shared/injection/injection-symbol';

@Component({
  selector: 'jaro-kit-sale-history-section1004',
  templateUrl: './sale-history-section1004.component.html',
  styleUrls: ['./sale-history-section1004.component.scss'],
})
export class SaleHistorySection1004Component implements OnInit {
  @Input() form: FormGroup;

  saleHistory: SubjectSaleDto[];
  ColumnMode = ColumnMode;

  constructor(
    @Inject(InjectionSymbol.ReportInputTemplateService)
    private reportInputTemplateService: ReportInputTemplateService
  ) {}

  ngOnInit(): void {
    this.saleHistory = this.form?.value['saleHistory'] || [];

    this.reportInputTemplateService.onCancel.subscribe((events) => {
      if (events?.includes('saleHistory')) {
        this.saleHistory = this.form?.value['saleHistory'] || [];
      }
    });
  }
}
