<div *ngIf="form" [formGroup]="form">
  <mat-form-field appearance="outline" class="jaro-kit-textarea">
    <mat-label>{{ field.label }}</mat-label>
    <textarea
      type="text"
      [formControlName]="field.formControlName"
      [placeholder]="field.label"
      matInput
      rows="4"
      [id]="field.formControlName"
    ></textarea>
  </mat-form-field>

  <jaro-kit-validation-message
    [form]="form"
    [controlName]="field.formControlName"
    [isRequired]="field.isRequired"
  ></jaro-kit-validation-message>
</div>
