import { Confirmation } from '@abp/ng.theme.shared';
import { Component, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
  selector: 'halo-global-confirmation',
  templateUrl: './confirmation.component.html',
  styleUrls: ['./confirmation.component.scss'],
})
export class ConfirmationComponent {

  constructor(
    public dialogRef: MatDialogRef<ConfirmationComponent>,
    @Inject(MAT_DIALOG_DATA)
    public data: {
      title?:string;
      message?:string;
      isVisibleCancelBtn?: boolean;
      isVisibleYesBtn?: boolean;
      isVisibleClose?: boolean;
      cancelText?: string;
      yesText?: string;
    }
  ) {}

  closeDialog() {
    this.dialogRef.close(Confirmation.Status.dismiss);
  }

  confirm(){
    this.dialogRef.close(Confirmation.Status.confirm);
  }

  reject(){
    this.dialogRef.close(Confirmation.Status.reject);
  }
}
