import { ReportInputDataComponentMapping } from 'src/app/features/report/shared/providers/report-input-layout-components.prototype';
import { ReportInputCheckBoxComponent } from '../components/checkbox/checkbox.component';
import { ReportInputDateComponent } from '../components/date/date.component';
import { ReportInputFreeTextComponent } from '../components/free-text/free-text.component';
import { ReportInputTextareaComponent } from '../components/textarea/textarea.component';

export const FormBuilderComponentMapping: ReportInputDataComponentMapping[] = [
  {
    componentName: 'text',
    componentType: ReportInputFreeTextComponent,
  },
  {
    componentName: 'date',
    componentType: ReportInputDateComponent,
  },
  {
    componentName: 'checkbox',
    componentType: ReportInputCheckBoxComponent,
  },
  {
    componentName: 'textarea',
    componentType: ReportInputTextareaComponent,
  },
];

