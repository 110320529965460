import { HttpClient, HttpHeaders } from '@angular/common/http';
import { DOCUMENT } from '@angular/common';
import { Inject, Injectable } from '@angular/core';

@Injectable({
    providedIn: 'root'
  })
  
export class DownloadFile {

      
constructor(
    private http: HttpClient,  
    @Inject(DOCUMENT) private document: Document 
   ){}

downloadFileBrowserDialog(
   fileUrl: string,
   fileName:string,
   fileExtension:string
  ) {
    const mimeType = this.getMimeType(fileExtension);    
    const headers = new HttpHeaders({
      'Content-Type': mimeType
    });
    
    this.http.get(fileUrl, { headers:headers, responseType: 'blob' }).subscribe(blob => {
        const objectUrl = URL.createObjectURL(blob);
        const link = this.document.createElement('a');
        link.href = objectUrl;
        link.download = fileName;
        this.document.body.appendChild(link);
        link.click();
        this.document.body.removeChild(link);  
        URL.revokeObjectURL(objectUrl);      
      });
  }

  getMimeType(fileExtension: string): string {    
    switch (fileExtension.toLowerCase()) {
      case 'pdf':
        return 'application/pdf';
      case 'png':
        return 'image/png';
      case 'jpg':
      case 'jpeg':
        return 'image/jpeg';
      case 'doc':
        return 'application/msword';
      case 'docx':
        return 'application/vnd.openxmlformats-officedocument.wordprocessingml.document';
      case 'xls':
        return 'application/vnd.ms-excel';
      case 'xlsx':
        return 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet';
      case 'ppt':
        return 'application/vnd.ms-powerpoint';
      case 'pptx':
        return 'application/vnd.openxmlformats-officedocument.presentationml.presentation';
      case 'txt':
        return 'text/plain';
      default:
        return 'application/octet-stream'; 

    }
  }

}