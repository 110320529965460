import { Component, EventEmitter, Input, Output } from '@angular/core';
import { ReportDocumentWithFileDto } from '@proxy/bff/activity/report-documents/v1';
import { ReportInputSectionModel } from 'src/app/features/report/shared/models/report-input-section.model';
import { PartialUpdatePdfDocumentModel } from 'src/app/features/shared/forms/models/report-input-document/partial-update-pdf-document.model';
import { DocumentUploadedEvent } from 'src/app/shared/components/upload-document/document-uploaded-event';
import { ReportResourceType } from 'src/app/shared/enums/report-resource-type.enum';
import { FileUploading } from 'src/app/shared/services/file-uploading';
import { DocumentAddedToSectionEvent } from './document-added-to-section-event';

@Component({
  selector: 'jaro-kit-document-sections',
  templateUrl: './document-sections.component.html',
  styleUrls: ['./document-sections.component.scss'],
})
export class DocumentSectionsComponent {

  @Input() documentSections: ReportInputSectionModel[];
  @Input() orderAssignmentId: string;
  @Input() reportId: string;
  @Input() reportVersion: number;

  @Output() onUpdateSection = new EventEmitter<ReportInputSectionModel>();
  @Output() onDeleteSection = new EventEmitter<ReportInputSectionModel>();
  @Output() onAddDocumentToSection = new EventEmitter<DocumentAddedToSectionEvent>();
  @Output() onAddExistingDocumentToSection = new EventEmitter<DocumentAddedToSectionEvent>();
  @Output() onRemoveDocument = new EventEmitter<ReportDocumentWithFileDto>();
  @Output() onReselectedPages = new EventEmitter<PartialUpdatePdfDocumentModel>();

  reportResourceType: ReportResourceType = ReportResourceType.Document;
  msgDelete = 'You are about to remove this section with documents in it - are you sure?';

  sectionUploadDocuments: Map<string, FileUploading[]> = new Map();

  isEditSectionEnabled(section: ReportInputSectionModel): boolean
  {
    return !this.isMapSection(section);
  }

  isDeleteSectionEnabled(section: ReportInputSectionModel): boolean
  {
    return this.isMapSection(section);
  }

  getfileTypeAccept(section: ReportInputSectionModel): string
  {
    return this.isMapSection(section) ? "map" : "pdf";
  }

  editSectionNameClick(section: ReportInputSectionModel) {
    section.isEditSection = true;
  }

  editSection(section: ReportInputSectionModel) {
    this.onUpdateSection.emit(section);
  }

  cancelEditSection(section: ReportInputSectionModel) {
    section.isEditSection = false;
  }

  deleteSection(section: ReportInputSectionModel) {
    this.onDeleteSection.emit(section);
  }

  addNewDocumentToSection(document: DocumentUploadedEvent, sectionId: string) {
    this.onAddDocumentToSection.emit({
      ...document,
      sectionId: sectionId
    });
  }

  addExistingDocumentToSection(documentId: string, sectionId: string) {
    this.onAddExistingDocumentToSection.emit({
      documentId,
      sectionId: sectionId
    });
  }

  getSectionDocumentUpload(sectionId: string) {
    let sectionUploads = this.sectionUploadDocuments.get(sectionId);
    if(!sectionUploads){
      sectionUploads = [];
      this.sectionUploadDocuments.set(sectionId, sectionUploads);
    }
    return this.sectionUploadDocuments.get(sectionId);
  }

  removeDocument(document: PartialUpdatePdfDocumentModel, sectionId: string){
    document.currentSectionId = sectionId;
    this.onRemoveDocument.emit(document);
  }

  reselectedPages(document: PartialUpdatePdfDocumentModel, sectionId: string){
    document.currentSectionId = sectionId;
    this.onReselectedPages.emit(document);
  }

  private isMapSection(section: ReportInputSectionModel): boolean
  {
    return (section?.name || '').toLowerCase() === "maps";
  }
}
