import { Component, Inject, Input, OnDestroy, OnInit } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { get } from 'lodash-es';
import * as moment from 'moment';
import { Subscription } from 'rxjs';
import { ReportWithExtraAttributes } from 'src/app/features/report/shared/models/report-input-extra-atributes.model';
import { ReportInputTemplateService } from 'src/app/features/shared/forms/providers/report-input-template.service';
import { ContentServiceInterface } from 'src/app/interface/content/content-service-interface';
import { ItemData } from 'src/app/shared/components/multiple-combo-box/multiple-combo-box.component';
import InjectionSymbol from 'src/app/shared/injection/injection-symbol';
@Component({
  selector: 'jaro-kit-pud-information',
  templateUrl: './pud-information.component.html',
  styleUrls: ['./pud-information.component.scss'],
})
export class PudInformationComponent implements OnInit, OnDestroy {
  @Input() form: FormGroup;
  @Input() reportDto: ReportWithExtraAttributes;
  private subscription: Subscription;

  options: ItemData[] = [];

  get isShowPUDInformationTotal() {
    const attachmentType = get(this.reportDto, 'property.improvement.attachmentType');
    return (
      ['attached', 'semi-detached/end unit'].includes(attachmentType) &&
      this.form.value['PUDIsDeveloperInControlOfHoa']
    );
  }

  constructor(
    @Inject(InjectionSymbol.ContentService)
    public contentService: ContentServiceInterface,
    @Inject(InjectionSymbol.ReportInputTemplateService)
    private reportInputTemplateService: ReportInputTemplateService
  ) {
    this.options = this.contentService.getContentByKey('commonElementsAndRecreationalFacilities');
  }

  ngOnInit(): void {    
    this.subscription = this.reportInputTemplateService.onPublishFieldChanges.subscribe(
      (fieldChanges) => {
        if (fieldChanges) {
          const exteriorType = fieldChanges.find((item) => item.fieldName === 'exteriorType');
          if (!['attached', 'semi-detached/end unit'].includes(exteriorType.value)) {
            this.onChangePUDIsDeveloperInControlOfHoa({ value: false }, false);
          }
        }
      }
    );
  }

  ngOnDestroy() {
    if (this.subscription) this.subscription.unsubscribe();
  }

  onChangePUDIsDeveloperInControlOfHoa($event, isUpdateValue = true) {
    if ($event.value === false) {
      const fieldNames = [
        'PUDProjectName',
        'PUDNumberOfPhases',
        'PUDNumberOfUnits',
        'PUDNumberOfUnitsSold',
        'PUDNumberOfUnitsRented',
        'PUDNumberOfUnitsForSale',
        'PUDProjectDataSource',
        'PUDIsCreatedByBuildingConversion',
        'PUDBuildingConversionDate',
        'PUDIsAnyMultipleDwellingUnits',
        'PUDMultipleDwellingUnitsDataSource',
        'PUDAreUnitsCommonElementsAndRecreationFacilitiesCompleted',
        'PUDAreUnitsCommonElementsAndRecreationFacilitiesCompletedComment',
        'PUDIsAnyCommonElementsLeasedByOrToHoa',
        'PUDIsAnyCommonElementsLeasedByOrToHoaComment',
        'PUDCommonElementsAndRecreationalFacilities',
      ];
      this.resetFields(fieldNames);
    }
    if (isUpdateValue) {
      this.form.controls['PUDIsDeveloperInControlOfHoa'].setValue($event.value);
    }
    this.form.updateValueAndValidity();
  }

  private resetFields(fieldNames: string[]) {
    fieldNames.forEach((fieldName) => {
      const formControl = this.form.controls[fieldName];
      if (formControl) {
        formControl.setValue(null);
        formControl.setErrors(null);
        formControl.markAsDirty();
      }
    });
  }

  onChangePUDIsCreatedByBuildingConversion($event) {
    if ($event.value === false) {
      const fieldNames = ['PUDBuildingConversionDate'];
      this.resetFields(fieldNames);
    }
    this.form.controls['PUDIsCreatedByBuildingConversion'].setValue($event.value);
    this.form.updateValueAndValidity();
  }

  onChangePUDAreUnitsCommonElementsAndRecreationFacilitiesCompleted($event) {
    if ($event.value === true) {
      const fieldNames = ['PUDAreUnitsCommonElementsAndRecreationFacilitiesCompletedComment'];
      this.resetFields(fieldNames);
    }
    this.form.controls['PUDAreUnitsCommonElementsAndRecreationFacilitiesCompleted'].setValue(
      $event.value
    );
    this.form.updateValueAndValidity();
  }

  onChangePUDIsAnyCommonElementsLeasedByOrToHoa($event) {
    if ($event.value === true) {
      const fieldNames = ['PUDIsAnyCommonElementsLeasedByOrToHoaComment'];
      this.resetFields(fieldNames);
    }
    this.form.controls['PUDIsAnyCommonElementsLeasedByOrToHoa'].setValue($event.value);
    this.form.updateValueAndValidity();
  }

  onChangeBuildingConversionDate($event) {
    setTimeout(() => {
      const date = $event.target.value;
      const isValidDate = moment(date, ['M/D/YYYY', 'MM/YYYY', 'YYYY'], true).isValid();
      const PUDBuildingConversionDate = this.form.controls['PUDBuildingConversionDate'];
      if (date && !isValidDate) {
        PUDBuildingConversionDate.setErrors({ inValidDate: true });
      } else {
        PUDBuildingConversionDate.setErrors(null);
      }
      PUDBuildingConversionDate.markAsTouched();
      PUDBuildingConversionDate.markAsDirty();
      this.form.updateValueAndValidity();
    }, 200);
  }

  hasError = (controlName: string, errorName: string) => {
    if (this.form) {
      const control = this.form.controls[controlName];
      return (
        control?.invalid &&
        (control?.dirty || control?.touched) &&
        control?.errors[errorName] != undefined
      );
    }
    return false;
  };
}
