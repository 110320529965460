import { get } from 'lodash-es';
import { ReportInputDataFormFieldModel } from 'src/app/features/shared/forms/models/report-input-data-field-form-model/report-input-date-field-form-model';
import { YesNoFormatPipe } from 'src/app/shared/pipes/yes-no-format/yes-no-format.pipe';

export const ReportInputTemplateConfigSiteSection: ReportInputDataFormFieldModel[] = [
  {
    formControlName: 'siteViewFactor1Desc',
    mapToFieldFunc: (value, objectDto) => {
      const factor1 = get(objectDto, 'property.view.factor1');
      if (factor1 && factor1.toLowerCase() !== 'other') {
        return null;
      }
      return value;
    },
  },
  {
    formControlName: 'siteViewFactor2Desc',
    mapToFieldFunc: (value, objectDto) => {
      const factor2 = get(objectDto, 'property.view.factor2');
      if (factor2 && factor2.toLowerCase() !== 'other') {
        return '';
      }
      return value;
    },
  },
  {
    formControlName: 'siteElectricity',
    mapToFieldFunc: (value) => {
      if (value && value.toLowerCase() !== 'public') {
        return 'other';
      }
      return value && typeof value === 'string' ? value.toLowerCase().trim() : value;
    }
  },  
  {
    formControlName: 'siteElectricityDescription',    
    mapToFieldFunc: (value) => {
      const options = ['public', 'other'];
      return value && options.includes(value.toLowerCase()) ? null : value;
    },
    mapToPartialUpdateDto: (value) => {
      // Convert whitespace to other
      if (!value || value.trim() === '') {
        return 'other';
      }
      return value;
    },
  },
  {
    formControlName: 'siteGas',
    mapToFieldFunc: (value) => {
      if (value && value.toLowerCase() !== 'public') {
        return 'other';
      }
      return value && typeof value === 'string' ? value.toLowerCase().trim() : value;
    }
  },
  {
    formControlName: 'siteGasDescription',
    mapToFieldFunc: (value) => {
      const options = ['public', 'other'];
      return value && options.includes(value.toLowerCase()) ? null : value;
    },
    mapToPartialUpdateDto: (value) => {
      // Convert whitespace to other
      if (!value || value.trim() === '') {
        return 'other';
      }
      return value;
    },
  },
  {
    formControlName: 'siteWater',
    mapToFieldFunc: (value) => {
      if (value && value.toLowerCase() !== 'public') {
        return 'other';
      }
      return value && typeof value === 'string' ? value.toLowerCase().trim() : value;
    }    
  },
  {
    formControlName: 'siteWaterDescription',
    mapToFieldFunc: (value) => {
      const options = ['public', 'other'];
      return value && options.includes(value.toLowerCase()) ? null : value;
    },
    mapToPartialUpdateDto: (value) => {
      // Convert whitespace to other
      if (!value || value.trim() === '') {
        return 'other';
      }
      return value;
    },
  },
  {
    formControlName: 'siteSanitarySewer',
    mapToFieldFunc: (value) => {
      if (value && value.toLowerCase() !== 'public') {
        return 'other';
      }
      return value && typeof value === 'string' ? value.toLowerCase().trim() : value;
    }
  },
  {
    formControlName: 'siteSanitarySewerDescription',
    mapToFieldFunc: (value) => {
      const options = ['public', 'other'];
      return value && options.includes(value.toLowerCase()) ? null : value;
    },
    mapToPartialUpdateDto: (value) => {
      // Convert whitespace to other
      if (!value || value.trim() === '') {
        return 'other';
      }
      return value;
    },
  },
  {
    formControlName: 'siteLegallyPermissible',
    format: {
      pipeType: YesNoFormatPipe,
    },
  },
  {
    formControlName: 'sitePhysicallyPossible',
    format: {
      pipeType: YesNoFormatPipe,
    },
  },
  {
    formControlName: 'siteFinanciallyFeasible',
    format: {
      pipeType: YesNoFormatPipe,
    },
  },
  {
    formControlName: 'siteMaximallyProductive',
    format: {
      pipeType: YesNoFormatPipe,
    },
  },
  {
    formControlName: 'siteAreSiteTypical',
    format: {
      pipeType: YesNoFormatPipe,
    },
  },
  {
    formControlName: 'siteAreThereSiteConditions',
    format: {
      pipeType: YesNoFormatPipe,
    },
  },
];
