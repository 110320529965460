import { ENTER } from '@angular/cdk/keycodes';
import { Component, ElementRef, EventEmitter, Inject, Input, OnInit, Output, ViewChild } from '@angular/core';
import { FormControl } from '@angular/forms';
import { MatAutocomplete, MatAutocompleteSelectedEvent, MatAutocompleteTrigger } from '@angular/material/autocomplete';
import { MatChipInputEvent } from '@angular/material/chips';
import { Observable } from 'rxjs';
import { map, startWith } from 'rxjs/operators';
import { ContentServiceInterface } from 'src/app/interface/content/content-service-interface';
import { SelectedPropertyRecordValuePipe } from 'src/app/proxy-adapter/shared/pipes/selected-property-record-value.pipe';
import { ItemData } from 'src/app/shared/components/multiple-combo-box/multiple-combo-box.component';
import InjectionSymbol from 'src/app/shared/injection/injection-symbol';
import { AdjustableAttributeModel } from '../../../models/property-attribute/adjustable-attribute-model';

@Component({
  selector: 'jaro-kit-market-cleanup-property-attribute-row',
  templateUrl: './market-cleanup-property-attribute-row.component.html',
  styleUrls: ['./market-cleanup-property-attribute-row.component.scss']
})
export class MarketCleanupPropertyAttributeRowComponent implements OnInit {
  @Input() attribute: AdjustableAttributeModel;
  @Input() isLoading: boolean = true;
  @Output() onChangeAttributeValue = new EventEmitter<AdjustableAttributeModel>();
  selectedValuePipe: SelectedPropertyRecordValuePipe;
  options: ItemData[] = [];
  filteredData: Observable<ItemData[]>;
  filterControl = new FormControl();
  visible = true;
  selectable = true;
  removable = true;
  separatorKeysCodes: number[] = [ENTER];
  chipControl = new FormControl();
  selectedChips:string[]=[];
  filteredChips: Observable<ItemData[]>;
  @ViewChild('chipInput') chipInput: ElementRef<HTMLInputElement>;
  @ViewChild('auto') matAutocomplete: MatAutocomplete;
  @ViewChild('chipInput', { read: MatAutocompleteTrigger })
  autoCompleteInput: MatAutocompleteTrigger;
  
  constructor(
    @Inject(InjectionSymbol.ContentService)
    private contentService: ContentServiceInterface
  ) {
    this.selectedValuePipe = new SelectedPropertyRecordValuePipe();
   
  }

  private filter(value: string | ItemData): ItemData[] {
    const filteredValue = typeof value === 'string' ? value : value.name;

    const filteredOptions = this.options.filter(
      (option) => option.name.includes(filteredValue) || option.value.includes(filteredValue)
    );

    return filteredOptions;
  }

  ngOnInit() {
    this.options = this.contentService.getContentByKey(this.attribute.key);    
    if(this.attribute.valueTypeName == "string[]")
      this.selectedChips = JSON.parse(this.attribute.custom || '[]');
    this.filteredData = this.filterControl.valueChanges.pipe(
      startWith(''),
      map((value) => this.filter(value))
    ); 
    this.enableFilterForChipControl();
    this.formatQualityAndCondition();
    this.checkAndDisableCustomInput();     
  }

  enableFilterForChipControl(){
    this.filteredChips = this.chipControl.valueChanges.pipe(
      startWith(''),
      map((value) => this.filter(value))
    );  
  }

  formatQualityAndCondition() {
    if(["overallQuality", "overallCondition"].indexOf(this.attribute.key) != -1) {
      let prefix = (this.attribute.key == "overallQuality") ? "Q" : "C";
      if(this.attribute.pubrec && this.attribute.pubrec.indexOf(prefix) == -1) {
        this.attribute.pubrec = prefix + this.attribute.pubrec;
      }
      if(this.attribute.mls && this.attribute.mls.indexOf(prefix) == -1) {
        this.attribute.mls = prefix + this.attribute.mls;
      }

      for(let i = 0; i < this.options.length; i++){
        this.options[i].name = this.options[i].name.toUpperCase();
        this.options[i].value = this.options[i].value.replace(/[a-zA-Z]+/g, "");
      }
    }
  }

  onChange(dataSource: string) {
    this.attribute.isMlsSelected = dataSource === 'mls';
    this.attribute.isPubRecSelected = dataSource === 'pubrec';
    this.attribute.isCustomSelected = dataSource === 'custom';
    this.attribute.isJaroAiSelected = dataSource === 'jaroAi';
    this.checkAndDisableCustomInput();
    this.onChangeAttributeValue.emit(this.attribute);
  }

  onChangeCustomValue() {
    if(this.attribute.isCustomSelected && !this.isValidPattern(this.attribute.validationPatterns, this.attribute.custom)) 
      this.attribute.hasErrors = true;
    else 
      this.attribute.hasErrors = false;
    this.onChangeAttributeValue.emit(this.attribute);
  }

  onOptionSelected(event: MatAutocompleteSelectedEvent) {
    this.attribute.custom = event.option.value;
    this.onChangeCustomValue();
  }
  
  isValidPattern(attributes: RegExp[], value: string)
  {
    if(!Array.isArray(attributes) || attributes.length ===0 || value.length==0)
      return true;
    
    let isMatch = false;     
    for(let pattern of attributes){
      let match = new RegExp(pattern).exec(value)
      if(Array.isArray(match)) {isMatch = true; break;}
    }
    return isMatch;
  }
  
  private checkAndDisableCustomInput() {
    if (this.attribute.isEditable && this.attribute.isSingleField) {
      if (!this.attribute.isCustomSelected) {
        this.filterControl.disable();
      } else {
        this.filterControl.enable();
      }
    }
  }

  addSelectedItem(event: MatChipInputEvent): void {
    const input = event.chipInput.inputElement;
    const value = event.value;

    // Add custom value
    if ((value || '').trim()) {
      this.selectedChips.push(value.trim());
      this.attribute.custom = JSON.stringify(this.selectedChips);  
      this.onChangeCustomValue();  
    }

    // Reset the input value
    if (input) {
      input.value = '';
    }

    this.chipControl.setValue(null);
  }

  removeSelectedItem(value: string): void {
    const index = this.selectedChips.indexOf(value);

    if (index >= 0) {
      this.selectedChips.splice(index, 1);
      this.attribute.custom = JSON.stringify(this.selectedChips);  
      this.onChangeCustomValue();  
    }
    this.chipInput.nativeElement.value = '';
    this.chipControl.setValue(null); 
    this.enableFilterForChipControl();
  }

  onChipSelected(event: MatAutocompleteSelectedEvent): void {
    const value=event.option.viewValue;
    if(!this.selectedChips.includes(value) && this.selectedChips.length<2)
    {
      this.selectedChips.push(value);   
      this.attribute.custom = JSON.stringify(this.selectedChips);  
      this.onChangeCustomValue();   
    }   
    this.chipInput.nativeElement.value = '';
    this.chipControl.setValue(null); 
    if (this.selectedChips.length < 2) {
      this.enableFilterForChipControl();
    }
       
  }

  openAutoComplete(event: Event) {
    event.stopPropagation();
    this.autoCompleteInput.openPanel();
  }

  closeAutoComplete(event: Event) {
    event.stopPropagation();
    this.autoCompleteInput.closePanel();
  }
 
}
