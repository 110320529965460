import { Component, EventEmitter, Inject, Input, OnDestroy, OnInit, Output } from '@angular/core';
import InjectionSymbol from 'src/app/shared/injection/injection-symbol';
import { EventService } from 'src/app/shared/services/event.service';
declare global {
  interface Window {
    JaroKit: JaroKitDocument;
  }
}
class JaroKitDocument {
  paginationAttributeSuffix?: string = "PreviewDocument";
  getPdfViewerPagination?: Function;
  updateThumbnailSelection?: Function;
  togglePdfPage?: Function;
  PDFViewerApplication?: {
    page?: number;
  };
}

class JaroKitPagination {
  pages?: number[];
  pagesCount?: number;
  pageSelected?: number;
}

window.JaroKit = window.JaroKit || {};

window.JaroKit.getPdfViewerPagination = (paginationAttributeSuffix: string): JaroKitPagination =>
{
  window.JaroKit["PdfViewerPagination"] = window.JaroKit["PdfViewerPagination"] || {};
  window.JaroKit["PdfViewerPagination"][paginationAttributeSuffix] = window.JaroKit["PdfViewerPagination"][paginationAttributeSuffix] || {};    
  return window.JaroKit["PdfViewerPagination"][paginationAttributeSuffix];
};

window.JaroKit.updateThumbnailSelection = (page: number, paginationAttributeSuffix: string) => {
  const pagination = window.JaroKit.getPdfViewerPagination(paginationAttributeSuffix);
  window.JaroKit.PDFViewerApplication = window.JaroKit.PDFViewerApplication || {};
  window.JaroKit.PDFViewerApplication.page = page;
  setTimeout(() => {
    const radioButton = document.getElementById(`thumbnailRadioButton${page}`) as HTMLInputElement;
    if (radioButton) {
      radioButton.checked = pagination.pages.includes(page);
    }
  });
};

window.JaroKit.togglePdfPage = (page: number, paginationAttributeSuffix: string) => {
  const pagination = window.JaroKit.getPdfViewerPagination(paginationAttributeSuffix);
  window.JaroKit.PDFViewerApplication = window.JaroKit.PDFViewerApplication || {};
  window.JaroKit.PDFViewerApplication.page = page;

  pagination.pages = pagination.pages ? pagination.pages : [];
  const index = pagination.pages.indexOf(page);
  if (index > -1) {
    pagination.pages.splice(index, 1);
  } else {
    pagination.pages.push(page);
  }

  window.JaroKit.updateThumbnailSelection(page, paginationAttributeSuffix);
  document.getElementById(`jarokit-preview-extract-document`).click();
};

@Component({
  selector: 'jaro-kit-preview-document',
  templateUrl: './preview-document.component.html',
  styleUrls: ['./preview-document.component.scss'],
})
export class PreviewDocumentComponent implements OnInit, OnDestroy {
  @Input() url: string;
  @Input() pages: number[] = [];
  @Input() isSelectedAllPage: boolean;
  @Input() heightOfPdfViewer: string = "80vh";
  @Input() paginationAttributeSuffix: string = "PreviewDocument";
  @Input() isLoading: boolean;

  @Output() onPdfLoadingFailed = new EventEmitter<boolean>();

  public isPdfViewerVisible: boolean = false;

  constructor(
    @Inject(InjectionSymbol.EventService) 
    private eventService: EventService) {
  }
  
  get selectedPages() {
    const pagination = window.JaroKit.getPdfViewerPagination(this.paginationAttributeSuffix);
    return pagination.pages ? pagination.pages.length : '0';
  }  

  ngOnInit(): void {
    this.isLoading = true;
    this.resetValue(this.pages);
    // Hide all other pdf viewers as multiple visible pdf viewers is not supported by ngx-extended-pdf-viewer
    this.eventService.showSinglePdfViewer(this.paginationAttributeSuffix);

    // Hide this viewer if a new pdf viewer opens
    this.eventService.onShowSinglePdfViewer.subscribe((paginationAttributeSuffix) => {
      if(paginationAttributeSuffix && paginationAttributeSuffix !== this.paginationAttributeSuffix) {
        this.isPdfViewerVisible = false;
      }
    });

    window.JaroKit.paginationAttributeSuffix = this.paginationAttributeSuffix;

    // Set a delay before showing this viewer to make sure that other pdf viewers are already hidden - required by ngx-extended-pdf-viewer
    setTimeout(() => { this.isPdfViewerVisible = true }, 100);
  }

  ngOnDestroy() {
    setTimeout(() => {
      this.resetValue();
    }, 100);
  }

  private resetValue(pages?: number[]) {
    const pagination = window.JaroKit.getPdfViewerPagination(this.paginationAttributeSuffix);
    pagination.pages = pages || [];
    pagination.pageSelected = 1;
    pagination.pagesCount = 0;
  }

  public pdfLoadingStarts(_$event) {
    this.isLoading = true;
  }

  public pdfLoadingFailed(_$event) {
    this.onPdfLoadingFailed.emit(true);
    this.isLoading = false;
  }

  public pdfLoaded($event) {
    const pagination = window.JaroKit.getPdfViewerPagination(this.paginationAttributeSuffix);
    pagination.pagesCount = $event.pagesCount;
    this.isLoading = false;
    setTimeout(() => {
      const allPagesArray = [...Array.from({ length: pagination.pagesCount }, (_, i) => i + 1)];
      pagination.pages = this.isSelectedAllPage ? allPagesArray : pagination.pages;
      allPagesArray.forEach((page) => {
        const radioButton = document.getElementById(
          `thumbnailRadioButton${page}`
        ) as HTMLInputElement;
        if (radioButton) {
          radioButton.checked = pagination.pages.includes(page);
        }
      });
    }, 100);
  }

  public onPageChange(page: number): void {
    const pagination = window.JaroKit.getPdfViewerPagination(this.paginationAttributeSuffix);
    pagination.pageSelected = page;
  }
}
