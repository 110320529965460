<div
  class="row m-0 order-result-item"
  [routerLink]="['/report-input/report', { orderAssignmentId: orderAssignment.id }]"
  (click)="onClick.emit(true)"
>
  <div class="col-3">
    <div class="order-id">Customer Order ID</div>
    <div class="order-id">Assignment Type</div>
    <div *ngIf="orderAssignment.officeId" class="order-id">Customer</div>
    <div class="order-id">Address</div>
  </div>
  <div class="col-9">
    <div class="order-id">{{ orderAssignment.orderData.customerOrderId }} ({{ orderAssignment.orderId }})</div>
    <div class="order-id">{{ orderAssignment.type }}</div>
    <div *ngIf="orderAssignment.officeId" class="order-id">{{ orderAssignment.officeId }}</div>
    <div class="order-address-text p-0">
      {{ orderAddress }}
    </div>
  </div>

</div>
