import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { AddressFormatPipe } from './address-format/address-format.pipe';
import { AreaFormatPipe } from './area-format/area-format.pipe';
import { ArrayFormatPipe } from './array-format/array-format.pipe';
import { DateTimeFormatPipe } from './date-time-format/date-time-format.pipe';
import { DynamicPipe } from './dynamic-pipe/dynamic.pipe';
import { EmptyArrayIfTruePipe } from './empty-array-if-true/empty-array-if-true.pipe';
import { FacetRangeFormatPipe } from './facet-range-format/facet-range-format.pipe';
import { GetValueWithPathPipe } from './get-value-with-path/get-value-with-path.pipe';
import { MainDateTimeFormatPipe } from './main-date-time-format/main-date-time-format.pipe';
import { NumberRoundingFormatPipe } from './number-rounding-format/number-rounding-format.pipe';
import { GoogleMapAddressUrlFormatPipe } from './order-summary/google-map-address-url-format.pipe';
import { GoogleSearchAddressUrlFormatPipe } from './order-summary/google-search-address-url-format.pipe';
import { OrderSummaryAddressFormatPipe } from './order-summary/order-summary-address-format.pipe';
import { OrderSummarySalientDetailsFormatPipe } from './order-summary/order-summary-sailient-details-format.pipe';
import { ZillowSearchAddressUrlFormatPipe } from './order-summary/zillow-search-address-url-format.pipe';
import { PageRangePipe } from './page-range/page-range.pipe';
import { PascalCaseToSpaceDelimitedPipe } from './pascal-case-to-space-delimited/pascal-case-to-space-delimited.pipe';
import { PhotoSourcePipe } from './photo-source/photo-source.pipe';
import { DefaultValuePipe } from './report-input-default-value/report-input-default-value.pipe';
import { TimezoneAbbreviations } from './time-zone-abbreviations/time-zone-abbreviations';
import { ToLocalTimeForTimeZonePipe } from './to-local-time-for-time-zone/to-local-time-for-time-zone';
import { ToLocalTimeWithTimeZoneSuffixPipe } from './to-local-time-with-time-zone-suffix/to-local-time-with-time-zone-suffix';
import { ToTimestampInTimezonePipe } from './to-timestamp-in-timezone/to-timestamp-in-timezone';
import { ToUtcForTimeZonePipe } from './to-utc-for-time-zone/to-utc-for-time-zone';
import { TranslateDataPipe } from './translate-data/translate-data.pipe';
import { YesNoFormatPipe } from './yes-no-format/yes-no-format.pipe';

@NgModule({
  declarations: [
    MainDateTimeFormatPipe,
    PascalCaseToSpaceDelimitedPipe,
    DateTimeFormatPipe,
    PhotoSourcePipe,
    AddressFormatPipe,
    TimezoneAbbreviations,
    GetValueWithPathPipe,
    AreaFormatPipe,
    NumberRoundingFormatPipe,
    DynamicPipe,
    YesNoFormatPipe,
    ArrayFormatPipe,
    TranslateDataPipe,
    PageRangePipe,
    FacetRangeFormatPipe,
    ToLocalTimeForTimeZonePipe,
    ToTimestampInTimezonePipe,
    ToLocalTimeWithTimeZoneSuffixPipe,
    ToUtcForTimeZonePipe,
    EmptyArrayIfTruePipe,
    DefaultValuePipe,
    OrderSummaryAddressFormatPipe,
    OrderSummarySalientDetailsFormatPipe,
    GoogleMapAddressUrlFormatPipe,
    GoogleSearchAddressUrlFormatPipe,
    ZillowSearchAddressUrlFormatPipe
  ],
  imports: [CommonModule],
  exports: [
    MainDateTimeFormatPipe,
    PascalCaseToSpaceDelimitedPipe,
    DateTimeFormatPipe,
    PhotoSourcePipe,
    AddressFormatPipe,
    TimezoneAbbreviations,
    GetValueWithPathPipe,
    AreaFormatPipe,
    NumberRoundingFormatPipe,
    DynamicPipe,
    YesNoFormatPipe,
    ArrayFormatPipe,
    TranslateDataPipe,
    PageRangePipe,
    FacetRangeFormatPipe,
    ToLocalTimeForTimeZonePipe,
    ToTimestampInTimezonePipe,
    ToLocalTimeWithTimeZoneSuffixPipe,
    ToUtcForTimeZonePipe,
    EmptyArrayIfTruePipe,
    DefaultValuePipe,
    OrderSummaryAddressFormatPipe,
    OrderSummarySalientDetailsFormatPipe,
    GoogleMapAddressUrlFormatPipe,
    GoogleSearchAddressUrlFormatPipe,
    ZillowSearchAddressUrlFormatPipe
  ],
})
export class PipesModule {}
