import { Component, Inject, Input } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { WorkfileRecordDto } from '@proxy/workfile/workfile/v1';

@Component({
  selector: 'jaro-kit-workfile-preview-image',
  templateUrl: './workfile-preview-image.component.html',
  styleUrls: ['./workfile-preview-image.component.scss'],
})
export class WorkfilePreviewImageComponent {
  @Input() workfile: WorkfileRecordDto;

  constructor(
    public dialogRef: MatDialogRef<WorkfilePreviewImageComponent>,
    @Inject(MAT_DIALOG_DATA)
    public imageData: {
      workfile: WorkfileRecordDto;
    }
  ) {}

  ngOnInit(): void {
    this.workfile = this.imageData.workfile;
  }

  close() {
    this.dialogRef.close();
  }
}
