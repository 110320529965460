<div class="document-review-detail">

  <mat-spinner
    *ngIf="isLoading"
    value="100"
    diameter="100"
    strokeWidth="5"
    class="action-loading-spinner"
  ></mat-spinner>
  <div
    [ngClass]="{
      'translucent-loading-overlay': isLoading
    }"
  >
  
  <div class="report-input-documents-page" [ngStyle]="{'height': heightOfPdfViewer}">
    <ng-container *ngIf="isPdfViewerVisible">
      <ngx-extended-pdf-viewer
        [handTool]="false"
        [src]="url"
        [customThumbnail]="radiobuttonThumbnail"
        [height]="heightOfPdfViewer"
        [useBrowserLocale]="true"
        [zoom]="'auto'"
        [showToolbar]="false"
        [sidebarVisible]="true"
        [enableDragAndDrop]="false"
        [delayFirstView]="100"
        [activeSidebarView]="1"
        (pdfLoadingFailed)="pdfLoadingFailed($event)"
        (pageChange)="onPageChange($event)"
        (pdfLoaded)="pdfLoaded($event)"
        (pdfLoadingStarts)="pdfLoadingStarts($event)"
      ></ngx-extended-pdf-viewer>
    </ng-container>
  </div>
</div>

<ng-template #radiobuttonThumbnail>
  <a class="pdf-viewer-template">
    <div class="thumbnail" data-page-number="PAGE_NUMBER">
      <div
        class="thumbnailSelectionRing"
        style="width: WIDTH_OF_RING; height: HEIGHT_OF_RING; display: contents"
      >
        <div class="image-container"></div>
      </div>
      <span class="document-page-number">Page PAGE_NUMBER</span>
    </div>
  </a>
</ng-template>
