import { Confirmation } from '@abp/ng.theme.shared';
import { Component, Inject, Input, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { SubjectPropertyDto } from '@proxy/appraisal/report/reports/v1/form1004/models';
import { OrderDetailDto } from '@proxy/ascent/order/order-assignment/order-assignments/v2/orderDetailDto';
import {
  PropertyJobResponseDto, ErrorDto
} from '@proxy/property/property-job/property-jobs/v1';
import { combineLatestWith } from 'rxjs/operators';
import InjectionSymbol from 'src/app/shared/injection/injection-symbol';
import { MessageService } from 'src/app/shared/services/message.service';
import { PropertyJobServiceInterface } from '../../../interface/property/property-job/property-job-service-interface';
import { OrderAssignmentServiceInterface } from '../../../interface/ascent/order-assignment-service-interface';
import { OrderAssignmentDto } from '@proxy/ascent/order/order-assignment/order-assignments';

@Component({
  selector: 'jaro-kit-raw-data-view',
  templateUrl: './raw-data-view.component.html',
  styleUrls: ['./raw-data-view.component.scss'],
})
export class RawDataViewComponent implements OnInit {
  constructor(
    @Inject(InjectionSymbol.PropertyJobService)
    private propertyJobsService: PropertyJobServiceInterface,
    @Inject(InjectionSymbol.OrderAssignmentService) private orderAssignmentService: OrderAssignmentServiceInterface,
    private msgService: MessageService,
    private activatedRoute: ActivatedRoute
  ) {
    // do nothing
  }

  @Input() orderData: OrderDetailDto;
  @Input() subjectProperty: SubjectPropertyDto;
  assignmentType: string;
  orderAssignmentId: string;
  orderAssignment: OrderAssignmentDto;
  isLoading: boolean;
  showErrors: boolean;
  sectionErrors: string[];
  appraiserId: string;
  subjectPropertyRecordDto: PropertyJobResponseDto;

  titleHeader() {
    return this.orderData?.customerOrderId ? '#' + this.orderData?.customerOrderId : '';
  }

  ngOnInit(): void {
    this.isLoading = true;
    this.assignmentType = this.subjectProperty?.assignmentType;
    let router = this.activatedRoute;
    while (router.firstChild) {
      router = router.firstChild;
    }

    // Set timeout to allow for the loading spinner to display
    setTimeout(() => {
      router.params.pipe(combineLatestWith(router.queryParams)).subscribe((paramsArray) => {
        this.orderAssignmentId = paramsArray[1].orderAssignmentId;
        this.getSubjectPropertyDetails(this.orderAssignmentId);
      });
    }, 100);
  }

  private getSubjectPropertyDetails(orderAssignmentId: string) {
    this.isLoading = true;
    this.propertyJobsService.getJobPropertyDetails(orderAssignmentId).subscribe({
      next: (response: PropertyJobResponseDto) => {
        this.subjectPropertyRecordDto = response;
        this.isLoading = false;
        this.getOrderAssignmentData(this.orderAssignmentId);
      },
      error: (err) => this.logActionError(err),
    });
  }

  private updateShowErrors() {
    this.getErrors();
    this.showErrors = false;
    if(this.orderAssignment?.extraProperties != null) {
      try {
        let extras = new Map(Object.entries(this.orderAssignment.extraProperties));
        this.showErrors = (extras.get("BoostEnabled") == true) && (this.sectionErrors.length > 0); 
      } catch {
        // throw away.
      }
    } 
  }
  

  private appendError(errObject: ErrorDto) {
    if(errObject == null ) return;
    if(errObject.isError)  {
      if(errObject.errorMessage !== "" && this.sectionErrors.indexOf(errObject.errorMessage) < 0) {
        this.sectionErrors.push(errObject.errorMessage);
      }
    }
  }

  
  getErrors() {
    this.sectionErrors = [];
    if(this.subjectPropertyRecordDto?.propertyDetails?.sourceStatus == null) return;

    this.appendError(this.subjectPropertyRecordDto?.propertyDetails?.sourceStatus?.aeriaMapError );
    this.appendError(this.subjectPropertyRecordDto?.propertyDetails?.sourceStatus?.femaError );
    this.appendError(this.subjectPropertyRecordDto?.propertyDetails?.sourceStatus?.floodMapError );
    this.appendError(this.subjectPropertyRecordDto?.propertyDetails?.sourceStatus?.listingHistoryError );
    this.appendError(this.subjectPropertyRecordDto?.propertyDetails?.sourceStatus?.mlsError );
    this.appendError(this.subjectPropertyRecordDto?.propertyDetails?.sourceStatus?.platMapError );
    this.appendError(this.subjectPropertyRecordDto?.propertyDetails?.sourceStatus?.pubRecError );
    this.appendError(this.subjectPropertyRecordDto?.propertyDetails?.sourceStatus?.saleHistoryError );
    this.appendError(this.subjectPropertyRecordDto?.propertyDetails?.sourceStatus?.zonomicsError);
  }

  protected getOrderAssignmentData(orderAssignmentId: string) {
    this.orderAssignmentService.get(orderAssignmentId).subscribe({
      next: (response: OrderAssignmentDto) => {
        if(response != null) {
          this.orderAssignment = response;
          this.updateShowErrors();
        }
      },
      error: (error) => {
        this.logActionError(error);
      },
    });
  }

  private logActionError(err: any): void {
    this.isLoading = false;
    this.msgService.error(err).subscribe({
      next: (_status: Confirmation.Status) => {},
    });
  }
}
