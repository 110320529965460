import { SubjectPropertyAddressComponent } from 'src/app/features/report/shared/components/subject-property-address/subject-property-address.component';
import { ReportInputDataComponentMapping } from '../../../report/shared/providers/report-input-layout-components.prototype';
import { ContractSection1004Component } from '../../products/1004/contract-section1004/contract-section1004.component';
import { ListingHistoryEditModeComponent } from '../../products/1004/listing-history-section1004/listing-history-edit-mode/listing-history-edit-mode.component';
import { ListingHistorySection1004Component } from '../../products/1004/listing-history-section1004/listing-history-section1004.component';
import { OrderDetailsSection1004Component } from '../../products/1004/order-details1004/order-details-section1004.component';
import { PropertySizeComponent } from '../../products/1004/property-size1004/property-size1004.component';
import { SaleHistoryEditModeComponent } from '../../products/1004/sale-history-section1004/sale-history-edit-mode/sale-history-edit-mode.component';
import { SaleHistorySection1004Component } from '../../products/1004/sale-history-section1004/sale-history-section1004.component';
import { SubjectSection1004Component } from '../../products/1004/subject-section1004/subject-section1004.component';
import { ZoningDetailsComponent } from '../../products/1004/zoning-details1004/zoning-details1004.component';

export const JaroBoostComponentMapping: ReportInputDataComponentMapping[] = [
  
  {
    componentName: 'orderDetails1004',
    componentType: OrderDetailsSection1004Component,
  },
  {
    componentName: 'subject1004',
    componentType: SubjectSection1004Component,
  },
  {
    componentName: 'listingHistory1004',
    componentType: ListingHistoryEditModeComponent,
  },
  {
    componentName: 'saleHistory1004',
    componentType: SaleHistoryEditModeComponent,
  },
  {
    componentName: 'property-size1004',
    componentType: PropertySizeComponent,
  },
  {
    componentName: 'zoning-details1004',
    componentType: ZoningDetailsComponent,
  },
  {
    componentName: 'contract1004',
    componentType: ContractSection1004Component,
  },  
];

export const JaroBoostViewModeComponentMapping: ReportInputDataComponentMapping[] = [
  {
    componentName: 'listingHistory1004',
    componentType: ListingHistorySection1004Component,
  },
  {
    componentName: 'saleHistory1004',
    componentType: SaleHistorySection1004Component,
  },
  {
    componentName: 'contract1004',
    componentType: ContractSection1004Component,
  },
  {
    componentName: 'subjectAddress',
    componentType: SubjectPropertyAddressComponent,
  }
];
