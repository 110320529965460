import { Component, Inject, OnInit } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { MatRadioChange } from '@angular/material/radio';
import { ContentServiceInterface } from 'src/app/interface/content/content-service-interface';
import { ItemData } from 'src/app/shared/components/multiple-combo-box/multiple-combo-box.component';
import InjectionSymbol from 'src/app/shared/injection/injection-symbol';
import { AddressStateTranslations } from 'src/app/shared/pipes/translate-data/address-state.prototype';
import { ReportInputTranslationModel } from 'src/app/shared/pipes/translate-data/translate-data.prototype';

@Component({
  selector: 'jaro-kit-jaro-boost-order-details-section1004',
  templateUrl: './order-details-section1004.component.html',
  styleUrls: ['./order-details-section1004.component.scss'],
})
export class OrderDetailsSection1004Component implements OnInit {
  form: FormGroup;

  occupantOptions: ItemData[] = [];
  rightsAppraisedOptions: ItemData[] = [];
  assignmentTypeOptions: ItemData[] = [];
  addressStateOptions: ReportInputTranslationModel[] = AddressStateTranslations;

  isShowRightsAppraisedOther: boolean;
  isShowAssignmentTypeDescribe: boolean;

  constructor(
    @Inject(InjectionSymbol.ContentService)
    public contentService: ContentServiceInterface
  ) {
    this.occupantOptions = this.contentService.getContentByKey('occupancy.occupant');

    this.rightsAppraisedOptions = this.contentService.getContentByKey(
      'propertyRightAppraised',
      true
    );

    this.assignmentTypeOptions = this.contentService.getContentByKey('assignmentType', true);
  }

  ngOnInit(): void {
    this.isShowRightsAppraisedOther = this.form.value.subjectRightsAppraised === 'other';
    this.isShowAssignmentTypeDescribe = this.form.value.subjectAssignmentType === 'other';
  }

  onChangeRightsAppraised($event: MatRadioChange) {
    if ($event.value !== 'other') {
      this.form.controls['subjectRightsAppraisedOther'].setValue(null);
      this.form.controls['subjectRightsAppraisedOther'].markAsDirty();
      this.form.updateValueAndValidity();
    }
    this.isShowRightsAppraisedOther = $event.value === 'other';
  }

  onChangeAssignmentType($event: MatRadioChange) {
    if ($event.value !== 'other') {
      this.form.controls['subjectAssignmentTypeDescription'].setValue(null);
      this.form.controls['subjectAssignmentTypeDescription'].markAsPristine();
      this.form.updateValueAndValidity();
    }
    this.isShowAssignmentTypeDescribe = $event.value === 'other';
  }
}
