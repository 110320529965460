import { Injectable, Pipe, PipeTransform } from '@angular/core';
@Injectable({
  providedIn: 'root',
})
@Pipe({
  name: 'yesNoFormat',
})
export class YesNoFormatPipe implements PipeTransform {
  transform(value: boolean | string, yesText?: string, noText?: string): string {
    if(value === null || value === undefined || value ==='') {
      return null;
    }
    yesText = yesText ? yesText : 'Yes';
    noText = noText ? noText : 'No';

    if(value === "true" || value === "yes")
      return yesText;

    if(value === "false" || value === "no")
      return noText;

    return !!value ? yesText : noText;
  }
}
