import { Injectable } from '@angular/core';
import { AbstractSecurityStorage } from 'angular-auth-oidc-client';
import * as _ from 'lodash';


// Session storage for security tokens that overrides the default class provided by angular-auth-oidc-client.
// Prevents overwrite of an existing id_token with null when the new value to write has a null id_token
// by re-using the existing id_token in the value to be written.
// This is to work around the issue here: https://github.com/damienbod/angular-auth-oidc-client/issues/1408#issuecomment-1126155880
@Injectable({
  providedIn: 'root',
})
export class LocalSecurityStorage implements AbstractSecurityStorage {
  read(key: string) {
    return localStorage.getItem(key);
  }

  write(key: string, value: any): void {
    const currentDataRaw = localStorage.getItem(key);
    if (!currentDataRaw) {
      localStorage.setItem(key, value);
      return;
    }

    // Patch id_token when missing in token refresh response
    const currentDataParsed = JSON.parse(currentDataRaw) || {};
    const newDataParsed = JSON.parse(value) || {};

    const currentIdToken = _.get(currentDataParsed, 'authnResult.id_token');
    const newIdToken = _.get(newDataParsed, 'authnResult.id_token');
    const newAccessToken = _.get(newDataParsed, 'authnResult.access_token');

    // If id_token is currently present, but missing from refresh response, patch it with old value
    const newDataPatched =
      newAccessToken && currentIdToken && !newIdToken
        ? _.set(newDataParsed, 'authnResult.id_token', currentIdToken)
        : newDataParsed;

        localStorage.setItem(key, JSON.stringify(newDataPatched));
  }

  remove(key: string): void {
    localStorage.removeItem(key);
  }

  clear(): void {
    localStorage.clear();
  }
}
