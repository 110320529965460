import { Confirmation, ConfirmationService } from '@abp/ng.theme.shared';
import { HttpErrorResponse } from '@angular/common/http';
import { Component, Inject, Input, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { CreateReportDocumentDto } from '@proxy/appraisal/report/report-documents/v1';
import { ReportDocumentPositionType } from '@proxy/bff/activity/report-documents';
import {
  AddReportDocumentToSectionDto,
  ReportDocumentWithFileDto
} from '@proxy/bff/activity/report-documents/v1';
import { ReportVersionWithLinksDto } from '@proxy/bff/activity/reports/v1';
import { DocumentWithFileDto, ResponseDto } from '@proxy/publication/document/documents/v1';
import { cloneDeep, isEmpty } from 'lodash-es';
import { Observable, exhaustMap, first, forkJoin, interval, timeout } from 'rxjs';
import { ReportWithExtraAttributes } from 'src/app/features/report/shared/models/report-input-extra-atributes.model';
import { ViewModeSubSectionComponent } from 'src/app/features/shared/forms/components/section-list/section/sub-section/view-mode-sub-section/view-mode-sub-section.component';
import { PartialUpdatePdfDocumentModel } from 'src/app/features/shared/forms/models/report-input-document/partial-update-pdf-document.model';
import {
  ReportInputTemplateSectionModel,
  ReportInputTemplateSubSectionModel,
} from 'src/app/features/shared/forms/models/report-input-layout.model';
import { ReportInputTemplateService } from 'src/app/features/shared/forms/providers/report-input-template.service';
import { ReportDocumentServiceInterface } from 'src/app/interface/bff/activity/report-document-interface.service';
import { ReportDocumentSectionServiceInterface } from 'src/app/interface/bff/activity/report-document-section-interface.service';
import { ReportsServiceInterface } from 'src/app/interface/bff/activity/reports-service-interface';
import { ContentServiceInterface } from 'src/app/interface/content/content-service-interface';
import { DocumentsServiceInterface } from 'src/app/interface/publication/document/documents/documents-interface.service';
import { PreviewExtractDocumentComponent } from 'src/app/shared/components/document/preview-extract-document/preview-extract-document.component';
import { DocumentSettings } from 'src/app/shared/components/upload-document/document-settings';
import { DocumentUploadedEvent } from 'src/app/shared/components/upload-document/document-uploaded-event';
import InjectionSymbol from 'src/app/shared/injection/injection-symbol';
import { EventService } from 'src/app/shared/services/event.service';
import { FileUploading } from 'src/app/shared/services/file-uploading';
import { MessageService } from 'src/app/shared/services/message.service';
import { ContractDocument } from './contract-list/contract-list.component';
@Component({
  selector: 'jaro-kit-contract-section1004',
  templateUrl: './contract-section1004.component.html',
  styleUrls: ['./contract-section1004.component.scss'],
})
export class ContractSection1004Component implements OnInit, OnDestroy {
  @ViewChild('contractViewMode') contractViewMode: ViewModeSubSectionComponent;
  @ViewChild('previewExtractDocument') previewExtractDocument: PreviewExtractDocumentComponent;

  @Input() reportInputSectionForm: FormGroup;
  @Input() reportDto: ReportWithExtraAttributes;
  @Input() reportVersionDto: ReportVersionWithLinksDto;
  @Input() section: ReportInputTemplateSectionModel;
  @Input() subSection: ReportInputTemplateSubSectionModel;
  @Input() feature: string;
  @Input() timeZoneId: string;
  @Input() isEditMode: boolean;
  @Input() orderAssignmentId: string;

  uploadingWaitCount: number = 0;
  contractSection: ReportInputTemplateSubSectionModel;
  contractUploadDocuments: Map<string, FileUploading[]> = new Map();
  isLoading: boolean;
  currentPreviewDocument: ContractDocument;
  isUploadOrEditContractDocument: boolean;
  newContractDocument: PartialUpdatePdfDocumentModel;
  isCloseContractDocument: boolean;
  readonly paginationAttributeSuffix = "ContractSection1004";
  isInvalidData: boolean = true;
  reportVersion: ReportVersionWithLinksDto;
  contractDocs: ContractDocument[];
  contractDocumentSectionId: string;
  contractDocumentSettings: DocumentSettings = {
    documentCategory: 'Purchase Contract',
    source: 'User',
    fileType: 'document',
    title: 'Purchase Contract',
    labels: []
  };

  get selectedPages() {
    const pagination = window.JaroKit.getPdfViewerPagination(this.paginationAttributeSuffix);
    return pagination.pages ? pagination.pages.length : 0;
  }

  get areAllPagesSelected() {
    const pagination = window.JaroKit.getPdfViewerPagination(this.paginationAttributeSuffix);
    return this.selectedPages > 0 && this.selectedPages === pagination.pagesCount;
  }

  set areAllPagesSelected(value) {
    this.areAllPagesSelected = value;
  }

  constructor(
    @Inject(InjectionSymbol.ActivityReportsService)
    public activityReportsService: ReportsServiceInterface,
    @Inject(InjectionSymbol.ContentService)
    public contentService: ContentServiceInterface,
    @Inject(InjectionSymbol.DocumentsService)
    private documentsService: DocumentsServiceInterface,
    @Inject(InjectionSymbol.ReportDocumentService)
    public reportDocumentService: ReportDocumentServiceInterface,
    public confirmation: ConfirmationService,
    public matDialog: MatDialog,
    private msgService: MessageService,
    @Inject(InjectionSymbol.EventService)
    private eventService: EventService,
    @Inject(InjectionSymbol.ReportInputTemplateService)
    public reportInputTemplateService: ReportInputTemplateService,
    @Inject(InjectionSymbol.ReportDocumentSectionService)
    public reportDocumentSectionService: ReportDocumentSectionServiceInterface
  ) { }



  ngOnInit(): void {
    this.isUploadOrEditContractDocument = false;
    this.isLoading = true;
    this.contractSection = cloneDeep(this.subSection);
    this.contractSection.isCustomComponent = false;
    this.reportVersion = this.reportVersionDto;
    this.isCloseContractDocument = true;

    this.loadContractDocuments().subscribe(() => {
      if (this.contractDocs.length == 1) {
        this.openContractDocumentForExtraction(this.contractDocs[0]);
      }
    });

    if (this.reportInputSectionForm) {
      this.isInvalidData = this.isInvalidForm();
      this.reportInputSectionForm.valueChanges.subscribe((_value) => {
        setTimeout(() => {
          this.isInvalidData = this.isInvalidForm();
        }, 100);
      });
    }
  }

  ngOnDestroy(): void {
    this.isUploadOrEditContractDocument = false;
    this.isLoading = false;
    this.newContractDocument = null;
  }


  loadContractDocuments(): Observable<undefined> {
    this.isLoading = true;

    return new Observable(subscriber => forkJoin({
      documents: this.documentsService.getAllWithFileVersions(this.orderAssignmentId),
      documentSections: this.reportDocumentSectionService.getList(this.reportVersionDto.reportId, this.reportVersionDto.version, this.orderAssignmentId)
    })
      .subscribe(
        {
          next: ({ documents, documentSections }) => {
            this.contractDocs = [];
            this.contractDocumentSectionId = documentSections[0].id // use the first section found

            documentSections.forEach(s =>
              s.reportDocuments.forEach(rd => {
                if (rd.documentCategory == "Purchase Contract" && rd.fileExtension === 'pdf') {
                  const files = documents.find(d => d.id === rd.documentId)?.files;
                  if (files?.length > 0 && files[files.length - 1].links?.length > 0) {
                    const fileUrl = files[files.length - 1].links[0].href;

                    this.contractDocs.push({
                      documentId: rd.documentId,
                      title: rd.title,
                      fileExtension: rd.fileExtension,
                      documentCategory: rd.documentCategory,
                      source: rd.source,
                      reportDocumentId: rd.id,
                      creationTime: rd.creationTime,
                      fileUrl: fileUrl,
                      currentSectionId: s.id,
                      latestExtractedVersion: {
                        version: rd.latestExtractedVersion?.version,
                        documentFileVersion: rd.latestExtractedVersion?.documentFileVersion,
                        url: rd.latestExtractedVersion?.links?.length > 0 ? rd.latestExtractedVersion?.links[0].href : null,
                        isFileAvailable: rd.latestExtractedVersion?.isFileAvailable,
                        creationTime: rd.latestExtractedVersion?.creationTime,
                        pages: rd.latestExtractedVersion?.pages
                      }
                    });

                  }
                }
              }));

            documents.filter(d => !d.isDeleted && d.documentCategory == "Purchase Contract" && this.getFileExtension(d.fileName) === 'pdf')
              .forEach(d => {
                if (!this.contractDocs.find(doc => doc.documentId == d.id)) {

                  let fileUrl;
                  if (d.files && d.files.length > 0) {
                    let file = d.files[d.files.length - 1];
                    if (file.links?.length > 0)
                      fileUrl = file.links[0].href;
                  }
                  this.contractDocs.push({
                    documentId: d.id,
                    title: d.title,
                    fileExtension: d.fileName.split('.').pop(),
                    documentCategory: d.documentCategory,
                    source: d.source,
                    reportDocumentId: null,
                    creationTime: d.creationTime,
                    fileUrl: fileUrl
                  });
                }
              });

            this.contractDocs = this.contractDocs.sort((a,b) => {
              if(a.documentId > b.documentId)
                return 1;
              if(a.documentId < b.documentId)
                return -1;
              return 0;
            });
            this.isLoading = false;

            subscriber.next();
            subscriber.complete();
          }
        }));
  }


  uploadNewContractDocument($event: DocumentUploadedEvent) {
    this.isLoading = true;
    this.waitForFileReady($event.documentId)
      .subscribe({
        next: () => {
          this.loadContractDocuments().subscribe(() => {
            let document = this.contractDocs.find(d => d.documentId == $event.documentId);
            this.openContractDocumentForExtraction(document);
          });
        },
        error: () => {
          console.error('There was a problem waiting for file');
        }
      });
  }

  private openDocumentPreviewAfterGettingDirectFileUrl(
    document: PartialUpdatePdfDocumentModel,
    count: number
  ) {
    this.isLoading = true;
    this.documentsService
      .getFile(document.documentId, this.orderAssignmentId, null)
      .subscribe({
        next: (data: ResponseDto) => {
          if (data && data.links.length > 0) {
            document.url = data.links[0].href;
            this.newContractDocument = document;
            this.isLoading = false;
          }
        },
        error: (err: HttpErrorResponse) => {
          if (err.status === 404 && count < 20) {
            count++;
            this.confirmation.clear();
            this.openDocumentPreviewAfterGettingDirectFileUrl(document, count);
          }
        },
      });
  }

  private getFileExtension(filename: string): string {
    return filename?.split('.').pop();
  }

  saveContractDocuments() {
    const pagination = window.JaroKit.getPdfViewerPagination(this.paginationAttributeSuffix);
    if (!pagination.pages || pagination.pages.length == 0) {
      let contractDocument = this.contractDocs.find(cd => cd.reportDocumentId == this.newContractDocument.id);
      this.deleteContractDocument(contractDocument);
      this.closeExtractContractDocuments();
      return;
    }
    this.newContractDocument.pages = pagination.pages;
    this.newContractDocument.isSelectedAllPage = pagination.isSelectedAllPage || (pagination.pagesCount == pagination.pages?.length);

    if (this.newContractDocument?.id) {
      this.reselectedPages(this.newContractDocument);
    }
    else {
      this.createReportDocument(this.newContractDocument);
    }
  }

  closeContractDocuments() {
    this.isCloseContractDocument = true;
  }

  closeExtractContractDocuments() {
    this.isLoading = false;
    this.isCloseContractDocument = true;
    this.isUploadOrEditContractDocument = false;
    this.newContractDocument = null;
    this.loadContractDocuments().subscribe();
    this.isLoading = false;
  }

  pdfLoadingFailed() {
    const options = {
      hideYesBtn: true,
      cancelText: 'Close',
    };
    this.msgService.error(
      null,
      'File Viewer',
      'The contract document cannot be opened. Please try it again!',
      options
    );
  }

  private isInvalidForm() {
    if (this.reportInputSectionForm.invalid) {
      return Object.keys(this.reportInputSectionForm.controls).some((controlName) => {
        const currentControl = this.reportInputSectionForm.controls[controlName];

        if (currentControl.dirty && currentControl.invalid && currentControl.errors) {
          const errors = cloneDeep(currentControl.errors);
          delete errors['required'];
          return !isEmpty(errors);
        }
        return false;
      });
    }

    return false;
  }

  private createReportDocument(document: PartialUpdatePdfDocumentModel) {
    const createReportDocumentRequest = {
      reportId: this.reportVersionDto.reportId,
      reportVersion: this.reportVersionDto.version,
      documentId: document.documentId,
      title: document.title || document.fileName,
      source: "User",
      documentCategory: "Purchase Contract"
    } as CreateReportDocumentDto;
    this.reportDocumentService
      .create(this.orderAssignmentId, createReportDocumentRequest)
      .subscribe({
        next: (reportDocument: ReportDocumentWithFileDto) => {
          document.id = reportDocument.id;
          this.addDocumentToSection(document);
        },
        error: (err) => {
          this.logActionError(err);
          this.closeExtractContractDocuments();
        }
      });
  }

  deleteContractDocument(document: ContractDocument) {
    this.reportDocumentService
      .deleteFromSection(
        this.reportVersion.reportId,
        this.reportVersion.version,
        this.contractDocumentSectionId,
        document.reportDocumentId)
      .subscribe({
        next: () => {
          this.loadContractDocuments().subscribe();
        },
        error: (err) => this.logActionError(err),
      });
  }


  reselectedPages(document: PartialUpdatePdfDocumentModel) {
    document.isProcessing = true;
    this.reportDocumentService
      .deleteFromSection(this.reportVersionDto.reportId, this.reportVersionDto.version, document.currentSectionId, document.id)
      .subscribe({
        next: () => {
          this.createReportDocument(document);
        },
        error: (err) => this.logActionError(err),
      });
  }

  private addDocumentToSection(document: PartialUpdatePdfDocumentModel) {
    document.isProcessing = true;
    document.position = {
      positionType: ReportDocumentPositionType.First,
    };

    const reportInputDocument = {
      reportDocumentId: document.id,
      selectedPages: document.isSelectedAllPage ? [] : document.pages,
      position: document.position,
    } as AddReportDocumentToSectionDto;
    this.reportDocumentService
      .addDocumentToSection(
        this.orderAssignmentId,
        this.reportVersionDto.reportId,
        this.reportVersionDto.version,
        this.contractDocumentSectionId,
        reportInputDocument
      )
      .subscribe({
        next: () => {
          document.isProcessing = false;
          this.isLoading = false;
          this.loadContractDocuments().subscribe();
        },
        error: (err) => this.logActionError(err),
      });
  }


  private logActionError(err: any): void {
    this.isLoading = false;
    this.isUploadOrEditContractDocument = false;
    this.newContractDocument = null;
    this.msgService.error(err).subscribe((_status: Confirmation.Status) => { });
  }


  // opens the already-extracted document
  openDocumentPreview(document: ContractDocument) {
    this.currentPreviewDocument = document;
    this.isCloseContractDocument = false;
  }

  // opens the raw document to extract pages from
  openContractDocumentForExtraction(document: ContractDocument) {
    const newDoc = <PartialUpdatePdfDocumentModel>cloneDeep(document);
    if (newDoc) {
      newDoc.url = document.fileUrl;
      newDoc.id = document.reportDocumentId;
      newDoc.currentSectionId = document.currentSectionId;
      newDoc.pages = newDoc.latestExtractedVersion?.pages;
      newDoc.isSelectedAllPage = newDoc.pages?.length === 0;
      const pagination = window.JaroKit.getPdfViewerPagination(this.paginationAttributeSuffix);
      pagination.pages = newDoc.latestExtractedVersion?.pages || [];

      this.newContractDocument = newDoc;
      this.isUploadOrEditContractDocument = true;
      this.isCloseContractDocument = false;
    }
  }

  getLatestExtractedUrl(document: ContractDocument) {
    return document?.latestExtractedVersion?.url;
  }

  toggleMode() {
    if (this.isEditMode) {
      Object.keys(this.reportInputSectionForm.controls).forEach((controlName) => {
        const currentControl = this.reportInputSectionForm.controls[controlName];

        if (currentControl.dirty) {
          const fieldConfig = this.contractSection.fields.find(
            (item) => item.formControlName === controlName
          );
          if (fieldConfig) {
            currentControl.setValue(fieldConfig.value);
          }
          currentControl.markAsPristine();
        }
      });
    }

    this.isEditMode = !this.isEditMode;
  }

  saveButtonClick() {
    this.eventService.clickSaveSection('section-contract');
    setTimeout(() => {
      this.isEditMode = !this.isEditMode;
      this.contractSection = cloneDeep(this.subSection);
      this.contractSection.isCustomComponent = false;
    }, 500);
  }

  selectAll($event) {
    this.previewExtractDocument.selectAll($event.checked);
  }



  /**
   * Recursively waits for the extracted file to be ready
   */
  private waitForFileReady(documentId: string): Observable<any> {
    return interval(1000)
    .pipe(
      exhaustMap(() =>this.documentsService.getDocumentWithFile(documentId, this.orderAssignmentId)),
      first((response: DocumentWithFileDto) => response.file?.isFileAvailable && response.file?.links?.length > 0),
      timeout(30000)
    );
  }
}

