import { Confirmation } from '@abp/ng.theme.shared';
import { Component, ElementRef, EventEmitter, Input, Output, ViewChild } from '@angular/core';
import { MessageService } from 'src/app/shared/services/message.service';
import { ReportInputSectionModel } from '../../../report/shared/models/report-input-section.model';

@Component({
  selector: 'jaro-kit-edit-section-name',
  templateUrl: './edit-section-name.component.html',
  styleUrls: ['./edit-section-name.component.scss'],
})
export class EditSectionNameComponent {
  @ViewChild('inputSection') inputSection: ElementRef;

  @Input() sections: ReportInputSectionModel[];
  @Input() sectionName: string;
  @Input() section: ReportInputSectionModel;
  @Input() msgDelete: string;

  @Output() onAddNewSection = new EventEmitter<string>();
  @Output() onEditSection = new EventEmitter<ReportInputSectionModel>();
  @Output() onCancelAddNewSection = new EventEmitter<void>();
  @Output() onCancelEditSection = new EventEmitter<void>();
  @Output() onDeleteSection = new EventEmitter<ReportInputSectionModel>();

  constructor(private msgService: MessageService) {}

  scrollAndFocusInputSection() {
    setTimeout(() => {
      this.sectionName = '';
      this.inputSection.nativeElement.scrollIntoView();
      this.inputSection.nativeElement.focus();
    }, 0);
  }

  saveNewSection() {
    const isInvalidSectionName = ['system contract'].includes((this.sectionName || '').toLowerCase());
    if (
      !this.sectionName ||
      isInvalidSectionName ||
      (this.sections || []).find(
        (item) => item.name.toLowerCase() === this.sectionName.toLowerCase()
      )
    ) {
      const title = this.section?.id ? 'Update new section' : 'Create new section';
      const msg = isInvalidSectionName ? 'This section name is not allowed.' : 'The section name is incorrect or already exists.';
      this.msgService
        .error(null, title, msg)
        .subscribe((_status: Confirmation.Status) => {
          // do nothing
        });
    } else {
      if (this.section?.isEditSection) {
        this.section.name = this.sectionName;
        this.onEditSection.emit(this.section);
      } else {
        this.onAddNewSection.emit(this.sectionName);
      }
    }
  }

  discardAddNewSection() {
    this.sectionName = this.section?.name || '';
    if (this.section?.isEditSection) {
      this.onCancelEditSection.emit();
    } else {
      this.onCancelAddNewSection.emit();
    }
  }

  deleteSection() {
    if (this.section?.photos?.length > 0 || this.section?.reportDocuments?.length > 0) {
      const title = 'Confirm removal of section';
      const msgDelete =
        this.msgDelete || 'You are about to remove a section with photos in it - are you sure?';
      const options: Partial<Confirmation.Options> = {
        yesText: 'Yes, remove',
        cancelText: 'Cancel',
      };
      this.msgService.confirm(title, msgDelete, options).subscribe({
        next: (status: Confirmation.Status) => {
          if (status === Confirmation.Status.confirm) {
            this.onDeleteSection.emit(this.section);
          }
        },
      });
    } else {
      this.onDeleteSection.emit(this.section);
    }
  }
}
