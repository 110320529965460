<div class="property-attribute-panel">
  <table class="property-attribute-table" aria-label="Property Attribute Table">
    <thead class="property-attribute-table-head-row">
      <tr>
        <th>&nbsp;</th>
        <th>MLS</th>
        <th>Public Record</th>
        <th>JaroAi</th>
        <th class="col-custom">Custom</th>
      </tr>
    </thead>
    <tbody>
      <jaro-kit-market-cleanup-property-attribute-row
        *ngFor="let attr of editableAttributes; index as i"
        [attribute]="attr"
        [ngClass]="{
          'property-attribute-row': true,
          'property-attribute-row-odd': i % 2 != 0
        }"
        (onChangeAttributeValue)="changeAttributeValue($event)"
      >
      </jaro-kit-market-cleanup-property-attribute-row>
    </tbody>
  </table>
</div>
