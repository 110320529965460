import { Component, Input } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'halo-global-popup',
  templateUrl: './popup.component.html',
  styleUrls: ['./popup.component.scss'],
})
export class PopupComponent {
  @Input() dialogRef: MatDialogRef<any>;
  @Input() title: string;
  @Input() isHideClose: boolean;
  @Input() isShowScrollbars: boolean;

  onClose() {
    this.dialogRef.close();
  }
}
