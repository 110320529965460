<div class="report-input-sketch-page">
  <mat-spinner
    *ngIf="isLoading"
    value="100"
    diameter="100"
    strokeWidth="5"
    class="action-loading-spinner"
  ></mat-spinner>
  <div
    [ngClass]="{
      'sketch-container': true,
      'sketch-container-loading': isLoading
    }"
    class="sketch-container"
    *ngIf="sketchThumbnail"
  >
    <em class="fa fa-pencil mouse-cursor-pointer" aria-hidden="true" (click)="openSketch()"></em>

    <img [src]="sketchThumbnail" class="sketch-thumbnail" alt="sketch-thumbnail" />
  </div>

  <div class="empty-sketch-panel" *ngIf="!isLoading && !sketchThumbnail">
    <span class="report-input-label title">No sketches has been created</span>
  </div>
</div>
