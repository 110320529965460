
<div [ngClass]="{ 'translucent-loading-overlay': isLoading, 'map-panel': true }">
  <mat-spinner
    *ngIf="isLoading"
    value="50"
    diameter="50"
    strokeWidth="5"
    class="loading-spinner"
  ></mat-spinner>
  <div
    *ngIf="polygons.length"
    class="drawing-manager-delete-icon"
    (click)="onDeleteDrawing()"
    title='Delete Polygon'>
  </div>
  <agm-map
    class="map"
    [latitude]="subjectGeolocation.latitude"
    [longitude]="subjectGeolocation.longitude"
    [scrollwheel]="true"
    [fullscreenControl]="true"
    [mapTypeControl]="true"
    [streetViewControl]="true"
    [fitBounds]="true"
    (mapReady)="onLoadMap($event)"
    (mapClick)="mapClicked()"
    [agmDrawingManager]="drawing"
  >
    <agm-marker *ngFor="let prop of defaultSubtractFilteredProperties | emptyArrayIfTrue: !areDefaultMapMarkersVisible" class="unselected-map-marker" [latitude]="prop.geoLocation.latitude" [longitude]="prop.geoLocation.longitude" [agmFitBounds]="true" [iconUrl]="unselectedPropertyMarkerSymbol" [label]="{color: 'black', text: prop.priceFormatted}" (markerClick)="markerClicked($event)">
      <agm-info-window [disableAutoPan]="true">
        <ul class="map-info-attribute-list">
          <li><span class="map-info-attribute-name"><em class="grid-edit-icon grid-edit-column fas fa-pencil-alt" (click)="editProperty(prop.id)"></em></span><span class="map-info-attribute-value">{{prop.address}}</span></li>
          <li><span class="map-info-attribute-name">latitude</span><span class="map-info-attribute-value">{{prop.geoLocation.latitude}} </span></li>
          <li><span class="map-info-attribute-name">longitude</span><span class="map-info-attribute-value">{{prop.geoLocation.longitude}}</span></li>
        </ul>
      </agm-info-window>
    </agm-marker>
    <agm-marker *ngFor="let prop of remainingFilteredProperties | emptyArrayIfTrue: !areFilteredMapMarkersVisible" class="filtered-map-marker" [latitude]="prop.geoLocation.latitude" [longitude]="prop.geoLocation.longitude" [agmFitBounds]="true" [iconUrl]="filteredPropertyMarkerSymbol" [label]="{color: '#4169e1', fontWeight: 'bold', text: prop.tickedPriceFormatted}" (markerClick)="markerClicked($event)">
      <agm-info-window [disableAutoPan]="true">
        <ul class="map-info-attribute-list">
          <li><span class="map-info-attribute-name"><em class="grid-edit-icon grid-edit-column fas fa-pencil-alt" (click)="editProperty(prop.id)"></em></span><span class="map-info-attribute-value">{{prop.address}}</span></li>
          <li><span class="map-info-attribute-name">latitude</span><span class="map-info-attribute-value">{{prop.geoLocation.latitude}} </span></li>
          <li><span class="map-info-attribute-name">longitude</span><span class="map-info-attribute-value">{{prop.geoLocation.longitude}}</span></li>
          <li><button (click)="editProperty(prop.id)">Cleanup</button></li>
        </ul>
      </agm-info-window>
    </agm-marker>
    <agm-marker class="subject-map-marker" [latitude]="subjectGeolocation.latitude" [longitude]="subjectGeolocation.longitude" [agmFitBounds]="true" [iconUrl]="subjectMarkerSymbol" [zIndex]="9999">
      <agm-info-window [disableAutoPan]="true" >
        <div class="subject-info-window-title">Subject</div>
      </agm-info-window>
    </agm-marker>
    <agm-polygon
      *ngFor="let iterator of polygons; index as i"
      (polyMouseDown)="onClickPolygon(i)"
      (polyMouseUp)="onEditPolygon(i)"
      (polyDragEnd)="onEditPolygon(i)"
      [fillOpacity]="polygonOptions.fillOpacity"
      [fillColor]="polygonOptions.fillColor"
      [strokeColor]="polygonOptions.strokeColor"
      [strokeWeight]="polygonOptions.strokeWeight"
      [polyDraggable]="polygonOptions.draggable"
      [editable]="polygonOptions.editable"
      [paths]="iterator">
    </agm-polygon>
  </agm-map>

  <agm-drawing-manager #drawing="agmDrawingManager"
    [drawingMode]="drawingMode"
    (overlayComplete)="onOverlayComplete($event)"
    [drawingControlOptions]="drawingControlOptions"
    [polygonOptions]="polygonOptions"
    ></agm-drawing-manager>
</div>
