import { Component } from '@angular/core';
import { FormGroup } from '@angular/forms';

@Component({
  selector: 'jaro-kit-certification',
  templateUrl: './certification.component.html',
  styleUrls: ['./certification.component.scss'],
})
export class CertificationComponent {
  form: FormGroup;

  onChangePropertyInspected($event) {
    if ($event.value === false) {
      this.form.controls['certificationInspectionType'].setValue(null);
      this.form.controls['certificationInspectionType'].markAsDirty();
      this.form.controls['certificationInspectionDate'].setValue(null);
      this.form.controls['certificationInspectionDate'].markAsDirty();
    }
    this.form.controls['certificationPropertyInspected'].setValue($event.value);
    this.form.updateValueAndValidity();
  }

  onChangeComparableSalesInspected($event) {
    if ($event.value === false) {
      this.form.controls['certificationComparableSalesInspectionDate'].setValue(null);
      this.form.controls['certificationComparableSalesInspectionDate'].markAsDirty();
    }
    this.form.controls['certificationComparableSalesInspected'].setValue($event.value);
    this.form.updateValueAndValidity();
  }
}
