import { ReportInputDataFormFieldModel } from 'src/app/features/shared/forms/models/report-input-data-field-form-model/report-input-date-field-form-model';
import { YesNoFormatPipe } from 'src/app/shared/pipes/yes-no-format/yes-no-format.pipe';

export const ReportInputTemplateConfigAppraisalUpdateSection: ReportInputDataFormFieldModel[] = [
  {
    formControlName: 'appraisalUpdate_propertyMarketValueDecreased',
    format: {
      pipeType: YesNoFormatPipe,
    }
  },
  {
    formControlName: 'certificationCompletion_propertyImprovementsCompleted',
    format: {
      pipeType: YesNoFormatPipe,
    }
  },
  {
    formControlName: 'subjectContractIsNotApplicable',
    format: {
      pipeType: YesNoFormatPipe,
    }    
  }
];
