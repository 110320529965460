import { Component, Inject, Input, OnInit } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { SubjectListingHistoryDto } from '@proxy/bff/activity/reports/v1';
import { ReportInputTemplateService } from 'src/app/features/shared/forms/providers/report-input-template.service';
import InjectionSymbol from 'src/app/shared/injection/injection-symbol';

@Component({
  selector: 'jaro-kit-listing-history-section1004',
  templateUrl: './listing-history-section1004.component.html',
  styleUrls: ['./listing-history-section1004.component.scss'],
})
export class ListingHistorySection1004Component implements OnInit {
  @Input() form: FormGroup;
  listingHistoryGroups: SubjectListingHistoryDto[];

  constructor(
    @Inject(InjectionSymbol.ReportInputTemplateService)
    private reportInputTemplateService: ReportInputTemplateService
  ) {}

  ngOnInit(): void {
    this.listingHistoryGroups = this.form?.value['listingHistory'] || [];
    this.filterEmptyData();
    this.reportInputTemplateService.onCancel.subscribe((events) => {
      if (events?.includes('listingHistory')) {
        this.listingHistoryGroups = this.form?.value['listingHistory'] || [];
        this.filterEmptyData();
      }
    });
  }

  private filterEmptyData() {
    this.listingHistoryGroups.forEach((item) => {
      delete item.guid;
      item.listingHistory = (item.listingHistory || []).filter(
        (item) => item.date || item.event || item.price
      );
    });
    this.listingHistoryGroups = this.listingHistoryGroups.filter(
      (item) => item.listingHistory?.length > 0
    );
  }
}
