import { Injectable, Pipe, PipeTransform } from '@angular/core';
import * as moment from 'moment';
import { DateUtils } from '../../utils/date/date.utils';

@Injectable({
  providedIn: 'root',
})
@Pipe({
  name: 'toTimestampInTimezone',
})
export class ToTimestampInTimezonePipe implements PipeTransform {
  transform(utcDateTimeOrString: string | Date, timeZoneId: string): any {
    if(!utcDateTimeOrString)
      return null;

    const utcDateTime = moment.utc(utcDateTimeOrString).toDate();
    const dateFormatToUse = 'MM/DD/YYYY HH:mm:ss';

    // Return UTC if no time zone id applied rather than null or an exception.
    if(!timeZoneId)
    {
      return utcDateTime ? `${moment.utc(utcDateTime).format(dateFormatToUse)}` : null;
    }

    // Offset the date and time for the timezone difference
    // e.g.
    // 01-Jan-2023 06:15 UTC America/Phoenix -> 31-Dec-2022 23:15
    // 01-Jan-2023 08:15 UTC America/Phoenix -> 01-Jan-2023 01:15
    const localisedDateTime = utcDateTime && timeZoneId ? DateUtils.uTCToTimeZoneDate(`${moment.utc(utcDateTime).format("YYYY-MM-DDTHH:mm:ssZ")}`, timeZoneId) : null;

    // Abbreviates 'America/Phoenix' to 'MST'
    const abbreviation = DateUtils.getTimeZoneAbbreviationShortName(utcDateTimeOrString, timeZoneId);
    return localisedDateTime ? `${moment(localisedDateTime).format(dateFormatToUse)} ${abbreviation}` : null;
  }
}
