import {
  Component,
  ComponentFactoryResolver,
  ComponentRef,
  Inject,
  Input,
  OnDestroy,
  OnInit,
  Type,
  ViewChild,
  ViewContainerRef,
} from '@angular/core';
import { FormGroup } from '@angular/forms';
import { ReportDto } from '@proxy/appraisal/report/v1/form1004';
import { ReportVersionWithLinksDto } from '@proxy/bff/activity/reports/v1';
import {
  ReportInputTemplateField,
  ReportInputTemplateSectionModel,
  ReportInputTemplateSubSectionModel,
} from 'src/app/features/shared/forms/models/report-input-layout.model';
import { ReportInputTemplateService } from 'src/app/features/shared/forms/providers/report-input-template.service';
import InjectionSymbol from 'src/app/shared/injection/injection-symbol';

@Component({
  selector: 'jaro-kit-dynamic-component',
  templateUrl: './dynamic.component.html',
  styleUrls: ['./dynamic.component.scss'],
})
export class DynamicComponent implements OnInit, OnDestroy {
  @ViewChild('viewContainerRef', {
    read: ViewContainerRef,
    static: true,
  })
  viewContainerRef: ViewContainerRef;

  @Input() componentName: string;
  @Input() reportDto: ReportDto;
  @Input() reportInputForm: FormGroup;
  @Input() section: ReportInputTemplateSectionModel;
  @Input() subSection: ReportInputTemplateSubSectionModel;
  @Input() isEditMode: boolean;
  @Input() orderAssignmentId: string;
  @Input() feature: string;
  @Input() timeZoneId: string;
  @Input() reportVersionDto: ReportVersionWithLinksDto;
  @Input() field: ReportInputTemplateField;

  componentRef: ComponentRef<any>;

  constructor(
    @Inject(InjectionSymbol.ReportInputTemplateService)
    public reportInputTemplateService: ReportInputTemplateService,
    protected componentFactoryResolver: ComponentFactoryResolver
  ) {
    // do nothing
  }

  ngOnInit(): void {
    const params = {
      form: this.reportInputForm,
      reportDto: this.reportDto,
      section: this.section,
      subSection: this.subSection,
      orderAssignmentId: this.orderAssignmentId,
      isEditMode: this.isEditMode,
      timeZoneId: this.timeZoneId,
      reportVersionDto: this.reportVersionDto,
      field: this.field,
      reportInputSectionForm: this.reportInputForm,
    };

    const metadata = this.subSection.metadata;

    if (typeof metadata === 'object') {
      Object.keys(metadata).forEach((propertyName) => {
        params[propertyName] = metadata[propertyName];
      });
    }

    const component = this.reportInputTemplateService.getComponentByName(
      this.componentName,
      !this.isEditMode,
      this.feature
    );
    if(component) {
      this.renderDynamicComponent(component, this.viewContainerRef, params);
    }
  }

  ngOnDestroy() {
    if (this.componentRef) {
      this.componentRef.destroy();
    }
  }

  renderDynamicComponent<TComponent>(
    dynamicComponent: Type<TComponent>,
    viewContainerRef: ViewContainerRef,
    params?: any
  ): TComponent {
    const componentFactory =
      this.componentFactoryResolver.resolveComponentFactory(dynamicComponent);
    const componentRef = viewContainerRef.createComponent(componentFactory);
    const dynamicComponentInstance = componentRef.instance;

    if (params) {
      Object.keys(params).forEach((propertyName) => {
        dynamicComponentInstance[propertyName] = params[propertyName];
      });
    }

    return dynamicComponentInstance;
  }
}
