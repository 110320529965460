import { Component, Input, OnInit } from '@angular/core';
import { FormGroup, ValidationErrors } from '@angular/forms';

@Component({
  selector: 'halo-global-present-land-use',
  templateUrl: './present-land-use.component.html',
  styleUrls: ['./present-land-use.component.scss'],
})
export class PresentLandUseComponent implements OnInit {  
  @Input() form: FormGroup;

  ngOnInit(): void {
    this.validatePresentLandUseTotal();
  }
  
  onLandUseKeyUp(_$event) {
    this.validatePresentLandUseTotal();
  }

  private validatePresentLandUseTotal() {
    const neighborhoodOneUnitValue = parseInt(this.form.value['neighborhoodOneUnit'], 10);
    const neighborhoodTwoToFourUnitValue = parseInt(this.form.value['neighborhoodTwoToFourUnit'], 10);
    const neighborhoodMultiFamilyValue = parseInt(this.form.value['neighborhoodMultiFamily'], 10);
    const neighborhoodCommercialValue = parseInt(this.form.value['neighborhoodCommercial'], 10);
    const neighborhoodOtherValue = parseInt(this.form.value['neighborhoodOther'], 10);

    const presentLandUseTotalValue = (neighborhoodOneUnitValue || 0) + (neighborhoodTwoToFourUnitValue || 0) + 
                  (neighborhoodMultiFamilyValue || 0) + (neighborhoodCommercialValue || 0) + 
                  (neighborhoodOtherValue || 0);

    let presentLandUseTotalErrors : ValidationErrors = null;      
    this.form.controls['presentLandUseTotal'].setValidators(null);
    this.form.controls['presentLandUseTotal'].updateValueAndValidity();
    
    // Present Land Use Total must equal 100%
    if(presentLandUseTotalValue !== 100) {
      presentLandUseTotalErrors = {"totalPercentageShouldEqualNumber": true};      
    }   

    setTimeout(() => 
      this.form.controls["presentLandUseTotal"].setErrors(presentLandUseTotalErrors), 500);
  }
}
