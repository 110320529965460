import { AccountConfigModule } from '@abp/ng.account/config';
import { CoreModule, LocalizationPipe } from '@abp/ng.core';
import { IdentityConfigModule } from '@abp/ng.identity/config';
import { SettingManagementConfigModule } from '@abp/ng.setting-management/config';
import { TenantManagementConfigModule } from '@abp/ng.tenant-management/config';
import { ThemeBasicModule } from '@abp/ng.theme.basic';
import { ThemeSharedModule } from '@abp/ng.theme.shared';
import { HttpClientModule } from '@angular/common/http';
import { CUSTOM_ELEMENTS_SCHEMA, ErrorHandler, NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { NgxsModule } from '@ngxs/store';
import { NgxDatatableModule } from '@swimlane/ngx-datatable';
import { AbstractSecurityStorage } from 'angular-auth-oidc-client';
import { InfiniteScrollModule } from 'ngx-infinite-scroll';
import { AdminSidebarModule } from 'src/app/admin/admin-sidebar/admin-sidebar.module';
import { environment } from '../environments/environment';
import { OrderSearchResultsModule } from './admin/admin-sidebar/order-search-results/order-search-results.module';
import { AdminModule } from './admin/admin.module';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { AuthConfigModule } from './auth-config.module';
import { GlobalErrorHandlerService } from './exception/global/global-error-handler.service';
import { LoginFailedModule } from './login-failed/login-failed.module';
import { NotFoundModule } from './not-found/not-found.module';
import { ReportInputModule } from './report-input/report-input.module';
import { RawDataViewModule } from './features/raw-data-view/raw-data-view.module';
import { CanActivateReportInput } from './shared/guards/report-input.guard';
import InjectionSymbol from './shared/injection/injection-symbol';
import { CacheService } from './shared/services/cache.service';
import { EnvServiceProvider } from './shared/services/env.service.provider';
import { EventService } from './shared/services/event.service';
import { LocalSecurityStorage } from './shared/utils/session/local-security-storage';

@NgModule({
  imports: [
    AccountConfigModule.forRoot(),
    AppRoutingModule,
    BrowserAnimationsModule,
    BrowserModule,
    CoreModule.forRoot({ environment, skipGetAppConfiguration: false }),
    FormsModule,
    HttpClientModule,
    IdentityConfigModule.forRoot(),
    NgxDatatableModule,
    NgxsModule.forRoot(),
    NotFoundModule,
    LoginFailedModule,
    OrderSearchResultsModule,
    SettingManagementConfigModule.forRoot(),
    TenantManagementConfigModule.forRoot(),
    ThemeBasicModule.forRoot(),
    ThemeSharedModule.forRoot(),
    ReportInputModule,
    RawDataViewModule,
    InfiniteScrollModule,
    AdminModule,
    AdminSidebarModule,
    AuthConfigModule
  ],

  declarations: [AppComponent],
  providers: [
    GlobalErrorHandlerService,
    LocalizationPipe,
    { provide: ErrorHandler, useClass: GlobalErrorHandlerService },
    EnvServiceProvider,
    CanActivateReportInput,
    { provide: AbstractSecurityStorage, useClass: LocalSecurityStorage },
    { provide: InjectionSymbol.EventService, useClass: EventService },
    { provide: InjectionSymbol.CacheService, useClass: CacheService },
  ],
  bootstrap: [AppComponent],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
})
export class AppModule {}
