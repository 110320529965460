<div class="material-condition" *ngIf="formGroup" [formGroup]="formGroup">
  <div class="mb-15">
    <halo-global-multiple-combo-box
      [label]="title"
      placeholder="New Material..."
      [options]="materialOptions"
      [(value)]="value"
      [formGroup]="formGroup"
      [formControlName]="materialFormControlName"
    >
    </halo-global-multiple-combo-box>
  </div>

  <div class="report-input-form-field">
    <p class="report-input-label report-input-radio-label">{{condition}}</p>

    <mat-radio-group [formControlName]="conditionFormControlName" >
      <mat-radio-button
        *ngFor="let option of conditionOptions"
        [value]="option.value"
        class="report-input-radio-button"
        >{{ option.name }}</mat-radio-button
      >
    </mat-radio-group>
  </div>
</div>
