import { Confirmation } from '@abp/ng.theme.shared';
import { Component, EventEmitter, Inject, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { AbstractControl, FormBuilder, FormGroup } from '@angular/forms';
import { ReportVersionWithLinksDto } from '@proxy/bff/activity/reports/v1';
import { isEmpty, set } from 'lodash-es';
import { Subscription } from 'rxjs';
import InjectionSymbol from 'src/app/shared/injection/injection-symbol';
import { CacheService } from 'src/app/shared/services/cache.service';
import { EventService } from 'src/app/shared/services/event.service';
import { MessageService } from 'src/app/shared/services/message.service';
import { ReportWithExtraAttributes } from '../../../../../report/shared/models/report-input-extra-atributes.model';
import {
  ReportInputFieldType,
  ReportInputTemplateSectionModel,
  ReportInputTemplateSubSectionModel
} from '../../../models/report-input-layout.model';
import { ReportInputTemplateService } from '../../../providers/report-input-template.service';
import { ErrorDto } from '@proxy/property/property-job/property-jobs/v1';


@Component({
  selector: 'jaro-kit-section',
  templateUrl: './section.component.html',
  styleUrls: ['./section.component.scss'],
})
export class SectionComponent implements OnInit, OnDestroy {
  @Input() section: ReportInputTemplateSectionModel;
  @Input() reportDto: ReportWithExtraAttributes;
  @Input() timeZoneId: string;
  @Input() orderAssignmentId: string;
  @Input() boostEnabled: boolean;
  @Input() feature: string;
  @Input() reportVersionDto: ReportVersionWithLinksDto;

  @Output() onSaveSectionForm = new EventEmitter<any>();

  isLoading: boolean;
  reportInputSectionForm: FormGroup;
  formFields: AbstractControl[] = [];
  private subscription: Subscription;
  sectionErrors: ErrorDto[];
  get layoutHint(): string {
    return `report-input-template-section ${this.section.layoutHint}`;
  }

  get subSections(): ReportInputTemplateSubSectionModel[] {
    return this.section.subSections;
  }

  constructor(
    @Inject(InjectionSymbol.ReportInputTemplateService)
    public reportInputTemplateService: ReportInputTemplateService,
    private msgService: MessageService,
    private formBuilder: FormBuilder,

    @Inject(InjectionSymbol.EventService)
    private eventService: EventService,

    @Inject(InjectionSymbol.CacheService)
    private cacheService: CacheService
  ) {}

  ngOnInit(): void {
    this.getTemplateConfig();
    this.subscribeFieldChange();
    this.sectionErrors = this.section.errors;
    this.eventService.onGoToReportField.subscribe((field) => {
      if (field != null) {
        if (field.sectionId === this.section.id) {
          this.checkAndGoToField(field);
        }
      }
    });
    this.eventService.onClickSaveSection.subscribe((sectionId) => {
      if (sectionId === this.section.id) {
        this.section.isEditMode = true;
        this.saveButtonClick();
      }
    });
  } 

  private checkAndGoToField(field, count: number = 0) {
    const formControl = this.reportInputSectionForm.controls[field.fieldId];
    const sectionElementRef = document.getElementById(field.sectionId);

    if (formControl && !sectionElementRef && count < 20) {
      setTimeout(() => {
        this.checkAndGoToField(field, count + 1);
      }, 500);

      return;
    }

    this.goToField(field);

  }

  private goToField(field) {
    const formControl = this.reportInputSectionForm.controls[field.fieldId];
    if (!this.section.isEditMode) {
      this.toggleMode();
    }

    setTimeout(() => {
      const elementRef = document.getElementById(field.fieldId);
      if (elementRef) {
        elementRef.scrollIntoView({ behavior: 'smooth', block: 'center' });
        elementRef.focus();
        formControl.updateValueAndValidity();
        formControl.markAsTouched();
      } else {
        const sectionElementRef = document.getElementById(field.sectionId);
        sectionElementRef.scrollIntoView({ behavior: 'smooth', block: 'center' });
      }
    }, 500);
  }

  private subscribeFieldChange() {
    if (this.section.dependentFields) {
      this.subscription = this.reportInputTemplateService.onPublishFieldChanges.subscribe(
        (fieldChanges) => {
          this.isLoading = true;
          if (fieldChanges) {
            const hasDependentField = fieldChanges.some((item) =>
              this.section.dependentFields.includes(item.fieldName)
            );
            if (hasDependentField) {
              this.reportInputTemplateService.initValuesForSection(
                this.section,
                this.reportDto,
                this.timeZoneId,
                this.reportInputSectionForm
              );
              setTimeout(() => {
                this.isLoading = false;
              }, 100);
            }
          }
          setTimeout(() => {
            this.isLoading = false;
          }, 100);
        }
      );
    }
  }

  ngOnDestroy() {
    if (this.subscription) this.subscription.unsubscribe();
    // Todo: Must replace with an unsubscribe
    this.eventService.goToReportField(null);
  }

  private getTemplateConfig() {
    this.isLoading = true;
    if (this.section?.jsonPath) {
      this.cacheService.checkAndGetJsonFile(this.section.jsonPath).subscribe({
        next: (section: ReportInputTemplateSectionModel) => {
          this.section.subSections = section.subSections.filter((subSection) =>
            this.reportInputTemplateService.isVisible(
              subSection,
              this.reportDto,
              this.reportInputSectionForm
            )
          );
          this.reportInputTemplateService.initValuesForSection(
            section,
            this.reportDto,
            this.timeZoneId
          );
          this.isLoading = false;
          this.initFormBuilder();
        },
        error: (err) => {
          this.logActionError(err);
        },
      });
    }
  }

  private initFormBuilder() {
    this.reportInputSectionForm = this.formBuilder.group(
      this.reportInputTemplateService.getGroupFormControlBySection(this.section)
    );
    this.reportInputTemplateService.updateVisibleForSection(
      this.section,
      this.reportDto,
      this.reportInputSectionForm
    );
  }

  saveButtonClick() {
    // Wait to complete validation.
    this.reportInputSectionForm.updateValueAndValidity();
    this.reportInputSectionForm.markAllAsTouched();
    setTimeout(() => {
      this.reportInputTemplateService.onSaveSection(this.section);
      setTimeout(() => {
        if (this.section.isEditMode) {
          this.saveSectionForm();
        }
      }, 100);
    }, 100);
  }

  toggleMode() {
    if (this.section.isEditMode) {
      this.reInitValue();
      this.reportInputTemplateService.onCancelSection(this.section);
    }
    this.section.isEditMode = !this.section.isEditMode;
    this.section.subSections.forEach(
      (subSection) => (subSection.isEditMode = this.section.isEditMode)
    );
    this.formFields.forEach((formField) => {
      formField.markAsPristine();
    });
  }

  private reInitValue() {
    Object.keys(this.reportInputSectionForm.controls).forEach((controlName) => {
      const currentControl = this.reportInputSectionForm.controls[controlName];

      if (currentControl.dirty) {
        const fieldConfig = this.reportInputTemplateService.getFormFieldConfig(
          controlName,
          this.section
        );
        if (fieldConfig) {
          currentControl.setValue(fieldConfig.value);
        }
      }
      if (currentControl) {
        currentControl.markAsUntouched();
      }
    });
  }

  private logActionError(err: any): void {
    this.isLoading = false;
    this.msgService.error(err).subscribe((_status: Confirmation.Status) => {});
  }

  private saveSectionForm() {
    const reportInputUpdates: Record<string, string> = {};
    this.formFields = [];
    const fieldChanges = [];

    Object.keys(this.reportInputSectionForm.controls).forEach((controlName) => {
      const currentControl = this.reportInputSectionForm.controls[controlName];

      if (currentControl.dirty) {
        const fieldConfig = this.reportInputTemplateService.getFormFieldConfig(
          controlName,
          this.section
        );
        if (fieldConfig) {
          let value = currentControl.value;
          // update the new values changed for the report input model
          if (fieldConfig.mapToModelFunc) {
            fieldConfig.mapToModelFunc(value, this.reportDto, fieldConfig, this.timeZoneId);
          } else {
            if (fieldConfig.type === ReportInputFieldType.Date) {
              value = this.reportInputTemplateService.getDateValue(currentControl.value, fieldConfig.dateConfig?.displayFormat);
            }
            set(this.reportDto, fieldConfig.path, value);
          }
          fieldConfig.value = value;

          // map the new values modified to the partial update model
          this.reportInputTemplateService.mapToPartialUpdateDto(
            fieldConfig,
            value,
            reportInputUpdates,
            this.timeZoneId
          );

          this.formFields.push(currentControl);
          if (fieldConfig.isPublishFieldChange) {
            const publishFieldChange = {
              fieldName: fieldConfig.formControlName,
              value: fieldConfig.value,
            };
            fieldChanges.push(publishFieldChange);
          }
        }
      }
      if (currentControl) {
        currentControl.markAsUntouched();
      }
    });

    if (!isEmpty(reportInputUpdates)) {
      this.onSaveSectionForm.emit({
        reportInputUpdates: reportInputUpdates,
        toggleMode: this.toggleMode.bind(this),
      });

      if (!this.section.isFormBuilder) {
        this.reportInputTemplateService.updateVisibleForSection(this.section, this.reportDto);
      }

      if (!isEmpty(fieldChanges)) {
        this.reportInputTemplateService.publishFieldChanges(fieldChanges);
      }
    } else {
      this.toggleMode();
    }

    this.subscribeFieldChange();
  }
}
