import { AttributeStyleModel } from "./attribute-style-model";
import { AttributeValueType } from "./attribute-value-type";
import { CleanedListingModel } from "./cleaned-listing-model";

export class AdjustableAttributeModel {
    public id: string;
    public title: string;
    public isDate: boolean;
    public isEditable: boolean;
    mls: string;
    isMlsSelected: boolean;
    pubrec: string;
    isPubRecSelected: boolean;
    custom: string;
    isCustomSelected: boolean;
    public isDropdown:boolean;
    public isSingleField:boolean | null;
    public isMultiSelect:boolean | null;
    public key:string;
    public hasErrors:boolean;
    public validationPatterns : RegExp[];
    public validationErrorMessage : string;
    public toolTip: string;
    valueTypeName: AttributeValueType;
    jaroAi: string;
    isJaroAiSelected: boolean;
    formatFunc: (...args: any[]) => string; 

    defaultFormatFunc: (...args: any[]) => string = (s: string) => s;

    constructor(id: string, key:string, cleanedListing: CleanedListingModel, attributeStyle: AttributeStyleModel, valueTypeName: AttributeValueType, formatFunc: (...args: any[]) => string = null)
    {
        this.id = id;
        this.key = key;

        // Cleaned Listing Model
        this.mls = cleanedListing.mls;
        this.isMlsSelected = cleanedListing.selectedDataSource === "mls";
        this.pubrec = cleanedListing.pubrec;
        this.isPubRecSelected = cleanedListing.selectedDataSource === "pubrec";
        this.custom = cleanedListing.custom;
        this.isCustomSelected = cleanedListing.selectedDataSource === "custom";
        this.jaroAi = cleanedListing.jaroAi;
        this.isJaroAiSelected = cleanedListing.selectedDataSource === "jaroAi";
        
        // Attribute Style Model
        this.title = attributeStyle.title;
        this.isDate = attributeStyle.isDate;
        this.isEditable = attributeStyle.isEditable;
        this.isDropdown = attributeStyle.isDropdown
        this.isSingleField = attributeStyle.isSingleField;
        this.validationPatterns= attributeStyle.validationPatterns;  
        this.validationErrorMessage = attributeStyle.validationErrorMessage; 
        this.toolTip = attributeStyle.toolTip;      
        this.valueTypeName = valueTypeName;  
        this.isMultiSelect=attributeStyle.isMultiSelect;
        this.formatFunc = formatFunc !== null ? formatFunc : this.defaultFormatFunc;
    }
}