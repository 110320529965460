import { Component, Input } from '@angular/core';
import { PropertyDetailsDto } from '@proxy/property/property-job/property-jobs/v1';
import { PropertyRecordDto } from '@proxy/property/property-record/property-records/v1';

@Component({
  selector: 'jaro-kit-market-cleanup-data-reference',
  templateUrl: './market-cleanup-data-reference.component.html',
  styleUrls: ['./market-cleanup-data-reference.component.scss'],
})
export class MarketCleanupDataReferenceComponent {
  @Input() property: PropertyRecordDto;

  propertyDetails: PropertyDetailsDto;
  isLoading: boolean;

  ngOnInit(): void {
    this.propertyDetails = {
      pubRec: this.property.sources.pubrec as any,
      mls: this.property.sources.mls as any,
      maps: null,
      zoneomics: null,
      transferHistories: this.property.transferHistory,
    } as PropertyDetailsDto;
  }
}
