import { Confirmation } from '@abp/ng.theme.shared';
import { Component, EventEmitter, Inject, Input, OnInit, Output } from '@angular/core';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { ResponseDto } from '@proxy/publication/document/documents/v1';
import { PartialUpdatePdfDocumentModel } from 'src/app/features/shared/forms/models/report-input-document/partial-update-pdf-document.model';
import { DocumentsServiceInterface } from 'src/app/interface/publication/document/documents/documents-interface.service';
import InjectionSymbol from 'src/app/shared/injection/injection-symbol';
import { MessageService } from 'src/app/shared/services/message.service';
import { DocumentPreviewExtractedDocumentComponent } from '../../document-preview-extracted-document/document-preview-extracted-document.component';
import { DocumentPreviewModalComponent } from '../../document-preview-modal/document-preview-modal.component';

@Component({
  selector: 'jaro-kit-document-pdf',
  templateUrl: './document-pdf.component.html',
  styleUrls: ['./document-pdf.component.scss'],
})
export class DocumentPdfComponent implements OnInit {
  @Input() orderAssignmentId: string;
  @Input() document: PartialUpdatePdfDocumentModel;
  @Input() isUploading: boolean;
  @Output() onRemoveDocument = new EventEmitter<PartialUpdatePdfDocumentModel>();
  @Output() onReselectedPages = new EventEmitter<PartialUpdatePdfDocumentModel>();

  constructor(
    private msgService: MessageService,
    public matDialog: MatDialog,
    @Inject(InjectionSymbol.DocumentsService) private documentApiService: DocumentsServiceInterface
  ) {}

  ngOnInit(): void {
    const latestExtractedVersion = this.document.latestExtractedVersion;
    if (latestExtractedVersion && latestExtractedVersion.links?.length > 0) {
      const link = latestExtractedVersion.links[0];
      if (!link.href) {
        console.error("The href cannot be found in the file's link.");
      } else {
        this.document.extractedURL = link.href;
        this.document.pages = this.document.pages || latestExtractedVersion.pages;
      }
    }
    this.document.url = this.document.documentFileLocation?.href;
    const pages = this.document.pages || [];
    this.document.isSelectedAllPage = pages.length === 0;
  }

  removeDocument() {
    const options = {
      yesText: 'Yes, remove',
      cancelText: 'Cancel',
    };
    this.msgService
      .confirm(
        'You are about to delete a document',
        'You will still be able to upload documents from the library',
        options
      )
      .subscribe({
        next: (status: Confirmation.Status) => {
          if (status === Confirmation.Status.confirm) {
            this.onRemoveDocument.emit(this.document);
          }
        },
      });
  }

  checkAndOpenDocument(document: PartialUpdatePdfDocumentModel) {
    if (this.isUploading || document.isProcessing) {
      return this.showErrorMsg('The document is being uploaded. Please retry later!');
    }
    if (!document.extractedURL) {
      return this.getDocumentURLAndPreview();
    }

    this.openDocumentPreview(document);
  }

  checkAndOpenExtractedDocument(document: PartialUpdatePdfDocumentModel) {
    if (this.isUploading || document.isProcessing) {
      return this.showErrorMsg('The document is being uploaded. Please retry later!');
    }

    if (!document.extractedURL) {
      return this.showErrorMsg('The extracted document cannot be opened. Please retry later!');
    }

    const dialogConfig = new MatDialogConfig();
    dialogConfig.data = {
      document: document,
    };
    dialogConfig.panelClass = 'document-review-modal';
    let dialogRef = this.matDialog.open(
      DocumentPreviewExtractedDocumentComponent,
      dialogConfig
    );
    dialogRef.afterClosed().subscribe({
      next: (isPdfLoadingFailed: boolean) => {
        // verify the right type of response
        if (isPdfLoadingFailed === true) {
          this.showErrorMsg('The extracted document cannot be opened. Please retry later!');
        }
      },
    });
  }

  openDocumentPreview(document: PartialUpdatePdfDocumentModel) {
    const dialogConfig = new MatDialogConfig();
    // selected all when pages is null or empty
    dialogConfig.data = {
      document: document
    };
    dialogConfig.panelClass = 'document-review-modal';
    let dialogRef = this.matDialog.open(
      DocumentPreviewModalComponent,
      dialogConfig
    );
    dialogRef.afterClosed().subscribe({
      next: (response: PartialUpdatePdfDocumentModel | boolean) => {
        // verify the right type of response
        if (response && typeof response === 'object') {
          this.onReselectedPages.emit(response);
        } else if (response === true) {
          this.showErrorMsg('The document cannot be opened. Please retry later!');
        } else {
          // Nothing should be done. The user closes the dialog.
        }
      },
    });
  }

  getDocumentURLAndPreview() {
    this.documentApiService
      .getFile(this.document.documentId, this.orderAssignmentId, null)
      .subscribe({
        next: (data: ResponseDto) => {
          if (data && data.links.length > 0) {
            const link = data.links[0];
            this.document.url = link.href;
            this.openDocumentPreview(this.document);
          } else {
            this.showErrorMsg('The document cannot be opened. Please retry later!');
          }
        },
      });
  }

  private showErrorMsg(msg: string) {
    const options = {
      hideYesBtn: true,
      cancelText: 'Close',
    };
    this.msgService.error(msg, null, null, options).subscribe();
  }
}
