import { Component, Inject, OnInit, ViewChild } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { PartialUpdatePdfDocumentModel } from 'src/app/features/shared/forms/models/report-input-document/partial-update-pdf-document.model';
import { PreviewExtractDocumentComponent } from 'src/app/shared/components/document/preview-extract-document/preview-extract-document.component';
import { HttpClient } from '@angular/common/http';

@Component({
  selector: 'jaro-kit-document-preview-modal',
  templateUrl: './document-preview-modal.component.html',
  styleUrls: ['./document-preview-modal.component.scss'],
})
export class DocumentPreviewModalComponent implements OnInit {
  @ViewChild('previewExtractDocument') previewExtractDocument: PreviewExtractDocumentComponent;
  document: PartialUpdatePdfDocumentModel;

  readonly paginationAttributeSuffix = "DocumentPreviewModal"

  get selectedPages() {
    const pagination = window.JaroKit.getPdfViewerPagination(this.paginationAttributeSuffix);
    return pagination.pages ? pagination.pages.length : 0;
  }

  get areAllPagesSelected() {
    const pagination = window.JaroKit.getPdfViewerPagination(this.paginationAttributeSuffix);
    return this.selectedPages > 0 && this.selectedPages === pagination.pagesCount;
  }

  set areAllPagesSelected(value){
    this.areAllPagesSelected = value;
  }

  get isDisabledSelectAll(){
    const pagination = window.JaroKit.getPdfViewerPagination(this.paginationAttributeSuffix);
    return pagination.pagesCount === 0;
  }

  constructor(
    private http: HttpClient, 
    public dialogRef: MatDialogRef<DocumentPreviewModalComponent>,
    @Inject(MAT_DIALOG_DATA)
    public documentData: {
      document: PartialUpdatePdfDocumentModel;
      isContractDocument: boolean;
    }
  ) {}

  ngOnInit(): void {
    this.document = this.documentData.document;
    this.areAllPagesSelected = false;
  }

  pdfLoadingFailed() {
    this.dialogRef.close(false);
  }

  close() {
    this.dialogRef.close(null);
  }

  download() {
   if(this.document.url) 
    {
      this.http.get(this.document.url,{responseType:'blob'}) 
      .subscribe(res=>{
        let a = document.createElement('a');
        a.href = URL.createObjectURL(res);
        a.download = this.document.title;
        
        //download
        a.click();
     });
    }         
  }

  includeSelected() {
    const pagination = window.JaroKit.getPdfViewerPagination(this.paginationAttributeSuffix);
    const pages = pagination.pages || [];

    // check select all
    if(pagination.pagesCount === pages.length) {
      this.document.pages = null;
    } else {
      this.document.pages = [...pages].sort((a, b) => (a - b));
    }
    
    this.dialogRef.close(this.document);
  }

  selectAll($event) {
    this.previewExtractDocument.selectAll($event.checked);
  }
}
