<ng-container *ngIf="form" [formGroup]="form">
  <p class="report-input-title pb-15">Neighborhood Characteristics</p>

  <div class="report-input-form-field">
    <p class="report-input-label report-input-radio-label">Location</p>
    <mat-radio-group formControlName="neighborhoodLocation">
      <mat-radio-button
        *ngFor="let option of locationOptions"
        [value]="option.value"
        class="report-input-radio-button"
        >{{ option.name }}</mat-radio-button
      >
    </mat-radio-group>
  </div>

  <div class="report-input-form-field">
    <p class="report-input-label report-input-radio-label">Built-Up</p>
    <mat-radio-group formControlName="neighborhoodBuiltUp">
      <mat-radio-button
        *ngFor="let option of builtUpOptions"
        [value]="option.value"
        class="report-input-radio-button"
        >{{ option.name }}</mat-radio-button
      >
    </mat-radio-group>
  </div>

  <div class="report-input-form-field">
    <p class="report-input-label report-input-radio-label">Growth</p>
    <mat-radio-group formControlName="neighborhoodGrowth">
      <mat-radio-button
        *ngFor="let option of growthOptions"
        [value]="option.value"
        class="report-input-radio-button"
        >{{ option.name }}</mat-radio-button
      >
    </mat-radio-group>
  </div>
</ng-container>
