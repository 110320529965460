<div
  [ngClass]="layoutHint"
  *ngIf="!isLoading && reportInputSectionForm"
  [id]="section.id"
  [formGroup]="reportInputSectionForm"
  [boostEnabled]="boostEnabled"
>
  <jaro-kit-section-panel
    [title]="section.title"
    [reportDto]="reportDto"
    [sectionErrors]="sectionErrors"
    [(isEditMode)]="section.isEditMode"
    [isDisabled]="section.isDisabled"
    [isCustom]="section.isCustom"
    [boostEnabled]="boostEnabled"
    [isInvalidData]="reportInputSectionForm.invalid"
    [reportInputSectionForm]="reportInputSectionForm"
    (onSaveSection)="saveButtonClick()"
    (onToggleModeSection)="toggleMode()"
  ></jaro-kit-section-panel>

  <div class="report-input-template-section-container" *ngIf="!section.isFormBuilder">
    <jaro-kit-sub-section
      *ngFor="let subSection of subSections"
      [section]="section"
      [subSection]="subSection"
      [reportDto]="reportDto"
      [timeZoneId]="timeZoneId"
      [reportInputSectionForm]="reportInputSectionForm"
      [orderAssignmentId]="orderAssignmentId"
      [feature]="feature"
      [reportVersionDto]="reportVersionDto"
    ></jaro-kit-sub-section>
  </div>

  <div class="report-input-template-section-container" *ngIf="section.isFormBuilder">
    <jaro-kit-sub-section-formbuilder
      *ngFor="let subSection of subSections"
      [section]="section"
      [subSection]="subSection"
      [reportDto]="reportDto"
      [timeZoneId]="timeZoneId"
      [reportInputSectionForm]="reportInputSectionForm"
      [orderAssignmentId]="orderAssignmentId"
      [feature]="feature"
      [reportVersionDto]="reportVersionDto"
    ></jaro-kit-sub-section-formbuilder>
  </div>
</div>
