export class CommentaryAttributeModel {
    public id: string;
    public title: string;
    comments: string;

    constructor(id: string, title: string, comments: string)
    {
        this.id = id;
        this.title = title;
        this.comments = comments;
    }
}