import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { ReportPhotoOwnerType, ReportPhotoSource } from '@proxy/appraisal/report/report-photos';
import { ReportInputPhotoSectionDto } from '@proxy/bff/activity/report-input-photos/v1';
import { ReportPhotoResourceModel } from 'src/app/shared/dtos/report-input-resource/report-photo-resource.model';
import { SectionModel } from 'src/app/shared/models/photo/section.model';
import { PhotoPreviewModalComponent } from '../photo-preview-modal/photo-preview-modal.component';

@Component({
  selector: 'jaro-kit-photo',
  templateUrl: './photo.component.html',
  styleUrls: ['./photo.component.scss'],
})
export class PhotoComponent implements OnInit {
  @Input() photo: ReportPhotoResourceModel;
  @Input() photos: ReportPhotoResourceModel[] = [];
  @Input() orderAssignmentId: string;
  @Input() reportId: string;
  @Input() reportVersion: number;
  @Input() isOpenByReportPhoto: boolean;
  @Input() sectionOptions: SectionModel[];
  @Input() photoSections: ReportInputPhotoSectionDto[] = [];
  @Input() galleryPhotos: ReportPhotoResourceModel[];

  @Output() onUpdatePhotoSections = new EventEmitter<ReportInputPhotoSectionDto[]>();

  reportPhotoSource = ReportPhotoSource;
  isFileNotAvailable: boolean = false;

  constructor(public matDialog: MatDialog) {}

  ngOnInit(): void {
    const thumbnail = (this.photo.thumbnails || []).find((item) => item.size === 'small');
    const thumbnailAvailable = thumbnail && thumbnail.isFileAvailable;
    this.isFileNotAvailable = !this.photo.resourceUrl && !thumbnailAvailable;
  }

  clickOnPhoto(photo: ReportPhotoResourceModel) {
    switch (photo.photoOwnerType) {
      case ReportPhotoOwnerType.Subject:
        this.openPhotoPreview(photo);
        break;
      case ReportPhotoOwnerType.Comparable:
        break;
    }
  }

  openPhotoPreview(photo: ReportPhotoResourceModel) {
    const dialogConfig = new MatDialogConfig();
    dialogConfig.data = {
      orderAssignmentId: this.orderAssignmentId,
      reportId: this.reportId,
      reportVersion: this.reportVersion,
      photos: this.photos,
      currentPhoto: photo,
      isOpenByReportPhoto: this.isOpenByReportPhoto,
      sectionOptions: this.sectionOptions,
      photoSections: this.photoSections,
      galleryPhotos: this.galleryPhotos,
    };
    dialogConfig.panelClass = 'photo-review-modal';
    let openConditionalOrderDialogRef = this.matDialog.open(
      PhotoPreviewModalComponent,
      dialogConfig
    );
    openConditionalOrderDialogRef.afterClosed().subscribe({
      next: (sections: ReportInputPhotoSectionDto[]) => {
        if (sections && sections.length > 0) {
          this.onUpdatePhotoSections.emit(sections);
        }
      },
    });
  }
}
