import { AfterViewInit, Component, Inject, OnInit, ViewChild } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import {
  NgxGalleryAnimation,
  NgxGalleryComponent,
  NgxGalleryImage,
  NgxGalleryLayout,
  NgxGalleryOptions
} from '@kolkov/ngx-gallery';
import { MarketImageCarouselModalRequest } from './market-image-carousel-modal-request';
import { MarketImageCarouselModalResult } from './market-image-carousel-modal-result';


@Component({
  selector: 'jaro-kit-market-image-carousel-modal',
  templateUrl: './market-image-carousel-modal.component.html',
  styleUrls: ['./market-image-carousel-modal.component.scss'],
})
export class MarketImageCarouselModalComponent implements OnInit, AfterViewInit {
  @ViewChild('ngxGallery') ngxGalleryComponent: NgxGalleryComponent;
  galleryOptions: NgxGalleryOptions[];
  galleryImages: NgxGalleryImage[];

  form: FormGroup;
  currentImage: string;
  images: string[] = [];

  constructor(
    public dialogRef: MatDialogRef<MarketImageCarouselModalComponent, MarketImageCarouselModalResult>,
    @Inject(MAT_DIALOG_DATA)
    public data: MarketImageCarouselModalRequest
  ) {

  }
  ngAfterViewInit(): void {
    let currentImageIndex = 0;
    if(this.data.currentImage) {
      this.currentImage = this.data.currentImage;
      currentImageIndex = this.images.indexOf(this.data.currentImage);
      if(currentImageIndex < 0) {
        currentImageIndex = 0;
      }
    }
        
    this.ngxGalleryComponent.show(currentImageIndex);
  }

  ngOnInit() {    
    this.galleryOptions = [
      {
        width: '905px',
        height: '699px',
        thumbnailsColumns: 5,
        thumbnailsRows: 5,
        imageAnimation: NgxGalleryAnimation.Fade,
        previewFullscreen: false,
        thumbnailsArrows: false,
        layout: NgxGalleryLayout.ThumbnailsTop,
        lazyLoading: true,
        startIndex: 0,
        preview: false,
        arrowPrevIcon: 'fa fa-angle-left',
        arrowNextIcon: 'fa fa-angle-right',
        previewAnimation: true,
        imageInfinityMove: true,
      },
    ];

    
    this.images = this.data.images;
    this.setupGallery();                

    this.dialogRef.keydownEvents().subscribe((event) => {
      if (event.key === 'Escape') {
        this.closeDialog();
      }
    });

    this.dialogRef.backdropClick().subscribe((_event) => {
      this.closeDialog();
    });    
  }

  private setupGallery() {
    this.galleryImages = [];
    this.data.images.forEach((image: string) => {
      const galleryImage: NgxGalleryImage = {};
      const defaultURLNotFound = './assets/images/report-input/photo-failed-to-load.svg';
      galleryImage.small = image || defaultURLNotFound
      galleryImage.medium = image || defaultURLNotFound;
      galleryImage.big = image || defaultURLNotFound;
      this.galleryImages.push(galleryImage);
    });
  }

  closeDialog() {   
      this.dialogRef.close({
        currentImage: this.currentImage
      } as MarketImageCarouselModalResult);
  }

  changeSelectPhoto() {
    this.currentImage = this.data.images[this.ngxGalleryComponent.selectedIndex];
  }
}
