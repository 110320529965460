<ng-container *ngIf="form" [formGroup]="form">
  <div class="row report-input-sub-section">
    <div
      *ngFor="let item of utilities; let i = index"
      [ngClass]="{ 'col-md-6': true, 'pl-0': true, 'pr-15': i % 2 !== 2, 'pr-0': i % 2 === 2 }"
    >
      <halo-global-utility-card
        [form]="form"
        [title]="item.title"
        [description]="item.description"
        [icon]="item.icon"
        [controlName]="item.controlName"
        [descriptionControlName]="item.descriptionControlName"
      ></halo-global-utility-card>
    </div>
  </div>
</ng-container>
