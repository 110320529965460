import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { WorkfileRecordDto } from '@proxy/workfile/workfile/v1';

@Component({
  selector: 'jaro-kit-workfile-preview-modal',
  templateUrl: './workfile-preview-modal.component.html',
  styleUrls: ['./workfile-preview-modal.component.scss'],
})
export class WorkfilePreviewModalComponent implements OnInit {
  workfile: WorkfileRecordDto;


  constructor(
    public dialogRef: MatDialogRef<WorkfilePreviewModalComponent>,
    @Inject(MAT_DIALOG_DATA)
    public workfileData: {
      workfile: WorkfileRecordDto;
    }
  ) {}

  ngOnInit(): void {
    this.workfile = this.workfileData.workfile;
  }

  pdfLoadingFailed() {
    this.dialogRef.close(false);
  }

  close() {
    this.dialogRef.close(null);
  }

}
