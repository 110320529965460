import { NestedTreeControl } from '@angular/cdk/tree';
import {
  Component,
  EventEmitter,
  Inject,
  Input,
  Output,
  QueryList,
  ViewChildren,
} from '@angular/core';
import { MatMenuTrigger } from '@angular/material/menu';
import { MatTreeNestedDataSource } from '@angular/material/tree';
import { BehaviorSubject, fromEvent, map } from 'rxjs';
import { FeatureNames } from 'src/app/shared/enums/report-input.enum';
import InjectionSymbol from 'src/app/shared/injection/injection-symbol';
import { MenuItemModel } from 'src/app/shared/models/menuItem.model';
import { MenuUpdateModel } from 'src/app/shared/models/menuUpdate.model';
import { EventService } from 'src/app/shared/services/event.service';

@Component({
  selector: 'jaro-kit-report-input-sidebar',
  templateUrl: './report-input-sidebar.component.html',
  styleUrls: ['./report-input-sidebar.component.scss'],
})
export class ReportInputSidebarComponent {
  @ViewChildren('menuTrigger') menuTriggers: QueryList<MatMenuTrigger>;

  @Input() isExpanded: boolean = true;
  @Input() menuItems: MenuItemModel[];
  @Input() featureRouteSegment: string;
  @Input() menuUpdateModel = new BehaviorSubject<MenuUpdateModel>(null);

  @Output() toggleMenu = new EventEmitter<boolean>();
  @Output() onSelectMenuItem = new EventEmitter<string>();

  isShowToggleMenu = false;
  treeControl = new NestedTreeControl<MenuItemModel>((node) => node.children);
  dataSource = new MatTreeNestedDataSource<MenuItemModel>();
  selectedSubMenu: string;
  isRolling: boolean;
  checkRollingFunc: any;
  subMenuItems: MenuItemModel[];
  isMouseover: boolean;

  constructor(
    @Inject(InjectionSymbol.EventService)
    private eventService: EventService
  ) {        
  }

  ngOnInit(): void {
    // wait for generate report template
    setTimeout(() => {
      const contentReportInput = document.querySelector('.report-input-detail-page');
      const scrollEvent = fromEvent(contentReportInput, 'scroll').pipe(
        map(() => contentReportInput)
      );

      scrollEvent.subscribe(() => {
        if (!this.isRolling) this.detectScrollToSection(contentReportInput);
      });
    }, 1000);
    this.dataSource.data = this.menuItems;
    const featureMenu = this.menuItems.find(
      (item) => item.routeSegment === this.featureRouteSegment
    );
    this.subMenuItems = featureMenu.children;

    this.resetSelected();

    // Init the menu expansion
    this.toggleMenu.emit(true);
    this.onToggleSidebar();

    this.eventService.onGoToSection.subscribe((featureRouteSegment) => {
      if ( featureRouteSegment && featureRouteSegment !== this.featureRouteSegment) {
        const node = this.menuItems.find(item => item.routeSegment === featureRouteSegment);
        if(node) {
          this.onToggleMenu(node);
        }
      }
    });

    this.menuUpdateModel.subscribe((value: MenuUpdateModel) => {
      if(value.menuModel === null) {
        return;
      }

      if(this.dataSource?.data) {
        MenuItemModel.copyAllSelections(this.dataSource.data, value.menuModel);        
      }

      const expandedMenuItems: MenuItemModel[] = [];
      value.menuModel.forEach((menuItem) => {
      const correspondingSourceModel = this.dataSource.data.find(model => model.routeSegment === menuItem.routeSegment &&
                                                                          model.title === menuItem.title);

        if(correspondingSourceModel && this.treeControl.isExpanded(correspondingSourceModel) || value.selectedFeatureRouteSegment === menuItem.routeSegment) {
          expandedMenuItems.push(menuItem);
        }

        if(value.selectedFeatureRouteSegment) {
          menuItem.isSelected = value.selectedFeatureRouteSegment === menuItem.routeSegment;
        }
      });
      
      this.dataSource = new MatTreeNestedDataSource<MenuItemModel>();
      this.dataSource.data = value.menuModel;

      expandedMenuItems.forEach((menuItem) => {
        this.treeControl.expand(menuItem);
      });
  });
  }

  detectScrollToSection(contentReportInput) {
    for (const menuItem of this.subMenuItems) {
      const contentSection = document.querySelector(`#${menuItem.id}`);
      if (contentSection) {
        const sectionIsInViewport = this.isInViewport(contentSection, contentReportInput);
        if (sectionIsInViewport) {
          this.resetSelected();
          menuItem.isSelected = true;
        } else {
          menuItem.isSelected = false;
        }
      }
    }

    const reportMenu = this.dataSource.data.find(
      (item) => item.routeSegment === FeatureNames.Report
    );
    reportMenu.hasChildSelected = reportMenu.routeSegment === this.featureRouteSegment;
  }

  private isInViewport(elmSection, contentReportInput) {
    const elementTop = elmSection.offsetTop;
    const elementBottom = elementTop + elmSection.offsetHeight;

    const viewportTop = contentReportInput.scrollTop;
    const viewportBottom = viewportTop + contentReportInput.clientHeight;

    // Use the contentReportInput height to compare if the element is too high to fit within contentReportInput.
    const compareBottom =
      elementBottom - elementTop < contentReportInput.clientHeight
        ? elementBottom
        : elementTop + contentReportInput.clientHeight - 50;
    // Some small margin to not need to be an exact fit
    return compareBottom > viewportTop && elementTop < viewportBottom;
  }

  onClickSubMenu(node: MenuItemModel) {
    this.selectedSubMenu = node.routeSegment;
    // Clear the timeout and enable isRolling when auto scrolling begins.
    this.isRolling = true;
    if (this.checkRollingFunc) {
      clearTimeout(this.checkRollingFunc);
    }
    this.checkRollingFunc = setTimeout(() => {
      this.isRolling = false;
    }, 1000);

    document.getElementById(node.id).scrollIntoView();
  }

  onToggleMenu(node: MenuItemModel) {
    this.closeAllSubMenu();
    if (node.isParent) {
      this.selectedSubMenu = null;
      this.featureRouteSegment = node.routeSegment;
      this.resetSelected();
      node.isSelected = true;
      this.onSelectMenuItem.emit(node.routeSegment);
    } else if (node.parentTitle === this.featureRouteSegment) {
      const parentNode = this.dataSource.data.find((item) => item.routeSegment === node.parentTitle);
      parentNode.children.forEach((child) => (child.isSelected = false));
      node.isSelected = true;
      parentNode.hasChildSelected = true;
      parentNode.isSelected = false;
      this.onClickSubMenu(node);
    } else {
      this.resetSelected();
      const parentNode = this.dataSource.data.find((item) => item.routeSegment === node.parentTitle);
      this.onToggleMenu(parentNode);
      setTimeout(() => {
        parentNode.hasChildSelected = true;
        this.onToggleMenu(node);
      }, 1000);
    }
  }

  private resetSelected() {
    this.dataSource.data.forEach((item) => {
      item.isSelected = item.routeSegment === this.featureRouteSegment;
      item.hasChildSelected = false;
      (item.children || []).forEach((child) => (child.isSelected = false));
    });   
  }

  onToggleSidebar() {
    this.eventService.toggleSidebar(this.isExpanded);
  }

  hasChild = (_: number, node: MenuItemModel) => !!node.children && node.children.length > 0;

  mouseover(_node, menuTrigger) {
    if (!menuTrigger.menuOpen) {
      this.closeAllSubMenu();
      if (!this.isExpanded) {
        menuTrigger.openMenu();
      }
    }
  }

  closeAllSubMenu() {
    (this.menuTriggers || []).forEach((item) => {
      if (item.menuOpen) {
        item.closeMenu();
      }
    });
  }

  mouseoverMenu(){
    this.isMouseover = true;
  }

  mouseoutMenu(){
    this.isMouseover = false;
  }
}
