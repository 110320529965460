import { Component, Inject, Input, OnInit } from '@angular/core';
import { SubjectSaleDto } from '@proxy/appraisal/report/reports/v1/form1004';
import { ColumnMode } from '@swimlane/ngx-datatable';
import { PropertyRecordDto } from '@proxy/property/property-record/property-records/v1';
import { ReportInputTemplateService } from 'src/app/features/shared/forms/providers/report-input-template.service';
import InjectionSymbol from 'src/app/shared/injection/injection-symbol';

@Component({
  selector: 'jaro-kit-comp-sale-history',
  templateUrl: './comparable-sale-history.component.html',
  styleUrls: ['./comparable-sale-history.component.scss'],
})
export class ComparableSaleHistoryComponent implements OnInit {
  @Input() property: PropertyRecordDto;
  saleHistory: SubjectSaleDto[];
  ColumnMode = ColumnMode;

  constructor(
    @Inject(InjectionSymbol.ReportInputTemplateService)
    private reportInputTemplateService: ReportInputTemplateService
  ) {}

  ngOnInit(): void {
    this.saleHistory = this.property?.transferHistory ||  [];
  }
}
