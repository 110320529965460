<ng-container *ngIf="form" [formGroup]="form" >
  <p class="report-input-title pb-15">Property Details</p>
  <div class="row pb-15">
    <div class="col-md-6 ps-0 pe-15 report-input-form-field">
      <mat-form-field appearance="outline">
        <mat-label >Street Address</mat-label>
        <input matInput id="subjectStreetAddress" formControlName="subjectStreetAddress" autocomplete="off"/>
      </mat-form-field>
      <jaro-kit-validation-message [form]="form" [controlName]="'subjectStreetAddress'" [isRequired]="true"></jaro-kit-validation-message>
    </div>
    <div class="col-md-6 pl-15 pe-0 report-input-form-field">      
      <mat-form-field appearance="outline">
        <mat-label >Apt/Unit</mat-label>
        <input matInput id="subjectUnit" formControlName="subjectUnit" autocomplete="off"/>
      </mat-form-field>
    </div>
    <div class="col-md-6 ps-0 pe-15 report-input-form-field">
      <mat-form-field appearance="outline">
        <mat-label>City</mat-label>
        <input matInput id="subjectCity" formControlName="subjectCity" autocomplete="off"/>
      </mat-form-field>
      <jaro-kit-validation-message [form]="form" [controlName]="'subjectCity'" [isRequired]="true"></jaro-kit-validation-message>
    </div>
    <div class="col-md-3 ps-15 pe-15 capitalize report-input-form-field">               
      <mat-form-field appearance="outline">
        <mat-label class="report-input-label">State</mat-label>
        <mat-select
          id="subjectState" formControlName="subjectState"
          disableOptionCentering
          panelClass="report-input-drop-down"
          placeholder="Select from list"
          floatLabel="never"
        >
          <mat-select-trigger>
            {{form.controls['subjectState'].value}}
          </mat-select-trigger>
          <mat-option *ngFor="let item of addressStateOptions" [value]="item.value">{{
            item.name
          }}</mat-option>
        </mat-select>
      </mat-form-field>
      <jaro-kit-validation-message [form]="form" [controlName]="'subjectState'" [isRequired]="true"></jaro-kit-validation-message>
    </div>
    <div class="col-md-3 ps-15 pe-0 capitalize report-input-form-field">      
      <mat-form-field appearance="outline">
        <mat-label>Zip Code</mat-label>
        <input matInput id="subjectPostalCode" formControlName="subjectPostalCode" autocomplete="off" [mask]="'00000-0000'" [validation]="false" [pattern]="'^\\d{5}(\\d{4})?$'"/>
      </mat-form-field>
      <jaro-kit-validation-message [form]="form" [controlName]="'subjectPostalCode'" [isRequired]="true" [format]="'nnnnn, nnnnn-nnnn, or nnnnnnnnn'"></jaro-kit-validation-message>
    </div>
    <div class="col-md-6 pl-0 pe-15 report-input-form-field">
      <mat-label>Association (Y/N)</mat-label>
      <div>
        <mat-radio-group id="subjectAssociation" formControlName="subjectAssociation" (change)="onChangeSubjectPUD($event)">
          <mat-radio-button [value]="true" class="report-input-radio-button">Yes</mat-radio-button>
          <mat-radio-button [value]="false" class="report-input-radio-button">No</mat-radio-button>
        </mat-radio-group>
      </div>
    </div>
    <div class="col-md-6 ps-15 pe-0 report-input-form-field">&nbsp;</div>
    <div *ngIf="isShowHOADetails" class="col-md-6 ps-0 pe-15 report-input-form-field">
      <mat-form-field appearance="outline">
        <mat-label>Homeowner Association $</mat-label>
        <input
          matInput
          id="subjectHomeownerAssociation" formControlName="subjectHomeownerAssociation"
          autocomplete="off"
          currencyMask
          mask="separator.0"
          thousandSeparator=","
          separatorLimit="999999999"
          [allowNegativeNumbers]="false"
        />
      </mat-form-field>
      <jaro-kit-validation-message [form]="form" [controlName]="'subjectHomeownerAssociation'" [isRequired]="true"></jaro-kit-validation-message>
    </div>
    <div *ngIf="isShowHOADetails" class="col-md-6 pl-15 pe-0 report-input-form-field">
      <mat-label>HOA $ Frequency</mat-label>
      <div>
        <mat-radio-group id="subjectFrequency" formControlName="subjectFrequency">
          <mat-radio-button value="yearly" class="report-input-radio-button">Yearly</mat-radio-button>
          <mat-radio-button value="monthly" class="report-input-radio-button">Monthly</mat-radio-button>
        </mat-radio-group>
      </div>
    </div>    
  </div>


  <p class="report-input-title pb-15">Public Record Details</p>
  <div class="row pb-15">
    <div class="col-md-6 ps-0 pe-15 report-input-form-field">          
      <mat-form-field appearance="outline">
        <mat-label>Borrower</mat-label>
        <input matInput id="subjectBorrower" formControlName="subjectBorrower" autocomplete="off" />
      </mat-form-field> 
    </div>
    <div class="col-md-6 pl-15 pe-0 report-input-form-field">    
      <mat-form-field appearance="outline">
        <mat-label>Owner</mat-label>
        <input matInput id="subjectOwnerOfPublicRecord" formControlName="subjectOwnerOfPublicRecord" autocomplete="off" />
      </mat-form-field>      
    </div>
    <div class="col-md-6 ps-0 pe-15 report-input-form-field">
      <mat-form-field appearance="outline">
        <mat-label>County</mat-label>
        <input matInput id="subjectCounty" formControlName="subjectCounty" autocomplete="off" />
      </mat-form-field>
    </div>
    <div class="col-md-6 ps-15 pe-0 report-input-form-field">
      <mat-form-field appearance="outline">
        <mat-label>Parcel Number</mat-label>
        <input matInput id="subjectAssessorParcel" formControlName="subjectAssessorParcel" autocomplete="off" />
      </mat-form-field>
      <jaro-kit-validation-message [form]="form" [controlName]="'subjectAssessorParcel'" [isRequired]="true"></jaro-kit-validation-message>
    </div>
    <div class="col-md-6 ps-0 pe-15">
      <mat-form-field appearance="outline" class="jaro-kit-textarea">
        <mat-label>Legal Description</mat-label>
        <textarea
          type="text"
          id="subjectLegalDescription" formControlName="subjectLegalDescription"
          placeholder="Legal description"
          matInput
          rows="4"
        ></textarea>
      </mat-form-field>
      <jaro-kit-validation-message [form]="form" [controlName]="'subjectLegalDescription'" [isRequired]="true"></jaro-kit-validation-message>
    </div>
    <div class="col-md-6 ps-15 pe-0">&nbsp;</div>
    <div class="col-md-6 ps-0 pe-15 report-input-form-field">            
      <mat-form-field appearance="outline">
        <mat-label>Tax Year</mat-label>
        <input
          matInput
          id="subjectTaxYear" formControlName="subjectTaxYear"
          mask="separator.0"
          thousandSeparator=""
          separatorLimit="9999"
          [allowNegativeNumbers]="false"
          autocomplete="off"
        />
      </mat-form-field>
      <jaro-kit-validation-message [form]="form" [controlName]="'subjectTaxYear'" [isRequired]="true"></jaro-kit-validation-message>
    </div>
    <div class="col-md-6 ps-15 pe-0 report-input-form-field">    
      <mat-form-field appearance="outline">
        <mat-label>R.E. Taxes $</mat-label>
        <input
          matInput
          id="subjectTaxes" formControlName="subjectTaxes"
          autocomplete="off"
          currencyMask
          mask="separator.0"
          thousandSeparator=","
          separatorLimit="9999999999"
          [allowNegativeNumbers]="false"
        />
      </mat-form-field>
      <jaro-kit-validation-message [form]="form" [controlName]="'subjectTaxes'" [isRequired]="true"></jaro-kit-validation-message>
    </div>

    <div class="col-md-6 ps-0 pe-15 report-input-form-field">      
      <mat-form-field appearance="outline">
        <mat-label>Neighborhood Name</mat-label>
        <input matInput id="subjectNeighborhoodName" formControlName="subjectNeighborhoodName" autocomplete="off" />
      </mat-form-field>
      <jaro-kit-validation-message [form]="form" [controlName]="'subjectNeighborhoodName'" [isRequired]="true"></jaro-kit-validation-message>
    </div>
    <div class="col-md-6 ps-15 pe-0 report-input-form-field">      
      <mat-form-field class="col-md-12" appearance="outline">
        <mat-label>Map Reference</mat-label>
        <input matInput id="subjectMapReference" formControlName="subjectMapReference" autocomplete="off" />
      </mat-form-field>
    </div>
    <div class="col-md-6 ps-0 pe-15 report-input-form-field">      
      <mat-form-field class="col-md-12" appearance="outline">
        <mat-label>Census Tract</mat-label>
        <input matInput id="subjectCensusTract" formControlName="subjectCensusTract" autocomplete="off" />
      </mat-form-field>
    </div>    
    <div class="col-md-6 ps-15 pe-0">
      <mat-form-field appearance="outline">
        <mat-label>Year Built</mat-label>
        <input
          matInput
          id="exteriorYearBuilt" formControlName="exteriorYearBuilt"
          autocomplete="off"
          thousandSeparator=""
          separatorLimit="9999"
          [allowNegativeNumbers]="false"
          [mask]="'0000'" [validation]="false" [pattern]="'^\\d{4}$'"
        />
      </mat-form-field>
      <jaro-kit-validation-message [form]="form" [controlName]="'exteriorYearBuilt'" [isRequired]="true" [format]="'yyyy'"></jaro-kit-validation-message>
    </div>
    <div class="col-md-6 ps-0 pe-15">      
      <div class="row report-input-form-field">
        <mat-form-field appearance="outline">
          <mat-label>Special Assessments $</mat-label>
          <input
            matInput
            id="subjectSpecialAssessment" formControlName="subjectSpecialAssessment"
            autocomplete="off"
            currencyMask
            mask="separator.0"
            thousandSeparator=","
            separatorLimit="9999999999"
            [allowNegativeNumbers]="false"
          />
        </mat-form-field>
        <jaro-kit-validation-message [form]="form" [controlName]="'subjectSpecialAssessment'" [isRequired]="true"></jaro-kit-validation-message>
      </div>
    </div>    
    <div class="col-md-12 pl-0 pe-15 report-input-form-field">
      <p class="report-input-label report-input-radio-label">Property Rights Appraised</p>
      <mat-radio-group id="subjectRightsAppraised" formControlName="subjectRightsAppraised" >
        <mat-radio-button
          *ngFor="let option of rightsAppraisedOptions"
          [value]="option.value"
          (change)="onChangeRightsAppraised($event)"
          class="report-input-radio-button"
          >{{ option.name }}</mat-radio-button
        >
      </mat-radio-group>
    </div>
    <div class="col-md-6 pl-15 pe-0">&nbsp;</div>
    <div class="ps-0 pe-0 col-md-12">
      <div class="row report-input-form-field">
        <mat-form-field *ngIf="isShowRightsAppraisedOther" class="jaro-kit-textarea" appearance="outline">
          <mat-label>Property Rights Appraised Other (describe)</mat-label>
          <textarea
            type="text"
            id="subjectRightsAppraisedOther" formControlName="subjectRightsAppraisedOther"
            placeholder="Property Rights Appraised Other (describe)"
            matInput
            rows="4"
          ></textarea>
        </mat-form-field>
      </div>
    </div>
  </div>


  <p class="report-input-title pb-15">FEMA</p>
  <div class="row pb-15">
    <div class="col-md-6 ps-0 pe-15 report-input-form-field">
      <mat-label>Special Flood Hazard Area</mat-label>
      <div>
        <mat-radio-group id="siteIsFemaSpecial" formControlName="siteIsFemaSpecial" class="mt-15 mb-15">
          <mat-radio-button [value]="true" class="report-input-radio-button">Yes</mat-radio-button>
          <mat-radio-button [value]="false" class="report-input-radio-button">No</mat-radio-button>
        </mat-radio-group>
      </div>   
    </div>

    <div class="col-md-6 ps-15 pe-0 report-input-form-field">
      <mat-form-field appearance="outline">
        <mat-label>Flood Zone</mat-label>
        <input matInput id="siteFemaFloodZone" formControlName="siteFemaFloodZone" autocomplete="off" />
      </mat-form-field>
    </div>

    <div class="col-md-6 ps-0 pe-15 report-input-form-field">
      <mat-form-field appearance="outline">
        <mat-label>Map #</mat-label>
        <input matInput id="siteFemaMap" formControlName="siteFemaMap" autocomplete="off" />
      </mat-form-field>
    </div>

    <div class="col-md-6 ps-15 pe-0 report-input-form-field">
      <jaro-kit-date-picker
        [form]="form"
        dateControl="siteFemaMapDate"
        titleDate="Map Date"
      ></jaro-kit-date-picker>        
    </div>
  </div>
</ng-container>
