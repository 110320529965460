import { PartyType } from '@proxy/appraisal/report/reports';
import { ReportDto } from '@proxy/appraisal/report/v1/form1004';
import { get, set } from 'lodash-es';
import { ReportInputDataFormFieldModel } from 'src/app/features/shared/forms/models/report-input-data-field-form-model/report-input-date-field-form-model';
import { ReportInputTemplateField } from '../../../shared/forms/models/report-input-layout.model';

export const JaroBoostTemplateConfigOrderDetailsSection: ReportInputDataFormFieldModel[] = [
  {
    formControlName: 'subjectLenderClient',
    updatePath: 'valuation.parties.lender.name',
    mapToFieldFunc: (value) => {
      const data = (value || []).find((item) => item.partyType === PartyType.Lender);
      return data?.name;
    },
    mapToModelFunc: (value, reportDto: ReportDto, formFieldConfig: ReportInputTemplateField) => {
      const data = get(reportDto, formFieldConfig.path) || [];
      let borrower = data.find((item) => item.partyType === PartyType.Lender);
      if (borrower) {
        borrower.name = value;
      } else {
        // Not found the Lender
        borrower = {
          name: value,
          partyType: PartyType.Lender,
        };
        data.push(borrower);
        set(reportDto, formFieldConfig.path, data);
      }
    },
  },
  {
    formControlName: 'subjectLenderAddress',
    updatePath: 'valuation.parties.lender.address',
    mapToFieldFunc: (value) => {
      const data = (value || []).find((item) => item.partyType === PartyType.Lender);
      return data?.address;
    },
    mapToModelFunc: (value, reportDto: ReportDto, formFieldConfig: ReportInputTemplateField) => {
      const data = get(reportDto, formFieldConfig.path) || [];
      let borrower = data.find((item) => item.partyType === PartyType.Lender);
      if (borrower) {
        borrower.address = value;
      } else {
        // Not found the Lender
        borrower = {
          address: value,
          partyType: PartyType.Lender,
        };
        data.push(borrower);
        set(reportDto, formFieldConfig.path, data);
      }
    },
  },  
];
