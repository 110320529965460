
export class MenuItemModel {
    title: string;
    routeSegment: string;
    id?: string;
    icon?: string;
    iconSelected?: string;
    image?: string;
    children?: MenuItemModel[];
    isParent?: boolean;
    isSelectedByDefault?: boolean;
    parentTitle?: string;
    accept?: string[];
    deny?: string[];
    isSelected?: boolean;
    hasChildSelected?: boolean;
    
    public constructor(routeSegment: string, title: string, isParent: boolean, isSelectedByDefault: boolean) {
        this.routeSegment = routeSegment;
        this.title = title;
        this.isParent = isParent;
        this.isSelectedByDefault = isSelectedByDefault;
    }

    public static copyAllSelections(sourceModels: MenuItemModel[], targetModels: MenuItemModel[]) {
        if(!sourceModels || !targetModels) {
            return;
        }

        targetModels.forEach((targetModel) => {
            if(!targetModel) {
                return;
            }

            const correspondingSourceModel = sourceModels.find(model => model.routeSegment === targetModel.routeSegment &&
                                                                    model.title === targetModel.title);
                           
            if(correspondingSourceModel === undefined) {
                return;
            }          
            
            MenuItemModel.copySelections(correspondingSourceModel, targetModel)
        })
    }

    public static copySelections(sourceModel: MenuItemModel, targetModel: MenuItemModel) {
        if(!sourceModel) {
            return;
        }
        
        targetModel.isSelectedByDefault = sourceModel.isSelectedByDefault;
        targetModel.isSelected = sourceModel.isSelected;
        targetModel.hasChildSelected = sourceModel.hasChildSelected;

        if(!targetModel.children || !sourceModel.children) {
            return;
        }

        MenuItemModel.copyAllSelections(sourceModel.children, targetModel.children);
    }
  }