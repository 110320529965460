import { CoreModule } from '@abp/ng.core';
import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core';
import { OrderAssignmentService } from '@proxy/ascent/order/order-assignment/controllers/v2/order-assignment.service';
import { environment } from '../../environments/environment';
import InjectionSymbol from '../shared/injection/injection-symbol';
import { AdminRoutingModule } from './admin-routing.module';
import { AdminSidebarModule } from './admin-sidebar/admin-sidebar.module';
import { AdminComponent } from './admin.component';
import { OrderSearchModule } from './order-search/order-search.module';


@NgModule({
  imports: [
    AdminRoutingModule,
    OrderSearchModule,
    AdminSidebarModule,
    CoreModule.forRoot({ environment, skipGetAppConfiguration: false }),
  ],

  declarations: [AdminComponent],
  providers: [
    { provide: InjectionSymbol.OrderAssignmentService, useClass: OrderAssignmentService }
  ],
  bootstrap: [AdminComponent],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
})
export class AdminModule {}
