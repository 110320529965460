<ng-container *ngIf="form" [formGroup]="form">

  <div class="row report-input-sub-section">
    <div class="col-md-6 pl-0 pr-15">

      <mat-form-field appearance="outline">
        <mat-label class="report-input-label">Street (Road Surface)</mat-label>
        <mat-select
          formControlName="siteStreetSurface"
          disableOptionCentering
          panelClass="report-input-drop-down"
          placeholder="Select from list"
          floatLabel="never"
        >
          <mat-option *ngFor="let item of streetRoadSurfaceOptions" [value]="item.value">{{
            item.name
          }}</mat-option>
        </mat-select>
      </mat-form-field>

      <div class="report-input-form-field">
        <p class="report-input-label report-input-radio-label">Street</p>
        <mat-radio-group formControlName="siteStreet" class="mt-15 mb-15">
          <mat-radio-button value="public" class="report-input-radio-button">Public</mat-radio-button>
          <mat-radio-button value="private" class="report-input-radio-button">Private</mat-radio-button>
        </mat-radio-group>
      </div>

      <mat-form-field appearance="outline">
        <mat-label class="report-input-label">Alley (Road Surface)</mat-label>
        <mat-select
          formControlName="siteAlleySurface"
          disableOptionCentering
          panelClass="report-input-drop-down"
          placeholder="Select from list"
          floatLabel="never"
        >
          <mat-option *ngFor="let item of alleyRoadSurfaceOptions" [value]="item.value">{{
            item.name
          }}</mat-option>
        </mat-select>
      </mat-form-field>

      <div class="report-input-form-field">
        <p class="report-input-label report-input-radio-label">Alley</p>
        <mat-radio-group formControlName="siteAlley" class="mt-15 mb-15">
          <mat-radio-button value="public" class="report-input-radio-button">Public</mat-radio-button>
          <mat-radio-button value="private" class="report-input-radio-button">Private</mat-radio-button>
        </mat-radio-group>
      </div>
    </div>

    <div class="col-md-6 pl-15 pr-0">
      <span class="report-input-label mt-0 pt-0">
        Are the utilities and off-site improvements typical for the market area?
      </span>
      <div>
        <mat-radio-group formControlName="siteAreSiteTypical" class="mt-15 mb-15">
          <mat-radio-button [value]="true" class="report-input-radio-button">Yes</mat-radio-button>
          <mat-radio-button [value]="false" class="report-input-radio-button">No</mat-radio-button>
        </mat-radio-group>
      </div>

      <mat-form-field appearance="outline" class="jaro-kit-textarea">
        <textarea
          type="text"
          formControlName="siteAreSiteTypicalDescription"
          placeholder="If No, describe"
          matInput
          rows="4"
        ></textarea>
      </mat-form-field>

      <span class="report-input-label">Any adverse site conditions or external factors?</span>
      <div>
        <mat-radio-group formControlName="siteAreThereSiteConditions" class="mt-15 mb-15">
          <mat-radio-button [value]="true" class="report-input-radio-button">Yes</mat-radio-button>
          <mat-radio-button [value]="false" class="report-input-radio-button">No</mat-radio-button>
        </mat-radio-group>
      </div>

      <mat-form-field appearance="outline" class="jaro-kit-textarea">
        <textarea
          type="text"
          formControlName="siteConditionsDescription"
          placeholder="If Yes, describe"
          matInput
          rows="4"
        ></textarea>
      </mat-form-field>
    </div>
  </div>
</ng-container>
