<ng-container *ngIf="form" [formGroup]="form">
  <div class="row pl-15 pr-15">
    <div class="col-md-12 pl-0 pr-0">
      <p class="report-input-label">{{this.subSection.title || 'Additional Comments'}}</p>
      <div class="editor-container">
        <jaro-kit-editor [markDown]="markDown" [isReadonly]="true"></jaro-kit-editor>
      </div>
    </div>
  </div>
</ng-container>
