<halo-global-popup [dialogRef]="dialogRef" [isHideClose]="true">
  <div
    class="document-review-container"
    [ngClass]="{
      'document-review-container': true
    }"
  >
    <div class="document-review-top">
      <div class="document-top-left">
        <span class="document-title">{{ document.title }}.pdf</span>
      </div>

      <div class="document-top-right">
        <div class="space"></div>
        <button class="jaro-kit-primary-button mouse-cursor-pointer" (click)="download()">Download</button>
        <div class="save-btn">
          <button
            class="jaro-kit-primary-button mouse-cursor-pointer"
            (click)="closeDialog()"
          >
            Close
          </button>

          <span> {{ document.pages?.length ?? 0 }} selected</span>
        </div>
      </div>
    </div>
    <div class="document-review-detail" *ngIf="document?.extractedURL">
      <jaro-kit-preview-document
        [url]="document.extractedURL"
        (onPdfLoadingFailed)="pdfLoadingFailed()"
        [(isLoading)]="isLoading"
        [paginationAttributeSuffix]="paginationAttributeSuffix"
      ></jaro-kit-preview-document>
    </div>
  </div>
</halo-global-popup>
