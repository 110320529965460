<div class="quality-check-list">
  <div class="quality-check-list-top">
    <span class="quality-check-list-title">Quality Check</span>
    <button (click)="refreshQualityCheck()">
      <em class="fa-regular fa-arrows-rotate"></em>Refresh
    </button>
    <button (click)="closeQualityCheck()"><em class="fal fa-times"></em>Close</button>
  </div>

  <div class="report-validation-message">
    <ng-container *ngIf="!isLoading && (!qualityCheckList || qualityCheckList.length == 0)">    
      <div [ngClass]="{'empty-panel': true, 'pass-panel': isRefreshQualityCheckSuccessful}"  >        
        <div class="detail" style="margin: auto">
          <span *ngIf="isRefreshQualityCheckSuccessful" class="description">Quality Check Passed</span>
          <span *ngIf="!isRefreshQualityCheckSuccessful" class="description">Failed to Load Quality Check</span>
        </div>
      </div>
    </ng-container>
    <jaro-kit-tree-node-component *ngIf="!isLoading && qualityCheckList && qualityCheckList?.length > 0"
      [treeNodeItems]="qualityCheckList"
      (onClickNode)="gotoField($event)"      
    ></jaro-kit-tree-node-component>
    <mat-spinner
      *ngIf="isLoading"
      value="100"
      diameter="100"
      strokeWidth="5"
      class="action-loading-spinner"
    ></mat-spinner>
  </div>
  
</div>
