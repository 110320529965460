import { Component, EventEmitter, Input, Output, } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { ComparableDto } from '@proxy/appraisal/comparables/comparables/v1/models';
import { PropertyRecordDto } from '@proxy/property/property-record/property-records/v1/models';
import { BehaviorSubject } from 'rxjs';
import { PropertySearchModel, PropertySearchResponse } from 'src/app/proxy-adapter/property/property-job/models';

@Component({
  selector: 'jaro-kit-market-comparables',
  templateUrl: './market-comparables.component.html',
  styleUrls: ['./market-comparables.component.scss'],
})
export class MarketComparablesComponent {
  @Input() orderAssignmentId: string;
  @Input() selectedComparables: BehaviorSubject<[PropertySearchModel, ComparableDto][]>;
  @Input() defaultSearchResponse: BehaviorSubject<PropertySearchResponse>;
  @Input() filteredSearchResponse: BehaviorSubject<PropertySearchResponse>;
  @Input() isLoading: boolean = true;
  @Input() hasSelectedComparableChanges: boolean = false;
  @Output() onComparableAdded = new EventEmitter<string>();
  @Output() onComparableRemoved = new EventEmitter<string>();
  @Output() onPropertyEditRequested = new EventEmitter<string>();
  @Output() onComparableSaveForCleanUpRequested = new EventEmitter<PropertyRecordDto>();
  @Output() onComparableSaveRequested = new EventEmitter();
  @Output() onReSelectedComparables = new EventEmitter();

  constructor(
    public matDialog: MatDialog
  ) {}

  public editProperty(id: string) {
    if(this.isLoading)
      return;

    this.onPropertyEditRequested.emit(id);
  }

  public addComparable(id: string) {
    if(this.isLoading)
      return;

    this.onComparableAdded.emit(id);
  }

  public removeComparable(id: string) {
    if(this.isLoading)
      return;

    this.onComparableRemoved.emit(id);
  }

  saveComparables(): void {
    this.onComparableSaveRequested.emit();
  }

  reSelectedComparables(): void {
    this.onReSelectedComparables.emit();
  }
}
