<div class="photo-page" cdkDropListGroup>
  <mat-spinner
    *ngIf="isLoading"
    value="100"
    diameter="100"
    strokeWidth="5"
    class="action-loading-spinner"
  ></mat-spinner>

  <ng-container *ngIf="!isLoading">
    <div class="add-section-title mouse-cursor-pointer">
      <span (click)="addNewSectionClick()">Add Section</span>
    </div>
    <jaro-kit-photo-sections
      [orderAssignmentId]="orderAssignmentId"
      [reportId]="reportId"
      [reportVersion]="reportVersion"
      [photoSections]="photoSections"
      [galleryPhotos]="galleryPhotos"
      [sections]="sections"
      [maximumOfFileToUpload]="maximumOfFileToUpload"
      (onMakeCoverPhoto)="makeCoverPhoto($event)"
      (onAddPhotosToSection)="addPhotosToSection($event)"
      (onRemovePhotoFromSection)="removePhotoFromSection($event)"
      (onMovePhoto)="movePhoto($event)"
      (onUpdatePhotoTitle)="updatePhotoTitle($event)"
      (onUpdateSection)="updateSection($event)"
      (onDeleteSection)="deleteSection($event)"
    ></jaro-kit-photo-sections>
    <div class="add-section-container" *ngIf="isAddSection">
      <jaro-kit-edit-section-name
        #createNewSection
        [sections]="sections"
        (onAddNewSection)="addNewSection($event)"
        (onCancelAddNewSection)="cancelAddNewSection()"
      ></jaro-kit-edit-section-name>
    </div>
  </ng-container>

  <halo-global-button-photo-gallery
    *ngIf="!isShowPhotoGallery"
    [isLoading]="isLoading"
    (onOpenPhotoGallery)="openPhotoGallery($event)"
  ></halo-global-button-photo-gallery>

  <jaro-kit-report-resource-collection
    #reportResourceCollection
    *ngIf="isShowPhotoGallery"
    [orderAssignmentId]="orderAssignmentId"
    [reportId]="reportId"
    [reportVersion]="reportVersion"
    [photoSections]="photoSections"
    [items]="galleryPhotos"
    [isLoading]="isLoading"
    [propertyId]="propertyId"
    [properties]="properties"
    [isDisabledProperty]="true"
    [reportResourceType]="reportResourceType"
    (onOpenPhotoGallery)="openPhotoGallery($event)"
  ></jaro-kit-report-resource-collection>
</div>
