import { Injectable, Pipe, PipeTransform } from '@angular/core';
import { OrderDetailDto } from '@proxy/ascent/order/order-assignment/order-assignments/v2/orderDetailDto';

@Injectable({
  providedIn: 'root',
})
@Pipe({
  name: 'googleSearchAddressUrlFormat',
})
export class GoogleSearchAddressUrlFormatPipe implements PipeTransform {
  transform(orderData: OrderDetailDto): string {    
    const addressLineFormatted = orderData?.address?.fullAddressFormatted?.split(' ')?.join('+');
    return addressLineFormatted ? `http://www.google.com/search?q=${encodeURIComponent(addressLineFormatted)}` : "http://www.google.com";   
  }
}