<ng-container *ngIf="form" [formGroup]="form" class="additional-from">
  <div class="row">
    <div class="col-md-6 pl-0 pr-15">
      <p class="report-input-title">Attic</p>
      <div class="report-input-radio">
        <mat-radio-group
          formControlName="additionalAtticCheckbox"
          class="mt-15 mb-15"
          (change)="onChangeAttic($event)"
        >
          <mat-radio-button [value]="true" class="report-input-radio-button">Yes</mat-radio-button>
          <mat-radio-button [value]="false" class="report-input-radio-button">No</mat-radio-button>
        </mat-radio-group>
      </div>

      <div class="row" *ngIf="isShowAttic">
        <div class="col-md-4 pl-0 pr-15" *ngFor="let option of atticOption">
          <mat-checkbox
            [formControlName]="'additionalAttic' + option.name"
            [value]="option.value"
            [checked]="option.selected"
            (change)="toggleCheckbox($event, option)"
            class="report-input-radio-button"
          >
            {{ option.name }}
          </mat-checkbox>
        </div>

        <div *ngIf="hasError('additionalAttic', 'required')" class="col-12 validation-error pl-0">
          Attic is required
        </div>
      </div>
    </div>

    <div class="col-md-6 pl-15 pr-0">
      <p class="report-input-title">Mechanical Systems</p>

      <div class="mt-15 mb-15">
        <halo-global-multiple-combo-box
          label="Heating"
          placeholder="New Heating..."
          [options]="heatingOption"
          [(value)]="heating"
          [formGroup]="form"
          formControlName="additionalHeating"
        >
        </halo-global-multiple-combo-box>
      </div>

      <mat-form-field appearance="outline">
        <mat-label>Fuel</mat-label>
        <input matInput formControlName="additionalFuel" />
      </mat-form-field>

      <div class="mt-15 mb-15">
        <halo-global-multiple-combo-box
          label="Cooling"
          placeholder="New Cooling..."
          [options]="coolingOption"
          [(value)]="cooling"
          [formGroup]="form"
          formControlName="additionalCooling"
        >
        </halo-global-multiple-combo-box>
      </div>
    </div>
  </div>
</ng-container>
