import { Confirmation } from '@abp/ng.theme.shared';
import { Component, EventEmitter, Inject, Input, Output } from '@angular/core';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { WorkfileRecordDto } from '@proxy/workfile/workfile/v1';
import { ColumnMode } from '@swimlane/ngx-datatable';
import { Observable } from 'rxjs';
import { WorkfileServiceInterface } from 'src/app/interface/workfile/workfile-interface.service';
import InjectionSymbol from 'src/app/shared/injection/injection-symbol';
import { FileUploading } from 'src/app/shared/services/file-uploading';
import { MessageService } from 'src/app/shared/services/message.service';
import { WorkfilePreviewImageComponent } from '../workfile-preview-image/workfile-preview-image.component';
import { WorkfilePreviewModalComponent } from '../workfile-preview-modal/workfile-preview-modal.component';

@Component({
  selector: 'jaro-kit-workfile-items',
  templateUrl: './workfile-items.component.html',
  styleUrls: ['./workfile-items.component.scss'],
})
export class WorkfileItemsComponent {
  @Input() workfileUploading: FileUploading[] = [];
  @Input() workfiles: WorkfileRecordDto[];
  @Input() orderAssignmentId: string;
  @Input() orderTimezone: string;
  ColumnMode = ColumnMode;


  @Output() onDeleteWorkfile = new EventEmitter<WorkfileRecordDto>();


  constructor(
    @Inject(InjectionSymbol.WorkfileService)
    private workfileService: WorkfileServiceInterface,    
    private msgService: MessageService,
    private matDialog: MatDialog
  ) { }


  tryPreviewWorkfile(workfile: WorkfileRecordDto) {
    if (!this.supportsPreview(workfile)) {
      this.msgService.error("Can't preview this file type")
      return;
    }

    if (this.isFileAvailable(workfile)) {
      this.previewWorkfile(workfile);
    }
    else {
      // try to fetch again - most likely the file has not been marked as available by now
      this.workfileService.get(workfile.orderAssignmentId, workfile.id)
        .subscribe({
          next: (result: WorkfileRecordDto) => {
            if (!this.isFileAvailable(result)) {
              this.msgService.error("The file is still processing. Please try again later");
              return;
            }
            this.previewWorkfile(result);
          }
        });
    }
  }


  private previewWorkfile(workfile: WorkfileRecordDto) {
    if (this.isImage(workfile))
      this.openImagePreview(workfile);
    else if (this.isPdf(workfile))
      this.openDocumentPreview(workfile);
  }

  fetchUpdatedWorkfile(workfile: WorkfileRecordDto): Observable<WorkfileRecordDto> {
    return this.workfileService.get(workfile.orderAssignmentId, workfile.id);
  }

  downloadWorkfile(workfile: WorkfileRecordDto): void {
    if (workfile.isFileAvailable || !workfile.url) {
      const a = document.createElement('a');
      a.href = workfile.url;
      a.download = workfile.title;

      a.click();
      document.removeChild(a);
    }
  }

  isFileAvailable(workfile: WorkfileRecordDto): boolean {
    return workfile.isFileAvailable && workfile.url != null;
  }

  supportsPreview(workfile: WorkfileRecordDto): boolean {
    return (this.isImage(workfile) || this.isPdf(workfile));
  }

  private openImagePreview(workfile: WorkfileRecordDto) {
    const dialogConfig = new MatDialogConfig();
    // selected all when pages is null or empty
    dialogConfig.data = {
      workfile: workfile
    };

    dialogConfig.panelClass = 'document-review-modal';
    let dialogRef = this.matDialog.open(WorkfilePreviewImageComponent, dialogConfig);
    dialogRef.afterClosed().subscribe({
      next: () => {
        // do nothing
      },
    });
  }

  private openDocumentPreview(workfile: WorkfileRecordDto) {
    const dialogConfig = new MatDialogConfig();
    // selected all when pages is null or empty
    dialogConfig.data = {
      workfile: workfile
    };
    dialogConfig.panelClass = 'document-review-modal';
    let dialogRef = this.matDialog.open(
      WorkfilePreviewModalComponent,
      dialogConfig
    );

  }

  isImage(workfile: WorkfileRecordDto): boolean {
    const extensions: string[] = ["jpg", "jpeg", "jfif", "png", "gif", "bmp"];
    return extensions.find(extension => workfile.fileExtension === extension) != null;
  }


  isPdf(workfile: WorkfileRecordDto): boolean {
    const extensions: string[] = ["pdf"];
    return extensions.find(extension => workfile.fileExtension === extension) != null;
  }


  deleteWorkfile(workfile: WorkfileRecordDto) {
    const options = {
      yesText: 'Yes, remove',
      cancelText: 'Cancel',
    };
    this.msgService
      .confirm(
        'You are about to delete a workfile',
        `Do you want to delete (${workfile.title}) workfile?`,
        options
      )
      .subscribe({
        next: (status: Confirmation.Status) => {
          if (status === Confirmation.Status.confirm) {
            this.onDeleteWorkfile.emit(workfile);
          }
        },
      });
  }

  refreshFile(workfile: WorkfileRecordDto) {
    this.msgService.error("", null, "File is still processing").subscribe((_status: Confirmation.Status) => { });
  }

}
