import { Component, Input, OnInit } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { DamageDetailDto } from '@proxy/appraisal/report/reports/v1/form1004';

@Component({
  selector: 'jaro-kit-damage-notes',
  templateUrl: './damage-notes.component.html',
  styleUrls: ['./damage-notes.component.scss']
})
export class DamageNotesComponent implements OnInit {

  @Input() form: FormGroup;
  @Input() isEditMode: boolean;
  damageNotes: DamageDetailDto[];

  ngOnInit(): void {
    this.damageNotes = this.form.value['additionalDamageNotes'] || [];
  }

  onChangeDamageNote() {
    const damageNotesControl = this.form.controls['additionalDamageNotes'];
    if(damageNotesControl) {
      damageNotesControl.setValue(this.damageNotes);
      damageNotesControl.markAsDirty();
      this.form.updateValueAndValidity();
    }
  }

}
