<div class="sale-history">
  <div class="grid-page-container">
    <div class="grid-description-row" *ngIf="saleHistory.length > 0">
      <ngx-datatable
        class="material"
        [rows]="saleHistory"
        [columnMode]="ColumnMode.force"
        [headerHeight]="50"
        [footerHeight]="50"
        rowHeight="auto"
        [limit]="10"
        [scrollbarH]="true"
        [messages]="{
          emptyMessage: 'Sale History details incomplete!',
          totalMessage: 'total'
        }"
      >
        <ngx-datatable-column [name]="'Add'" [maxWidth]="50" [sortable]="false">
          <ng-template let-row="row" ngx-datatable-cell-template let-rowIndex="rowIndex">
            <em
              class="grid-selectable-icon grid-add-column fas fa-plus-circle"
              (click)="addRow(rowIndex)"
            ></em>
          </ng-template>
        </ngx-datatable-column>

        <ngx-datatable-column [name]="'Remove'" [maxWidth]="75" [sortable]="false">
          <ng-template let-row="row" ngx-datatable-cell-template let-rowIndex="rowIndex">
            <em
              class="grid-selected-icon grid-remove-column fas fa-minus-circle"
              (click)="removeRow(rowIndex)"
            ></em>
          </ng-template>
        </ngx-datatable-column>

        <ngx-datatable-column
          [name]="'Date'"
          [prop]="'transactionContractDate'"
          [sortable]="false"
          [minWidth]="300"
        >
          <ng-template let-row="row" ngx-datatable-cell-template let-rowIndex="rowIndex" >
            <div id="transactionContractDate">
            <jaro-kit-date-picker
              [form]="form"
              [(value)]="row.transactionContractDate"
              (changeNewDate)="changeNewDate($event, rowIndex, 'transactionContractDate')"
              [isRemoveControl]="true"
              [isRequired]="true"
              [id]="'transactionContractDate'+row.transactionContractDate"
            ></jaro-kit-date-picker>
          </div>
          </ng-template>
        </ngx-datatable-column>

        <ngx-datatable-column [name]="'Price'" [prop]="'closePrice'" [sortable]="false">
          <ng-template let-row="row" ngx-datatable-cell-template let-rowIndex="rowIndex">
            <mat-form-field appearance="outline" id="saleHistoryClosePrice">
              <input
                matInput
                [id]="'saleHistoryClosePrice'+row.transactionContractDate"
                [(ngModel)]="row.closePrice"
                currencyMask
                mask="separator.0"
                thousandSeparator=","
                separatorLimit="999999999"
                [allowNegativeNumbers]="false"
                autocomplete="off"
                [required]="isRequired(row)"
                #closePriceCtrl="ngModel"
              />
              <mat-error *ngIf="closePriceCtrl.hasError('required')">Field is required</mat-error>
            </mat-form-field>
          </ng-template>
        </ngx-datatable-column>

        <ngx-datatable-column [name]="'Source'" [prop]="'source'" [sortable]="false">
          <ng-template let-row="row" ngx-datatable-cell-template let-rowIndex="rowIndex">
            <mat-form-field appearance="outline" id="saleHistorySource">
              <input matInput [(ngModel)]="row.source" autocomplete="off" [required]="isRequired(row)" #sourceCtrl="ngModel" [id]="'saleHistorySource'+row.transactionContractDate"/>
              <mat-error *ngIf="sourceCtrl.hasError('required')">Field is required</mat-error>
            </mat-form-field>
          </ng-template>
        </ngx-datatable-column>

        <ngx-datatable-column
          [name]="'Document Number'"
          [prop]="'transactionRecordingDocumentId'"
          [sortable]="false"
          [minWidth]="120"
        >
          <ng-template let-row="row" ngx-datatable-cell-template let-rowIndex="rowIndex">
            <mat-form-field appearance="outline">
              <input matInput [(ngModel)]="row.transactionRecordingDocumentId" autocomplete="off" />
            </mat-form-field>
          </ng-template>
        </ngx-datatable-column>

        <ngx-datatable-column
          [name]="'Effective Date Of Source'"
          [prop]="'transactionRecordingDate'"
          [sortable]="false"
          [minWidth]="300"
        >
          <ng-template let-row="row" ngx-datatable-cell-template let-rowIndex="rowIndex">
            <div id="transactionRecordingDate">
            <jaro-kit-date-picker
              [form]="form"
              [(value)]="row.transactionRecordingDate"
              (changeNewDate)="changeNewDate($event, rowIndex, 'transactionRecordingDate')"
              [isRemoveControl]="true"
              [isRequired]="isRequired(row)"
              [id]="'transactionRecordingDate'+row.transactionContractDate"
            ></jaro-kit-date-picker>
          </div>
          </ng-template>
        </ngx-datatable-column>

        <ngx-datatable-column
          [name]="'Seller 1'"
          [prop]="'seller1FullName'"
          [sortable]="false"
          [minWidth]="150"
        >
          <ng-template let-row="row" ngx-datatable-cell-template let-rowIndex="rowIndex">
            <mat-form-field appearance="outline">
              <input matInput [(ngModel)]="row.seller1FullName" autocomplete="off" />
            </mat-form-field>
          </ng-template>
        </ngx-datatable-column>

        <ngx-datatable-column
          [name]="'Seller 2'"
          [prop]="'seller2FullName'"
          [sortable]="false"
          [minWidth]="150"
        >
          <ng-template let-row="row" ngx-datatable-cell-template let-rowIndex="rowIndex">
            <mat-form-field appearance="outline">
              <input matInput [(ngModel)]="row.seller2FullName" autocomplete="off" />
            </mat-form-field>
          </ng-template>
        </ngx-datatable-column>

        <ngx-datatable-column
          [name]="'Buyer 1'"
          [prop]="'buyer1FullName'"
          [sortable]="false"
          [minWidth]="150"
        >
          <ng-template let-row="row" ngx-datatable-cell-template let-rowIndex="rowIndex">
            <mat-form-field appearance="outline">
              <input matInput [(ngModel)]="row.buyer1FullName" autocomplete="off" />
            </mat-form-field>
          </ng-template>
        </ngx-datatable-column>

        <ngx-datatable-column
          [name]="'Buyer 2'"
          [prop]="'buyer2FullName'"
          [sortable]="false"
          [minWidth]="150"
        >
          <ng-template let-row="row" ngx-datatable-cell-template let-rowIndex="rowIndex">
            <mat-form-field appearance="outline">
              <input matInput [(ngModel)]="row.buyer2FullName" autocomplete="off" />
            </mat-form-field>
          </ng-template>
        </ngx-datatable-column>

        <ngx-datatable-column
          [name]="'Document Type'"
          [prop]="'transactionDocumentType'"
          [sortable]="false"
        >
          <ng-template let-row="row" ngx-datatable-cell-template let-rowIndex="rowIndex">
            <mat-form-field appearance="outline">
              <input matInput [(ngModel)]="row.transactionDocumentType" autocomplete="off" />
            </mat-form-field>
          </ng-template>
        </ngx-datatable-column>

        <ngx-datatable-footer>
          <ng-template
            ngx-datatable-footer-template
            let-rowCount="rowCount"
            let-pageSize="pageSize"
            let-selectedCount="selectedCount"
            let-curPage="curPage"
            let-offset="offset"
          >
            <div class="table-footer">
              <div class="table-footer-left">{{ rowCount }} total</div>
              <div class="table-footer-right">
                <button class="add-new-button" (click)="addRow()">
                  <em class="far fa-plus"></em>
                </button>
              </div>
            </div>
          </ng-template>
        </ngx-datatable-footer>
      </ngx-datatable>
    </div>

    <div class="empty-panel" *ngIf="!saleHistory || saleHistory.length == 0">
      <em class="fas fa-arrow-right" aria-hidden="true"></em>
      <div class="detail">
        <span class="title">No Sales History</span>
        <span class="description">No sales or transfer history in the last 36 months</span>
      </div>
    </div>

    <div class="sale-history-footer" *ngIf="!saleHistory || saleHistory.length == 0">
      <button class="add-new-button" (click)="addRow(0)">Add Sale History</button>
    </div>
  </div>
</div>
