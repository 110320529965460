<ng-container *ngIf="form" [formGroup]="form">
  <div class="row report-input-sub-section">
    <div class="col-md-6 pl-0 pr-15">
      <div class="row">
        <mat-form-field appearance="outline">
          <mat-label>Sales Comparison Approach $</mat-label>
          <input
            matInput
            formControlName="reconciliationSalesComparisonApproach"
            autocomplete="off"
            currencyMask
            mask="separator.0"
            thousandSeparator=","
            separatorLimit="999999999999"
            [allowNegativeNumbers]="false"
          />
        </mat-form-field>

        <mat-form-field appearance="outline">
          <mat-label>Cost Approach $</mat-label>
          <input
            matInput
            formControlName="reconciliationCostApproach"
            autocomplete="off"
            currencyMask
            mask="separator.0"
            thousandSeparator=","
            separatorLimit="999999999999"
            [allowNegativeNumbers]="false"
          />
        </mat-form-field>

        <mat-form-field appearance="outline">
          <mat-label>Income Approach $</mat-label>
          <input
            matInput
            formControlName="reconciliationIncomeApproach"
            autocomplete="off"
            currencyMask
            mask="separator.0"
            thousandSeparator=","
            separatorLimit="999999999999"
            [allowNegativeNumbers]="false"
          />
        </mat-form-field>

        <mat-form-field appearance="outline" class="jaro-kit-textarea">
          <mat-label>Reconciliation Summary Comment</mat-label>
          <textarea
            type="text"
            formControlName="reconciliationSummaryComment"
            placeholder="Reconciliation Summary Comment"
            matInput
            rows="4"
          ></textarea>
        </mat-form-field>
      </div>
    </div>

    <div class="col-md-6 pl-15 pr-0">
      <span class="report-input-title mb-0 pb-0">Conditions of Value Estimate</span>

      <div class="row">
        <div class="col-md-12 pl-0 ml-0 pt-15">
          <mat-checkbox
            id="reconciliationAsIs"
            formControlName="reconciliationAsIs"
            class="report-input-checkbox check-box-label"
            [value]="true"
            (change)="onChangeAsIs($event)"
            >As-is</mat-checkbox
          >
          <jaro-kit-validation-message [form]="form" [controlName]="'reconciliationAsIs'" [isRequired]="true"></jaro-kit-validation-message>
        </div>

        <div class="col-md-12 pl-0 ml-0 pt-15">
          <mat-checkbox
            id="reconciliationSubjectToCompletion"
            formControlName="reconciliationSubjectToCompletion"
            class="report-input-checkbox check-box-label"
            [value]="true"
            (change)="
              onChangeConditions(
                $event,
                'reconciliationSubjectToCompletion',
                'reconciliationSubjectToCompletionDescription'
              )
            "
            >Subject to Completion</mat-checkbox
          >
          <jaro-kit-validation-message [form]="form" [controlName]="'reconciliationSubjectToCompletion'" [isRequired]="true"></jaro-kit-validation-message>
        </div>

        <ng-container *ngIf="this.form.value['reconciliationSubjectToCompletion']">        
          <mat-form-field  appearance="outline" class="jaro-kit-textarea">
            <mat-label>Subject to Completion Description</mat-label>
            <textarea
              type="text"
              id="reconciliationSubjectToCompletionDescription"
              formControlName="reconciliationSubjectToCompletionDescription"
              placeholder="Subject to Completion Description"
              matInput
              rows="4"
            ></textarea>
          </mat-form-field>
          <jaro-kit-validation-message [form]="form" [controlName]="'reconciliationSubjectToCompletionDescription'" [isRequired]="true"></jaro-kit-validation-message>
        </ng-container>

        <div class="col-md-12 pl-0 ml-0 pt-15">
          <mat-checkbox
            id="reconciliationSubjectToRepairs"
            formControlName="reconciliationSubjectToRepairs"
            class="report-input-checkbox check-box-label"
            [value]="true"
            (change)="
              onChangeConditions(
                $event,
                'reconciliationSubjectToRepairs',
                'reconciliationSubjectToRepairsDescription'
              )
            "
            >Subject to Repairs</mat-checkbox
          >
          <jaro-kit-validation-message [form]="form" [controlName]="'reconciliationSubjectToRepairs'" [isRequired]="true"></jaro-kit-validation-message>
        </div>

        <ng-container *ngIf="this.form.value['reconciliationSubjectToRepairs']">        
          <mat-form-field appearance="outline" class="jaro-kit-textarea">
            <mat-label>Subject to Repairs Description</mat-label>
            <textarea
              type="text"
              id="reconciliationSubjectToRepairsDescription"
              formControlName="reconciliationSubjectToRepairsDescription"
              placeholder="Subject to Repairs Description"
              matInput
              rows="4"
            ></textarea>
          </mat-form-field>
          <jaro-kit-validation-message [form]="form" [controlName]="'reconciliationSubjectToRepairsDescription'" [isRequired]="true"></jaro-kit-validation-message>
        </ng-container>

        <div class="col-md-12 pl-0 ml-0 pt-15">
          <mat-checkbox
            id="reconciliationSubjectToInspection"
            formControlName="reconciliationSubjectToInspection"
            class="report-input-checkbox check-box-label"
            [value]="true"
            (change)="
              onChangeConditions(
                $event,
                'reconciliationSubjectToInspection',
                'reconciliationSubjectInspectionDescription'
              )
            "
            >Subject to Inspection</mat-checkbox
          >
          <jaro-kit-validation-message [form]="form" [controlName]="'reconciliationSubjectToInspection'" [isRequired]="true"></jaro-kit-validation-message>
        </div>

        <ng-container *ngIf="this.form.value['reconciliationSubjectToInspection']">        
          <mat-form-field  appearance="outline" class="jaro-kit-textarea">
            <mat-label>Subject to Inspection Description</mat-label>
            <textarea
              type="text"
              id="reconciliationSubjectInspectionDescription"
              formControlName="reconciliationSubjectInspectionDescription"
              placeholder="Subject to Inspection Description"
              matInput
              rows="4"
            ></textarea>
          </mat-form-field>
          <jaro-kit-validation-message [form]="form" [controlName]="'reconciliationSubjectInspectionDescription'" [isRequired]="true"></jaro-kit-validation-message>
        </ng-container>

        <mat-form-field appearance="outline">
          <mat-label>Opinion of Market Value $</mat-label>
          <input
            matInput
            id="reconciliationOpinionOfMarketValue"
            formControlName="reconciliationOpinionOfMarketValue"
            autocomplete="off"
            currencyMask
            mask="separator.0"
            thousandSeparator=","
            separatorLimit="999999999999"
            [allowNegativeNumbers]="false"
          />
        </mat-form-field>
        <jaro-kit-validation-message [form]="form" [controlName]="'reconciliationOpinionOfMarketValue'" [isRequired]="true"></jaro-kit-validation-message>

        <jaro-kit-date-picker
          [form]="form"
          id="reconciliationEffectiveDateOfValue"
          dateControl="reconciliationEffectiveDateOfValue"
          titleDate="Effective Date of Value"
          class="effective-date"         
        ></jaro-kit-date-picker>
      </div>
    </div>
  </div>
</ng-container>
