<ng-container *ngIf="form" [formGroup]="form">
  <div
    [ngClass]="{
      'utility-card': true,
      'utility-card-invalid': isInvalidData,
      'utility-card-valid': isValidData || (isFocus && !isInvalidData)
    }"
  >
    <div class="utility-card-title">
      <em [ngClass]="icon"></em>
      <p class="mat-title">{{ title }}</p>
    </div>
    <div class="utility-card-value mt-15">
      <p class="report-input-label">Type</p>
      <div class="report-input-radio">
        <mat-radio-group
          [formControlName]="controlName"
          [id]="controlName"
          class="mt-15 mb-15"
          (change)="onChangePublic($event)"
        >
          <mat-radio-button value="public" class="report-input-radio-button"
            >Public</mat-radio-button
          >
          <mat-radio-button value="other" class="report-input-radio-button">Other</mat-radio-button>
        </mat-radio-group>
        <jaro-kit-validation-message [form]="form" [controlName]="controlName" [isRequired]="true"></jaro-kit-validation-message>
      </div>
    </div>

    <div class="utility-card-description">
      <mat-form-field class="jaro-kit-textarea" appearance="outline">
        <mat-label>Description</mat-label>
        <textarea
          type="text"
          [formControlName]="descriptionControlName"
          [id]="descriptionControlName"
          placeholder="Description"
          matInput
          rows="4"
          (blur)="onBlur()"
          (focus)="onFocus()"
        ></textarea>
      </mat-form-field>
      
      <jaro-kit-validation-message [form]="form" [controlName]="descriptionControlName" [isRequired]="true"></jaro-kit-validation-message>
    </div>
  </div>
</ng-container>
