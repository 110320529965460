import { LogLevel } from 'angular-auth-oidc-client';

export const environment = {
  production: true,
  isUseDynamicEnvironmentVariables: false,
  application: {
    name: 'Halo Global',
    logoUrl: '',
  },
  oidcConfig: {
    postLoginRoute: '/',
    forbiddenRoute: '/login-failed',
    unauthorizedRoute: '/login-failed',
    logLevel: LogLevel.Error,
    historyCleanupOff: true,
    // Well known url is #{_%HaloGlobal.Angular.OidcConfig.Authority%_}#/.well-known/openid-configuration
    authority: '#{_%HaloGlobal.Angular.OidcConfig.Authority%_}#',
    postLogoutRedirectUri: '#{_%HaloGlobal.Angular.OidcConfig.JaroKitWebAppUrl%_}#/admin',
    clientId: '#{_%HaloGlobal.Angular.OidcConfig.ClientId%_}#',
    scope: 'openid profile email appraiser-api',
    responseType: 'code',
    silentRenew: true,
    useRefreshToken: true,
    renewTimeBeforeTokenExpiresInSeconds: 300,
    secureRoutes: [
      '#{_%HaloGlobal.Angular.OidcConfig.JaroKitWebAppUrl%_}#/',
      '#{_%HaloGlobal.Angular.OidcConfig.JaroKitWebApiUrl%_}#/',
      '#{_%HaloGlobal.Angular.OidcConfig.JaroDeskWebAppUrl%_}#/',
      '#{_%HaloGlobal.Angular.OidcConfig.JaroDeskWebApiUrl%_}#/',
    ],
    ignoreNonceAfterRefresh: true,
    autoUserInfo: false, // Denote if the library should automatically get user info after authentication - required as Ascent does not implement the userinfo end point
    renewUserInfoAfterTokenRenew: false, // Denotes if the library should automatically get user info after token renew - required as Ascent does not implement the userinfo end point
    customParamsRefreshTokenRequest: { scope: 'openid profile email appraiser-api' }, // Request the id_token on refresh - Ascent still needs to implement this
    disableIdTokenValidation: true // Disable validation for id_token expiry time - required as Ascent only gives the token on the intial request
  },
  apis: {
    default: {
      rootNamespace: 'HaloGlobal',
      url: '#{_%HaloGlobal.Angular.ApiUri%_}#',
    },
  },
  localization: {
    defaultResourceName: null,
  },
  googleMaps:{
    apiKey: "#{_%HaloGlobal.Angular.GoogleMaps.ApiKey%_}#"
  },
  instrumentation: {
    appInsights: {
      instrumentationKey: '#{_%HaloGlobal.AppInsights.InstrumentationKey%_}#',
      cloudRoleName: '#{_%HaloGlobal.AppInsights.CloudRoleName%_}#',
    },
  },
  featureFlags: [
    {
      name: 'JaroBoost',
      key: 'JaroBoost',
      isEnabled: '#{_%HaloGlobal.Angular.FeatureFlag.JaroBoost.isEnabled%_}#',
    },
    {
      name: 'Report Inputs',
      key: 'ReportInputs',
      isEnabled: '#{_%HaloGlobal.Angular.FeatureFlag.ReportInputs.isEnabled%_}#',
    },
    {
      name: '1004 Report Template',
      key: 'ReportTemplate.1004',
      isEnabled: '#{_%HaloGlobal.Angular.FeatureFlag.ReportTemplate.1004.IsEnabled%_}#',
    },
    {
      name: '1004D Report Template',
      key: 'ReportTemplate.1004D',
      isEnabled: '#{_%HaloGlobal.Angular.FeatureFlag.ReportTemplate.1004D.IsEnabled%_}#',
    },
    {
      name: 'FHAHUD92051 Report Template',
      key: 'ReportTemplate.FHAHUD92051',
      isEnabled: '#{_%HaloGlobal.Angular.FeatureFlag.ReportTemplate.FHAHUD92051.IsEnabled%_}#',
    },    
    {
      name: 'Report Template Send Report',
      key: 'ReportTemplate.SendReport',
      productList: '#{_%HaloGlobal.Angular.FeatureFlag.ReportTemplate.SendReport.ProductList%_}#',
    },
    {
      name: 'Quality Check',
      key: 'ReportTemplate.QualityCheck',
      productList: '#{_%HaloGlobal.Angular.FeatureFlag.ReportTemplate.QualityCheck.ProductList%_}#',
    },
    {
      name: 'Report Template Preview Report',
      key: 'ReportTemplate.PreviewReport',
      productList: '#{_%HaloGlobal.Angular.FeatureFlag.ReportTemplate.PreviewReport.ProductList%_}#',
    },
    {      
      name: 'PreviewReportQualityCheck',
      key: 'ReportTemplate.PreviewReportQualityCheck',
      productList: '#{_%HaloGlobal.Angular.FeatureFlag.ReportTemplate.PreviewReportQualityCheck.ProductList%_}#'
    },
    {
      name: 'Default Report Template',
      key: 'ReportTemplate.Default',
      isEnabled: '#{_%HaloGlobal.Angular.FeatureFlag.ReportTemplate.Default.IsEnabled%_}#',
    },    
    {
      name: 'Comparables',
      key: 'Comparables',
      isEnabled: '#{_%HaloGlobal.Angular.FeatureFlag.Comparables.IsEnabled%_}#'
    },
    {
      name: 'Comparable Grid Adjustment Validation',
      key: 'AdjustmentValidation',
      isEnabled: '#{_%HaloGlobal.Angular.FeatureFlag.AdjustmentValidation.IsEnabled%_}#'
    },
    {
      name: 'Market',
      key: 'Market',
      isEnabled: '#{_%HaloGlobal.Angular.FeatureFlag.Market.IsEnabled%_}#'
    },
    {
      name: 'Tools',
      key: 'Tools',
      isEnabled: '#{_%HaloGlobal.Angular.FeatureFlag.Tools.IsEnabled%_}#'
    },
    {
      name: 'Override Feature Flags',
      key: 'OverrideFeatureFlags',
      isEnabled: '#{_%HaloGlobal.Angular.FeatureFlag.OverrideFeatureFlags.IsEnabled%_}#'
    },
  ],
};
