import { Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { ReportInputPhotoSectionDto } from '@proxy/bff/activity/report-input-photos/v1';
import { ReportPhotoResourceModel } from 'src/app/shared/dtos/report-input-resource/report-photo-resource.model';
import { ReportResourceType } from 'src/app/shared/enums/report-resource-type.enum';
import { ReportSource } from '../enums/report-source.enum';
import { PhotoGalleryComponent } from '../photo-gallery/photo-gallery.component';
import { ReportResourceFilterComponent } from '../report-resource-filter/report-resource-filter.component';

@Component({
  selector: 'jaro-kit-report-resource-collection',
  templateUrl: './report-resource-collection.component.html',
  styleUrls: ['./report-resource-collection.component.scss'],
})
export class ReportResourceCollectionComponent implements OnInit {
  @ViewChild('reportResourceItems') reportResourceItems: PhotoGalleryComponent;
  @ViewChild('reportResourceFilter') reportResourceFilter: ReportResourceFilterComponent;

  @Input() orderAssignmentId: string;
  @Input() reportId: string;
  @Input() reportVersion: number;
  @Input() items: ReportPhotoResourceModel[] = [];
  @Input() photoSections: ReportInputPhotoSectionDto[] = [];
  @Input() isLoading: boolean;
  @Input() reportResourceType: ReportResourceType = ReportResourceType.Photo;

  @Output() onUpdatePhotoSections = new EventEmitter<ReportInputPhotoSectionDto[]>();
  @Output() onSoftDeleteDocument = new EventEmitter<ReportPhotoResourceModel>();
  @Output() onOpenPhotoGallery = new EventEmitter<boolean>();

  source: string;
  sourceValue: ReportSource | string;
  type = ReportResourceType;

  // This function filer photos by source and  propertyId
  get itemsFiltered(): ReportPhotoResourceModel[] {
    return this.items.filter(
      (item) => !this.sourceValue || item.resourceFilterType === this.sourceValue
    );
  }

  get title() {
    return this.reportResourceType === ReportResourceType.Photo ? 'Photo Gallery' : 'Documents';
  }

  ngOnInit(): void {
    (this.items || []).forEach((item) => {
      item.resourceFilterType = this.getValueOfSource(item.source);
    });
  }

  closeDialog() {
    this.onOpenPhotoGallery.emit(false);
  }

  updateSectionPhotos(sections: ReportInputPhotoSectionDto[]) {
    this.onUpdatePhotoSections.emit(sections);
  }

  onDeleteDocument(document: ReportPhotoResourceModel) {
    this.onSoftDeleteDocument.emit(document);
  }
  // when updating source or propertyId, in the photo gallery list photos will automation filter by source and  propertyId
  updateSource(sourceDto) {
    this.source = sourceDto?.name || 'Photo Gallery';
    this.sourceValue = sourceDto?.value;
    this.refreshPhotoGallery();
  }

  refreshPhotoGallery() {
    if (this.reportResourceItems) {
      setTimeout(() => {
        this.reportResourceItems.resetResources();
      }, 0);
    }
  }

  refreshFilterOfPhotoGallery() {
    this.reportResourceFilter.refreshSourceOptions();
    this.refreshPhotoGallery();
  }

  private getValueOfSource(source: unknown) {
    switch (source) {
      case 'Desk':
        return ReportSource.Desk;
      case 'Inspection':
        return ReportSource.Inspection;
      case 'JaroAi':
        return ReportSource.JaroAi;
      case 'Uploads':
        return ReportSource.User;
      case 'MLS':
        return ReportSource.MLS;
      case 'Preload':
        return ReportSource.Preload;
      case 'User':
        return ReportSource.User;
      default:
        return source;
    }
  }
}
