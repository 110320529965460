import { Injectable, Pipe, PipeTransform } from '@angular/core';
import { OrderDetailDto } from '@proxy/ascent/order/order-assignment/order-assignments/v2/orderDetailDto';

@Injectable({
  providedIn: 'root',
})
@Pipe({
  name: 'googleMapAddressUrlFormat',
})
export class GoogleMapAddressUrlFormatPipe implements PipeTransform {
  transform(orderData: OrderDetailDto): string {    
    const addressLineFormatted = orderData?.address?.fullAddressFormatted?.split(' ')?.join('+');
    return addressLineFormatted ? `https://www.google.com/maps/place/${encodeURIComponent(addressLineFormatted)}` : "https://www.google.com/maps";   
  }
}