import { Pipe, PipeTransform } from '@angular/core';
import { ReportPhotoSource } from '@proxy/appraisal/report/report-photos';

@Pipe({
  name: 'PhotoSourcePipe',
})
export class PhotoSourcePipe implements PipeTransform {
  transform(source: ReportPhotoSource): string {
    switch (source) {
      case ReportPhotoSource.Inspection:
        return 'Inspection';
      case ReportPhotoSource.MLS:
        return 'MLS';
      case ReportPhotoSource.User:
        return 'User';
      default:
        return '';
    }
  }
}
