<ng-container *ngIf="form" [formGroup]="form">
  <p class="report-input-title pb-15">One-Unit Housing Trends</p>

  <div class="report-input-form-field">
    <p class="report-input-label report-input-radio-label">Property Values</p>
    <mat-radio-group formControlName="neighborhoodProperty" id="neighborhoodProperty">
      <mat-radio-button
        *ngFor="let option of propertyOptions"
        [value]="option.value"
        class="report-input-radio-button"
        >{{ option.name }}</mat-radio-button
      >
    </mat-radio-group>
    <jaro-kit-validation-message [form]="form" [controlName]="'neighborhoodProperty'" [isRequired]="true"></jaro-kit-validation-message>
  </div>

  <div class="report-input-form-field">
    <p class="report-input-label report-input-radio-label">Demand / Supply</p>
    <mat-radio-group formControlName="neighborhoodDemandSupply" id="neighborhoodDemandSupply">
      <mat-radio-button
        *ngFor="let option of demandOptions"
        [value]="option.value"
        class="report-input-radio-button"
        >{{ option.name }}</mat-radio-button
      >
    </mat-radio-group>
    <jaro-kit-validation-message [form]="form" [controlName]="'neighborhoodDemandSupply'" [isRequired]="true"></jaro-kit-validation-message>
  </div>

  <div class="report-input-form-field">
    <p class="report-input-label report-input-radio-label">Marketing Time</p>
    <mat-radio-group formControlName="neighborhoodMarketingTime" id="neighborhoodMarketingTime">
      <mat-radio-button
        *ngFor="let option of marketingTimeOptions"
        [value]="option.value"
        class="report-input-radio-button"
        >{{ option.name }}</mat-radio-button
      >
    </mat-radio-group>
    <jaro-kit-validation-message [form]="form" [controlName]="'neighborhoodMarketingTime'" [isRequired]="true"></jaro-kit-validation-message>
  </div>
</ng-container>
