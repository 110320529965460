import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { ReportInputPhotoSectionDto } from '@proxy/bff/activity/report-input-photos/v1';
import { ReportPhotoResourceModel } from 'src/app/shared/dtos/report-input-resource/report-photo-resource.model';
import { ReportResourceType } from 'src/app/shared/enums/report-resource-type.enum';

@Component({
  selector: 'jaro-kit-photo-gallery',
  templateUrl: './photo-gallery.component.html',
  styleUrls: ['./photo-gallery.component.scss'],
})
export class PhotoGalleryComponent implements OnInit {
  @Input() orderAssignmentId: string;
  @Input() reportId: string;
  @Input() reportVersion: number;
  @Input() items: ReportPhotoResourceModel[] = [];
  @Input() itemsFiltered: ReportPhotoResourceModel[] = [];
  @Input() photoSections: ReportInputPhotoSectionDto[];
  @Input() isLoading: boolean;
  @Input() source: string;
  @Input() reportResourceType: ReportResourceType;

  @Output() onUpdatePhotoSections = new EventEmitter<ReportInputPhotoSectionDto[]>();

  itemsLoaded: ReportPhotoResourceModel[] = [];
  type = ReportResourceType;

  // Todo: determine the core reason of infinite-scroll not working
  nextItem = 1000;
  sum = this.nextItem;
  throttle = 1000;
  scrollDistance = 1;
  scrollUpDistance = 2;

  ngOnInit(): void {
    this.resetResources();
  }

  resetResources() {
    this.itemsLoaded = [];
    this.addItems(0, this.sum);
  }

  addItems(startIndex, endIndex) {
    const newPhotos = (this.itemsFiltered || []).slice(startIndex, endIndex);
    this.itemsLoaded.push.apply(this.itemsLoaded, newPhotos);
  }

  onScrollDown() {
    if (this.sum < this.itemsFiltered.length) {
      const start = this.sum;
      this.sum += this.nextItem;
      this.addItems(start, this.sum);
    }
  }

  updateSectionPhotos(sections: ReportInputPhotoSectionDto[]) {
    this.onUpdatePhotoSections.emit(sections);
  }
}
