<ng-container *ngIf="form" [formGroup]="form">
  <div class="row">
    <halo-global-amenity-card-view-mode
      *ngFor="let item of amenities; let i = index"
      [form]="form"
      [icon]="item.icon"
      [controlName]="item.controlName"
      [descriptionControlName]="item.descriptionControlName"
      [field]="getFormFieldConfig(item.controlName)"
      [description]="item.description"
      class="col-md-4"
    ></halo-global-amenity-card-view-mode>
  </div>

  <div class="row pl-0 pr-0 other-amenities-description">
    <jaro-kit-report-input-label
      [field]="otherField"
      [ngClass]="otherField.layoutHint"
    ></jaro-kit-report-input-label>
  </div>
</ng-container>
