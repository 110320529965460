import { HttpClient } from '@angular/common/http';
import { Component, Inject, Input } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { ReportInputTemplateSubSectionModel } from 'src/app/features/shared/forms/models/report-input-layout.model';
import { ContentServiceInterface } from 'src/app/interface/content/content-service-interface';
import { ItemData } from 'src/app/shared/components/multiple-combo-box/multiple-combo-box.component';
import InjectionSymbol from 'src/app/shared/injection/injection-symbol';

@Component({
  selector: 'jaro-kit-amenities-edit-mode',
  templateUrl: './amenities-edit-mode.component.html',
  styleUrls: ['./amenities-edit-mode.component.scss'],
})
export class AmenitiesEditModeComponent {
  @Input() form: FormGroup;
  subSection: ReportInputTemplateSubSectionModel;

  amenities = [];

  getFormFieldConfig(formControlName: string) {
    return this.subSection.fields.find((item) => item.formControlName === formControlName);
  }

  other = null;
  otherOptions: ItemData[] = [];

  constructor(
    @Inject(InjectionSymbol.ContentService)
    public contentService: ContentServiceInterface,
    private http: HttpClient
  ) {
    this.otherOptions = this.contentService.getContentByKey('amenities.other');
  }

  ngOnInit(): void {
    this.http
      .get('/assets/json/template/report/1004/amenities.config.json')
      .subscribe((data: any) => {
        this.amenities = data;
      });
  }
}
