<div class="section-view-mode-detail container">
  <div *ngIf="!subSection.isCustomComponent">
    <ng-container *ngFor="let group of fieldGroups">
      <div class="row">
        <ng-container *ngFor="let field of group.fields">
          <jaro-kit-report-input-label
            *ngIf="field.isVisible"
            [field]="field"
            [ngClass]="getLayoutHintField(field)"
            [timeZoneId]="timeZoneId"
            [reportInputForm]="reportInputSectionForm"
            [reportDto]="reportDto"
            [feature]="feature"
          ></jaro-kit-report-input-label>
        </ng-container>
      </div>
    </ng-container>
  </div>
  <div class="row" *ngIf="subSection.isCustomComponent">
    <jaro-kit-dynamic-component
      [componentName]="subSection.componentName"
      [reportDto]="reportDto"
      [reportInputForm]="reportInputSectionForm"
      [section]="section"
      [subSection]="subSection"
      [isEditMode]="false"
      [feature]="feature"
      [orderAssignmentId]="orderAssignmentId"
      [timeZoneId]="timeZoneId"
      [reportVersionDto]="reportVersionDto"
    ></jaro-kit-dynamic-component>
  </div>
</div>
