import { Component, Input, OnInit } from '@angular/core';
import {
  LineItemDto,
  ReportComparableFieldConfig,
} from 'src/app/proxy-adapter/report/comparables/models';

@Component({
  selector: 'jaro-kit-comparable-grid-input-view-mode',
  templateUrl: './comparable-grid-input-view-mode.component.html',
  styleUrls: ['./comparable-grid-input-view-mode.component.scss'],
})
export class ComparableGridInputViewModeComponent implements OnInit {
  @Input() field: ReportComparableFieldConfig;
  @Input() value: any;
  @Input() isAdjustment: boolean;
  @Input() lineItems: LineItemDto[];
  @Input() isSelected: boolean;

  totalRooms: string;
  bedRooms: string;
  bathRooms: string;

  ngOnInit(): void {
    if (this.field.key === 'aboveGradeRoomCount') {
      this.totalRooms = this.lineItems.find((item) => item.path === 'totalRooms')?.value;
      this.bedRooms = this.lineItems.find((item) => item.path === 'bedrooms')?.value;
      this.bathRooms = this.lineItems.find((item) => item.path === 'fullBaths')?.value || '';
      const halfBathCount = this.lineItems.find((item) => item.path === 'halfBaths')?.value;

      if (halfBathCount) {
        this.bathRooms += `.${halfBathCount}`;
      }
    }
  }
}
