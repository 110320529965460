<div class="report-input-workfile">
  <div class="workfile-list">
    <div class="grid-description-row" *ngIf="workfiles.length > 0">
      <ngx-datatable
        class="material"
        [rows]="workfiles"
        [columnMode]="ColumnMode.force"
        [headerHeight]="50"
        [footerHeight]="50"
        rowHeight="50px"
        [limit]="15"
        [scrollbarH]="true"
      >
        <ngx-datatable-column [name]="'Title'" [prop]="'title'" [sortable]="true" [minWidth]="120">
          <ng-template let-row="row" ngx-datatable-cell-template>
            <div>{{ row.title }}</div>
          </ng-template>
        </ngx-datatable-column>

        <ngx-datatable-column
          [name]="'File Type'"
          [prop]="'fileExtension'"
          [sortable]="true"
          [minWidth]="50"
          [maxWidth]="100"
        >
          <ng-template let-row="row" ngx-datatable-cell-template>
            <div>{{ row.fileExtension | uppercase }}</div>
          </ng-template>
        </ngx-datatable-column>

        <ngx-datatable-column
          [name]="'Timestamp'"
          [prop]="'creationTime'"
          [sortable]="true"
          [minWidth]="200"
        >
          <ng-template let-row="row" ngx-datatable-cell-template>
            <div>
              {{
                row.creationTime
                  | toTimestampInTimezone: orderTimezone
                  | defaultValue
              }}
            </div>
          </ng-template>
        </ngx-datatable-column>

        <ngx-datatable-column [sortable]="false" [minWidth]="15">
          <ng-template let-row="row" ngx-datatable-cell-template>
            <button mat-icon-button [matMenuTriggerFor]="moreAction" class="button-action">
              <em
                class="fa-regular fa-ellipsis-vertical jaro-kit-primary-icon"
                aria-hidden="true"
              ></em>
            </button>
            <mat-menu #moreAction="matMenu" xPosition="before">
              <button *ngIf="supportsPreview(row)" mat-menu-item (click)="tryPreviewWorkfile(row)">
                Preview
              </button>
              <button mat-menu-item (click)="downloadWorkfile(row)">Download</button>
              <hr />
              <button mat-menu-item (click)="deleteWorkfile(row)">Delete</button>
            </mat-menu>
          </ng-template>
        </ngx-datatable-column>
      </ngx-datatable>
    </div>

    <div class="empty-panel" *ngIf="!workfiles || workfiles.length == 0">
      <em class="fas fa-arrow-right" aria-hidden="true"></em>
      <div class="detail">
        <span class="title">No Workfiles Uploaded</span>
      </div>
    </div>
  </div>
</div>
