import { Component } from '@angular/core';
import { FormGroup } from '@angular/forms';

@Component({
  selector: 'jaro-kit-appraiser',
  templateUrl: './appraiser.component.html',
  styleUrls: ['./appraiser.component.scss'],
})
export class AppraiserComponent {
  form: FormGroup;
}
