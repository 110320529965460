import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { MatSliderChange } from '@angular/material/slider';
import { BehaviorSubject } from 'rxjs';
import { FacetRangeFormatPipe } from 'src/app/shared/pipes/facet-range-format/facet-range-format.pipe';
import { FacetFilterModel } from '../../models/facet/facet-filter-model';
import { FacetRangeModel } from '../../models/facet/facet-range-model';

@Component({
  selector: 'jaro-kit-range-slider-facet-filter',
  templateUrl: './range-slider-facet-filter.component.html',
  styleUrls: ['./range-slider-facet-filter.component.scss'],
})
export class RangeSliderFacetFilterComponent implements OnInit {
  @Input() facetFilterSubject: BehaviorSubject<FacetFilterModel[]>;
  @Input() defaultFilterModel: FacetFilterModel;
  @Input() filteredFilterModel: FacetFilterModel;
  @Input() selectedFilterModel: FacetFilterModel;
  @Output() onSelectedRangeUpdated = new EventEmitter<FacetRangeModel>();
  
  public isOpenDropdown: boolean = false;
  defaultRange: FacetRangeModel;  
  filteredRange: FacetRangeModel;
  selectedRange: FacetRangeModel;
  thumbDisplayPipe: FacetRangeFormatPipe;
  isMinSelectableRangeUpdated: boolean;
  isMaxSelectableRangeUpdated: boolean;

  ngOnInit(): void {
    this.facetFilterSubject.subscribe((_response: FacetFilterModel[]) => {
      this.updateRanges();
    });
    this.thumbDisplayPipe = new FacetRangeFormatPipe();
  }

  private updateRanges(): void {
    if((this.defaultFilterModel?.statistics?.min != null) && (this.defaultFilterModel?.statistics?.max != null)) {
      this.defaultRange = new FacetRangeModel(this.defaultFilterModel?.statistics?.min || 0, this.defaultFilterModel?.statistics?.max || 0);            

      if(this.selectedRange == null)
      {
        this.selectedRange = new FacetRangeModel(this.defaultFilterModel?.statistics?.min || 0, this.defaultFilterModel?.statistics?.max || 0);
        this.updateIsMinAndMaxSelectableRanges();
      }
    }

    if((this.filteredFilterModel?.statistics?.min != null) && (this.filteredFilterModel?.statistics?.max != null)) {
      this.filteredRange = new FacetRangeModel(this.filteredFilterModel?.statistics?.min || 0, this.filteredFilterModel?.statistics?.max || 0);
      this.updateIsMinAndMaxSelectableRanges();
    }
  }
    
  toggleOpenDropdown(_event: any) { 
    this.isOpenDropdown = !this.isOpenDropdown;
  } 

  private updateIsMinAndMaxSelectableRanges() {
    this.isMinSelectableRangeUpdated = this.filteredRange.min !== this.defaultRange.min;
    this.isMaxSelectableRangeUpdated = this.filteredRange.max !== this.defaultRange.max;
  }

  minSliderPositionUpdated(event: MatSliderChange)
  {
    // Prevent min being more than max.
    this.selectedRange.min = event.value;
    this.selectedFilterModel.statistics.min = this.selectedRange.min;
    this.onSelectedRangeUpdated.emit(this.selectedRange);        
  }

  maxSliderPositionUpdated(event: MatSliderChange)
  {
    // Prevent max being less than man.
    this.selectedRange.max = event.value;
    this.selectedFilterModel.statistics.max = this.selectedRange.max;
    this.onSelectedRangeUpdated.emit(this.selectedRange);       
  }

  // This should be used on the mat slider however the web page freezes when it is applied.
  public formatRangeValueForSliderThumb(value: number) : string
  {
    return this.thumbDisplayPipe.transform(value);
  }   
}