import { Component, Input } from '@angular/core';

@Component({
  selector: 'jaro-kit-raw-data-item',
  templateUrl: './raw-data-item.component.html',
  styleUrls: ['./raw-data-item.component.scss'],
})
export class RawDataItemComponent {
  @Input() data: any;

  spaces(num) {
    let spaces = '';
    for (let i = 0; i < num; i++) {
      spaces += '\u00A0';
      spaces += '\u00A0';
    }
    return spaces;
  }
}
