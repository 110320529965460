import { Component, Inject, Input, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { PartialUpdatePdfDocumentModel } from 'src/app/features/shared/forms/models/report-input-document/partial-update-pdf-document.model';
import { DownloadFile } from 'src/app/shared/utils/download-file/download-file';

@Component({
  selector: 'jaro-kit-document-preview-extracted-document',
  templateUrl: './document-preview-extracted-document.component.html',
  styleUrls: ['./document-preview-extracted-document.component.scss']
})
export class DocumentPreviewExtractedDocumentComponent implements OnInit {

  document: PartialUpdatePdfDocumentModel;
  @Input() isLoading: boolean;
  @Input() paginationAttributeSuffix: string = "DocumentPreviewExtracted";

  constructor(
    private downloadFile: DownloadFile,
    public dialogRef: MatDialogRef<DocumentPreviewExtractedDocumentComponent>,
    @Inject(MAT_DIALOG_DATA)
    public documentData: {
      document: PartialUpdatePdfDocumentModel;
    }
  ) {}

  ngOnInit(): void {
    this.document = this.documentData.document;
  }

  pdfLoadingFailed() {
    this.dialogRef.close(true);
  }

  closeDialog(){
    this.dialogRef.close();
  }

  download() {
    if(this.document.extractedURL)
     {
      this.downloadFile.downloadFileBrowserDialog(this.document.extractedURL,this.document.title, this.document.fileExtension);
     }
   }

}
