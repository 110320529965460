import { Component, Input, OnInit } from '@angular/core';
import { FormGroup, Validators } from '@angular/forms';
import { MatRadioChange } from '@angular/material/radio';

@Component({
  selector: 'halo-global-utility-card',
  templateUrl: './utility-card.component.html',
  styleUrls: ['./utility-card.component.scss'],
})
export class UtilityCardComponent implements OnInit {
  @Input() form: FormGroup;
  @Input() title: string;
  @Input() description: string;
  @Input() icon: string;

  @Input() controlName: string;
  @Input() descriptionControlName: string;

  isFocus: boolean;

  isDisabledDescription: boolean;
  isInvalidData: boolean;
  isValidData: boolean;

  ngOnInit(): void {
    this.isDisabledDescription = this.form.value[this.controlName] !== 'other';
    this.checkValidData();
  }

  // Disable and clear the description text box if the selected radio button is "Public", otherwise enable the description text box.
  onChangePublic($event: MatRadioChange) {
    const descriptionControl = this.form.controls[this.descriptionControlName];
    if (descriptionControl) {
      if ($event.value === 'public') {
        descriptionControl.setValue(null);
        descriptionControl.setValidators(null);
        descriptionControl.setErrors(null)
        descriptionControl.disable();
        descriptionControl.markAsPristine();
        this.isDisabledDescription = true;
      } else {
        descriptionControl.enable();
        descriptionControl.setValidators(Validators.required);
        descriptionControl.updateValueAndValidity();
        this.isDisabledDescription = false;
      }
    }

    // Radio-group's value not updated when the selected radio-button's value changes
    const formControl = this.form.controls[this.controlName];
    if (formControl) {
      formControl.setValue($event.value);
    }
    this.form.updateValueAndValidity();
    this.checkValidData();
  }

  onBlur() {
    this.isFocus = false;    
    this.checkValidData();
  }

  onFocus() {
    this.isFocus = true;
    this.checkValidData();
  }

  private checkValidData() {
    setTimeout(() => {
      this.isInvalidData =
        this.form.controls[this.controlName].invalid ||
        this.form.controls[this.descriptionControlName].invalid;

      this.isValidData =
        !this.isInvalidData &&
        (this.form.value[this.controlName] === 'public' ||
          (this.form.value[this.controlName] === 'other' &&
            this.form.value[this.descriptionControlName]));
      if (this.isDisabledDescription) {
        this.form.controls[this.descriptionControlName].disable();
      }
    }, 100);
  }
}
