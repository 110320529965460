import { HttpClient } from '@angular/common/http';
import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { ReportValidationDto, ReportValidationGroupDto } from '@proxy/appraisal/report/reports/v1';

export class ValidationLineItem {
  public message: string;
}

export class ValidationGroup {
  public message: string;
  public lineItems: ValidationLineItem[];
}

@Component({
  selector: 'jaro-kit-report-validation-message',
  templateUrl: './report-validation-message.component.html',
  styleUrls: ['./report-validation-message.component.scss'],
})
export class ReportValidationMessageComponent implements OnInit {
  validationSummary: Record<string, ValidationGroup>; // Key is validationGroup.groupRule  

  constructor(
    public dialogRef: MatDialogRef<ReportValidationMessageComponent>,
    @Inject(MAT_DIALOG_DATA)
    public data: {
      validations: ReportValidationDto[];
    },
    private http: HttpClient
  ) {}

  ngOnInit(): void {
    this.http
      .get('/assets/json/validation/validation.config.json')
      .subscribe((validationGroupDtos: ReportValidationGroupDto[]) => {
        this.validationSummary = {};
        
        this.data.validations.forEach((validationDto) => {
          const validationGroupDto = validationGroupDtos.find((groupDto) =>
            groupDto.rules.includes(validationDto.rule)
          );
          let group: ValidationGroup;
          const groupRule = validationGroupDto?.groupRule ?? validationDto.message;
          group = this.validationSummary[groupRule];                        
          if (!group) {
            group = this.validationSummary[groupRule] = {               
              message: validationGroupDto?.message ?? validationDto.message,
              lineItems: []
            } as ValidationGroup;
          } 

          // Only line items in appraiser-details are shown as these are more
          // difficult for the appraiser to find in the app as they won't be
          // highlighted as required in JaroKit.
          if(groupRule === "appraiser-details")
          {
            group.lineItems.push({ 
              message: validationDto.message 
            } as ValidationLineItem);
          }
        });        
      });
  }
}
