import { Component, Input } from '@angular/core';
import { MarketConditionDto } from '@proxy/appraisal/report/reports/v1/form1004';

@Component({
  selector: 'jaro-kit-general-information',
  templateUrl: './general-information.component.html',
  styleUrls: ['./general-information.component.scss']
})
export class GeneralInformationComponent{
  @Input() isEditMode: boolean;
  @Input() marketCondition: MarketConditionDto;
}
