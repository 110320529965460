<div *ngFor="let sectionPhoto of photoSections" class="report-photos">
  <span class="section-name report-input-title mat-headline" *ngIf="!sectionPhoto.isEditSection" (click)="editSectionNameClick(sectionPhoto)"
    >{{ sectionPhoto.name }} ({{ sectionPhoto.photos.length }})</span
  >

  <div class="add-section-container" *ngIf="sectionPhoto.isEditSection">
    <jaro-kit-edit-section-name
      [sections]="sections"
      [sectionName]="sectionPhoto.name"
      [section]="sectionPhoto"
      (onEditSection)="editSection($event)"
      (onCancelEditSection)="cancelEditSection(sectionPhoto)"
      (onDeleteSection)="deleteSection($event)"
    ></jaro-kit-edit-section-name>
  </div>
  <jaro-kit-upload-file
    [section]="sectionPhoto.name"
    [sectionId]="sectionPhoto.id"
    [orderAssignmentId]="orderAssignmentId"
    [(sectionUploadFiles)]="sectionUploadPhotos"
    [maximumOfFileToUpload]="maximumOfFileToUpload"
    fileTypeAccept="image"
    (onUploadPhotosToSection)="uploadPhotosToSection($event, sectionPhoto.id)"
    (onMovePhoto)="movePhoto($event, sectionPhoto.id)"
  ></jaro-kit-upload-file>

  <jaro-kit-photo-section-items
    [(photos)]="sectionPhoto.photos"
    [orderAssignmentId]="orderAssignmentId"
    [reportId]="reportId"
    [reportVersion]="reportVersion"
    [sectionOptions]="sectionOptions"
    [sectionId]="sectionPhoto.id"
    [photoSections]="photoSections"
    [galleryPhotos]="galleryPhotos"
    [photosUploading]="getSectionPhotoUpload(sectionPhoto.id)"
    (onMakeCoverPhoto)="makeCoverPhoto($event)"
    (onUpdatePhotoSections)="updateSectionPhotos($event)"
    (onAddPhotosToSection)="addPhotosSection($event, sectionPhoto.id)"
    (onRemovePhoto)="removePhotoFromSection($event, sectionPhoto.id)"
    (onMovePhoto)="movePhoto($event, sectionPhoto.id)"
    (onUpdatePhotoTitle)="updatePhotoTitle($event)"
  ></jaro-kit-photo-section-items>
</div>
