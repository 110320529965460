<ng-container *ngIf="form" [formGroup]="form">
  <div class="utility-card-view-mode">
    <div class="utility-card-title">
      <em [ngClass]="icon"></em>
      <p class="mat-title">{{ title }}</p>
    </div>
    <div class="utility-card-value">
      <p class="report-input-label">Type</p>
      <p class="report-input-value utility-card-type">{{ value || 'N/A' }}</p>
    </div>

    <div class="utility-card-description">
      <p class="report-input-label">Description</p>
      <div class="report-input-value">{{ description  || 'N/A' }}</div>
    </div>
  </div>
</ng-container>
