import { Component, Inject, Input, OnInit } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { ContentServiceInterface } from 'src/app/interface/content/content-service-interface';
import { ItemData } from 'src/app/shared/components/multiple-combo-box/multiple-combo-box.component';
import InjectionSymbol from 'src/app/shared/injection/injection-symbol';

@Component({
  selector: 'halo-global-view',
  templateUrl: './view.component.html',
  styleUrls: ['./view.component.scss'],
})
export class ViewComponent implements OnInit {
  @Input() form: FormGroup;

  viewRatingOptions:ItemData[] = [];
  viewFactorOptions:ItemData[] = [];
  zoningComplianceOptions:ItemData[] = [];
  isShowViewFactor1Description: boolean;
  isShowViewFactor2Description: boolean;  
  constructor(
    @Inject(InjectionSymbol.ContentService)
    public contentService: ContentServiceInterface
  ) {
    this.viewRatingOptions = this.contentService.getContentByKey('view.impact');
    this.viewFactorOptions = this.contentService.getContentByKey('view.factors', true);
    this.zoningComplianceOptions = this.contentService.getContentByKey('zoningCompliance');
  }

  ngOnInit(): void {
    this.isShowViewFactor1Description = this.form.value.siteViewFactor1 === 'other';
    this.isShowViewFactor2Description = this.form.value.siteViewFactor2 === 'other';    
  }

  onChangeViewFactor1(value) {
    if (value !== 'other') {
      const siteViewFactor1Desc = this.form.controls['siteViewFactor1Desc'];
      if (siteViewFactor1Desc) {
        siteViewFactor1Desc.setValue(null);
        siteViewFactor1Desc.markAsDirty();
      }
    }
    this.isShowViewFactor1Description = value === 'other';
    this.form.updateValueAndValidity();
  }

  onChangeViewFactor2(value) {
    if (value !== 'other') {
      const siteViewFactor2Desc = this.form.controls['siteViewFactor2Desc'];
      if (siteViewFactor2Desc) {
        siteViewFactor2Desc.setValue(null);
        siteViewFactor2Desc.markAsDirty();
      }
    }
    this.isShowViewFactor2Description = value === 'other';
    this.form.updateValueAndValidity();
  }  

  removeViewFactor2() {
    const viewFactor2 = this.form.controls['siteViewFactor2'];
    if (viewFactor2) {
      viewFactor2.setValue(null);
      viewFactor2.markAsDirty();
    }
    this.onChangeViewFactor2(null);
  }
}
