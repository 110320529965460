<ng-container *ngIf="form" [formGroup]="form">
  <div class="row pb-15">
    <div class="col-md-6 pl-0 pr-15">
      <div class="report-input-form-field subject-pud">
        <mat-checkbox
          formControlName="costApproachIncludedInReport"
          class="report-input-check-box-label"
          value="true"
          >Include In Report</mat-checkbox
        >
      </div>
      
      <mat-form-field appearance="outline" class="jaro-kit-textarea">
        <mat-label
          >Support for the opinion of site value (summary of comparable land sales or other methods
          for estimating site value)</mat-label
        >
        <textarea
          type="text"
          formControlName="costApproachSupportForOpinionOfSiteValue"
          placeholder="Support for the opinion of site value"
          matInput
          rows="4"
        ></textarea>
      </mat-form-field>

      <div class="report-input-form-field">
        <p class="report-input-label report-input-radio-label">Estimated</p>
        <mat-radio-group formControlName="costApproachEstimated" class="mt-15 mb-15">
          <mat-radio-button [value]="'Reproduction'" class="report-input-radio-button"
            >Reproduction</mat-radio-button
          >
          <mat-radio-button [value]="'Replacement Cost New'" class="report-input-radio-button"
            >Replacement Cost New</mat-radio-button
          >
        </mat-radio-group>
      </div>

      <mat-form-field appearance="outline">
        <mat-label>Source of cost data</mat-label>
        <input matInput formControlName="costApproachSource" autocomplete="off" />
      </mat-form-field>

      <div class="row">
        <div class="col-lg-7 col-md-7 pl-0 pr-15">
          <mat-form-field class="col-md-12" appearance="outline">
            <mat-label>Quality rating from cost service</mat-label>
            <input matInput formControlName="costApproachQualityRating" autocomplete="off" />
          </mat-form-field>
        </div>
        <div class="col-lg-5 col-md-5 pl-0 pr-0">
          <mat-form-field class="col-md-12" appearance="outline">
            <mat-label>Effective date of cost data</mat-label>
            <input matInput formControlName="costApproachEffectiveDate" autocomplete="off" />
          </mat-form-field>
        </div>
      </div>

      <mat-form-field appearance="outline" class="jaro-kit-textarea">
        <mat-label
          >Comments on Cost Approach (gross living area calculations, depreciation, etc.)</mat-label
        >
        <textarea
          type="text"
          formControlName="costApproachComment"
          placeholder="Comments on Cost Approach"
          matInput
          rows="4"
        ></textarea>
      </mat-form-field>

      <mat-form-field appearance="outline">
        <mat-label>Estimated Remaining Economic Life (HUD and VA only) Years</mat-label>
        <input
          matInput
          formControlName="costApproachEstimatedRemainingEconomicLife"
          autocomplete="off"
          currencyMask
          mask="separator.0"
          thousandSeparator=","
          [allowNegativeNumbers]="false"
        />
      </mat-form-field>
    </div>

    <div class="col-md-6 pl-15 pr-0">
      <div class="row">
        <div class="col-lg-9 col-md-9 pl-0 pr-15">
          <p class="report-input-label">Opinion of Site Value</p>
        </div>
        <div class="col-lg-3 col-md-3 pl-0 pr-0">
          <mat-form-field class="col-md-12" appearance="outline">
            <mat-label>Site Value $</mat-label>
            <input
              matInput
              formControlName="costApproachOpinionOfSiteValue"
              autocomplete="off"
              currencyMask
              mask="separator.0"
              thousandSeparator=","
              [allowNegativeNumbers]="false"
              (change)="onChangeIndicatedValue($event, 'costApproachOpinionOfSiteValue')"
            />
          </mat-form-field>
        </div>
      </div>

      <div class="row">
        <div class="col-lg-3 col-md-3 pl-0 pr-15">
          <p class="report-input-label">Dwelling</p>
        </div>
        <div class="col-lg-3 col-md-3 pl-0 pr-15">
          <mat-form-field class="col-md-12" appearance="outline">
            <mat-label>Square Feet</mat-label>
            <input
              matInput
              formControlName="costApproachDwellingSquareFeet"
              autocomplete="off"
              [disabled]="true"
              currencyMask
              mask="separator.2"
              thousandSeparator=","
            />
          </mat-form-field>
        </div>
        <div class="col-lg-3 col-md-3 pl-0 pr-15">
          <mat-form-field class="col-md-12" appearance="outline">
            <mat-label>Sq. Ft. @ $</mat-label>
            <input
              matInput
              formControlName="costApproachDwellingPricePerSquareFeet"
              autocomplete="off"
              currencyMask
              mask="separator.2"
              thousandSeparator=","
              [allowNegativeNumbers]="false"
              (change)="onChangeDwellingPrice($event)"
            />
          </mat-form-field>
        </div>
        <div class="col-lg-3 col-md-3 pl-0 pr-0">
          <mat-form-field class="col-md-12" appearance="outline">
            <mat-label>Cost $</mat-label>
            <input
              matInput
              formControlName="costApproachDwellingCost"
              autocomplete="off"
              [disabled]="true"
              currencyMask
              mask="separator.2"
              thousandSeparator=","
              (change)="onChangeTotalCost()"
            />
          </mat-form-field>
        </div>
      </div>

      <div class="row">
        <div class="col-lg-3 col-md-3 pl-0 pr-15">
          <mat-form-field class="col-md-12" appearance="outline">
            <mat-label>Below Grade Description</mat-label>
            <input
              matInput
              formControlName="costApproachBelowGradeDescription"
              autocomplete="off"
            />
          </mat-form-field>
        </div>
        <div class="col-lg-3 col-md-3 pl-0 pr-15 below-grade-synchronisation">
          <mat-form-field appearance="outline">
            <mat-label>Square Feet</mat-label>
            <input
              matInput
              formControlName="costApproachBelowGradeSquareFeet"
              autocomplete="off"
              numberMask
              mask="separator.0"
              thousandSeparator=","
              [allowNegativeNumbers]="false"
              (change)="onChangeBelowGradeCost($event, 'costApproachBelowGradeSquareFeet')"
              [disabled]="form.value['costApproachIsSyncActiveForBelowGradeSquareFeet']"
            />
          </mat-form-field>

          <mat-slide-toggle
            formControlName="costApproachIsSyncActiveForBelowGradeSquareFeet"
            (change)="onChangeBelowGradeSynchronisation($event)"
          ></mat-slide-toggle>
        </div>
        <div class="col-lg-3 col-md-3 pl-0 pr-15">
          <mat-form-field class="col-md-12" appearance="outline">
            <mat-label>Sq. Ft. @ $</mat-label>
            <input
              matInput
              formControlName="costApproachBelowGradePricePerSquareFeet"
              autocomplete="off"
              currencyMask
              mask="separator.2"
              thousandSeparator=","
              [allowNegativeNumbers]="false"
              (change)="onChangeBelowGradeCost($event, 'costApproachBelowGradePricePerSquareFeet')"
            />
          </mat-form-field>
        </div>
        <div class="col-lg-3 col-md-3 pl-0 pr-0">
          <mat-form-field class="col-md-12" appearance="outline">
            <mat-label>Cost $</mat-label>
            <input
              matInput
              formControlName="costApproachBelowGradeCost"
              autocomplete="off"
              [disabled]="true"
              currencyMask
              mask="separator.2"
              thousandSeparator=","
              (change)="onChangeTotalCost()"
            />
          </mat-form-field>
        </div>
      </div>

      <div class="row">
        <div class="col-lg-9 col-md-9 pl-0 pr-15">
          <mat-form-field class="col-md-12" appearance="outline">
            <mat-label>Extras Description</mat-label>
            <input matInput formControlName="costApproachExtraDescription" autocomplete="off" />
          </mat-form-field>
        </div>
        <div class="col-lg-3 col-md-3 pl-0 pr-0">
          <mat-form-field class="col-md-12" appearance="outline">
            <mat-label>Cost $</mat-label>
            <input
              matInput
              formControlName="costApproachExtraCost"
              autocomplete="off"
              currencyMask
              mask="separator.0"
              thousandSeparator=","
              [allowNegativeNumbers]="false"
              (change)="onChangeTotalCost($event)"
            />
          </mat-form-field>
        </div>
      </div>

      <div class="row">
        <div class="col-lg-3 col-md-3 pl-0 pr-15">
          <p class="report-input-label">Garage/Carport</p>
        </div>
        <div class="col-lg-3 col-md-3 pl-0 pr-15">
          <mat-form-field class="col-md-12" appearance="outline">
            <mat-label>Square Feet</mat-label>
            <input
              matInput
              formControlName="costApproachGarageCarportSquareFeet"
              autocomplete="off"
              numberMask
              mask="separator.0"
              thousandSeparator=","
              [allowNegativeNumbers]="false"
              (change)="onChangeGarageCarportSquareFeet($event)"
            />
          </mat-form-field>
        </div>
        <div class="col-lg-3 col-md-3 pl-0 pr-15">
          <mat-form-field class="col-md-12" appearance="outline">
            <mat-label>Sq. Ft. @ $</mat-label>
            <input
              matInput
              formControlName="costApproachGarageCarportPricePerSquareFeet"
              autocomplete="off"
              currencyMask
              mask="separator.2"
              thousandSeparator=","
              [allowNegativeNumbers]="false"
              (change)="onChangeGarageCarportPrice($event)"
            />
          </mat-form-field>
        </div>
        <div class="col-lg-3 col-md-3 pl-0 pr-0">
          <mat-form-field class="col-md-12" appearance="outline">
            <mat-label>Cost $</mat-label>
            <input
              matInput
              formControlName="costApproachGarageCarportCost"
              autocomplete="off"
              [disabled]="true"
              currencyMask
              mask="separator.2"
              thousandSeparator=","
              (change)="onChangeTotalCost()"
            />
          </mat-form-field>
        </div>
      </div>

      <div class="row">
        <div class="col-lg-9 col-md-9 pl-0 pr-15">
          <p class="report-input-label">Total Estimate of Cost-New</p>
        </div>
        <div class="col-lg-3 col-md-3 pl-0 pr-0">
          <mat-form-field class="col-md-12" appearance="outline">
            <mat-label>Total Cost $</mat-label>
            <input
              matInput
              formControlName="costApproachTotalCost"
              autocomplete="off"
              [disabled]="true"
              currencyMask
              mask="separator.2"
              thousandSeparator=","
            />
          </mat-form-field>
        </div>
      </div>

      <div class="row">
        <div class="col-lg-2 col-md-2 pl-0 pr-5 less-depreciation">
          <p class="report-input-label">Less Depreciation</p>
        </div>
        <div class="col-lg-2 col-md-2 pl-0 pr-5 less-depreciation">
          <mat-form-field class="col-md-12" appearance="outline">
            <mat-label>Physical $</mat-label>
            <input
              matInput
              formControlName="costApproachDepreciationPhysical"
              autocomplete="off"
              currencyMask
              mask="separator.0"
              thousandSeparator=","
              [allowNegativeNumbers]="false"
              (change)="onChangeDepreciationCost($event, 'costApproachDepreciationPhysical')"
            />
          </mat-form-field>
        </div>
        <div class="col-lg-3 col-md-3 pl-0 pr-5 less-depreciation">
          <mat-form-field class="col-md-12" appearance="outline">
            <mat-label>Functional $</mat-label>
            <input
              matInput
              formControlName="costApproachDepreciationFunctional"
              autocomplete="off"
              currencyMask
              mask="separator.0"
              thousandSeparator=","
              [allowNegativeNumbers]="false"
              (change)="onChangeDepreciationCost($event, 'costApproachDepreciationFunctional')"
            />
          </mat-form-field>
        </div>
        <div class="col-lg-2 col-md-2 pl-0 pr-5 less-depreciation">
          <mat-form-field class="col-md-12" appearance="outline">
            <mat-label>External $</mat-label>
            <input
              matInput
              formControlName="costApproachDepreciationExternal"
              autocomplete="off"
              currencyMask
              mask="separator.0"
              thousandSeparator=","
              [allowNegativeNumbers]="false"
              (change)="onChangeDepreciationCost($event, 'costApproachDepreciationExternal')"
            />
          </mat-form-field>
        </div>
        <div class="col-lg-3 col-md-3 pl-0 pr-0">
          <mat-form-field class="col-md-12" appearance="outline">
            <mat-label>Total Depreciation $</mat-label>
            <input
              matInput
              formControlName="costApproachTotalDepreciation"
              autocomplete="off"
              [disabled]="true"
              currencyMask
              mask="separator.2"
              thousandSeparator=","
            />
          </mat-form-field>
        </div>
      </div>

      <div class="row">
        <div class="col-lg-9 col-md-9 pl-0 pr-15">
          <p class="report-input-label">Depreciated Cost of Improvements</p>
        </div>
        <div class="col-lg-3 col-md-3 pl-0 pr-0">
          <mat-form-field class="col-md-12" appearance="outline">
            <mat-label>Depreciated Cost $</mat-label>
            <input
              matInput
              formControlName="costApproachDepreciatedCost"
              autocomplete="off"
              [disabled]="true"
              currencyMask
              mask="separator.2"
              thousandSeparator=","
            />
          </mat-form-field>
        </div>
      </div>

      <div class="row">
        <div class="col-lg-9 col-md-9 pl-0 pr-15">
          <p class="report-input-label">“As-is” Value of Site Improvements</p>
        </div>
        <div class="col-lg-3 col-md-3 pl-0 pr-0">
          <mat-form-field class="col-md-12" appearance="outline">
            <mat-label>“As-is” Value $</mat-label>
            <input
              matInput
              formControlName="costApproachAsIsValueOfSiteImprovements"
              autocomplete="off"
              autocomplete="off"
              currencyMask
              mask="separator.0"
              thousandSeparator=","
              [allowNegativeNumbers]="false"
              (change)="onChangeIndicatedValue($event, 'costApproachAsIsValueOfSiteImprovements')"
            />
          </mat-form-field>
        </div>
      </div>

      <div class="row">
        <div class="col-lg-9 col-md-9 pl-0 pr-15">
          <p class="report-input-label">Indicated Value By Cost Approach</p>
        </div>
        <div class="col-lg-3 col-md-3 pl-0 pr-0">
          <mat-form-field class="col-md-12" appearance="outline">
            <mat-label>Total Cost $</mat-label>
            <input
              matInput
              formControlName="costApproachIndicatedValue"
              autocomplete="off"
              [disabled]="true"
              currencyMask
              mask="separator.2"
              thousandSeparator=","
            />
          </mat-form-field>
        </div>
      </div>
    </div>
  </div>
</ng-container>
