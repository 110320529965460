<div class="market-cleanup-property-summary">
  <div class="property-address">
    <button (click)="cancel()">
      <i class="fal fa-times" aria-hidden="true"></i>
    </button>

    <span class="report-input-headline">{{ address }}</span>
  </div>
  <div class="save-button-panel">
    <button
      (click)="save()"
      class="jaro-kit-primary-button btn-save-section"
      [disabled]="isLoading || !hasPropertyAttributeChanges || hasPropertyAttributeHasErrors"
    >
      Save
    </button>
  </div>
</div>
