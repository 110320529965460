import { Component, Input, OnInit } from '@angular/core';
import { FormGroup } from '@angular/forms';

@Component({
  selector: 'halo-global-utility-card-view-mode',
  templateUrl: './utility-card-view-mode.component.html',
  styleUrls: ['./utility-card-view-mode.component.scss'],
})
export class UtilityCardViewModeComponent implements OnInit {
  @Input() form: FormGroup;
  @Input() title: string;
  @Input() icon: string;
  @Input() controlName: string;
  @Input() descriptionControlName: string;

  value: string;
  description: string;

  ngOnInit(): void {
    this.value = this.form.value[this.controlName];
    this.description = this.form.value[this.descriptionControlName];
  }
}
