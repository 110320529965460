import { Component, Inject, Input, OnInit } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { ContentServiceInterface } from 'src/app/interface/content/content-service-interface';
import { ItemData } from 'src/app/shared/components/multiple-combo-box/multiple-combo-box.component';
import InjectionSymbol from 'src/app/shared/injection/injection-symbol';

@Component({
  selector: 'jaro-kit-location-rating-and-factors',
  templateUrl: './location-rating-and-factors.component.html',
  styleUrls: ['./location-rating-and-factors.component.scss'],
})
export class LocationRatingAndFactorsComponent implements OnInit {
  @Input() form: FormGroup;

  locationRatingOptions: ItemData[] = [];
  locationFactorsOptions: ItemData[] = [];
  isShowLocationFactor1Des: boolean;
  isShowLocationFactor2Des: boolean;
  constructor(
    @Inject(InjectionSymbol.ContentService)
    public contentService: ContentServiceInterface
  ) {
    this.locationRatingOptions = this.contentService.getContentByKey('location.impact');
    this.locationFactorsOptions = this.contentService.getContentByKey('location.factors', true);
  }

  ngOnInit(): void {
    this.isShowLocationFactor1Des = this.form.value.locationFactor1 === 'other';
    this.isShowLocationFactor2Des = this.form.value.locationFactor2 === 'other';
  }

  onChangeLocationFactor1(value) {
    if (value !== 'other' || !value) {
      const locationFactor1Desc = this.form.controls['locationFactor1Desc'];
      if (locationFactor1Desc) {
        locationFactor1Desc.setValue(null);
        locationFactor1Desc.markAsDirty();
      }
    }
    this.isShowLocationFactor1Des = value === 'other';
    this.form.updateValueAndValidity();
  }

  onChangeLocationFactor2(value) {
    if (value !== 'other' || !value) {
      const locationFactor2Desc = this.form.controls['locationFactor2Desc'];
      if (locationFactor2Desc) {
        locationFactor2Desc.setValue(null);
        locationFactor2Desc.markAsDirty();
      }
    }
    this.isShowLocationFactor2Des = value === 'other';
    this.form.updateValueAndValidity();
  }

  removeLocationFactor2() {
    const locationFactor = this.form.controls['locationFactor2'];
    if (locationFactor) {
      locationFactor.setValue(null);
      locationFactor.markAsDirty();
    }
    this.onChangeLocationFactor2(null);
  }
}
