import { Component, Input } from '@angular/core';

@Component({
  selector: 'jaro-kit-comparable-data-reference-item',
  templateUrl: './comparable-data-reference-item.component.html',
  styleUrls: ['./comparable-data-reference-item.component.scss'],
})
export class ComparableDataReferenceItemComponent {
  @Input() data: any;
}
