import { Pipe } from "@angular/core";

@Pipe({
    name: 'emptyArrayIfTrue',
})
export class EmptyArrayIfTruePipe {

    transform(objects: any[], isReturnEmpty: boolean): any[] {
        if(objects) {
            return objects.filter(_object => {
                return !isReturnEmpty;
            });
        }
    }

}