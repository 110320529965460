
<div class="row-color-alternate">
    <div *ngFor="let item of data">
        <div [ngClass]="item.Level == '1' ? 'raw-data-item-section-header-row raw-data-item-flex-row' : 'raw-data-item-flex-row'">
            <span style="flex: 1;" class="raw-data-item-data raw-data-item-data-bold">
                <span>{{ spaces(item.Level) }}</span>
                <span>{{ item.Key }}</span>
            </span>
            <span style="flex: 1;" class="raw-data-item-data">
                {{ item.Value }}
            </span>  
        </div>
    </div>
</div>