import { Inject, Injectable, Type } from '@angular/core';
import { FormControl, FormGroup, ValidatorFn, Validators } from '@angular/forms';
import { ReportDto } from '@proxy/appraisal/report/v1/form1004';
import { filter, get, isEmpty } from 'lodash-es';
import * as moment from 'moment-timezone';
import { BehaviorSubject, Observable, forkJoin, of } from 'rxjs';
import { catchError, map, mergeMap } from 'rxjs/operators';
import { AssignmentTemplateFormFieldConfigs } from 'src/app/features/assignment/shared/providers/assignment-template-form-field-configs.prototype';
import {
  JaroBoostComponentMapping,
  JaroBoostViewModeComponentMapping,
} from 'src/app/features/jaro-boost/shared/providers/jaro-boost-layout-components.prototype';
import { JaroBoostTemplateFormFieldConfigs } from 'src/app/features/jaro-boost/shared/providers/jaro-boost-template-form-field-configs.prototype';
import { FormBuilderComponentMapping } from 'src/app/features/report/shared/providers/form-builder-layout-components.prototype';
import { FeatureName, FeatureNames } from 'src/app/shared/enums/report-input.enum';
import InjectionSymbol from 'src/app/shared/injection/injection-symbol';
import { MenuItemModel } from 'src/app/shared/models/menuItem.model';
import { ToLocalTimeForTimeZonePipe } from 'src/app/shared/pipes/to-local-time-for-time-zone/to-local-time-for-time-zone';
import { ToUtcForTimeZonePipe } from 'src/app/shared/pipes/to-utc-for-time-zone/to-utc-for-time-zone';
import { CacheService } from 'src/app/shared/services/cache.service';
import { Feature } from 'src/app/shared/utils/feature/feature';
import {
  ReportComponentMapping,
  ViewModeComponentMapping,
} from '../../../report/shared/providers/report-input-layout-components.prototype';
import { ReportInputTemplateFormFieldConfigs } from '../../../report/shared/providers/report-input-template-form-field-configs.prototype';
import { ReportInputDataFormFieldModel } from '../models/report-input-data-field-form-model/report-input-date-field-form-model';
import {
  PublishFieldChange,
  ReportInputFieldType,
  ReportInputTemplate,
  ReportInputTemplateField,
  ReportInputTemplateSectionModel,
  ReportInputTemplateSubSectionModel,
} from '../models/report-input-layout.model';
import { ReportInputTemplateInterface } from './report-input-template.interface';

export type ProductConfig = {
  mainForms: string [];
  productTemplatePath : string;
  formTemplatePath : string;
  defaultFeature: FeatureName;
  isEnabledFunc: (mainForm: string, featureName: string) => boolean;  
};

@Injectable({
  providedIn: 'root',
})
export class ReportInputTemplateService implements ReportInputTemplateInterface {
  public onSave: BehaviorSubject<string[]> = new BehaviorSubject<string[]>(null);
  public onCancel: BehaviorSubject<string[]> = new BehaviorSubject<string[]>(null);
  public onPublishFieldChanges: BehaviorSubject<PublishFieldChange[]> = new BehaviorSubject<
    PublishFieldChange[]
  >(null);
  
  private productConfigs: ProductConfig[] = [
    { mainForms: ["1004 - SFR"], productTemplatePath: "1004", formTemplatePath: null, defaultFeature: FeatureNames.Report, 
        isEnabledFunc: (_mainForm: string, _featureName: string) => Feature.isEnabledFeature("ReportTemplate.1004") },
    { mainForms: ["1004C - Manufactured"], productTemplatePath: "1004c", formTemplatePath: null, defaultFeature: FeatureNames.JaroBoost, 
        isEnabledFunc: (_mainForm: string, _featureName: string) => true},
    { mainForms: ["1004D Appraisal Update"], productTemplatePath: "1004d", formTemplatePath: "1004d/appraisal_update", defaultFeature: FeatureNames.Report, 
        isEnabledFunc: (_mainForm: string, _featureName: string) => Feature.isEnabledFeature("ReportTemplate.1004D") },
    { mainForms: ["1004D Final Inspection"], productTemplatePath: "1004d", formTemplatePath: "1004d/final_inspection", defaultFeature: FeatureNames.Report, 
        isEnabledFunc: (_mainForm: string, _featureName: string) => Feature.isEnabledFeature("ReportTemplate.1004D") },    
    { mainForms: ["1004D Final & Update"], productTemplatePath: "1004d", formTemplatePath: null, defaultFeature: FeatureNames.Report, 
        isEnabledFunc: (_mainForm: string, _featureName: string) => Feature.isEnabledFeature("ReportTemplate.1004D") },
    { mainForms: ["1025 - 2-4 Unit"], productTemplatePath: "1025", formTemplatePath: null, defaultFeature: FeatureNames.JaroBoost, 
        isEnabledFunc: (_mainForm: string, _featureName: string) => true},
    { mainForms: ["1025 + 216"], productTemplatePath: "1025_216", formTemplatePath: null, defaultFeature: FeatureNames.JaroBoost, 
        isEnabledFunc: (_mainForm: string, _featureName: string) => true},
    { mainForms: ["1073 - Condo"], productTemplatePath: "1073", formTemplatePath: null, defaultFeature: FeatureNames.JaroBoost, 
        isEnabledFunc: (_mainForm: string, _featureName: string) => true},
    { mainForms: ["HUD CIR-92051", "HUD-92051 Compliance Inspection Report"], productTemplatePath: "fha_hud_92051", formTemplatePath: null, defaultFeature: FeatureNames.Report, 
        isEnabledFunc: (_mainForm: string, _featureName: string) => Feature.isEnabledFeature("ReportTemplate.FHAHUD92051") },            
  ];

  private defaultProductConfig: ProductConfig = { mainForms: null, productTemplatePath: "shared", formTemplatePath: null, defaultFeature: FeatureNames.Assignment, 
                                                    isEnabledFunc: (_mainForm: string, _featureName: string) => true };

  protected templateFormFieldConfigs = ReportInputTemplateFormFieldConfigs.concat(
    JaroBoostTemplateFormFieldConfigs,
    AssignmentTemplateFormFieldConfigs
  );  

  constructor(
    @Inject(InjectionSymbol.CacheService)
    private cacheService: CacheService
  ) {}

  canActivateTemplate(mainForm: string, featureName: string): boolean {
    const productConfig =  this.getProductConfig(mainForm);
    return productConfig.isEnabledFunc(mainForm, featureName);
  }

  public getDefaultFeatureByProductType(mainForm: string) {
    return this.getProductConfig(mainForm)?.defaultFeature;
  }

  getTemplateByProductType(mainForm: string, featureName: FeatureName = FeatureNames.Report): Observable<ReportInputTemplate> {
    if (!this.canActivateTemplate(mainForm, featureName))
      throw new Error(`Cannot activate the form template for product main form '${mainForm}' and feature '${featureName}'.`);

    const productConfig =  this.getProductConfig(mainForm);
    let productAssetPath = productConfig.productTemplatePath ? `/assets/json/template/${featureName}/${productConfig.productTemplatePath}/template.json` : null;
    let formAssetPath = productConfig.formTemplatePath ? `/assets/json/template/${featureName}/${productConfig.formTemplatePath}/template.json` : null;    
    
    const productTemplate = productAssetPath ? this.cacheService.checkAndGetJsonFile(productAssetPath).pipe(
      catchError(() => {
        return this.cacheService.checkAndGetJsonFile(`/assets/json/template/${featureName}/shared/template.json`);
      }) 
    ) as Observable<ReportInputTemplate> : null;

    const mainFormTemplate = formAssetPath ? this.cacheService.checkAndGetJsonFile(formAssetPath).pipe(
      catchError(() => {
        return this.cacheService.checkAndGetJsonFile(`/assets/json/template/${featureName}/shared/template.json`);
      })
    ) as Observable<ReportInputTemplate> : null;
             
    if(mainFormTemplate && productTemplate) {
      return this.mergeTemplates(productTemplate, mainFormTemplate);
    }
    else {
      if(productTemplate || mainFormTemplate) {
        return productTemplate ?? mainFormTemplate;
      }
      else {
        return this.cacheService.checkAndGetJsonFile(`/assets/json/template/${featureName}/${this.defaultProductConfig.productTemplatePath}/template.json`);
      }
    }
  }  

  getConfigurationMenuLeft(mainForm: string, isBoostOnly: boolean): Observable<MenuItemModel[]> {
    if (!this.canActivateTemplate(mainForm, null)) {
      throw new Error('Cannot activate the sidebar menu template');
    }

    const url = '/assets/json/template/menu_left.json';
    const menuItems = this.cacheService.checkAndGetJsonFile(url) as Observable<MenuItemModel[]>;
    const enabledMenuItems = menuItems.pipe(
      map((items) => {
        if (isBoostOnly) {
          return filter(items, (item) => item.title === 'Boost');
        } else {
          return filter(
            items,
            (item) =>
              (item.title !== 'Market' &&
                item.title !== 'Comparables' &&
                item.title !== 'JaroBoost') ||
              (item.title === 'Market' && Feature.isEnabledFeature('Market')) ||
              (item.title === 'Comparables' && Feature.isEnabledFeature('Comparables')) ||
              (item.title === 'JaroBoost' && Feature.isEnabledFeature('JaroBoost'))
          );
        }
      })
    );

    return enabledMenuItems;
  }

  getComponentByName(componentName: string, isViewMode = false, feature = 'report'): Type<any> {
    let mapping = isViewMode ? ViewModeComponentMapping : ReportComponentMapping;
    if (feature === 'jaro-boost') {
      mapping = isViewMode ? JaroBoostViewModeComponentMapping : JaroBoostComponentMapping;
    }
    let component = mapping.find((item) => item.componentName === componentName);
    if (!component) {
      component = FormBuilderComponentMapping.find((item) => item.componentName === componentName);
    }
    return component ? component.componentType : null;
  }

  getDynamicFormControl(
    field: ReportInputTemplateField,
    report: any,
    originalFormControlName?: string
  ) {
    if (field) {
      const formFieldConfig = this.templateFormFieldConfigs.find(
        (item) =>
          item.formControlName === field.formControlName ||
          item.formControlName === originalFormControlName
      );
      this.mergeFormFieldConfig(field, formFieldConfig);
      field.value = this.getValue(field, report, null);
      field.isVisible = this.isVisible(field, report);
    }

    const formControl = new FormControl({ value: field.value, disabled: field.isDisabled });

    formControl.setValidators(this.getValidatorsByKey(field));
    return formControl;
  }

  initValuesForSection(
    section: ReportInputTemplateSectionModel,
    report: ReportDto,
    timeZoneId: string,
    reportInputSectionForm?: FormGroup
  ) {
    section.subSections.forEach((subSection) => {
      this.initValuesForSubSection(subSection, report, timeZoneId, reportInputSectionForm);
    });
  }

  updateVisibleForSection(
    section: ReportInputTemplateSectionModel,
    report: ReportDto,
    reportInputForm?: FormGroup
  ) {
    section.subSections.forEach((subSection) => {
      this.updateVisibleForSubSection(subSection, report, reportInputForm);
    });
  }

  updateVisibleForSubSection(
    subSection: ReportInputTemplateSubSectionModel,
    report,
    reportInputForm?: FormGroup
  ) {
    subSection.fields.forEach((field) => {
      field.isVisible = this.isVisible(field, report, reportInputForm);
    });
  }

  private getProductConfig(mainForm: string): ProductConfig {
    const productConfig =  mainForm ? this.productConfigs.find(config => config.mainForms != null && 
      config.mainForms.find(form => form != null && form.toLowerCase() === mainForm.toLowerCase()) != null) : this.defaultProductConfig;
    return productConfig || this.defaultProductConfig;
  }

  private initValuesForSubSection(
    subSection: ReportInputTemplateSubSectionModel,
    report: ReportDto,
    timeZoneId: string,
    reportInputSectionForm?: FormGroup
  ) {
    subSection.fields.forEach((field) => {
      if (field) {
        const formFieldConfig = this.templateFormFieldConfigs.find(
          (item) => item.formControlName === field.formControlName
        );
        this.mergeFormFieldConfig(field, formFieldConfig);
        field.value = this.getValue(field, report, timeZoneId);
        field.isVisible = this.isVisible(field, report, reportInputSectionForm);

        if (reportInputSectionForm) {
          const control = reportInputSectionForm.controls[field.formControlName];
          if (control) {
            control.setValue(field.value);
            if (field.isRequired) {
              control.markAsDirty();
            }
          }
        }
      }
    });
  }

  private mergeFormFieldConfig(
    field: ReportInputTemplateField,
    formFieldConfig: ReportInputDataFormFieldModel
  ) {
    if (formFieldConfig) {
      field.mapToFieldFunc = formFieldConfig.mapToFieldFunc || field.mapToFieldFunc;
      field.mapToModelFunc = formFieldConfig.mapToModelFunc || field.mapToModelFunc;
      field.mapToPartialUpdateDto =
        formFieldConfig.mapToPartialUpdateDto || field.mapToPartialUpdateDto;
      field.format = formFieldConfig.format || field.format;
      field.isIgnoreChange = formFieldConfig.isIgnoreChange || field.isIgnoreChange;
      field.updatePath = formFieldConfig.updatePath || field.updatePath;
      field.events = formFieldConfig.events || field.events;
    }
  }

  private mergeTemplates(productTemplate: Observable<ReportInputTemplate>, mainFormTemplate: Observable<ReportInputTemplate>): Observable<ReportInputTemplate> {
    const templates = {
      productTemplate: productTemplate,
      mainFormTemplate: mainFormTemplate      
    };

    return forkJoin(templates).pipe(
      mergeMap(
        (results: {
          productTemplate: ReportInputTemplate;
          mainFormTemplate: ReportInputTemplate;
        }) => {
          const productTemplate = results.productTemplate;
          const mainFormTemplate = results.mainFormTemplate;
          if (results.productTemplate) {
            const sections: ReportInputTemplateSectionModel[] = [];
            productTemplate.sections.forEach((section) => {
              let matchingMainSection = mainFormTemplate.sections.find((item) => item.id === section.id);
              if (matchingMainSection) {
                sections.push(matchingMainSection);
                // matchingMainSection = {} as ReportInputTemplateSectionModel;
                // Object.keys(matchingMainSection).forEach((propertyName) => {
                //   matchingMainSection[propertyName] = section[propertyName];
                // });
                // sections.push(matchingMainSection);
              } else {
                sections.push(section);
              }
            });
            mainFormTemplate.sections = sections;
          }
          return of(mainFormTemplate);
        }
      )
    );
  }

  getGroupFormControlBySection(section: ReportInputTemplateSectionModel) {
    const groupFormControl = {};
    section.subSections.forEach((subSection) => {
      subSection.fields.forEach((field) => {
        const controlFormBuilder = [
          { value: field.value, disabled: !!field.isDisabled },
          {
            validators: this.getValidatorsByKey(field),
            updateOn: 'change',
          },
        ];
        groupFormControl[field.formControlName] = controlFormBuilder;
      });
    });

    return groupFormControl;
  }

  getValidatorsByKey(field: ReportInputTemplateField): ValidatorFn[] {
    const validators = [];

    if (field.isRequired) {
      validators.push(Validators.required);
    }
    if (field?.validators?.maxLength) {
      validators.push(Validators.maxLength(field.validators.maxLength));
    }

    if (field?.validators?.minLength) {
      validators.push(Validators.minLength(field.validators.minLength));
    }

    if (field?.validators?.min) {
      validators.push(Validators.min(field.validators.min));
    }

    return validators;
  }

  isVisible(
    field: ReportInputTemplateField | ReportInputTemplateSubSectionModel,
    reportDto: ReportDto,
    reportInputForm?: FormGroup
  ): boolean {
    field.isVisible = !(
      this.isHiddenForAllow(field, reportDto, reportInputForm) ||
      this.isHiddenForDeny(field, reportDto, reportInputForm)
    );
    return field.isVisible;
  }

  private isHiddenForAllow(
    field: ReportInputTemplateField,
    reportDto: ReportDto,
    reportInputForm?: FormGroup
  ) {
    if (!isEmpty(field.allow)) {
      return field.allow.some((item) => {
        let valueItem = get(reportDto, item.key);
        if (item.isFormControl && reportInputForm) {
          valueItem = reportInputForm.value[item.key];
        }
        return !item.values.some((value) => this.isTheSameValue(value, valueItem));
      });
    }
    return false;
  }

  private isHiddenForDeny(
    field: ReportInputTemplateField,
    reportDto: ReportDto,
    reportInputForm?: FormGroup
  ) {
    if (!isEmpty(field.deny)) {
      return field.deny.some((item) => {
        let valueItem = get(reportDto, item.key);
        if (item.isFormControl && reportInputForm) {
          valueItem = reportInputForm.value[item.key];
        }
        return item.values.some((value) => this.isTheSameValue(value, valueItem));
      });
    }
    return false;
  }

  isTheSameValue(value: any, anotherValue: any): boolean {
    if (typeof value === 'string' && typeof anotherValue === 'string') {
      return value.toLowerCase() === anotherValue.toLowerCase();
    }
    return value === anotherValue;
  }

  getFormFieldConfig(
    controlName: string,
    section: ReportInputTemplateSectionModel
  ): ReportInputTemplateField {
    for (let subSection of section.subSections) {
      const field = subSection.fields.find((field) => field.formControlName === controlName);
      if (field) {
        const formFieldConfig = this.templateFormFieldConfigs.find(
          (item) => item.formControlName === field.formControlName
        );
        this.mergeFormFieldConfig(field, formFieldConfig);
        return field;
      }
    }
    return null;
  }

  mapToPartialUpdateDto(
    formFieldConfig: ReportInputTemplateField,
    value,
    reportInputUpdate: Record<string, string>,
    timeZoneId?: string
  ): void {
    if (formFieldConfig && formFieldConfig.path && !formFieldConfig.isIgnoreChange) {
      const path = formFieldConfig?.updatePath ? formFieldConfig.updatePath : formFieldConfig.path;
      reportInputUpdate[path] = value;

      switch (formFieldConfig.type) {
        case ReportInputFieldType.Date:
          this.mapDateToPartialUpdateDto(
            formFieldConfig,
            value,
            reportInputUpdate,
            path,
            timeZoneId
          );
          break;
        case ReportInputFieldType.ComboBox:
        case ReportInputFieldType.Array:
          if (Array.isArray(value)) {
            reportInputUpdate[path] = JSON.stringify(value);
          }
          break;
      }

      if (formFieldConfig.mapToPartialUpdateDto) {
        reportInputUpdate[path] = formFieldConfig.mapToPartialUpdateDto(value);
      }
    }
  }

  private mapDateToPartialUpdateDto(
    formFieldConfig: ReportInputTemplateField,
    value,
    reportInputUpdate: Record<string, string>,
    path: string,
    timeZoneId?: string
  ) {

    // no provided date configuration
    if (!formFieldConfig.dateConfig) {
      if (value && typeof value === 'object') {
        let date = this.getDateValue(value, 'MM/DD/YYYY');
        reportInputUpdate[path] = date;
      }
    }
    else if (formFieldConfig.dateConfig.dataFormat == "utc") {
      let timeZoneDate = this.getDateValue(value);
      let utcDate = new ToUtcForTimeZonePipe().transform(
        timeZoneDate,
        'YYYY-MM-DD HH:mm:ss',
        timeZoneId
      );
      reportInputUpdate[path] = utcDate;
    }
    else if (formFieldConfig.dateConfig.dataFormat == "local") {
      let timeZoneDate = this.getDateValue(value);
      let utcDate = new ToUtcForTimeZonePipe().transform(
        timeZoneDate,
        'YYYY-MM-DD HH:mm:ss',
        null
      );
      reportInputUpdate[path] = utcDate;
    }
    else {
      let date = this.getDateValue(value, formFieldConfig.dateConfig.dataFormat);
      reportInputUpdate[path] = date;
    }
  }

  private getValue(
    formFieldConfig: ReportInputTemplateField,
    reportDto: ReportDto,
    timeZoneId: string
  ) {
    if (formFieldConfig && formFieldConfig.path) {
      const value = get(reportDto, formFieldConfig.path);
      if (formFieldConfig.mapToFieldFunc) {
        return this.getMappedValue(formFieldConfig, reportDto, value, timeZoneId);
      } else {
        return this.getFormattedValue(formFieldConfig, value, timeZoneId);
      }
    }
    return null;
  }

  private getMappedValue(
    formFieldConfig: ReportInputTemplateField,
    reportDto: ReportDto,
    value: any,
    timeZoneId: string
  ) {
    try {
      if (formFieldConfig.type === ReportInputFieldType.Date) {
        value = new ToLocalTimeForTimeZonePipe().transform(value, formFieldConfig.dateConfig?.displayFormat ?? 'MM/DD/YYYY', timeZoneId);
      }
      return formFieldConfig.mapToFieldFunc(value, reportDto, timeZoneId);
    } catch (err) {
      console.error('Observer got an error: ', err);
      return value;
    }
  }

  private getFormattedValue(
    formFieldConfig: ReportInputTemplateField,
    value: any,
    timeZoneId: string
  ) {
    switch (formFieldConfig.type) {
      case ReportInputFieldType.Checkbox:
        return value && typeof value === 'string' ? value.toLowerCase().trim() : value;
      case ReportInputFieldType.ComboBox:
      case ReportInputFieldType.Array:
        return value && Array.isArray(value) ? value : [];
      case ReportInputFieldType.Date:
        // no provided date configuration
        if (!formFieldConfig.dateConfig) {
          return this.getDateValue(value);
        }
        else if (formFieldConfig.dateConfig.dataFormat == "utc") {
          let date = new ToLocalTimeForTimeZonePipe().transform(value, formFieldConfig.dateConfig.displayFormat ?? "MM/DD/YYYY", timeZoneId);
          return date;
        }
        else if (formFieldConfig.dateConfig.dataFormat == "local") {
          let date = new ToLocalTimeForTimeZonePipe().transform(value, formFieldConfig.dateConfig.displayFormat ?? "MM/DD/YYYY", null);
          return date;
        }
        else {
          return value && moment(value, formFieldConfig.dateConfig.dataFormat).isValid() ?
                moment(value, formFieldConfig.dateConfig.dataFormat).format(formFieldConfig.dateConfig.displayFormat ?? "MM/DD/YYYY") : null;
        }
      default:
        return value;
    }
  }

  getDateValue(date, format: string = 'MM/DD/YYYY'): string {
    return date && moment(date).isValid() ? moment(date).format(format) : null;
  }

  onSaveSection(section: ReportInputTemplateSectionModel) {
    if (section.eventsOnSave) {
      this.onSave.next(section.eventsOnSave);
    }
  }

  onCancelSection(section: ReportInputTemplateSectionModel) {
    if (section.eventsOnCancel) {
      this.onCancel.next(section.eventsOnCancel);
    }
  }

  publishFieldChanges(fieldChanges: PublishFieldChange[]) {
    this.onPublishFieldChanges.next(fieldChanges);
  }
}
