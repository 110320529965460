import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AutoLoginPartialRoutesGuard } from 'angular-auth-oidc-client';
import { AppComponent } from './app.component';
import { LoginFailedComponent } from './login-failed/login-failed.component';

const routes: Routes = [  
  {
    path: '',
    component: AppComponent,
  },
  {
    path: 'admin',
    canLoad: [AutoLoginPartialRoutesGuard],
    loadChildren: () =>
      import('./admin/admin.module').then((m) => m.AdminModule),
  },
  {
    path: 'report',
    canLoad: [AutoLoginPartialRoutesGuard],
    loadChildren: () =>
      import('./report-open/report-open.module').then((m) => m.ReportOpenModule),
  },
  {
    path: 'report/:internalOrderId',
    canLoad: [AutoLoginPartialRoutesGuard],
    loadChildren: () =>
      import('./report-open/report-open.module').then((m) => m.ReportOpenModule),
  },
  {
    path: 'report-input',
    canLoad: [AutoLoginPartialRoutesGuard],
    loadChildren: () =>
      import('./report-input/report-input.module').then((m) => m.ReportInputModule),
  },
  {
    path: 'boost/:orderId',
    canLoad: [AutoLoginPartialRoutesGuard],
    loadChildren: () =>
      import('./report-open/report-open.module').then((m) => m.ReportOpenModule),
  },
  {
    path: 'boost',
    canLoad: [AutoLoginPartialRoutesGuard],
    loadChildren: () =>
      import('./report-open/report-open.module').then((m) => m.ReportOpenModule),
  },  
  {
    path: 'raw-data-view',
    canLoad: [AutoLoginPartialRoutesGuard],
    loadChildren: () =>
      import('./features/raw-data-view/raw-data-view.module').then((m) => m.RawDataViewModule),
  },
  {
    path: 'login-failed',
    component: LoginFailedComponent,
  },
  {
    path: '**',
    redirectTo: '404',
  },
];

@NgModule({
  imports: [RouterModule.forRoot(routes, { relativeLinkResolution: 'legacy' } )],
  exports: [RouterModule],
})
export class AppRoutingModule {}
