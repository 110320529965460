<div>
  <span class="comparable-grid-cell-label">{{ field.label}}</span>
</div>
<div [ngSwitch]="field.inputType" class="comparable-grid-cell-input-tpl">
  <ng-template [ngSwitchCase]="'date'">
    <div *ngIf="!isAdjustment" class="comparable-grid-cell-value">
      {{ value | dateTimeFormat: 'MM/DD/YYYY' | defaultValue }}
    </div>
    <div *ngIf="isAdjustment" class="comparable-grid-cell-value">{{ value | defaultValue }}</div>
  </ng-template>
  

  <ng-template [ngSwitchCase]="'NestedItems'">
    <div class="comparable-grid-cell-value">{{ value | defaultValue  }}</div>
  </ng-template>


  <ng-template [ngSwitchCase]="'currency'">
    <div class="comparable-grid-cell-value">{{ value | currency: 'USD':'symbol':'1.0-2' | defaultValue }}</div>
  </ng-template>

  <ng-template [ngSwitchCase]="'array'">
    <div class="comparable-grid-cell-value">{{ value | arrayFormat: '; ' | defaultValue }}</div>
  </ng-template>
  <some-element *ngSwitchDefault>
    <div class="comparable-grid-cell-value">
      {{ value | dynamicPipe: field.format?.pipeType:field.format?.pipeArgs | defaultValue }}
    </div>
  </some-element>
</div>
