import { Component, Inject, Input, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { SubjectPropertyDto } from '@proxy/appraisal/report/reports/v1/form1004/models';
import { OrderDetailDto } from '@proxy/ascent/order/order-assignment/order-assignments/v2/orderDetailDto';
import InjectionSymbol from 'src/app/shared/injection/injection-symbol';
import { EventService } from 'src/app/shared/services/event.service';
@Component({
  selector: 'jaro-kit-order-summary-banner',
  templateUrl: './order-summary-banner.component.html',
  styleUrls: ['./order-summary-banner.component.scss'],
})
export class OrderSummaryBannerComponent implements OnInit {
  @Input() orderData: OrderDetailDto;
  @Input() orderAssignmentId: string;
  @Input() subjectProperty: SubjectPropertyDto;

  @Input() showBoostDataViewButton: boolean;
  rawDataUrl: string;
  assignmentType: string;

  constructor(
    @Inject(InjectionSymbol.EventService)
    private eventService: EventService,
    private router: Router) {}

  ngOnInit(): void {
    this.assignmentType = this.subjectProperty?.assignmentType;
      this.eventService.onUpdateAssignmentType.subscribe((assignmentType: string) => {
        this.assignmentType = assignmentType;
    });
    this.rawDataUrl = this.router.createUrlTree(['raw-data-view'],  {queryParams: { orderAssignmentId: this.orderAssignmentId }})?.toString();


  }
}
