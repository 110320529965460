<ng-container *ngIf="form" [formGroup]="form">
  <div
    [ngClass]="{
      'amenity-card': true,
      'amenity-card-invalid': isInvalidData,
      'amenity-card-valid': isValidData || (isFocus && !isInvalidData)
    }"
  >
    <div class="amenity-card-title">
      <em [ngClass]="icon"></em>
      <p class="mat-title">{{ field.label }}</p>
    </div>
    <div class="amenity-card-value mt-15">
      <p class="report-input-label">Type</p>

      <div class="report-input-radio">
        <mat-radio-group
          [formControlName]="controlName"
          class="mt-15 mb-15"
          (change)="onChangeRadio($event)"
        >
          <mat-radio-button [value]="true" class="report-input-radio-button">Yes</mat-radio-button>
          <mat-radio-button [value]="false" class="report-input-radio-button">No</mat-radio-button>
        </mat-radio-group>
      </div>
    </div>

    <div class="amenity-card-description">
      <ng-container *ngIf="isNumber">      
        <mat-form-field  appearance="outline">
          <mat-label>{{ description }}</mat-label>
          <input
            matInput
            [id]="descriptionControlName"
            [formControlName]="descriptionControlName"
            mask="separator.0"
            thousandSeparator=""
            separatorLimit="99"
            [allowNegativeNumbers]="false"
            placeholder="Whole number"
          />
        </mat-form-field>
        <jaro-kit-validation-message [form]="form" [controlName]="descriptionControlName" [isRequired]="true"></jaro-kit-validation-message>
    </ng-container>

      <mat-form-field *ngIf="!isNumber" appearance="outline">
        <mat-label>{{ description }}</mat-label>
        <input
          matInput
          [formControlName]="descriptionControlName"
          autocomplete="off"
          placeholder="Description"
        />
      </mat-form-field>
    </div>
  </div>
</ng-container>
