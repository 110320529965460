<halo-global-popup [dialogRef]="dialogRef" [isHideClose]="true">
  <div
    [ngClass]="{
      'translucent-loading-overlay': isSubmitting
    }"
  >
    <mat-spinner
      *ngIf="isSubmitting"
      value="100"
      diameter="100"
      strokeWidth="5"
      class="action-loading-spinner"
    ></mat-spinner>

    <div class="document-review-container">
      <div class="document-review-top">
        <div class="document-top-left">
          <span class="document-select-pages"></span>
        </div>

        <div class="document-top-right">
          <button class="document-button-submit" (click)="submitReportToJaroDesk()" *ngIf="isActiveSendReport" [disabled]="isDisabledSendReport || isSubmitting">Submit</button>
          <button class="document-button-cancel" (click)="cancelSubmit()">Cancel</button>
        </div>
      </div>


      <div class="document-review-detail">
        <jaro-kit-preview-document [url]="urlPDF" *ngIf="urlPDF" [(isLoading)]="isLoading" paginationAttributeSuffix="PreviewReport"></jaro-kit-preview-document>
      </div>

    </div>
  </div>
</halo-global-popup>
