import { HttpClient } from '@angular/common/http';
import { Component, Inject, Input, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { PartialUpdatePdfDocumentModel } from 'src/app/features/shared/forms/models/report-input-document/partial-update-pdf-document.model';
import { DocumentPreviewExtractedDocumentComponent } from '../document-preview-extracted-document/document-preview-extracted-document.component';

@Component({
  selector: 'jaro-kit-document-library-preview-modal',
  templateUrl: './document-library-preview-modal.component.html',
  styleUrls: ['./document-library-preview-modal.component.scss']
})
export class DocumentLibraryPreviewModalComponent implements OnInit {

  document: PartialUpdatePdfDocumentModel;
  isImage: boolean = false;
  @Input() isLoading: boolean;
  @Input() paginationAttributeSuffix: string = "DocumentPreviewExtracted";

  constructor(
    private http: HttpClient,
    public dialogRef: MatDialogRef<DocumentPreviewExtractedDocumentComponent>,
    @Inject(MAT_DIALOG_DATA)
    public documentData: {
      document: PartialUpdatePdfDocumentModel;
      isContractDocument: boolean;
    }
  ) { }

  ngOnInit(): void {
    this.document = this.documentData.document;
    if (this.documentData.document.fileExtension != 'pdf') {
      this.isImage = true;
    }
  }

  pdfLoadingFailed() {
    this.dialogRef.close(true);
  }

  closeDialog() {
    this.dialogRef.close();
  }

  download() {
    if (this.document?.documentFileLocation?.href) {
      this.http.get(this.document.documentFileLocation.href, { responseType: 'blob' })
        .subscribe(res => {
          let a = document.createElement('a');
          a.href = URL.createObjectURL(res);
          a.download = this.document.title;

          //download
          a.click();
        });
    }
  }

}
