import { Component, Input } from '@angular/core';
import { FormGroup } from '@angular/forms';

@Component({
  selector: 'jaro-kit-pud-information-total',
  templateUrl: './pud-information-total.component.html',
  styleUrls: ['./pud-information-total.component.scss'],
})
export class PUDInformationTotalComponent{
  @Input() form: FormGroup;
  @Input() isEditMode: boolean;
}
