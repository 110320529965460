<div class="section-view-mode-detail container">
  <div class="row" *ngIf="!subSection.isCustomComponent">
    <ng-container *ngFor="let group of fieldGroups">
      <ng-container *ngIf="group.spanAllField">
        <div class="ps-0 pe-0 col-md-12">
          <div class="row">
            <jaro-kit-report-input-label
              [field]="group.spanAllField"
              [ngClass]="getLayoutHintField(group.spanAllField)"
              [timeZoneId]="timeZoneId"
              [reportInputForm]="reportInputSectionForm"
              [reportDto]="reportDto"
              [section]="section"
              [subSection]="subSection"
              [feature]="feature"
            ></jaro-kit-report-input-label>
          </div>
        </div>
      </ng-container>
      <div
        [ngClass]="{
          'col-md-4': group.middleFields.length > 0 && group.rightFields.length > 0,
          'col-md-6': group.middleFields.length === 0 && group.rightFields.length > 0,
          'pe-0': true,
          'col-md-12': group.middleFields.length === 0 && group.rightFields.length === 0,
          'pl-0': true
        }"
      >
        <div class="row">
          <jaro-kit-report-input-label
            *ngFor="let field of group.leftFields"
            [field]="field"
            [ngClass]="getLayoutHintField(field)"
            [timeZoneId]="timeZoneId"
            [reportInputForm]="reportInputSectionForm"
            [reportDto]="reportDto"
            [section]="section"
            [subSection]="subSection"
            [feature]="feature"
          ></jaro-kit-report-input-label>
        </div>
      </div>
      <ng-container *ngIf="group.middleFields.length > 0">
        <div [ngClass]="{
            'col-md-4': group.rightFields.length > 0,
            'col-md-6': group.rightFields.length === 0,
            'pe-0': true,
            'pl-0': true
          }" >
          <div class="row">
            <jaro-kit-report-input-label
              *ngFor="let field of group.middleFields"
              [field]="field"
              [ngClass]="getLayoutHintField(field)"
              [timeZoneId]="timeZoneId"
              [reportInputForm]="reportInputSectionForm"
              [reportDto]="reportDto"
              [feature]="feature"
            ></jaro-kit-report-input-label>
          </div>
        </div>
      </ng-container>
      <ng-container *ngIf="group.rightFields.length > 0">
        <div [ngClass]="{
          'col-md-4': group.middleFields.length > 0,
          'col-md-6': group.middleFields.length === 0,
          'pe-0': true,
          'pl-0': true
        }" *ngIf="group.rightFields.length > 0">
          <div class="row">
            <jaro-kit-report-input-label
              *ngFor="let field of group.rightFields"
              [field]="field"
              [ngClass]="getLayoutHintField(field)"
              [timeZoneId]="timeZoneId"
              [reportInputForm]="reportInputSectionForm"
              [reportDto]="reportDto"
              [feature]="feature"
            ></jaro-kit-report-input-label>
          </div>
        </div>
      </ng-container>
    </ng-container>
  </div>
  <div class="row" *ngIf="subSection.isCustomComponent">
    <jaro-kit-dynamic-component
      [componentName]="subSection.componentName"
      [reportDto]="reportDto"
      [reportInputForm]="reportInputSectionForm"
      [section]="section"
      [subSection]="subSection"
      [isEditMode]="false"
      [feature]="feature"
      [orderAssignmentId]="orderAssignmentId"
      [timeZoneId]="timeZoneId"
      [reportVersionDto]="reportVersionDto"
    ></jaro-kit-dynamic-component>
  </div>
</div>
