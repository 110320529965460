import {
  CdkDragDrop,
  copyArrayItem,
  moveItemInArray,
  transferArrayItem,
} from '@angular/cdk/drag-drop';
import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { ReportInputPhotoPositionType } from '@proxy/bff/activity/report-input-photos';
import {
  ReportInputPhotoPositionDto,
  ReportInputPhotoSectionDto,
} from '@proxy/bff/activity/report-input-photos/v1';
import { ReportPhotoResourceModel } from 'src/app/shared/dtos/report-input-resource/report-photo-resource.model';
import { ReportResourceType } from 'src/app/shared/enums/report-resource-type.enum';
import { PartialUpdatePhotoModel } from 'src/app/shared/models/photo/partial-update-photo.model';
import { FileUploading } from 'src/app/shared/services/file-uploading';

@Component({
  selector: 'jaro-kit-photo-section-items',
  templateUrl: './photo-section-items.component.html',
  styleUrls: ['./photo-section-items.component.scss'],
})
export class PhotoSectionItemsComponent implements OnInit {
  @Input() photos: PartialUpdatePhotoModel[] = [];
  @Input() orderAssignmentId: string;
  @Input() reportId: string;
  @Input() reportVersion: number;
  @Input() sectionOptions: string[];
  @Input() sectionId: string;
  @Input() photosUploading: FileUploading[] = [];
  @Input() photoSections: ReportInputPhotoSectionDto[] = [];
  @Input() galleryPhotos: ReportPhotoResourceModel[];

  @Output() onMakeCoverPhoto = new EventEmitter<ReportPhotoResourceModel>();
  @Output() onRemovePhoto = new EventEmitter<ReportPhotoResourceModel>();
  @Output() onUpdatePhotoTitle = new EventEmitter<ReportPhotoResourceModel>();
  @Output() onUpdatePhotoSections = new EventEmitter<ReportInputPhotoSectionDto[]>();
  @Output() onMovePhoto = new EventEmitter<PartialUpdatePhotoModel>();
  @Output() onAddPhotosToSection = new EventEmitter<ReportPhotoResourceModel[]>();

  form: FormGroup;

  constructor(private formBuilder: FormBuilder) {
    this.form = this.formBuilder.group({});
  }

  ngOnInit(): void {
    this.photos.forEach((photo) => {
      photo.currentSectionId = this.sectionId;
      this.addPhotoTitle(photo);
    });
  }

  private addPhotoTitle(photo: PartialUpdatePhotoModel) {
    const photoTitle = new FormControl(photo.title, Validators.required);

    this.form.addControl(photo.id, photoTitle);
  }

  makeCoverPhoto(photo) {
    this.onMakeCoverPhoto.emit(photo);
  }

  removePhoto(photo: ReportPhotoResourceModel) {
    this.onRemovePhoto.emit(photo);
  }

  updateSectionPhotos(sections: ReportInputPhotoSectionDto[]) {
    this.onUpdatePhotoSections.emit(sections);
  }

  onDrop(event: CdkDragDrop<PartialUpdatePhotoModel[]>) {
    const photo = event.item.data;
    photo.position = null;
    if (event.previousContainer === event.container) {
      const currentIndex = this.photos.findIndex((item) => item.id === photo.id);
      photo.newSectionId = null;
      moveItemInArray(event.container.data, event.previousIndex, event.currentIndex);
      const photoIds = event.container.data.map((item) => item.id);
      photo.position = this.getPosition(photoIds, photo.id);
      const newIndex = photoIds.indexOf(photo.id);
      if (currentIndex !== newIndex) {
        this.onMovePhoto.emit(photo);
      }
    } else {
      if (this.photos.some((item) => item.documentId === photo.documentId)) {
        return;
      }

      if (event.item.boundaryElement === ReportResourceType.Photo && photo) {
        copyArrayItem(
          event.previousContainer.data,
          event.container.data,
          event.previousIndex,
          event.currentIndex
        );
        const photoIds = event.container.data.map((item) => item.id);
        photo.position = this.getPosition(photoIds, photo.id);

        this.onAddPhotosToSection.emit([photo]);
      } else {
        transferArrayItem(
          event.previousContainer.data,
          event.container.data,
          event.previousIndex,
          event.currentIndex
        );
        const photoIds = event.container.data.map((item) => item.id);
        photo.position = this.getPosition(photoIds, photo.id);

        photo.newSectionId = this.sectionId;
        this.onMovePhoto.emit(photo);
      }
    }
  }

  toggleEditMode(photo: PartialUpdatePhotoModel) {
    if (!this.form.controls[photo.id]) {
      this.addPhotoTitle(photo);
    }
    this.editTitle(photo);
  }

  editTitle(photo: PartialUpdatePhotoModel) {
    this.form.controls[photo.id].setValue(photo.title);
    photo.isInEditMode = !photo.isInEditMode;
  }

  saveTitle(photo: PartialUpdatePhotoModel) {
    const photoTitleControl = this.form.controls[photo.id];
    if (photoTitleControl.valid) {
      const newTitle = photoTitleControl.value;
      photo.isInEditMode = false;
      if (newTitle && photo.title !== newTitle) {
        photo.title = this.form.controls[photo.id].value.trim();
        this.onUpdatePhotoTitle.emit(photo);
      }
    }
  }

  private getPosition(photoIds: string[], photoId: string): ReportInputPhotoPositionDto {
    const photoIndex = photoIds.indexOf(photoId);
    const positionType = this.getPositionType(photoIndex, photoIds.length);
    return {
      positionType: positionType,
      previousReportPhotoId:
        positionType === ReportInputPhotoPositionType.BetweenPhotos
          ? photoIds[photoIndex - 1]
          : null,
      nextReportPhotoId:
        positionType === ReportInputPhotoPositionType.BetweenPhotos
          ? photoIds[photoIndex + 1]
          : null,
    };
  }

  private getPositionType(photoIndex: number, photoCount: number): ReportInputPhotoPositionType {
    switch (photoIndex) {
      case 0:
        return ReportInputPhotoPositionType.First;
      case photoCount - 1:
      case -1:
        return ReportInputPhotoPositionType.Last;
      default:
        return ReportInputPhotoPositionType.BetweenPhotos;
    }
  }
}
