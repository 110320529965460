import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { PropertyRecordDto } from '@proxy/property/property-record/property-records/v1';
import { ReportComparableDto, ReportComparableItemDto } from 'src/app/proxy-adapter/report/comparables/models';
import { SelectedPropertyRecordValuePipe } from 'src/app/proxy-adapter/shared/pipes/selected-property-record-value.pipe';

@Component({
  selector: 'jaro-kit-market-cleanup-property-summary',
  templateUrl: './market-cleanup-property-summary.component.html',
  styleUrls: ['./market-cleanup-property-summary.component.scss'],
})
export class MarketCleanupPropertySummaryComponent implements OnInit {
  @Input() property: PropertyRecordDto;
  @Input() reportComparable: ReportComparableDto;
  @Input() isLoading: boolean = true;
  @Input() hasPropertyAttributeChanges: boolean = false;
  @Input() hasPropertyAttributeHasErrors: boolean = false;
  @Output() onSaveRequested = new EventEmitter();
  @Output() onCancelRequested = new EventEmitter();

  selectedValuePipe: SelectedPropertyRecordValuePipe;
  address: string;
  adjustedSalePrice: number;
  price: number;

  constructor(
  ) {
    this.selectedValuePipe = new SelectedPropertyRecordValuePipe();
  }

  ngOnInit(): void {
    const listing = this.property.cleanedListing;
    this.address = listing.address;
    this.adjustedSalePrice = this.calculateAdjustedSalePrice();
    const salePriceString =  this.isSold(this.selectedValuePipe.transform(listing.standardStatus))
                              ? this.selectedValuePipe.transform(listing.closePrice)
                              : this.selectedValuePipe.transform(listing.listPrice);
    this.price = parseFloat(salePriceString);
  }

  private isSold(status: string) {
    if (status?.trim()) {
      return (status.toLowerCase() === "settled sale");
    } else {
      return false;
    }

  }

  save(): void {
    this.onSaveRequested.emit();
  }

  cancel(): void {
    this.onCancelRequested.emit();
  }

  // Gets the sum of the sale price
  // and the adjustment values for each adjustment item.
  private calculateAdjustedSalePrice(): number | null {
    if(!this.reportComparable?.items) {
      return null;
    }

    const items = this.reportComparable.items;
    if(!items.price)
    {
      return null;
    }

    let adjustedSalePrice = items.price;
    for(let prop in items)
    {
        if (!Object.prototype.hasOwnProperty.call(items, prop)) {
            continue;
        }

        // If does not have adjustment then must not be ComparableItemDto
        if(!items[prop] || typeof(items[prop]["adjustment"]) === "undefined") {
          continue;
        }

        const compItemDto = items[prop] as ReportComparableItemDto;
        if(!compItemDto.value) {
          continue;
        }

        adjustedSalePrice += compItemDto.adjustment;
    }

    const customItems = this.reportComparable.customItems;
    if(!customItems)
    {
      return adjustedSalePrice;
    }

    for(let item of customItems) {
      if(!item.adjustment) {
        continue;
      }

      adjustedSalePrice += item.adjustment;
    }

    return adjustedSalePrice;
  }
}
