<div class="property-commentary-article">
  <div *ngIf="attribute.comments" class="commentary-panel">
      <span class="commentary-title">{{attribute.title}}</span>
      <span class="toggle-icon mouse-cursor-pointer">
        <mat-icon
          matSuffix
          class="mat-suffix-icon"
          *ngIf="!isOpenDropdown"
          (click)="toggleOpenDropdown($event)"
          >arrow_drop_down</mat-icon>
        <mat-icon
          matSuffix
          class="mat-suffix-icon"
          *ngIf="isOpenDropdown"
          (click)="toggleOpenDropdown($event)"
          >arrow_drop_up</mat-icon>
      </span>
  </div>
  <div *ngIf="!attribute.comments" class="commentary-panel">
    <span class="col-10 commentary-title">{{attribute.title}} (Blank)</span>    
  </div>
  <div *ngIf="isOpenDropdown" class="comment-panel">{{attribute.comments}}</div>    
</div>   