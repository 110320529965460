<halo-global-popup [dialogRef]="dialogRef" [isHideClose]="true">
  <div class="sketch-dialog-container">
    <div class="sketch-dialog-top">
      <div (click)="closeDialog()" class="photo-review-close">
        <em class="fal fa-times"></em>
      </div>
    </div>
    <div class="sketch-dialog-detail">
      <div class="sketch-canvas-container">
        <div id="cont"></div>
        <div class="output"></div>
        <output></output>
      </div>

      <div class="sketch-virtual-keypad-container">
        <jaro-kit-sketch-virtual-keypad></jaro-kit-sketch-virtual-keypad>
      </div>
    </div>
  </div>
</halo-global-popup>
