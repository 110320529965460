<halo-global-popup [dialogRef]="dialogRef" [isHideClose]="true" [title]="imageData.workfile.title">
  <div class="document-review-container">
    <div class="workfile-review-top">
      <div class="workfile-top-left">
      </div>

      <div class="workfile-top-right">
        <div class="space"></div>
        <button class="jaro-kit-primary-button mouse-cursor-pointer" (click)="close()">Close</button>
      </div>
    </div>

    <div class="workfile-image-container">
      <img [src]="imageData.workfile.url" alt="image" />
    </div>
  </div>
</halo-global-popup>
