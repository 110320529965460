<section
  [ngClass]="{ 'report-input-sidebar': true, 'report-input-sidebar-expanded': isExpanded }"
  id="report-input-sidebar"
>
  <div class="jaro-kit-toggle-menu" (mouseover)="closeAllSubMenu()">
    <em
      class="fa-regular"
      [ngClass]="{ 'fa-bars': !isExpanded, 'fa-sidebar': isExpanded }"
      (click)="isExpanded = !isExpanded; toggleMenu.emit(isExpanded); onToggleSidebar()"
    ></em>
  </div>

  <mat-tree
    [dataSource]="dataSource"
    [treeControl]="treeControl"
    [ngClass]="{
      'jaro-kit-tree': true,
      'report-input-sidebar-mouseover': isMouseover
    }"
    (mouseover)="mouseoverMenu()"
    (mouseout)="mouseoutMenu()"
    *ngIf="isExpanded"
  >
    <mat-tree-node
      *matTreeNodeDef="let node"
      matTreeNodeToggle
      [ngClass]="{
        'menu-item-selected': node.isSelected,
        'menu-item-has-child-selected': node.hasChildSelected
      }"
      (mouseover)="closeAllSubMenu()"
    >
      <em
        class="mat-tree-icon"
        [ngClass]="node.isSelected && node.iconSelected ? node.iconSelected : node.icon"
        *ngIf="node.icon"
        (click)="onToggleMenu(node)"
      ></em>
      <span
        [ngClass]="{
          'mat-tree-title': true,
          'mat-tree-title-selected': node.routeSegment === selectedSubMenu
        }"
        (click)="onToggleMenu(node)"
        >{{ node.title }}</span
      >
    </mat-tree-node>

    <mat-nested-tree-node *matTreeNodeDef="let node; when: hasChild">
      <div
        [ngClass]="{
          'mat-tree-node': true,
          'menu-item-selected': node.isSelected,
          'menu-item-has-child-selected': node.hasChildSelected,
          'menu-item-expanded': treeControl.isExpanded(node)
        }"
        [matMenuTriggerFor]="jaroKitSubMenu"
        #menuTrigger="matMenuTrigger"
        (click)="onToggleMenu(node)"
        (mouseover)="treeControl.isExpanded(node) ? null : mouseover(node, menuTrigger)"
      >
        <em
          class="mat-tree-icon"
          [ngClass]="
            (node.isSelected || node.hasChildSelected) && node.iconSelected
              ? node.iconSelected
              : node.icon
          "
          *ngIf="node.icon"
          (click)="onToggleMenu(node)"
        ></em>
        <span
          (click)="onToggleMenu(node)"
          [ngClass]="{
            'mat-tree-title': true,
            'mat-tree-title-selected': node.routeSegment === selectedSubMenu || node.isSelected
          }"
          >{{ node.title }}</span
        >
        <button
          mat-icon-button
          matTreeNodeToggle
          [attr.aria-label]="'Toggle ' + node.title"
          class="mat-icon-expanded"
          (click)="closeAllSubMenu()"
        >
          <em
            class="fa-regular"
            [ngClass]="{
              'fa-chevron-down': !treeControl.isExpanded(node),
              'fa-chevron-up': treeControl.isExpanded(node)
            }"
          ></em>
        </button>
      </div>

      <mat-menu
        #jaroKitSubMenu="matMenu"
        [overlapTrigger]="true"
        class="jaro-kit-sub-menu jaro-kit-sub-menu-expanded"
        [overlapTrigger]="true"
        [hasBackdrop]="false"
        yPosition="below"
        xPosition="after"
      >
        <div (mouseleave)="menuTrigger.closeMenu()">
          <span class="jaro-kit-sub-menu-title" [disabled]="true">{{ node.title }}</span>
          <span
            mat-menu-item
            *ngFor="let subItem of node.children"
            (click)="onToggleMenu(subItem)"
            >{{ subItem.title }}</span
          >
        </div>
      </mat-menu>
      <div [class.jaro-kit-tree-invisible]="!treeControl.isExpanded(node)" role="group">
        <ng-container matTreeNodeOutlet></ng-container>
      </div>
    </mat-nested-tree-node>
  </mat-tree>

  <div
    *ngIf="!isExpanded"
    [ngClass]="{
      'report-input-sidebar-collapsed': true,
      'report-input-sidebar-mouseover': isMouseover
    }"
    (mouseover)="mouseoverMenu()"
    (mouseout)="mouseoutMenu()"
  >
    <mat-list class="nav" *ngFor="let item of dataSource.data">
      <a
        mat-list-item
        [ngClass]="{
          'menu-item': true,
          'menu-item-selected': item.routeSegment === featureRouteSegment,
          'menu-item-disabled': item.children?.length > 0
        }"
        (click)="onToggleMenu(item)"
        [matMenuTriggerFor]="jaroKitSubMenu"
        #menuTrigger="matMenuTrigger"
        (mouseover)="mouseover(item, menuTrigger)"
      >
        <em
          [ngClass]="
            item.routeSegment === featureRouteSegment && item.iconSelected ? item.iconSelected : item.icon
          "
          *ngIf="item.icon"
        ></em>
      </a>

      <mat-menu
        #jaroKitSubMenu="matMenu"
        [overlapTrigger]="true"
        class="jaro-kit-sub-menu"
        [overlapTrigger]="true"
        [hasBackdrop]="false"
        yPosition="below"
        xPosition="after"
      >
        <div (mouseleave)="menuTrigger.closeMenu()">
          <span class="jaro-kit-sub-menu-title" [disabled]="true">{{ item.title }}</span>
          <span
            mat-menu-item
            *ngFor="let subItem of item.children"
            (click)="onToggleMenu(subItem)"
            >{{ subItem.title }}</span
          >
        </div>
      </mat-menu>
    </mat-list>
  </div>
</section>
