import { LocalizationPipe } from '@abp/ng.core';
import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { LoginResponse, OidcSecurityService } from 'angular-auth-oidc-client';

@Component({
  selector: 'app-root',
  providers: [LocalizationPipe],
  template: `
    <abp-loader-bar></abp-loader-bar>
    <abp-dynamic-layout> </abp-dynamic-layout>
  `,
})
export class AppComponent implements OnInit {
  private static checkAuthThreadCount: number = 0;

  constructor(private oidcSecurityService: OidcSecurityService, private router: Router) {}

  ngOnInit(): void {
    // Sempahore lock to prevent login failures from a 2nd call to checkAuth 
    // while the 1st call to checkAuth is still running.
    AppComponent.checkAuthThreadCount++;
    if (AppComponent.checkAuthThreadCount > 1) return;

    this.oidcSecurityService.checkAuth().subscribe((_loginResponse: LoginResponse) => {
      AppComponent.checkAuthThreadCount--;
    });
  }  
}
