import { Component, Input, OnInit } from '@angular/core';
import {
  MarketConditionDto,
  MarketConditionInventoryAnalysisDto,
} from '@proxy/appraisal/report/reports/v1/form1004';

@Component({
  selector: 'jaro-kit-inventory-analysis',
  templateUrl: './inventory-analysis.component.html',
  styleUrls: ['./inventory-analysis.component.scss'],
})
export class InventoryAnalysisComponent implements OnInit {
  @Input() isEditMode: boolean;
  @Input() marketCondition: MarketConditionDto;
  inventoryAnalysis: MarketConditionInventoryAnalysisDto;

  ngOnInit(): void {
    if(this.marketCondition?.inventoryAnalysis) {
      this.inventoryAnalysis = this.marketCondition.inventoryAnalysis;
      this.inventoryAnalysis.totalNumberOfComparableActiveListings = this.inventoryAnalysis.totalNumberOfComparableActiveListings || {};
    }
  }

  getMonthsOfHousingSupply(type: string) {
    if (
      !this.inventoryAnalysis.totalNumberOfComparableSales[type] ||
      this.inventoryAnalysis.totalNumberOfComparableSales[type] == 0
    ) {
      return null;
    }
    return (
      this.inventoryAnalysis?.totalNumberOfComparableActiveListings[type] /
      (this.inventoryAnalysis.totalNumberOfComparableSales[type] /
        (type === 'prior7To12Months' ? 6 : 3))
    );
  }
}
