<ng-container *ngIf="form" [formGroup]="form">
  <div class="row">
    <div class="col-md-6 pl-0 pr-15">
      <mat-form-field appearance="outline">
        <mat-label>Total Room Count</mat-label>
        <input
          matInput
          formControlName="interiorTotalRoomCount"
          id="interiorTotalRoomCount"
          autocomplete="off"
          mask="separator.0"
          thousandSeparator=""
          separatorLimit="99"
          [allowNegativeNumbers]="false"
        />
      </mat-form-field>
      <jaro-kit-validation-message [form]="form" [controlName]="'interiorTotalRoomCount'" [isRequired]="true"></jaro-kit-validation-message>
      
      <mat-form-field appearance="outline">
        <mat-label>Total Bedroom Count</mat-label>
        <input
          matInput
          formControlName="interiorTotalBedroomCount"
          id="interiorTotalBedroomCount"
          autocomplete="off"
          mask="separator.0"
          thousandSeparator=""
          separatorLimit="99"
          [allowNegativeNumbers]="false"
        />
      </mat-form-field>
      <jaro-kit-validation-message [form]="form" [controlName]="'interiorTotalBedroomCount'" [isRequired]="true"></jaro-kit-validation-message>

      <mat-form-field appearance="outline">
        <mat-label>Total Bathroom Count</mat-label>
        <input
          matInput
          formControlName="interiorTotalBathroomCount"
          id="interiorTotalBathroomCount"
          autocomplete="off"
          mask="0*.00"
          [allowNegativeNumbers]="false"
          [dropSpecialCharacters]="false"
          (focus)="onChangeTotalBathroomCount()"
          (keyup)="onChangeTotalBathroomCount()"
          maxlength="5"
        />
      </mat-form-field>
      <jaro-kit-validation-message [form]="form" [controlName]="'interiorTotalBathroomCount'" [isRequired]="true" [maxLength]="2"></jaro-kit-validation-message>
      <input formControlName="totalFullBathroomAboveGradeCount" type="hidden" />
      <input formControlName="totalHalfBathroomAboveGradeCount" type="hidden" />

      <mat-form-field appearance="outline">
        <mat-label>Gross Living Area Square Feet</mat-label>
        <input
          matInput
          formControlName="interiorGrossLivingArea"
          id="interiorGrossLivingArea"
          autocomplete="off"
          mask="separator.0"
          thousandSeparator=""
          separatorLimit="99999"
          [allowNegativeNumbers]="false"
          (change)="onChangeGrossLivingArea($event)"
        />
      </mat-form-field>
      <jaro-kit-validation-message [form]="form" [controlName]="'interiorGrossLivingArea'" [isRequired]="true"></jaro-kit-validation-message>
    </div>

    <div class="col-md-6 pl-15 pr-0">
      <div class="report-input-form-field">
        <p class="report-input-label report-input-radio-label">Evidence of Infestation</p>
        <mat-radio-group formControlName="interiorEvidenceOfInfestation" class="mt-15 mb-15">
          <mat-radio-button [value]="true" class="report-input-radio-button">Yes</mat-radio-button>
          <mat-radio-button [value]="false" class="report-input-radio-button">No</mat-radio-button>
        </mat-radio-group>
      </div>

      <div class="report-input-form-field">
        <p class="report-input-label report-input-radio-label">Evidence of Dampness</p>
        <mat-radio-group formControlName="interiorEvidenceOfDampness" class="mt-15 mb-15">
          <mat-radio-button [value]="true" class="report-input-radio-button">Yes</mat-radio-button>
          <mat-radio-button [value]="false" class="report-input-radio-button">No</mat-radio-button>
        </mat-radio-group>
      </div>

      <div class="report-input-form-field">
        <p class="report-input-label report-input-radio-label">Evidence of Settlement</p>
        <mat-radio-group formControlName="interiorEvidenceOfSettlement" class="mt-15 mb-15">
          <mat-radio-button [value]="true" class="report-input-radio-button">Yes</mat-radio-button>
          <mat-radio-button [value]="false" class="report-input-radio-button">No</mat-radio-button>
        </mat-radio-group>
      </div>
    </div>
  </div>
</ng-container>
