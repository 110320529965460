<div
  *ngIf="formGroup"
  [formGroup]="formGroup"
  [ngClass]="{ 'multiple-combo-box-focus': isFocus,'multiple-combo-box-touched': isTouched,'multiple-combo-box-has-value': value && value.length > 0 }"
>
  <mat-form-field class="multiple-combo-box full-width" appearance="outline">
    <mat-label class="combo-box-label">{{ label }}</mat-label>

    <mat-chip-list #chipList aria-label="chiplist selection" class="chiplist-section">
      <mat-chip *ngFor="let select of selectData" (removed)="toggleChip(select)">
        {{ select.name }}
        <button matChipRemove>
          <mat-icon>cancel</mat-icon>
        </button>
      </mat-chip>
      <input
        [placeholder]="placeholder"
        #autoCompleteInput
        ngDefaultControl
        [formControl]="filterControl"
        [matAutocomplete]="auto"
        [matChipInputFor]="chipList"
        [matChipInputSeparatorKeyCodes]="separatorKeyCodes"
        (matChipInputTokenEnd)="addItem($event)"
      />
      <mat-icon
        matSuffix
        class="mat-suffix-icon"
        *ngIf="!isOpenDropdown"
        (click)="openAutoComplete($event)"
        >arrow_drop_down</mat-icon
      >
      <mat-icon
        matSuffix
        class="mat-suffix-icon"
        *ngIf="isOpenDropdown"
        (click)="closeAutoComplete($event)"
        >arrow_drop_up</mat-icon
      >
    </mat-chip-list>
    <mat-autocomplete
      #auto="matAutocomplete"
      [displayWith]="displayFn"
      (closed)="closedAutoComplete()"
      (opened)="openedAutoComplete()"
    >
      <mat-option
        *ngFor="let data of filteredData | async"
        [ngClass]="{ 'mat-option-selected': data.selected }"
      >
        <div (click)="optionClicked($event, data)">
          <mat-checkbox
            [checked]="data.selected"
            (change)="toggleChip(data)"
            (click)="$event.stopPropagation()"
          >
            {{ data.name }}
          </mat-checkbox>
        </div>
      </mat-option>
    </mat-autocomplete>
    <input
      [formControlName]="formControlName"
      [matChipInputFor]="chipList"
      [matChipInputSeparatorKeyCodes]="separatorKeyCodes"
      [matChipInputAddOnBlur]="openedAutoComplete"
      class="input-multiple-combo-box"
    />
  </mat-form-field>
</div>
