import { FileVersionDto } from "@proxy/publication/document/documents/v1";

export class DocumentModel {
    id: string;
    author: string;
    created: Date;
    extension: string;
    name: string;
    tags: string[];
    url: string;
    title: string;
    labels: string[];
    files?: FileVersionDto[];
  }