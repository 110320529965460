import { AfterViewInit, Component, Input } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { MatRadioChange } from '@angular/material/radio';
import { ReportInputTemplateField } from 'src/app/features/shared/forms/models/report-input-layout.model';

@Component({
  selector: 'jaro-kit-amenity-card-edit-mode',
  templateUrl: './amenity-card-edit-mode.component.html',
  styleUrls: ['./amenity-card-edit-mode.component.scss'],
})
export class AmenityCardEditModeComponent implements AfterViewInit {
  @Input() form: FormGroup;
  @Input() title: string;
  @Input() description: string;
  @Input() icon: string;
  @Input() isNumber: boolean = false;
  @Input() controlName: string;
  @Input() descriptionControlName: string;
  @Input() field: ReportInputTemplateField;

  isFocus: boolean;

  get isInvalidData() {
    return (
      this.form.controls[this.controlName].invalid ||
      this.form.controls[this.descriptionControlName].invalid
    );
  }

  get isValidData() {
    return (
      !this.isInvalidData &&
      (this.form.value[this.controlName] === false ||
        (this.form.value[this.controlName] && this.form.value[this.descriptionControlName]))
    );
  }

  ngAfterViewInit(): void {
    setTimeout(() => {
      const descriptionControl = this.form.controls[this.descriptionControlName];
      const isEnabled = this.form.value[this.controlName];
      if (descriptionControl) {
        if (isEnabled) {
          descriptionControl.enable();
        } else {
          descriptionControl.disable();
        }
      }
    }, 0);
  }

  onChangeRadio($event: MatRadioChange) {
    const descriptionControl = this.form.controls[this.descriptionControlName];
    if (descriptionControl) {
      if (!$event.value) {
        descriptionControl.setValue(null);
        descriptionControl.disable();
      } else {
        descriptionControl.enable();
      }
    }
    descriptionControl.markAsDirty();
    this.form.updateValueAndValidity();
  }
}
