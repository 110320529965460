<div class="report-resource-collection">
  <div class="report-resource-collection-container-top">
    <div class="report-resource-collection-top">
      <span class="report-resource-collection-title">Documents</span>
      <div (click)="closeDialog()" class="report-resource-collection-close mouse-cursor-pointer">
        <em class="fal fa-times"></em>Close
      </div>
    </div>

    <mat-form-field appearance="outline" class="report-resource-dropdown">
      <mat-label class="jaro-kit-edit-input-label">Source</mat-label>
      <mat-select
        [(ngModel)]="source"
        (selectionChange)="updateSource($event.value)"
        disableOptionCentering panelClass="panel-filter">
        <mat-option *ngFor="let option of sourceFilterOptions" [value]="option.value">{{
          option.name
        }}</mat-option>
      </mat-select>
    </mat-form-field>

  </div>

  <div class="report-input-document-library-container">
    <div
      class="report-input-documents"
      *ngIf="items?.length > 0"
      infinite-scroll
      [infiniteScrollDistance]="scrollDistance"
      [infiniteScrollUpDistance]="scrollUpDistance"
      [infiniteScrollThrottle]="throttle"
      [scrollWindow]="false"
      [infiniteScrollContainer]="'.report-input-document-library-container'"
      [fromRoot]="true"
      (scrolled)="onScrollDown()"
    >
      <div
        cdkDropList
        #documentList="cdkDropList"
        [cdkDropListData]="sourceListItems"
        class="document-list"
        [cdkDropListAutoScrollDisabled]="true"
      >
        <div
          *ngFor="let item of sourceListItems"
          cdkDrag
          cdkDragPreviewContainer
          [cdkDragData]="item"
          [cdkDragBoundary]=""
          cdkDragPreviewClass="dragPreviewDocument"
          class="document-drag-drop"
        >
          <div class="document-container">
            <halo-global-document-icon
              class="document-icon"
              [fileExtension]="'.' + item.fileExtension"
            ></halo-global-document-icon>
            <div class="document-info">
              <span class="document-title">{{ item.title }}</span>
              <span class="document-category">{{ item.documentCategory }}</span>
              <span class="document-creation-time"
                >Added {{ item.creationTime | mainDateTimeFormat }}</span
              >
            </div>

            <em class="fa fa-check-circle" aria-hidden="true" *ngIf="item.isInReport"></em>
          </div>

          <button mat-icon-button [matMenuTriggerFor]="moreAction" class="button-action">
            <em class="fa-regular fa-ellipsis-vertical jaro-kit-primary-icon" aria-hidden="true"></em>
          </button>

          <mat-menu #moreAction="matMenu" xPosition="before">
            <button mat-menu-item (click)="previewDocument(item)">Preview</button>
            <button mat-menu-item (click)="downloadDocument(item)">Download</button>
            <hr>
            <button mat-menu-item (click)="deleteDocument(item)">Delete</button>
          </mat-menu>

        </div>

      </div>
    </div>

    <div *ngIf="!sourceListItems || sourceListItems.length === 0" class="empty-container">
      <span class="empty-documents">No document from the source {{ source }}</span>
    </div>
  </div>

</div>
