<div ngbDropdown class="d-inline-block export-workfile-button-container">
  <div>
    <button
      class="jaro-kit-primary-button mt-4 mb-4 export-workfile-button"
      (click)="openWorkfileExport()"
      [disabled]="isModalOpen"
    >
      Export Workfile
    </button>
  </div>
</div>

