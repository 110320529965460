import { RestService } from '@abp/ng.core';
import { Injectable } from '@angular/core';
import type { CreateDocumentDto, DocumentDto, DocumentWithFileCollectionDto, DocumentWithFileDto, FileVersionDto, ResponseDto, UpdateDocumentDto } from './models';

@Injectable({
  providedIn: 'root',
})
export class DocumentsService {
  apiName = 'Publication';

  create = (input: CreateDocumentDto) =>
    this.restService.request<any, DocumentDto>({
      method: 'POST',
      url: `/api/v1/documents`,
      body: input,
    },
    { apiName: this.apiName });

  createNewFileVersion = (documentId: string, orderAssignmentId: string) =>
    this.restService.request<any, FileVersionDto>({
      method: 'POST',
      url: `/api/v1/documents/${documentId}/order-assignment/${orderAssignmentId}/files`
    },
    { apiName: this.apiName });

  deleteDocument = (documentId: string, orderAssignmentId: string) =>
    this.restService.request<any, void>({
      method: 'DELETE',
      url: `/api/v1/documents/${documentId}/order-assignment/${orderAssignmentId}`
    },
    { apiName: this.apiName });

  getFile = (documentId: string, orderAssignmentId: string, fileVersion?: number) =>
    this.restService.request<any, ResponseDto>({
      method: 'GET',
      url: `/api/v1/documents/${documentId}/order-assignment/${orderAssignmentId}/file`,
      params: { fileVersion: fileVersion },
    },
    { apiName: this.apiName });

  getDocument = (documentId: string, orderAssignmentId: string) =>
    this.restService.request<any, DocumentDto>({
      method: 'GET',
      url: `/api/v1/documents/${documentId}/order-assignment/${orderAssignmentId}`
    },
    { apiName: this.apiName });

  getDocumentWithFile = (documentId: string, orderAssignmentId: string, fileVersion?: number) =>
    this.restService.request<any, DocumentWithFileDto>({
      method: 'GET',
      url: `/api/v1/documents/${documentId}/order-assignment/${orderAssignmentId}/detailed`,
      params: { fileVersion: fileVersion },
    },
    { apiName: this.apiName });

  getAll = (orderAssignmentId: string) =>
    this.restService.request<any, DocumentDto[]>({
      method: 'GET',
      url: `/api/v1/documents/order-assignment/${orderAssignmentId}`
    },
    { apiName: this.apiName });

  getAllWithFileVersions = (orderAssignmentId: string) =>
    this.restService.request<any, DocumentWithFileCollectionDto[]>({
      method: 'GET',
      url: `/api/v1/documents/order-assignment/${orderAssignmentId}/detailed-list`
    },
    { apiName: this.apiName });

  updateDocument = (documentId: string, orderAssignmentId: string, input: UpdateDocumentDto) =>
    this.restService.request<any, DocumentDto>({
      method: 'PUT',
      url: `/api/v1/documents/order/${orderAssignmentId}/document/${documentId}`,
      body: input,
    },
    { apiName: this.apiName });

  constructor(private restService: RestService) {}
}
