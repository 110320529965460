<div class="report-input-documents">
  <div
    class="document-list"
    cdkDropList
    #sectionPhoto="cdkDropList"
    (cdkDropListDropped)="onDrop($event)"
  >
    <div class="document-block" *ngFor="let document of documents">
      <jaro-kit-document-pdf
        *ngIf="(document.fileExtension || '').toLowerCase() === 'pdf'"
        [orderAssignmentId]="orderAssignmentId"
        [document]="document"
        (onRemoveDocument)="removeDocument($event)"
        (onReselectedPages)="reselectedPages($event)"
      ></jaro-kit-document-pdf>
      <jaro-kit-document-image-file
        *ngIf="(document.fileExtension || '').toLowerCase() !== 'pdf'"
        [orderAssignmentId]="orderAssignmentId"
        [document]="document"
        (onRemoveDocument)="removeDocument($event)"
      ></jaro-kit-document-image-file>
    </div>

    <div class="document-block document-loading" *ngFor="let documentUpload of documentsUploading">
      <jaro-kit-document-pdf
        *ngIf="(documentUpload.fileExtension || '').toLowerCase() === 'pdf'"
        [orderAssignmentId]="orderAssignmentId"
        [isUploading]="true"
        [document]="documentUpload"
      ></jaro-kit-document-pdf>
      <jaro-kit-document-image-file
        *ngIf="(documentUpload.fileExtension || '').toLowerCase() !== 'pdf'"
        [orderAssignmentId]="orderAssignmentId"
        [isUploading]="true"
        [document]="documentUpload"
      ></jaro-kit-document-image-file>
      <halo-global-progress-bar
        class="document-progress-bar"
        *ngIf="documentUpload.progress !== 100"
        [value]="documentUpload.progress"
      ></halo-global-progress-bar>
    </div>
  </div>
</div>
