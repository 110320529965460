<div class="row validation-error-message">
  <p *ngIf="hasError('required') && isRequired && !hasError('matDatepickerParse')">Field is required</p>

  <p *ngIf="hasError('matDatepickerParse')">Please enter a valid date</p>
  <p *ngIf="hasError('greaterThanOrEqualToZero')">Must be greater than or equal to zero</p>
  <p *ngIf="hasError('greaterThanZeroOrBlank')">Must be blank or greater than zero</p>
  <p *ngIf="hasError('equalToZero')">Must be zero</p>
  <p *ngIf="hasError('pattern')">Enter in {{format}} format only</p>
  <p *ngIf="hasError('maxLengthOfNumber')">Acceptable format is up to {{maxLength}} digits (whole number only)</p>  
</div>
<div class="row validation-warning-message">  
  <p *ngIf="hasWarning('totalPercentageShouldEqualNumber')">Does not equal {{total}}%</p>
</div>
