import { VirtualKeypadActionItem } from './virtual-keypad.model';

export const VirtualKeypadActionPrototypes: VirtualKeypadActionItem[] = [
  {
    actionName: 'copy',
    displayName: '',
    cssClass: 'group-action',
    iconClass: 'fas fa-copy',
    keyCode: 100001, // ctrl + c for "copy selected area"
  },
  {
    actionName: 'paste',
    displayName: '',
    cssClass: 'group-action',
    iconClass: 'fas fa-paste',
    keyCode: 100002, // ctrl + v for "paste the copied area"
  },
  {
    actionName: 'center',
    displayName: '',
    cssClass: 'group-action',
    iconClass: 'fas fa-compress-wide',
    keyCode: 67, // c for "center"
  },
  {
    actionName: 'arrow-up-left',
    displayName: '',
    cssClass: 'rotate315',
    iconClass: 'fas fa-arrow-up',
    keyCode: 100006, // move up-left
  },
  {
    actionName: 'arrow-up',
    displayName: '',
    cssClass: '',
    iconClass: 'fas fa-arrow-up',
    keyCode: 38, // move up
  },
  {
    actionName: 'arrow-up-right',
    displayName: '',
    cssClass: 'rotate45',
    iconClass: 'fas fa-arrow-up',
    keyCode: 100007, // move up-right
  },
  {
    actionName: 'arrow-left',
    displayName: '',
    cssClass: '',
    iconClass: 'fas fa-arrow-left',
    keyCode: 37, // move right
  },
  {
    actionName: 'arrow-house',
    displayName: '',
    cssClass: '',
    iconClass: 'fas fa-house',
    keyCode: 85, // h for home
  },
  {
    actionName: 'arrow-right',
    displayName: '',
    cssClass: '',
    iconClass: 'fas fa-arrow-right',
    keyCode: 39, // move right
  },
  {
    actionName: 'arrow-down-left',
    displayName: '',
    cssClass: 'rotate315 group-action',
    iconClass: 'fas fa-arrow-left',
    keyCode: 100008, // move down-left
  },
  {
    actionName: 'arrow-down',
    displayName: '',
    cssClass: 'group-action',
    iconClass: 'fas fa-arrow-down',
    keyCode: 40, // move down
  },
  {
    actionName: 'arrow-down-right',
    displayName: '',
    cssClass: 'rotate45 group-action',
    iconClass: 'fas fa-arrow-right',
    keyCode: 100009, // move down-right
  },
  {
    actionName: 'undo',
    displayName: '',
    cssClass: 'group-action',
    iconClass: 'fas fa-undo',
    keyCode: 100003, // ctrl-z for undo
  },
  {
    actionName: 'redo',
    displayName: '',
    cssClass: 'group-action',
    iconClass: 'fas fa-redo',
    keyCode: 100004, // ctrl-y for redo
  },
  {
    actionName: 'delete',
    displayName: '',
    cssClass: 'group-action',
    iconClass: 'fas fa-trash',
    keyCode: 8, // delete
  },
  {
    actionName: 'compass',
    displayName: '',
    cssClass: 'group-action',
    iconClass: 'fas fa-drafting-compass',
    keyCode: 65, // a for arcKey
  },
  {
    actionName: 'angle-left',
    displayName: '',
    cssClass: 'group-action',
    iconClass: 'fas fa-range-left',
    keyCode: 76, // l for angle left
  },
  {
    actionName: 'angle-right',
    displayName: '',
    cssClass: 'group-action',
    iconClass: 'fas fa-range-right',
    keyCode: 82, // r for angle right
  },
  {
    actionName: 'number-7',
    displayName: '7',
    cssClass: '',
    iconClass: '',
    keyCode: 55,
  },
  {
    actionName: 'number-8',
    displayName: '8',
    cssClass: '',
    iconClass: '',
    keyCode: 56,
  },
  {
    actionName: 'number-9',
    displayName: '9',
    cssClass: '',
    iconClass: '',
    keyCode: 57,
  },
  {
    actionName: 'number-4',
    displayName: '4',
    cssClass: '',
    iconClass: '',
    keyCode: 52,
  },
  {
    actionName: 'number-5',
    displayName: '5',
    cssClass: '',
    iconClass: '',
    keyCode: 53,
  },
  {
    actionName: 'number-6',
    displayName: '6',
    cssClass: '',
    iconClass: '',
    keyCode: 54,
  },
  {
    actionName: 'number-1',
    displayName: '1',
    cssClass: '',
    iconClass: '',
    keyCode: 49,
  },
  {
    actionName: 'number-2',
    displayName: '2',
    cssClass: '',
    iconClass: '',
    keyCode: 50,
  },
  {
    actionName: 'number-3',
    displayName: '3',
    cssClass: '',
    iconClass: '',
    keyCode: 51,
  },
  {
    actionName: 'number-0',
    displayName: '0',
    cssClass: 'number-zero',
    iconClass: '',
    keyCode: 48,
  },
  {
    actionName: 'decimalPlace',
    displayName: '.',
    cssClass: '',
    iconClass: '',
    keyCode: 110,
  },
  {
    actionName: 'feet',
    displayName: "'",
    cssClass: '',
    iconClass: '',
    keyCode: 222, // ' for feet
  },
  {
    actionName: 'inches',
    displayName: '"',
    cssClass: '',
    iconClass: '',
    keyCode: 100005, // shift + '  -> " for inches
  },
  {
    actionName: 'plus',
    displayName: '+',
    cssClass: '',
    iconClass: '',
    keyCode: 107, // +
  },
  {
    actionName: 'minus',
    displayName: '-',
    cssClass: '',
    iconClass: '',
    keyCode: 109, // -
  },
  {
    actionName: 'select',
    displayName: '',
    cssClass: 'draw-action',
    iconClass: 'far fa-record-vinyl',
    keyCode: 13, // enter
  },
  {
    actionName: 'multiply',
    displayName: '*',
    cssClass: '',
    iconClass: '',
    keyCode: 106, // multiply
  },
  {
    actionName: 'divide',
    displayName: '/',
    cssClass: '',
    iconClass: '',
    keyCode: 111, // divide
  },
];
