import { Component, Inject, Input } from '@angular/core';
import InjectionSymbol from 'src/app/shared/injection/injection-symbol';
import { MessageService } from 'src/app/shared/services/message.service';
import { PropertyRecordDto } from '@proxy/property/property-record/property-records/v1';

@Component({
  selector: 'jaro-kit-market-cleanup-property-sale-history',
  templateUrl: './market-cleanup-property-sale-history.component.html',
  styleUrls: ['./market-cleanup-property-sale-history.component.scss'],
})
export class MarketCleanupPropertySaleHistoryComponent  {
  @Input() property: PropertyRecordDto;

  constructor(
    @Inject(InjectionSymbol.ActivityReportsService)
    private msgService: MessageService
  ) {}

  
}
