<div class="sub-section-edit-mode-detail" *ngIf="subSection.componentName">
  <jaro-kit-dynamic-component
    [componentName]="subSection.componentName"
    [reportDto]="reportDto"
    [reportInputForm]="reportInputSectionForm"
    [section]="section"
    [subSection]="subSection"
    [orderAssignmentId]="orderAssignmentId"
    [feature]="feature"
    [reportVersionDto]="reportVersionDto"
    [isEditMode]="true"
  ></jaro-kit-dynamic-component>
</div>
