import { Component, Inject, Input, OnInit } from '@angular/core';
import { FormGroup, ValidationErrors, Validators } from '@angular/forms';
import { MatRadioChange } from '@angular/material/radio';
import { ContentServiceInterface } from 'src/app/interface/content/content-service-interface';
import InjectionSymbol from 'src/app/shared/injection/injection-symbol';

@Component({
  selector: 'jaro-kit-contract-form',
  templateUrl: './contract-form.component.html',
  styleUrls: ['./contract-form.component.scss'],
})
export class ContractFormComponent implements OnInit {
  @Input() form: FormGroup;

  saleTypes = [];
  isShowConcession: boolean;

  constructor(
    @Inject(InjectionSymbol.ContentService)
    public contentService: ContentServiceInterface
  ) {
    this.saleTypes = this.contentService.getContentByKey('saleType');
  }

  ngOnInit(): void {
    this.isShowConcession = this.form?.value?.contractAnyFinancialAssistance === true;
  }

  onChangeAnyFinancialAssistance($event: MatRadioChange) {
    this.form.controls['contractAnyFinancialAssistance'].setValue($event.value);
    this.form.controls['contractAnyFinancialAssistance'].updateValueAndValidity();
    if (!$event.value) {
      const fieldNames = ['contractConcessionAmount', 'contractConcessionAmountUnknown', 'contractConcessionDescription'];
      fieldNames.forEach(fieldName => this.clearDataAndValidity(fieldName));
    } else {
      this.form.controls['contractConcessionDescription'].setValidators(Validators.required);
      this.form.controls['contractConcessionAmountUnknown'].setValidators(Validators.required);
    }
    this.isShowConcession = !!$event.value;
    this.toggleErrorsForConcessionAmount();
    this.form.updateValueAndValidity();
  }

  onChangeContractConcessionAmount($event) {    
    this.form.controls['contractConcessionAmount'].setValue($event.target.value);
    this.form.controls['contractConcessionAmount'].updateValueAndValidity();
    this.toggleErrorsForConcessionAmount();
    this.form.updateValueAndValidity();
  }

  onChangeContractConcessionAmountUnknown($event: MatRadioChange) {
    this.form.controls['contractConcessionAmountUnknown'].setValue($event.value);
    this.form.controls['contractConcessionAmountUnknown'].updateValueAndValidity();
    this.toggleErrorsForConcessionAmount();    
    this.form.updateValueAndValidity();
  }

  private clearDataAndValidity(formControlName: string){
    this.form.controls[formControlName].setValue(null);
    this.form.controls[formControlName].setErrors(null);
    this.form.controls[formControlName].setValidators(null);
    this.form.controls[formControlName].markAsDirty();
  }  

  private toggleErrorsForConcessionAmount() {
    const contractAnyFinancialAssistance = this.form?.value?.contractAnyFinancialAssistance;
    const contractConcessionAmountUnknown = this.form?.value?.contractConcessionAmountUnknown;
    const contractConcessionAmount = this.form?.value?.contractConcessionAmount;

    let contractConcessionAmountErrors : ValidationErrors = null;
    // Financial assistance amount - valid is zero or higher when Financial Assistance=YES and Unknown amount indicator=NO
    if(contractAnyFinancialAssistance && !contractConcessionAmountUnknown &&
      (contractConcessionAmount === null || contractConcessionAmount === "" || parseFloat(contractConcessionAmount) < 0)) {
        contractConcessionAmountErrors = {"required": true};
        this.form.controls['contractConcessionAmount'].setValidators(Validators.required);
    }
    
    // Financial assistance amount - valid is null or higher than zero when Financial Assistance=YES and Unknown amount
    if((contractConcessionAmountErrors === null && contractAnyFinancialAssistance && contractConcessionAmountUnknown) && 
      (contractConcessionAmount !== null && contractConcessionAmount !== "" && parseFloat(contractConcessionAmount) <= 0)) {
        contractConcessionAmountErrors = {"greaterThanZeroOrBlank": true};
      this.form.controls['contractConcessionAmount'].setValidators(null);
    }   

    setTimeout(() => 
      this.form.controls["contractConcessionAmount"].setErrors(contractConcessionAmountErrors), 100);
  }
}
