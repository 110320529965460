<halo-global-popup [dialogRef]="dialogRef" [isHideClose]="!data.isVisibleClose">
  <div class="confirmation-container">
    <div class="confirmation-detail">
      <p class="confirmation-title" [innerHTML]="data.title"></p>
      <p class="confirmation-message" [innerHTML]="data.message"></p>
    </div>

    <div class="confirmation-footer">
      <div class="confirmation-footer-left"></div>
      <button
        type="submit"
        class="button-cancel"
        (click)="reject()"
        *ngIf="data.isVisibleCancelBtn"
      >
        {{ data.cancelText || 'Cancel' }}
      </button>

      <button
        type="submit"
        class="button-save jaro-kit-primary-button"
        (click)="confirm()"
        *ngIf="data.isVisibleYesBtn"
      >
        {{ data.yesText || 'Yes' }}
      </button>
    </div>
  </div>
</halo-global-popup>
