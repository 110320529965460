import { Confirmation } from '@abp/ng.theme.shared';
import { HttpErrorResponse } from '@angular/common/http';
import { Component, EventEmitter, Inject, OnInit, Output } from '@angular/core';
import { SignReportDto } from '@proxy/appraisal/report/reports/v1';
import { OrderAssignmentDto } from '@proxy/ascent/order/order-assignment/order-assignments';
import { ReportVersionWithLinksDto } from '@proxy/bff/activity/reports/v1';
import { JaroDeskUserDto } from '@proxy/integration/ascent/order/v1';
import { ReportServiceInterface } from 'src/app/interface/appraisal/report/report-service-interface';
import { AscentReportInterface } from 'src/app/interface/bff/activity/ascent.report.interface';
import { FeatureNames } from 'src/app/shared/enums/report-input.enum';
import InjectionSymbol from 'src/app/shared/injection/injection-symbol';
import { EventService } from 'src/app/shared/services/event.service';
import { MessageService } from 'src/app/shared/services/message.service';
@Component({
  selector: 'jaro-kit-tools',
  templateUrl: './tools.component.html',
  styleUrls: ['./tools.component.scss'],
})
export class ToolsComponent implements OnInit {
  isLoading: boolean;
  orderAssignmentId: string;
  appraiserId: string;
  currentOrderAssignment: OrderAssignmentDto;
  reportVersionDto: ReportVersionWithLinksDto;
  isInspectorAppraiser: boolean;
  isPrimaryAppraiser: boolean;
  isReviewerAppraiser: boolean;
  feature = FeatureNames.Tools;
  @Output() onToggleQualityCheck = new EventEmitter<boolean>();

  constructor(
    private eventService: EventService,
    private msgService: MessageService,
    @Inject(InjectionSymbol.AscentReportService)
    private ascentReportService: AscentReportInterface,
    @Inject(InjectionSymbol.ReportService) private reportService: ReportServiceInterface

  ) {}

  ngOnInit(): void {
    this.getUserInfo();
  }

  signReport() {
    const option = {
      yesText: 'Ok',
      cancelText: 'Cancel',
      dismissible: true,
    };
    const msg =
      this.isInspectorAppraiser || this.isPrimaryAppraiser
        ? 'Report will be approved and signed on your behalf'
        : 'Report will be approved';
    this.msgService.confirm('Sign report', msg, option).subscribe({
      next: (status: Confirmation.Status) => {
        if (status === Confirmation.Status.confirm) {
          const signDto = {
            orderAssignmentId: this.orderAssignmentId,
            reportId: this.reportVersionDto.reportId,
            version: this.reportVersionDto.version,
            concurrencyStamp: null,
          } as SignReportDto;
          this.reportService.signReport(signDto).subscribe({
            next: (_response) => {
              const successMsg =
                this.isInspectorAppraiser || this.isPrimaryAppraiser
                  ? 'Report signed and approved'
                  : 'Report approved';
              this.msgService.successAlert('Sign report', successMsg);
            },
            error: (err: HttpErrorResponse) => {
              const optionMsg = {
                hideYesBtn: true,
                cancelText: 'Close',
                dismissible: true,
              };
              this.msgService.error(err, 'Sign report', err.error.error.message, optionMsg, true);
            },
          });
        }
      },
    });
  }

  refreshAppraiserDetails() {
    this.reportService.updateAppraiserInfo(this.orderAssignmentId).subscribe({
      next: (response) => {
        setTimeout(() => {
          this.eventService.onUpdateAppraiserInfo.next(response);
        }, 500);
        this.msgService.successAlert("Refresh Appraiser Details", "Refreshed");
      },
      error: (err: HttpErrorResponse) => {
        const optionMsg = {
          hideYesBtn: true,
          cancelText: 'Close',
          dismissible: true,
        };
        this.msgService.error(err, "Refresh Appraiser Details", err.error.error.message, optionMsg, true);
      },
    });
  }

  private getUserInfo() {
    this.ascentReportService.getUserInfo().subscribe({
      next: (userInfo: JaroDeskUserDto) => {
        this.isInspectorAppraiser =
          userInfo.appraiserGuid === this.reportVersionDto.inspectorAppraiser.appraiserId;
        this.isPrimaryAppraiser =
          userInfo.appraiserGuid === this.reportVersionDto.primaryAppraiser.appraiserId;
        this.isReviewerAppraiser =
          userInfo.appraiserGuid === this.reportVersionDto.reviewerAppraiser.appraiserId;
      },
      error: (err) => this.logActionError(err),
    });
  }

  private logActionError(err: any): void {
    this.msgService.error(err).subscribe((_status: Confirmation.Status) => {});
  }
}
