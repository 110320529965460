import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FacetScoreModel } from '../../models/facet/facet-score-model';
import { FacetFilterModel } from '../../models/facet/facet-filter-model';
import { BehaviorSubject } from 'rxjs/internal/BehaviorSubject';

@Component({
  selector: 'jaro-kit-checkbox-list-facet-filter',
  templateUrl: './checkbox-list-facet-filter.component.html',
  styleUrls: ['./checkbox-list-facet-filter.component.scss'],
})
export class CheckboxListFacetFilterComponent implements OnInit {
  readonly numberOfFirstScores: number = 10;
  @Input() facetFilterSubject: BehaviorSubject<FacetFilterModel[]>;

  @Input() defaultFilterModel: FacetFilterModel;
  @Input() filteredFilterModel: FacetFilterModel;
  @Input() selectedFilterModel: FacetFilterModel;
  @Output() onScoreToggled = new EventEmitter<FacetFilterModel>();
  
  
  public isOpenDropdown: boolean = false;  
  private defaultFirstScoresArray: FacetScoreModel[];
  private defaultMoreScoresArray: FacetScoreModel[];
  private filteredScoreRecord: Record<string, Partial<FacetScoreModel>> = {};
  
  isShowMoreScores: boolean = false;
 
  get firstScores(): FacetScoreModel[] 
  {  
    return this.defaultFirstScoresArray;
  }  
  
  get moreScores(): FacetScoreModel[] 
  {  
    return this.defaultMoreScoresArray;
  } 

  get isShowMoreScoresLink(): boolean
  {
    return (!!this.defaultMoreScoresArray) && this.defaultMoreScoresArray.length > 0 && !this.isShowMoreScores;
  }

  get isShowLessScoresLink(): boolean
  {
    return (!!this.defaultMoreScoresArray) && this.defaultMoreScoresArray.length > 0 && this.isShowMoreScores;
  }

  isScoreUpdated(selectedScore: FacetScoreModel): boolean
  {
    const score = this.filteredScoreRecord[selectedScore.title];
    return (!score || score.frequency != selectedScore.frequency);
  }

  updatedFrequency(selectedScore: FacetScoreModel): number
  {
    const score = this.filteredScoreRecord[selectedScore.title];
    return (score?.frequency || 0);
  }

  ngOnInit(): void {    
    this.facetFilterSubject.subscribe((_response: FacetFilterModel[]) => {
      this.updateRanges();
    });
  }

  private updateRanges(): void {
    if((this.defaultFirstScoresArray == null) && (this.defaultFilterModel?.scores?.length !== 0)) {
        this.initDefaultScores();        
    }

    if(this.filteredFilterModel.scores?.length !== 0){
      this.initScores();
    }
  }
    
  toggleOpenDropdown(_event: any) { 
    this.isOpenDropdown = !this.isOpenDropdown;     
  } 

  toggleScoreSelection(score: FacetScoreModel) {  
    score.isSelected = !score.isSelected;
    const selectedScores = this.selectedFilterModel.scores.filter((s) => s.title === score.title);
    for(let selectedScore of selectedScores)
    {
      selectedScore.isSelected = score.isSelected;
    }
    this.onScoreToggled.emit(this.selectedFilterModel);
  }

  showMoreScores() {
    this.isShowMoreScores = true;
  }

  showLessScores() {
    this.isShowMoreScores = false;
  }  

  private initDefaultScores()
  {
    this.defaultFirstScoresArray = [];
    this.defaultMoreScoresArray = [];
    let scoreCount = 0;
    for(let score of this.defaultFilterModel.scores || [])
    {            
      if(scoreCount < this.numberOfFirstScores)
      {
        this.defaultFirstScoresArray.push(score);
      }
      else
      {
        this.defaultMoreScoresArray.push(score);
      }
      scoreCount++;
    }
  }

  private initScores()
  {
    const record = {};
    for(let score of this.filteredFilterModel.scores || [])
    {
      record[score.title] = score;
    }
    this.filteredScoreRecord = record;
  }
}
   