<mat-tree
  [dataSource]="nestedDataSource"
  [treeControl]="nestedTreeControl"
  class="tree-node-container"
>
  <mat-tree-node *matTreeNodeDef="let node" matTreeNodeToggle>
    <ul>
      <li class="mat-tree-node-item">
        <button mat-icon-button disabled></button>
        <span [ngClass]="{
            'node-item-text': true,
            'mouse-cursor-pointer': node.value
          }"
          (click)="clickNode(node)"
        >
          <em [ngClass]="{'fa-circle-exclamation': !node.isWarning, 'fa-warning' : node.isWarning }" class="fa-regular" *ngIf="node.value"></em>{{ node.title }}
        </span>
      </li>
    </ul>
  </mat-tree-node>

  <mat-nested-tree-node *matTreeNodeDef="let node; when: hasNestedChild">
    <ul>
      <li>
        <div class="mat-tree-node-group-item">
          <button
            mat-icon-button
            matTreeNodeToggle
            [attr.aria-label]="'toggle ' + node.title"
            (click)="changeState(node)"
          >
            <em
              class="fa-regular"
              [ngClass]="{
                'fa-plus': node.expanded,
                'fa-minus': !node.expanded
              }"
            ></em>
          </button>
          <span class="node-item-title">{{ node.title }}</span>
        </div>
        <ul [class.tree-node-invisible]="node.expanded">
          <ng-container matTreeNodeOutlet></ng-container>
        </ul>
      </li>
    </ul>
  </mat-nested-tree-node>
</mat-tree>
