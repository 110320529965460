import { Component, Inject, Input } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { ContentServiceInterface } from 'src/app/interface/content/content-service-interface';
import { ItemData } from 'src/app/shared/components/multiple-combo-box/multiple-combo-box.component';
import InjectionSymbol from 'src/app/shared/injection/injection-symbol';

@Component({
  selector: 'halo-global-neighborhood-characteristics',
  templateUrl: './neighborhood-characteristics.component.html',
  styleUrls: ['./neighborhood-characteristics.component.scss'],
})
export class NeighborhoodCharacteristicsComponent {
  @Input() form: FormGroup;
  
  locationOptions: ItemData[] = [];
  builtUpOptions: ItemData[] = [];
  growthOptions: ItemData[] = [];
  constructor(
    @Inject(InjectionSymbol.ContentService)
    public contentService: ContentServiceInterface
  ) {
    this.locationOptions = this.contentService.getContentByKey('location.type');

    this.builtUpOptions = this.contentService.getContentByKey(
      'builtUp'
    );

    this.growthOptions = this.contentService.getContentByKey('growth');
  }
}
