import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'jaro-kit-document-preview-image',
  templateUrl: './document-preview-image.component.html',
  styleUrls: ['./document-preview-image.component.scss'],
})
export class DocumentPreviewImageComponent {
  constructor(
    public dialogRef: MatDialogRef<DocumentPreviewImageComponent>,
    @Inject(MAT_DIALOG_DATA)
    public imageData: {
      url: string;
    }
  ) {}

  closeDialog() {
    this.dialogRef.close();
  }
}
