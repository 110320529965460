<ng-container *ngIf="form" [formGroup]="form">
  <div class="row">
    <div class="col-md-6 pl-0 pr-15">
      <jaro-kit-date-picker
        [form]="form"
        dateControl="subjectInspectionDate"
        titleDate="Inspection Date"
      ></jaro-kit-date-picker>

      <div class="report-input-form-field mb-15">
        <p class="report-input-radio-label">
          Has the value of the subject property declined since the effective date of the original
          appraisal?
        </p>

        <mat-radio-group formControlName="appraisalUpdate_propertyMarketValueDecreased">
          <mat-radio-button [value]="true" class="report-input-radio-button">Yes</mat-radio-button>
          <mat-radio-button [value]="false" class="report-input-radio-button">No</mat-radio-button>
        </mat-radio-group>
      </div>

      <mat-form-field appearance="outline" class="jaro-kit-textarea">
        <mat-label>Additional Comments</mat-label>
        <textarea
          type="text"
          formControlName="appraisalUpdate_comment"
          matInput
          rows="4"
        ></textarea>
      </mat-form-field>
    </div>

    <div class="col-md-6 pl-15 pr-0">
      <div class="report-input-form-field mb-15">
        <p class="report-input-radio-label">
          Have the improvements been completed in accordance with the requirements and conditions
          stated in the original appraisal report?
        </p>

        <mat-radio-group formControlName="certificationCompletion_propertyImprovementsCompleted">
          <mat-radio-button [value]="true" class="report-input-radio-button">Yes</mat-radio-button>
          <mat-radio-button [value]="false" class="report-input-radio-button">No</mat-radio-button>
        </mat-radio-group>
      </div>

      <mat-form-field appearance="outline" class="jaro-kit-textarea">
        <mat-label>Additional Comments</mat-label>
        <textarea
          type="text"
          formControlName="certificationCompletion_comment"
          matInput
          rows="4"
        ></textarea>
      </mat-form-field>
    </div>
  </div>
</ng-container>
