<ng-container *ngIf="form" [formGroup]="form">
  <div class="row report-input-sub-section">
    <div class="col-md-6 ps-0 pe-15">
      <div class="report-input-field">
        <mat-form-field appearance="outline">
          <mat-label>Specific Zoning Classification</mat-label>
          <input matInput formControlName="siteClassification" autocomplete="off" />
        </mat-form-field>
      </div>
    </div>
    <div class="col-md-6 ps-0 pe-0">&nbsp;</div>
    <div class="ps-0 pe-0 col-md-12">
      <div class="report-input-form-field">
        <mat-form-field appearance="outline" class="jaro-kit-textarea">
          <mat-label>Zoning Description</mat-label>
          <textarea
            type="text"
            formControlName="siteZoningDescription"
            placeholder="Zoning Description"
            matInput
            rows="4"
          ></textarea>
        </mat-form-field>
      </div>
    </div>
    <div class="ps-0 pe-0 col-md-12">
      <div class="report-input-form-field">
        <mat-label>Zoning Compliance</mat-label>
        <div>
          <mat-radio-group formControlName="siteZoningCompliance" (change)="onChangeZoningCompliance($event)">
            <mat-radio-button
              *ngFor="let option of zoningComplianceOptions"
              [value]="option.value"
              class="report-input-radio-button"
              >{{ option.value }}</mat-radio-button
            >
          </mat-radio-group>
        </div>
      </div>      
    </div>
    <div class="ps-0 pe-0 col-md-12">
      <div class="report-input-field">
        <mat-form-field *ngIf="isShowComplianceDescription" appearance="outline" class="jaro-kit-textarea">
          <mat-label>Zoning Compliance Description</mat-label>
          <textarea
            type="text"
            formControlName="siteComplianceDescription"
            placeholder="Zoning Compliance Description"
            matInput
            rows="4"
          ></textarea>
        </mat-form-field>
      </div>
    </div>
  </div>
</ng-container>
