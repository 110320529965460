import { FacetRangeModel } from "./facet-range-model";
import { FacetScoreModel } from "./facet-score-model";
import { isEmpty, filter } from 'lodash-es';

export enum FacetFilterLayoutColumn
{
    left,
    right
}

export enum FacetFilterComponentType
{   
    checkboxList,
    rangeSlider
}


export class FacetFilterModel {
    public id: string;
    public title: string;
    public componentType: FacetFilterComponentType = FacetFilterComponentType.checkboxList;
    public column: FacetFilterLayoutColumn = FacetFilterLayoutColumn.right;
    public scores?: FacetScoreModel[] = [];
    public statistics?: FacetRangeModel;

    constructor(id: string, title: string, componentType: FacetFilterComponentType, column: FacetFilterLayoutColumn)
    {
        this.id = id;
        this.title = title;
        this.componentType = componentType;
        this.column = column;
    }

    public getSelectedScores(): FacetScoreModel[]
    {        
        if(isEmpty(this.scores || []))
        {
            return [];
        }
        else
        {
            return filter(this.scores, (score: FacetScoreModel) => score.isSelected);
        }
    }
}