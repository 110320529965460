import { get, multiply, set, subtract, sum } from 'lodash-es';
import { ReportInputDataFormFieldModel } from 'src/app/features/shared/forms/models/report-input-data-field-form-model/report-input-date-field-form-model';
import { YesNoFormatPipe } from 'src/app/shared/pipes/yes-no-format/yes-no-format.pipe';

export const ReportInputTemplateConfigCostApproachSection: ReportInputDataFormFieldModel[] = [
  {
    formControlName: 'costApproachIncludedInReport',
    format: {
      pipeType: YesNoFormatPipe,
    },
  },
  {
    formControlName: 'costApproachDwellingSquareFeet',
    mapToFieldFunc: (_value, reportDto) => {
      const grossLivingAreaSize = get(
        reportDto,
        'property.improvement.interior.grossLivingAreaSize'
      );
      set(reportDto, 'property.costApproach.dwellingSquareFeet', grossLivingAreaSize);
      return grossLivingAreaSize;
    },
  },
  {
    formControlName: 'costApproachBelowGradeSquareFeet',
    mapToFieldFunc: (value, reportDto) => {
      const isSyncActiveForBelowGradeSquareFeet = get(
        reportDto,
        'property.costApproach.isSyncActiveForBelowGradeSquareFeet'
      );      
      if (isSyncActiveForBelowGradeSquareFeet) {
        value = get(reportDto, 'property.subjectAreaCalculations.totalBelowGradeArea');
        set(reportDto, 'property.costApproach.belowGradeSquareFeet', value);
        return value;
      }
      return value;
    },
  },
  {
    formControlName: 'costApproachDwellingCost',
    mapToFieldFunc: (_value, objectDto) => {
      const dwellingSquareFeet = get(objectDto, 'property.costApproach.dwellingSquareFeet');
      const dwellingPricePerSquareFeet = get(
        objectDto,
        'property.costApproach.dwellingPricePerSquareFeet'
      );
      let value = null;
      if (dwellingSquareFeet && dwellingPricePerSquareFeet) {
        value = Math.round(multiply(dwellingSquareFeet, dwellingPricePerSquareFeet * 100)) / 100;
      }
      set(objectDto, 'property.costApproach.dwellingCost', value);
      return value;
    },
  },
  {
    formControlName: 'basementAreaSqFt',
    mapToFieldFunc: (_value, objectDto) => {
      const basementAreaValue = get(
        objectDto,
        'property.subjectAreaCalculations.totalBelowGradeArea'
      );
      if(basementAreaValue == null) {
        _value = 0;
      }
      set(objectDto, 'property.subjectAreaCalculations.totalBelowGradeArea', _value);
      return _value;
    },
  },
  {
    formControlName: 'costApproachBelowGradeCost',
    mapToFieldFunc: (_value, objectDto) => {
      const belowGradeSquareFeet = get(objectDto, 'property.costApproach.belowGradeSquareFeet');
      const belowGradePricePerSquareFeet = get(
        objectDto,
        'property.costApproach.belowGradePricePerSquareFeet'
      );
      let value = null;
      if (belowGradeSquareFeet && belowGradePricePerSquareFeet) {
        value =
          Math.round(
            multiply(
              parseFloat(belowGradeSquareFeet),
              parseFloat(belowGradePricePerSquareFeet) * 100
            )
          ) / 100;
      }
      set(objectDto, 'property.costApproach.belowGradeCost', value);
      return value;
    },
  },
  {
    formControlName: 'costApproachGarageCarportCost',
    mapToFieldFunc: (_value, objectDto) => {
      const garageCarportSquareFeet = get(
        objectDto,
        'property.costApproach.garageCarportSquareFeet'
      );
      const garageCarportPricePerSquareFeet = get(
        objectDto,
        'property.costApproach.garageCarportPricePerSquareFeet'
      );
      let value = null;
      if (garageCarportSquareFeet && garageCarportPricePerSquareFeet) {
        value =
          Math.round(
            multiply(
              parseFloat(garageCarportSquareFeet),
              parseFloat(garageCarportPricePerSquareFeet) * 100
            )
          ) / 100;
      }
      set(objectDto, 'property.costApproach.garageCarportCost', value);
      return value;
    },
  },
  {
    formControlName: 'costApproachTotalCost',
    mapToFieldFunc: (_value, objectDto) => {
      const dwellingCost = get(objectDto, 'property.costApproach.dwellingCost') || 0;
      const belowGradeCost = get(objectDto, 'property.costApproach.belowGradeCost') || 0;
      const extraCost = get(objectDto, 'property.costApproach.extraCost') || 0;
      const garageCarportCost = get(objectDto, 'property.costApproach.garageCarportCost') || 0;

      const value =
        Math.round(
          sum([
            parseFloat(dwellingCost) * 100,
            parseFloat(belowGradeCost) * 100,
            parseFloat(extraCost) * 100,
            parseFloat(garageCarportCost) * 100,
          ])
        ) / 100;
      set(objectDto, 'property.costApproach.totalCost', value);
      return value;
    },
  },
  {
    formControlName: 'costApproachTotalDepreciation',
    mapToFieldFunc: (_value, objectDto) => {
      const depreciationPhysical =
        get(objectDto, 'property.costApproach.depreciationPhysical') || 0;
      const depreciationFunctional =
        get(objectDto, 'property.costApproach.depreciationFunctional') || 0;
      const depreciationExternal =
        get(objectDto, 'property.costApproach.depreciationExternal') || 0;

      const value =
        Math.round(
          sum([
            parseFloat(depreciationPhysical) * 100,
            parseFloat(depreciationFunctional) * 100,
            parseFloat(depreciationExternal) * 100,
          ])
        ) / 100;
      set(objectDto, 'property.costApproach.totalDepreciation', value);
      return value;
    },
  },
  {
    formControlName: 'costApproachDepreciatedCost',
    mapToFieldFunc: (_value, objectDto) => {
      const totalCost = get(objectDto, 'property.costApproach.totalCost') || 0;
      const totalDepreciation = get(objectDto, 'property.costApproach.totalDepreciation') || 0;
      const value =
        Math.round(subtract(parseFloat(totalCost) * 100, parseFloat(totalDepreciation) * 100)) /
        100;

      set(objectDto, 'property.costApproach.depreciatedCost', value);
      return value;
    },
  },
  {
    formControlName: 'costApproachIndicatedValue',
    mapToFieldFunc: (_value, objectDto) => {
      const opinionOfSiteValue = get(objectDto, 'property.costApproach.opinionOfSiteValue') || 0;
      const asIsValue = get(objectDto, 'property.costApproach.asIsValueOfSiteImprovements') || 0;
      const depreciatedCost = get(objectDto, 'property.costApproach.depreciatedCost') || 0;

      const value =
        Math.round(
          sum([
            parseFloat(opinionOfSiteValue) * 100,
            parseFloat(asIsValue) * 100,
            parseFloat(depreciatedCost) * 100,
          ])
        ) / 100;

      set(objectDto, 'property.costApproach.indicatedValue', value);
      return value;
    },
  },
];
