import { RestService } from '@abp/ng.core';
import { Injectable } from '@angular/core';
import { UrlLink } from '@proxy/shared/urlLink/models';
import type { CreateWorkfileDto, UpdateWorkfileDto, WorkfileRecordDto } from './models';

@Injectable({
  providedIn: 'root',
})
export class WorkfileService {
  apiName = 'Publication'; 

  create = (orderAssignmentId: string, input: CreateWorkfileDto) =>
    this.restService.request<any, WorkfileRecordDto>({
      method: 'POST',
      url: `/api/v1/workfile/order-assignment/${orderAssignmentId}`,
      body: input,
    },
    { apiName: this.apiName });

  exportZip = (orderAssignmentId: string) =>
      this.restService.request<any, UrlLink>({
        method: 'POST',        
        url: `/api/v1/workfile/order-assignment/${orderAssignmentId}/export/zip`
      },
      { apiName: this.apiName });

  delete = (orderAssignmentId: string, workfileId: string) =>
    this.restService.request<any, void>({
      method: 'DELETE',
      url: `/api/v1/workfile/order-assignment/${orderAssignmentId}/workfile/${workfileId}`
    },
    { apiName: this.apiName });

  get = (orderAssignmentId: string, workfileId: string) =>
    this.restService.request<any, WorkfileRecordDto>({
      method: 'GET',
      url: `/api/v1/workfile/order-assignment/${orderAssignmentId}/workfile/${workfileId}`
    },
    { apiName: this.apiName });


  getList = (orderAssignmentId: string) =>
    this.restService.request<any, WorkfileRecordDto[]>({
      method: 'GET',
      url: `/api/v1/workfile/order-assignment/${orderAssignmentId}`
    },
    { apiName: this.apiName });

  update = (orderAssignmentId: string, workfileId: string, input: UpdateWorkfileDto) =>
    this.restService.request<any, WorkfileRecordDto>({
      method: 'PATCH',
      url: `/api/v1/workfile/order-assignment/${orderAssignmentId}/workfile/${workfileId}`,
      body: input,
    },
    { apiName: this.apiName });


  constructor(private restService: RestService) {}
}
