import { Component, Inject, Input } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { ReportInputTemplateField } from 'src/app/features/shared/forms/models/report-input-layout.model';
import InjectionSymbol from 'src/app/shared/injection/injection-symbol';
import { EventService } from 'src/app/shared/services/event.service';
import { ReportWithExtraAttributes } from '../../models/report-input-extra-atributes.model';
@Component({
  selector: 'jaro-kit-base-component',
  template: '',
})
export class ReportInputBaseComponent {
  @Input() form: FormGroup;
  @Input() field: ReportInputTemplateField;
  @Input() reportDto: ReportWithExtraAttributes;

  constructor(
    @Inject(InjectionSymbol.EventService)
    protected eventService: EventService
  ) {}

  onChange($event) {
    const eventChange = (this.field.events || []).find((item) => item.key === 'change');
    if (eventChange) {
      eventChange.action($event, this.form, this.reportDto);
    }
    const metadata = this.field.metadata;
    if (metadata?.isRefreshVisible) {
      this.eventService.changeReportData(this.field);
    }
  }
}
