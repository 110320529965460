import { Component, Input, OnInit } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { ReportInputTemplateSubSectionModel } from 'src/app/features/shared/forms/models/report-input-layout.model';

@Component({
  selector: 'jaro-kit-additional-comments-view-mode',
  templateUrl: './additional-comments-view-mode.component.html',
  styleUrls: ['./additional-comments-view-mode.component.scss'],
})
export class AdditionalCommentsViewModeComponent implements OnInit {
  markDown: string;
  @Input() form: FormGroup;
  @Input() subSection: ReportInputTemplateSubSectionModel;

  ngOnInit(): void {
    this.markDown = this.form.value['narrativeCommentsAdditionalComments'] || '';
  }
}
