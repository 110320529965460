<div class="report-input-form-field mt-15" [ngClass]="{'col-md-12': !isEditMode}" *ngIf="!damageNotes || damageNotes?.length == 0">
  <span>None</span>
</div>

<div class="damage-note" *ngIf="isEditMode && damageNotes?.length > 0">
  <div  *ngFor="let damageNote of damageNotes">
    <mat-form-field appearance="outline">
      <mat-label>Deficiency {{ damageNote.index }}</mat-label>
      <input matInput [(ngModel)]="damageNote.value" (change)="onChangeDamageNote()"/>
    </mat-form-field>
  </div>
</div>

<div class="damage-note" *ngIf="!isEditMode && damageNotes?.length > 0">
  <div  *ngFor="let damageNote of damageNotes">
    <div class="report-input-form-field col-md-12 mt-15">
        <span class="report-input-label">Deficiency {{ damageNote.index}}</span>
        <span class="report-input-value">
            {{ damageNote.value }}
        </span>
      </div>
  </div>
</div>
