import { PropertySearchRequestDto } from "@proxy/property/property-job/property-jobs/v1";

export class PropertySearchRequestDtoFactory {
  static getEmptyRequest(): PropertySearchRequestDto {  
    return {
      aboveBath: {
        min: null,
        max: null
      },
      aboveBedrooms: {
        min: null,
        max: null
      },
      aboveGradeFinishedArea: {
        min: null,
        max: null
      },
      aboveGradeRoomCount: {
        min: null,
        max: null
      },
      actualAge: {
        min: null,
        max: null
      },
      address: [],
      adu: [],
      architecturalStyle: [],
      associationFee: [],
      associationName: [],
      associationYN: [],
      basement: [],
      basementEntry: [],
      basementType: [],
      bathroomsTotal: {
        min: null,
        max: null
      },
      bedroomsTotal: {
        min: null,
        max: null
      },
      belowBath: {
        min: null,
        max: null
      },
      belowBedrooms: {
        min: null,
        max: null
      },
      belowGradeFinishedArea: {
        min: null,
        max: null
      },
      belowOther: {
        min: null,
        max: null
      },
      carportSpaces: {
        min: null,
        max: null
      },
      city: [],
      closeDate: {
        min: null,
        max: null
      },
      closePrice: {
        min: null,
        max: null
      },
      concessions: [],
      concessionsAmount: {
        min: null,
        max: null
      },
      concessionsComments: [],
      condition: {
        min: null,
        max: null
      },
      cooling: [],
      distressType: [],
      distressedYN: [],
      elementarySchool: [],
      firePlaceCount: {
        min: null,
        max: null
      },
      fullBath: {
        min: null,
        max: null
      },
      functionalUtility: [],
      garageSpaces: {
        min: null,
        max: null
      },
      halfBath: {
        min: null,
        max: null
      },
      heating: [],
      highSchool: [],
      id: [],
      leaseholdFeeSimple: [],
      listingContractDate: {
        min:null,
        max:null
      },
      listingContractMonthYear: [],
      livingArea: {
        min:null,
        max:null
      },
      listPrice: {
        min: null,
        max: null
      },
      price: {
        min: null,
        max: null
      },
      location: [],
      locationFactors: [],
      lotSizeSquareFeet: {
        min:null,
        max:null
      },
      middleSchool: [],
      mlsStatus: [],
      openParking: {
        min:null,
        max:null
      },
      pool: [],
      porchPatioDeck: [],
      postalCode: [],
      pricePerSquareFeet: {
        min:null,
        max:null
      },
      privateRemarks: [],
      propertySubType: [],
      publicRemarks: [],
      quality: {
        min:null,
        max:null
      },
      schoolDistrict: [],
      site: {
        min:null,
        max:null
      },
      standardStatus: [],
      state: [],
      storiesTotal: {
        min:null,
        max:null
      },
      style: [],
      subDivisionName: [],
      unparsedAddress: [],
      view: [],
      viewFactors: [],
      yearBuilt: {
        min:null,
        max:null
      },
      zip: [],
      polygons: []
    } as PropertySearchRequestDto;
  }
}
