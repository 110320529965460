<ng-container *ngIf="form" [formGroup]="form">
  <div class="row">
    <div class="col-md-6 pl-0 pr-15">
      <jaro-kit-date-picker
        [form]="form"
        dateControl="subjectInspectionDate"
        titleDate="Date of Inspection"
        id="subjectInspectionDate"
        [isRequired]="true"
      ></jaro-kit-date-picker>
    </div>

    <div class="col-md-6 pl-15 pr-0">
      <jaro-kit-date-picker
        [form]="form"
        dateControl="subjectEffectiveDate"
        titleDate="Effective Date of Appraisal Update"
        *ngIf="isShowEffectiveDate"
        [isRequired]="true"
        id="subjectEffectiveDate"
      ></jaro-kit-date-picker>
    </div>
  </div>

  <div class="row">
    <div class="col-md-6 pl-0 pr-15" *ngIf="isShowDeclinedQuestion">
      <div class="report-input-form-field mb-15" id="appraisalUpdate_propertyMarketValueDecreased">
        <p class="report-input-radio-label">
          Has the value of the subject property declined since the effective date of the original appraisal?
        </p>

        <mat-radio-group formControlName="appraisalUpdate_propertyMarketValueDecreased">
          <mat-radio-button [value]="true" class="report-input-radio-button">Yes</mat-radio-button>
          <mat-radio-button [value]="false" class="report-input-radio-button">No</mat-radio-button>
        </mat-radio-group>

        <jaro-kit-validation-message [form]="form" [controlName]="'appraisalUpdate_propertyMarketValueDecreased'" [isRequired]="true"></jaro-kit-validation-message>
      </div>

      <mat-form-field  appearance="outline" class="jaro-kit-textarea">
        <mat-label>Additional Comments</mat-label>
        <textarea
          type="text"
          formControlName="appraisalUpdate_comment"
          matInput
          rows="4"
        ></textarea>
      </mat-form-field>
    </div>

    <ng-container *ngIf="isShowCompletedQuestion">    
      <div [ngClass]="{ 'col-md-6': true, 'pl-15': isShowDeclinedQuestion, 'pr-0': true }" >
        <div class="report-input-form-field mb-15">
          <p class="report-input-radio-label" id="certificationCompletion_propertyImprovementsCompleted">
              Have the improvements been completed in accordance with the requirements and conditions stated in the original appraisal report?
          </p>

          <mat-radio-group formControlName="certificationCompletion_propertyImprovementsCompleted" (change)="onChangeImprovementsCompleted($event)">
            <mat-radio-button [value]="true" class="report-input-radio-button">Yes</mat-radio-button>
            <mat-radio-button [value]="false" class="report-input-radio-button">No</mat-radio-button>
          </mat-radio-group>

          <jaro-kit-validation-message [form]="form" [controlName]="'certificationCompletion_propertyImprovementsCompleted'" [isRequired]="true"></jaro-kit-validation-message>
        </div>

        <mat-form-field  appearance="outline" class="jaro-kit-textarea">
          <mat-label>Additional Comments</mat-label>
          <textarea
            type="text"
            formControlName="certificationCompletion_comment"
            matInput
            rows="4"
            id="certificationCompletion_comment"
          ></textarea>
        </mat-form-field>

        <jaro-kit-validation-message [form]="form" [controlName]="'certificationCompletion_comment'" [isRequired]="true"></jaro-kit-validation-message>
      </div>
    </ng-container>
  </div>  
</ng-container>
