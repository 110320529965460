import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { ReportVersionWithLinksDto } from '@proxy/bff/activity/reports/v1';
import { ExportReportComponent } from '../export-report/export-report.component';

@Component({
  selector: 'jaro-kit-export-report-button',
  templateUrl: './export-report-button.component.html',
  styleUrls: ['./export-report-button.component.scss'],
})
export class ExportReportButtonComponent implements OnInit, OnDestroy {
  @Input() orderAssignmentId: string;
  @Input() productName: string;
  @Input() reportVersionDto: ReportVersionWithLinksDto;
  isModalOpen : boolean;

  constructor(
    private matDialog: MatDialog
  ) {}

  ngOnInit(): void {
    this.isModalOpen = false;
  }

  ngOnDestroy(): void {
    this.isModalOpen = false;
  }

  openDocumentPreview() {
    if(this.isModalOpen) return;
    this.isModalOpen = true;
    const dialogConfig = new MatDialogConfig();
    dialogConfig.data = {
      orderAssignmentId: this.orderAssignmentId,
      reportVersionDto: this.reportVersionDto,
      productName: this.productName,
    };
    dialogConfig.panelClass = 'export-report-modal';
    dialogConfig.disableClose = true;
    let dialogRef = this.matDialog.open(ExportReportComponent, dialogConfig);
    dialogRef.afterClosed().subscribe({
      next: (_response: any) => {
        this.isModalOpen = false;
      },
    });
  }
}
