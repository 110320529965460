import { Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
  selector: 'jaro-kit-document-library-button',
  templateUrl: './document-library-button.component.html',
  styleUrls: ['./document-library-button.component.scss']
})
export class DocumentLibraryButtonComponent  {
  @Input() isLoading: boolean;
  @Output() onOpenDocumentLibrary = new EventEmitter<boolean>();

  openDocumentLibrary() {
    this.onOpenDocumentLibrary.emit(true);
  }
}
