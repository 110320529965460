<div class="document-item">
  <img *ngIf="urlImage" [src]="urlImage" class="image" alt="image" (error)="(!urlImage)" />
  <i *ngIf="!urlImage" class="fa fa-file-image-o default-image" aria-hidden="true"></i>
  <div class="document-detail">
    <span
      class="document-title mouse-cursor-pointer"
      (click)="openImagePreview(document)"
      >{{ document.title }}</span
    >
    <span class="document-category">{{
      document.documentCategory
    }}</span>
    <div class="document-pages">
      <div class="vertical_dotted_line">
        <div class="dotted_line"></div>
      </div>
      <span class="document-page-range mouse-cursor-pointer"
      (click)="openImagePreview(document)">Image</span>
      <em
        class="fa-regular fa-trash-can jaro-kit-primary-icon"
        aria-hidden="true"
        (click)="removeDocument()"
      ></em>
    </div>
  </div>
</div>
