<halo-global-popup [dialogRef]="dialogRef" [isHideClose]="false">
    <div class="report-validation-message">
        <span>Please complete before submitting the report</span>
        <ul>
            <li *ngFor="let group of validationSummary | keyvalue">{{group.value.message}}
                <ul *ngIf="group.value.lineItems.length > 0">
                    <li *ngFor="let lineItem of group.value.lineItems">{{lineItem.message}}
                </ul>
            </li>
        </ul>

        <button (click)="dialogRef.close()">Close</button>
    </div>
  </halo-global-popup>
