import { LocalizationPipe } from '@abp/ng.core';
import { CommonModule } from '@angular/common';
import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MatDialogModule } from '@angular/material/dialog';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { OrderAssignmentService } from '@proxy/ascent/order/order-assignment/controllers/v2';
import { NgxDatatableModule } from '@swimlane/ngx-datatable';
import InjectionSymbol from 'src/app/shared/injection/injection-symbol';
import { SharedModule } from '../../shared/shared.module';
import { AdminSidebarModule } from '../admin-sidebar/admin-sidebar.module';
import { OrderSearchComponent } from './order-search.component';


@NgModule({
  declarations: [OrderSearchComponent],
  providers: [
    LocalizationPipe,
    { provide: InjectionSymbol.OrderAssignmentService, useClass: OrderAssignmentService }
  ],
  imports: [
    CommonModule,
    MatButtonModule,
    MatDialogModule,
    NgbModule,
    NgxDatatableModule,
    SharedModule,
    AdminSidebarModule
  ],
  exports: [OrderSearchComponent],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
})
export class OrderSearchModule {}
