import { LocalizationPipe } from '@abp/ng.core';
import { CommonModule } from '@angular/common';
import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core';
import { SharedModule } from '../../shared/shared.module';
import { AdminSidebarComponent } from './admin-sidebar.component';
import { OrderSearchResultsModule } from './order-search-results/order-search-results.module';
import { AbstractSecurityStorage, AuthModule, OidcSecurityService } from 'angular-auth-oidc-client';
import { LocalSecurityStorage } from 'src/app/shared/utils/session/local-security-storage';

@NgModule({
  declarations: [AdminSidebarComponent],
  providers: [
    LocalizationPipe, 
    OidcSecurityService,
    { provide: AbstractSecurityStorage, useClass: LocalSecurityStorage },
  ],
  imports: [
    CommonModule,
    SharedModule,
    OrderSearchResultsModule,
    AuthModule
  ],
  exports: [AdminSidebarComponent],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
})
export class AdminSidebarModule {}
