import { Injectable } from '@angular/core';
import { ReportInputPhotoPositionType } from '@proxy/bff/activity/report-input-photos/report-input-photo-position-type.enum';
import { ReportInputPhotoSectionDto } from '@proxy/bff/activity/report-input-photos/v1';
import { ReportPhotoResourceModel } from '../dtos/report-input-resource/report-photo-resource.model';
import { PartialUpdatePhotoModel } from '../models/photo/partial-update-photo.model';
@Injectable({
  providedIn: 'root',
})
export class ReportInputPhotoResourceService {
  public mapPhotoDtoToUI(photo: ReportPhotoResourceModel) {
    photo.smallSizedUrl = this.getUrlThumbnail(photo, 'small');
    photo.mediumSizedUrl = this.getUrlThumbnail(photo, 'medium');
    photo.largeSizedUrl = this.getUrlThumbnail(photo, 'large');
  }

  private getUrlThumbnail(photo: ReportPhotoResourceModel, thumbnailSize = 'small') {
    const thumbnail = (photo.thumbnails || []).find((item) => item.size == thumbnailSize);
    return thumbnail?.links?.length > 0 && thumbnail?.links[0].href
      ? thumbnail.links[0].href
      : photo.resourceUrl;
  }

  addPhotoToSection(
    photo: PartialUpdatePhotoModel,
    sectionId: string,
    photoSections: ReportInputPhotoSectionDto[],
    photoGalleries: ReportPhotoResourceModel[],
    isSyncGalleryPhoto: boolean = true
  ) {
    photo.isInReport = true;
    if (!photo.sectionIds.includes(sectionId)) {
      photo.sectionIds.push(sectionId);
    }

    const photoSection = photoSections.find((item) => item.id === sectionId);
    const photoIndex = photoSection.photos.findIndex((item) => item.id === photo.id);
    if (photoIndex > -1) {
      photoSection.photos.splice(photoIndex, 1, photo);
    } else {
      switch (photo.position?.positionType) {
        case ReportInputPhotoPositionType.First:
          photoSection.photos.unshift(photo);
          break;

        case ReportInputPhotoPositionType.BetweenPhotos:
          const index = photoSection.photos.findIndex(
            (item) => item.id === photo.position.previousReportPhotoId
          );
          photoSection.photos.splice(index + 1, 0, photo);
          break;

        case ReportInputPhotoPositionType.Last:
        default:
          photoSection.photos.push(photo);
          break;
      }
    }
    photo.position = null;

    if (isSyncGalleryPhoto) {
      this.syncGalleryPhoto(photo, photoSections, photoGalleries);
    }

    photo.currentSectionId = sectionId;
  }

  removePhotoFromSection(
    photo: PartialUpdatePhotoModel,
    sectionId: string,
    photoSections: ReportInputPhotoSectionDto[],
    photoGalleries: ReportPhotoResourceModel[],
    isSyncGalleryPhoto: boolean = true
  ) {
    const photoSection = photoSections.find((item) => item.id === sectionId);
    const photoIndex = photoSection.photos.findIndex((item) => item.id === photo.id);
    if (photoIndex > -1) {
      photoSection.photos.splice(photoIndex, 1);
    }

    photo.isInReport = photoSections.some((section) => {
      return section.photos.some((item) => item.id === photo.id);
    });
    photo.sectionIds = photo.sectionIds.filter((item) => item !== sectionId);

    if (isSyncGalleryPhoto) {
      this.syncGalleryPhoto(photo, photoSections, photoGalleries);
    }
  }

  movePhoto(
    photo: PartialUpdatePhotoModel,
    sectionId: string,
    newSectionId: string,
    photoSections: ReportInputPhotoSectionDto[],
    photoGalleries: ReportPhotoResourceModel[]
  ) {
    this.removePhotoFromSection(photo, sectionId, photoSections, photoGalleries, false);
    this.addPhotoToSection(photo, newSectionId, photoSections, photoGalleries, true);
  }

  syncGalleryPhoto(
    photo: PartialUpdatePhotoModel,
    photoSections: ReportInputPhotoSectionDto[],
    photoGalleries: ReportPhotoResourceModel[]
  ) {
    photoSections.forEach((section) => {
      section.photos.forEach((newPhoto) => {
        this.syncPhotoData(photo, newPhoto);
      });
    });

    photoGalleries.forEach((newPhoto) => {
      this.syncPhotoData(photo, newPhoto);
    });
  }

  addPhotoToAndRemovePhotoFromSections(
    photo: ReportPhotoResourceModel,
    addNewSectionIds: string[],
    removeSectionIds: string[],
    photoSections: ReportInputPhotoSectionDto[],
    photoGalleries: ReportPhotoResourceModel[]
  ) {
    const isSyncGalleryPhoto = addNewSectionIds?.length > 0;
    removeSectionIds.forEach((removeSectionId) => {
      this.removePhotoFromSection(
        photo,
        removeSectionId,
        photoSections,
        photoGalleries,
        !isSyncGalleryPhoto
      );
    });

    addNewSectionIds.forEach((addNewSectionId) => {
      this.addPhotoToSection(
        photo,
        addNewSectionId,
        photoSections,
        photoGalleries,
        isSyncGalleryPhoto
      );
    });
  }

  mergeReportPhotoWithPhotoGallery(
    section: ReportInputPhotoSectionDto,
    photos: ReportPhotoResourceModel[]
  ) {
    section.photos.forEach((photo: ReportPhotoResourceModel) => {
      this.mapPhotoDtoToUI(photo);
      photo.currentSectionId = section.id;
      const galleryPhoto = photos.find((item) => item.id === photo.id);
      if (galleryPhoto) {
        galleryPhoto.isInReport = true;
        if (galleryPhoto.sectionIds) {
          galleryPhoto.sectionIds.push(section.id);
        } else {
          galleryPhoto.sectionIds = [section.id];
        }
        photo.sectionIds = galleryPhoto.sectionIds;
        photo.isInReport = true;
        galleryPhoto.title = photo.title;
      }
    });
  }

  private syncPhotoData(photo: PartialUpdatePhotoModel, newPhoto: PartialUpdatePhotoModel) {
    if (photo.id === newPhoto.id) {
      newPhoto.id = photo.id;
      newPhoto.title = photo.title;
      newPhoto.isCoverPhoto = photo.isCoverPhoto;
      newPhoto.isInReport = photo.isInReport;
      newPhoto.sectionIds = photo.sectionIds;
    } else if (photo.isCoverPhoto) {
      // only one photo in the gallery can be the cover photo.
      newPhoto.isCoverPhoto = false;
    }
  }
}
