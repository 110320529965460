import { Confirmation, ConfirmationService } from '@abp/ng.theme.shared';
import { HttpClient, HttpEventType } from '@angular/common/http';
import { EventEmitter, Injectable } from '@angular/core';
import {
  CreateDocumentDto,
  DocumentDto,
  DocumentsService,
  FileVersionDto
} from '@proxy/publication/document/documents/v1';
import { Observable, Subscription, interval } from 'rxjs';
import { FileUploading } from './file-uploading';
import { MessageService } from './message.service';

@Injectable({
  providedIn: 'root',
})
export class FilesUploaderService {
  constructor(
    private http: HttpClient,
    private documentsService: DocumentsService,
    private confirmation: ConfirmationService,
    private msgService: MessageService
  ) {}
  private subscription: Subscription;
  public filesWithUploadingProgress: FileUploading[] = [];
  private orderAssignmentId: string;
  private documentCategory: string;
  private documentSource: string;
  private fileType: string;
  private title: string;
  public onUploadingCompletion = new EventEmitter<FileUploading[]>();

  public uploadFiles(files: FileUploading[], orderAssignmentId: string, title: string, documentSource?: string, fileType?: string, documentCategory?: string, labels?: string[]) {
    this.filesWithUploadingProgress = files;
    this.orderAssignmentId = orderAssignmentId;
    this.documentCategory = documentCategory;
    this.documentSource = documentSource;
    this.fileType = fileType;
    this.title = title;
    //check uploading status every 1 second
    const source = interval(1000);
    this.subscription = source.subscribe(() => {
      this.checkIsCompleted();
    });

    //if there are attached files
    for (let i = 0; i < this.filesWithUploadingProgress.length; i++) {
      this.addDocument(this.filesWithUploadingProgress[i].file, labels).subscribe({
        next: (doc) => {
          this.filesWithUploadingProgress[i].docId = doc.id;

          this.addFile(doc.id).subscribe({
            next: (fileAdded) => {
              this.UploadFileData(fileAdded, i);
            },
            error: (err) => {
              this.logActionError(err);
            },
          });
        },
        error: (err) => {
          this.logActionError(err);
        },
      });
    }
  }

  public resetEvent() {
    this.onUploadingCompletion = new EventEmitter<FileUploading[]>();
  }

  private addFile(docId: string): Observable<FileVersionDto> {
    return this.documentsService.createNewFileVersion(docId, this.orderAssignmentId);
  }

  private addDocument(file: File, labels: string[] = []): Observable<DocumentDto> {
    const dto: CreateDocumentDto = {
      fileName: file.name,
      title: this.title,
      orderAssignmentId: this.orderAssignmentId,
      labels: labels,
      tags: [],
      source: this.documentSource,
      fileType: this.fileType,
      documentCategory: this.documentCategory,
    } as CreateDocumentDto;
    return this.documentsService.create(dto);
  }

  private UploadFileData(fileAdded: FileVersionDto, i: number) {
    this.filesWithUploadingProgress[i].isUploading = true;
    this.http
      .put(fileAdded.links[0].href, this.filesWithUploadingProgress[i].file, {
        reportProgress: true,
        observe: 'events',
      })
      .subscribe({
        next: (event) => {
          if (event.type === HttpEventType.UploadProgress) {
            let uploadProgress = Math.round((100 * event.loaded) / event.total);
            this.filesWithUploadingProgress[i].progress = uploadProgress;
          } else if (event.type === HttpEventType.Response) {
            if (event.ok) {
              this.filesWithUploadingProgress[i].isSuccessful = true;
            } else {
              this.filesWithUploadingProgress[i].error = "Error. File wasn't uploaded!";
            }
            this.filesWithUploadingProgress[i].isUploaded = true;
            this.filesWithUploadingProgress[i].progress = 100;
          }
        },
        error: (err) => {
          this.logActionError(err);
        },
      });
  }

  //It is used for multiple file uploading. It s called every time after completion popup closing. It checks completion of all

  private checkIsCompleted() {
    if (!this.filesWithUploadingProgress.some((item) => !item.isUploaded)) {
      if (this.subscription) this.subscription.unsubscribe();
      if (this.filesWithUploadingProgress.some((item) => !item.isSuccessful)) {
        const options: Partial<Confirmation.Options> = {
          hideCancelBtn: true,
          yesText: 'Close',
        };
        this.confirmation
          .error("Files weren't uploaded!", 'An error has occurred!', options)
          .subscribe((_status: Confirmation.Status) => {
            // do nothing
          });
      }
      this.onUploadingCompletion.emit(this.filesWithUploadingProgress);
    }
  }

  ngOnDestroy() {
    if (this.subscription) this.subscription.unsubscribe();
  }

  private logActionError(err: any): void {
    this.onUploadingCompletion.emit(
      this.filesWithUploadingProgress.filter((item) => item.isSuccessful)
    );
    this.msgService.error(err).subscribe((_status: Confirmation.Status) => {});
  }
}
