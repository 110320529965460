import { ProductType } from './product-type.enum';

export class ProductModel {
  public name: string;
  public productType: string;
}

export const ProductPrototypes: ProductModel[] = [
  {
    name: '1004 - SFR',
    productType: ProductType.SFR,
  },
];
