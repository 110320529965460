<halo-global-popup [dialogRef]="dialogRef" [isHideClose]="true" [title]="workfile.title">
  <div class="workfile-review-container">

    <div class="workfile-review-top">
      <div class="workfile-top-left">
      </div>

      <div class="workfile-top-right">
        <div class="space"></div>
        <button class="jaro-kit-primary-button mouse-cursor-pointer" (click)="close()">Close</button>
      </div>
    </div>

    <jaro-kit-preview-document
      [url]="workfile.url"
      (onPdfLoadingFailed)="pdfLoadingFailed()"
      (isLoading)="false">
    </jaro-kit-preview-document>
  </div>

</halo-global-popup>
