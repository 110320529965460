<halo-global-popup [dialogRef]="dialogRef" [isHideClose]="true" [isShowScrollbars]="true">
  <div [ngClass]="{ 'translucent-loading-overlay': isLoading, 'comparables-panel': true }">
    <div class="market-data-page">
      <mat-spinner
        *ngIf="isLoading"
        value="100"
        diameter="100"
        strokeWidth="5"
        class="loading-spinner"
      ></mat-spinner>
      <ng-container>
        <div class="container">
          <div class="row jaro-kit-market-cleanup-header">
            <div class="col-12">
              <div *ngIf="!isLoading" class="property-summary">
                <jaro-kit-market-cleanup-property-summary
                  [property]="property"
                  [reportComparable]="reportComparable"
                  [isLoading]="isLoading"
                  [hasPropertyAttributeChanges]="hasPropertyAttributeChanges"
                  [hasPropertyAttributeHasErrors]="hasPropertyAttributeHasErrors"
                  (onSaveRequested)="save()"
                  (onCancelRequested)="cancel()"
                ></jaro-kit-market-cleanup-property-summary>
              </div>
            </div>
          </div>

          <div class="row">
            <jaro-kit-market-section [section]="sections.main" class="jaro-kit-market-section">
              <mat-tab-group dynamicHeight>
                <mat-tab label="Property Details">
                  <div class="section-container" *ngIf="!isLoading">
                    <jaro-kit-market-cleanup-property-details
                      [property]="property"
                      [isLoading]="isLoading"
                    ></jaro-kit-market-cleanup-property-details>
                  </div>
                </mat-tab>

                <mat-tab label="Remarks">
                  <div class="section-container" *ngIf="!isLoading">
                    <jaro-kit-market-cleanup-property-commentary
                      [property]="property"
                      [isLoading]="isLoading"
                    ></jaro-kit-market-cleanup-property-commentary>
                  </div>
                </mat-tab>

                <mat-tab label="Grid Details">
                  <div class="section-container" *ngIf="!isLoading">
                    <jaro-kit-market-cleanup-property-attribute-table
                      [property]="property"
                      (onChangeAttributeValue)="changeAttributeValue($event)"
                    ></jaro-kit-market-cleanup-property-attribute-table>
                  </div>
                </mat-tab>

                <mat-tab label="Data Reference​">
                  <div class="section-container" *ngIf="!isLoading">
                    <jaro-kit-market-cleanup-data-reference
                      [property]="property"
                      [isLoading]="isLoading"
                    ></jaro-kit-market-cleanup-data-reference>
                  </div>
                </mat-tab>
              </mat-tab-group>
            </jaro-kit-market-section>
          </div>
        </div>
      </ng-container>
    </div>
  </div>
</halo-global-popup>
