export default class InjectionSymbol {
  public static readonly OrderAssignmentService = 'OrderAssignmentService';
  public static readonly PropertyService = 'PropertyService';
  public static readonly PropertyJobService = "PropertyJobService";
  public static readonly PropertyRecordService = "PropertyRecordService";
  public static readonly JobService = 'JobService';
  public static readonly DocumentsService = 'DocumentsService';
  public static readonly AppraiserInfoService = 'AppraiserInfoService';
  public static readonly ClockService = 'ClockService';
  public static readonly UploadReportMismoFilesValidationRule = 'UploadReportMismoFilesValidationRule';
  public static readonly SendReportMismoFilesValidationRule = 'SendReportMismoFilesValidationRule';
  public static readonly UploadReportMismoFilesValidationFactory = 'UploadReportMismoFilesValidationFactory';
  public static readonly SendReportMismoFilesValidationFactory = 'SendReportMismoFilesValidationFactory';
  public static readonly StatusChipService = 'StatusChipService';
  public static readonly ActivityReportsService = 'ActivityReportsService';
  public static readonly ReportServiceAdapter = 'ReportServiceAdapter';
  public static readonly ReportService = 'ReportsService';
  public static readonly ProductService = 'ProductService';
  public static readonly ReportPhotoService = 'ReportPhotoService';
  public static readonly ReportInputPhotoSectionService = 'ReportInputPhotoSectionService';
  public static readonly ReportInputPhotoService = 'ReportInputPhotoService';
  public static readonly ReportComparablePhotoService = 'ReportComparablePhotoService';
  public static readonly ReportComparableService = 'ReportComparableService';
  public static readonly ReportDocumentService = 'ReportDocumentService';
  public static readonly ReportDocumentSectionService = 'ReportDocumentSectionService';
  public static readonly ContentService = 'ContentService';
  public static readonly ReportInputTemplateService  = 'ReportInputTemplateService ';
  public static readonly ReportGenerationService = 'ReportGenerationService';
  public static readonly AscentReportService = 'AscentReportService';
  public static readonly ComparableService = 'ComparableService';
  public static readonly EventService = 'EventService';
  public static readonly WorkfileService = 'WorkfileService';
  public static readonly CacheService = 'CacheService';
}
