<halo-global-popup [dialogRef]="dialogRef" [isHideClose]="true">
  <div
    [ngClass]="{
      'translucent-loading-overlay': isLoading
    }"
    >&nbsp;</div>
        
    <div class="report-export-container">
      <div class="report-export-block">
        <div class="report-export-left">
          <span class="report-export-title" >
            Export Report
          </span>
        </div>       
      </div>      
      <div class="report-export-container" style="background-color: rgb(242, 242, 243)">        
        <div class="report-export-block" *ngIf="!isError">
            <span  class="report-export-left">
              <p>Please wait while the <strong>TDCX</strong> file is being generated.</p>
            </span>
            <a #exportLink [href]="exportUrl" style="display: none" [disabled]="isLoading" target="_self" download><em class="fas fa-download"></em>Download</a>
        </div>
        <div class="report-export-block" *ngIf="isError">          
          <span class="report-export-title">
            <span class="buttonDescription"><p>Failed to generate the <strong>TDCX</strong> file.</p></span>
            <button class="report-export-cancel" (click)="cancelButton()" >Close</button>
          </span>
        </div>
      </div>
    </div>
</halo-global-popup>
