<div class="sale-history-page">
  <jaro-kit-section-panel
    title="Sale History"
    [isEditMode]="isEditMode"
    (onSaveSection)="saveSection()"
    (onToggleModeSection)="toggleMode()"
  ></jaro-kit-section-panel>

  <div class="sale-history-detail" *ngIf="!isEditMode">
    <jaro-kit-commentaries [(property)]="property"></jaro-kit-commentaries>
  </div>

  <div class="sale-history-detail" *ngIf="isEditMode">
    <jaro-kit-commentaries-edit-mode [(property)]="property"></jaro-kit-commentaries-edit-mode>
  </div>
 </div>
