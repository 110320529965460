<div class="material-condition" *ngIf="form" [formGroup]="form">
  <mat-form-field appearance="outline">
    <mat-label class="report-input-label">{{ title }}</mat-label>
    <mat-select
      [formControlName]="formControlName"
      [id]="formControlName"
      disableOptionCentering
      panelClass="report-input-drop-down"
      placeholder="Select from list"
      floatLabel="never"
      (selectionChange)="sourceChange($event)" 
    >
      <mat-option *ngFor="let item of options" [value]="item?.value">{{ item?.name }}</mat-option>
    </mat-select>
  </mat-form-field>
  <jaro-kit-validation-message [form]="form" [controlName]="formControlName" [isRequired]="true"></jaro-kit-validation-message>
</div>
