import { RestService } from '@abp/ng.core';
import { Injectable } from '@angular/core';
import { PropertyRecordDto } from '../property-records/v1/models';
import { UpdateCustomRecordDto } from '../property-records';
import { PropertyRecordServiceInterface } from 'src/app/interface/property/property-job/property-record-service-interface';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class PropertyRecordService implements PropertyRecordServiceInterface {
  apiName = 'Property';

  getByTimestamp = (id: string, timestamp: number) =>
  this.restService.request<any, PropertyRecordDto>({
    method: 'GET',
    url: `/${this.apiName.toLowerCase()}/api/v1/property-records/${id}/${timestamp}`
  },
  { apiName: this.apiName, skipHandleError: true }); 

  get = (upi: string) =>
  this.restService.request<any, PropertyRecordDto>({
    method: 'GET',
    url: `/${this.apiName.toLowerCase()}/api/v1/property-records/${upi}`
  },
  { apiName: this.apiName, skipHandleError: true }); 

  post = (upi: string, updateDto: UpdateCustomRecordDto) =>
  this.restService.request<any, PropertyRecordDto>({
    method: 'POST',
    url: `/${this.apiName.toLowerCase()}/api/v1/property-records/${upi}/update-custom-record`,
    body: updateDto
  },
  { apiName: this.apiName });

  constructor(private restService: RestService) {} 

  postUpdatedProperty = (_property: PropertyRecordDto): Observable<PropertyRecordDto> => {
    throw new Error("Not Implemented");
  }
}
