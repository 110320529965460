<div class="listing-history-item">
  <div class="row">
    <div class="col-md-3 pl-0 pr-15">
      <mat-form-field appearance="outline">
        <mat-label>Source</mat-label>
        <input
          matInput
          [(ngModel)]="subjectListingHistoryDto.dataSource"
          autocomplete="off"
          (change)="updateSource()"
          [required]="true"
        />
      </mat-form-field>
    </div>

    <div class="col-md-3 pl-0 pr-15">
      <mat-form-field appearance="outline">
        <mat-label>Listing number</mat-label>
        <input
          matInput
          [(ngModel)]="subjectListingHistoryDto.listingNumber"
          autocomplete="off"
          (change)="updateSource()"
          [required]="true"
        />
      </mat-form-field>
      <input type="hidden" [formControlName]="formControlName" />
      <div *ngIf="hasError(formControlName, 'inValidSource')" class="validation-error">
        There is already a data source with the listing number.
      </div>
    </div>

    <div class="col-md-6 pl-15 pr-0">
      <mat-form-field appearance="outline">
        <mat-label>DOM</mat-label>
        <input
          matInput
          [(ngModel)]="subjectListingHistoryDto.daysOnMarket"
          autocomplete="off"
          mask="separator.0"
          thousandSeparator=","
          separatorLimit="999999999999"
          [allowNegativeNumbers]="false"
        />
      </mat-form-field>
    </div>
  </div>
  <div class="grid-page-container">
    <div class="grid-description-row">
      <ngx-datatable
        class="material"
        [rows]="subjectListingHistoryDto.listingHistory"
        [columnMode]="ColumnMode.force"
        [headerHeight]="50"
        [footerHeight]="50"
        rowHeight="auto"
        [messages]="{ emptyMessage: 'Listing details incomplete!', totalMessage: 'total' }"
      >
        <ngx-datatable-column [name]="'Add'" [maxWidth]="50" [sortable]="false">
          <ng-template let-row="row" ngx-datatable-cell-template let-rowIndex="rowIndex">
            <em
              class="grid-selectable-icon grid-add-column fas fa-plus-circle"
              (click)="addRow(rowIndex)"
            ></em>
          </ng-template>
        </ngx-datatable-column>

        <ngx-datatable-column [name]="'Remove'" [maxWidth]="75" [sortable]="false">
          <ng-template let-row="row" ngx-datatable-cell-template let-rowIndex="rowIndex">
            <em
              class="grid-selected-icon grid-remove-column fas fa-minus-circle"
              (click)="removeRow(rowIndex)"
            ></em>
          </ng-template>
        </ngx-datatable-column>

        <ngx-datatable-column [name]="'Date'" [prop]="'date'" [sortable]="false" [minWidth]="300">
          <ng-template let-row="row" ngx-datatable-cell-template let-rowIndex="rowIndex">
            <jaro-kit-date-picker
              [form]="form"
              [(value)]="row.date"
              (changeNewDate)="changeNewDate($event, rowIndex)"
              [isRequired]="true"
              [isDefaultTouch]="true"
              [isRemoveControl]="true"
            ></jaro-kit-date-picker>
          </ng-template>
        </ngx-datatable-column>

        <ngx-datatable-column [name]="'Event'" [prop]="'event'" [sortable]="false">
          <ng-template let-row="row" ngx-datatable-cell-template let-rowIndex="rowIndex">
            <mat-form-field appearance="outline">
              <input
                matInput
                [(ngModel)]="row.event"
                autocomplete="off"
                (keyup)="updateRowValue()"
              />
            </mat-form-field>
          </ng-template>
        </ngx-datatable-column>

        <ngx-datatable-column [name]="'Price'" [prop]="'price'" [sortable]="false">
          <ng-template let-row="row" ngx-datatable-cell-template let-rowIndex="rowIndex">
            <mat-form-field appearance="outline">
              <input
                matInput
                [(ngModel)]="row.price"
                currencyMask
                mask="separator.0"
                thousandSeparator=","
                separatorLimit="999999999"
                [allowNegativeNumbers]="true"
                autocomplete="off"
                (keyup)="updateRowValue()"
              />
            </mat-form-field>
          </ng-template>
        </ngx-datatable-column>

        <ngx-datatable-footer>
          <ng-template
            ngx-datatable-footer-template
            let-rowCount="rowCount"
            let-pageSize="pageSize"
            let-selectedCount="selectedCount"
            let-curPage="curPage"
            let-offset="offset"
          >
            <div class="table-footer">
              <div class="table-footer-left">{{ rowCount }} total</div>
              <div class="table-footer-right">
                <button class="add-new-button" (click)="addRow()">
                  <em class="far fa-plus"></em>
                </button>
              </div>
            </div>
          </ng-template>
        </ngx-datatable-footer>
      </ngx-datatable>
    </div>
  </div>
</div>
