<ng-container *ngIf="form" [formGroup]="form">
  <div class="row">
    <jaro-kit-amenity-card-edit-mode
      *ngFor="let item of amenities; let i = index"
      [form]="form"
      [title]="item.title"
      [description]="item.description"
      [icon]="item.icon"
      [controlName]="item.controlName"
      [descriptionControlName]="item.descriptionControlName"
      [isNumber]="item.isNumber"
      [field]="getFormFieldConfig(item.controlName)"
      class="col-md-4"
    ></jaro-kit-amenity-card-edit-mode>
  </div>

  <div class="row">
    <div class="mt-0 mb-15 other-amenities-description">
      <halo-global-multiple-combo-box
        label="Other"
        placeholder="New Other..."
        [options]="otherOptions"
        [(value)]="other"
        [formGroup]="form"
        formControlName="additionalOtherAmenitiesDescription"
      >
      </halo-global-multiple-combo-box>
    </div>
  </div>
</ng-container>
