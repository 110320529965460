import { Component, Input } from '@angular/core';
import { FormGroup } from '@angular/forms';

@Component({
  selector: 'halo-global-narrative-comments',
  templateUrl: './narrative-comments.component.html',
  styleUrls: ['./narrative-comments.component.scss'],
})
export class NarrativeCommentsComponent {
  @Input() form: FormGroup;
}
