import { Component } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { ReportWithExtraAttributes } from 'src/app/features/report/shared/models/report-input-extra-atributes.model';

@Component({
  selector: 'jaro-kit-subject-property-address',
  templateUrl: './subject-property-address.component.html',
  styleUrls: ['./subject-property-address.component.scss'],
})
export class SubjectPropertyAddressComponent {
  form: FormGroup;
  reportDto: ReportWithExtraAttributes;

  get propertyAddress() {
    return this.reportDto.productType == '1004d'
      ? `${this.form.value['subjectStreetAddress']} ${this.form.value['subjectUnitNumber']}`
      : `${this.form.value['subjectStreetAddress']}`;
  }
}
