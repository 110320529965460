import { Confirmation } from '@abp/ng.theme.shared';
import { Component, Inject, OnInit, ViewChild } from '@angular/core';
import { PartialUpdateReportPhotoDto } from '@proxy/appraisal/report';
import { ReportPhotoOwnerType, ReportPhotoSource } from '@proxy/appraisal/report/report-photos';
import { CreateReportPhotoDto } from '@proxy/appraisal/report/report-photos/v1/models';
import { OrderAssignmentDto } from '@proxy/ascent/order/order-assignment/order-assignments';
import { ReportInputPhotoPositionType } from '@proxy/bff/activity/report-input-photos';
import { ReportInputPhotoSectionDto } from '@proxy/bff/activity/report-input-photos/v1';
import { ReportPhotoWithThumbnailDto } from '@proxy/bff/activity/report-photos/v1';
import { Guid } from 'guid-typescript';
import { Observable, bufferCount, concatMap, forkJoin, from } from 'rxjs';
import { ReportInputSectionModel } from 'src/app/features/report/shared/models/report-input-section.model';
import { EditSectionNameComponent } from 'src/app/features/shared/report-resource/edit-section-name/edit-section-name.component';
import { ReportResourceCollectionComponent } from 'src/app/features/shared/report-resource/report-resource-collection/report-resource-collection.component';
import { ReportServiceInterface } from 'src/app/interface/appraisal/report/report-service-interface';
import { ReportInputPhotoServiceInterface } from 'src/app/interface/bff/activity/report-input-photo-interface.service';
import { ReportInputPhotoSectionServiceInterface } from 'src/app/interface/bff/activity/report-input-photo-section-interface.service';
import { ReportPhotoServiceInterface } from 'src/app/interface/bff/activity/report-photo-service-interface';
import { ReportPhotoResourceModel } from 'src/app/shared/dtos/report-input-resource/report-photo-resource.model';
import { FeatureNames } from 'src/app/shared/enums/report-input.enum';
import { ReportResourceType } from 'src/app/shared/enums/report-resource-type.enum';
import InjectionSymbol from 'src/app/shared/injection/injection-symbol';
import { EditSectionModel } from 'src/app/shared/models/photo/edit-section.model';
import { PartialUpdatePhotoModel } from 'src/app/shared/models/photo/partial-update-photo.model';
import { PropertyModel } from 'src/app/shared/models/photo/property.model';
import { AddressFormatPipe } from 'src/app/shared/pipes/address-format/address-format.pipe';
import { MessageService } from 'src/app/shared/services/message.service';
import { ReportInputPhotoResourceService } from 'src/app/shared/services/photo-resource.service';

@Component({
  selector: 'jaro-kit-photos',
  templateUrl: './photos.component.html',
  styleUrls: ['./photos.component.scss'],
})
export class PhotosComponent implements OnInit {
  @ViewChild('createNewSection') createNewSection: EditSectionNameComponent;
  @ViewChild('reportResourceCollection')
  reportResourceCollection: ReportResourceCollectionComponent;

  orderAssignmentId: string;
  currentOrderAssignment: OrderAssignmentDto;
  reportVersion: number;
  reportId: string;
  isLoading: boolean = false;
  feature = FeatureNames.Photos;

  photos: ReportPhotoResourceModel[] = [];
  photoSections: ReportInputPhotoSectionDto[];
  galleryPhotos: ReportPhotoResourceModel[] = [];
  isShowPhotoGallery: boolean = false;
  isAddSection: boolean = false;
  properties: PropertyModel[] = [];
  propertyId: string = '';
  reportResourceType: ReportResourceType = ReportResourceType.Photo;
  maximumOfFileToUpload: number = 10;

  get sections(): ReportInputSectionModel[] {
    return this.photoSections.map((item) => {
      const section = {
        id: item.id,
        reportId: item.reportId,
        reportVersion: item.reportVersion,
        name: item.name,
        displayOrder: item.displayOrder,
        photos: item.photos,
      } as ReportInputSectionModel;
      return section;
    });
  }

  constructor(
    public reportInputService: ReportInputPhotoResourceService,
    @Inject(InjectionSymbol.ReportService) public reportService: ReportServiceInterface,
    @Inject(InjectionSymbol.ReportPhotoService)
    public reportPhotoService: ReportPhotoServiceInterface,
    @Inject(InjectionSymbol.ReportInputPhotoSectionService)
    public reportInputPhotoSectionService: ReportInputPhotoSectionServiceInterface,
    @Inject(InjectionSymbol.ReportInputPhotoService)
    public reportInputPhotoService: ReportInputPhotoServiceInterface,
    private addressService: AddressFormatPipe,
    private msgService: MessageService
  ) {}

  ngOnInit(): void {
    this.getAllPhotos(this.reportId, this.reportVersion, this.orderAssignmentId);
    if (this.currentOrderAssignment && this.currentOrderAssignment.orderData.address) {
      this.properties = [
        {
          displayAddress: this.addressService.transform(this.currentOrderAssignment.orderData.address)
        },
      ] as PropertyModel[];
    }
  }

  getAllPhotos(reportId: string, reportVersion: number, orderAssignmentId: string) {
    this.isLoading = true;
    const getPhotos = this.reportPhotoService.getSubjectPhotoList(
      reportId,
      reportVersion,
      orderAssignmentId
    );

    const getReportPhotos =
      this.reportInputPhotoSectionService.getReportInputPhotoSectionsByReportIdAndReportVersionAndOrderAssignmentId(
        reportId,
        reportVersion,
        this.orderAssignmentId
      );

    const subscribedPhotos = {
      photos: getPhotos,
      sections: getReportPhotos,
    };

    forkJoin(subscribedPhotos).subscribe({
      next: (results: {
        photos: ReportPhotoResourceModel[];
        sections: ReportInputPhotoSectionDto[];
      }) => {
        this.isLoading = false;

        this.photos = results.photos;
        this.photoSections = results.sections;

        this.photos.forEach((photo: ReportPhotoResourceModel) => {
          this.reportInputService.mapPhotoDtoToUI(photo);
          photo.sectionIds = [];
          photo.isInReport = false;
        });

        this.photoSections.forEach((section) => {
          this.mergeReportPhotoWithPhotoGallery(section);
        });
        this.filterPhotos();
      },
      error: (err) => this.logActionError(err),
    });
  }

  makeCoverPhoto(photo: ReportPhotoResourceModel) {
    const dataUpdate = {};

    dataUpdate['isCoverPhoto'] = true;
    photo.isCoverPhoto = true;
    this.updateReportPhoto(photo, dataUpdate);
  }

  updatePhotoTitle(photo: ReportPhotoResourceModel) {
    const dataUpdate = {};

    dataUpdate['title'] = photo.title;
    this.updateReportPhoto(photo, dataUpdate);
  }

  private updateReportPhoto(photo: ReportPhotoResourceModel, updates: Record<string, string>) {
    const partialUpdateReportPhotoRequest = {
      updates: updates,
    } as PartialUpdateReportPhotoDto;
    photo.isInReport = true;
    this.reportPhotoService
      .partialUpdate(this.reportId, this.reportVersion, photo.id, partialUpdateReportPhotoRequest)
      .subscribe({
        next: () => {
          // do nothing
        },
        error: (err) => this.logActionError(err),
      });
    this.reportInputService.syncGalleryPhoto(photo, this.photoSections, this.photos);
  }

  private filterPhotos() {
    this.galleryPhotos = this.photos.filter(
      (item) => item.photoOwnerType === ReportPhotoOwnerType.Subject
    );
  }

  openPhotoGallery(isShow: boolean) {
    this.isShowPhotoGallery = isShow;
  }

  savePhotos(photos: ReportPhotoResourceModel[]) {
    photos.forEach((photo) => {
      this.upsertReportPhoto(photo);
    });
  }

  private createReportPhoto(photo: ReportPhotoResourceModel) {
    const createReportPhotoRequest = {
      reportId: this.reportId,
      reportVersion: this.reportVersion,
      source: photo.source || ReportPhotoSource.User,
      photoOwnerType: photo.photoOwnerType || ReportPhotoOwnerType.Subject,
      externalReference: photo.externalReference,
      documentId: photo.documentId,
      fileVersion: photo.fileVersion || 1,
      isCoverPhoto: !!photo.isCoverPhoto,
      title: photo.title || '',
    } as CreateReportPhotoDto;
    this.reportPhotoService
      .createReportPhoto(this.orderAssignmentId, createReportPhotoRequest)
      .subscribe({
        next: (photoDto: ReportPhotoWithThumbnailDto) => {
          this.syncPhotoData(photo, photoDto);
        },
        error: (err) => this.logActionError(err, true),
      });
  }

  private upsertReportPhoto(photo: ReportPhotoResourceModel) {
    if (!photo.id || Guid.parse(photo.id).isEmpty()) {
      this.createReportPhoto(photo);
    } else {
      const dataUpdate = {};

      dataUpdate['title'] = photo.title;
      dataUpdate['isInReport'] = !!photo.isInReport;
      dataUpdate['isCoverPhoto'] = !!photo.isCoverPhoto;
      this.updateReportPhoto(photo, dataUpdate);
    }
  }

  addPhotosToSection(photos: ReportPhotoResourceModel[]) {
    const observableCreatePhotos = [];
    const sectionId = photos[0].currentSectionId;
    photos.forEach((photo) => {
      if (!photo.id || Guid.parse(photo.id).isEmpty()) {
        const createReportPhotoRequest = {
          reportId: this.reportId,
          reportVersion: this.reportVersion,
          source: photo.source || ReportPhotoSource.User,
          photoOwnerType: photo.photoOwnerType || ReportPhotoOwnerType.Subject,
          externalReference: photo.externalReference,
          documentId: photo.documentId,
          fileVersion: photo.fileVersion || 1,
          isCoverPhoto: !!photo.isCoverPhoto,
          title: photo.title || '',
        } as CreateReportPhotoDto;
        const subscribedCreatePhoto = this.reportPhotoService.createReportPhoto(
          this.orderAssignmentId,
          createReportPhotoRequest
        );
        observableCreatePhotos.push(subscribedCreatePhoto);
      }
    });

    if (observableCreatePhotos.length > 0) {
      this.createMultipleReportPhoto(observableCreatePhotos, photos, sectionId);
    } else {
      this.addMultiplePhotosToSection(photos, sectionId);
    }
  }

  private createMultipleReportPhoto(
    observableCreatePhotos: Observable<any>[],
    photos: ReportPhotoResourceModel[],
    sectionId: string
  ) {
    if (observableCreatePhotos.length > 0) {
      from(observableCreatePhotos)
        .pipe(
          bufferCount(10),
          concatMap((buffer) => forkJoin(buffer))
        )
        .subscribe({
          next: (reportInputPhotos: ReportPhotoWithThumbnailDto[]) => {
            reportInputPhotos.forEach((reportInputPhoto) => {
              const photo = photos.find((item) => item.documentId === reportInputPhoto.documentId);
              this.syncPhotoData(photo, reportInputPhoto);
            });
          },
          complete: () => {
            this.addMultiplePhotosToSection(photos, sectionId);
          },
          error: (err) => this.logActionError(err),
        });
    }
  }

  private syncPhotoData(
    photo: PartialUpdatePhotoModel,
    reportInputPhoto: ReportPhotoWithThumbnailDto
  ) {
    if (!photo.id) {
      this.reportInputService.mapPhotoDtoToUI(photo);
      this.photos.unshift(photo);
      if (!this.galleryPhotos.includes(photo)) {
        this.galleryPhotos.unshift(photo);
      }
    }
    photo.id = reportInputPhoto.id;
  }

  private addMultiplePhotosToSection(photos: PartialUpdatePhotoModel[], sectionId: string) {
    const defaultPosition = {
      positionType: ReportInputPhotoPositionType.Last,
    };
    const position = photos[0].position || defaultPosition;
    const reportPhotoIds = photos.map((photo) => photo.id);

    if (reportPhotoIds.length > 0) {
      const reportInputPhotoToCreates = {
        reportPhotoIds: reportPhotoIds,
        position: position,
      };

      this.reportInputPhotoService
        .addMultiplePhotosToSection(
          this.orderAssignmentId,
          this.reportId,
          this.reportVersion,
          sectionId,
          reportInputPhotoToCreates
        )
        .subscribe({
          next: () => {
            // do nothing
          },
          error: (err) => this.logActionError(err, true),
        });
      this.reportResourceCollection.refreshFilterOfPhotoGallery();
    }
  }

  removePhotoFromSection(photo: ReportPhotoResourceModel) {
    this.reportInputPhotoService
      .deleteFromSection(
        this.orderAssignmentId,
        this.reportId,
        this.reportVersion,
        photo.currentSectionId,
        photo.id
      )
      .subscribe({
        next: () => {
          // do nothing
        },
        error: (err) => this.logActionError(err, true),
      });
    this.reportInputService.removePhotoFromSection(
      photo,
      photo.currentSectionId,
      this.photoSections,
      this.photos
    );
  }

  movePhoto(photo: PartialUpdatePhotoModel) {
    const defaultPosition = {
      positionType: ReportInputPhotoPositionType.Last,
    };

    photo.position = photo.position || defaultPosition;
    const partialUpdate = { sectionId: photo.newSectionId, position: photo.position };

    this.reportInputPhotoService
      .partialUpdatePositionInSection(
        this.orderAssignmentId,
        this.reportId,
        this.reportVersion,
        photo.currentSectionId,
        photo.id,
        partialUpdate
      )
      .subscribe({
        next: () => {
          // do nothing
        },
        error: (err) => this.logActionError(err, true),
      });

    this.reportInputService.movePhoto(
      photo,
      photo.currentSectionId,
      photo.newSectionId,
      this.photoSections,
      this.photos
    );
  }

  private mergeReportPhotoWithPhotoGallery(section: ReportInputPhotoSectionDto) {
    this.reportInputService.mergeReportPhotoWithPhotoGallery(section, this.photos);
  }

  private logActionError(err: any, isReloadData?: boolean): void {
    this.isLoading = false;
    if (isReloadData) {
      this.getAllPhotos(this.reportId, this.reportVersion, this.orderAssignmentId);
    }
    this.msgService.error(err).subscribe((status: Confirmation.Status) => {
      if (status === Confirmation.Status.confirm && !isReloadData) {
        this.getAllPhotos(this.reportId, this.reportVersion, this.orderAssignmentId);
      }
    });
  }

  addNewSectionClick() {
    this.isAddSection = true;
    setTimeout(() => {
      this.createNewSection.scrollAndFocusInputSection();
    }, 0);
  }

  addNewSection(sectionName: string) {
    const sectionToCreate = {
      reportId: this.reportId,
      reportVersion: this.reportVersion,
      name: sectionName,
    };
    this.reportInputPhotoSectionService.createReportInputPhotoSection(sectionToCreate).subscribe({
      next: (photoSectionDto: ReportInputPhotoSectionDto) => {
        this.photoSections.push(photoSectionDto);
        this.isAddSection = false;
      },
      error: (err) => this.logActionError(err),
    });
  }

  cancelAddNewSection() {
    this.isAddSection = false;
  }

  updateSection(section: EditSectionModel) {
    const updates = {};
    updates['name'] = section.name;
    const updateSection = {
      updates: updates,
    };
    this.reportInputPhotoSectionService
      .partialUpdateReportInputPhotoSection(
        this.reportId,
        this.reportVersion,
        section.id,
        updateSection
      )
      .subscribe({
        next: (_photoSectionDto: ReportInputPhotoSectionDto) => {
          section.isEditSection = false;
        },
        error: (err) => this.logActionError(err),
      });
  }

  deleteSection(section: EditSectionModel) {
    this.reportInputPhotoSectionService
      .deleteReportInputPhotoSection(this.reportId, this.reportVersion, section.id)
      .subscribe({
        next: () => {
          section.isEditSection = false;

          const photos = section.photos;
          section.photos = [];

          photos.forEach((photo) => {
            this.reportInputService.removePhotoFromSection(
              photo,
              section.id,
              this.photoSections,
              this.photos
            );
          });

          const index = this.photoSections.findIndex((item) => item.id === section.id);
          this.photoSections.splice(index, 1);
          this.reportResourceCollection.refreshFilterOfPhotoGallery();
        },
        error: (err) => this.logActionError(err),
      });
  }
}
