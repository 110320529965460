import { Injectable, Pipe, PipeTransform } from '@angular/core';
import { AddressDto } from '@proxy/ascent/order/order-assignment/order-assignments';

@Injectable({
  providedIn: 'root',
})
@Pipe({
  name: 'addressFormat',
})
export class AddressFormatPipe implements PipeTransform {
  transform(address: AddressDto): string {
    return `${address?.street}, ${address?.city}`;
  }
}
