<div class="grid-page-container">
  <div class="container">
    <div class="comparables-grid-panel-first row">
      <div class="col-12">
        <jaro-kit-market-comparables-selected-grid
          [selectedComparables]="selectedComparables"
          [isLoading]="isLoading"
          (onPropertyEditRequested)="editProperty($event)"
          (onComparableRemoved)="removeComparable($event)"
          (onComparableSaveRequested)="saveComparables()"
          [hasSelectedComparableChanges]="hasSelectedComparableChanges"
          (onReSelectedComparables)="reSelectedComparables()"
        ></jaro-kit-market-comparables-selected-grid>
      </div>
    </div>
    <div class="comparables-grid-panel-last row">
      <div class="col-12">
        <jaro-kit-market-comparables-search-grid
          [defaultSearchResponse]="defaultSearchResponse"
          [selectedComparables]="selectedComparables"
          [filteredSearchResponse]="filteredSearchResponse"
          [isLoading]="isLoading"
          (onPropertyEditRequested)="editProperty($event)"
          (onComparableAdded)="addComparable($event)"
          (onComparableRemoved)="removeComparable($event)"
        ></jaro-kit-market-comparables-search-grid>
      </div>
    </div>
  </div>
</div>
