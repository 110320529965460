import { Component, Input } from '@angular/core';
import { CommentaryAttributeModel } from '../../../models/property-attribute/commentary-attribute-model';

@Component({
  selector: 'jaro-kit-market-cleanup-property-commentary-article',
  templateUrl: './market-cleanup-property-commentary-article.component.html',
  styleUrls: ['./market-cleanup-property-commentary-article.component.scss'],
})
export class MarketCleanupPropertyCommentaryArticleComponent {
  @Input() attribute: CommentaryAttributeModel;    

  isOpenDropdown: boolean = false;

  toggleOpenDropdown(_event: any) { 
    this.isOpenDropdown = !this.isOpenDropdown;     
  }
}
