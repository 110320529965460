<div class="raw-data-panel" *ngIf="isSubject">
  <div *ngIf="outputArrayPubRec.length > 1" class="raw-data-item-container">
    <jaro-kit-raw-data-item [data]="outputArrayPubRec"></jaro-kit-raw-data-item>
  </div>
  <div *ngIf="outputArrayMls.length > 1" class="raw-data-item-container">
    <jaro-kit-raw-data-item [data]="outputArrayMls"></jaro-kit-raw-data-item>
  </div>
  <div *ngIf="outputArrayMaps.length > 1" class="raw-data-item-container">
    <jaro-kit-raw-data-item [data]="outputArrayMaps"></jaro-kit-raw-data-item>
  </div>
  <div *ngIf="outputArrayZonedata.length > 1" class="raw-data-item-container">
    <jaro-kit-raw-data-item [data]="outputArrayZonedata"></jaro-kit-raw-data-item>
  </div>
  <div *ngIf="outputArrayTransferHistories.length > 1" class="raw-data-item-container">
    <jaro-kit-raw-data-item [data]="outputArrayTransferHistories"></jaro-kit-raw-data-item>
  </div>
</div>

<div class="raw-data-panel" *ngIf="!isSubject">
  <div *ngIf="outputArrayPubRec.length > 1" class="raw-data-item-container">
    <jaro-kit-comparable-data-reference-item [data]="outputArrayPubRec"></jaro-kit-comparable-data-reference-item>
  </div>
  <div *ngIf="outputArrayMls.length > 1" class="raw-data-item-container">
    <jaro-kit-comparable-data-reference-item [data]="outputArrayMls"></jaro-kit-comparable-data-reference-item>
  </div>
  <div *ngIf="outputArrayMaps.length > 1" class="raw-data-item-container">
    <jaro-kit-comparable-data-reference-item [data]="outputArrayMaps"></jaro-kit-comparable-data-reference-item>
  </div>
  <div *ngIf="outputArrayZonedata.length > 1" class="raw-data-item-container">
    <jaro-kit-comparable-data-reference-item [data]="outputArrayZonedata"></jaro-kit-comparable-data-reference-item>
  </div>
  <div *ngIf="outputArrayTransferHistories.length > 1" class="raw-data-item-container">
    <jaro-kit-comparable-data-reference-item [data]="outputArrayTransferHistories"></jaro-kit-comparable-data-reference-item>
  </div>
</div>