import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root',
})
export class Feature {
  static getLocationHref() {
    return location.href || '';
  }

  static getFeatureFlags(){
    return environment.featureFlags || [];
  }

  public static isEnabledFeature(key: string): boolean {
    const overrideFeatureFlags = this.getOverrideFeatureFlags(key);
    if (overrideFeatureFlags !== null) {
      return overrideFeatureFlags;
    }
    const feature = Feature.getFeatureFlags().find((item) => item.key == key);
    return /true/i.test((feature?.isEnabled || '').toString());
  }

  public static isEnabledFeatureWithProductType(key: string, productType): boolean {
    const overrideFeatureFlags = this.getOverrideFeatureFlags(key);
    if (overrideFeatureFlags !== null) {
      return overrideFeatureFlags;
    }
    const feature = Feature.getFeatureFlags().find((item) => item.key == key);
    const productList = (feature?.productList || '').split(',');
    return productList.includes(productType);
  }

  private static getOverrideFeatureFlags(key: string) {
    const overrideFeatureFlags = Feature.getFeatureFlags().find(
      (item) => item.key === 'OverrideFeatureFlags'
    );
    if (/true/i.test((overrideFeatureFlags?.isEnabled || '').toString())) {
      const url = new URL(Feature.getLocationHref());
      const featureFlagsEnabled = url.searchParams.get('featureFlagsEnabled') || '';
      const featureFlagsDisabled = url.searchParams.get('featureFlagsDisabled') || '';
      if (featureFlagsEnabled.toLowerCase().includes(key.toLowerCase())) {
        return true;
      }

      if (featureFlagsDisabled.toLowerCase().includes(key.toLowerCase())) {
        return false;
      }
    }
    return null;
  }
}
