<ng-container *ngIf="form" [formGroup]="form">
    <div class="row">
        <div class="col-md-12 ps-0 pe-0">
            <div class="report-input-form-field">
                <mat-label>Occupant</mat-label>
                <div>
                    <mat-radio-group id="subjectOccupantType" formControlName="subjectOccupantType">
                        <mat-radio-button
                        *ngFor="let option of occupantOptions"
                        [value]="option.value"
                        class="report-input-radio-button"
                        >{{ option.value }}</mat-radio-button
                        >
                    </mat-radio-group>
                    <jaro-kit-validation-message [form]="form" [controlName]="'subjectOccupantType'" [isRequired]="true"></jaro-kit-validation-message>
                </div>
            </div>
        </div>
    </div>
</ng-container>