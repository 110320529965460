
import { Component, EventEmitter, Inject, Input, OnChanges, OnDestroy, OnInit, Output, SimpleChanges } from '@angular/core';
import { AbstractControl, FormControl, FormGroup, Validators } from '@angular/forms';
import { MAT_MOMENT_DATE_FORMATS, MomentDateAdapter } from '@angular/material-moment-adapter';
import { DateAdapter, MAT_DATE_FORMATS, MAT_DATE_LOCALE } from '@angular/material/core';
import { MatDatepickerInputEvent } from '@angular/material/datepicker';
import { Guid } from 'guid-typescript';
import * as moment from 'moment';



export class CustomDateFormat {

  format = 'MM/DD/YYYY';

  constructor(){}
  get display() {
    return {
      dateInput: this.format,
      monthYearLabel: 'MMM YYYY',
      dateA11yLabel: 'LL',
      monthYearA11yLabel: 'MMMM YYYY'
    }
  }

  get parse() {
    return {
      dateInput: this.format
    }
  }
}

@Component({
  selector: 'jaro-kit-date-picker',
  templateUrl: './date-picker.component.html',
  styleUrls: ['./date-picker.component.scss'],
  providers: [
    { provide: DateAdapter, useClass: MomentDateAdapter, deps: [MAT_DATE_LOCALE] },
    { provide: MAT_DATE_FORMATS, useClass: CustomDateFormat },
  ],
})
export class DatePickerComponent implements OnInit, OnDestroy, OnChanges {
  @Input() form: FormGroup;
  @Input() isRequired: boolean;
  @Input() titleDate: string;
  @Input() dateControl: string;
  @Input() formatDate: string = 'MM/DD/YYYY';
  @Input() value: any;
  @Input() isDefaultTouch: string;
  @Input() isRemoveControl: boolean;
  @Input() id: string;
  @Output() changeNewDate: EventEmitter<Date | string> = new EventEmitter<Date | string>();

  dateFormControl: AbstractControl;

  constructor(@Inject(MAT_DATE_FORMATS) public config: CustomDateFormat){}


  ngOnInit(): void {
    this.config.format = this.formatDate;
    if (!this.dateControl) {
      this.dateControl = `Date${Guid.create()}`;
      this.form.addControl(this.dateControl, new FormControl(null));
    } else {
      this.value = this.form.value[this.dateControl];
    }
    this.dateFormControl = this.form?.controls[this.dateControl];
    if (this.value && this.dateFormControl) {
      const date = moment.isMoment(this.value) ? this.value : moment(this.value, this.formatDate);
      this.dateFormControl.setValue(date);
    }

    if (this.dateFormControl) {
      if(this.isRequired) {
        this.dateFormControl.setValidators(Validators.required);
      }
      if (this.isDefaultTouch) {
        this.dateFormControl.markAsTouched();
      }
    }
  }

  ngOnDestroy(): void {
    if (this.isRemoveControl) {
      this.form.removeControl(this.dateControl);
    }
  }

  ngOnChanges(changes: SimpleChanges) {
    if(changes.isRequired ) {
      if(this.isRequired){
        this.dateFormControl.setValidators(Validators.required);
      } else {
        this.dateFormControl.setValidators(null);
      }
      this.dateFormControl.updateValueAndValidity();
    }
    
}

  changeDate(event: MatDatepickerInputEvent<Date>) {
    this.value = moment.isMoment(event.value) ? event.value.format(this.formatDate) : event.value;
    this.changeNewDate.emit(this.value);
  }

  public hasError = (controlName: string, errorName: string) => {
    if (this.form) {
      const control = this.form.controls[controlName];
      return (
        control &&
        control.invalid &&
        (control.dirty || control.touched) &&
        control.errors[errorName] != undefined
      );
    }
    return false;
  };
}
