import { LocalizationPipe } from '@abp/ng.core';
import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { MatMenuModule } from '@angular/material/menu';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatTreeModule } from '@angular/material/tree';
import { PropertyService } from '@proxy/appraisal/property/v1';
import { PipesModule } from 'src/app/shared/pipes/pipes.module';
import { SharedModule } from 'src/app/shared/shared.module';
import { PropertyJobServiceAdapter } from '../../proxy-adapter/property/property-job/property-jobs-adapter.service';
import { PropertyRecordServiceAdapter } from '../../proxy-adapter/property/property-record/property-record-adapter.service';
import InjectionSymbol from '../../shared/injection/injection-symbol';
import { RawDataViewRoutingModule } from './raw-data-view-routing.module';
import { RawDataViewComponent } from './raw-data-view/raw-data-view.component';

@NgModule({
  declarations: [
    RawDataViewComponent
  ],
  providers: [
    LocalizationPipe,
    { provide: InjectionSymbol.PropertyService, useClass: PropertyService },
    { provide: InjectionSymbol.PropertyRecordService, useClass: PropertyRecordServiceAdapter },
    { provide: InjectionSymbol.PropertyJobService, useClass: PropertyJobServiceAdapter },
  ],
  imports: [
    CommonModule,  
    MatTreeModule,
    MatIconModule,
    MatMenuModule,
    MatButtonModule,
    MatProgressSpinnerModule,
    RawDataViewRoutingModule,
    PipesModule,
    SharedModule
  ],
})
export class RawDataViewModule { }
