import { Component, Inject, OnInit } from '@angular/core';
import { ContentServiceInterface } from 'src/app/interface/content/content-service-interface';
import { ItemData } from 'src/app/shared/components/multiple-combo-box/multiple-combo-box.component';
import InjectionSymbol from 'src/app/shared/injection/injection-symbol';
import { EventService } from 'src/app/shared/services/event.service';
import { ReportInputBaseComponent } from '../base-component/base-component';
@Component({
  selector: 'jaro-kit-date',
  templateUrl: './checkbox.component.html',
  styleUrls: ['./checkbox.component.scss'],
})
export class ReportInputCheckBoxComponent extends ReportInputBaseComponent implements OnInit {

  options: ItemData[] = [];

  constructor(
    @Inject(InjectionSymbol.ContentService)
    public contentService: ContentServiceInterface,
    
    @Inject(InjectionSymbol.EventService)
    protected eventService: EventService
  ) {
    super(eventService);
  }

  ngOnInit(): void {
    const metadata = this.field.metadata;
    if (metadata?.keyContentService) {
      this.options = this.contentService.getContentByKey(
        metadata.keyContentService,
        metadata.hasOtherOption,
        metadata.hasNone
      );
    } else if (metadata?.options) {
      this.options = metadata.options;
    }
  }

  onChange($event) {
    this.form.controls[this.field.formControlName].setValue($event.value);
    super.onChange($event);
  }
}
