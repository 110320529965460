<div
  class="drag-area"
  [ngClass]="{ 'drag-area': true, 'drag-area-disabled': isUploading }"
  haloGlobalMultipleUploaderZone
  (fileDropped)="uploadFiles($event)"
>
  <span class="report-input-label" >
    <em class="fa-regular fa-cloud-arrow-up"></em> Drag and drop {{ title }} to upload or
    <span class="text-upload-file" (click)="uploadFileClick()">choose from a different source...</span>
  </span>

  <input
    id="fileInput"
    class="input-upload-file mouse-cursor-pointer"
    type="file"
    #fileInput
    [accept]="typeAccept"
    [multiple]="isMultiple"
    (change)="uploadFiles($event.target.files)"
    [disabled]="isUploading"
  />
</div>
