<halo-global-popup [dialogRef]="dialogRef" [isHideClose]="true" [title]="'Add New Document'">
  <div class="document-settings-container">

    <div>
      <mat-form-field appearance="outline" [floatLabel]="true">
        <mat-label>Document Title</mat-label>
        <input matInput [(ngModel)]="settings.title" >
      </mat-form-field>

      <mat-form-field appearance="outline" [floatLabel]="true">
        <mat-label>Document Category</mat-label>
        <mat-select required
          placeholder="Document Category"
          [(ngModel)]="settings.documentCategory"
        >
          <mat-option *ngFor="let item of documentCategories" [value]="item">{{ item }}</mat-option>
        </mat-select>
      </mat-form-field>

    </div>

    <div class="button-panel">
      <button class="jaro-kit-secondary-button mouse-cursor-pointer" (click)="close()">
        Cancel
      </button>
      <button
        class="jaro-kit-primary-button mouse-cursor-pointer"
        (click)="upload()"
        [disabled]="isUploadDisabled"
      >
        Upload
      </button>
    </div>
  </div>
</halo-global-popup>
