<div class="report-input-documents-page" cdkDropListGroup>
  <mat-spinner
    *ngIf="isLoading"
    value="100"
    diameter="100"
    strokeWidth="5"
    class="action-loading-spinner"
  ></mat-spinner>

  <ng-container *ngIf="!isLoading">
    <div class="add-section-title mouse-cursor-pointer">
      <span (click)="addNewSectionClick()">Add Section</span>
    </div>

    <jaro-kit-document-sections
      [orderAssignmentId]="orderAssignmentId"
      [reportId]="reportId"
      [reportVersion]="reportVersion"
      (onAddDocumentToSection)="addNewDocumentToSection($event)"
      (onAddExistingDocumentToSection)="addNewDocumentToSection($event)"
      [documentSections]="documentSections"
      (onUpdateSection)="updateSection($event)"
      (onDeleteSection)="deleteSection($event)"
      (onRemoveDocument)="removeDocument($event)"
      (onReselectedPages)="reselectedPages($event)"
    ></jaro-kit-document-sections>

    <div class="add-section-container" *ngIf="isAddSection">
      <jaro-kit-edit-section-name
        #createNewSection
        [sections]="documentSections"
        (onAddNewSection)="addNewSection($event)"
        (onCancelAddNewSection)="cancelAddNewSection()"
      ></jaro-kit-edit-section-name>
    </div>
  </ng-container>

  <jaro-kit-document-library-button
    *ngIf="!isShowDocumentLibrary"
    [isLoading]="isLoading"
    (onOpenDocumentLibrary)="openDocumentLibrary($event)"
  ></jaro-kit-document-library-button>

  <jaro-kit-document-library
    #documentLibrary
    *ngIf="isShowDocumentLibrary"
    [orderAssignmentId]="orderAssignmentId"
    [reportId]="reportId"
    [reportVersion]="reportVersion"
    [items]="documentsLibrary"
    [isLoading]="isLoading"
    (onOpenDocumentLibrary)="openDocumentLibrary($event)"
    (onSoftDeleteDocument)="refreshDocuments($event)"
  ></jaro-kit-document-library>
</div>
