import { Injectable, Pipe, PipeTransform } from '@angular/core';
@Injectable({
  providedIn: 'root',
})
@Pipe({
  name: 'arrayFormat',
})
export class ArrayFormatPipe implements PipeTransform {
  transform(value: string[], separator = ', '): string {
    if (Array.isArray(value)) {
      return value.join(separator);
    }
    return null;
  }
}
