import { AgmInfoWindow, AgmMap, AgmMarker, AgmPolygon, MapsAPILoader } from '@agm/core';
import { LatLng, LatLngLiteral } from '@agm/core/services/google-maps-types';
import { OverlayType } from '@agm/drawing';
import { Component, EventEmitter, Input, OnInit, Output, QueryList, ViewChild, ViewChildren } from '@angular/core';
import { faHouse, faLocationDot } from "@fortawesome/free-solid-svg-icons";
import { GeoLocation, GeoPolygon } from '@proxy/property/property-job/property-jobs';
import { BehaviorSubject } from 'rxjs/internal/BehaviorSubject';
import { PropertySearchModel, PropertySearchResponse } from 'src/app/proxy-adapter/property/property-job/models';
import { Coordinate } from '../market-base.component';

// Shows the subject and search results on the map for the latitude and longitude coordinates provided
//
// If either the subject latitude or longitude is not zero or is null or undefined
// then the fallback subject address is searched for using a google api and the resultant
// latitude and longitude are used for the subject on the map.
@Component({
  selector: 'jaro-kit-market-map',
  templateUrl: './market-map.component.html',
  styleUrls: ['./market-map.component.scss'],
})
export class MarketMapComponent implements OnInit {


  @Input() defaultSearchResponse: BehaviorSubject<PropertySearchResponse>;
  @Input() filteredSearchResponse: BehaviorSubject<PropertySearchResponse>;
  @Input() subjectAddressLine: string;
  @Input() subjectGeolocation: Coordinate;
  @Input() isLoading: boolean = true;
  @Input() areDefaultMapMarkersVisible: boolean = true;
  @Input() areFilteredMapMarkersVisible: boolean = true;
  @Output() onPolygonsUpdated = new EventEmitter<GeoPolygon[]>();
  @Output() onPropertyEditRequested = new EventEmitter<string>();

  @ViewChild('AgmMap') agmMap: AgmMap;
  @ViewChildren(AgmPolygon) public polygonRefs!: QueryList<AgmPolygon>;
  previousInfoWindow: AgmInfoWindow;
  mapRef!: AgmMap;
  activePolygonIndex!: number;
  drawingMode: any = null;
  defaultProperties: PropertySearchModel[];
  filteredProperties: PropertySearchModel[];
  defaultSubtractFilteredProperties: PropertySearchModel[];
  remainingFilteredProperties: PropertySearchModel[];

  subjectMarkerSymbol: google.maps.Symbol = {
    path: faHouse.icon[4] as string,
		fillColor: 'Red',
		fillOpacity: 1,
		strokeColor: 'black',
		strokeWeight: 1,
    scale: 0.07,
  };

  unselectedPropertyMarkerSymbol: google.maps.Symbol = {
    path: faLocationDot.icon[4] as string,
		fillColor: '#7F7F7F',
    fillOpacity: 1,
		strokeColor: 'black',
		strokeWeight: 1,
    scale: 0.06,
  };

  filteredPropertyMarkerSymbol: google.maps.Symbol = {
    path: faLocationDot.icon[4] as string,
		fillColor: '#4169e1', // Royal Blue for web
		fillOpacity: 1,
		strokeColor: 'black',
		strokeWeight: 1,
    scale: 0.06,
  };

  drawingControlOptions: any = {
    // ControlPosition.TOP_CENTER commented out to prevent error:
    //   Module not found: Error: Can't resolve '@agm/core/services/google-maps-types'
    position: 2, //ControlPosition.TOP_CENTER,
    drawingModes: [ OverlayType.POLYGONE
    ]
  }

  polygonOptions = {
    fillOpacity: 0.3,
    fillColor: '#4169e1',
    strokeColor: '#4169e1',
    strokeWeight: 2,
    draggable: true,
    editable: true
  }

  polygons: LatLngLiteral[][] = [
  ]

  constructor(private apiloader: MapsAPILoader) {}

  ngOnInit()
  {
    this.defaultSearchResponse.subscribe((response: PropertySearchResponse) =>
    {
      if(!response)
          return;

      this.defaultProperties = JSON.parse(JSON.stringify(response.properties)) as PropertySearchModel[];
      this.updateSelection();
    });

    this.filteredSearchResponse.subscribe((response: PropertySearchResponse) =>
    {
      if(!response)
          return;

      this.filteredProperties = JSON.parse(JSON.stringify(response.properties)) as PropertySearchModel[];
      this.updateSelection();
    });

    if (!this.isSubjectGeometryPopulated() && this.subjectAddressLine)
    {
      this.populateSubjectGeometryFromGoogleSearch(this.subjectAddressLine);
    }
  }



  onLoadMap($event: AgmMap) {
    this.mapRef = $event;
  }

  markerClicked(agmMarker: AgmMarker): void {
    this.previousInfoWindow?.close()
      .catch(e => console.log(e));

    if (agmMarker.infoWindow.length) {
      this.previousInfoWindow = agmMarker.infoWindow.first;
    }
  }

  mapClicked(): void {
    this.previousInfoWindow?.close()
      .catch(e => console.log(e));
  }

  onOverlayComplete($overlayEvent: any) {
    this.drawingMode = this.drawingMode === null ? '' : null;
    if ($overlayEvent.type === OverlayType.POLYGONE) {
      const newPolygon = $overlayEvent.overlay.getPath()
        .getArray()
        .map((latLng: LatLng) => ({ lat: latLng.lat(), lng: latLng.lng() }))

      // start and end point should be same for valid geojson
      const startPoint = newPolygon[0];
      newPolygon.push(startPoint);
      $overlayEvent.overlay.setMap(null);
      this.polygons = [...this.polygons, newPolygon];
      this.emitPolygonsUpdated();
    }
  }

  onClickPolygon(index: number) {
    this.activePolygonIndex = index;
  }

  onEditPolygon(index: number) {
    const allPolygons = this.polygonRefs.toArray();
    allPolygons[index].getPath()
      .then((path: Array<LatLng>) => {
        this.polygons[index] = path.map((latLng: LatLng) => ({
          lat: latLng.lat(),
          lng: latLng.lng()
        }));
        this.emitPolygonsUpdated();
      })
  }

  public editProperty(id: string) {
    if(this.isLoading)
      return;

    this.onPropertyEditRequested.emit(id);
  }

  onDeleteDrawing() {
    this.polygons = this.polygons.filter((polygon, index) => index !== this.activePolygonIndex)
    this.emitPolygonsUpdated();
  }

  // Test for >= 0 as 0 is valid for both subject latitude and longitude
  private isSubjectGeometryPopulated(): boolean {
    return !(typeof this.subjectGeolocation?.latitude === 'undefined'  ||
            this.subjectGeolocation?.latitude   === null ||
            typeof this.subjectGeolocation?.longitude === 'undefined' ||
            this.subjectGeolocation?.longitude === null);
  }

  private populateSubjectGeometryFromGoogleSearch(addressLine: string): void {
    // Continue to find the latitude and longitude for the subject using the address
    this.apiloader.load().then(() => {
      const geocoder = new window['google'].maps.Geocoder();
      geocoder.geocode(
        {
          address: addressLine,
        },
        (r) => {
          this.subjectGeolocation.latitude = r[0].geometry.location.lat();
          this.subjectGeolocation.longitude = r[0].geometry.location.lng();
        }
      );
    }).catch((err) => {
      console.log(err);
    });
  }

  private updateSelection() {
    if(!this.defaultProperties || !this.filteredProperties)
      return;

    this.defaultSubtractFilteredProperties = (this.defaultProperties.length !== this.filteredProperties.length)
                                  ? this.defaultProperties.filter(prop => !this.filteredProperties.map(p => p.id).includes(prop.id)) : this.defaultProperties;


    this.remainingFilteredProperties = this.defaultProperties.filter(prop => !this.defaultSubtractFilteredProperties.map(p => p.id).includes(prop.id));
  }

  private emitPolygonsUpdated() {
    const geoPolygons = this.mapLatLngArrayToGeoPolygonArray(this.polygons);
    this.onPolygonsUpdated.emit(geoPolygons);
  }

  private mapLatLngArrayToGeoPolygonArray(polygons: LatLngLiteral[][]): GeoPolygon[] {
    if(!polygons || polygons.length === 0)
      return [] as GeoPolygon[]

    const geoPolygons = [] as GeoPolygon[];
    for(let poly of polygons) {
      const geoPoly = this.mapPolygonToGeoPolygon(poly);

      if(geoPoly?.geoPoints?.length === 0)
        continue;

      geoPolygons.push(geoPoly);
    }

    return geoPolygons;
  }

  private mapPolygonToGeoPolygon(polygon: LatLngLiteral[]): GeoPolygon {
    const geoPoly = {
      geoPoints: []
    } as GeoPolygon;

    if(!polygon || polygon.length === 0)
        return geoPoly;

    for(let point of polygon) {
      if(!point)
        continue;

      const geoLoc = this.mapLatLngToGeoLocation(point);
      if(!geoLoc)
        continue;

      geoPoly.geoPoints.push(geoLoc);
    }

    return geoPoly;
  }

  private mapLatLngToGeoLocation(point: LatLngLiteral) {
    const geoLocation = {
      latitude: point.lat,
      longitude: point.lng
    } as GeoLocation;

    return geoLocation;
  }
}
