import { Component, Inject, Input } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { ContentServiceInterface } from 'src/app/interface/content/content-service-interface';
import { ItemData } from 'src/app/shared/components/multiple-combo-box/multiple-combo-box.component';
import InjectionSymbol from 'src/app/shared/injection/injection-symbol';

@Component({
  selector: 'jaro-kit-appliances-edit-mode',
  templateUrl: './appliances-edit-mode.component.html',
  styleUrls: ['./appliances-edit-mode.component.scss'],
})
export class AppliancesEditModeComponent {
  @Input() form: FormGroup;

  appliances = null;
  appliancesOptions: ItemData[] = [];

  constructor(
    @Inject(InjectionSymbol.ContentService)
    public contentService: ContentServiceInterface
  ) {
    this.appliancesOptions = this.contentService.getContentByKey('appliances');
  }
}
