<div class="order-search-results-container">
  <div class="row m-0">
    <div class="col-12 p-0">
      <mat-form-field floatPlaceholder="never" floatLabel="never" appearance="none">
        <input
          matInput
          autocomplete="off"
          placeholder="Search by Customer Order ID"
          (keydown.enter)="applyFilter($event.target.value)"
          [(ngModel)]="selectedSearchValue"
        />
        <mat-icon
          matSuffix
          class="mr-3 order-search-icon"
          (click)="applyFilter(selectedSearchValue)"
        >
          <em class="fas fa-search"></em>
        </mat-icon>
      </mat-form-field>
    </div>
  </div>
  <jaro-kit-order-search-results-panel
    [isLoading]="isLoading"
    [searchResults]="searchResults"
    (onClick)="onClickAction($event)"
  ></jaro-kit-order-search-results-panel>
</div>
