import { Injectable } from '@angular/core';
import { ProductServiceInterface } from './products-service-interface';
import { ProductPrototypes } from './products.prototype';

@Injectable({
  providedIn: 'root',
})
export class ProductService implements ProductServiceInterface {
  getProductType(name: string): string {
    const productResult = ProductPrototypes.find((product) => product.name == name);
    return productResult?.productType || '';
  }
}
