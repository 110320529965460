import { Injectable, Pipe, PipeTransform } from '@angular/core';
@Injectable({
  providedIn: 'root',
})
@Pipe({
  name: 'pageRange',
})
export class PageRangePipe implements PipeTransform {
  transform(value: number[]): string {
    let result = 'All pages';
    if (value && Array.isArray(value) && value.length > 0) {
      return this.getPageRange(value);
    }
    return result;
  }

  private getPageRange(value: number[]): string {
    const sortArr = [...value].sort((a, b) => a - b);
    let lastNumber = sortArr[0];
    let result = sortArr.length > 1 ? `Pages ${lastNumber} ` : `Page ${lastNumber} `;
    let isNewRange = true;
    for (let index = 1; index < sortArr.length; index++) {
      let currentNumber = sortArr[index];
      if (currentNumber === lastNumber + 1) {
        isNewRange = false;

        // check last item
        if (index === sortArr.length - 1) {
          result += `- ${currentNumber} `;
        }
      } else {
        result += isNewRange ? `, ${currentNumber} ` : `- ${lastNumber}, ${currentNumber} `;
        isNewRange = true;
      }
      lastNumber = currentNumber;
    }
    return result;
  }
}
