import { Confirmation, ConfirmationService } from '@abp/ng.theme.shared';
import { HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { Observable } from 'rxjs';
import { AlertComponent } from '../components/message/confirmation/alert/alert.component';
import { ConfirmationComponent } from '../components/message/confirmation/confirmation.component';
import { AlertType } from '../providers/alert-type/alert-type';

@Injectable({
  providedIn: 'root',
})
export class MessageService {
  constructor(private confirmation: ConfirmationService, public matDialog: MatDialog) {}

  confirm(
    title = '',
    message = '',
    options?: Confirmation.Options
  ): Observable<Confirmation.Status> {
    const dialogConfig = new MatDialogConfig();
    try {
      // It is ineffective when the message is unavailable.
      this.confirmation.clear();
    } catch (e) {
      console.log(e);
    }

    dialogConfig.data = {
      title: title,
      message: message,
      isVisibleCancelBtn: !options?.hideCancelBtn,
      isVisibleYesBtn: !options?.hideYesBtn,
      cancelText: options?.cancelText,
      yesText: options?.yesText,
      isVisibleClose: options?.dismissible,
      type: AlertType.error,
    };
    dialogConfig.panelClass = 'confirm-modal';
    return this.matDialog.open(ConfirmationComponent, dialogConfig).afterClosed();
  }

  error(
    err: any,
    title?: string,
    message?: string,
    _options?: Confirmation.Options,
    isKeepPreviousDialog?: boolean
  ): Observable<Confirmation.Status> {
    const dialogConfig = new MatDialogConfig();
    try {
      this.confirmation.clear();
    } catch (e) {
      console.log(e);
    }

    let msg = 'An internal error has occurred during your request ';
    if (typeof err === 'string') {
      msg = err;
    } else if (err instanceof Error) {
      msg = err.message;
    } else if (err instanceof HttpErrorResponse) {
      msg = err.error.error.message;
    }
    console.error('Observer got an error: ' + msg);

    dialogConfig.data = {
      type: AlertType.error,
      title: title || 'An error has occurred!',
      message: message || msg,
    };
    dialogConfig.panelClass = 'alert-modal';
    if (!isKeepPreviousDialog) {
      this.matDialog.closeAll();
    }
    return this.matDialog.open(AlertComponent, dialogConfig).afterClosed();
  }

  notifyMessage(
    title: string,
    message: string,
    isKeepPreviousDialog = true
  ): Observable<Confirmation.Status> {
    const dialogConfig = new MatDialogConfig();
    try {
      this.confirmation.clear();
    } catch (e) {
      console.log(e);
    }
    dialogConfig.data = {
      type: AlertType.notify,
      title: title || '',
      message: message || '',
    };
    dialogConfig.panelClass = 'alert-modal';
    if (!isKeepPreviousDialog) {
      this.matDialog.closeAll();
    }
    return this.matDialog.open(AlertComponent, dialogConfig).afterClosed();
  }

  successAlert(
    title: string,
    message: string,
    isKeepPreviousDialog = true
  ): Observable<Confirmation.Status> {
    const dialogConfig = new MatDialogConfig();
    try {
      this.confirmation.clear();
    } catch (e) {
      console.log(e);
    }
    dialogConfig.data = {
      type: AlertType.success,
      title: title || '',
      message: message || '',
    };
    dialogConfig.panelClass = 'alert-modal';
    if (!isKeepPreviousDialog) {
      this.matDialog.closeAll();
    }
    return this.matDialog.open(AlertComponent, dialogConfig).afterClosed();
  }
}
