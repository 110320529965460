import { mapEnumToOptions } from '@abp/ng.core';

export enum ReportPhotoSource {
  Unknown = 0,
  Inspection = 1,
  MLS = 2,
  User = 3,
}

export const reportPhotoSourceOptions = mapEnumToOptions(ReportPhotoSource);
