<div class="listing-history-1004">
  <ng-container *ngIf="form">
    <jaro-kit-listing-history-item-edit-mode
      *ngFor="let listingHistory of listingHistoryGroups; let index = index; trackBy: trackByGuid"
      [(subjectListingHistoryDto)]="listingHistoryGroups[index]"
      [listingHistoryGroups]="listingHistoryGroups"
      [form]="form"
      (onRemoveListingHistory)="removeListingHistory($event)"
    ></jaro-kit-listing-history-item-edit-mode>
  </ng-container>

  <div class="empty-panel" *ngIf="listingHistoryGroups.length === 0">
    <em class="fas fa-arrow-right" aria-hidden="true"></em>
    <div class="detail">
      <span class="title">No Listing History</span>
      <span class="description">No known listing for past 12 months</span>
    </div>
  </div>

  <div class="listing-history-footer">
    <button class="add-new-button" (click)="addListingHistoryGroup()">
      Add Listing History Group
    </button>
  </div>
</div>
