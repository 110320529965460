import {
  ListService,
  LocalizationPipe,
} from '@abp/ng.core';
import { Component, EventEmitter, HostListener, Input, OnInit, Output } from '@angular/core';
import { ComparableDto } from '@proxy/appraisal/comparables/comparables/v1/models';
import { BehaviorSubject, Observable, of } from 'rxjs';
import { PropertySearchModel } from 'src/app/proxy-adapter/property/property-job/models';

@Component({
  selector: 'jaro-kit-market-comparables-selected-grid',
  templateUrl: './market-comparables-selected-grid.component.html',
  styleUrls: ['./market-comparables-selected-grid.component.scss'],
  providers: [ListService, LocalizationPipe],
})
export class MarketComparablesSelectedGridComponent implements OnInit {
  @Input() selectedComparables: BehaviorSubject<[PropertySearchModel, ComparableDto][]>;
  @Input() isLoading: boolean = true;
  @Input() hasSelectedComparableChanges: boolean = false;
  @Output() onPropertyEditRequested = new EventEmitter<string>();
  @Output() onComparableRemoved = new EventEmitter<string>();
  @Output() onComparableSaveRequested = new EventEmitter();
  @Output() onReSelectedComparables = new EventEmitter();

  readonly minWidthToFitAllColumns = 1625;
  readonly minAddressColumnWidth = 200;
  footerHeight = 80;
  offset = 0;
  pageSize = 5;
  measuredGridWidth: number = this.minWidthToFitAllColumns;
  defaultSort = [{ prop: 'index', dir: 'asc' }];
  sortKey: string = "";
  sortOrder: string = "";
  isDefaultClass = true;
  projectedComparables: Observable<PropertySearchModel[]> = of([] as PropertySearchModel[]);
  isEditMode: boolean;

  @HostListener('window:resize', ['$event'])
  getScreenSize(_event?) {
    this.measuredGridWidth = window.innerWidth;
  }

  get addressColumnWidth(): number
  {
     return this.measuredGridWidth > this.minWidthToFitAllColumns ? this.minAddressColumnWidth + (this.measuredGridWidth - this.minWidthToFitAllColumns) : this.minAddressColumnWidth;
  }

  public onPage(event): void {
    this.offset = event.offset;
  }

  public onSort(event): void {
    const sort = event.sorts[0];
    this.sortKey = sort.prop;
    this.sortOrder = sort.dir;
    this.offset = 0;
  }

  getRowCssClasses = (_row: PropertySearchModel): any => {
    const classes = {
    };
    this.isDefaultClass = !this.isDefaultClass;
    return classes;
  };

  ngOnInit(): void {
    this.measuredGridWidth = window.innerWidth;

    this.selectedComparables.subscribe((comps: [PropertySearchModel, ComparableDto][]) =>
    {
      if(comps !== null) {
        this.projectedComparables = of(comps.map((comp) => comp[0]));
      }
    });
  }

  removeComparable(id: string): void {
    this.onComparableRemoved.emit(id);
  }

  editProperty(id: string): void {
    this.onPropertyEditRequested.emit(id);
  }

  saveComparables(): void {
    this.onComparableSaveRequested.emit();
  }

  reSelectedComparables(): void {
    this.onReSelectedComparables.emit();
  }
}
