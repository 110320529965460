import { Component, Inject, Input, OnInit } from '@angular/core';
import { ReportVersionDto } from '@proxy/appraisal/report/reports/v1';
import { SubjectPropertyDto } from '@proxy/appraisal/report/reports/v1/form1004';
import { PartialUpdateReportDto } from '@proxy/appraisal/report/v1';
import { ReportVersionWithLinksDto } from '@proxy/bff/activity/reports/v1';
import { cloneDeep, get, set } from 'lodash-es';
import { ReportsServiceInterface } from 'src/app/interface/bff/activity/reports-service-interface';
import InjectionSymbol from 'src/app/shared/injection/injection-symbol';
import { MessageService } from 'src/app/shared/services/message.service';
@Component({
  selector: 'jaro-kit-sale-history',
  templateUrl: './sale-history.component.html',
  styleUrls: ['./sale-history.component.scss'],
})
export class SaleHistoryComponent implements OnInit {
  @Input() reportVersionDto: ReportVersionWithLinksDto;
  property: SubjectPropertyDto;
  isEditMode: boolean;

  constructor(
    @Inject(InjectionSymbol.ActivityReportsService)
    public activityReportsService: ReportsServiceInterface,
    private msgService: MessageService
  ) {}

  ngOnInit(): void {
    this.property = cloneDeep(get(this.reportVersionDto, 'report.property') || {});
  }

  saveSection() {
    const updates = {
      'property.isSaleOrTransferHistoryResearchedDone':
        this.property.isSaleOrTransferHistoryResearchedDone,
      'property.saleOrTransferHistoryResearchedDoneReason':
        this.property.saleOrTransferHistoryResearchedDoneReason,
      'property.analysisOfPriorSaleOrTransferHistory':
        this.property.analysisOfPriorSaleOrTransferHistory,
      'property.salesComparisonApproachSummary':
        this.property.salesComparisonApproachSummary,
      'property.salesComparisonApproach.indicatedValueBySalesComparisonApproach':
        this.property.salesComparisonApproach.indicatedValueBySalesComparisonApproach
    };
    this.saveSaleHistory(updates);
  }

  toggleMode() {
    this.property = cloneDeep(get(this.reportVersionDto, 'report.property') || {});
    this.isEditMode = !this.isEditMode;
  }

  private saveSaleHistory(updates: Record<string, any>) {
    const updateReportVersionRequest = {
      orderAssignmentId: this.reportVersionDto.orderAssignmentId,
      updates: updates,
    } as PartialUpdateReportDto;
    this.activityReportsService
      .partialUpdate(
        this.reportVersionDto.reportId,
        this.reportVersionDto.version,
        updateReportVersionRequest
      )
      .subscribe({
        next: (_response: ReportVersionDto) => {
          set(this.reportVersionDto, 'report.property', this.property);
          this.isEditMode = !this.isEditMode;
        },
        error: (err) => this.logActionError(err),
      });
  }

  private logActionError(err: any): void {
    this.msgService.error(err).subscribe(() => {});
  }
}
