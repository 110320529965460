<jaro-kit-photo
  [photo]="photo"
  [photos]="photos"
  [galleryPhotos]="galleryPhotos"
  [orderAssignmentId]="orderAssignmentId"
  [reportId]="reportId"
  [reportVersion]="reportVersion"
  [isOpenByReportPhoto]="false"
  [sectionOptions]="sectionOptions"
  [photoSections]="photoSections"
  (onUpdatePhotoSections)="updateSectionPhotos($event)"
></jaro-kit-photo>
<p class="photo-title" *ngIf="photo.photoOwnerType === photoOwnerType.Subject">
  <span [ngClass]="{ 'photo-title': true, 'photo-untitled': !photo.title }">{{
    photo.title || 'Untitled'
  }}</span>
</p>
