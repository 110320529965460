<ng-container *ngIf="form" [formGroup]="form">
  <p class="report-input-title pb-15">One-Unit Housing ('000)</p>

  <span class="report-input-label ">Price</span>
  <div class="row">
    <div class="col-md-4 pl-0 pr-15">
      <mat-form-field appearance="outline">
        <mat-label>Low</mat-label>
        <input
          matInput
          formControlName="neighborhoodLowPrice"
          autocomplete="off"
          currencyMask
          mask="separator.0"
          thousandSeparator=","
          separatorLimit="9999999999"
          [allowNegativeNumbers]="false"
        />
      </mat-form-field>
    </div>

    <div class="col-md-4 pl-0 pr-15">
      <mat-form-field appearance="outline">
        <mat-label>High</mat-label>
        <input
          matInput
          formControlName="neighborhoodHighPrice"
          autocomplete="off"
          currencyMask
          mask="separator.0"
          thousandSeparator=","
          separatorLimit="9999999999"
          [allowNegativeNumbers]="false"
        />
      </mat-form-field>
    </div>

    <div class="col-md-4 pl-0 pr-0">
      <mat-form-field appearance="outline">
        <mat-label>Pred</mat-label>
        <input
          matInput
          formControlName="neighborhoodPredominantPrice"
          autocomplete="off"
          currencyMask
          mask="separator.0"
          thousandSeparator=","
          separatorLimit="9999999999"
          [allowNegativeNumbers]="false"
        />
      </mat-form-field>
    </div>
  </div>

  <span class="report-input-label">Age</span>

  <div class="row">
    <div class="col-md-4 pl-0 pr-15">
      <mat-form-field appearance="outline">
        <mat-label>Low</mat-label>
        <input
          matInput
          formControlName="neighborhoodLowAge"
          autocomplete="off"
          mask="separator.0"
          thousandSeparator=""
          separatorLimit="999"
          [allowNegativeNumbers]="false"
        />
      </mat-form-field>
    </div>

    <div class="col-md-4 pl-0 pr-15">
      <mat-form-field appearance="outline">
        <mat-label>High</mat-label>
        <input
          matInput
          formControlName="neighborhoodHighAge"
          autocomplete="off"
          mask="separator.0"
          thousandSeparator=""
          separatorLimit="999"
          [allowNegativeNumbers]="false"
        />
      </mat-form-field>
    </div>

    <div class="col-md-4 pl-0 pr-0">
      <mat-form-field appearance="outline">
        <mat-label>Pred</mat-label>
        <input
          matInput
          formControlName="neighborhoodPredominantAge"
          autocomplete="off"
          mask="separator.0"
          thousandSeparator=""
          separatorLimit="999"
          [allowNegativeNumbers]="false"
        />
      </mat-form-field>
    </div>
  </div>
</ng-container>
