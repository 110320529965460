<ng-container *ngIf="form && roomDto && roomDto.roomType && roomDto.areaName" [formGroup]="form">
  <div class="row">
    <div class="col-md-6 pl-0 pr-15">
      <div class="pt-15 pb-15" *ngIf="!isBathroomType">
        <jaro-kit-material-combo-box-condition
          [formGroup]="form"
          title="Floors (Material)"
          condition="Floors (Condition)"
          [materialOptions]="floorOptions"
          [conditionOptions]="conditionOptions"
          [materialFormControlName]="getControlName('interiorRoom_RoomName_Floors')"
          [conditionFormControlName]="getControlName('interiorRoom_RoomName_FloorsCondition')"
        >
        </jaro-kit-material-combo-box-condition>
      </div>

      <div class="pt-15 pb-15">
        <jaro-kit-material-combo-box-condition
          [formGroup]="form"
          title="Trim/Finish (Material)"
          condition="Trim/Finish (Condition)"
          [materialOptions]="trimFinishOptions"
          [conditionOptions]="conditionOptions"
          [materialFormControlName]="getControlName('interiorRoom_RoomName_TrimFinishes')"
          [conditionFormControlName]="getControlName('interiorRoom_RoomName_TrimFinishesCondition')"
        >
        </jaro-kit-material-combo-box-condition>
      </div>

      <div class="pt-15 pb-15" *ngIf="isBathroomType">
        <jaro-kit-material-combo-box-condition
          [formGroup]="form"
          title="Bath Wainscot (Material)"
          condition="Bath Wainscot (Condition)"
          [materialOptions]="bathWainscotOptions"
          [conditionOptions]="conditionOptions"
          [materialFormControlName]="getControlName('interiorRoom_RoomName_BathWainscot')"
          [conditionFormControlName]="getControlName('interiorRoom_RoomName_BathWainscotCondition')"
        >
        </jaro-kit-material-combo-box-condition>
      </div>

      <div class="mb-15 mt-15">
        <div *ngIf="isBathroomType">
          <jaro-kit-material-select-list-condition
            [form]="form"
            title="Bathroom Improvement - Level of Work Completed"
            [formControlName]="getControlName('interiorRoom_RoomName_BathroomLevelOfWork')"
            [options]="conditionsLevelOfWorkOptions"
            (onSourceChange)="onSourceChange($event, 'interiorRoom_RoomName_BathroomTimeFrame')"
          ></jaro-kit-material-select-list-condition>

          <jaro-kit-material-select-list-condition
            [form]="form"
            title="Bathroom Improvement - Timeframe"
            [formControlName]="getControlName('interiorRoom_RoomName_BathroomTimeFrame')"
            [options]="conditionsTimeFrameOptions"
            *ngIf="isShowBathroomImprovementTimeframe"
          ></jaro-kit-material-select-list-condition>
        </div>
      </div>
    </div>

    <div class="col-md-6 pl-15 pr-0">
      <div class="pt-15 pb-15">
        <jaro-kit-material-combo-box-condition
          [formGroup]="form"
          title="Walls (Material)"
          condition="Walls (Condition)"
          [materialOptions]="wallsOptions"
          [conditionOptions]="conditionOptions"
          [materialFormControlName]="getControlName('interiorRoom_RoomName_Walls')"
          [conditionFormControlName]="getControlName('interiorRoom_RoomName_WallsCondition')"
        >
        </jaro-kit-material-combo-box-condition>
      </div>

      <div class="pt-15 pb-15" *ngIf="isBathroomType">
        <jaro-kit-material-combo-box-condition
          [formGroup]="form"
          title="Bath Floor (Material)"
          condition="Bath Floor (Condition)"
          [materialOptions]="bathFloorOptions"
          [conditionOptions]="conditionOptions"
          [materialFormControlName]="getControlName('interiorRoom_RoomName_BathFloors')"
          [conditionFormControlName]="getControlName('interiorRoom_RoomName_BathFloorsCondition')"
        >
        </jaro-kit-material-combo-box-condition>
      </div>

      <div class="mb-15 mt-15">
        <div *ngIf="roomDto.roomType.toLowerCase() === 'kitchen'">
          <jaro-kit-material-select-list-condition
            [form]="form"
            title="Kitchen Improvement - Level of Work Completed"
            [formControlName]="getControlName('interiorRoom_RoomName_KitchenLevelOfWork')"
            [options]="conditionsLevelOfWorkOptions"
            (onSourceChange)="onSourceChange($event, 'interiorRoom_RoomName_KitchenTimeFrame')"
          ></jaro-kit-material-select-list-condition>

          <jaro-kit-material-select-list-condition
            [form]="form"
            title="Kitchen Improvement - Timeframe"
            [formControlName]="getControlName('interiorRoom_RoomName_KitchenTimeFrame')"
            [options]="conditionsTimeFrameOptions"
            *ngIf="isShowKitchenImprovementTimeframe"
          ></jaro-kit-material-select-list-condition>
        </div>
      </div>
    </div>
  </div>
</ng-container>
