<halo-global-popup [dialogRef]="dialogRef" [isHideClose]="true" isShowScrollbars="true">
  <div
    [ngClass]="{
      'translucent-loading-overlay': isLoading,
      'comparables-panel': true,
      'market-modal-panel': true
    }"
  >
    <div class="market-data-page">
      <mat-spinner
        *ngIf="isLoading || areDefaultSearchResultsLoading"
        value="100"
        diameter="100"
        strokeWidth="5"
        class="loading-spinner"
      ></mat-spinner>
      <ng-container>
        <div class="market-form">
          <jaro-kit-market-section [section]="sections.propertyMap">
            <div class="search-result-count">
              <span
                [ngClass]="{
                  'filtered-search-result-count': defaultSearchCount !== filteredSearchCount
                }"
                >{{ filteredSearchCount }}</span
              >&nbsp;/&nbsp;{{ defaultSearchCount }}&nbsp;Results
            </div>
            <mat-slide-toggle
              class="default-map-markers-panel-toggle"
              [(ngModel)]="areDefaultMapMarkersVisible"
              >Grey Markers</mat-slide-toggle
            >
            <mat-slide-toggle
              class="filtered-map-markers-panel-toggle"
              [(ngModel)]="areFilteredMapMarkersVisible"
              >Blue Markers</mat-slide-toggle
            >
            <mat-slide-toggle class="filter-panel-toggle" [(ngModel)]="isFacetFilterPanelVisible"
              >Filters</mat-slide-toggle
            >
            <div class="property-map container">
              <div class="row">
                <div
                  [ngClass]="{
                    'col-6': isFacetFilterPanelVisible,
                    'col-12': !isFacetFilterPanelVisible
                  }"
                >
                  <jaro-kit-market-map
                    [defaultSearchResponse]="defaultSearchResponse"
                    [filteredSearchResponse]="filteredSearchResponse"
                    (onPolygonsUpdated)="polygonsUpdated($event)"
                    (onPropertyEditRequested)="editProperty($event)"
                    [subjectAddressLine]="this.addressLine"
                    [subjectGeolocation]="this.geolocation"
                    [isLoading]="areFilteredSearchResultsLoading"
                    [areDefaultMapMarkersVisible]="areDefaultMapMarkersVisible"
                    [areFilteredMapMarkersVisible]="areFilteredMapMarkersVisible"
                  >
                  </jaro-kit-market-map>
                </div>
                <div [ngClass]="{ 'toggle-hidden': !isFacetFilterPanelVisible, 'col-6': true }">
                  <jaro-kit-market-facet-filter-panel
                    [defaultSearchResponse]="defaultSearchResponse"
                    [filteredSearchResponse]="filteredSearchResponse"
                    (onFilterSelectionUpdated)="filterSelectionUpdated($event)"
                    [isLoading]="areFilteredSearchResultsLoading"
                  >
                  </jaro-kit-market-facet-filter-panel>
                </div>
              </div>
            </div>
          </jaro-kit-market-section>
          <jaro-kit-market-section [section]="sections.comparables">
            <div class="search-result-count">
              <span
                [ngClass]="{
                  'filtered-search-result-count': defaultSearchCount !== filteredSearchCount
                }"
                >{{ filteredSearchCount }}</span
              >&nbsp;/&nbsp;{{ defaultSearchCount }}&nbsp;Results
            </div>
            <div class="comparables-grid container">
              <jaro-kit-market-comparables
                [orderAssignmentId]="orderAssignmentId"
                [selectedComparables]="selectedComparables"
                [defaultSearchResponse]="defaultSearchResponse"
                [filteredSearchResponse]="filteredSearchResponse"
                [isLoading]="areFilteredSearchResultsLoading"
                (onPropertyEditRequested)="editProperty($event)"
                (onComparableAdded)="addLocalComparable($event)"
                (onComparableRemoved)="removeComparable($event)"
                (onComparableSaveRequested)="saveComparables()"
                (onReSelectedComparables)="reSelectedComparables()"
                [hasSelectedComparableChanges]="hasSelectedComparableChanges"
              ></jaro-kit-market-comparables>
            </div>
          </jaro-kit-market-section>
        </div>
      </ng-container>
      <ng-container>
        <jaro-kit-comparable-totals [reportVersionDto]="reportVersionDto">
        </jaro-kit-comparable-totals>
        <jaro-kit-market-conditions
          [reportVersionDto]="reportVersionDto"
        ></jaro-kit-market-conditions>
      </ng-container>
    </div>
  </div>
</halo-global-popup>
