import { Component, Input } from '@angular/core';
import { PropertyRecordDto } from '@proxy/property/property-record/property-records/v1';
import { SelectedPropertyRecordValuePipe } from 'src/app/proxy-adapter/shared/pipes/selected-property-record-value.pipe';

@Component({
  selector: 'jaro-kit-market-cleanup-property-details',
  templateUrl: './market-cleanup-property-details.component.html',
  styleUrls: ['./market-cleanup-property-details.component.scss'],
})
export class MarketCleanupPropertyDetailsComponent {
  @Input() property: PropertyRecordDto;
  @Input() isLoading: boolean = true;

  selectedValuePipe: SelectedPropertyRecordValuePipe;
  price: number;

  constructor() {
    this.selectedValuePipe = new SelectedPropertyRecordValuePipe();
  }

  ngOnInit(): void {
    const listing = this.property.cleanedListing;
    const salePriceString = this.isSold(this.selectedValuePipe.transform(listing.standardStatus))
      ? this.selectedValuePipe.transform(listing.closePrice)
      : this.selectedValuePipe.transform(listing.listPrice);
    this.price = parseFloat(salePriceString);
  }

  private isSold(status: string) {
    if (status?.trim()) {
      return status.toLowerCase() === 'settled sale';
    } else {
      return false;
    }
  }
}
