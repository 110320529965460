import { RestService } from '@abp/ng.core';
import { Inject, Injectable } from '@angular/core';
import { map, of } from 'rxjs';
import { OrderAssignmentServiceInterface } from 'src/app/interface/ascent/order-assignment-service-interface';
import InjectionSymbol from 'src/app/shared/injection/injection-symbol';
import { CacheService } from 'src/app/shared/services/cache.service';
import { OrderAssignmentDto } from '../../order-assignments/v2/orderAssignmentDto';
import { OrderAssignmentSearchDto } from '../../order-assignments/v2/orderAssignmentSearchDto';

@Injectable({
  providedIn: 'root',
})
export class OrderAssignmentService implements OrderAssignmentServiceInterface {
  apiName = 'Ascent';

  constructor (
    private restService: RestService,
    @Inject(InjectionSymbol.CacheService)
    private cacheService: CacheService
  ) { }


  get = (orderAssignmentId: string) =>
    this.restService.request<any, OrderAssignmentDto>(
      {
        method: 'GET',
        url: `/api/v2/order-assignments/${orderAssignmentId}`,
      },
      { apiName: this.apiName }
    );

  getOrderAssignment = (orderAssignmentId: string) => {
    const orderAssignment = this.cacheService.getDataCacheByKey(orderAssignmentId);
    if (orderAssignment) {
      return of(orderAssignment);
    }

    return this.get(orderAssignmentId).pipe(
      map((orderAssignment) => {
        this.cacheService.cacheDataWithKey(orderAssignmentId, orderAssignment);
        return orderAssignment;
      })
    );
  };

  getActiveByOrderId = (orderId: number) =>
    this.restService.request<any, OrderAssignmentDto>({
      method: 'GET',
      url: `/api/v2/order-assignments/${orderId}`,
    },
    { apiName: this.apiName });

  search = (input: OrderAssignmentSearchDto) =>
    this.restService.request<any, OrderAssignmentDto[]>({
      method: 'POST',
      url: `/api/v2/order-assignments/search`,
      body: input
    },
    { apiName: this.apiName });
}
