<ng-container *ngIf="form" [formGroup]="form">
  <div class="row">    
    <div class="col-md-4 pl-0 pr-15">
      <div class="report-input-label-container report-input-title title-label">
        <span class="report-input-label">&nbsp;</span>
     </div>
      <div class="report-input-form-field">
        <p class="report-input-label report-input-radio-label">Concrete Slab</p>
        <mat-radio-group formControlName="foundationConcreteSlab">
          <mat-radio-button [value]="true" class="report-input-radio-button">Yes</mat-radio-button>
          <mat-radio-button [value]="false" class="report-input-radio-button">No</mat-radio-button>
        </mat-radio-group>
      </div>

      <div class="report-input-form-field">
        <p class="report-input-label report-input-radio-label">Crawl Space</p>
        <mat-radio-group formControlName="foundationCrawlSpace">
          <mat-radio-button [value]="true" class="report-input-radio-button">Yes</mat-radio-button>
          <mat-radio-button [value]="false" class="report-input-radio-button">No</mat-radio-button>
        </mat-radio-group>
      </div>

      <div class="report-input-form-field">
        <p class="report-input-label report-input-radio-label">Full Basement</p>
        <mat-radio-group formControlName="foundationFullBasement">
          <mat-radio-button [value]="true" class="report-input-radio-button">Yes</mat-radio-button>
          <mat-radio-button [value]="false" class="report-input-radio-button">No</mat-radio-button>
        </mat-radio-group>
      </div>

      <div class="report-input-form-field">
        <p class="report-input-label report-input-radio-label">Partial Basement</p>
        <mat-radio-group formControlName="foundationPartialBasement">
          <mat-radio-button [value]="true" class="report-input-radio-button">Yes</mat-radio-button>
          <mat-radio-button [value]="false" class="report-input-radio-button">No</mat-radio-button>
        </mat-radio-group>
      </div>
    </div>


    <div class="col-md-4 pl-15 pr-0">
      <div class="report-input-label-container report-input-title title-label">
          <span class="report-input-label">Below Grade</span>
      </div>      
      <mat-form-field appearance="outline">
        <mat-label>Total Below Grade Area Square Feet</mat-label>
        <input
          matInput
          formControlName="basementAreaSqFt"
          id="basementAreaSqFt"
          autocomplete="off"
          mask="separator.0"
          thousandSeparator=""
          [allowNegativeNumbers]="false"
          (keyup)="onChangeBasementArea($event)"
          maxlength="5"
        />
      </mat-form-field>
      <jaro-kit-validation-message [form]="form" [controlName]="'basementAreaSqFt'" [isRequired]="true"></jaro-kit-validation-message>
      <ng-container *ngIf="isShowBasementFinishedAreaSqFt">
        <mat-form-field appearance="outline" >
          <mat-label>Finished Below Grade Area Square Feet</mat-label>
          <input
            matInput
            id="basementFinishedAreaSqFt"
            formControlName="basementFinishedAreaSqFt"
            autocomplete="off"
            mask="separator.0"
            thousandSeparator=""
            [allowNegativeNumbers]="false"
            maxlength="5"
            (change)="onChangeBasementFinishedAreaSqFt($event)"
          />
        </mat-form-field>
        <jaro-kit-validation-message [form]="form" [controlName]="'basementFinishedAreaSqFt'" [isRequired]="true"></jaro-kit-validation-message>
      </ng-container>
          
      <ng-container *ngIf="isShowBasementExit">      
        <mat-form-field appearance="outline">
          <mat-label>Basement Entry/Exit</mat-label>
          <mat-select
            id="basementExit"
            formControlName="basementExit"
            disableOptionCentering
            panelClass="report-input-drop-down"
            placeholder="Select from list"
            floatLabel="never"          
          >
          <mat-option *ngFor="let item of basementExitOptions" [value]="item.value">{{
            item.name
          }}</mat-option>
          </mat-select>
        </mat-form-field>
        <jaro-kit-validation-message [form]="form" [controlName]="'basementExit'" [isRequired]="true"></jaro-kit-validation-message>
      </ng-container>    

      <div class="report-input-form-field">
        <p class="report-input-label report-input-radio-label">Sump Pump</p>
        <mat-radio-group formControlName="foundationSumpPump">
          <mat-radio-button [value]="true" class="report-input-radio-button">Yes</mat-radio-button>
          <mat-radio-button [value]="false" class="report-input-radio-button">No</mat-radio-button>
        </mat-radio-group>
      </div>
    </div>  

    <div class="col-md-4 pl-15 pr-0">
      <div class="report-input-label-container report-input-title title-label">
        <span class="report-input-label">&nbsp;</span>
    </div>
      <ng-container *ngIf="isShowTotalRecreationRoomBelowGradeCount">      
        <mat-form-field appearance="outline">
          <mat-label>Recreation Room Count</mat-label>
          <input
            matInput
            id="totalRecreationRoomBelowGradeCount"
            formControlName="totalRecreationRoomBelowGradeCount"
            autocomplete="off"
            mask="0"
            [allowNegativeNumbers]="false"
            maxlength="1"
          />
        </mat-form-field>
        <jaro-kit-validation-message [form]="form" [controlName]="'totalRecreationRoomBelowGradeCount'" [isRequired]="true"></jaro-kit-validation-message>
      </ng-container>

      <ng-container *ngIf="isShowTotalBedroomBelowGradeCount">
        <mat-form-field appearance="outline" *ngIf="isShowTotalBedroomBelowGradeCount">
          <mat-label>Bedroom Count</mat-label>
          <input
            matInput
            id="totalBedroomBelowGradeCount"
            formControlName="totalBedroomBelowGradeCount"
            autocomplete="off"
            mask="0"
            [allowNegativeNumbers]="false"
            maxlength="1"
          />
        </mat-form-field>
        <jaro-kit-validation-message [form]="form" [controlName]="'totalBedroomBelowGradeCount'" [isRequired]="true"></jaro-kit-validation-message>
      </ng-container>      

      <ng-container *ngIf="isShowFullAndHalfBathroomBelowGradeCount">
        <mat-form-field appearance="outline">
          <mat-label>Bathroom Count</mat-label>
          <input
            matInput
            id="fullAndHalfBathroomBelowGradeCount"
            formControlName="fullAndHalfBathroomBelowGradeCount"
            autocomplete="off"
            mask="0*.00"
            [allowNegativeNumbers]="false"
            [dropSpecialCharacters]="false"
            maxlength="3"
            (focus)="onChangeTotalBathroomCount()"
            (keyup)="onChangeTotalBathroomCount()"
          />
        </mat-form-field>
        <jaro-kit-validation-message [form]="form" [controlName]="'fullAndHalfBathroomBelowGradeCount'" [isRequired]="true" [maxLength]="1"></jaro-kit-validation-message>
        <input formControlName="totalFullBathroomBelowGradeCount" type="hidden" />
        <input formControlName="totalHalfBathroomBelowGradeCount" type="hidden" />
      </ng-container>

      <ng-container *ngIf="isShowTotalOtherRoomBelowGradeCount">
        <mat-form-field appearance="outline">
          <mat-label>Other Room Count</mat-label>
          <input
            matInput
            id="totalOtherRoomBelowGradeCount"
            formControlName="totalOtherRoomBelowGradeCount"
            autocomplete="off"
            mask="0"
            [allowNegativeNumbers]="false"
            maxlength="1"
          />
        </mat-form-field>
        <jaro-kit-validation-message [form]="form" [controlName]="'totalOtherRoomBelowGradeCount'" [isRequired]="true"></jaro-kit-validation-message>
      </ng-container>
    </div>
  </div>
</ng-container>
