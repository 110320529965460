<div class="mt-0" *ngIf="form" [formGroup]="form">
  <p class="report-input-label report-input-radio-label">{{ field.label }}</p>
  <mat-radio-group [formControlName]="field.formControlName" [id]="field.formControlName">
    <mat-radio-button
      *ngFor="let option of options"
      [value]="option.value"
      (change)="onChange($event)"
      class="report-input-radio-button"
      >{{ option.name }}</mat-radio-button
    >
  </mat-radio-group>
  <jaro-kit-validation-message
    [form]="form"
    [controlName]="field.formControlName"
    [isRequired]="field.isRequired"
  ></jaro-kit-validation-message>
</div>
