<div class="row" *ngIf="reportInputSectionForm">
  <div class="contract-form-container" *ngIf="subSection" [formGroup]="reportInputSectionForm">
    <div class="contract-form-header">
      <span class="report-input-headline mat-headline">Form</span>
      <div class="action-item">
        <div class="space"></div>
        <button
          (click)="toggleMode()"
          class="jaro-kit-primary-button btn-edit-section"
          *ngIf="!isEditMode"
        >
          <em class="fas fa-edit" aria-hidden="true"></em>
        </button>

        <button
          (click)="toggleMode()"
          class="jaro-kit-primary-button btn-cancel-section"
          *ngIf="isEditMode"
        >
          Cancel
        </button>

        <button
          (click)="saveButtonClick()"
          class="jaro-kit-primary-button btn-save-section"
          *ngIf="isEditMode"
          [disabled]="isInvalidData"
        >
          Save
        </button>
      </div>
    </div>
    <jaro-kit-view-mode-sub-section
      *ngIf="!isEditMode"
      [section]="section"
      [subSection]="contractSection"
      [reportDto]="reportDto"
      [timeZoneId]="timeZoneId"
      [reportInputSectionForm]="reportInputSectionForm"
      [orderAssignmentId]="orderAssignmentId"
      [feature]="feature"
      #contractViewMode
    >
    </jaro-kit-view-mode-sub-section>

    <jaro-kit-contract-form
      *ngIf="isEditMode"
      [form]="reportInputSectionForm"
      [reportDto]="reportDto"
    ></jaro-kit-contract-form>
  </div>

  <div class="file-viewer-container">
    <div class="contract-form-header">
      <span class="report-input-headline mat-headline">File Viewer</span>

      <div class="action-item" *ngIf="isUploadOrEditContractDocument">
        <div class="space"></div>
        <button
          class="jaro-kit-primary-button"
          (click)="closeExtractContractDocuments()"
          [disabled]="isLoading"
        >
          Close
        </button>
        <div class="contract-save-btn">
          <button
            class="jaro-kit-primary-button"
            (click)="saveContractDocuments()"
            [disabled]="isLoading"
          >
            Save
          </button>

          <span> {{ selectedPages }} selected</span>
        </div>
      </div>

      <div
        class="action-item"
        *ngIf="
          !isUploadOrEditContractDocument &&
          !isCloseContractDocument
        "
      >
        <div class="space"></div>

        <button class="jaro-kit-primary-button" (click)="closeContractDocuments()">Close</button>
      </div>
    </div>
    <div  *ngIf="isCloseContractDocument">
      <jaro-kit-upload-document
      [orderAssignmentId]="orderAssignmentId"
      [isMultiple]="false"
      [fileTypeAccept]="'pdf'"
      title="document"
      (onFileUploadCompleted)="uploadNewContractDocument($event)"
      [documentSettings]="contractDocumentSettings"
    ></jaro-kit-upload-document>

    </div>

    <mat-spinner
      *ngIf="isLoading"
      value="100"
      diameter="100"
      strokeWidth="5"
      class="action-loading-spinner"
    ></mat-spinner>

    <div *ngIf="!isUploadOrEditContractDocument && isCloseContractDocument && !isLoading">
      <jaro-kit-contract-list
        [contractDocuments]="contractDocs"
        [reportVersion]="reportVersion"
        (onOpenDocumentPreview)="openDocumentPreview($event)"
        (onOpenContractDocumentForExtraction)="openContractDocumentForExtraction($event)"
        (onDeleteContractDocument)="deleteContractDocument($event)"
      ></jaro-kit-contract-list>
    </div>
    <div
        class="document-review-container"
        *ngIf="getLatestExtractedUrl(currentPreviewDocument) && !isCloseContractDocument && !isUploadOrEditContractDocument"
      >
      <div class="document-review-top contract-preview-top">
        <div class="document-top-left">
          <span class="document-select-pages"
            >{{ currentPreviewDocument.title }}.pdf</span
          >
        </div>
      </div>
      <div class="document-review-detail">
        <jaro-kit-preview-document
          [url]="getLatestExtractedUrl(currentPreviewDocument)"
          [heightOfPdfViewer]="'60vh'"
          (onPdfLoadingFailed)="pdfLoadingFailed()"
          [(isLoading)]="isLoading"
          [paginationAttributeSuffix]="paginationAttributeSuffix"
        ></jaro-kit-preview-document>
      </div>
    </div>


    <div class="document-review-container" *ngIf="isUploadOrEditContractDocument && !isCloseContractDocument">
      <div class="document-review-top">
        <div class="document-top-left">
          <span class="document-select-pages">Select pages to include</span>
          <span class="document-title">{{ newContractDocument.title }}.{{newContractDocument.fileExtension}}</span>
        </div>
      </div>
      <div class="document-review-detail" *ngIf="newContractDocument">
        <div class="document-select-all">
          <mat-checkbox
            [(ngModel)]="areAllPagesSelected"
            (change)="selectAll($event)"
            id="thumbnailRadioButtonSelectAll"
            [disabled]="isLoading"
            >Select All
          </mat-checkbox>
        </div>
        <jaro-kit-preview-extract-document
          [url]="newContractDocument.url"
          [pages]="newContractDocument.pages"
          [isSelectedAllPage]="newContractDocument.isSelectedAllPage"
          [heightOfPdfViewer]="'60vh'"
          (onPdfLoadingFailed)="pdfLoadingFailed()"
          [(isLoading)]="isLoading"
          [paginationAttributeSuffix]="paginationAttributeSuffix"
          #previewExtractDocument
        ></jaro-kit-preview-extract-document>
      </div>
    </div>
  </div>
</div>
