import { Component, Inject, Input, OnInit } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { ContentServiceInterface } from 'src/app/interface/content/content-service-interface';
import { ItemData } from 'src/app/shared/components/multiple-combo-box/multiple-combo-box.component';
import InjectionSymbol from 'src/app/shared/injection/injection-symbol';

@Component({
  selector: 'jaro-kit-zone-details',
  templateUrl: './zoning-details1004.component.html',
  styleUrls: ['./zoning-details1004.component.scss'],
})
export class ZoningDetailsComponent implements OnInit {
  @Input() form: FormGroup;
  
  zoningComplianceOptions:ItemData[] = [];  
  isShowComplianceDescription: boolean;
  constructor(
    @Inject(InjectionSymbol.ContentService)
    public contentService: ContentServiceInterface
  ) {    
    this.zoningComplianceOptions = this.contentService.getContentByKey('zoningCompliance');
  }

  ngOnInit(): void {    
    this.isShowComplianceDescription = this.form.value.siteZoningCompliance === 'illegal use';
  }    

  onChangeZoningCompliance($event) {
    if ($event.value !== 'illegal use') {
      this.form.controls['siteComplianceDescription'].setValue(null);
      this.form.controls['siteComplianceDescription'].markAsDirty();
      this.form.updateValueAndValidity();
    }
    this.isShowComplianceDescription = $event.value === 'illegal use';
  }  
}
