import { Component, EventEmitter, Inject, Input, OnInit, Output } from '@angular/core';
import {
  QualityCheckDto,
  ValidationResultDto
} from '@proxy/appraisal/report/reports/v1';
import { ReportVersionWithLinksDto } from '@proxy/bff/activity/reports/v1';
import { ReportServiceInterface } from 'src/app/interface/appraisal/report/report-service-interface';
import { TreeNodeModel } from 'src/app/shared/components/tree-node/tree-node.component';
import InjectionSymbol from 'src/app/shared/injection/injection-symbol';
import { CacheService } from 'src/app/shared/services/cache.service';
import { EventService } from 'src/app/shared/services/event.service';
import { MessageService } from 'src/app/shared/services/message.service';

@Component({
  selector: 'jaro-kit-quality-check-list',
  templateUrl: './quality-check-list.component.html',
  styleUrls: ['./quality-check-list.component.scss'],
})
export class QualityCheckListComponent implements OnInit {
  @Input() orderAssignmentId: string;
  @Input() productName: string;
  @Input() reportVersionDto: ReportVersionWithLinksDto;
  @Input() featureRouteSegment: string;
  @Output() onToggleQualityCheck = new EventEmitter<boolean>();
  @Output() onRefreshQualityCheck = new EventEmitter<boolean>();
  @Output() onGoToPage = new EventEmitter<string>();

  isLoading: boolean;
  isRefreshQualityCheckSuccessful: boolean = false;
  qualityCheckList: TreeNodeModel[] = [];
  timeToRefreshQualityCheck = 15 * 60 * 1000;
  refreshInterval: ReturnType<typeof setInterval>;
  refreshIntervalGoToField: ReturnType<typeof setInterval>;

  constructor(
    @Inject(InjectionSymbol.EventService)
    private eventService: EventService,

    private msgService: MessageService,
    @Inject(InjectionSymbol.ReportService)
    private reportService: ReportServiceInterface,
    @Inject(InjectionSymbol.CacheService)
    private cacheService: CacheService
  ) {
    this.eventService.onRefreshQualityCheck.subscribe((isRefresh) => {
      if (isRefresh) {
        this.isLoading = true;
      }
    });

    this.eventService.onUpdatedQualityCheck.subscribe((reportValidation) => {
      if (reportValidation) {
        this.refreshQCRuleList(reportValidation);
      }
    });
  }

  ngOnInit(): void {
    if ((this.qualityCheckList || []).length === 0) {
      this.refreshQualityCheck();
    }
  }

  closeQualityCheck() {
    this.onToggleQualityCheck.emit(false);
  }

  gotoField(node: TreeNodeModel) {
    if (this.refreshIntervalGoToField) {
      clearInterval(this.refreshIntervalGoToField);
    }
    if (node?.value && node?.parentId) {
      const feature = this.qualityCheckList.find((item) =>
        item.children.some((child) => child.value === node.parentId) || item.value === node.parentId
      );

      if (feature) {
        if (feature.value === 'comparables') {
          if (
            [
              'transactionRecordingDate',
              'saleHistorySource',
              'saleHistoryClosePrice',
              'transactionContractDate',
            ].includes(node.value)
          ) {
            const newNode = {
              parentId: 'section-property-history',
              value: node.value,
            };
            this.eventService.goToSection('jaro-boost');
            this.checkAndGoToField(newNode);
            this.refreshIntervalGoToField = setInterval(() => {
              this.checkAndGoToField(newNode);
            }, 1000);
          } else {
            this.navigateAndScrollToField(feature, node);
          }
        } else {
          this.navigateAndScrollToField(feature, node);
        }
      }
    }
  }

  private navigateAndScrollToField(feature: TreeNodeModel, node: TreeNodeModel) {
    if (feature.value === this.featureRouteSegment) {
      this.eventService.goToReportField({
        sectionId: node.parentId,
        fieldId: node.value,
      });
    } else {
      this.eventService.goToSection(feature?.value);
      this.checkAndGoToField(node);
      this.refreshIntervalGoToField = setInterval(() => {
        this.checkAndGoToField(node);
      }, 1000);
    }
  }

  checkAndGoToField(node: TreeNodeModel) {
    this.eventService.goToReportField({
      sectionId: node.parentId,
      fieldId: node.value,
    });
    const elementRef = document.getElementById(node.value);
    if (elementRef) {
      if (this.refreshIntervalGoToField) {
        clearInterval(this.refreshIntervalGoToField);
      }
    }
  }

  refreshQualityCheck() {
    this.isLoading = true;
    this.eventService.refreshQualityCheck(true);

    setTimeout(() => {
      const isUseMainFormName = this.reportVersionDto.productType?.toLocaleLowerCase() === '1004d';
      const mainFormName = isUseMainFormName
        ? this.productName?.toLocaleLowerCase()?.replace('&', 'and')
        : null;

      this.reportService
        .reportValidations(this.orderAssignmentId, 'kit-submit', mainFormName)
        .subscribe({
          next: (response: ValidationResultDto) => {
            this.eventService.updateQualityCheck(response);
          },
          error: (err) => this.logActionError(err),
        });
    }, 100);
  }

  private refreshQCRuleList(validation: ValidationResultDto) {
    this.qualityCheckList = [];
    if (validation?.results?.length > 0) {
      let validations = validation.results;

      this.cacheService
        .checkAndGetJsonFile(
          `/assets/json/validation/${this.reportVersionDto.productType}/quality-check.config.json`
        )
        .subscribe({
          next: (qualityCheckListDto: QualityCheckDto[]) => {
            qualityCheckListDto.forEach((qualityCheckListFeature) => {
              const featureNode = {
                title:
                  qualityCheckListFeature.feature === 'jaro-boost'
                    ? 'Boost'
                    : qualityCheckListFeature.feature,
                value: qualityCheckListFeature.feature,
                expanded: false,
                children: [],
                index: qualityCheckListFeature.index,
              } as TreeNodeModel;

              qualityCheckListFeature.sections.forEach((qualityCheckSection) => {
                const sectionNode = {
                  title: qualityCheckSection.title,
                  value: qualityCheckSection.id,
                  expanded: false,
                  children: [],
                } as TreeNodeModel;
                const qcItems = qualityCheckSection.fields
                  .filter((field) =>
                    validations.find((validation) => validation.rule === field.rule)
                  )
                  .map((field) => {
                    return {
                      title: field.label,
                      value: field.formControlName,
                      isWarning: field.isWarning,
                      parentId: qualityCheckSection.id,
                    };
                  });

                if (qualityCheckSection.id) {
                  sectionNode.children = qcItems;
                } else {
                  featureNode.children = featureNode.children.concat(qcItems);
                }

                if (sectionNode.children.length > 0) {
                  featureNode.children.push(sectionNode);
                }
              });


              if(featureNode.value === 'comparables') {
                const comparableQC = validations.find(validation => (validation.rule || '').toLocaleLowerCase() === 'comparables');

                if(comparableQC?.comparables?.length > 0) {
                  const comparableNodes = comparableQC.comparables
                  .map((comparable) => {
                    return {
                      title: `Comparable ${comparable.index}`,
                      value: comparable.upi,
                      isWarning: false,
                      parentId: 'comparables',
                    };
                  });
                  featureNode.children = featureNode.children.concat(comparableNodes);
                }
              }

              if (featureNode.children.length > 0) {
                this.qualityCheckList.push(featureNode);
              }
            });
            this.isRefreshQualityCheckSuccessful = true;
            this.isLoading = false;
          },
          error: (err) => this.logActionError(err),
        });
    } else {
      this.isRefreshQualityCheckSuccessful = true;
      this.isLoading = false;
    }
  }

  private logActionError(err: any): void {
    this.isLoading = false;
    this.msgService.error(err).subscribe(() => {});
  }
}
