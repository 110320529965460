import { Component, Inject, Input } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { RoomDto } from '@proxy/appraisal/report/reports/v1/form1004';
import { ContentServiceInterface } from 'src/app/interface/content/content-service-interface';
import { ItemData } from 'src/app/shared/components/multiple-combo-box/multiple-combo-box.component';
import InjectionSymbol from 'src/app/shared/injection/injection-symbol';

@Component({
  selector: 'halo-global-room',
  templateUrl: './room.component.html',
  styleUrls: ['./room.component.scss'],
})
export class RoomComponent {
  @Input() form: FormGroup;
  @Input() roomDto: RoomDto;

  get isBathroomType(): boolean {
    const bathroomTypes = ['bathroom', 'bath (half)', 'bath (full)', 'bath (primary)'];
    const roomType = this.roomDto?.roomType || '';
    return bathroomTypes.includes(roomType.toLowerCase());
  }

  floorOptions: ItemData[] = [];
  wallsOptions: ItemData[] = [];
  trimFinishOptions: ItemData[] = [];
  bathFloorOptions: ItemData[] = [];
  bathWainscotOptions: ItemData[] = [];
  conditionOptions: ItemData[] = [];
  conditionsLevelOfWorkOptions: ItemData[] = [];
  conditionsTimeFrameOptions: ItemData[] = [];

  get isShowKitchenImprovementTimeframe(): boolean {
    const value = this.form?.value[this.getControlName('interiorRoom_RoomName_KitchenLevelOfWork')];
    return value && value.toString().toLowerCase() != 'not updated';
  }

  get isShowBathroomImprovementTimeframe(): boolean {
    const value =
      this.form?.value[this.getControlName('interiorRoom_RoomName_BathroomLevelOfWork')];
    return value && value.toString().toLowerCase() != 'not updated';
  }

  constructor(
    @Inject(InjectionSymbol.ContentService)
    public contentService: ContentServiceInterface
  ) {
    this.conditionsTimeFrameOptions = this.contentService.getContentByKey(
      'rooms.yearsSinceCompletion'
    );
    this.conditionsLevelOfWorkOptions = this.contentService.getContentByKey('rooms.updateStatus');
    this.conditionOptions = this.contentService.getContentByKey('materialConditions');
    this.bathWainscotOptions = this.contentService.getContentByKey('rooms.bathWainscotMaterials');
    this.bathFloorOptions = this.contentService.getContentByKey('rooms.bathFloorMaterials', false, true);
    this.trimFinishOptions = this.contentService.getContentByKey('rooms.trimFinishMaterials');
    this.wallsOptions = this.contentService.getContentByKey('rooms.wallsMaterials');
    this.floorOptions = this.contentService.getContentByKey('rooms.floorMaterials', false, true);
  }

  getControlName(key: string) {
    return key && this?.roomDto?.areaName ? key.replace('_RoomName_', this.roomDto.areaName) : key;
  }

  onSourceChange(value, formControlName: string) {
    const isNotUpdated = value && value.toLowerCase() === 'not updated';
    if (isNotUpdated) {
      const control = this.form.controls[this.getControlName(formControlName)];
      if (control) {
        control.setValue(null);
        control.markAsDirty();
        this.form.updateValueAndValidity();
      }
    }
  }
}
