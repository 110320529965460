import { Component, Input } from '@angular/core';
import { FormGroup } from '@angular/forms';

@Component({
  selector: 'jaro-kit-validation-message',
  templateUrl: './validation-message.component.html',
  styleUrls: ['./validation-message.component.scss'],
})
export class ValidationMessageComponent {
  @Input() form: FormGroup;
  @Input() controlName: string;
  @Input() isRequired: boolean;
  @Input() format: string;
  @Input() maxLength: string;
  @Input() total: string;

  public hasError = (errorName: string) => {
    if (this.form) {
      const control = this.form.controls[this.controlName];
      if (control?.errors) {
        if(errorName === 'greaterThanZeroOrBlank' && control.errors[errorName]) {
          return (
            control.invalid &&
            (control.dirty || control.touched) &&
            control.errors[errorName] != undefined
          );
        }        

        return (
          control.invalid &&
          (control.dirty || control.touched) &&
          control.errors[errorName] != undefined
        );
      }
    }
    return false;
  };

  public hasWarning = (warningName: string) => {
    if (this.form) {
      const control = this.form.controls[this.controlName];
      if (control?.errors) {        
        if(warningName === 'totalPercentageShouldEqualNumber' && control.errors[warningName]) {
          return control.invalid;
        }

        return (
          control.invalid &&
          (control.dirty || control.touched) &&
          control.errors[warningName] != undefined
        );
      }
    }
    return false;
  };
}
