<ng-container *ngIf="form" [formGroup]="form">
  <div class="row">
    <div class="col-md-6 pl-0 pr-15">
      <div class="report-input-form-field">
        <p class="report-input-label report-input-radio-label">Car Storage</p>
        <mat-radio-group
          formControlName="carStorage"
          class="mt-15 mb-15"
          (change)="onChangeCarStorage($event)"
        >
          <mat-radio-button [value]="true" class="report-input-radio-button">Yes</mat-radio-button>
          <mat-radio-button [value]="false" class="report-input-radio-button">No</mat-radio-button>
        </mat-radio-group>
      </div>

      <div *ngIf="this.form.value['carStorage']">
        <div class="report-input-form-field">
          <p class="report-input-label report-input-radio-label">Driveway</p>
          <mat-radio-group
            formControlName="carStorageDriveway"            
            class="mt-15 mb-15"
            (change)="onChangeDriveway($event)"
          >
            <mat-radio-button [value]="true" class="report-input-radio-button"
              >Yes</mat-radio-button
            >
            <mat-radio-button [value]="false" class="report-input-radio-button"
              >No</mat-radio-button
            >
          </mat-radio-group>          
        </div>

        <mat-form-field *ngIf="this.form.value['carStorageDriveway']" appearance="outline">
          <mat-label>Driveway # of Cars</mat-label>
          <input
            matInput
            formControlName="carStorageDrivewayCars"
            id="carStorageDrivewayCars"
            mask="separator.0"
            thousandSeparator=""
            separatorLimit="99"
            [allowNegativeNumbers]="false"
            autocomplete="off"
          />
        </mat-form-field>
        <jaro-kit-validation-message [form]="form" [controlName]="'carStorageDrivewayCars'" [isRequired]="true"></jaro-kit-validation-message>

        <div class="report-input-form-field" *ngIf="this.form.value['carStorageDriveway']">
          <p class="report-input-label report-input-radio-label">Driveway Surface</p>
          <mat-radio-group formControlName="carStorageDrivewaySurface">
            <mat-radio-button
              *ngFor="let option of drivewaySurfaceOptions"
              [value]="option.value"
              class="report-input-radio-button"
              >{{ option.name }}</mat-radio-button
            >
          </mat-radio-group>
        </div>
      </div>
    </div>

    <div class="col-md-6 pl-15 pr-0" *ngIf="this.form.value['carStorage']">
      <div class="report-input-form-field">
        <p class="report-input-label report-input-radio-label">Carport</p>
        <mat-radio-group
          formControlName="carStorageCarport"
          class="mt-15 mb-15"
          (change)="onChangeCarport($event)"
        >
          <mat-radio-button [value]="true" class="report-input-radio-button">Yes</mat-radio-button>
          <mat-radio-button [value]="false" class="report-input-radio-button">No</mat-radio-button>
        </mat-radio-group>
      </div>

      <mat-form-field *ngIf="this.form.value['carStorageCarport']" appearance="outline">
        <mat-label>Carport # of Cars</mat-label>
        <input
          matInput
          formControlName="carStorageCarportCars"
          id="carStorageCarportCars"
          mask="separator.0"
          thousandSeparator=""
          separatorLimit="99"
          [allowNegativeNumbers]="false"
          autocomplete="off"
        />
      </mat-form-field>
      <jaro-kit-validation-message [form]="form" [controlName]="'carStorageCarportCars'" [isRequired]="true"></jaro-kit-validation-message>

      <div class="report-input-form-field">
        <p class="report-input-label report-input-radio-label">Garage</p>
        <mat-radio-group
          formControlName="carStorageGarage"
          class="mt-15 mb-15"
          (change)="onChangeGarage($event)"
        >
          <mat-radio-button [value]="true" class="report-input-radio-button">Yes</mat-radio-button>
          <mat-radio-button [value]="false" class="report-input-radio-button">No</mat-radio-button>
        </mat-radio-group>
      </div>

      <div *ngIf="this.form.value['carStorageGarage']">
        <mat-form-field appearance="outline">
          <mat-label>Attached Garage # of Cars</mat-label>
          <input
            matInput
            formControlName="carStorageAttachedGarage"
            id="carStorageAttachedGarage"
            mask="separator.0"
            thousandSeparator=""
            separatorLimit="99"
            [allowNegativeNumbers]="false"
            autocomplete="off"
          />
        </mat-form-field>
        <jaro-kit-validation-message [form]="form" [controlName]="'carStorageAttachedGarage'" [isRequired]="true"></jaro-kit-validation-message>

        <mat-form-field appearance="outline">
          <mat-label>Detached Garage # of Cars</mat-label>
          <input
            matInput
            formControlName="carStorageDetachedGarage"
            id="carStorageDetachedGarage"
            mask="separator.0"
            thousandSeparator=""
            separatorLimit="99"
            [allowNegativeNumbers]="false"
            autocomplete="off"
          />
        </mat-form-field>
        <jaro-kit-validation-message [form]="form" [controlName]="'carStorageDetachedGarage'" [isRequired]="true"></jaro-kit-validation-message>

        <mat-form-field appearance="outline">
          <mat-label>Built-in Garage # of Cars</mat-label>
          <input
            matInput
            formControlName="carStorageBuiltInGarage"
            id="carStorageBuiltInGarage"
            mask="separator.0"
            thousandSeparator=""
            separatorLimit="99"
            [allowNegativeNumbers]="false"
            autocomplete="off"
          />
        </mat-form-field>
        <jaro-kit-validation-message [form]="form" [controlName]="'carStorageBuiltInGarage'" [isRequired]="true"></jaro-kit-validation-message>
      </div>
    </div>
  </div>
</ng-container>
