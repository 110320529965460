<div class="comparable-grid-container">
  <mat-spinner
    *ngIf="isLoading"
    value="100"
    diameter="100"
    strokeWidth="5"
    class="action-loading-spinner"
  ></mat-spinner>

  <div
    [ngClass]="{
      'translucent-loading-overlay': isLoading
    }"
  >
  <div class="comparable-grid-header">
    <div class="comparable-grid-top-right">
      <span *ngIf="visibleDataSource.length > 1"
        >{{ startComp }}-{{ endComp }} of {{ totalComp }}</span
      >
      <button
        *ngIf="visibleDataSource.length > 1"
        class="btn-nav-left"
        [disabled]="startComp <= 1"
        (click)="scrollLeft()"
      >
        <em class="fa-regular fa-angle-left" aria-hidden="true"></em>
      </button>
      <button
        *ngIf="visibleDataSource.length > 1"
        class="btn-nav-right"
        [disabled]="endComp == totalComp"
        (click)="scrollRight()"
      >
        <em class="fa-regular fa-angle-right" aria-hidden="true"></em>
      </button>
      <button
        class="jaro-kit-primary-button button-add-comparable"
        (click)="addComparable()"
        [disabled]="isLoading"
      >
        Add Comparable
      </button>
    </div>
    <div
      *ngIf="dataSource.length - visibleDataSource.length > 0"
      class="comparable-grid-top-center"
    >
      <span>{{ dataSource.length - visibleDataSource.length }} Comparable(s) Hidden</span>
      <button
        class="jaro-kit-action-button button-show-all-comparable"
        (click)="showAllHiddenComparables()"
        [disabled]="isLoading"
      >
        Show All
      </button>
    </div>
    <div class="comparable-grid-top-left ">&nbsp;</div>
  </div>

    <table
      mat-table
      [dataSource]="horizontalColumns"
      class="comparable-grid-table mat-elevation-z8"
      cdkDropList
      cdkDropListOrientation="horizontal"
      (cdkDropListDropped)="drop($event)"
    >
      <caption style="display: none">
        comparable grid
      </caption>

      <ng-container
        [matColumnDef]="index"
        *ngFor="let index of verticalRowIndex"
        [sticky]="index == SubjectColumnIndex"
      >
        <th mat-header-cell *matHeaderCellDef class="comparable-grid-header-cell">
          <jaro-kit-comparables-grid-page-header
            [comparableDto]="getComparableItemData(index)"
            [reportVersionDto]="reportVersionDto"
            [orderAssignmentId]="orderAssignmentId"
            [subjectProperty]="reportVersionDto.report.property"
            [(isViewMore)]="isViewMore"
            (onChangeViewMore)="changeViewMore($event)"
            (onHideComparable)="hideComparable($event)"
            (onEditComparable)="openCleanupModal($event)"
            (onDeleteComparable)="deleteComparable($event)"
            (partialUpdateReport)="partialUpdateReport($event)"
          ></jaro-kit-comparables-grid-page-header>
        </th>
        <td
          mat-cell
          *matCellDef="let element"
          class="comparable-grid-cell"
          [ngClass]="{
            'comparable-grid-row': true,
            'comparable-grid-row-selected': element?.key === rowSelected,
            'comparable-grid-row-subject': index == SubjectColumnIndex
          }"
        >
          <jaro-kit-comparables-grid-cell
            [comparableDto]="getComparableItemData(index)"
            [subjectDto]="getComparableItemData(SubjectColumnIndex.toString())"
            [field]="element"
            [isSelected]="isSelectedRow(element)"
            (partialUpdate)="partialUpdate($event)"
          ></jaro-kit-comparables-grid-cell>
        </td>
      </ng-container>

      <tr mat-header-row *matHeaderRowDef="verticalRowIndex; sticky: true"></tr>
      <tr
        mat-row
        *matRowDef="let row; columns: verticalRowIndex"
        (click)="clickedRow(row)"
        [ngClass]="{
          'comparable-grid-row': true,
          'comparable-grid-row-selected': row?.key === rowSelected,
          'comparable-grid-row-total-adjustment': row?.type === 'TotalAdjustment'
        }"
      ></tr>
    </table>

    <jaro-kit-sale-history
      [reportVersionDto]="reportVersionDto"
      *ngIf="!isLoading"
    ></jaro-kit-sale-history>
  </div>
</div>
