<ng-container *ngIf="form" [formGroup]="form">
  <div class="row">
    <div class="col-md-12 pl-0 pr-0">
      <p class="report-input-title pb-15">Contract Analysis</p>

      <div class="report-input-form-field">
        <p class="report-input-label report-input-radio-label mat-subheading-2">
          Contract Analyzed (Y/N)
        </p>
        <mat-radio-group id="contractIsReviewed" formControlName="contractIsReviewed" class="mt-15 mb-15">
          <mat-radio-button [value]="true" class="report-input-radio-button">Yes</mat-radio-button>
          <mat-radio-button [value]="false" class="report-input-radio-button">No</mat-radio-button>
        </mat-radio-group>
        <jaro-kit-validation-message [form]="form" [controlName]="'contractIsReviewed'" [isRequired]="true"></jaro-kit-validation-message>
      </div>

      <mat-form-field appearance="outline" class="jaro-kit-textarea">
        <mat-label>Explanation of Analysis Results</mat-label>
        <textarea
          type="text"
          id="contractAnalysisResult"
          formControlName="contractAnalysisResult"
          placeholder="Explanation of Analysis Results"
          matInput
          rows="4"
        ></textarea>
      </mat-form-field>
      <jaro-kit-validation-message [form]="form" [controlName]="'contractAnalysisResult'" [isRequired]="true"></jaro-kit-validation-message>

      <p class="report-input-title pb-15">Contract Details</p>

      <div class="row">
        <div class="col-md-6 pl-0 pr-15">
          <mat-form-field appearance="outline">
            <mat-label class="report-input-label">Sale Type</mat-label>
            <mat-select
              id="contractSaleType"
              formControlName="contractSaleType"
              disableOptionCentering
              panelClass="report-input-drop-down"
              placeholder="Select from list"
              floatLabel="never"
            >
              <mat-option *ngFor="let item of saleTypes" [value]="item.value">{{
                item.name
              }}</mat-option>
            </mat-select>
          </mat-form-field>
          <jaro-kit-validation-message [form]="form" [controlName]="'contractSaleType'" [isRequired]="true"></jaro-kit-validation-message>
        </div>
      </div>

      <div class="row">
        <div class="col-md-6 pl-0 pr-15">
          <mat-form-field appearance="outline">
            <mat-label>Contract Price</mat-label>
            <input
              matInput
              id="contractPrice"
              formControlName="contractPrice"
              autocomplete="off"
              currencyMask
              mask="separator.0"
              thousandSeparator=","
              separatorLimit="999999999"
              [allowNegativeNumbers]="true"
            />
          </mat-form-field>
          <jaro-kit-validation-message [form]="form" [controlName]="'contractPrice'" [isRequired]="true"></jaro-kit-validation-message>
        </div>
        <div class="col-md-6 pl-15 pr-0">
          <jaro-kit-date-picker
            [form]="form"
            id="contractDate"
            dateControl="contractDate"
            titleDate="Date of Contract"
            formatDate="MM/DD/YYYY"
          ></jaro-kit-date-picker>
        </div>
      </div>

      <div class="report-input-form-field">
        <p class="report-input-label report-input-radio-label">
          Property Seller Owner of Public Record?
        </p>

        <mat-radio-group id="contractIsSellerOwner" formControlName="contractIsSellerOwner" class="mt-15">
          <mat-radio-button [value]="true" class="report-input-radio-button">Yes</mat-radio-button>
          <mat-radio-button [value]="false" class="report-input-radio-button">No</mat-radio-button>
        </mat-radio-group>
        <jaro-kit-validation-message [form]="form" [controlName]="'contractIsSellerOwner'" [isRequired]="true"></jaro-kit-validation-message>
      </div>

      <mat-form-field appearance="outline">
        <mat-label>Data Source(s)</mat-label>
        <input matInput formControlName="contractDataSource" autocomplete="off" />
      </mat-form-field>
      <jaro-kit-validation-message [form]="form" [controlName]="'contractDataSource'" [isRequired]="true"></jaro-kit-validation-message>

      <p class="report-input-title pb-15">Financial Assistance</p>

      <div class="report-input-form-field">
        <p class="report-input-label report-input-radio-label">Financial Assistance</p>

        <mat-radio-group
          id="contractAnyFinancialAssistance"
          formControlName="contractAnyFinancialAssistance"
          class="mt-15"
          (change)="onChangeAnyFinancialAssistance($event)"
        >
          <mat-radio-button [value]="true" class="report-input-radio-button">Yes</mat-radio-button>
          <mat-radio-button [value]="false" class="report-input-radio-button">No</mat-radio-button>
        </mat-radio-group>
        <jaro-kit-validation-message [form]="form" [controlName]="'contractAnyFinancialAssistance'" [isRequired]="true"></jaro-kit-validation-message>
      </div>

      <div *ngIf="isShowConcession">
        <mat-form-field appearance="outline">
          <mat-label>Amount</mat-label>
          <input
            matInput
            id="contractConcessionAmount"
            formControlName="contractConcessionAmount"
            autocomplete="off"
            currencyMask
            mask="separator.0"
            thousandSeparator=","
            separatorLimit="9999999999"
            [allowNegativeNumbers]="false"
            (change)="onChangeContractConcessionAmount($event)"
          />
        </mat-form-field>
        <jaro-kit-validation-message [form]="form" [controlName]="'contractConcessionAmount'" [isRequired]="true"></jaro-kit-validation-message>

        <mat-form-field appearance="outline" class="jaro-kit-textarea">
          <mat-label>Description of Items to be Paid</mat-label>
          <textarea
            type="text"
            id="contractConcessionDescription"
            formControlName="contractConcessionDescription"
            placeholder="Description of Items to be Paid"
            matInput
            rows="4"
            maxlength="150"
          ></textarea>
        </mat-form-field>
        <jaro-kit-validation-message [form]="form" [controlName]="'contractConcessionDescription'" [isRequired]="true"></jaro-kit-validation-message>

        <div class="report-input-form-field">
          <p class="report-input-label report-input-radio-label">Unknown Amount?</p>

          <mat-radio-group
            id="contractConcessionAmountUnknown"
            formControlName="contractConcessionAmountUnknown"
            class="mt-15"
            (change)="onChangeContractConcessionAmountUnknown($event)"
            >
            <mat-radio-button [value]="true" class="report-input-radio-button"
              >Yes</mat-radio-button
            >
            <mat-radio-button [value]="false" class="report-input-radio-button"
              >No</mat-radio-button
            >
          </mat-radio-group>
          <jaro-kit-validation-message [form]="form" [controlName]="'contractConcessionAmountUnknown'" [isRequired]="true"></jaro-kit-validation-message>
        </div>
      </div>
    </div>
  </div>
</ng-container>
