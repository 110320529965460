import { ReportInputDataFormFieldModel } from 'src/app/features/shared/forms/models/report-input-data-field-form-model/report-input-date-field-form-model';
import { ArrayFormatPipe } from 'src/app/shared/pipes/array-format/array-format.pipe';
import { YesNoFormatPipe } from 'src/app/shared/pipes/yes-no-format/yes-no-format.pipe';

export const ReportInputTemplateConfigAdditionalObservationsSection: ReportInputDataFormFieldModel[] =
  [
    {
      formControlName: 'additionalFireplaces',
      format: {
        pipeType: YesNoFormatPipe,
      },
    },
    {
      formControlName: 'additionalPatioDeck',
      format: {
        pipeType: YesNoFormatPipe,
      },
    },
    {
      formControlName: 'additionalPool',
      format: {
        pipeType: YesNoFormatPipe,
      },
    },
    {
      formControlName: 'additionalWoodstoves',
      format: {
        pipeType: YesNoFormatPipe,
      },
    },
    {
      formControlName: 'additionalFence',
      format: {
        pipeType: YesNoFormatPipe,
      },
    },
    {
      formControlName: 'additionalPorch',
      format: {
        pipeType: YesNoFormatPipe,
      },
    },
    {
      formControlName: 'additionalAttic',
      format: {
        pipeType: ArrayFormatPipe,
      }
    },
    {
      formControlName: 'additionalHeating',
      format: {
        pipeType: ArrayFormatPipe,
      },
    },
    {
      formControlName: 'additionalCooling',
      format: {
        pipeType: ArrayFormatPipe,
      },
    },
    {
      formControlName: 'additionalOtherAmenitiesDescription',
      format: {
        pipeType: ArrayFormatPipe,
      },
    },
    {
      formControlName: 'additionalAppliances',
      format: {
        pipeType: ArrayFormatPipe,
      },
    },
    {
      formControlName: 'additionalAdditionalFeatures',
      format: {
        pipeType: ArrayFormatPipe,
      },
    },
    {
      formControlName: 'additionalOtherAmenitiesDescription',
      format: {
        pipeType: ArrayFormatPipe,
      },
    },
  ];
