import { Component, Inject, OnInit } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { MatRadioChange } from '@angular/material/radio';
import { ContentServiceInterface } from 'src/app/interface/content/content-service-interface';
import { ItemData } from 'src/app/shared/components/multiple-combo-box/multiple-combo-box.component';
import InjectionSymbol from 'src/app/shared/injection/injection-symbol';

@Component({
  selector: 'jaro-kit-appraisal-update-fha-hud-92051',
  templateUrl: './appraisal-update.component.html',
  styleUrls: ['./appraisal-update.component.scss'],
})
export class AppraisalUpdateFHAHUD92051Component implements OnInit {
  form: FormGroup;
  readonly uspapReportingOptions: ItemData[] = [
    { name: 'Appraisal Report', value: 'appraisal report', selected: false },
    { name: 'Restricted Appraisal Report', value: 'restricted appraisal report', selected: false },
  ];

  isShowRightsAppraisedOther: boolean;
  rightsAppraisedOptions: ItemData[] = [];

  constructor(
    @Inject(InjectionSymbol.ContentService)
    public contentService: ContentServiceInterface
  ) {
    this.rightsAppraisedOptions = this.contentService.getContentByKey(
      'propertyRightAppraised',
      true
    );
  }

  ngOnInit(): void {
    this.configureRightsAppraised(this.form?.value?.subjectRightsAppraised);    
    this.configureContractIsNotApplicable(this.form?.value?.subjectContractIsNotApplicable);
  }

  onChangeRightsAppraised($event: MatRadioChange) {
    const subjectRightsAppraised = $event.value;

    if (subjectRightsAppraised !== 'other') {
        this.isShowRightsAppraisedOther = false;      
        this.clearValueAndErrors('subjectRightsAppraisedOther');      
    } else {
      this.isShowRightsAppraisedOther = true;
      // TODO: Uncomment when adding required validations to HUD
      //this.form.controls['subjectRightsAppraisedOther'].setValidators([Validators.required]);     
    }
    
    this.form.controls['subjectRightsAppraisedWithOther'].setValue(subjectRightsAppraised);    
    this.form.controls['subjectRightsAppraisedWithOther'].markAsDirty();    
  }
  
  onChangeRightsAppraisedOther($event) {
    const subjectRightsAppraisedOther = $event.target.value;
    this.form.controls['subjectRightsAppraisedWithOther'].setValue(
      `Other - ${subjectRightsAppraisedOther}`
    );
  
    this.form.controls['subjectRightsAppraisedWithOther'].markAsDirty();
  }  

  onChangeContractIsNotApplicable($event: MatRadioChange) {
    const isContractNotApplicable = $event.value;
    if (isContractNotApplicable) {
      ['subjectContractPrice', 'subjectContractDate'].forEach((item) =>
        this.clearValueAndErrors(item)
      );
    } else {
      // TODO: Uncomment when adding required validations to HUD
      //this.form.controls['subjectContractPrice'].setValidators([Validators.required]);
      this.form.controls['subjectContractPrice'].markAsDirty();
      this.form.controls['subjectContractPrice'].updateValueAndValidity();
      
      // TODO: Uncomment when adding required validations to HUD
      //this.form.controls['subjectContractDate'].setValidators([Validators.required]);      
      this.form.controls['subjectContractDate'].markAsDirty();
      this.form.controls['subjectContractDate'].updateValueAndValidity();
    }

    this.form.controls['subjectContractIsNotApplicable'].setValue(isContractNotApplicable);    
    this.form.controls['subjectContractIsNotApplicable'].markAsDirty();
  }  

  private configureRightsAppraised(subjectRightsAppraised: string) {
    if (subjectRightsAppraised !== 'other') {
      this.isShowRightsAppraisedOther = false;   
      this.form.controls['subjectRightsAppraisedOther'].clearValidators();           
    } else {
      this.isShowRightsAppraisedOther = true;
      // TODO: Uncomment when adding required validations to HUD
      //this.form.controls['subjectRightsAppraisedOther'].setValidators([Validators.required]);     
    }   
  } 

  private configureContractIsNotApplicable(isContractNotApplicable: boolean) {
    if (!isContractNotApplicable) {
      // TODO: Uncomment when adding required validations to HUD
      //this.form.controls['subjectContractPrice'].setValidators([Validators.required]);     
      //this.form.controls['subjectContractDate'].setValidators([Validators.required]);      
    }
  }

  private clearValueAndErrors(formControlName: string, isMarkAsDirty = true) {
    const formControl = this.form.controls[formControlName];
    if (formControl) {
      formControl.setValue(null);
      formControl.clearValidators();
      formControl.setErrors(null);
      if(isMarkAsDirty) formControl.markAsDirty();
      formControl.updateValueAndValidity();
    }
  }
}
