import { Injectable } from '@angular/core';
import { PropertyRecordDataDto, UpdateCustomRecordDto } from '@proxy/property/property-record/property-records/models';
import { PropertyRecordDto, UpdateCustomAttributeDto } from '@proxy/property/property-record/property-records/v1';

@Injectable({
    providedIn: 'root',
  })
@Injectable()
export class UpdateCustomRecordDtoFactory
{
  getCustomUpdateDto(propRecDto: PropertyRecordDto): UpdateCustomRecordDto {
    const updateCustomDto: Partial<UpdateCustomRecordDto> = {};
    const listing = propRecDto.cleanedListing;

    for(let prop in listing)
    {
        if (!Object.prototype.hasOwnProperty.call(listing, prop)) {
            continue;
        }

        // Map strings
        if(typeof(listing[prop]) === "string") {
          updateCustomDto[prop] = {
            custom: listing[prop],
            selected: "custom"
         } as UpdateCustomAttributeDto;
          continue;
        }

        // If does not have selected then must not be PropertyRecordDataDto
        if(!listing[prop] && typeof(listing[prop]["selected"]) === "undefined") {
          continue;
        }

        const dataDto = listing[prop] as PropertyRecordDataDto;

        updateCustomDto[prop] = {
           custom: dataDto.custom,
           selected: dataDto.selected
        } as UpdateCustomAttributeDto;
    }
    return updateCustomDto as UpdateCustomRecordDto;
  }
}
