<div class="report-input-header">
  <div class="report-input-header-left">
    <jaro-kit-order-summary-banner
      [orderData]="orderData"
      [subjectProperty]="reportVersionDto.report.property"
      [orderAssignmentId] = "reportVersionDto.orderAssignmentId"
      [showBoostDataViewButton] = "showBoostDataViewButton"
    ></jaro-kit-order-summary-banner>
  </div>

  <div class="report-input-header-right">
    <button
      mat-icon-button
      [matMenuTriggerFor]="moreAction"
      class="button-action"
    >
      <em
        [ngClass]="{
          'fa-solid fa-circle': true,
          'circle-red': !isLoading && isQCLoaded && errorCount > 0,
          'circle-yellow': !isLoading && isQCLoaded && errorCount == 0 && warningCount > 0,
          'circle-green': !isLoading && isQCLoaded && errorCount == 0 && warningCount == 0,
          'circle-grey': isLoading || !isQCLoaded
        }"
        *ngIf="isActiveQualityCheck"
      ></em>
      <em class="fa-regular fa-ellipsis-vertical jaro-kit-primary-icon" aria-hidden="true"></em>
    </button>
  </div>
</div>

<mat-menu #moreAction="matMenu" xPosition="before" class="photo-menu">
  <div mat-menu-item *ngIf="isActivePreviewReport">
    <jaro-kit-preview-report-button
      [orderAssignmentId]="orderAssignmentId"
      [productName]="productName"
      [reportVersionDto]="reportVersionDto"
    ></jaro-kit-preview-report-button>
  </div>
  <div mat-menu-item>
    <jaro-kit-export-report-button
      [orderAssignmentId]="orderAssignmentId"
      [productName]="productName"
      [reportVersionDto]="reportVersionDto"
    ></jaro-kit-export-report-button>
  </div>
  <div mat-menu-item>
    <jaro-kit-export-workfile-button
      [orderAssignmentId]="orderAssignmentId">
    </jaro-kit-export-workfile-button>
  </div>

  <div mat-menu-item *ngIf="isActiveQualityCheck">
    <jaro-kit-quality-check
      [isQCLoaded]="isQCLoaded"
      [isLoading]="isLoading"
      [errorCount]="errorCount"
      [warningCount]="warningCount"
      (onOpenQualityCheck)="openQualityCheck()"
    >
    </jaro-kit-quality-check>
  </div>
</mat-menu>
