import { CleanedListingDto } from '@proxy/property/property-record/property-records/v1/models';

export const Validations = {
  date: {
    pattern: [/^\s*(1[012]|0?[1-9])\/((?:19|20)\d{2})\s*$/, /^\s*(1[012]|0?[1-9])\/(\d{2})\s*$/],
    message: 'Enter in mm/yy or mm/yyyy format only',
  },
  amount: {
    pattern: [/^\d*$/],
    message: 'Enter valid amount (E.g.99999)',
  },
  price: {
    pattern: [/^\d{1,10}$/],
    message: 'Enter  valid amount (maximum 10 digits)',
  },
  amountNineDigits: {
    pattern: [/^\d{1,9}$/],
    message: 'Enter  valid amount (maximum 9 digits)',
  },
  count: {
    pattern: [/^\d*$/],
    message: 'Enter valid number ',
  },
  decimalAmount: {
    pattern: [/[-+]?\d*[.,]\d+|\d+/],
    message: 'Enter valid amount (E.g.99999.99)',
  },
  allowNumberAndUnknown: {
    pattern: [/^\d{1,10}$/, /^unk$/i, /^unknown$/i],
    message: 'Enter a valid number or Unk if unknown',
  },
  roomCount: {
    pattern: [/^\d$/],
    message: 'Enter a single digit for the room count',
  },
  area: {
    pattern: [/^\d{1,5}$/],
    message: 'Enter up to 5 digits for area',
  },
};

export type AttributeConfig = {
  title: string;
  isAdjustableValue: boolean;
  isEditable: boolean;
  isCommentary: boolean;
  isDate: boolean;
  isDropdown: boolean;
  key: string;
  isSingleField: boolean | null;
  isMultiSelect: boolean | null;
  validationPatterns: RegExp[];
  validationErrorMessage: string;
  toolTip: string;
  valueTypeName: 'string' | 'string[]' | 'integer' | 'float' | 'boolean' | 'GeoLocation';
  formatFunc: (...args: any[]) => string;
};

export class AttributeRecordFactory {
  public static getConfig(): Record<keyof CleanedListingDto, AttributeConfig> {
    return {
      // Adjustment Grid Start
      // ---------------------

      closePrice: {
        title: 'Close Price',
        isAdjustableValue: true,
        isEditable: true,
        isCommentary: false,
        isDate: false,
        isDropdown: false,
        key: '',
        validationPatterns: Validations.price.pattern,
        validationErrorMessage: Validations.price.message,
        valueTypeName: 'integer',
      },
      listPrice: {
        title: 'List Price',
        isAdjustableValue: true,
        isEditable: true,
        isCommentary: false,
        isDate: false,
        isDropdown: false,
        key: '',
        validationPatterns: Validations.price.pattern,
        validationErrorMessage: Validations.price.message,
        valueTypeName: 'integer',
      },
      onMarketDate: {
        title: 'Listing Date',
        isAdjustableValue: true,
        isEditable: true,
        isCommentary: false,
        isDate: true,
        isDropdown: false,
        key: '',
        validationErrorMessage: Validations.date.message,
        validationPatterns: Validations.date.pattern,
        valueTypeName: 'string',
      },
      dataSourceName: {
        title: 'Data Source Name',
        isAdjustableValue: true,
        isEditable: true,
        isCommentary: false,
        isDate: false,
        valueTypeName: 'string',
      },
      mlsListingNumber: {
        title: 'Listing ID',
        isAdjustableValue: true,
        isEditable: true,
        isCommentary: false,
        isDate: false,
        isDropdown: false,
        key: '',
        valueTypeName: 'string',
      },
      verificationSources: {
        title: 'Verification Source Name',
        isAdjustableValue: true,
        isEditable: true,
        isCommentary: false,
        isDate: false,
        key: '',
        valueTypeName: 'string',
      },
      daysOnMarket: {
        title: 'Days On Market',
        isAdjustableValue: true,
        isEditable: true,
        isCommentary: false,
        isDate: false,
        validationPatterns: Validations.allowNumberAndUnknown.pattern,
        validationErrorMessage: Validations.allowNumberAndUnknown.message,
        valueTypeName: 'string',
      },
      comparableSaleType: {
        title: 'Sale Type',
        isAdjustableValue: true,
        isEditable: true,
        isCommentary: false,
        isDate: false,
        isDropdown: true,
        key: 'saleTypeComps',
        valueTypeName: 'string',
      },
      concessions: {
        title: 'Financing Type',
        isAdjustableValue: true,
        isEditable: true,
        isCommentary: false,
        isDate: false,
        isDropdown: true,
        key: 'financingType',
        valueTypeName: 'string',
        isSingleField: true,
      },
      concessionsAmount: {
        title: 'Concession Amount',
        isAdjustableValue: true,
        isEditable: true,
        isCommentary: false,
        isDate: false,
        isDropdown: false,
        key: '',
        validationPatterns: Validations.amountNineDigits.pattern,
        validationErrorMessage: Validations.amountNineDigits.message,
        valueTypeName: 'integer',
      },
      standardStatus: {
        title: 'Listing Status',
        isAdjustableValue: true,
        isEditable: true,
        isCommentary: false,
        isDate: false,
        isDropdown: true,
        key: 'listingStatusComps',
        valueTypeName: 'string',
      },
      closeDate: {
        title: 'Sale Date',
        isAdjustableValue: true,
        isEditable: true,
        isDate: true,
        isDropdown: false,
        key: '',
        isCommentary: false,
        validationErrorMessage: Validations.date.message,
        validationPatterns: Validations.date.pattern,
        valueTypeName: 'string',
      },
      listingContractDate: {
        title: 'Listing Contract Date',
        isAdjustableValue: true,
        isEditable: true,
        isDate: true,
        isDropdown: false,
        key: '',
        isCommentary: false,
        validationErrorMessage: Validations.date.message,
        validationPatterns: Validations.date.pattern,
        valueTypeName: 'string',
      },
      expirationDate: {
        title: 'Listing Expiration Date',
        isAdjustableValue: true,
        isEditable: true,
        isCommentary: false,
        isDate: true,
        isDropdown: false,
        key: '',
        validationErrorMessage: Validations.date.message,
        validationPatterns: Validations.date.pattern,
        valueTypeName: 'string',
      },
      withdrawnDate: {
        title: 'Listing Withdrawn Date',
        isAdjustableValue: true,
        isEditable: true,
        isCommentary: false,
        isDate: true,
        isDropdown: false,
        key: '',
        validationErrorMessage: Validations.date.message,
        validationPatterns: Validations.date.pattern,
        valueTypeName: 'string',
      },
      location: {
        title: 'Location',
        isAdjustableValue: true,
        isEditable: true,
        isCommentary: false,
        isDate: false,
        isDropdown: true,
        key: 'locationComps',
        valueTypeName: 'string',
      },
      locationFactors: {
        title: 'Location Factors',
        isAdjustableValue: true,
        isEditable: true,
        isCommentary: false,
        isDate: false,
        isDropdown: true,
        key: 'locationFactorsComps',
        toolTip: 'You can enter multiple values using a comma(,)',
        valueTypeName: 'string[]',
        isMultiSelect: true,
      },
      leaseholdFeeSimple: {
        title: 'Property Rights Appraised',
        isAdjustableValue: true,
        isEditable: true,
        isCommentary: false,
        isDate: false,
        isDropdown: true,
        isSingleField: true,
        key: 'propertyRightAppraisedComps',
        valueTypeName: 'string',
      },
      site: {
        title: 'Site Area (Sq.Ft.)',
        isAdjustableValue: true,
        isEditable: true,
        isCommentary: false,
        isDate: false,
        isDropdown: false,
        key: '',
        validationPatterns: Validations.count.pattern,
        validationErrorMessage: Validations.count.message,
        valueTypeName: 'integer',
      },
      siteValue: {
        title: 'Site Value',
        isAdjustableValue: true,
        isEditable: true,
        isCommentary: false,
        isDate: false,
        isDropdown: false,
        key: '',
        validationPatterns: Validations.amount.pattern,
        validationErrorMessage: Validations.amount.message,
        valueTypeName: 'integer',
      },
      view: {
        title: 'View',
        isAdjustableValue: true,
        isEditable: true,
        isCommentary: false,
        isDate: false,
        isDropdown: true,
        key: 'viewComps',
        valueTypeName: 'string',
      },
      viewFactors: {
        title: 'View Factors',
        isAdjustableValue: true,
        isEditable: true,
        isCommentary: false,
        isDate: false,
        isDropdown: true,
        key: 'viewFactorsComps',
        valueTypeName: 'string[]',
        isMultiSelect: true,
      },

      attachmentType: {
        title: 'Attachment Type',
        isAdjustableValue: true,
        isEditable: true,
        isCommentary: false,
        isDate: false,
        isDropdown: true,
        key: 'attachmentTypeComp',
        valueTypeName: 'string',
      },

      storiesTotal: {
        title: 'Total Stories Count',
        isAdjustableValue: true,
        isEditable: true,
        isCommentary: false,
        isDate: false,
        isDropdown: false,
        key: '',
        validationPatterns: Validations.decimalAmount.pattern,
        validationErrorMessage: Validations.decimalAmount.message,
        valueTypeName: 'integer',
      },
      style: {
        title: 'Design (Style)',
        isAdjustableValue: true,
        isEditable: true,
        isCommentary: false,
        isDate: false,
        isDropdown: false,
        key: '',
        valueTypeName: 'string',
      },
      quality: {
        title: 'Quality',
        isAdjustableValue: true,
        isEditable: true,
        isCommentary: false,
        isDate: false,
        isDropdown: true,
        key: 'overallQuality',
        valueTypeName: 'string',
      },
      condition: {
        title: 'Condition',
        isAdjustableValue: true,
        isEditable: true,
        isCommentary: false,
        isDate: false,
        isDropdown: true,
        key: 'overallCondition',
        valueTypeName: 'string',
      },
      actualAge: {
        title: 'Actual age',
        isAdjustableValue: true,
        isEditable: true,
        isCommentary: false,
        isDate: false,
        isDropdown: false,
        key: '',
        validationPatterns: Validations.count.pattern,
        validationErrorMessage: Validations.count.message,
        valueTypeName: 'integer',
      },
      aboveGradeBedrooms: {
        title: 'Above Grade Bedroom Count',
        isAdjustableValue: true,
        isEditable: true,
        isCommentary: false,
        isDate: false,
        isDropdown: false,
        key: '',
        validationPatterns: Validations.count.pattern,
        validationErrorMessage: Validations.count.message,
        valueTypeName: 'integer',
      },
      aboveGradeFullBath: {
        title: 'Above Grade Full Bathroom Count',
        isAdjustableValue: true,
        isEditable: true,
        isCommentary: false,
        isDate: false,
        isDropdown: false,
        key: '',
        validationPatterns: Validations.count.pattern,
        validationErrorMessage: Validations.count.message,
        valueTypeName: 'integer',
      },
      aboveGradePartialBath: {
        title: 'Above Grade Partial Bathroom Count',
        isAdjustableValue: true,
        isEditable: true,
        isCommentary: false,
        isDate: false,
        isDropdown: false,
        key: '',
        validationPatterns: Validations.count.pattern,
        validationErrorMessage: Validations.count.message,
        valueTypeName: 'integer',
      },
      aboveGradeRoomCount: {
        title: 'Above Grade Room Count',
        isAdjustableValue: true,
        isEditable: true,
        isCommentary: false,
        isDate: false,
        isDropdown: false,
        key: '',
        validationPatterns: Validations.count.pattern,
        validationErrorMessage: Validations.count.message,
        valueTypeName: 'integer',
      },

      bedroomsTotal: {
        title: 'Total Bedroom Count',
        isAdjustableValue: true,
        isEditable: true,
        isCommentary: false,
        isDate: false,
        isDropdown: false,
        key: '',
        validationPatterns: Validations.count.pattern,
        validationErrorMessage: Validations.count.message,
        valueTypeName: 'integer',
      },
      bathroomsTotal: {
        title: 'Total Bathroom Count',
        isAdjustableValue: true,
        isEditable: true,
        isCommentary: false,
        isDate: false,
        isDropdown: false,
        key: '',
        validationPatterns: Validations.count.pattern,
        validationErrorMessage: Validations.count.message,
        valueTypeName: 'integer',
      },
      livingArea: {
        title: 'Living Area',
        isAdjustableValue: true,
        isEditable: true,
        isCommentary: false,
        isDate: false,
        isDropdown: false,
        key: '',
        validationPatterns: Validations.count.pattern,
        validationErrorMessage: Validations.count.message,
        valueTypeName: 'integer',
      },
      basement: {
        title: 'Basement',
        isAdjustableValue: true,
        isEditable: true,
        isCommentary: false,
        isDate: false,
        isDropdown: false,
        key: '',
        valueTypeName: 'string',
      },
      basementType: {
        title: 'Basement  Type',
        isAdjustableValue: true,
        isEditable: true,
        isCommentary: false,
        isDate: false,
        isDropdown: false,
        key: '',
        valueTypeName: 'string',
      },
      basementEntry: {
        title: 'Basement Entry',
        isAdjustableValue: true,
        isEditable: true,
        isCommentary: false,
        isDate: false,
        isDropdown: true,
        key: 'basementEntryComps',
        valueTypeName: 'string',
      },
      belowGradeTotalArea: {
        title: 'Below Grade Total Area',
        isAdjustableValue: true,
        isEditable: true,
        isCommentary: false,
        isDate: false,
        isDropdown: false,
        key: '',
        validationPatterns: Validations.area.pattern,
        validationErrorMessage: Validations.area.message,
        valueTypeName: 'integer',
      },
      belowGradeFinishedArea: {
        title: 'Below Grade Finished Area',
        isAdjustableValue: true,
        isEditable: true,
        isCommentary: false,
        isDate: false,
        isDropdown: false,
        key: '',
        validationPatterns: Validations.area.pattern,
        validationErrorMessage: Validations.area.message,
        valueTypeName: 'integer',
      },
      belowGradeBedrooms: {
        title: 'Below Grade Bedroom Count',
        isAdjustableValue: true,
        isEditable: true,
        isCommentary: false,
        isDate: false,
        isDropdown: false,
        key: '',
        validationPatterns: Validations.roomCount.pattern,
        validationErrorMessage: Validations.roomCount.message,
        valueTypeName: 'integer',
      },
      belowGradeRecRoomCount: {
        title: 'Below Grade Recreation Room Count',
        isAdjustableValue: true,
        isEditable: true,
        isCommentary: false,
        isDate: false,
        isDropdown: false,
        key: '',
        validationPatterns: Validations.roomCount.pattern,
        validationErrorMessage: Validations.roomCount.message,
        valueTypeName: 'integer',
      },
      belowGradeFullBath: {
        title: 'Below Grade Full Bathroom Count',
        isAdjustableValue: true,
        isEditable: true,
        isCommentary: false,
        isDate: false,
        isDropdown: false,
        key: '',
        validationPatterns: Validations.roomCount.pattern,
        validationErrorMessage: Validations.roomCount.message,
        valueTypeName: 'integer',
      },
      belowGradePartialBath: {
        title: 'Below Grade Partial Bathroom Count',
        isAdjustableValue: true,
        isEditable: true,
        isCommentary: false,
        isDate: false,
        isDropdown: false,
        key: '',
        validationPatterns: Validations.roomCount.pattern,
        validationErrorMessage: Validations.roomCount.message,
        valueTypeName: 'integer',
      },
      belowOther: {
        title: 'Below Other',
        isAdjustableValue: true,
        isEditable: true,
        isCommentary: false,
        isDate: false,
        isDropdown: false,
        key: '',
        validationPatterns: Validations.roomCount.pattern,
        validationErrorMessage: Validations.roomCount.message,
        valueTypeName: 'integer',
      },
      functionalUtility: {
        title: 'Functional Utility',
        isAdjustableValue: true,
        isEditable: true,
        isCommentary: false,
        isDate: false,
        isDropdown: false,
        key: '',
        valueTypeName: 'string',
      },
      heating: {
        title: 'Heating',
        isAdjustableValue: true,
        isEditable: true,
        isCommentary: false,
        isDate: false,
        isDropdown: false,
        key: '',
        valueTypeName: 'string',
      },
      cooling: {
        title: 'Cooling',
        isAdjustableValue: true,
        isEditable: true,
        isCommentary: false,
        isDate: false,
        isDropdown: false,
        key: '',
        valueTypeName: 'string',
      },
      greenEnergyEfficient: {
        title: 'Energy Efficient Items',
        isAdjustableValue: true,
        isEditable: true,
        isCommentary: false,
        isDate: false,
        isDropdown: false,
        key: '',
        valueTypeName: 'string',
      },
      attachedGarageSpaces: {
        title: 'Attached Garage Spaces',
        isAdjustableValue: true,
        isEditable: true,
        isCommentary: false,
        isDate: false,
        isDropdown: false,
        key: '',
        validationPatterns: Validations.count.pattern,
        validationErrorMessage: Validations.count.message,
        valueTypeName: 'integer',
      },
      detachedGarageSpaces: {
        title: 'Detached Garage Spaces',
        isAdjustableValue: true,
        isEditable: true,
        isCommentary: false,
        isDate: false,
        isDropdown: false,
        key: '',
        validationPatterns: Validations.count.pattern,
        validationErrorMessage: Validations.count.message,
        valueTypeName: 'integer',
      },
      builtinGarageSpaces: {
        title: 'Built-in Garage Spaces',
        isAdjustableValue: true,
        isEditable: true,
        isCommentary: false,
        isDate: false,
        isDropdown: false,
        key: '',
        validationPatterns: Validations.count.pattern,
        validationErrorMessage: Validations.count.message,
        valueTypeName: 'integer',
      },
      carportSpaces: {
        title: 'Carport Spaces',
        isAdjustableValue: true,
        isEditable: true,
        isCommentary: false,
        isDate: false,
        isDropdown: false,
        key: '',
        validationPatterns: Validations.count.pattern,
        validationErrorMessage: Validations.count.message,
        valueTypeName: 'integer',
      },
      porchPatioDeck: {
        title: 'Porch / Patio / Deck',
        isAdjustableValue: true,
        isEditable: true,
        isCommentary: false,
        isDate: false,
        isDropdown: false,
        key: '',
        valueTypeName: 'string',
      },
    } as Record<keyof CleanedListingDto, AttributeConfig>;
  }
}
