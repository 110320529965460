import { Confirmation } from '@abp/ng.theme.shared';
import { Component, EventEmitter, Inject, Input, OnInit, Output } from '@angular/core';
import { ReportVersionWithLinksDto } from '@proxy/bff/activity/reports/v1';
import { PartialUpdatePdfDocumentModel } from 'src/app/features/shared/forms/models/report-input-document/partial-update-pdf-document.model';
import { ReportDocumentServiceInterface } from 'src/app/interface/bff/activity/report-document-interface.service';
import { ReportsServiceInterface } from 'src/app/interface/bff/activity/reports-service-interface';
import InjectionSymbol from 'src/app/shared/injection/injection-symbol';
import { MessageService } from 'src/app/shared/services/message.service';
import { DownloadFile } from 'src/app/shared/utils/download-file/download-file';




@Component({
  selector: 'jaro-kit-contract-list',
  templateUrl: './contract-list.component.html',
  styleUrls: ['./contract-list.component.scss']
})
export class ContractListComponent implements OnInit {

  @Input() reportVersion: ReportVersionWithLinksDto;
  @Input() contractDocuments: ContractDocument[];

  @Output() onOpenDocumentPreview = new EventEmitter<PartialUpdatePdfDocumentModel>();
  @Output() onOpenContractDocumentForExtraction = new EventEmitter<ContractDocument>();
  @Output() onDeleteContractDocument = new EventEmitter<PartialUpdatePdfDocumentModel>();

  constructor(
    private downloadFile: DownloadFile,
     private msgService: MessageService,
     @Inject(InjectionSymbol.ActivityReportsService)
     public activityReportsService: ReportsServiceInterface,
    @Inject(InjectionSymbol.ReportDocumentService)
    public reportDocumentService: ReportDocumentServiceInterface,) { }

  ngOnInit(): void {

  }

  openDocumentPreview(document: PartialUpdatePdfDocumentModel) {
    this.onOpenDocumentPreview.emit(document);
  }

  openContractDocumentForExtraction(document: ContractDocument) {
    this.onOpenContractDocumentForExtraction.emit(document);
  }

  downloadContractDocument(document: ContractDocument) {
    if (document?.fileUrl) {
      this.downloadFile.downloadFileBrowserDialog(document?.fileUrl, document.title, document.fileExtension);
    }
  }

  downloadExtractContractDocument(document: ContractDocument) {
    if (document?.latestExtractedVersion?.url) {
      this.downloadFile.downloadFileBrowserDialog(document?.latestExtractedVersion?.url, document.title, document.fileExtension);
    }
  }

  removeContractDocument(document: PartialUpdatePdfDocumentModel) {
    this.msgService
      .confirm(
        'Remove contract document',
        'Are you sure want to remove contract document? This action cannot be undone.'
      )
      .subscribe({
        next: (status: Confirmation.Status) => {
          if (status === Confirmation.Status.confirm) {
            this.deleteContractDocument(document);
          }
        },
      });
  }

  private deleteContractDocument(document: PartialUpdatePdfDocumentModel) {
    this.onDeleteContractDocument.emit(document);
  }

  private logActionError(err: any): void {
    this.msgService.error(err).subscribe((_status: Confirmation.Status) => {});
  }


  isInReport(document: ContractDocument) : boolean {
    if(document.reportDocumentId)
      return true;
    return false;
  }

}



export class ContractDocument {
  documentId: string;
  title: string;
  fileExtension: string;
  creationTime: string;
  updatedTime?: string;
  fileUrl: string;
  reportDocumentId?: string;
  currentSectionId?: string;
  latestExtractedVersion?: ExtractedDocument;
  source: string;
  documentCategory:string;
}

export class ExtractedDocument {
  version: number;
  documentFileVersion: number;
  url: string;
  isFileAvailable: boolean;
  creationTime?: string;
  pages: number[];
}
