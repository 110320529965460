import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { faLocationDot } from "@fortawesome/free-solid-svg-icons";
import {
  NgxGalleryAnimation,
  NgxGalleryComponent,
  NgxGalleryImage,
  NgxGalleryLayout,
  NgxGalleryOptions
} from '@kolkov/ngx-gallery';
import { GeoLocation } from '@proxy/property/property-job/property-jobs';
import { PropertyRecordDto } from '@proxy/property/property-record/property-records/v1';
import { MarketImageCarouselModalRequest } from '../../market-image-carousel-modal/market-image-carousel-modal-request';
import { MarketImageCarouselModalComponent } from '../../market-image-carousel-modal/market-image-carousel-modal.component';

@Component({
  selector: 'jaro-kit-market-cleanup-property-visual-media',
  templateUrl: './market-cleanup-property-visual-media.component.html',
  styleUrls: ['./market-cleanup-property-visual-media.component.scss'],
})
export class MarketCleanupPropertyVisualMediaComponent implements OnInit {
  @Input() property: PropertyRecordDto;
  @Input() isLoading: boolean = true;
  @ViewChild(NgxGalleryComponent) ngxGalleryComponent;

  address: string;
  geoLocation: GeoLocation;
  currentImage: string;
  images: string[] = [];
  galleryOptions: NgxGalleryOptions[];
  galleryImages: NgxGalleryImage[];

  markerSymbol: google.maps.Symbol = {
    path: faLocationDot.icon[4] as string,
		fillColor: 'Red',
		fillOpacity: 1,
		strokeColor: 'black',
		strokeWeight: 1,
    scale: 0.07,
  };

  constructor(public matDialog: MatDialog) {
  }

  ngOnInit(): void {
    const listing = this.property.cleanedListing;
    this.address = listing.address;
    this.geoLocation = listing.geolocation;
    this.images = listing.images;
    this.currentImage = this.images?.length > 0 ? this.images[0] : null;
    this.setupGallery();

    this.galleryOptions = [
      {
        height: '300px',
        width: '485px', // golden ratio 1.618
        thumbnails: false,
        imageAnimation: NgxGalleryAnimation.Slide,
        previewFullscreen: false,
        thumbnailsArrows: false,
        layout: NgxGalleryLayout.ThumbnailsTop,
        lazyLoading: true,
        startIndex: 0,
        preview: false,
        arrowPrevIcon: 'fa fa-angle-left',
        arrowNextIcon: 'fa fa-angle-right',
        previewAnimation: true,
        imageInfinityMove: true,
      }
    ];
  }

  changeSelectPhoto() {
    this.currentImage = this.images[this.ngxGalleryComponent.selectedIndex];
  }

  openImageCarouselModal(): void {
    if(this.isLoading || this.currentImage === null)
    {
      return;
    }

    const dialogConfig = new MatDialogConfig();
    dialogConfig.data = {
      images: this.images,
      currentImage: this.currentImage
    } as MarketImageCarouselModalRequest;
    dialogConfig.panelClass = 'market-image-carousel-modal';
    const dialogRef = this.matDialog.open(
      MarketImageCarouselModalComponent,
      dialogConfig
    );
    dialogRef.afterClosed().subscribe({
      next: (result: MarketImageCarouselModalRequest) => {
          const currentImageIndex = this.images.indexOf(result.currentImage);
          if(currentImageIndex >= 0) {
            this.ngxGalleryComponent.show(currentImageIndex);
          }
          this.currentImage = result.currentImage;
        }
      });
  }

  private setupGallery() {
    this.galleryImages = [];
    this.images.forEach((image: string) => {
      const galleryImage: NgxGalleryImage = {};
      const defaultURLNotFound = './assets/images/report-input/photo-failed-to-load.svg';
      galleryImage.small = image || defaultURLNotFound
      galleryImage.medium = image || defaultURLNotFound;
      galleryImage.big = image || defaultURLNotFound;
      this.galleryImages.push(galleryImage);
    });
  }
}
