<nav aria-label="Expand Menu"
  [ngClass]="{
    'halo-global-sidebar-menu': true,
    'halo-global-sidebar-menu-shadow': !expanded
  }"
>
  <img
    class="halo-global-sidebar-menu-logo"
    src="../../../assets/images/icons/logo.png"
    [routerLink]="['/admin/']"
    alt="logo"
  />
  <div
    [ngClass]="{
      'halo-global-sidebar-menu-btn': true,
      'halo-global-sidebar-active': activeMenuName == 'search'
    }"
    (click)="toggle('search')"
  >
    <div class="halo-global-sidebar-menu-icon">
      <em class="fas fa-search"></em>
    </div>
  </div>
  <div class="halo-global-left-menu-bottom">
    <div class="halo-global-sidebar-menu-btn" (click)="logout()">
      <div class="halo-global-sidebar-menu-icon">
        <em class="far fa-sign-out"></em>
      </div>
    </div>
  </div>
</nav>
<nav class="halo-global-sidebar-expanded" *ngIf="expanded" aria-label="Order Search Results">
  <jaro-kit-order-search-results (onClick)="closePanel()"> </jaro-kit-order-search-results>
</nav>
