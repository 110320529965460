import { Confirmation } from '@abp/ng.theme.shared';
import { Component, EventEmitter, Inject, Input, Output } from '@angular/core';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { PartialUpdateImageDocumentModel } from 'src/app/features/shared/forms/models/report-input-document/partial-update-image-document.model';
import { PartialUpdatePdfDocumentModel } from 'src/app/features/shared/forms/models/report-input-document/partial-update-pdf-document.model';
import { DocumentsServiceInterface } from 'src/app/interface/publication/document/documents/documents-interface.service';
import InjectionSymbol from 'src/app/shared/injection/injection-symbol';
import { MessageService } from 'src/app/shared/services/message.service';
import { DocumentPreviewImageComponent } from '../document-preview-image/document-preview-image.component';

@Component({
  selector: 'jaro-kit-document-image-file',
  templateUrl: './document-image-file.component.html',
  styleUrls: ['./document-image-file.component.scss'],
})
export class DocumentImageFileComponent {
  @Input() orderAssignmentId: string;
  @Input() document: PartialUpdateImageDocumentModel;
  @Input() isUploading: boolean;
  @Output() onRemoveDocument = new EventEmitter<PartialUpdateImageDocumentModel>();  

  isFileNotAvailable: boolean = false;

  get urlImage() {
    return this.document?.url || this.document.documentFileLocation?.href;
  }

  constructor(
    private msgService: MessageService,
    public matDialog: MatDialog,
    @Inject(InjectionSymbol.DocumentsService) private documentApiService: DocumentsServiceInterface
  ) {}

  removeDocument() {
    const options = {
      yesText: 'Yes, remove',
      cancelText: 'Cancel',
    };
    this.msgService
      .confirm(
        'You are about to delete a document from the report',
        'You will still be able to upload documents from the library',
        options
      )
      .subscribe({
        next: (status: Confirmation.Status) => {
          if (status === Confirmation.Status.confirm) {
            this.onRemoveDocument.emit(this.document);
          }
        },
      });
  }

  openImagePreview(document: PartialUpdatePdfDocumentModel) {
    if (this.isUploading || document.isProcessing) {
      return this.showErrorMsg('The image is being uploaded. Please retry later!');
    }

    const dialogConfig = new MatDialogConfig();
    // selected all when pages is null or empty
    dialogConfig.data = {
      url: this.urlImage,
    };
    dialogConfig.panelClass = 'document-review-modal';
    let dialogRef = this.matDialog.open(DocumentPreviewImageComponent, dialogConfig);
    dialogRef.afterClosed().subscribe({
      next: () => {
        // do nothing
      },
    });
  }

  private showErrorMsg(msg: string) {
    const options = {
      hideYesBtn: true,
      cancelText: 'Close',
    };
    this.msgService.error(msg, null, null, options).subscribe();
  }
}
