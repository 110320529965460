import { Component, Input } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { MatCheckboxChange } from '@angular/material/checkbox';

@Component({
  selector: 'jaro-kit-reconciliation-section1004',
  templateUrl: './reconciliation-section1004.component.html',
  styleUrls: ['./reconciliation-section1004.component.scss'],
})
export class ReconciliationSection1004Component {
  @Input() form: FormGroup;

  onChangeAsIs($event: MatCheckboxChange) {
    if ($event.checked) {
      const controlCheckBoxNames = [
        'reconciliationSubjectToCompletion',
        'reconciliationSubjectToRepairs',
        'reconciliationSubjectToInspection',
      ];
      controlCheckBoxNames.forEach((item) => {
        this.form.controls[item].setValue(false);
        this.form.controls[item].markAsDirty();
      });

      const controlNames = [
        'reconciliationSubjectToCompletionDescription',
        'reconciliationSubjectToRepairsDescription',
        'reconciliationSubjectInspectionDescription',
      ];
      controlNames.forEach((item) => {
        this.form.controls[item].setValue(null);
        this.form.controls[item].markAsDirty();
      });
    }

    this.form.updateValueAndValidity();
  }

  onChangeConditions($event: MatCheckboxChange, controlName: string, descriptionName: string) {
    if ($event.checked) {
      // remove check As-is
      this.form.controls['reconciliationAsIs'].setValue(false);
      this.form.controls['reconciliationAsIs'].markAsDirty();
    } else {
      // clear description field
      this.form.controls[descriptionName].setValue(null);
      this.form.controls[descriptionName].markAsDirty();
    }
    this.form.controls[controlName].setValue($event.checked);
    this.form.controls[controlName].markAsDirty();
    this.form.updateValueAndValidity();
  }
}
