import { Component } from '@angular/core';
import { ActivatedRoute } from '@angular/router';

@Component({
  selector: 'halo-global-login-failed',
  templateUrl: './login-failed.component.html',
  styleUrls: ['./login-failed.component.scss'],
})
export class LoginFailedComponent {
  subscription: any;

  public errorList: Object;
  
  constructor(    
    private activatedRoute: ActivatedRoute,
  ){
    this.errorList = new Object;
  }
  ngOnInit(): void {
    this.activatedRoute.params
      .subscribe((params) => {
        Object.keys(params).forEach((key) => {
          this.errorList[key] = params[key];
        });
      });
  }


}
