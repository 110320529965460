<div class="container filter-panel">
    <div class="row">
        <span class="col-10 facet-title">{{filteredFilterModel.title}}</span>
        <span class="col-2"><mat-icon
            matSuffix
            class="mat-suffix-icon"
            *ngIf="!isOpenDropdown"
            (click)="toggleOpenDropdown($event)"
            >arrow_drop_down</mat-icon>
        <mat-icon
            matSuffix
            class="mat-suffix-icon"
            *ngIf="isOpenDropdown"
            (click)="toggleOpenDropdown($event)"
            >arrow_drop_up</mat-icon>
        </span>
    </div>
    <div *ngIf="isOpenDropdown" class="range-panel container">
        <div class="row">
            <span class="range-label col-2">Min:</span>
            <span class="col-7"><mat-slider (change)="minSliderPositionUpdated($event)" [min]="defaultRange.min" [max]="defaultRange.max" [value]="selectedRange.min" [(NgModel)]="selectedRange.min" thumbLabel></mat-slider></span>
            <span class="col-3">
                <div *ngIf="isMinSelectableRangeUpdated">
                    <div>
                        <mat-chip-list>
                            <mat-chip [ngClass]="{ 'mat-standard-chip': true, 'range-value-updated': true }">{{ filteredRange.min | facetRangeFormat }}</mat-chip>
                        </mat-chip-list>
                    </div>
                    <div *ngIf="!isMinSelectableRangeUpdated">&nbsp;</div>                
                </div>
                <div class="range-value-selected">{{ selectedRange.min === 0 ? "0" : selectedRange.min | facetRangeFormat }}</div>
            </span>
        </div>
        <div class="row">
            <span class="range-label col-2">Max:</span>
            <span class="col-7"><mat-slider (change)="maxSliderPositionUpdated($event)" [min]="defaultRange.min" [max]="defaultRange.max" [value]="selectedRange.max" [(NgModel)]="selectedRange.max" thumbLabel></mat-slider></span>
            <span class="col-3">
                <div *ngIf="isMaxSelectableRangeUpdated">
                    <div>
                        <mat-chip-list>
                            <mat-chip [ngClass]="{ 'mat-standard-chip': true, 'range-value-updated': true }">{{ filteredRange.max | facetRangeFormat }}</mat-chip>
                        </mat-chip-list>
                    </div>
                    <div *ngIf="!isMaxSelectableRangeUpdated">&nbsp;</div>                
                </div>
                <div class="range-value-selected">{{ selectedRange.max === 0 ? "0" : selectedRange.max | facetRangeFormat }}</div>
            </span>
        </div>
    </div>
</div>