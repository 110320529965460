import { ReportDto } from '@proxy/appraisal/report/v1/form1004';
import { set } from 'lodash-es';
import { ReportInputDataFormFieldModel } from 'src/app/features/shared/forms/models/report-input-data-field-form-model/report-input-date-field-form-model';
import { ArrayFormatPipe } from 'src/app/shared/pipes/array-format/array-format.pipe';
import { YesNoFormatPipe } from 'src/app/shared/pipes/yes-no-format/yes-no-format.pipe';
import { ReportInputTemplateField } from '../../../shared/forms/models/report-input-layout.model';
import { ReportWithExtraAttributes } from '../models/report-input-extra-atributes.model';

export const ReportInputTemplateConfigExteriorSection: ReportInputDataFormFieldModel[] = [
  {
    formControlName: 'exteriorUnits',
    format: {
      pipeType: YesNoFormatPipe,
      pipeArgs: ['One', 'One with Accessory Unit'],
    },
  },
  {
    formControlName: 'exteriorFoundationWalls',
    format: {
      pipeType: ArrayFormatPipe,
    },
  },
  {
    formControlName: 'exteriorWalls',
    format: {
      pipeType: ArrayFormatPipe,
    },
  },
  {
    formControlName: 'exteriorRoofSurface',
    format: {
      pipeType: ArrayFormatPipe,
    },
  },
  {
    formControlName: 'exteriorGuttersAndDownspouts',
    format: {
      pipeType: ArrayFormatPipe,
    },
  },
  {
    formControlName: 'exteriorWindowType',
    format: {
      pipeType: ArrayFormatPipe,
    },
  },
  {
    formControlName: 'exteriorStormSashInsulated',
    format: {
      pipeType: ArrayFormatPipe,
    },
  },
  {
    formControlName: 'exteriorScreens',
    mapToFieldFunc: (value, report: ReportWithExtraAttributes) => {
      if (value && Array.isArray(value)) {
        const hasScreens = value.find((item) => item.toLowerCase() === 'yes');
        report.isShowScreensCondition = !!hasScreens;
        return hasScreens ? 'yes' : 'no';
      }
      return null;
    },
    mapToModelFunc: (value, reportDto: ReportDto, formFieldConfig: ReportInputTemplateField) => {
      if (value && typeof value === 'string') {
        set(reportDto, formFieldConfig.path, [value]);
      } else {
        set(reportDto, formFieldConfig.path, value);
      }
    },
    mapToPartialUpdateDto: (value) => {
      // convert back to array
      if (value && typeof value === 'string') {
        return JSON.stringify([value]);
      }
      return value;
    },
  },
];
