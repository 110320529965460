import { Component } from '@angular/core';
import { FeatureNames } from 'src/app/shared/enums/report-input.enum';
import { CommonFeatureComponent } from '../../shared/forms/components/common-feature/common-feature.component';

@Component({
  selector: 'jaro-kit-jaro-boost',
  templateUrl: '../../shared/forms/components/common-feature/common-feature.component.html',
  styleUrls: ['../../shared/forms/components/common-feature/common-feature.component.scss'],
})
export class JaroBoostComponent extends CommonFeatureComponent {
  feature = FeatureNames.JaroBoost;
}
