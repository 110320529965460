<div ngbDropdown class="d-inline-block export-report-button-container">
  <div>
    <button
      class="jaro-kit-primary-button mt-4 mb-4 export-report-button"
      (click)="openDocumentPreview()"
      [disabled]="isModalOpen"
    >
      Export TDCX
    </button>
  </div>
</div>

