<div class="photos">
  <form [formGroup]="form">
    <div
      cdkDropList
      #sectionPhoto="cdkDropList"
      [cdkDropListData]="photos"
      class="photo-list"
      (cdkDropListDropped)="onDrop($event)"
    >
      <div
        class="photo-block"
        *ngFor="let photo of photos"
        cdkDrag
        [cdkDragData]="photo"
        [cdkDropListAutoScrollDisabled]="true"
      >
        <jaro-kit-photo
          [photo]="photo"
          [photos]="photos"
          [orderAssignmentId]="orderAssignmentId"
          [reportId]="reportId"
          [reportVersion]="reportVersion"
          [isOpenByReportPhoto]="true"
          [sectionOptions]="sectionOptions"
          [photoSections]="photoSections"
          [galleryPhotos]="galleryPhotos"
          (onUpdatePhotoSections)="updateSectionPhotos($event)"
        ></jaro-kit-photo>

        <div class="photo-preview-footer" *ngIf="!photo.isInEditMode">
          <p
            [ngClass]="{ 'photo-title report-input-label report-input-title mat-headline': true, 'photo-untitled': !photo.title }"
            (click)="toggleEditMode(photo)"
          >
            {{ photo.title || 'Untitled ' }}
          </p>

          <em
            class="fa-regular fa-trash-can jaro-kit-primary-icon"
            aria-hidden="true"
            (click)="removePhoto(photo)"
          ></em>

          <jaro-kit-photo-button-actions
            *ngIf="!photo.isCoverPhoto"
            [isCoverPhoto]="photo.isCoverPhoto"
            (onMakeCoverPhoto)="makeCoverPhoto(photo)"
          ></jaro-kit-photo-button-actions>
        </div>

        <div class="photo-preview-footer" *ngIf="photo.isInEditMode">
          <mat-form-field
            class="photo-title-form mat-form-field-should-float"
            appearance="outline"
          >
            <input matInput type="text" [formControlName]="photo.id" />
            <button matSuffix mat-icon-button aria-label="Clear" (click)="toggleEditMode(photo)">
              <mat-icon>close</mat-icon>
            </button>

            <button
              matSuffix
              mat-icon-button
              aria-label="Save"
              class="save-button"
              (click)="saveTitle(photo)"
            >
              <mat-icon>check</mat-icon>
            </button>
          </mat-form-field>
        </div>
      </div>
      <div class="photo-block photo-loading" *ngFor="let photoUpload of photosUploading">
        <img [src]="photoUpload.localUrl" alt="photo" />
        <halo-global-progress-bar
          class="document-progress-bar"
          *ngIf="photoUpload.progress !== 100"
          [value]="photoUpload.progress"
        ></halo-global-progress-bar>
      </div>
    </div>
  </form>
</div>
