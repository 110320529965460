import { AfterViewInit, Component, ElementRef, Input, ViewChild } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { Editor, defaultValueCtx, editorViewOptionsCtx, rootCtx } from '@milkdown/core';
import { redoCommand, undoCommand } from '@milkdown/plugin-history';
import { listener, listenerCtx } from '@milkdown/plugin-listener';
import {
  commonmark,
  toggleEmphasisCommand,
  toggleStrongCommand,
  wrapInBlockquoteCommand,
  wrapInBulletListCommand,
  wrapInHeadingCommand,
  wrapInOrderedListCommand
} from '@milkdown/preset-commonmark';
import { nord } from '@milkdown/theme-nord';
import { callCommand } from '@milkdown/utils';
@Component({
  selector: 'jaro-kit-editor',
  templateUrl: './editor.component.html',
  styleUrls: ['./editor.component.scss'],
})
export class EditorComponent implements AfterViewInit {
  @ViewChild('editorRef') editorRef: ElementRef;

  @Input() form: FormGroup;
  @Input() formControlName: string;
  @Input() markDown: string;
  @Input() isReadonly: boolean;
  editor: Editor;
  isHeader: boolean;
  isFocus:boolean;

  async ngAfterViewInit() {
    const editable = () => !this.isReadonly;

    this.editor = await Editor.make()
      .config((ctx) => {
        ctx.update(editorViewOptionsCtx, (prev) => ({
          ...prev,
          editable,
        }));
        
        ctx.set(rootCtx, this.editorRef.nativeElement);
        ctx.set(defaultValueCtx, this.markDown || "");

        const listener = ctx.get(listenerCtx);

        listener.markdownUpdated((_ctx, markdown, _prevMarkdown) => {
          this.markDown = (markdown?.replace(/\r/gm,'') ?? "").replace(/\n{3}/gm,'\n\n\u00AD\n') ?? "".replace(/^\u00AD([^\n])/gm,'$1') ?? "";
          const formControl = this.form.controls[this.formControlName];
          if (formControl) {
            formControl.setValue(this.markDown);
            formControl.markAsDirty();
          }
        });
        listener.focus((_ctx) => {
          this.isFocus = true;
        });
        listener.blur((_ctx) => {
          this.isFocus = false;
        })
      })
      .config(nord)
      .use(listener)
      .use(commonmark)
      .create();
  }

  toggleStrong() {
    this.editor.action(callCommand(toggleStrongCommand.key));
  }

  undo() {
    this.editor.action(callCommand(undoCommand.key));
  }

  redo() {
    this.editor.action(callCommand(redoCommand.key));
  }

  toggleEmphasis() {
    this.editor.action(callCommand(toggleEmphasisCommand.key));
  }

  wrapInBulletList() {
    this.editor.action(callCommand(wrapInBulletListCommand.key));
  }

  wrapInOrderedList() {
    this.editor.action(callCommand(wrapInOrderedListCommand.key));
  }

  wrapInBlockquote() {
    this.editor.action(callCommand(wrapInBlockquoteCommand.key));
  }

  toggleHeader() {
    this.isHeader = !this.isHeader;
    this.editor.action(callCommand(wrapInHeadingCommand.key, this.isHeader ? 1 : 0));
  }
}
