<halo-global-popup [dialogRef]="dialogRef" [isHideClose]="true">
  <div class="document-review-container">
    <div class="document-review-top">
      <div class="document-top-left">
        <span class="document-select-pages">Select pages to include</span>
        <span class="document-title">{{ document.title }}.pdf</span>
        <div class="document-select-all">
          <mat-checkbox
            [(ngModel)]="areAllPagesSelected"
            (change)="selectAll($event)"
            id="thumbnailRadioButtonSelectAll"
            [disabled]="isDisabledSelectAll"
            >Select All
          </mat-checkbox>
        </div>
      </div>

      <div class="document-top-right">
        <button class="jaro-kit-primary-button mouse-cursor-pointer" (click)="download()">Download</button>
        <div class="space"></div>
        <button class="jaro-kit-primary-button mouse-cursor-pointer" (click)="close()">Close</button>
        <div class="save-btn">
          <button
            class="jaro-kit-primary-button mouse-cursor-pointer"
            (click)="includeSelected()"
            [disabled]="selectedPages === 0"
          >
            Save
          </button>

          <span> {{ selectedPages }} selected</span>
        </div>
      </div>
    </div>
    <div class="document-review-detail" *ngIf="document?.url">
      <jaro-kit-preview-extract-document
        [url]="document.url"
        [pages]="document.pages"
        [isSelectedAllPage]="document.isSelectedAllPage"
        (onPdfLoadingFailed)="pdfLoadingFailed()"
        [paginationAttributeSuffix]="paginationAttributeSuffix"
        #previewExtractDocument
      ></jaro-kit-preview-extract-document>
    </div>
  </div>
</halo-global-popup>
