import { Component, Input } from '@angular/core';
import { FormGroup } from '@angular/forms';

@Component({
  selector: 'jaro-kit-above-grade-area',
  templateUrl: './above-grade-area.component.html',
  styleUrls: ['./above-grade-area.component.scss'],
})
export class AboveGradeAreaComponent {
  @Input() form: FormGroup;

  onChangeTotalBathroomCount() {
    const totalBathroomCount = this.form.value['interiorTotalBathroomCount'];
    let totalFullBathroomAboveGradeCount = null;
    let totalHalfBathroomAboveGradeCount = null;
    if (totalBathroomCount) {
      const arr = totalBathroomCount.split('.');
      totalFullBathroomAboveGradeCount = arr[0];
      totalHalfBathroomAboveGradeCount = arr[1];
    }

    let isInvalid = false;
    if(totalFullBathroomAboveGradeCount?.length > 2) {
      this.form.controls['totalFullBathroomAboveGradeCount'].setValue(null);
      isInvalid = true;
    } else {
      this.form.controls['totalFullBathroomAboveGradeCount'].setValue(
        totalFullBathroomAboveGradeCount
      );
    }
    this.form.controls['totalFullBathroomAboveGradeCount'].markAsDirty();

    if(totalHalfBathroomAboveGradeCount?.length > 2) {
      this.form.controls['totalFullBathroomAboveGradeCount'].setValue(null);
      isInvalid = true;
    } else {
      this.form.controls['totalHalfBathroomAboveGradeCount'].setValue(
        totalHalfBathroomAboveGradeCount
      );
    }
    this.form.controls['totalHalfBathroomAboveGradeCount'].markAsDirty();
    this.form.updateValueAndValidity();
    if(isInvalid) {
      setTimeout(() => {
        this.form.controls['interiorTotalBathroomCount'].setErrors({maxLengthOfNumber: true});
      }, 100);
    }
  }

  onChangeGrossLivingArea($event) {
    const value = $event.target.value;
    const dwellingSquareFeet = this.form.controls['dwellingSquareFeet'];
    if (dwellingSquareFeet) {
      dwellingSquareFeet.setValue(parseInt(value));
      dwellingSquareFeet.markAsDirty();
      this.form.updateValueAndValidity();
    }
  }
}
