import { Injectable } from '@angular/core';
import { CanActivate } from '@angular/router';
import { Feature } from '../utils/feature/feature';

@Injectable()
export class CanActivateReportInput implements CanActivate {
  canActivate(): boolean {
    return Feature.isEnabledFeature('ReportInputs');
  }
}
