import { RestService } from '@abp/ng.core';
import { Injectable } from '@angular/core';
import type { OriginalAppraisalFileDto } from '../models';
import type { JaroDeskUserDto } from './models';

@Injectable({
  providedIn: 'root',
})
export class AscentService {
  apiName = 'Ascent';

  getOriginalAppraisalDetailsByInternalOrderId = (internalOrderId: number) =>
    this.restService.request<any, OriginalAppraisalFileDto>(
      {
        method: 'GET',
        url: `/api/v1/ascent/original-appraisal/${internalOrderId}`,
      },
      { apiName: this.apiName }
    );

  getUserInfo = () =>
    this.restService.request<any, JaroDeskUserDto>(
      {
        method: 'GET',
        url: `/ascent/api/v1/user-info`,
      },
      { apiName: this.apiName }
    );

  constructor(private restService: RestService) {}
}
