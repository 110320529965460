import { Confirmation, ConfirmationService } from '@abp/ng.theme.shared';
import { HttpClient, HttpEventType } from '@angular/common/http';
import { EventEmitter, Injectable } from '@angular/core';
import {
  CreateDocumentDto
} from '@proxy/publication/document/documents/v1';
import { CreateWorkfileDto, WorkfileRecordDto, WorkfileService } from '@proxy/workfile/workfile/v1';
import { Observable, Subscription, interval } from 'rxjs';
import { MessageService } from 'src/app/shared/services/message.service';
import { WorkfileUploading } from './workfile-uploading';

@Injectable({
  providedIn: 'root',
})
export class WorkfileFileUploaderService {
  constructor(
    private http: HttpClient,
    private workfileService: WorkfileService,
    private confirmation: ConfirmationService,
    private msgService: MessageService
  ) {}
  private subscription: Subscription;
  public filesWithUploadingProgress: WorkfileUploading[] = [];
  private orderAssignmentId: string;
  public onUploadingCompletion = new EventEmitter<WorkfileUploading[]>();

  public uploadFiles(files: WorkfileUploading[], orderAssignmentId: string) {
    this.filesWithUploadingProgress = files;
    this.orderAssignmentId = orderAssignmentId;

    //check uploading status every 1 second
    const source = interval(1000);
    this.subscription = source.subscribe(() => {
      this.checkIsCompleted();
    });

    //if there are attached files
    for (let i = 0; i < this.filesWithUploadingProgress.length; i++) {
      this.addWorkfile(this.filesWithUploadingProgress[i]).subscribe({
        next: (workfile) => {
          this.filesWithUploadingProgress[i].workfileId = workfile.id;

          this.uploadFileData(workfile, i);

        },
        error: (err) => {
          this.logActionError(err);
        },
      });
    }
  }

  public resetEvent() {
    this.onUploadingCompletion = new EventEmitter<WorkfileUploading[]>();
  }

  private addWorkfile(file: WorkfileUploading): Observable<WorkfileRecordDto> {
    const dto: CreateWorkfileDto = {
      fileName: file.file.name,
      title: file.title
    } as CreateDocumentDto;
    return this.workfileService.create(this.orderAssignmentId, dto);
  }

  private uploadFileData(workfile: WorkfileRecordDto, i: number) {
    this.filesWithUploadingProgress[i].isUploading = true;
    this.http
      .put(workfile.url, this.filesWithUploadingProgress[i].file, {
        reportProgress: true,
        observe: 'events',
      })
      .subscribe({
        next: (event) => {
          if (event.type === HttpEventType.UploadProgress) {
            let uploadProgress = Math.round((100 * event.loaded) / event.total);
            this.filesWithUploadingProgress[i].progress = uploadProgress;
          } else if (event.type === HttpEventType.Response) {
            if (event.ok) {
              this.filesWithUploadingProgress[i].isSuccessful = true;
            } else {
              this.filesWithUploadingProgress[i].error = "Error. File wasn't uploaded!";
            }
            this.filesWithUploadingProgress[i].isUploaded = true;
            this.filesWithUploadingProgress[i].progress = 100;
          }
        },
        error: (err) => {
          this.logActionError(err);
        },
      });
  }

  //It is used for multiple file uploading. It s called every time after completion popup closing. It checks completion of all
  private checkIsCompleted() {
    if (!this.filesWithUploadingProgress.some((item) => !item.isUploaded)) {
      if (this.subscription) this.subscription.unsubscribe();
      if (this.filesWithUploadingProgress.some((item) => !item.isSuccessful)) {
        const options: Partial<Confirmation.Options> = {
          hideCancelBtn: true,
          yesText: 'Close',
        };
        this.confirmation
          .error("Files weren't uploaded!", 'An error has occurred!', options)
          .subscribe((_status: Confirmation.Status) => {
            // do nothing
          });
      }
      this.onUploadingCompletion.emit(this.filesWithUploadingProgress);
    }
  }

  ngOnDestroy() {
    if (this.subscription) this.subscription.unsubscribe();
  }

  private logActionError(err: any): void {
    this.onUploadingCompletion.emit(
      this.filesWithUploadingProgress.filter((item) => item.isSuccessful)
    );
    this.msgService.error(err).subscribe((_status: Confirmation.Status) => {});
  }
}
