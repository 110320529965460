import { Injectable } from '@angular/core';
import { ApplicationInsights } from '@microsoft/applicationinsights-web';
import { environment } from '../../../environments/environment';

// See https://devblogs.microsoft.com/premier-developer/angular-how-to-add-application-insights-to-an-angular-spa/
@Injectable({ providedIn: 'root' })
export class LogService {
  appInsights: ApplicationInsights;
  constructor() {
    this.appInsights = new ApplicationInsights({
      config: {
        instrumentationKey: environment.instrumentation.appInsights.instrumentationKey,
        enableAutoRouteTracking: true, // option to log all route changes
        enableCorsCorrelation: false
      },
    });

    this.appInsights.loadAppInsights();

    var telemetryInitializer = (envelope) => {
      envelope.tags["ai.cloud.role"] = environment.instrumentation.appInsights.cloudRoleName;
    }
    this.appInsights.addTelemetryInitializer(telemetryInitializer);

    // https://github.com/microsoft/applicationinsights-js
    this.appInsights.trackPageView(); // Manually call trackPageView to establish the current user/session/pageview
  }

  logPageView(name?: string, url?: string) {
    // option to call manually
    this.appInsights.trackPageView({
      name: name,
      uri: url,
    });
  }

  logEvent(name: string, properties?: { [key: string]: any }) {
    this.appInsights.trackEvent({ name: name }, properties);
  }

  logMetric(name: string, average: number, properties?: { [key: string]: any }) {
    this.appInsights.trackMetric({ name: name, average: average }, properties);
  }

  logException(exception: Error, severityLevel?: number) {
    this.appInsights.trackException({ exception: exception, severityLevel: severityLevel });
  }

  logTrace(message: string, properties?: { [key: string]: any }) {
    this.appInsights.trackTrace({ message: message }, properties);
  }
}
