<ng-container *ngIf="form" [formGroup]="form">
  <div class="row subject-form">
    <div class="col-md-6 pl-0 pr-15">
      <mat-form-field appearance="outline">
        <mat-label>Street Address</mat-label>
        <input matInput formControlName="subjectStreetAddress" autocomplete="off" />
      </mat-form-field>

      <mat-form-field appearance="outline">
        <mat-label>City</mat-label>
        <input matInput formControlName="subjectCity" autocomplete="off" />
      </mat-form-field>

      <mat-form-field appearance="outline">
        <mat-label>County</mat-label>
        <input matInput formControlName="subjectCounty" autocomplete="off" />
      </mat-form-field>
    </div>

    <div class="col-md-6 pl-15 pr-0">
      <mat-form-field appearance="outline">
        <mat-label>Apt/Unit</mat-label>
        <input matInput formControlName="subjectUnitNumber" autocomplete="off" />
      </mat-form-field>

      <div class="row">
        <div class="col-md-6 pl-0 pr-15">
          <mat-form-field appearance="outline">
            <mat-label class="report-input-label">State</mat-label>
            <mat-select
              formControlName="subjectState"
              disableOptionCentering
              panelClass="report-input-drop-down"
              placeholder="Select from list"
              floatLabel="never"
            >
              <mat-select-trigger>
                {{ form.controls['subjectState'].value }}
              </mat-select-trigger>
              <mat-option *ngFor="let item of addressStateOptions" [value]="item.value">{{
                item.name
              }}</mat-option>
            </mat-select>
          </mat-form-field>
        </div>
        <div class="col-md-6 pl-0">
          <mat-form-field appearance="outline">
            <mat-label>Zip Code</mat-label>
            <input matInput formControlName="subjectPostalCode" autocomplete="off" />
          </mat-form-field>
        </div>
      </div>
    </div>
  </div>

  <div class="row subject-form">
    <div class="col-md-12 pl-0 pr-0">
      <mat-form-field appearance="outline" class="jaro-kit-textarea">
        <mat-label>Legal Description</mat-label>
        <textarea
          type="text"
          formControlName="subjectLegalDescription"
          placeholder="Legal description"
          matInput
          rows="4"
        ></textarea>
      </mat-form-field>
    </div>
  </div>
</ng-container>
