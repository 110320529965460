<ng-container *ngIf="form" [formGroup]="form">
  <div class="row">
    <div class="col-md-6 pl-0 pr-15">
      <div class="report-input-form-field">
        <p class="report-input-label report-input-radio-label">
          Appraisal Report or Restricted Appraisal Report?
        </p>

        <mat-radio-group formControlName="uspap_reportingOption" class="mt-15 mb-15">
          <mat-radio-button
            *ngFor="let option of uspapReportingOptions"
            [value]="option.value"
            class="report-input-radio-button"
            >{{ option.name }}</mat-radio-button
          >
        </mat-radio-group>
      </div>

      <div class="report-input-form-field">
        <p class="report-input-label report-input-radio-label">Appraised within 3 years?</p>

        <mat-radio-group
          formControlName="uspap_areTherePerformedServicesLast3Years"
          class="mt-15 mb-15"
        >
          <mat-radio-button [value]="true" class="report-input-radio-button">Yes</mat-radio-button>
          <mat-radio-button [value]="false" class="report-input-radio-button">No</mat-radio-button>
        </mat-radio-group>
      </div>
    </div>
    <div class="col-md-6 pl-15 pr-0">
      <div class="mt-0">
        <p class="report-input-label report-input-radio-label">Property Rights Appraised</p>
        <mat-radio-group formControlName="subjectRightsAppraised" id="subjectRightsAppraised">
          <mat-radio-button
            *ngFor="let option of rightsAppraisedOptions"
            [value]="option.value"
            (change)="onChangeRightsAppraised($event)"
            class="report-input-radio-button"
            >{{ option.name }}</mat-radio-button
          >
        </mat-radio-group>
        <jaro-kit-validation-message [form]="form" [controlName]="'subjectRightsAppraised'" [isRequired]="true"></jaro-kit-validation-message>
      </div>

      <mat-form-field
        *ngIf="isShowRightsAppraisedOther"
        appearance="outline"
        class="jaro-kit-textarea"
      >
        <mat-label>Property Rights Appraised Other (describe)</mat-label>
        <textarea
          type="text"
          formControlName="subjectRightsAppraisedOther"
          matInput
          rows="4"
          (blur)="onChangeRightsAppraisedOther($event)"
          id="subjectRightsAppraisedOther"
        ></textarea>
      </mat-form-field>
      <jaro-kit-validation-message [form]="form" [controlName]="'subjectRightsAppraisedOther'" [isRequired]="true"></jaro-kit-validation-message>
    </div>
  </div>

  <div class="row">
    <div class="col-md-6 pl-0 pr-15">
      <mat-form-field appearance="outline">
        <mat-label>Borrower</mat-label>
        <input matInput formControlName="subjectBorrower" autocomplete="off" id="subjectBorrower" />
      </mat-form-field>
      <jaro-kit-validation-message [form]="form" [controlName]="'subjectBorrower'" [isRequired]="true"></jaro-kit-validation-message>
    </div>
  </div>

  <div class="row">
    <div class="col-md-6 pl-0 pr-15">
      <div class="report-input-form-field">        
        <mat-label>Contract is not applicable</mat-label>
        <div>
          <mat-radio-group formControlName="subjectContractIsNotApplicable" (change)="onChangeContractIsNotApplicable($event)">
            <mat-radio-button [value]="true" class="report-input-radio-button">Yes</mat-radio-button>
            <mat-radio-button [value]="false" class="report-input-radio-button">No</mat-radio-button>
          </mat-radio-group>
        </div>
      </div>
    </div>
  </div> 

  <div class="row">
    <div class="col-md-6 pl-0 pr-15">
      <mat-form-field
        class="col-md-12"
        appearance="outline"
        *ngIf="!form.value['subjectContractIsNotApplicable']"
      >
        <mat-label>Contract Price</mat-label>
        <input
          matInput
          formControlName="subjectContractPrice"
          autocomplete="off"
          currencyMask
          mask="separator.0"
          thousandSeparator=","
          separatorLimit="9999999999"
          [allowNegativeNumbers]="false"
          id="subjectContractPrice"
        />
      </mat-form-field>
      <jaro-kit-validation-message [form]="form" [controlName]="'subjectContractPrice'" [isRequired]="true"></jaro-kit-validation-message>

      <mat-form-field class="col-md-12" appearance="outline">
        <mat-label>Original Appraised Value</mat-label>
        <input
          matInput
          formControlName="subjectOriginalAppraisedValue"
          autocomplete="off"
          currencyMask
          mask="separator.0"
          thousandSeparator=","
          separatorLimit="9999999999"
          [allowNegativeNumbers]="false"
          id="subjectOriginalAppraisedValue"
        />
      </mat-form-field>
      <jaro-kit-validation-message [form]="form" [controlName]="'subjectOriginalAppraisedValue'" [isRequired]="true"></jaro-kit-validation-message>

      <mat-form-field appearance="outline">
        <mat-label>Original Appraiser</mat-label>
        <input matInput formControlName="subjectOriginalAppraiser" autocomplete="off" id="subjectOriginalAppraiser"/>
      </mat-form-field>
      <jaro-kit-validation-message [form]="form" [controlName]="'subjectOriginalAppraiser'" [isRequired]="true"></jaro-kit-validation-message>

      <mat-form-field appearance="outline">
        <mat-label>Original Lender/Client</mat-label>
        <input matInput formControlName="subjectOriginalLenderClient" autocomplete="off" id="subjectOriginalLenderClient"/>
      </mat-form-field>
      <jaro-kit-validation-message [form]="form" [controlName]="'subjectOriginalLenderClient'" [isRequired]="true"></jaro-kit-validation-message>
    </div>

    <div class="col-md-6 pl-15 pr-0">
      <jaro-kit-date-picker
        [form]="form"
        dateControl="subjectContractDate"
        titleDate="Date of Contract"
        *ngIf="!form.value['subjectContractIsNotApplicable']"
        [isRequired]="true"
        id="subjectContractDate"
      ></jaro-kit-date-picker>

      <jaro-kit-date-picker
        [form]="form"
        dateControl="subjectOriginalAppraisalDate"
        titleDate="Original Appraisal Date"
        [isRequired]="true"
        id="subjectOriginalAppraisalDate"
      ></jaro-kit-date-picker>

      <mat-form-field appearance="outline">
        <mat-label>Company</mat-label>
        <input matInput formControlName="subjectCompany" autocomplete="off" id="subjectCompany"/>
      </mat-form-field>
      <jaro-kit-validation-message [form]="form" [controlName]="'subjectCompany'" [isRequired]="true"></jaro-kit-validation-message>

      <mat-form-field appearance="outline">
        <mat-label>Original Lender/Client Address</mat-label>
        <input matInput formControlName="subjectOriginalLenderAddress" autocomplete="off" id="subjectOriginalLenderAddress"/>
      </mat-form-field>
      <jaro-kit-validation-message [form]="form" [controlName]="'subjectOriginalLenderAddress'" [isRequired]="true"></jaro-kit-validation-message>
    </div>
  </div>
</ng-container>
