import { Injectable } from '@angular/core';
import {
  DocumentDto,
  DocumentWithFileCollectionDto,
} from '@proxy/publication/document/documents/v1';
import { DocumentModel } from '../models/photo/document.model';
@Injectable({
  providedIn: 'root',
})
export class DocumentService {
  // File extensions for files which have icons mapped.
  private IconClassAbbrevMap: string[][] = [
    ['.ai', '', 'ff3369'],
    ['.ax3', '', 'ff3369'],
    ['.ax4', '', 'ff3369'],
    ['.ax5', '', 'ff3369'],
    ['.axz', '', 'ff3369'],
    ['.bmp', '-image', '80397b'],
    ['.csv', '-csv', '217346'],
    ['.dat', '', 'ff3369'],
    ['.doc', '-word', '2b579a'],
    ['.docm', '-word', '2b579a'],
    ['.docx', '-word', '2b579a'],
    ['.dotx', '-word', '2b579a'],
    ['.dwg', '', 'ff3369'],
    ['.dxf', '', 'ff3369'],
    ['.eml', '', 'ff3369'],
    ['.emf', '', 'ff3369'],
    ['.eps', '', 'ff3369'],
    ['.gif', '-image', '80397b'],
    ['.htm', '', 'ff3369'],
    ['.html', '', 'ff3369'],
    ['.jpg', '-image', '80397b'],
    ['.jpeg', '-image', '80397b'],
    ['.log', '', 'ff3369'],
    ['.mdi', '', 'ff3369'],
    ['.mht', '', 'ff3369'],
    ['.msg', '', 'ff3369'],
    ['.mp4', '-video', '80397b'],
    ['.mpeg', '-video', '80397b'],
    ['.mpg', '-video', '80397b'],
    ['.odf', '-word', '2b579a'],
    ['.ods', '-word', '2b579a'],
    ['.odt', '-word', '2b579a'],
    ['.pdf', '-pdf', 'ff3369'],
    ['.pln', '', 'ff3369'],
    ['.png', '-image', '80397b'],
    ['.ppt', '-powerpoint', 'd24726'],
    ['.pptm', '-powerpoint', 'd24726'],
    ['.pptx', '-powerpoint', 'd24726'],
    ['.rar', '-archive', 'ff3369'],
    ['.rs', '', 'ff3369'],
    ['.rtf', '-alt', 'ff3369'],
    ['.scribble', '', 'ff3369'],
    ['.sketch', '', 'ff3369'],
    ['.tga', '', 'ff3369'],
    ['.tiff', '', 'ff3369'],
    ['.txt', '-alt', 'ff3369'],
    ['.wri', '', 'ff3369'],
    ['.xls', '-excel', '217346'],
    ['.xlsm', '-excel', '217346'],
    ['.xlsx', '-excel', '217346'],
    ['.xml', '', 'ff3369'],
    ['.xps', '', 'ff3369'],
    ['.zip', '-zip', '80397b'],
  ];

  // Gets the url for the icon with the icon class abbreviation.
  // If undefined or null will use the default icon file class.
  private getIconClassByAbbr(iconClassAbbrev: string) {
    const abbrev = iconClassAbbrev || '';
    return `fa-file${abbrev}`;
  }

  // Gets the file extension with the dot to the left if the
  // file name contains a dot and lowercases it.
  // Otherwise returns null.
  public getFileExtension(name: string): string {
    return (name || '').indexOf('.') >= 0 ? '.' + name.split('.').pop().toLowerCase() : null;
  }

  // Sets the icon url and to be either the image uploaded
  // for an image file or the file icon if the extension is known.
  // If the image url if for the image uploaded then also sets this
  // to be the preview url.
  public getIconClass(fileExtension): string {
    const iconClassAbbrevMap = this.IconClassAbbrevMap.find((map) => {
      return fileExtension === map[0];
    });

    const iconClassAbbrev = (iconClassAbbrevMap || null) !== null ? iconClassAbbrevMap[1] : null;

    return this.getIconClassByAbbr(iconClassAbbrev);
  }

  public getIconColor(fileExtension): string {
    const iconClassAbbrevMap = this.IconClassAbbrevMap.find((map) => {
      return fileExtension === map[0];
    });

    return (iconClassAbbrevMap || null) !== null ? '#' + iconClassAbbrevMap[2] : 'black';
  }

  public filterAndMapDocuments(
    documents: DocumentDto[] | DocumentWithFileCollectionDto[],
    fileType?: string,
    labels?: string[]
  ): DocumentModel[] {
    return documents
      .filter(
        (item) =>
          (!fileType || item.fileType?.toLowerCase() === fileType.toLowerCase()) &&
          (!labels || this.isSubArray(item.labels, labels))
      )
      .sort((a, b) => (a.creationTime > b.creationTime ? -1 : 1)) // sorts documents by creation time descending
      .map((doc) => {
        const document = new DocumentModel();
        document.id = doc.id;
        document.author = doc.createdByMoniker || 'Unknown';
        document.created = new Date(doc.creationTime);
        document.name = doc.fileName;
        document.extension = this.getFileExtension(doc.fileName);
        document.title = doc['title'];
        document.labels = doc['labels'];
        document.files = doc['files'];
        return document;
      });
  }

  private isSubArray(parent, child): boolean {
    return child.every((element) => {
      return parent.includes(element);
    });
  }
}
