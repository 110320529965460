import { Component, Input, OnInit } from '@angular/core';
import { FormGroup, Validators } from '@angular/forms';
import { ReportWithExtraAttributes } from 'src/app/features/report/shared/models/report-input-extra-atributes.model';

@Component({
  selector: 'jaro-kit-inspection-details',
  templateUrl: './inspection-details.component.html',
  styleUrls: ['./inspection-details.component.scss'],
})
export class InspectionDetailsComponent implements OnInit {
  @Input() form: FormGroup;
  @Input() reportDto: ReportWithExtraAttributes;
  isShowEffectiveDate: boolean;
  isShowDeclinedQuestion: boolean;
  isShowCompletedQuestion: boolean;

  ngOnInit(): void {
    this.isShowEffectiveDate = this.reportDto?.productName !== '1004D Final Inspection';
    this.isShowDeclinedQuestion = this.reportDto?.productName !== '1004D Final Inspection';
    this.isShowCompletedQuestion = this.reportDto?.productName !== '1004D Appraisal Update';

    if (this.isShowCompletedQuestion) {
      this.setValidators(
        this.form.value['certificationCompletion_propertyImprovementsCompleted'],
        'certificationCompletion_comment',
        false
      );
    }
  }

  onChangeImprovementsCompleted($event) {
    if(this.reportDto.productName !== '1004D Appraisal Update'){
      this.setValidators($event.value, 'certificationCompletion_comment');
      this.form.controls['certificationCompletion_propertyImprovementsCompleted'].setValue($event.value);
      this.form.controls['certificationCompletion_propertyImprovementsCompleted'].updateValueAndValidity();
    }
  }

  private setValidators(value: boolean, formControlName: string, isMarkAsDirty = true) {
    const formControl = this.form.controls[formControlName];
    if (formControl) {
      if (value === false) {
        formControl.setValidators([Validators.required]);
      } else {
        formControl.setErrors(null);
        formControl.clearValidators();
      }
      if( isMarkAsDirty ) formControl.markAsDirty();
      formControl.updateValueAndValidity();
    }
  }
}
