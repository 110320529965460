import { Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
  selector: 'jaro-kit-photo-button-actions',
  templateUrl: './photo-button-actions.component.html',
  styleUrls: ['./photo-button-actions.component.scss'],
})
export class PhotoButtonActionsComponent {
  @Input() isCoverPhoto: boolean;
  @Output() onMakeCoverPhoto = new EventEmitter<boolean>();

  makeCoverPhoto() {
    this.onMakeCoverPhoto.emit(true);
  }
}
