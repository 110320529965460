import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { ExportWorkfileComponent } from '../export-workfile/export-workfile.component';

@Component({
  selector: 'jaro-kit-export-workfile-button',
  templateUrl: './export-workfile-button.component.html',
  styleUrls: ['./export-workfile-button.component.scss'],
})
export class ExportWorkfileButtonComponent implements OnInit, OnDestroy {
  @Input() orderAssignmentId: string;
  isModalOpen : boolean;

  constructor(
    private matDialog: MatDialog
  ) {}

  ngOnInit(): void {
    this.isModalOpen = false;
  }

  ngOnDestroy(): void {
    this.isModalOpen = false;
  }

  openWorkfileExport() {
    if(this.isModalOpen) return;
    this.isModalOpen = true;
    const dialogConfig = new MatDialogConfig();
    dialogConfig.data = {
      orderAssignmentId: this.orderAssignmentId     
    };
    dialogConfig.panelClass = 'export-workfile-modal';
    dialogConfig.disableClose = true;
    let dialogRef = this.matDialog.open(ExportWorkfileComponent, dialogConfig);
    dialogRef.afterClosed().subscribe({
      next: (_response: any) => {
        this.isModalOpen = false;
      },
    });
  }
}
