import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { ReportInputComponent } from './report-input.component';

const routes: Routes = [
  {
    path: '',
    component: ReportInputComponent,
    children: [
      {
        path: 'assignment',
        loadChildren: () =>
          import(`src/app/features/assignment/assignment.module`).then(
            (m) => m.AssignmentModule
          ),
      },
      {
        path: 'jaro-boost',
        loadChildren: () =>
          import(`src/app/features/jaro-boost/jaro-boost.module`).then(
            (m) => m.JaroBoostModule
          ),
      },
      {
        path: 'report',
        loadChildren: () =>
          import(`src/app/features/report/report.module`).then(
            (m) => m.ReportInputDataModule
          ),
      },
      {
        path: 'sketch',
        loadChildren: () =>
          import(`src/app/features/sketch/sketch.module`).then(
            (m) => m.SketchModule
          ),
      },
      {
        path: 'photos',
        loadChildren: () => import(`src/app/features/photos/photos.module`).then((m) => m.PhotosModule),
      },
      {
        path: 'documents',
        loadChildren: () =>
          import(`src/app/features/documents/documents.module`).then(
            (m) => m.DocumentsModule
          ),
      },
      {
        path: 'comparables',
        loadChildren: () =>
          import(`src/app/features/comparables/comparables.module`).then((m) => m.ComparablesModule),
      },
      {
        path: 'market',
        loadChildren: () => import(`src/app/features/market/market.module`).then((m) => m.MarketModule),
      },
      {
        path: 'tools',
        loadChildren: () => import(`src/app/features/tools/tools.module`).then((m) => m.ToolsModule),
      },
      {
        path: '',
        redirectTo: 'report',
        pathMatch: 'full',
      },
    ],
  },
];

@NgModule({
  imports: [RouterModule.forChild(routes),
  ],
  exports: [RouterModule],
})
export class ReportInputRoutingModule {}
