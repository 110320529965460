import { Injectable, Pipe, PipeTransform } from '@angular/core';
import { PropertyRecordDataDto } from '../../../proxy/property/property-record/property-records/models'

@Injectable({
  providedIn: 'root',
})
@Pipe({
  name: 'selectedPropertyRecordValue',
})
export class SelectedPropertyRecordValuePipe implements PipeTransform {
  transform(value: PropertyRecordDataDto): string | null {
    if(!value)
    {
        return null;
    }

    switch((value.selected || "").toLowerCase()) {
        case "mls": return `${value.mls ?? ""}`;
        case "pubrec": return `${value.pubrec ?? ""}`;
        case "custom": return `${value.custom ?? ""}`;
        default: return null;
      }

  }
}
