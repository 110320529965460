import {
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnInit,
  Output,
  SimpleChanges,
} from '@angular/core';
import { ReportPhotoResourceModel } from 'src/app/shared/dtos/report-input-resource/report-photo-resource.model';
import { ReportResourceType } from 'src/app/shared/enums/report-resource-type.enum';
import { ReportSource } from '../enums/report-source.enum';

@Component({
  selector: 'jaro-kit-report-resource-filter',
  templateUrl: './report-resource-filter.component.html',
  styleUrls: ['./report-resource-filter.component.scss'],
})
export class ReportResourceFilterComponent implements OnInit, OnChanges {
  @Input() items: ReportPhotoResourceModel[] = [];
  @Input() reportResourceType: ReportResourceType;
  @Output() onUpdateSource = new EventEmitter();

  source: string | ReportSource;
  sources = [
    {
      value: ReportSource.All,
      name: 'All',
    },
    {
      value: ReportSource.Desk,
      name: 'Desk',
    },
    {
      value: ReportSource.Inspection,
      name: 'Inspection',
    },
    {
      value: ReportSource.MLS,
      name: 'MLS',
    },
    {
      value: ReportSource.JaroAi,
      name: 'JaroAi',
    },
    {
      value: ReportSource.User,
      name: 'User',
    },
  ];

  sourceOptions = this.sources;

  ngOnInit() {
    this.source =
      this.reportResourceType === ReportResourceType.Document ? '' : ReportSource.Inspection;
    this.refreshSourceOptions();
    const source = this.sources.find((item) => item.value === this.source);
    this.onUpdateSource.emit(source);
  }

  ngOnChanges(_changes: SimpleChanges) {
    this.refreshSourceOptions();
  }

  refreshSourceOptions() {
    const excludedSources =
      this.reportResourceType === ReportResourceType.Document
        ? [ReportSource.MLS]
        : [ReportSource.Preload, ReportSource.JaroAi, ReportSource.Desk];
    this.sourceOptions = this.sources
      .filter((item) => !excludedSources.includes(item.value))
      .map((item) => {
        const photos = this.items.filter(
          (photo) => !item.value || photo.resourceFilterType?.toString() === item.value?.toString()
        );
        return {
          value: item.value,
          name: `${item.name} (${photos.length})`,
        };
      });
  }

  onSourceChange(value) {
    const source = this.sources.find((item) => item.value === value);
    this.onUpdateSource.emit(source);
  }
}
