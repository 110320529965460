import { isNumber } from '@abp/ng.core';
import { Injectable, Pipe, PipeTransform } from '@angular/core';
import * as moment from 'moment';

@Injectable({
  providedIn: 'root',
})
@Pipe({
  name: 'comparableDateTimeFormat',
})
export class ComparableDateTimeFormatPipe implements PipeTransform {  
  // Converts the given string
  // From:
  // - yyyyMM   (pass through as is)
  // - yyyyMMDD (pass through as is)
  // - MM/YY
  // - M{1,2}/Y{2,4}       e.g. "7/23",   "07/2023"
  // - M{1,2}/D{1,2}Y{2,4} e.g. "7/8/23", "07/08/2023"
  // To:
  // - yyyyMM
  // - yyyyMMDD
  transform(dateStr: string): any {
    if(!dateStr)
      return null;

    if(isNumber(dateStr) && [8,6].find(length => dateStr.length === length))
      return dateStr;

    const dateComponents = dateStr.split("/");          
    for(let dateComp of dateComponents)
    {
      if(!isNumber(dateComp))
        return null;
    }
    
    return this.formatDate(dateComponents);
  }

  private formatDate(dateComponents: string[]): string {
    let formattedDate: string;
    switch(dateComponents.length)
    {
      case 2:
        formattedDate = moment(`${dateComponents[0].padStart(2, "0")}/${dateComponents[1].padStart(4, "20")}`, "MM/yyyy").format("yyyyMM");
        break;
      case 3:
        formattedDate = moment(`${dateComponents[0].padStart(2, "0")}/${dateComponents[1].padStart(2, "0")}/${dateComponents[2].padStart(4, "20")}`, "MM/DD/yyyy").format("yyyyMMDD");
        break;
      default:
        return null;
    }
    
    return isNumber(formattedDate) ? formattedDate : null; // Return "Invalid Date" as null.
  }
}
