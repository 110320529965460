<mat-spinner
  *ngIf="isLoading"
  value="100"
  diameter="30"
  strokeWidth="2"
  class="order-search-spinner"
></mat-spinner>
<div class="row" *ngIf="false">
  <div class="col-15">
    <a class="order-search-results-advanced" href="#">Advanced search</a>
  </div>
</div>
<div class="row mb-2" *ngIf="!isLoading && searchResults.length == 0">
  <div class="col-12 order-search-no-results-label">No orders found - please try again</div>
</div>
<div class="row" *ngIf="!isLoading">
  <ng-container *ngFor="let item of searchResults">
    <jaro-kit-order-search-results-item
      [orderAssignment]="item"
      (onClick)="onClick.emit(true)"
      class="w-100"
    >
    </jaro-kit-order-search-results-item>
  </ng-container>
</div>
