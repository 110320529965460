<div
  class="editor-container"
  [ngClass]="{
    'editor-container-edit-mode': !isReadonly,
    'editor-container-valid': !isReadonly && (isFocus || markDown)
  }"
>
  <div class="editor-toolbar" *ngIf="!isReadonly">
    <em class="far fa-heading" (click)="toggleHeader()"></em>
    <em class="far fa-bold" (click)="toggleStrong()"></em>
    <em class="far fa-italic"(click)="toggleEmphasis()"></em>
    <em class="far fa-list-ul"(click)="wrapInBulletList()"></em>
    <em class="far fa-list-ol"(click)="wrapInOrderedList()"></em>
  </div>
  <div class="editor-detail" #editorRef></div>
</div>
