<ng-container *ngIf="form" [formGroup]="form">
  <div class="row report-input-sub-section">
    <div class="col-md-12 pl-0 pr-15">
      <mat-form-field appearance="outline">
        <mat-label class="report-input-label">Overall Location Rating</mat-label>
        <mat-select
          id="locationImpact"
          formControlName="locationImpact"
          disableOptionCentering
          panelClass="report-input-drop-down"
          placeholder="Select from list"
          floatLabel="never"
        >
          <mat-option *ngFor="let item of locationRatingOptions" [value]="item.value">{{
            item.name
          }}</mat-option>
        </mat-select>
      </mat-form-field>
      <jaro-kit-validation-message [form]="form" [controlName]="'locationImpact'" [isRequired]="true"></jaro-kit-validation-message>

      <div class="location-factor mb-15">
        <mat-form-field appearance="outline">
          <mat-label class="report-input-label">Location Factor 1</mat-label>
          <mat-select
            id="locationFactor1"
            formControlName="locationFactor1"
            disableOptionCentering
            panelClass="report-input-drop-down"
            placeholder="Select from list"
            floatLabel="never"
            (selectionChange)="onChangeLocationFactor1($event.value)"
          >
            <mat-option *ngFor="let item of locationFactorsOptions" [value]="item.value">{{
              item.name
            }}</mat-option>
          </mat-select>
        </mat-form-field>
        <jaro-kit-validation-message [form]="form" [controlName]="'locationFactor1'" [isRequired]="true"></jaro-kit-validation-message>
      </div>

      <mat-form-field *ngIf="isShowLocationFactor1Des" appearance="outline">
        <mat-label>Location Factor 1 Other Description</mat-label>
        <input matInput formControlName="locationFactor1Desc" id="locationFactor1Desc" autocomplete="off" />
        <jaro-kit-validation-message [form]="form" [controlName]="'locationFactor1Desc'" [isRequired]="true"></jaro-kit-validation-message>
      </mat-form-field>

      <div class="location-factor mb-15">
        <mat-form-field appearance="outline">
          <mat-label class="report-input-label">Location Factor 2</mat-label>
          <mat-select
            formControlName="locationFactor2"
            disableOptionCentering
            panelClass="report-input-drop-down"
            placeholder="Select from list"
            floatLabel="never"
            (selectionChange)="onChangeLocationFactor2($event.value)"
          >
            <mat-option *ngFor="let item of locationFactorsOptions" [value]="item.value">{{
              item.name
            }}</mat-option>
          </mat-select>
        </mat-form-field>
        <button
          (click)="removeLocationFactor2()"
          *ngIf="form.value.locationFactor1 && form.value.locationFactor2"
        >
          <em class="fa fa-trash-o" aria-hidden="true"></em>Delete
        </button>
      </div>

      <mat-form-field *ngIf="isShowLocationFactor2Des" appearance="outline">
        <mat-label>Location Factor 2 Other Description</mat-label>
        <input matInput formControlName="locationFactor2Desc" autocomplete="off" />
      </mat-form-field>
    </div>
  </div>
</ng-container>
