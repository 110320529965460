<div ngbDropdown class="d-inline-block">
  <div>
    <button
      class="jaro-kit-primary-button mt-4 mb-4 download-tdcx-button"
      (click)="generateAndPreviewReportForSubmit()"
      [disabled]="isLoading"
    >
      Preview Report
    </button>
  </div>
</div>
<div class="loading-overlay" *ngIf="isLoading">
  <mat-spinner
    mode="indeterminate"
    value="100"
    diameter="100"
    strokeWidth="10"
    class="action-loading-spinner"
  ></mat-spinner>
</div>
