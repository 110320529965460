import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { DocumentSettings } from 'src/app/shared/components/upload-document/document-settings';

@Component({
  selector: 'jaro-kit-document-upload-modal',
  templateUrl: './document-upload-modal.component.html',
  styleUrls: ['./document-upload-modal.component.scss'],
})
export class DocumentUploadModalComponent implements OnInit {
  settings: DocumentSettings;

  get documentCategories(): string[] {
    return [
      'Purchase Contract',
      'Aerial Map',
      'Plat Map',
      'Flood Map',
      'Miscellaneous'
    ];
  }

  get isUploadDisabled(): boolean{
    return false;
  }

  constructor(
    public dialogRef: MatDialogRef<DocumentUploadModalComponent>,
    @Inject(MAT_DIALOG_DATA)
    public data: {
      settings: DocumentSettings;
    }
  ) {}

  ngOnInit(): void {
    this.settings = this.data.settings;
  }

  close() {
    this.dialogRef.close(null);
  }

  upload(){
    this.dialogRef.close(this.settings)
  }

}
