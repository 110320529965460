import { Component, Input } from '@angular/core';
import { VirtualKeypadActionItem } from 'src/app/shared/providers/virtual-keypad/virtual-keypad.model';

// define the method in "interaction.js" when user has any keydown action
declare var _rs_keydown_action: any;

@Component({
  selector: 'jaro-kit-sketch-virtual-keypad-action-item',
  templateUrl: './sketch-virtual-keypad-action-item.component.html',
  styleUrls: ['./sketch-virtual-keypad-action-item.component.scss'],
})
export class SketchVirtualKeypadActionItemComponent {
  @Input() actionItem: VirtualKeypadActionItem;

  onClick() {
    _rs_keydown_action(this.actionItem.keyCode);
  }
}
