import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';

// define the method in "interaction.js" get sketch data
declare var _rs_autoSaveSketchData: any;
@Component({
  selector: 'jaro-kit-sketch-dialog',
  templateUrl: './sketch-dialog.component.html',
  styleUrls: ['./sketch-dialog.component.scss'],
})
export class SketchDialogComponent implements OnInit {
  loadAPI: Promise<any>;
  constructor(
    public dialogRef: MatDialogRef<SketchDialogComponent>,
    @Inject(MAT_DIALOG_DATA)
    public sketchData: {
      orderAssignmentId: string;
      sketchJson: any;
    }
  ) {}

  ngOnInit(): void {
    window['jsonData'] = this.sketchData.sketchJson;
    this.loadAPI = new Promise((resolve) => {
      this.loadScript('/assets/js/rapid-sketch-init.js');

      setTimeout(() => {
        this.loadScript('/assets/js/interaction.js');
      }, 200);
    });
  }

  public loadScript(url) {
    console.log('preparing to load...');
    let node = document.createElement('script');
    node.src = url;
    node.type = 'text/javascript';
    node.async = true;
    node.charset = 'utf-8';
    document.getElementsByTagName('head')[0].appendChild(node);
  }

  closeDialog() {
    const sketchData = _rs_autoSaveSketchData();
    this.dialogRef.close(sketchData);
  }
}
