import { Component, EventEmitter, Inject, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { SubjectListingHistoryDto } from '@proxy/bff/activity/reports/v1';
import { ColumnMode } from '@swimlane/ngx-datatable';
import { Guid } from 'guid-typescript';
import { ReportInputTemplateInterface } from 'src/app/features/shared/forms/providers/report-input-template.interface';
import InjectionSymbol from 'src/app/shared/injection/injection-symbol';

@Component({
  selector: 'jaro-kit-listing-history-item-edit-mode',
  templateUrl: './listing-history-item-edit-mode.component.html',
  styleUrls: ['./listing-history-item-edit-mode.component.scss'],
})
export class ListingHistoryItemEditModeComponent implements OnInit, OnDestroy {
  @Input() subjectListingHistoryDto: SubjectListingHistoryDto;
  @Input() listingHistoryGroups: SubjectListingHistoryDto[];
  @Input() form: FormGroup;

  @Output() onRemoveListingHistory = new EventEmitter<Guid>();

  ColumnMode = ColumnMode;
  DOM: string;
  formControlName: string;

  constructor(
    @Inject(InjectionSymbol.ReportInputTemplateService)
    private reportInputTemplateService: ReportInputTemplateInterface
  ) {}

  ngOnInit(): void {
    this.formControlName = `DataSource${this.subjectListingHistoryDto.guid}`;
    this.DOM =
      this.subjectListingHistoryDto.daysOnMarket &&
      !isNaN(this.subjectListingHistoryDto.daysOnMarket)
        ? this.subjectListingHistoryDto.daysOnMarket.toString()
        : 'UNK';
    this.subjectListingHistoryDto.listingHistory =
      this.subjectListingHistoryDto?.listingHistory || [];
    this.subjectListingHistoryDto.listingHistory.forEach((item) => {
      item.date = this.reportInputTemplateService.getDateValue(item.date);
    });

    const formControl = this.form.controls[this.formControlName];
    if (!formControl) {
      this.form.addControl(
        this.formControlName,
        new FormControl(this.subjectListingHistoryDto.dataSource)
      );
    }
    this.validateData();

    const saleHistoryControl = this.form.controls['saleHistory'];
    if (saleHistoryControl) {
      saleHistoryControl.valueChanges.subscribe((_value) => {
        setTimeout(() => {
          this.validateData();
        }, 100);
      });
    }
  }

  ngOnDestroy(): void {
    this.form.controls[this.formControlName].setErrors(null);
    this.form.removeControl(this.formControlName);
    this.form.updateValueAndValidity();
  }

  getFormControl(rowIndex: number, prefix) {
    return `${prefix}${this.subjectListingHistoryDto.guid}${rowIndex}`;
  }

  addRow(rowIndex?: number) {
    rowIndex =
      typeof rowIndex == 'number' ? rowIndex : this.subjectListingHistoryDto.listingHistory.length;
    const newListingHistory = {
      date: null,
      event: null,
      price: null,
    };
    this.subjectListingHistoryDto.listingHistory.splice(rowIndex + 1, 0, newListingHistory);
    this.subjectListingHistoryDto.listingHistory = [
      ...this.subjectListingHistoryDto.listingHistory,
    ];
    this.validateData();
  }

  removeRow(rowIndex: number) {
    this.subjectListingHistoryDto.listingHistory.splice(rowIndex, 1);
    this.subjectListingHistoryDto.listingHistory = [
      ...this.subjectListingHistoryDto.listingHistory,
    ];

    if (this.subjectListingHistoryDto.listingHistory.length == 0) {
      this.form.controls[this.formControlName].setErrors(null);
      this.form.updateValueAndValidity();
      this.onRemoveListingHistory.emit(this.subjectListingHistoryDto.guid);
    }
    this.validateData();
  }

  updateSource() {
    this.validateData();
  }

  public hasError = (controlName: string, errorName: string) => {
    if (this.form) {
      const control = this.form.controls[controlName];
      return (
        control.invalid &&
        (control.dirty || control.touched || controlName == this.formControlName) &&
        control.errors[errorName] != undefined
      );
    }
    return false;
  };

  changeNewDate(date, rowIndex) {
    const listingHistory = this.subjectListingHistoryDto.listingHistory[rowIndex];
    listingHistory.date = this.reportInputTemplateService.getDateValue(date);
    this.validateData();
  }

  updateRowValue() {
    this.validateData();
  }

  private validateData() {
    const formControl = this.form.controls[this.formControlName];
    if (formControl) {
      let valid = true;
      if (
        !this.subjectListingHistoryDto.dataSource ||
        !this.subjectListingHistoryDto.listingNumber
      ) {
        formControl.setErrors({ inValidData: true });
        valid = false;
      }

      const existingGroup = this.listingHistoryGroups.filter(
        (item) =>
          item.dataSource == this.subjectListingHistoryDto.dataSource &&
          item.listingNumber == this.subjectListingHistoryDto.listingNumber
      );

      if (existingGroup.length > 1) {
        formControl.setErrors({ inValidSource: true });
        valid = false;
      }

      if (valid) {
        formControl.setErrors(null);
        formControl.updateValueAndValidity();
      }
    }
  }
}
