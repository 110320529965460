import { LocalizationPipe } from '@abp/ng.core';
import { Component } from '@angular/core';

@Component({
  selector: 'jaro-kit-admin',
  providers: [LocalizationPipe],
  template: `
    <jaro-kit-admin-sidebar></jaro-kit-admin-sidebar>
  `,
})
export class AdminComponent {
}
