import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'jaro-kit-preview-report-quality-check',
  templateUrl: './preview-report-quality-check.component.html',
  styleUrls: ['./preview-report-quality-check.component.scss'],
})
export class PreviewReportQualityCheckComponent {

  constructor(
    public dialogRef: MatDialogRef<PreviewReportQualityCheckComponent>,
    @Inject(MAT_DIALOG_DATA)
    public data: {
    }   
  ) {} 
}
