<ng-container *ngIf="form" [formGroup]="form">
  <div class="amenity-card-view-mode">
    <div class="utility-card-title">
      <em [ngClass]="icon"></em>
      <p class="mat-title">{{ field.label }}</p>
    </div>
    <div class="utility-card-value">
      <p class="report-input-label">Type</p>
      <p *ngIf="value != null && value != undefined" class="amenity-card-type report-input-value">
        {{ value | dynamicPipe: field.format?.pipeType:field.format?.pipeArgs }}
      </p>
      <p *ngIf="value === null || value === undefined" class="report-input-value">N/A</p>
    </div>

    <div class="utility-card-description">
      <p class="report-input-label">{{description}}</p>
      <div class="report-input-value">{{ descriptionValue || 'N/A' }}</div>
    </div>
  </div>
</ng-container>
