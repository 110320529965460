import { enableProdMode } from '@angular/core';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';
import { AppModule } from './app/app.module';
import { EnvService } from './app/shared/services/env.service';
import { environment } from './environments/environment';

export function getBaseUrl() {
  return document.getElementsByTagName('base')[0].href;
}

const providers = [{ provide: 'BASE_URL', useFactory: getBaseUrl, deps: [] }];

if (environment.production && !environment.isUseDynamicEnvironmentVariables) {
  enableProdMode();
}

platformBrowserDynamic(providers)
  .bootstrapModule(AppModule)
  .then((ref) => {
    if (environment.isUseDynamicEnvironmentVariables) {
      const envService = ref.injector.get(EnvService);
      if (envService.prod) {
        enableProdMode();
      }
    }
  })
  .catch((err) => console.log(err));
