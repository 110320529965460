import { Component, Inject, Input, OnDestroy, OnInit } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { get, multiply } from 'lodash-es';
import { Subscription } from 'rxjs';
import { ReportWithExtraAttributes } from 'src/app/features/report/shared/models/report-input-extra-atributes.model';
import { ReportInputTemplateService } from 'src/app/features/shared/forms/providers/report-input-template.service';
import InjectionSymbol from 'src/app/shared/injection/injection-symbol';
@Component({
  selector: 'jaro-kit-cost-approach-section1004',
  templateUrl: './cost-approach-section1004.component.html',
  styleUrls: ['./cost-approach-section1004.component.scss'],
})
export class CostApproachSection1004Component implements OnInit, OnDestroy {
  @Input() reportDto: ReportWithExtraAttributes;
  @Input() form: FormGroup;
  private subscription: Subscription;

  constructor(
    @Inject(InjectionSymbol.ReportInputTemplateService)
    private reportInputTemplateService: ReportInputTemplateService,   
  ) {}

  ngOnInit(): void {    
    this.subscription = this.reportInputTemplateService.onPublishFieldChanges.subscribe(
      (fieldChanges) => {
        if (fieldChanges) {
          const basementAreaSqFt = fieldChanges.find(
            (item) => item.fieldName === 'basementAreaSqFt'
          );
          if (
            basementAreaSqFt &&
            this.form.value['costApproachIsSyncActiveForBelowGradeSquareFeet']
          ) {
            const belowGradeSquareFeet = this.form.controls['costApproachBelowGradeSquareFeet'];
            belowGradeSquareFeet.setValue(basementAreaSqFt.value);
            belowGradeSquareFeet.markAsDirty();
            this.onChangeBelowGradeCost();
          }

          const interiorGrossLivingArea = fieldChanges.find(
            (item) => item.fieldName === 'interiorGrossLivingArea'
          );
          if (interiorGrossLivingArea) {
            const costApproachDwellingSquareFeet =
              this.form.controls['costApproachDwellingSquareFeet'];
            costApproachDwellingSquareFeet.setValue(interiorGrossLivingArea.value);
            this.onChangeDwellingPrice();
          }
        }
      }
    );
    if (this.form.value['costApproachIsSyncActiveForBelowGradeSquareFeet']) {
      this.form.controls['costApproachBelowGradeSquareFeet'].disable();
    }
  }

  ngOnDestroy() {
    if (this.subscription) this.subscription.unsubscribe();
  }

  onChangeDwellingPrice($event?: any) {
    const dwellingPrice = $event
      ? this.getValueFromEventChange($event)
      : this.form.controls['costApproachDwellingPricePerSquareFeet'].value;
    const dwellingSquareFeet = this.form.controls['costApproachDwellingSquareFeet'].value;

    let costApproachDwellingCost;
    if ([dwellingPrice, dwellingSquareFeet].some((item) => !item && item != 0)) {
      costApproachDwellingCost = null;
    } else {
      costApproachDwellingCost =
        Math.round(multiply(parseFloat(dwellingSquareFeet), parseFloat(dwellingPrice) * 100)) / 100;
    }
    this.form.controls['costApproachDwellingCost'].setValue(costApproachDwellingCost);
    this.form.controls['costApproachDwellingCost'].markAsDirty();
    this.onChangeTotalCost();
  }

  onChangeBelowGradeCost($event?: any, fieldName?: string) {
    const belowGradePrice =
      fieldName == 'costApproachBelowGradePricePerSquareFeet'
        ? this.getValueFromEventChange($event)
        : this.form.controls['costApproachBelowGradePricePerSquareFeet'].value;

    const belowGradeSquareFeet =
      fieldName == 'costApproachBelowGradeSquareFeet'
        ? this.getValueFromEventChange($event)
        : this.form.controls['costApproachBelowGradeSquareFeet'].value;
    let costApproachBelowGradeCost;
    if ([belowGradePrice, belowGradeSquareFeet].some((item) => !item && item != 0)) {
      costApproachBelowGradeCost = null;
    } else {
      costApproachBelowGradeCost =
        Math.round(multiply(parseFloat(belowGradeSquareFeet), parseFloat(belowGradePrice) * 100)) /
        100;
    }
    this.form.controls['costApproachBelowGradeCost'].setValue(costApproachBelowGradeCost);
    this.form.controls['costApproachBelowGradeCost'].markAsDirty();
    this.onChangeTotalCost();
  }

  onChangeBelowGradeSynchronisation($event) {
    const belowGradeSquareFeet = this.form.controls['costApproachBelowGradeSquareFeet'];
    if ($event.checked) {
      const basementAreaSqFt = get(
        this.reportDto,
        'property.improvement.internalArea.basementAreaSqFt'
      );
      belowGradeSquareFeet.setValue(basementAreaSqFt);
      belowGradeSquareFeet.disable();
      belowGradeSquareFeet.markAsDirty();
      this.onChangeBelowGradeCost();
    } else {
      belowGradeSquareFeet.enable();
    }
  }

  onChangeGarageCarportSquareFeet($event) {
    const garageCarportSquareFeet = this.getValueFromEventChange($event);
    const garageCarportPrice =
      this.form.controls['costApproachGarageCarportPricePerSquareFeet'].value;

    let costApproachGarageCarportCost;
    if ([garageCarportSquareFeet, garageCarportPrice].some((item) => !item && item != 0)) {
      costApproachGarageCarportCost = null;
    } else {
      costApproachGarageCarportCost =
        Math.round(multiply(garageCarportSquareFeet, parseFloat(garageCarportPrice) * 100)) / 100;
    }
    this.form.controls['costApproachGarageCarportCost'].setValue(costApproachGarageCarportCost);
    this.form.controls['costApproachGarageCarportCost'].markAsDirty();
    this.onChangeTotalCost();
  }

  onChangeGarageCarportPrice($event) {
    const garageCarportPrice = this.getValueFromEventChange($event);
    const garageCarportSquareFeet = this.form.controls['costApproachGarageCarportSquareFeet'].value;
    let costApproachGarageCarportCost;
    if ([garageCarportSquareFeet, garageCarportPrice].some((item) => !item && item != 0)) {
      costApproachGarageCarportCost = null;
    } else {
      costApproachGarageCarportCost =
        Math.round(multiply(parseFloat(garageCarportSquareFeet), garageCarportPrice * 100)) / 100;
    }
    this.form.controls['costApproachGarageCarportCost'].setValue(costApproachGarageCarportCost);
    this.form.controls['costApproachGarageCarportCost'].markAsDirty();
    this.onChangeTotalCost();
  }

  onChangeTotalCost($event?: any) {
    const dwellingCost = this.form.controls['costApproachDwellingCost'].value || 0;
    const belowGradeCost = this.form.controls['costApproachBelowGradeCost'].value || 0;
    const garageCarportCost = this.form.controls['costApproachGarageCarportCost'].value || 0;
    let extraCost = $event?.target?.value
      ? this.getValueFromEventChange($event)
      : this.form.controls['costApproachExtraCost'].value;
    extraCost = extraCost || 0;
    let costApproachTotalCost =
      parseFloat(dwellingCost) * 100 +
      parseFloat(belowGradeCost) * 100 +
      parseFloat(extraCost) * 100 +
      parseFloat(garageCarportCost) * 100;
    costApproachTotalCost = Math.round(costApproachTotalCost) / 100;

    this.form.controls['costApproachTotalCost'].setValue(costApproachTotalCost);
    this.form.controls['costApproachTotalCost'].markAsDirty();

    this.onChangeDepreciatedCost();
  }

  onChangeDepreciationCost($event, fieldName) {
    let costApproachDepreciationPhysical =
      fieldName === 'costApproachDepreciationPhysical'
        ? this.getValueFromEventChange($event)
        : this.form.controls['costApproachDepreciationPhysical'].value;
    costApproachDepreciationPhysical = costApproachDepreciationPhysical || 0;
    let costApproachDepreciationFunctional =
      fieldName === 'costApproachDepreciationFunctional'
        ? this.getValueFromEventChange($event)
        : this.form.controls['costApproachDepreciationFunctional'].value;
    costApproachDepreciationFunctional = costApproachDepreciationFunctional || 0;

    let costApproachDepreciationExternal =
      fieldName === 'costApproachDepreciationExternal'
        ? this.getValueFromEventChange($event)
        : this.form.controls['costApproachDepreciationExternal'].value;
    costApproachDepreciationExternal = costApproachDepreciationExternal || 0;

    let costApproachTotalDepreciation =
      parseFloat(costApproachDepreciationPhysical) * 100 +
      parseFloat(costApproachDepreciationFunctional) * 100 +
      parseFloat(costApproachDepreciationExternal) * 100;
    costApproachTotalDepreciation = Math.round(costApproachTotalDepreciation) / 100;
    this.form.controls['costApproachTotalDepreciation'].setValue(costApproachTotalDepreciation);
    this.form.controls['costApproachTotalDepreciation'].markAsDirty();

    this.onChangeDepreciatedCost();
  }

  onChangeDepreciatedCost() {
    const totalCost = this.form.controls['costApproachTotalCost'].value || 0;
    const totalDepreciation = this.form.controls['costApproachTotalDepreciation'].value || 0;

    const depreciatedCost =
      Math.round(parseFloat(totalCost) * 100 - parseFloat(totalDepreciation) * 100) / 100;

    this.form.controls['costApproachDepreciatedCost'].setValue(depreciatedCost);
    this.form.controls['costApproachDepreciatedCost'].markAsDirty();

    this.onChangeIndicatedValue();
  }

  onChangeIndicatedValue($event?: any, fieldName?: string) {
    let opinionOfSiteValue =
      fieldName == 'costApproachOpinionOfSiteValue'
        ? this.getValueFromEventChange($event)
        : this.form.controls['costApproachOpinionOfSiteValue'].value;
    opinionOfSiteValue = opinionOfSiteValue || 0;
    let asIsValue =
      fieldName == 'costApproachAsIsValueOfSiteImprovements'
        ? this.getValueFromEventChange($event)
        : this.form.controls['costApproachAsIsValueOfSiteImprovements'].value;
    asIsValue = asIsValue || 0;
    const depreciatedCost = this.form.controls['costApproachDepreciatedCost'].value || 0;

    const indicatedValue =
      Math.round(
        parseFloat(opinionOfSiteValue) * 100 +
          parseFloat(asIsValue) * 100 +
          parseFloat(depreciatedCost) * 100
      ) / 100;

    this.form.controls['costApproachIndicatedValue'].setValue(indicatedValue);
    this.form.controls['costApproachIndicatedValue'].markAsDirty();
  }

  private getValueFromEventChange($event) {
    let value = $event.target.value;
    value = value ? value.toString().replace(/(\d+),(?=\d{3}(\D|$))/g, '$1') : null;
    return parseFloat(value);
  }
}
