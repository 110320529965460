import { Component, Inject, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import {
  NgxGalleryAnimation,
  NgxGalleryComponent,
  NgxGalleryImage,
  NgxGalleryLayout,
  NgxGalleryOptions,
} from '@kolkov/ngx-gallery';
import { PartialUpdateReportPhotoDto } from '@proxy/appraisal/report';
import { ReportPhotoOwnerType, ReportPhotoSource } from '@proxy/appraisal/report/report-photos';
import { CreateReportPhotoDto } from '@proxy/appraisal/report/report-photos/v1';
import {
  PartialUpdateReportInputPhotoDto,
  ReportInputPhotoSectionDto,
} from '@proxy/bff/activity/report-input-photos/v1';
import { ReportPhotoWithThumbnailDto } from '@proxy/bff/activity/report-photos/v1';
import { Guid } from 'guid-typescript';
import { ReportInputPhotoServiceInterface } from 'src/app/interface/bff/activity/report-input-photo-interface.service';
import { ReportPhotoServiceInterface } from 'src/app/interface/bff/activity/report-photo-service-interface';

import { ReportPhotoResourceModel } from 'src/app/shared/dtos/report-input-resource/report-photo-resource.model';
import InjectionSymbol from 'src/app/shared/injection/injection-symbol';
import { PhotoCarouselModel } from 'src/app/shared/models/photo/photo-carousel.model';
import { SectionModel } from 'src/app/shared/models/photo/section.model';
import { ReportInputPhotoResourceService } from 'src/app/shared/services/photo-resource.service';
@Component({
  selector: 'jaro-kit-photo-preview-modal',
  templateUrl: './photo-preview-modal.component.html',
  styleUrls: ['./photo-preview-modal.component.scss'],
})
export class PhotoPreviewModalComponent implements OnInit {
  @ViewChild(NgxGalleryComponent) ngxGalleryComponent;
  galleryOptions: NgxGalleryOptions[];
  galleryImages: NgxGalleryImage[];

  form: FormGroup;
  currentPhoto: ReportPhotoResourceModel;
  isSubmit: boolean = false;
  sections: ReportInputPhotoSectionDto[] = [];
  galleryPhotos: ReportPhotoResourceModel[] = [];

  constructor(
    public dialogRef: MatDialogRef<PhotoPreviewModalComponent>,
    @Inject(MAT_DIALOG_DATA)
    public photoGalleryData: {
      orderAssignmentId: string;
      reportId: string;
      reportVersion: number;
      photos: ReportPhotoResourceModel[];
      currentPhoto: ReportPhotoResourceModel;
      isOpenByReportPhoto: boolean;
      sectionOptions: SectionModel[];
      photoSections: ReportInputPhotoSectionDto[];
      galleryPhotos: ReportPhotoResourceModel[];
    },
    private formBuilder: FormBuilder,
    public reportInputService: ReportInputPhotoResourceService,
    @Inject(InjectionSymbol.ReportPhotoService)
    public reportPhotoService: ReportPhotoServiceInterface,
    @Inject(InjectionSymbol.ReportInputPhotoService)
    public reportInputPhotoService: ReportInputPhotoServiceInterface
  ) {
    this.form = formBuilder.group({
      photoSection: formBuilder.control(null),
      photoTitle: formBuilder.control(null),
    });
  }

  ngOnInit() {
    const startIndex = this.photoGalleryData.photos.indexOf(this.photoGalleryData.currentPhoto);
    this.galleryOptions = [
      {
        width: '905px',
        height: '699px',
        thumbnailsColumns: 5,
        imageAnimation: NgxGalleryAnimation.Fade,
        previewFullscreen: false,
        thumbnailsArrows: false,
        layout: NgxGalleryLayout.ThumbnailsTop,
        lazyLoading: true,
        startIndex: startIndex >= 0 ? startIndex : 0,
        preview: false,
        arrowPrevIcon: 'fa fa-angle-left',
        arrowNextIcon: 'fa fa-angle-right',
        previewAnimation: true,
        imageInfinityMove: true,
      },
    ];

    this.currentPhoto = this.photoGalleryData.currentPhoto;
    this.currentPhoto.oldSectionIds = this.currentPhoto.sectionIds;
    this.sections = this.photoGalleryData.photoSections;
    this.galleryPhotos = this.photoGalleryData.galleryPhotos;
    this.discardPhotoDetail();
    this.getPhotoCarousels();

    this.dialogRef.keydownEvents().subscribe((event) => {
      if (event.key === 'Escape') {
        this.closeDialog();
      }
    });

    this.dialogRef.backdropClick().subscribe((_event) => {
      this.closeDialog();
    });
  }

  private getPhotoCarousels() {
    this.galleryImages = [];
    this.photoGalleryData.photos.forEach((item) => {
      const photo: PhotoCarouselModel = Object.assign({}, item);
      const defaultURLNotFound = './assets/images/report-input/photo-failed-to-load.svg';
      photo.small = photo.smallSizedUrl || defaultURLNotFound;
      photo.medium = photo.mediumSizedUrl || defaultURLNotFound;
      photo.big = photo.largeSizedUrl || defaultURLNotFound;
      this.galleryImages.push(photo);
    });
  }

  closeDialog() {
    if (!this.isSubmit) {
      this.dialogRef.close();
    }
  }

  changeSelectPhoto() {
    this.currentPhoto = this.photoGalleryData.photos[this.ngxGalleryComponent.selectedIndex];
    this.discardPhotoDetail();
  }

  discardPhotoDetail() {
    this.form.controls['photoSection'].setValue(this.currentPhoto.sectionIds);
    this.form.controls['photoTitle'].setValue(this.currentPhoto.title);
    this.form.markAsPristine();
  }

  upsertReportPhoto() {
    if (!this.currentPhoto.id || Guid.parse(this.currentPhoto.id).isEmpty()) {
      this.createReportPhoto(this.currentPhoto);
    } else {
      this.updateReportPhoto(this.getAndUpdatePhotoDto());
    }
  }

  private getAndUpdatePhotoDto() {
    const dataUpdate = {};
    const title = this.form.controls['photoTitle'].value;

    if (title !== this.currentPhoto.title) {
      dataUpdate['title'] = title;
      this.currentPhoto.title = title;
    }

    const sections = this.form.controls['photoSection'].value;

    this.currentPhoto.sectionIds = sections;
    dataUpdate['sectionIds'] = `[${sections.map((section) => {
      return "'" + section + "'";
    })}]`;

    return dataUpdate;
  }

  makeCoverPhoto() {
    this.isSubmit = true;
    const dataUpdate = {};

    dataUpdate['isCoverPhoto'] = true;
    this.currentPhoto.isCoverPhoto = true;

    const partialUpdateReportPhotoRequest = {
      updates: dataUpdate,
    } as PartialUpdateReportPhotoDto;

    this.reportPhotoService
      .partialUpdate(
        this.photoGalleryData.reportId,
        this.photoGalleryData.reportVersion,
        this.currentPhoto.id,
        partialUpdateReportPhotoRequest
      )
      .subscribe({
        next: () => {
          this.isSubmit = false;
          this.form.markAsPristine();
          this.reportInputService.syncGalleryPhoto(
            this.currentPhoto,
            this.sections,
            this.galleryPhotos
          );
        },
        error: (err) => this.logActionError(err),
      });
  }

  private updateReportPhoto(updates: Record<string, string>) {
    this.isSubmit = true;
    const partialUpdateRequest = {
      updates: updates,
    } as PartialUpdateReportInputPhotoDto;

    this.reportInputPhotoService
      .partialUpdate(
        this.photoGalleryData.orderAssignmentId,
        this.photoGalleryData.reportId,
        this.photoGalleryData.reportVersion,
        this.currentPhoto.id,
        partialUpdateRequest
      )
      .subscribe({
        next: () => {
          this.isSubmit = false;
          this.updateExistingSections();
          this.form.markAsPristine();
        },
        error: (err) => this.logActionError(err),
      });
  }

  private createReportPhoto(photo: ReportPhotoResourceModel) {
    this.isSubmit = true;
    const createReportPhotoRequest = {
      reportId: this.photoGalleryData.reportId,
      reportVersion: this.photoGalleryData.reportVersion,
      source: photo.source || ReportPhotoSource.User,
      photoOwnerType: photo.photoOwnerType || ReportPhotoOwnerType.Subject,
      externalReference: photo.externalReference,
      documentId: photo.documentId,
      fileVersion: photo.fileVersion || 1,
      isCoverPhoto: !!photo.isCoverPhoto,
      title: photo.title || '',
    } as CreateReportPhotoDto;
    this.reportPhotoService
      .createReportPhoto(
        this.photoGalleryData.orderAssignmentId,
        createReportPhotoRequest
      )
      .subscribe({
        next: (photoDto: ReportPhotoWithThumbnailDto) => {
          photo.isInReport = true;
          photo.id = photoDto.id;
          this.updateReportPhoto(this.getAndUpdatePhotoDto());
        },
        error: (err) => this.logActionError(err),
      });
  }

  private logActionError(err: any): void {
    this.isSubmit = false;
    return console.error('Observer got an error: ' + err);
  }

  private updateExistingSections() {
    const removeSectionIds = this.currentPhoto.oldSectionIds.filter(
      (item) => !this.currentPhoto.sectionIds.includes(item)
    );
    const addNewSectionIds = this.currentPhoto.sectionIds.filter(
      (item) => !this.currentPhoto.oldSectionIds.includes(item)
    );

    this.reportInputService.addPhotoToAndRemovePhotoFromSections(
      this.currentPhoto,
      addNewSectionIds,
      removeSectionIds,
      this.sections,
      this.galleryPhotos
    );

    this.currentPhoto.oldSectionIds = this.currentPhoto.sectionIds;

    const isRemoveSection = !this.currentPhoto.sectionIds.includes(
      this.currentPhoto.currentSectionId
    );
    if (this.photoGalleryData.isOpenByReportPhoto && isRemoveSection) {
      if (this.galleryImages.length === 1) {
        this.closeDialog();
      } else {
        const isLastPhoto =
          this.ngxGalleryComponent.selectedIndex === this.galleryImages.length - 1;
        this.galleryImages.splice(this.ngxGalleryComponent.selectedIndex, 1);
        this.photoGalleryData.photos.splice(this.ngxGalleryComponent.selectedIndex, 1);
        this.ngxGalleryComponent.selectFromThumbnails(
          isLastPhoto ? 0 : this.ngxGalleryComponent.selectedIndex
        );
        this.changeSelectPhoto();
      }
    }
  }
}
