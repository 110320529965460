import { Component, Input, OnInit } from '@angular/core';
import { SubjectListingHistoryDto } from '@proxy/bff/activity/reports/v1';
import { ColumnMode } from '@swimlane/ngx-datatable';

@Component({
  selector: 'jaro-kit-listing-history-item',
  templateUrl: './listing-history-item.component.html',
  styleUrls: ['./listing-history-item.component.scss'],
})
export class ListingHistoryItemComponent implements OnInit {
  @Input() subjectListingHistoryDto: SubjectListingHistoryDto;
  ColumnMode = ColumnMode;
  hasListingHistory: boolean = false;
  DOM: string;

  ngOnInit(): void {
    this.hasListingHistory = this.subjectListingHistoryDto?.listingHistory?.length > 0;
    this.DOM =
      this.subjectListingHistoryDto.daysOnMarket &&
      !isNaN(this.subjectListingHistoryDto.daysOnMarket)
        ? this.subjectListingHistoryDto.daysOnMarket.toString()
        : 'UNK';
  }
}
