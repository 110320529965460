import { Confirmation } from '@abp/ng.theme.shared';
import { Component, EventEmitter, Inject, Input, OnChanges, OnInit, Output, SimpleChanges } from '@angular/core';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { ReportInputPhotoSectionDto } from '@proxy/bff/activity/report-input-photos/v1';
import { ResponseDto } from '@proxy/publication/document/documents/v1/models';
import { DocumentsServiceInterface } from 'src/app/interface/publication/document/documents/documents-interface.service';
import InjectionSymbol from 'src/app/shared/injection/injection-symbol';
import { MessageService } from 'src/app/shared/services/message.service';
import { DownloadFile } from 'src/app/shared/utils/download-file/download-file';
import { PartialUpdatePdfDocumentModel } from '../../shared/forms/models/report-input-document/partial-update-pdf-document.model';
import { DocumentLibraryPreviewModalComponent } from '../document-library-preview-modal/document-library-preview-modal.component';

@Component({
  selector: 'jaro-kit-document-library',
  templateUrl: './document-library.component.html',
  styleUrls: ['./document-library.component.scss'],
})
export class DocumentLibraryComponent implements OnInit, OnChanges {

  @Input() orderAssignmentId: string;
  @Input() reportId: string;
  @Input() reportVersion: number;
  @Input() items: PartialUpdatePdfDocumentModel[] = [];

  @Input() isLoading: boolean;

  @Output() onUpdatePhotoSections = new EventEmitter<ReportInputPhotoSectionDto[]>();
  @Output() onSoftDeleteDocument = new EventEmitter<PartialUpdatePdfDocumentModel>();
  @Output() onOpenDocumentLibrary = new EventEmitter<boolean>();

  source: string = 'All';
  sourceValue: string;

  constructor(
    private downloadFile: DownloadFile,
    private matDialog: MatDialog,
    private msgService: MessageService,
    @Inject(InjectionSymbol.DocumentsService)
    private documentsService: DocumentsServiceInterface
  ) {}

  sourceFilterOptions = [];

  startIndex: number = 0;
  endIndex: number = 1000;


  sourceListItems: PartialUpdatePdfDocumentModel[];

  setSourceListItems(){
    let itemsToDisplay = [];
    this.items.filter(val => (this.source === 'All' ? true : val.source === this.source))
    .filter((val, index, arr) => index < this.endIndex)
    .forEach((item) => itemsToDisplay.push(item));
    this.sourceListItems = itemsToDisplay;
  }




  ngOnInit(): void {
    this.refreshLibrary(this.items);
  }

  ngOnChanges(changes: SimpleChanges): void {
    this.setSourceListItems();
  }

  getSourceLabel(source: string): string {
    if (source === 'All')
      return `All (${this.items.length})`;
    else {
      let sourceItems = this.items.filter(i => i.source == source);
      return `${source} (${sourceItems.length})`;
    }
  }



  closeDialog() {
    this.onOpenDocumentLibrary.emit(false);
  }


  onDeleteDocument(document: PartialUpdatePdfDocumentModel) {
    this.softDeleteDocument(document);
  }

  // when updating source or propertyId, in the photo gallery list photos will automation filter by source and  propertyId
  updateSource(source) {
    this.source = source;
    this.setSourceListItems();
  }


  // Todo: determine the core reason of infinite-scroll not working
  private scrollSize = 1000;
  private scrollCount = this.scrollSize;
  throttle = 1000;
  scrollDistance = 1;
  scrollUpDistance = 2;



  resetResources() {
    this.scrollCount = this.scrollSize;
  }

  onScrollDown() {
    if (this.scrollCount < this.items.length) {
      this.scrollCount += this.scrollSize;
    }
  }

  previewDocument(document: PartialUpdatePdfDocumentModel) {
    if (document?.documentId) {
      this.documentsService.getFile(document.documentId, this.orderAssignmentId, null).subscribe({
        next: (data: ResponseDto) => {
          if (data && data.links.length > 0) {
            document.extractedURL = data.links[0].href;
            this.openDocumentPreview(document);
          } else {
            this.showErrorMsg('The document cannot be opened. Please retry later!');
          }
        },
      });
    }
  }

  private showErrorMsg(msg: string) {
    const options = {
      hideYesBtn: true,
      cancelText: 'Close',
    };
    this.msgService.error(msg, null, null, options).subscribe();
  }

  openDocumentPreview(document: PartialUpdatePdfDocumentModel) {
    const dialogConfig = new MatDialogConfig();
    dialogConfig.data = {
      document: document,
    };
    dialogConfig.panelClass = 'document-review-modal';
    let dialogRef = this.matDialog.open(DocumentLibraryPreviewModalComponent, dialogConfig);
    dialogRef.afterClosed().subscribe({
      next: (response: PartialUpdatePdfDocumentModel | boolean) => {
        if (response === true) {
          this.showErrorMsg('The document cannot be opened. Please retry later!');
        } else {
          // Nothing should be done. The user closes the dialog.
        }
      },
    });
  }

  downloadDocument(document: PartialUpdatePdfDocumentModel) {
    if (document?.documentId) {
      this.documentsService.getFile(document.documentId, this.orderAssignmentId, null).subscribe({
        next: (data: ResponseDto) => {
          if (data && data.links.length > 0) {
            document.documentFileLocation = data.links[0];
            this.downloadFile.downloadFileBrowserDialog(
              document.documentFileLocation.href,
              document.fileName,
              document.fileExtension
            );
          }
        },
      });
    }
  }

  deleteDocument(document: PartialUpdatePdfDocumentModel) {
    if (document?.documentId) {
      this.msgService
        .confirm(
          'Delete document',
          'Are you sure want to delete this document? This action cannot be undone.'
        )
        .subscribe({
          next: (status: Confirmation.Status) => {
            if (status === Confirmation.Status.confirm) {
              this.softDeleteDocument(document, false);
            }
          },
        });
    }
  }

  private softDeleteDocument(document: PartialUpdatePdfDocumentModel, isKeepLoading = true) {
    this.documentsService
      .deleteDocument(document.documentId, this.orderAssignmentId)
      .subscribe(() => {
        this.onSoftDeleteDocument.emit(document);
      });
  }

  refreshLibrary(documentLibrary: PartialUpdatePdfDocumentModel[]){
    this.items = documentLibrary;
    this.setSourceListItems();
    this.resetResources();
    this.sourceFilterOptions = [
      {
        value: 'All',
        name: this.getSourceLabel('All'),
      },
      {
        value: 'Desk',
        name: this.getSourceLabel('Desk'),
      },
      {
        value: 'Inspection',
        name: this.getSourceLabel('Inspection'),
      },
      {
        value: 'JaroAi',
        name: this.getSourceLabel('JaroAi'),
      },
      {
        value: 'User',
        name: this.getSourceLabel('User'),
      }
    ]
  }

}
