<div class="inventory-analysis-detail" *ngIf="!isEditMode && inventoryAnalysis">
    <table
      class="inventory-analysis-table"
      [ngClass]="{ 'inventory-analysis-table-disabled': !marketCondition.isOverrideCompsStatistics }"
    >
      <caption style="display: none">
        Inventory Analysis Table
      </caption>
      <tr>
        <th class="inventory-analysis-table-title">Inventory Analysis</th>
        <th>Prior 7-12 Months</th>
        <th>Prior 4-6 Months</th>
        <th>Current - 3 Months</th>
        <th class="inventory-analysis-overall-trend">Overall Trend</th>
      </tr>
      <tr>
        <td class="inventory-analysis-table-title">Total # of Comparable Sales (Settled)</td>
        <td>
          {{
            inventoryAnalysis?.totalNumberOfComparableSales?.prior7To12Months | number | defaultValue 
          }}
        </td>
        <td>
          {{
            inventoryAnalysis?.totalNumberOfComparableSales?.prior4To6Months | number | defaultValue 
          }}
        </td>
  
        <td>
          {{
            inventoryAnalysis?.totalNumberOfComparableSales?.currentTo3Months | number | defaultValue 
          }}
        </td>
  
        <td>
          {{ inventoryAnalysis?.totalNumberOfComparableSales?.overallTrend | defaultValue  }}
        </td>
      </tr>
  
      <tr>
        <td class="inventory-analysis-table-title">Absorption Rate (Total Sales/Months)</td>
        <td class="inventory-analysis-row-readonly">
          {{ inventoryAnalysis?.totalNumberOfComparableSales?.prior7To12Months / 6 | number : '1.2-2' | defaultValue  }}
        </td>
        <td class="inventory-analysis-row-readonly">
          {{ inventoryAnalysis?.totalNumberOfComparableSales?.prior4To6Months / 3 | number : '1.2-2' | defaultValue  }}
        </td>
        <td class="inventory-analysis-row-readonly">
          {{ inventoryAnalysis?.totalNumberOfComparableSales?.currentTo3Months / 3 | number : '1.2-2' | defaultValue  }}
        </td>
  
        <td>
          {{ inventoryAnalysis?.absorptionRate?.overallTrend | defaultValue  }}
        </td>
      </tr>
  
      <tr>
        <td class="inventory-analysis-table-title">Total # of Comparable Active Listings</td>
        <td>
          {{
            inventoryAnalysis?.totalNumberOfComparableActiveListings?.prior7To12Months | number | defaultValue 
          }}
        </td>
        <td>
          {{
            inventoryAnalysis?.totalNumberOfComparableActiveListings?.prior4To6Months | number | defaultValue 
          }}
        </td>
        <td>
          {{
            inventoryAnalysis?.totalNumberOfComparableActiveListings?.currentTo3Months | number | defaultValue 
          }}
        </td>
  
        <td>
          {{ inventoryAnalysis?.totalNumberOfComparableActiveListings?.overallTrend | defaultValue  }}
        </td>
      </tr>
  
      <tr>
        <td class="inventory-analysis-table-title">Months of Housing Supply (Total Listings/Ab.Rate)</td>
        <td class="inventory-analysis-row-readonly">
            {{ getMonthsOfHousingSupply('prior7To12Months') | number : '1.1-1' | defaultValue  }}
        </td>
        <td class="inventory-analysis-row-readonly">
            {{ getMonthsOfHousingSupply('prior4To6Months') | number : '1.1-1' | defaultValue  }}
        </td>
        <td class="inventory-analysis-row-readonly">
            {{ getMonthsOfHousingSupply('currentTo3Months') | number : '1.1-1' | defaultValue  }}
        </td>
  
        <td>
          {{ inventoryAnalysis?.monthsOfHousingSupply?.overallTrend | defaultValue  }}
        </td>
      </tr>
  
    </table>
  </div>
  
  <div class="inventory-analysis-detail inventory-analysis-detail-edit-mode" *ngIf="isEditMode">
    <table
      class="inventory-analysis-table"
      [ngClass]="{ 'inventory-analysis-table-disabled': !marketCondition.isOverrideCompsStatistics }"
    >
      <caption style="display: none">
        Inventory Analysis Table
      </caption>
      <tr>
        <th class="inventory-analysis-table-title">Inventory Analysis</th>
        <th>Prior 7-12 Months</th>
        <th>Prior 4-6 Months</th>
        <th>Current - 3 Months</th>
        <th class="inventory-analysis-overall-trend">Overall Trend</th>
      </tr>
      <tr>
        <td class="inventory-analysis-table-title">Total # of Comparable Sales (Settled)</td>
        <td>
          <input
              matInput
              [(ngModel)]="inventoryAnalysis.totalNumberOfComparableSales.prior7To12Months"
              autocomplete="off"
              numberMask
              mask="separator.0"
              thousandSeparator=","
              separatorLimit="9999999999"
              [allowNegativeNumbers]="false"
              [disabled]="!marketCondition.isOverrideCompsStatistics"
            />
        </td>
        <td>
          <input
              matInput
              [(ngModel)]="inventoryAnalysis.totalNumberOfComparableSales.prior4To6Months"
              autocomplete="off"
              numberMask
              mask="separator.0"
              thousandSeparator=","
              separatorLimit="9999999999"
              [allowNegativeNumbers]="false"
              [disabled]="!marketCondition.isOverrideCompsStatistics"
            />
        </td>
  
        <td>
          <input
              matInput
              [(ngModel)]="inventoryAnalysis.totalNumberOfComparableSales.currentTo3Months"
              autocomplete="off"
              numberMask
              mask="separator.0"
              thousandSeparator=","
              separatorLimit="9999999999"
              [allowNegativeNumbers]="false"
              [disabled]="!marketCondition.isOverrideCompsStatistics"
            />
        </td>
  
        <td>
          <mat-radio-group
          [(ngModel)]="inventoryAnalysis.totalNumberOfComparableSales.overallTrend"
          [disabled]="!marketCondition.isOverrideCompsStatistics"
          class="overall-trend"
          >
          <mat-radio-button [value]="'Increasing'" class="report-input-radio-button">Increasing</mat-radio-button>
          <mat-radio-button [value]="'Stable'" class="report-input-radio-button">Stable</mat-radio-button>
          <mat-radio-button [value]="'Declining'" class="report-input-radio-button">Declining</mat-radio-button>
          </mat-radio-group>
        </td>
      </tr>
  
      <tr >
        <td class="inventory-analysis-table-title">Absorption Rate (Total Sales/Months)</td>
        <td class="inventory-analysis-row-readonly">
          {{ inventoryAnalysis?.totalNumberOfComparableSales?.prior7To12Months / 6 | number : '1.2-2' | defaultValue  }}
        </td>
        <td class="inventory-analysis-row-readonly">
          {{ inventoryAnalysis?.totalNumberOfComparableSales?.prior4To6Months / 3 | number : '1.2-2' | defaultValue  }}
        </td>
        <td class="inventory-analysis-row-readonly">
          {{ inventoryAnalysis?.totalNumberOfComparableSales?.currentTo3Months / 3 | number : '1.2-2' | defaultValue  }}
        </td>
        <td>
              <mat-radio-group
              [(ngModel)]="inventoryAnalysis.absorptionRate.overallTrend"
              [disabled]="!marketCondition.isOverrideCompsStatistics"
              class="overall-trend"
              >
              <mat-radio-button [value]="'Increasing'" class="report-input-radio-button">Increasing</mat-radio-button>
              <mat-radio-button [value]="'Stable'" class="report-input-radio-button">Stable</mat-radio-button>
              <mat-radio-button [value]="'Declining'" class="report-input-radio-button">Declining</mat-radio-button>
              </mat-radio-group>
        </td>
      </tr>
  
      <tr>
        <td class="inventory-analysis-table-title">Total # of Comparable Active Listings</td>
        <td>
          <input
              matInput
              [(ngModel)]="inventoryAnalysis.totalNumberOfComparableActiveListings.prior7To12Months"
              autocomplete="off"
              numberMask
              mask="separator.0"
              thousandSeparator=","
              separatorLimit="9999999999"
              [allowNegativeNumbers]="false"
              [disabled]="!marketCondition.isOverrideCompsStatistics"
            />
        </td>
        <td>
          <input
              matInput
              [(ngModel)]="inventoryAnalysis.totalNumberOfComparableActiveListings.prior4To6Months"
              autocomplete="off"
              numberMask
              mask="separator.0"
              thousandSeparator=","
              separatorLimit="9999999999"
              [allowNegativeNumbers]="false"
              [disabled]="!marketCondition.isOverrideCompsStatistics"
            />
        </td>
  
        <td>
          <input
              matInput
              [(ngModel)]="inventoryAnalysis.totalNumberOfComparableActiveListings.currentTo3Months"
              autocomplete="off"
              numberMask
              mask="separator.0"
              thousandSeparator=","
              separatorLimit="9999999999"
              [allowNegativeNumbers]="false"
              [disabled]="!marketCondition.isOverrideCompsStatistics"
            />
        </td>
  
          <td>
          <mat-radio-group
          [(ngModel)]="inventoryAnalysis.totalNumberOfComparableActiveListings.overallTrend"
          [disabled]="!marketCondition.isOverrideCompsStatistics"
          class="overall-trend"
          >
              <mat-radio-button [value]="'Declining'" class="report-input-radio-button">Declining</mat-radio-button>
              <mat-radio-button [value]="'Stable'" class="report-input-radio-button">Stable</mat-radio-button>
              <mat-radio-button [value]="'Increasing'" class="report-input-radio-button">Increasing</mat-radio-button>
          </mat-radio-group>
          </td>
      </tr>
  
      <tr>
        <td class="inventory-analysis-table-title">Months of Housing Supply (Total Listings/Ab.Rate)</td>
        <td class="inventory-analysis-row-readonly">
          {{ getMonthsOfHousingSupply('prior7To12Months') | number : '1.1-1' | defaultValue  }}
        </td>
        <td class="inventory-analysis-row-readonly">
          {{ getMonthsOfHousingSupply('prior4To6Months') | number : '1.1-1' | defaultValue  }}
        </td>
        <td class="inventory-analysis-row-readonly">
          {{ getMonthsOfHousingSupply('currentTo3Months') | number : '1.1-1' | defaultValue  }}
        </td>
  
        <td>
          <mat-radio-group
          [(ngModel)]="inventoryAnalysis.monthsOfHousingSupply.overallTrend"
          [disabled]="!marketCondition.isOverrideCompsStatistics"
          class="overall-trend"
          >
              <mat-radio-button [value]="'Declining'" class="report-input-radio-button">Declining</mat-radio-button>
              <mat-radio-button [value]="'Stable'" class="report-input-radio-button">Stable</mat-radio-button>
              <mat-radio-button [value]="'Increasing'" class="report-input-radio-button">Increasing</mat-radio-button>
          </mat-radio-group>
          </td>
      </tr>
    </table>
  </div>
  