import { Injectable, Pipe, PipeTransform } from '@angular/core';
import { OrderDetailDto } from '@proxy/ascent/order/order-assignment/order-assignments/v2/orderDetailDto';

@Injectable({
  providedIn: 'root',
})
@Pipe({
  name: 'zillowSearchAddressUrlFormat',
})
export class ZillowSearchAddressUrlFormatPipe implements PipeTransform {
  transform(orderData: OrderDetailDto): string {    
    const addressLineFormatted = orderData?.address?.fullAddressFormatted?.split(' ')?.join('-');    
    return addressLineFormatted ? `http://www.zillow.com/homes/${encodeURIComponent(addressLineFormatted)}` : "http://www.zillow.com";
  }
}