import { Confirmation } from '@abp/ng.theme.shared';
import { Component, ElementRef, EventEmitter, Input, Output, ViewChild } from '@angular/core';
import { MessageService } from 'src/app/shared/services/message.service';
import { WorkfileFileUploaderService } from './workfile-file-uploader.service';
import { WorkfileUploading } from './workfile-uploading';

@Component({
  selector: 'jaro-kit-upload-workfile',
  templateUrl: './workfile-upload-file.component.html',
  styleUrls: ['./workfile-upload-file.component.scss'],
})
export class WorkfileUploadFileComponent {
  @ViewChild('fileInput') fileInput: ElementRef;

  @Input() orderAssignmentId: string;
  @Input() title: string = '';
  @Input() isMultiple: boolean = true;
  @Input() maximumOfFileToUpload: number = 10;

  @Output() onAddFile = new EventEmitter<any>();

  filesUploading: WorkfileUploading[] = [];
  isUploading: boolean = false;

  get typeAccept(): string {
    return "*";
  }

  constructor(
    private filesUploaderService: WorkfileFileUploaderService,
    private msgService: MessageService
  ) {}

  uploadFileClick() {
    this.fileInput.nativeElement.dispatchEvent(new MouseEvent('click'));
  }

  public uploadFiles(files: FileList) {
    if (files && files.length > 0) {
      const fileUploads = this.getFileUploads(files);
      this.filesUploading = fileUploads;

      this.filesUploaderService.onUploadingCompletion.subscribe({
        next: (filesUploaded: WorkfileUploading[]) => {
          this.filesUploaderService.onUploadingCompletion.unsubscribe();
          this.filesUploaderService.resetEvent();
          this.isUploading = false;
          this.onAddFile.emit();
        },
        error: (err) => this.logActionError(err),
      });

      this.isUploading = true;
      this.filesUploaderService.uploadFiles(fileUploads, this.orderAssignmentId);
    }
    this.fileInput.nativeElement.value = null;
  }

  private getFileUploads(files: FileList): WorkfileUploading[] {
    const fileUploads = Array.from(files)
      .map((file: File) => {
        const fileUpload = {
          file: file,
          isUploading: false,
          progress: 0,
          isSuccessful: false,
          workfileId: '',
          error: '',
          localUrl: '',
          fileName: file.name,
          title: this.getTitle(file),
        } as WorkfileUploading;
        const reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = (_event) => {
          fileUpload.localUrl = reader.result;
        };
        return fileUpload;
      });
    return this.isMultiple
      ? fileUploads.splice(0, this.maximumOfFileToUpload)
      : fileUploads.splice(0, 1);
  }

  private logActionError(err: any): void {
    this.isUploading = false;
    this.msgService.error(err).subscribe((_status: Confirmation.Status) => {});
  }

  private getTitle(file: File): string {
    const fileName = file.name;
    return fileName.substring(0, fileName.lastIndexOf('.'));
  }
}
