import { Component, ElementRef, Inject, OnInit, ViewChild } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { ReportVersionWithLinksDto } from '@proxy/bff/activity/reports/v1';
import { UrlLink } from '@proxy/shared/urlLink/models';
import { WorkfileServiceInterface } from 'src/app/interface/workfile/workfile-interface.service';
import InjectionSymbol from 'src/app/shared/injection/injection-symbol';
import { MessageService } from 'src/app/shared/services/message.service';

@Component({
  selector: 'jaro-kit-export-workfile',
  templateUrl: './export-workfile.component.html',
  styleUrls: ['./export-workfile.component.scss'],
})

export class ExportWorkfileComponent implements OnInit {
  orderAssignmentId: string;  
  isLoading: boolean;
  exportUrl: string;
  isError: boolean;  
  @ViewChild("exportLink") exportLink: ElementRef<HTMLLinkElement>;

  constructor(
    @Inject(InjectionSymbol.WorkfileService)
    private workfileService: WorkfileServiceInterface,   
    private msgService: MessageService,
    public dialogRef: MatDialogRef<ExportWorkfileComponent>,
    @Inject(MAT_DIALOG_DATA)
    public exportDocumentData: {
      orderAssignmentId: string;
      reportVersionDto: ReportVersionWithLinksDto;
      productName;
    }
  ) {}

  ngOnInit(): void {
    this.orderAssignmentId = this.exportDocumentData.orderAssignmentId;
    this.exportUrl = "";
    this.isError = false;

    this.exportWorkfile();
  }

  exportWorkfile() {
    this.isLoading = true;
    this.workfileService.exportZip(this.orderAssignmentId).subscribe({
      next: (exportLink: UrlLink) => {
        this.exportUrl = exportLink.url;
        this.isLoading = false;
        setTimeout(
          () => {
            this.exportLink.nativeElement.click();
            this.dialogRef.close();
          }, 500);
      },
      error: (err) =>  {
        this.isError = true;
        this.isLoading = false;
        this.msgService.error(err);
      }
    });
  } 

  cancelButton(){
    this.isLoading = false;
    this.dialogRef.close();
  }  
}
