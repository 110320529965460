<div class="median-comparable-detail" *ngIf="!isEditMode && medianSalesAnalysis">
  <table
    class="median-comparable-table"
    [ngClass]="{ 'median-comparable-table-disabled': !marketCondition.isOverrideCompsStatistics }"
  >
    <caption style="display: none">
      Median Comparable Table
    </caption>
    <tr>
      <th class="median-comparable-table-title">Median Sale & List Price, DOM, Sale/List %</th>
      <th>Prior 7-12 Months</th>
      <th>Prior 4-6 Months</th>
      <th>Current - 3 Months</th>
      <th class="median-comparable-overall-trend">Overall Trend</th>
    </tr>
    <tr>
      <td class="median-comparable-table-title">Median Comparable Sale Price</td>
      <td>
        {{
          medianSalesAnalysis?.medianComparableSalePrice?.prior7To12Months | currency: 'USD':'symbol':'1.0-0' | defaultValue 
        }}
      </td>
      <td>
        {{
          medianSalesAnalysis?.medianComparableSalePrice?.prior4To6Months | currency: 'USD':'symbol':'1.0-0' | defaultValue 
        }}
      </td>

      <td>
        {{
          medianSalesAnalysis?.medianComparableSalePrice?.currentTo3Months | currency: 'USD':'symbol':'1.0-0' | defaultValue 
        }}
      </td>

      <td>
        {{ medianSalesAnalysis?.medianComparableSalePrice?.overallTrend | defaultValue  }}
      </td>
    </tr>

    <tr>
      <td class="median-comparable-table-title">Median Comparable Sales Days on Market</td>
      <td>
        {{ medianSalesAnalysis?.medianComparableSaleDaysOnMarket?.prior7To12Months | number | defaultValue  }}
      </td>
      <td>
        {{ medianSalesAnalysis?.medianComparableSaleDaysOnMarket?.prior4To6Months | number | defaultValue }}
      </td>

      <td>
        {{ medianSalesAnalysis?.medianComparableSaleDaysOnMarket?.currentTo3Months | number | defaultValue }}
      </td>

      <td>
        {{ medianSalesAnalysis?.medianComparableSaleDaysOnMarket?.overallTrend | defaultValue  }}
      </td>
    </tr>

    <tr>
      <td class="median-comparable-table-title">Median Comparable List Price</td>
      <td>
        {{
          medianSalesAnalysis?.medianComparableListPrice?.prior7To12Months | currency: 'USD':'symbol':'1.0-0' | defaultValue 
        }}
      </td>
      <td>
        {{
          medianSalesAnalysis?.medianComparableListPrice?.prior4To6Months | currency: 'USD':'symbol':'1.0-0' | defaultValue 
        }}
      </td>

      <td>
        {{
          medianSalesAnalysis?.medianComparableListPrice?.currentTo3Months | currency: 'USD':'symbol':'1.0-0' | defaultValue 
        }}
      </td>

      <td>
        {{ medianSalesAnalysis?.medianComparableListPrice?.overallTrend | defaultValue  }}
      </td>
    </tr>

    <tr>
      <td class="median-comparable-table-title">Median Comparable Listings Days on Market</td>
      <td>
        {{ medianSalesAnalysis?.medianComparableListingDaysOnMarket?.prior7To12Months | number | defaultValue  }}
      </td>
      <td>
        {{ medianSalesAnalysis?.medianComparableListingDaysOnMarket?.prior4To6Months | number | defaultValue  }}
      </td>

      <td>
        {{ medianSalesAnalysis?.medianComparableListingDaysOnMarket?.currentTo3Months | number | defaultValue  }}
      </td>

      <td>
        {{ medianSalesAnalysis?.medianComparableListingDaysOnMarket?.overallTrend | defaultValue  }}
      </td>
    </tr>

    <tr>
      <td class="median-comparable-table-title">Median Sale Price as % of List Price</td>
      <td>{{ medianSalesAnalysis?.medianSalePriceAsPercentOfListPrice?.prior7To12Months  | defaultValue }}</td>
      <td>{{ medianSalesAnalysis?.medianSalePriceAsPercentOfListPrice?.prior4To6Months | defaultValue}}</td>
      <td>{{ medianSalesAnalysis?.medianSalePriceAsPercentOfListPrice?.currentTo3Months | defaultValue}}</td>

      <td>
        {{ medianSalesAnalysis?.medianSalePriceAsPercentOfListPrice?.overallTrend | defaultValue  }}
      </td>
    </tr>

    <tr>
      <td colspan="4" class="median-comparable-table-title">
        Seller-(developer, builder, etc.) paid financial assistance prevalent?
        <span style="padding-left: 15px">{{
          medianSalesAnalysis?.isSellerPaidFinancialAssistancePrevalent | yesNoFormat | defaultValue 
        }}</span>
      </td>
      <td>
        {{ medianSalesAnalysis?.sellerPaidFinancialAssistancePrevalentOverallTrend | defaultValue  }}
      </td>
    </tr>
  </table>
</div>

<div class="median-comparable-detail median-comparable-detail-edit-mode" *ngIf="isEditMode && medianSalesAnalysis">
  <table
    class="median-comparable-table"
    [ngClass]="{ 'median-comparable-table-disabled': !marketCondition.isOverrideCompsStatistics }"
  >
    <caption style="display: none">
      Median Comparable Table
    </caption>
    <tr>
      <th class="median-comparable-table-title">Median Sale & List Price, DOM, Sale/List %</th>
      <th>Prior 7-12 Months</th>
      <th>Prior 4-6 Months</th>
      <th>Current - 3 Months</th>
      <th class="median-comparable-overall-trend">Overall Trend</th>
    </tr>
    <tr>
      <td class="median-comparable-table-title">Median Comparable Sale Price</td>
      <td>
        <input
            matInput
            [(ngModel)]="medianSalesAnalysis.medianComparableSalePrice.prior7To12Months"
            autocomplete="off"
            currencyMask
            mask="separator.0"
            thousandSeparator=","
            separatorLimit="9999999999"
            [allowNegativeNumbers]="false"
            [disabled]="!marketCondition.isOverrideCompsStatistics"
          />
      </td>
      <td>
        <input
            matInput
            [(ngModel)]="medianSalesAnalysis.medianComparableSalePrice.prior4To6Months"
            autocomplete="off"
            currencyMask
            mask="separator.0"
            thousandSeparator=","
            separatorLimit="9999999999"
            [allowNegativeNumbers]="false"
            [disabled]="!marketCondition.isOverrideCompsStatistics"
          />
      </td>

      <td>
        <input
            matInput
            [(ngModel)]="medianSalesAnalysis.medianComparableSalePrice.currentTo3Months"
            autocomplete="off"
            currencyMask
            mask="separator.0"
            thousandSeparator=","
            separatorLimit="9999999999"
            [allowNegativeNumbers]="false"
            [disabled]="!marketCondition.isOverrideCompsStatistics"
          />
      </td>

      <td>
        <mat-radio-group
        [(ngModel)]="medianSalesAnalysis.medianComparableSalePrice.overallTrend"
        [disabled]="!marketCondition.isOverrideCompsStatistics"
        class="overall-trend"
        >
        <mat-radio-button [value]="'Increasing'" class="report-input-radio-button">Increasing</mat-radio-button>
        <mat-radio-button [value]="'Stable'" class="report-input-radio-button">Stable</mat-radio-button>
        <mat-radio-button [value]="'Declining'" class="report-input-radio-button">Declining</mat-radio-button>
        </mat-radio-group>
      </td>
    </tr>

    <tr>
      <td class="median-comparable-table-title">Median Comparable Sales Days on Market</td>
      <td>
        <input
            matInput
            [(ngModel)]="medianSalesAnalysis.medianComparableSaleDaysOnMarket.prior7To12Months"
            autocomplete="off"
            numberMask
            mask="separator.0"
            thousandSeparator=","
            separatorLimit="9999999999"
            [allowNegativeNumbers]="false"
            [disabled]="!marketCondition.isOverrideCompsStatistics"
          />
      </td>
      <td>
        <input
            matInput
            [(ngModel)]="medianSalesAnalysis.medianComparableSaleDaysOnMarket.prior4To6Months"
            autocomplete="off"
            numberMask
            mask="separator.0"
            thousandSeparator=","
            separatorLimit="9999999999"
            [allowNegativeNumbers]="false"
            [disabled]="!marketCondition.isOverrideCompsStatistics"
          />
      </td>

      <td>
        <input
            matInput
            [(ngModel)]="medianSalesAnalysis.medianComparableSaleDaysOnMarket.currentTo3Months"
            autocomplete="off"
            numberMask
            mask="separator.0"
            thousandSeparator=","
            separatorLimit="9999999999"
            [allowNegativeNumbers]="false"
            [disabled]="!marketCondition.isOverrideCompsStatistics"
          />
      </td>

      <td>
            <mat-radio-group
            [(ngModel)]="medianSalesAnalysis.medianComparableSaleDaysOnMarket.overallTrend"
            [disabled]="!marketCondition.isOverrideCompsStatistics"
            class="overall-trend"
            >
            <mat-radio-button [value]="'Declining'" class="report-input-radio-button">Declining</mat-radio-button>
            <mat-radio-button [value]="'Stable'" class="report-input-radio-button">Stable</mat-radio-button>
            <mat-radio-button [value]="'Increasing'" class="report-input-radio-button">Increasing</mat-radio-button>
            </mat-radio-group>
      </td>
    </tr>

    <tr>
      <td class="median-comparable-table-title">Median Comparable List Price</td>
      <td>
        <input
            matInput
            [(ngModel)]="medianSalesAnalysis.medianComparableListPrice.prior7To12Months"
            autocomplete="off"
            currencyMask
            mask="separator.0"
            thousandSeparator=","
            separatorLimit="9999999999"
            [allowNegativeNumbers]="false"
            [disabled]="!marketCondition.isOverrideCompsStatistics"
          />
      </td>
      <td>
        <input
            matInput
            [(ngModel)]="medianSalesAnalysis.medianComparableListPrice.prior4To6Months"
            autocomplete="off"
            currencyMask
            mask="separator.0"
            thousandSeparator=","
            separatorLimit="9999999999"
            [allowNegativeNumbers]="false"
            [disabled]="!marketCondition.isOverrideCompsStatistics"
          />
      </td>

      <td>
        <input
            matInput
            [(ngModel)]="medianSalesAnalysis.medianComparableListPrice.currentTo3Months"
            autocomplete="off"
            currencyMask
            mask="separator.0"
            thousandSeparator=","
            separatorLimit="9999999999"
            [allowNegativeNumbers]="false"
            [disabled]="!marketCondition.isOverrideCompsStatistics"
          />
      </td>

        <td>
        <mat-radio-group
        [(ngModel)]="medianSalesAnalysis.medianComparableListPrice.overallTrend"
        [disabled]="!marketCondition.isOverrideCompsStatistics"
        class="overall-trend"
        >
        <mat-radio-button [value]="'Increasing'" class="report-input-radio-button">Increasing</mat-radio-button>
        <mat-radio-button [value]="'Stable'" class="report-input-radio-button">Stable</mat-radio-button>
        <mat-radio-button [value]="'Declining'" class="report-input-radio-button">Declining</mat-radio-button>
        </mat-radio-group>
        </td>
    </tr>

    <tr>
      <td class="median-comparable-table-title">Median Comparable Listings Days on Market</td>
      <td>
        <input
            matInput
            [(ngModel)]="medianSalesAnalysis.medianComparableListingDaysOnMarket.prior7To12Months"
            autocomplete="off"
            numberMask
            mask="separator.0"
            thousandSeparator=","
            separatorLimit="9999999999"
            [allowNegativeNumbers]="false"
            [disabled]="!marketCondition.isOverrideCompsStatistics"
          />
      </td>
      <td>
        <input
            matInput
            [(ngModel)]="medianSalesAnalysis.medianComparableListingDaysOnMarket.prior4To6Months"
            autocomplete="off"
            numberMask
            mask="separator.0"
            thousandSeparator=","
            separatorLimit="9999999999"
            [allowNegativeNumbers]="false"
            [disabled]="!marketCondition.isOverrideCompsStatistics"
          />
      </td>

      <td>
        <input
            matInput
            [(ngModel)]="medianSalesAnalysis.medianComparableListingDaysOnMarket.currentTo3Months"
            autocomplete="off"
            numberMask
            mask="separator.0"
            thousandSeparator=","
            separatorLimit="9999999999"
            [allowNegativeNumbers]="false"
            [disabled]="!marketCondition.isOverrideCompsStatistics"
          />
      </td>

      <td>
        <mat-radio-group
        [(ngModel)]="medianSalesAnalysis.medianComparableListingDaysOnMarket.overallTrend"
        [disabled]="!marketCondition.isOverrideCompsStatistics"
        class="overall-trend"
        >
            <mat-radio-button [value]="'Declining'" class="report-input-radio-button">Declining</mat-radio-button>
            <mat-radio-button [value]="'Stable'" class="report-input-radio-button">Stable</mat-radio-button>
            <mat-radio-button [value]="'Increasing'" class="report-input-radio-button">Increasing</mat-radio-button>
        </mat-radio-group>
        </td>
    </tr>

    <tr>
      <td class="median-comparable-table-title">Median Sale Price as % of List Price</td>
      <td>
        <input
            matInput
            [(ngModel)]="medianSalesAnalysis.medianSalePriceAsPercentOfListPrice.prior7To12Months"
            autocomplete="off"
            numberMask
            mask="separator.0"
            separatorLimit="9999999999"
            [allowNegativeNumbers]="false"
            [disabled]="!marketCondition.isOverrideCompsStatistics"
          />
      </td>
      <td>
        <input
            matInput
            [(ngModel)]="medianSalesAnalysis.medianSalePriceAsPercentOfListPrice.prior4To6Months"
            autocomplete="off"
            numberMask
            mask="separator.0"
            separatorLimit="9999999999"
            [allowNegativeNumbers]="false"
            [disabled]="!marketCondition.isOverrideCompsStatistics"
          />
      </td>

      <td>
        <input
            matInput
            [(ngModel)]="medianSalesAnalysis.medianSalePriceAsPercentOfListPrice.currentTo3Months"
            autocomplete="off"
            numberMask
            mask="separator.0"
            separatorLimit="9999999999"
            [allowNegativeNumbers]="false"
            [disabled]="!marketCondition.isOverrideCompsStatistics"
          />
      </td>

      <td>
        <mat-radio-group
        [(ngModel)]="medianSalesAnalysis.medianSalePriceAsPercentOfListPrice.overallTrend"
        [disabled]="!marketCondition.isOverrideCompsStatistics"
        class="overall-trend"
        >
        <mat-radio-button [value]="'Increasing'" class="report-input-radio-button">Increasing</mat-radio-button>
        <mat-radio-button [value]="'Stable'" class="report-input-radio-button">Stable</mat-radio-button>
        <mat-radio-button [value]="'Declining'" class="report-input-radio-button">Declining</mat-radio-button>
        </mat-radio-group>
        </td>
    </tr>

    <tr>
      <td colspan="4" class="median-comparable-table-title">
        <div style="display: flex;">
            Seller-(developer, builder, etc.) paid financial assistance prevalent?
            <mat-radio-group
            [(ngModel)]="medianSalesAnalysis.isSellerPaidFinancialAssistancePrevalent"
            [disabled]="!marketCondition.isOverrideCompsStatistics"
            class="overall-trend"
            >
            <mat-radio-button [value]="true" class="report-input-radio-button">Yes</mat-radio-button>
            <mat-radio-button [value]="false" class="report-input-radio-button">No</mat-radio-button>
            </mat-radio-group>
        </div>
      </td>
      <td class="">
        <mat-radio-group
        [(ngModel)]="medianSalesAnalysis.sellerPaidFinancialAssistancePrevalentOverallTrend"
        class="overall-trend"
        [disabled]="!marketCondition.isOverrideCompsStatistics"
        >
            <mat-radio-button [value]="'Declining'" class="report-input-radio-button">Declining</mat-radio-button>
            <mat-radio-button [value]="'Stable'" class="report-input-radio-button">Stable</mat-radio-button>
            <mat-radio-button [value]="'Increasing'" class="report-input-radio-button">Increasing</mat-radio-button>
        </mat-radio-group>
        </td>
    </tr>
  </table>
</div>
