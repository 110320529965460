<halo-global-popup [dialogRef]="dialogRef" [isHideClose]="true">
  <div
    class="document-review-container"
    [ngClass]="{
      'document-review-container': true,
      'contract-document-review-container': documentData.isContractDocument
    }"
  >
    <div class="document-review-top">
      <div class="document-top-left">
        <span class="document-title">{{ document.title }}.{{document.fileExtension}}</span>
      </div>

      <div class="document-top-right">
        <button class="jaro-kit-primary-button mouse-cursor-pointer" (click)="download()">Download</button>
        <div class="space"></div>
      
        <div class="save-btn">
          <button
            class="jaro-kit-primary-button mouse-cursor-pointer"
            (click)="closeDialog()"
          >
            Close
          </button>

          
        </div>
      </div>
    </div>
    <div class="document-review-detail" *ngIf="document?.extractedURL && !isImage">
      <jaro-kit-preview-document
        [url]="document.extractedURL"
        (onPdfLoadingFailed)="pdfLoadingFailed()"
        [(isLoading)]="isLoading"
        [paginationAttributeSuffix]="paginationAttributeSuffix"
      ></jaro-kit-preview-document>
    </div>

    <div class="document-image-container"  *ngIf="document?.extractedURL && isImage">
      <img [src]="document.extractedURL" alt="image" />
    </div>

  </div>
</halo-global-popup>
