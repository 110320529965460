import { Component, Input, OnInit } from '@angular/core';
import {
  MarketConditionDto,
  MarketConditionMedianSalesAnalysisDto
} from '@proxy/appraisal/report/reports/v1/form1004';

@Component({
  selector: 'jaro-kit-median-comparable',
  templateUrl: './median-comparable.component.html',
  styleUrls: ['./median-comparable.component.scss'],
})
export class MedianComparableComponent implements OnInit {
  @Input() isEditMode: boolean;
  @Input() marketCondition: MarketConditionDto;
  medianSalesAnalysis: MarketConditionMedianSalesAnalysisDto;

  ngOnInit(): void {
    if(this.marketCondition?.medianSalesAnalysis) {
      this.medianSalesAnalysis = this.marketCondition.medianSalesAnalysis;
      this.medianSalesAnalysis.medianComparableSalePrice = this.medianSalesAnalysis.medianComparableSalePrice || {};
      this.medianSalesAnalysis.medianComparableSaleDaysOnMarket = this.medianSalesAnalysis.medianComparableSaleDaysOnMarket || {};
      this.medianSalesAnalysis.medianComparableListPrice = this.medianSalesAnalysis.medianComparableListPrice || {};
      this.medianSalesAnalysis.medianComparableListingDaysOnMarket = this.medianSalesAnalysis.medianComparableListingDaysOnMarket || {};
      this.medianSalesAnalysis.medianSalePriceAsPercentOfListPrice = this.medianSalesAnalysis.medianSalePriceAsPercentOfListPrice || {};
    }
  }
}