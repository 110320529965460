import { get } from 'lodash-es';
import { ReportInputDataFormFieldModel } from 'src/app/features/shared/forms/models/report-input-data-field-form-model/report-input-date-field-form-model';
export const ReportInputTemplateConfigSubjectSection: ReportInputDataFormFieldModel[] = [
  {
    formControlName: 'subjectRightsAppraisedWithOther',
    mapToFieldFunc: (value, reportDto) => {
      if (value == 'other') {
        const propertyRightsDescription = get(
          reportDto,
          'property.propertyRightsDescription'
        );
        return `Other - ${propertyRightsDescription}`
      }
      return value;
    },
  }  
];
