import { CurrencyPipe } from '@angular/common';
import { Injectable, Pipe, PipeTransform } from '@angular/core';
import { OrderDetailDto } from '@proxy/ascent/order/order-assignment/order-assignments';
import { StringUtils } from '../../utils/string/string.utils';

@Injectable({
  providedIn: 'root',
})
@Pipe({
  name: 'orderSummarySailentDetailsFormat',
})
export class OrderSummarySalientDetailsFormatPipe implements PipeTransform {
  transform(assignmentType: string, orderData: OrderDetailDto): string {
    const loanAndProduct = [orderData?.loanForm, orderData?.mainForm].filter(s => !!s).join(" ");

    let assignmentTypeAndPurchaseContractAmount: string;
    if(assignmentType?.toLowerCase() === "purchase") {
      const purchaseContractAmount = (new CurrencyPipe("en-US")).transform(orderData?.purchasePrice, "USD", "symbol", "1.0-0");
      assignmentTypeAndPurchaseContractAmount = [StringUtils.capitalizeFirstLetter(assignmentType), purchaseContractAmount].filter(s => !!s).join(" - ");
    }
    else {
      assignmentTypeAndPurchaseContractAmount = StringUtils.capitalizeFirstLetter(assignmentType);
    }

    const sailientDetailsLine = [
      loanAndProduct,
      assignmentTypeAndPurchaseContractAmount,
      orderData?.propertyType,
      orderData?.residenceType
    ].filter(s => !!s).join(" | ");
    
    return sailientDetailsLine;
  }
}