import { CurrencyPipe } from '@angular/common';
import { Component, EventEmitter, Inject, Input, OnInit, Output } from '@angular/core';
import { get, set } from 'lodash-es';
import * as moment from 'moment';
import {
  LineItemDto,
  ReportComparableFieldConfig,
  ReportComparableFieldType,
  ReportComparableModel,
} from 'src/app/proxy-adapter/report/comparables/models';
import InjectionSymbol from 'src/app/shared/injection/injection-symbol';
import { DateTimeFormatPipe } from 'src/app/shared/pipes/date-time-format/date-time-format.pipe';
import { YesNoFormatPipe } from 'src/app/shared/pipes/yes-no-format/yes-no-format.pipe';
import { EventService } from 'src/app/shared/services/event.service';
import { Feature } from 'src/app/shared/utils/feature/feature';
@Component({
  selector: 'jaro-kit-comparables-grid-cell',
  templateUrl: './comparables-grid-cell.component.html',
  styleUrls: ['./comparables-grid-cell.component.scss'],
  providers:    [ CurrencyPipe ]
})
export class ComparablesGridCellComponent implements OnInit {
  @Input() comparableDto: ReportComparableModel;
  @Input() subjectDto: ReportComparableModel;
  @Input() field: ReportComparableFieldConfig;
  @Input() isSelected: boolean;

  @Output() partialUpdate = new EventEmitter<any>();
  @Output() onUpdateSubject = new EventEmitter<any>();  

  inputType: string;
  gridCellType: string;
  value: string;
  totalAdjustmentValue: number;
  lineItems: LineItemDto[];
  isShowHighlightAdjustment: boolean;
  
  get subjectValue() {
    return this.subjectDto ? this.subjectDto[this.field.key] : null;
  }

  get isHighlightAdjustment() {
    if(!Feature.isEnabledFeature('AdjustmentValidation'))
      return false;

    if(this.field.isAdjustmentRequired === false)
    {
      return false;
    }

    let isDifferentSubjectValue: boolean;

    if (!this.subjectValue && !this.value) {
      isDifferentSubjectValue = false;
    } else if (!this.subjectValue || !this.value) {
      isDifferentSubjectValue = true;
    } else {
      isDifferentSubjectValue = this.subjectValue.trim().toLowerCase() != this.value.trim().toLowerCase()
    }
    return (
      isDifferentSubjectValue &&
      !this.totalAdjustmentValue &&
      this.totalAdjustmentValue != 0 &&
      this.isShowHighlightAdjustment
    );
  }

  get isDisabled() {
    return this.field.isDisabled || this.comparableDto.isSubject;
  }

  get totalAdjustment() {
    return this.totalAdjustmentValue
      ? Math.abs(this.totalAdjustmentValue)
      : this.totalAdjustmentValue;
  }

  constructor(private currencyPipe: CurrencyPipe,
    @Inject(InjectionSymbol.EventService)
    private eventService: EventService) {}

  ngOnInit(): void {
    this.gridCellType = 'Comparable';
    if (this.comparableDto) {
      if (this.field.type === ReportComparableFieldType.TotalAdjustment) {
        this.gridCellType = 'TotalAdjustment';
      } else if (this.field.type === ReportComparableFieldType.ExtraAttribute) {
        this.gridCellType = 'ExtraAttribute';
      } else if (this.comparableDto.isSubject) {
        this.gridCellType = 'Subject';
        setTimeout(() => {
          this.subjectDto[this.field.key] = this.value;
        }, 100);
      }
      this.inputType = this.field?.inputType;
      this.initValueAndAdjustmentValue();
      
    }

    this.eventService.onGoToReportField.subscribe((field) => {
      if (field.sectionId === 'comparables') {
        setTimeout(() => {
          if(field.fieldId === this.comparableDto.upi) {
            this.isShowHighlightAdjustment = true;
          }
        }, 500);
      }
    });
  }

  private initValueAndAdjustmentValue() {
    if (this.field.path) {
      switch (this.field.type) {
        case ReportComparableFieldType.Item:
          this.initLineItemValue();
          break;
        case ReportComparableFieldType.NestedItems:
          this.initNestedItems();
          break;
        case ReportComparableFieldType.Custom:
          const customItem = (this.comparableDto.customItems || []).find(
            (item) => item.name == this.field.key
          );
          if (customItem) {
            this.value = customItem.value || '';
            this.totalAdjustmentValue = customItem.adjustment;
          }

          break;
        case ReportComparableFieldType.ReadOnly:
        default:
          this.value = get(this.comparableDto, this.field.path);
          break;
      }
    }
  }

  private initNestedItems() {
    if (!this.isNested()) return;
    this.buildNestedDisplayValueOutput();
    if (!this.comparableDto.isSubject) {
      this.initNestedLineItems();
      this.setNestedTotalAdjustmentValue();
      this.calculateGrossAdjustment();
    }
  }

  private setNestedTotalAdjustmentValue() {
    if (!this.hasNestedItems()) this.totalAdjustmentValue = null;
    let totalAdj = null;
    this.comparableDto.nestedItems[this.field.key].forEach((nestedItem) => {
      let newAdjustment =
        (nestedItem.adjustment || nestedItem.adjustment === 0) && !isNaN(nestedItem.adjustment)
          ? parseFloat(nestedItem.adjustment.toString())
          : null;
      totalAdj = totalAdj !== null ? totalAdj + newAdjustment : newAdjustment;
    });
    this.totalAdjustmentValue = totalAdj;
  }

  private initNestedLineItems() {
    if (!this.hasNestedItems()) return;
    if (!this.comparableDto.isSubject) {
      if (!this.comparableDto.nestedItems || this.comparableDto.nestedItems === undefined)
        this.comparableDto.nestedItems = {};
      let nestedItems: LineItemDto[] = [];
      this.field.nestedItems.forEach((nestedLineItem) => {
        let baseItem = get(this.comparableDto, nestedLineItem.path) ?? null;
        if (baseItem && typeof baseItem === 'object') {
          let newItem: LineItemDto = {
            name: nestedLineItem.name,
            label: nestedLineItem.label,
            path: nestedLineItem.path,
            value: nestedLineItem.value,
            inputType: nestedLineItem.inputType,
            isDisabled: nestedLineItem.isDisabled,
            isOptional: nestedLineItem.isOptional,
          };
          newItem.adjustment = baseItem.totalAdjustment;
          nestedItems.push(newItem);
        }
      });

      this.comparableDto.nestedItems[this.field.key] = nestedItems;
    }
  }

  private buildNestedDisplayValues() {
    let displayValues: string[] = [];
    // Build nested item base field values array.
    this.field.nestedItems.forEach((element) => {
      let nestedItem = get(this.comparableDto, element.path);
      let outputVal = nestedItem.value ?? '';
      // Add any custom formatting based on field name.
      switch (element.name) {
        case 'saleType': {
          outputVal = get(this.comparableDto, 'saleType.type.value');          
          break;
        }
        case 'saleOrFinancingConcession': {
          let financingType = get(
            this.comparableDto,
            'saleOrFinancingConcession.financingType.value'
          );
          let concessionAmount = get(
            this.comparableDto,
            'saleOrFinancingConcession.concessionAmount.value'
          );

          if (!financingType && !concessionAmount) {
            return '';
          }

          if (concessionAmount) {
            concessionAmount = concessionAmount ? `${concessionAmount}`.replace(/,/gm, '') : '0';
            concessionAmount = this.currencyPipe.transform(
              concessionAmount,
              'USD',
              'symbol',
              '1.0-0'
            );
          }
          outputVal = [financingType, concessionAmount].join(' | ');
          break;
        }
        case 'quality': {
          let quality = parseFloat(nestedItem.value);
          if (!isNaN(quality)) {
            outputVal = 'Q' + nestedItem.value;
          } else {
            // Subject and some existing data will contain Q/C but lower cased.
            outputVal = outputVal.toUpperCase();
          }
          break;
        }
        case 'condition': {
          let condition = parseFloat(nestedItem.value);
          if (!isNaN(condition)) {
            outputVal = 'C' + nestedItem.value;
          } else {
            // Subject and some existing data will contain Q/C but lower cased.
            outputVal = outputVal.toUpperCase();
          }
          break;
        }
        default:
          break;
      }
      displayValues.push(outputVal);
    });
    return displayValues;
  }

  private isNested() {
    return this.field.type === ReportComparableFieldType.NestedItems;
  }

  private hasNestedItems() {
    return this.isNested() && this.field.nestedItems && this.field.nestedItems.length > 0;
  }

  private buildNestedDisplayValueOutput() {
    if (!this.hasNestedItems()) return;

    // Build an array of display Values.
    let displayValues = this.buildNestedDisplayValues();

    // Join and apply any custom formatting to the values
    let displayString = displayValues.join(' | ');
    this.value = displayString;
  }

  private initLineItemValue() {
    const item = get(this.comparableDto, this.field.path);
    this.lineItems = [];
    if (item && typeof item === 'object') {
      this.value = item.value;
      this.totalAdjustmentValue = item.adjustment || item.totalAdjustment;
      this.initLineItems(item);
      this.calculateGrossAdjustment();
      this.initCustomDisplayValue(this.field);
    }
  }

  private initCustomDisplayValue(field : ReportComparableFieldConfig) {
    switch (this.field.key) {
      case 'dateOfSaleTime': {
        this.value = this.getDateOfSaleTime();
        break;
      }
      case 'site' : {
        let siteElement = get(this.comparableDto, field.path);
        let siteArea : number = (siteElement?.value && !isNaN(siteElement.value)) ? parseFloat(siteElement?.value) : null;
        if(siteArea && siteArea < 43560) {
          siteElement.value = siteArea.toString() + " sf";
        } else if(siteArea && siteArea >= 43560) {
          siteElement.value = (siteArea / 43560).toFixed(2) + " ac";
        }
        this.value = siteElement.value;
        set(this.comparableDto, `${field.path}.value`, siteElement.value);
        break;
      }
      default: {
        break;
      }
    }
  }

  private initLineItems(item) {
    this.lineItems = (this.field.lineItems || []).map((lineItem) => {
      const obj = item[lineItem.path];
      return {
        name: lineItem.name,
        path: lineItem.path,
        inputType: lineItem.inputType,
        isDisabled: obj?.isNonAdjustable,
        value: this.getFormatValueItem(lineItem.inputType, obj.value),
        adjustment: obj?.adjustment,
        isOptional: lineItem.isOptional,
      };
    });
    if (this.lineItems.length > 0) {
      if (this.lineItems.filter((lineItem) => lineItem.value).length == 0) {
        this.value = '';
      } else if (this.lineItems.length > 0 && !this.field.adjustmentOverridden) {
        this.value = this.lineItems
          .filter((lineItem) => lineItem.value || !lineItem.isOptional)
          .map((lineItem) => lineItem.value || '')
          .join(' | ');
      }
      //
      this.totalAdjustmentValue = item.totalAdjustment;
    }
  }

  private getDateOfSaleTime() {
    const status = get(this.comparableDto, 'dateOfSaleTime.status.value');
    if (!status) {
      return null;
    }
    let dateOfSaleTimeValue = null;
    switch (status.toLowerCase()) {
      case 'active':
        dateOfSaleTimeValue = `${status}`;
        break;
      case 'contract': {
        const contractDate = this.getDateValue(
          get(this.comparableDto, 'dateOfSaleTime.contractDate.value')
        );
        dateOfSaleTimeValue = `c${contractDate || ''}`;
        break;
      }
      case 'expired': {
        const expiredDate = this.getDateValue(
          get(this.comparableDto, 'dateOfSaleTime.expiredDate.value')
        );
        dateOfSaleTimeValue = `e${expiredDate || ''}`;
        break;
      }

      case 'withdrawn': {
        const withdrawnDate = this.getDateValue(
          get(this.comparableDto, 'dateOfSaleTime.withdrawnDate.value')
        );
        dateOfSaleTimeValue = `w${withdrawnDate || ''}`;
        break;
      }
      case 'settled sale': {
        const contract = this.getDateValue(
          get(this.comparableDto, 'dateOfSaleTime.contractDate.value')
        );
        const settledDate = this.getDateValue(
          get(this.comparableDto, 'dateOfSaleTime.settledDate.value')
        );
        const contractValue = contract ? `c${contract}` : 'Unk';
        dateOfSaleTimeValue = `s${settledDate || ''} | ${contractValue}`;
        break;
      }
      default:
        break;
    }
    return dateOfSaleTimeValue;
  }

  private getDateValue(date: string) {
    if (!date) return null;
    const utcDate = moment(date, ['YYYYMMDD', 'YYYYMM', 'MM/DD/YYYY', 'MM/YY'], true);
    return utcDate.isValid() ? utcDate.format('MM/YY') : '';
  }

  private calculateGrossAdjustment() {
    if (this.isNested()) {
      this.calculateNestedGrossAdjustments();
    } else {
      this.calculateObjectGrossAdjustment();
    }
  }

  private calculateNestedGrossAdjustments() {
    if (!this.hasNestedItems()) return 0;
    this.field.nestedItems.forEach((element) => {
      let nestedItem = get(this.comparableDto, element.path);
      if (nestedItem && typeof nestedItem === 'object' && nestedItem.totalAdjustment) {
        set(
          this.comparableDto,
          `${element.path}.grossAdjustment`,
          Math.abs(+nestedItem.totalAdjustment || 0)
        );
      }
    });
  }

  private calculateObjectGrossAdjustment() {
    const obj = get(this.comparableDto, this.field.path);
    if (obj && typeof obj === 'object') {
      if (obj.adjustment !== undefined && !isNaN(obj.adjustment) && obj.adjustment != null) {
        obj.grossAdjustment = Math.abs(obj.adjustment || 0);
      } else {
        obj.grossAdjustment = Math.abs(obj.totalAdjustment || 0);
      }

      if (this.lineItems.length > 0 && this.field.adjustmentType !== 'totals') {
        obj.grossAdjustment = this.lineItems
          .map((item) =>
            item.adjustment && !isNaN(item.adjustment)
              ? Math.abs(parseFloat(item.adjustment.toString()))
              : 0
          )
          .reduce((a, b) => a + b, 0);
      }
    }
  }

  private getFormatValueItem(inputType: string, value) {
    switch (inputType) {
      case 'date':
        const formatDate = new DateTimeFormatPipe();
        return formatDate.transform(value);
      case 'boolean':
        const yesNoFormat = new YesNoFormatPipe();
        return yesNoFormat.transform(value);
      default:
        return value;
    }
  }

  private calculateAdjustmentTotal() {
    this.comparableDto.netAdjustmentTotal =
      this.calculateNetAndGrossAdjustmentTotal('totalAdjustment');
    this.comparableDto.netAdjPercent = this.calculateNetAdjPercent();    
    this.comparableDto.adjustedSalePrice = this.calculateAdjustedSalePrice();
    this.comparableDto.grossAdjustmentTotal =
      this.calculateNetAndGrossAdjustmentTotal('grossAdjustment');
    this.comparableDto.grossAdjPercent = this.calculateGrossAdjPercent();
  }

  private calculateNetAndGrossAdjustmentTotal(type: string) {
    let total = 0;
    // This will include base items which are used for Nested totals.
    Object.keys(this.comparableDto).forEach((key) => {
      const item = this.comparableDto[key];
      if (item && typeof item === 'object' && item[type] && !isNaN(item[type])) {
        total += parseFloat(item[type]);
      }
    });
    const totalCustom = this.comparableDto.customItems
      .map((item) =>
        item.adjustment && !isNaN(item.adjustment)
          ? type === 'grossAdjustment'
            ? Math.abs(parseFloat(item.adjustment.toString()))
            : parseFloat(item.adjustment.toString())
          : 0
      )
      .reduce((a, b) => a + b, 0);

    return total + totalCustom;
  }

  private calculateAdjustedSalePrice() {
    return (
      this.comparableDto.netAdjustmentTotal +
      (!isNaN(this.comparableDto.price) ? this.comparableDto.price : 0)
    );
  }

  private calculateNetAdjPercent() {
    return this.comparableDto.price &&
      this.comparableDto.price != 0 &&
      !isNaN(this.comparableDto.price)
      ? (this.comparableDto.netAdjustmentTotal * 100) / this.comparableDto.price
      : null;
  }

  private calculateGrossAdjPercent() {
    return this.comparableDto.price &&
      this.comparableDto.price != 0 &&
      !isNaN(this.comparableDto.price)
      ? (this.comparableDto.grossAdjustmentTotal * 100) / this.comparableDto.price
      : null;
  }

  changeAdjustmentValue(value) {
    this.totalAdjustmentValue = value == '' ? null : value;
    if (this.field.type === ReportComparableFieldType.Custom) {
      const customItem = (this.comparableDto.customItems || []).find(
        (item) => item.name === this.field.key
      );
      if (customItem && typeof customItem === 'object') {
        customItem.adjustment = value;
      }
    } else {
      set(this.comparableDto, `${this.field.path}.totalAdjustment`, this.totalAdjustmentValue);
    }
    this.calculateGrossAdjustment();
    this.calculateAdjustmentTotal();
  }

  changeNestedItemAdjustmentValue(keypair: object) {
    if (this.field.type !== ReportComparableFieldType.NestedItems) return;

    let key = keypair['key'] ?? '';
    let value = keypair['value'] ?? '';
    set(this.comparableDto, `${key}.adjustment`, +value);
    set(this.comparableDto, `${key}.totalAdjustment`, +value);
    set(this.comparableDto, `${key}.grossAdjustment`, Math.abs(+value || 0));
    this.calculateAdjustmentTotal();
    this.setNestedTotalAdjustmentValue();
  }

  onPartialUpdate(data) {
    if (data) {
      const partialUpdate = {
        updates: data,
        upi: this.comparableDto.upi,
        isSubject: this.comparableDto.isSubject,
      };
      this.partialUpdate.emit(partialUpdate);
    }
  }

  changeValue() {
    const reportInputUpdates: Record<string, string> = {};
    reportInputUpdates[`${this.field.path}.value`] = this.value;
    this.onPartialUpdate(reportInputUpdates);
  }

  changeCustomField() {
    const customField = this.comparableDto.customItems.find((item) => item.name === this.field.key);
    if (customField) {
      customField.displayName = this.field.label;
      customField.value = this.value;
    }
    const partialUpdate = {
      updates: this.comparableDto.customItems,
      isSubject: this.comparableDto.isSubject,
    };
    this.subjectDto[this.field.key] = this.value;
    this.partialUpdate.emit(partialUpdate);
  }
}
