<ng-container *ngIf="form" [formGroup]="form">
  <p class="report-input-title pb-15">Present Land Use %</p>  
  <div class="row">
    <div class="col-md-4 pl-0 pr-15">
      <mat-form-field appearance="outline">
        <mat-label>One-Unit</mat-label>
        <input matInput formControlName="neighborhoodOneUnit" autocomplete="off" mask="separator.0" thousandSeparator="" separatorLimit="999"  [allowNegativeNumbers]="false" (keyup)="onLandUseKeyUp($event)" />
      </mat-form-field>
    </div>

    <div class="col-md-4 pl-0 pr-15">
      <mat-form-field appearance="outline">
        <mat-label>2-4 Unit</mat-label>
        <input matInput formControlName="neighborhoodTwoToFourUnit" autocomplete="off" mask="separator.0" thousandSeparator="" separatorLimit="999" [allowNegativeNumbers]="false" (keyup)="onLandUseKeyUp($event)" />
      </mat-form-field>
    </div>

    <div class="col-md-4 pl-0 pr-0">
      <mat-form-field appearance="outline">
        <mat-label>Multi-Family</mat-label>
        <input matInput formControlName="neighborhoodMultiFamily" autocomplete="off" mask="separator.0" thousandSeparator="" separatorLimit="999" [allowNegativeNumbers]="false" (keyup)="onLandUseKeyUp($event)" />
      </mat-form-field>
    </div>

    <div class="col-md-4 pl-0 pr-15">
      <mat-form-field appearance="outline">
        <mat-label>Commercial</mat-label>
        <input matInput formControlName="neighborhoodCommercial" autocomplete="off" mask="separator.0" thousandSeparator="" separatorLimit="999" [allowNegativeNumbers]="false" (keyup)="onLandUseKeyUp($event)" />
      </mat-form-field>
    </div>

    <div class="col-md-4 pl-0 pr-15">
      <mat-form-field appearance="outline">
        <mat-label>Other</mat-label>
        <input matInput formControlName="neighborhoodOther" autocomplete="off" mask="separator.0" thousandSeparator="" separatorLimit="999" [allowNegativeNumbers]="false" (keyup)="onLandUseKeyUp($event)" />
      </mat-form-field>
    </div>

    <div class="col-md-4 pl-0 pr-15" style="padding-top:10px">
      <input type="hidden" formControlName="presentLandUseTotal" id="presentLandUseTotal"/>
      <jaro-kit-validation-message [form]="form" [controlName]="'presentLandUseTotal'" total="100"></jaro-kit-validation-message>
    </div>    
  </div>
</ng-container>
