import { Component } from '@angular/core';
import { FormGroup } from '@angular/forms';

@Component({
  selector: 'halo-global-neighborhood-section1004',
  templateUrl: './neighborhood-section1004.component.html',
  styleUrls: ['./neighborhood-section1004.component.scss']
})
export class NeighborhoodSection1004Component{

  form: FormGroup;

}
