import { RestService } from '@abp/ng.core';
import { Inject, Injectable } from '@angular/core';
import { MarketConditionDto } from '@proxy/appraisal/report/reports/v1/form1004';
import { PartialUpdateReportDto } from '@proxy/appraisal/report/v1';
import { ReportService } from '@proxy/bff/activity/controllers/v1';
import { ReportVersionWithLinksDto } from '@proxy/bff/activity/reports/v1/models';
import { get } from 'lodash-es';
import { Observable, map, of } from 'rxjs';
import { ReportsServiceInterface } from 'src/app/interface/bff/activity/reports-service-interface';
import InjectionSymbol from 'src/app/shared/injection/injection-symbol';
import { CacheService } from 'src/app/shared/services/cache.service';

@Injectable()
export class ReportsServiceAdapter extends ReportService implements ReportsServiceInterface {
  readonly key = 'LatestReport';
  constructor(
    private _restService: RestService,
    @Inject(InjectionSymbol.CacheService)
    private cacheService: CacheService
  ) {
    super(_restService);
  }
  getLatest = (
    orderAssignmentId: string,
    isUseCacheData?: boolean
  ): Observable<ReportVersionWithLinksDto> => {
    if (isUseCacheData) {
      const latestReport = this.cacheService.getDataCacheByKey(this.key);
      if (latestReport) return of(latestReport);
    }
    const reportVersionObservable = this.getLatestReport(orderAssignmentId);
    return reportVersionObservable.pipe(
      map((reportVersion) => {
        const extendReportVersion = this.extendReportVersion(reportVersion);
        this.cacheService.cacheDataWithKey(this.key, extendReportVersion);
        return extendReportVersion;
      })
    );
  };

  partialUpdate = (
    reportId: string,
    reportVersion: number,
    partialUpdateRequest: PartialUpdateReportDto
  ): Observable<ReportVersionWithLinksDto> => {
    return this.partialUpdateReport(reportId, reportVersion, partialUpdateRequest).pipe(
      map((reportVersion) => {
        const extendReportVersion = this.extendReportVersion(reportVersion);
        this.cacheService.cacheDataWithKey(this.key, extendReportVersion);
        return extendReportVersion;
      })
    );
  };

  private extendReportVersion(reportVersion: ReportVersionWithLinksDto): ReportVersionWithLinksDto {
    reportVersion.report.property.improvement.fullAndHalfBathroomBelowGradeCount =
      this.getFullAndHalfBathroomBelowGradeCount(reportVersion);
    return reportVersion;
  }

  private getFullAndHalfBathroomBelowGradeCount(reportVersion: ReportVersionWithLinksDto): string {
    const fullCount = reportVersion.report.property.improvement.totalFullBathroomBelowGradeCount;
    const halfCount = reportVersion.report.property.improvement.totalHalfBathroomBelowGradeCount;
    const isShowEmpty = !fullCount && fullCount !== 0 && !halfCount && halfCount !== 0;
    return !isShowEmpty ? `${fullCount || '0'}.${halfCount || '0'}` : null;
  }

  getUpdateMarketConditionModel(marketCondition: MarketConditionDto) {
    return {
      // general-information
      'property.marketCondition.isOverrideCompsStatistics': get(
        marketCondition,
        'isOverrideCompsStatistics'
      ),
      'property.marketCondition.isIncludeInReport': get(marketCondition, 'isIncludeInReport'),
      'property.marketCondition.sellerConcessionTrendsForPast12Months': get(
        marketCondition,
        'sellerConcessionTrendsForPast12Months'
      ),
      'property.marketCondition.areForeclosureSalesAFactorInMarket': get(
        marketCondition,
        'areForeclosureSalesAFactorInMarket'
      ),
      'property.marketCondition.foreclosureSalesExplanationIncludingTrendsInListings': get(
        marketCondition,
        'foreclosureSalesExplanationIncludingTrendsInListings'
      ),
      'property.marketCondition.dataSources': get(marketCondition, 'dataSources'),
      'property.marketCondition.summary': get(marketCondition, 'summary'),

      //median-comparable
      'property.marketCondition.medianSalesAnalysis.medianComparableSalePrice.prior7To12Months':
        get(marketCondition, 'medianSalesAnalysis.medianComparableSalePrice.prior7To12Months'),
      'property.marketCondition.medianSalesAnalysis.medianComparableSalePrice.prior4To6Months': get(
        marketCondition,
        'medianSalesAnalysis.medianComparableSalePrice.prior4To6Months'
      ),
      'property.marketCondition.medianSalesAnalysis.medianComparableSalePrice.currentTo3Months':
        get(marketCondition, 'medianSalesAnalysis.medianComparableSalePrice.currentTo3Months'),
      'property.marketCondition.medianSalesAnalysis.medianComparableSalePrice.overallTrend': get(
        marketCondition,
        'medianSalesAnalysis.medianComparableSalePrice.overallTrend'
      ),

      'property.marketCondition.medianSalesAnalysis.medianComparableSaleDaysOnMarket.prior7To12Months':
        get(
          marketCondition,
          'medianSalesAnalysis.medianComparableSaleDaysOnMarket.prior7To12Months'
        ),
      'property.marketCondition.medianSalesAnalysis.medianComparableSaleDaysOnMarket.prior4To6Months':
        get(
          marketCondition,
          'medianSalesAnalysis.medianComparableSaleDaysOnMarket.prior4To6Months'
        ),
      'property.marketCondition.medianSalesAnalysis.medianComparableSaleDaysOnMarket.currentTo3Months':
        get(
          marketCondition,
          'medianSalesAnalysis.medianComparableSaleDaysOnMarket.currentTo3Months'
        ),
      'property.marketCondition.medianSalesAnalysis.medianComparableSaleDaysOnMarket.overallTrend':
        get(marketCondition, 'medianSalesAnalysis.medianComparableSaleDaysOnMarket.overallTrend'),

      'property.marketCondition.medianSalesAnalysis.medianComparableListPrice.prior7To12Months':
        get(marketCondition, 'medianSalesAnalysis.medianComparableListPrice.prior7To12Months'),
      'property.marketCondition.medianSalesAnalysis.medianComparableListPrice.prior4To6Months': get(
        marketCondition,
        'medianSalesAnalysis.medianComparableListPrice.prior4To6Months'
      ),
      'property.marketCondition.medianSalesAnalysis.medianComparableListPrice.currentTo3Months':
        get(marketCondition, 'medianSalesAnalysis.medianComparableListPrice.currentTo3Months'),
      'property.marketCondition.medianSalesAnalysis.medianComparableListPrice.overallTrend': get(
        marketCondition,
        'medianSalesAnalysis.medianComparableListPrice.overallTrend'
      ),

      'property.marketCondition.medianSalesAnalysis.medianComparableListingDaysOnMarket.prior7To12Months':
        get(
          marketCondition,
          'medianSalesAnalysis.medianComparableListingDaysOnMarket.prior7To12Months'
        ),
      'property.marketCondition.medianSalesAnalysis.medianComparableListingDaysOnMarket.prior4To6Months':
        get(
          marketCondition,
          'medianSalesAnalysis.medianComparableListingDaysOnMarket.prior4To6Months'
        ),
      'property.marketCondition.medianSalesAnalysis.medianComparableListingDaysOnMarket.currentTo3Months':
        get(
          marketCondition,
          'medianSalesAnalysis.medianComparableListingDaysOnMarket.currentTo3Months'
        ),
      'property.marketCondition.medianSalesAnalysis.medianComparableListingDaysOnMarket.overallTrend':
        get(
          marketCondition,
          'medianSalesAnalysis.medianComparableListingDaysOnMarket.overallTrend'
        ),

      'property.marketCondition.medianSalesAnalysis.medianSalePriceAsPercentOfListPrice.prior7To12Months':
        get(
          marketCondition,
          'medianSalesAnalysis.medianSalePriceAsPercentOfListPrice.prior7To12Months'
        ),
      'property.marketCondition.medianSalesAnalysis.medianSalePriceAsPercentOfListPrice.prior4To6Months':
        get(
          marketCondition,
          'medianSalesAnalysis.medianSalePriceAsPercentOfListPrice.prior4To6Months'
        ),
      'property.marketCondition.medianSalesAnalysis.medianSalePriceAsPercentOfListPrice.currentTo3Months':
        get(
          marketCondition,
          'medianSalesAnalysis.medianSalePriceAsPercentOfListPrice.currentTo3Months'
        ),
      'property.marketCondition.medianSalesAnalysis.medianSalePriceAsPercentOfListPrice.overallTrend':
        get(
          marketCondition,
          'medianSalesAnalysis.medianSalePriceAsPercentOfListPrice.overallTrend'
        ),

      'property.marketCondition.medianSalesAnalysis.isSellerPaidFinancialAssistancePrevalent': get(
        marketCondition,
        'medianSalesAnalysis.isSellerPaidFinancialAssistancePrevalent'
      ),
      'property.marketCondition.medianSalesAnalysis.sellerPaidFinancialAssistancePrevalentOverallTrend':
        get(
          marketCondition,
          'medianSalesAnalysis.sellerPaidFinancialAssistancePrevalentOverallTrend'
        ),

      //Inventory Analysis
      'property.marketCondition.inventoryAnalysis.totalNumberOfComparableSales.prior7To12Months':
        get(marketCondition, 'inventoryAnalysis.totalNumberOfComparableSales.prior7To12Months'),
      'property.marketCondition.inventoryAnalysis.totalNumberOfComparableSales.prior4To6Months':
        get(marketCondition, 'inventoryAnalysis.totalNumberOfComparableSales.prior4To6Months'),
      'property.marketCondition.inventoryAnalysis.totalNumberOfComparableSales.currentTo3Months':
        get(marketCondition, 'inventoryAnalysis.totalNumberOfComparableSales.currentTo3Months'),
      'property.marketCondition.inventoryAnalysis.totalNumberOfComparableSales.overallTrend': get(
        marketCondition,
        'inventoryAnalysis.totalNumberOfComparableSales.overallTrend'
      ),

      'property.marketCondition.inventoryAnalysis.absorptionRate.overallTrend': get(
        marketCondition,
        'inventoryAnalysis.absorptionRate.overallTrend'
      ),

      'property.marketCondition.inventoryAnalysis.totalNumberOfComparableActiveListings.prior7To12Months':
        get(
          marketCondition,
          'inventoryAnalysis.totalNumberOfComparableActiveListings.prior7To12Months'
        ),
      'property.marketCondition.inventoryAnalysis.totalNumberOfComparableActiveListings.prior4To6Months':
        get(
          marketCondition,
          'inventoryAnalysis.totalNumberOfComparableActiveListings.prior4To6Months'
        ),
      'property.marketCondition.inventoryAnalysis.totalNumberOfComparableActiveListings.currentTo3Months':
        get(
          marketCondition,
          'inventoryAnalysis.totalNumberOfComparableActiveListings.currentTo3Months'
        ),
      'property.marketCondition.inventoryAnalysis.totalNumberOfComparableActiveListings.overallTrend':
        get(
          marketCondition,
          'inventoryAnalysis.totalNumberOfComparableActiveListings.overallTrend'
        ),

      'property.marketCondition.inventoryAnalysis.monthsOfHousingSupply.overallTrend': get(
        marketCondition,
        'inventoryAnalysis.monthsOfHousingSupply.overallTrend'
      ),
    };
  }
}
