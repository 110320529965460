export enum FeatureNames {
  Report = "report",
  Sketch = "sketch",
  Photos = "photos",
  Documents = "documents",
  Comparables = "comparables",
  Market = "market",
  Tools = "tools",
  JaroBoost = "jaro-boost",
  Assignment = "assignment",
  RawDataView = "raw-data-view",
  Workfile = "tools/workfile"
}

export type FeatureName = FeatureNames.Assignment | FeatureNames.Comparables | FeatureNames.Documents | FeatureNames.JaroBoost | FeatureNames.Market |
                            FeatureNames.Photos | FeatureNames.RawDataView | FeatureNames.Report | FeatureNames.Sketch | FeatureNames.Tools |
                            FeatureNames.Workfile;
