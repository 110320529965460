import { Component, Inject, Input } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { ContentServiceInterface } from 'src/app/interface/content/content-service-interface';
import { ItemData } from 'src/app/shared/components/multiple-combo-box/multiple-combo-box.component';
import InjectionSymbol from 'src/app/shared/injection/injection-symbol';

@Component({
  selector: 'halo-global-car-storage-section1004',
  templateUrl: './car-storage-section1004.component.html',
  styleUrls: ['./car-storage-section1004.component.scss'],
})
export class CarStorageSection1004Component {
  @Input() form: FormGroup;

  drivewaySurfaceOptions: ItemData[] = [];

  constructor(
    @Inject(InjectionSymbol.ContentService)
    public contentService: ContentServiceInterface
  ) {
    this.drivewaySurfaceOptions = this.contentService.getContentByKey(
      'vehicleStorage.drivewaySurface'
    );
  }

  onChangeCarStorage($event) {
    this.form.controls['carStorage'].setValue($event.value);
    if ($event.value === false) {
      const controlNames = [
        'carStorageDriveway',
        'carStorageDrivewayCars',
        'carStorageDrivewaySurface',
        'carStorageCarport',
        'carStorageCarportCars',
        'carStorageGarage',
        'carStorageAttachedGarage',
        'carStorageDetachedGarage',
        'carStorageBuiltInGarage',
      ];
      this.resetFields(controlNames);
    }
  }

  onChangeDriveway($event) {
    this.form.controls['carStorageDriveway'].setValue($event.value);
    if ($event.value === false) {
      const controlNames = ['carStorageDrivewayCars', 'carStorageDrivewaySurface'];
      this.resetFields(controlNames);
    }
  }

  onChangeCarport($event) {
    this.form.controls['carStorageCarport'].setValue($event.value);
    if ($event.value === false) {
      const controlNames = ['carStorageCarportCars'];
      this.resetFields(controlNames);
    }
  }

  onChangeGarage($event) {
    this.form.controls['carStorageGarage'].setValue($event.value);
    if ($event.value === false) {
      const controlNames = [
        'carStorageAttachedGarage',
        'carStorageDetachedGarage',
        'carStorageBuiltInGarage',
      ];
      this.resetFields(controlNames);
    }
  }

  private resetFields(controlNames: string[]) {
    controlNames.forEach((controlName) => {
      const control = this.form.controls[controlName];
      if (control) {
        control.setValue(null);
        control.markAsDirty();
      }
    });
    this.form.updateValueAndValidity();
  }
}
