<div class="document-pdf-item">
  <div class="document-icon">
    <img src="/assets/images/icons/document.svg" alt="document" />
  </div>
  <div class="document-detail">
    <span class="document-title mouse-cursor-pointer" (click)="checkAndOpenDocument(document)">{{
      document.title
    }}</span>
    <span class="document-category">{{
      document.documentCategory
    }}</span>
    <div class="document-pages">
      <div class="vertical_dotted_line">
        <div class="dotted_line"></div>
      </div>
      <span *ngIf="!document.isProcessing"
      class="document-page-range mouse-cursor-pointer"
      (click)="checkAndOpenExtractedDocument(document)"
      >{{ document.pages | pageRange }}</span>

      <div *ngIf="document.isProcessing === true" class="pdf-loading-spinner">
      <mat-spinner
        value="100"
        diameter="30"
        strokeWidth="2"></mat-spinner>
      </div>
      <em
        class="fa-regular fa-trash-can jaro-kit-primary-icon"
        aria-hidden="true"
        (click)="removeDocument()"
      ></em>
    </div>
  </div>
</div>
