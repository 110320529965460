<div *ngIf="form" [formGroup]="form">
  <mat-form-field appearance="outline">
    <mat-label>{{ field.label }}</mat-label>
    <input
      matInput
      [formControlName]="field.formControlName"
      autocomplete="off"
      [id]="field.formControlName"
    />
  </mat-form-field>
  <jaro-kit-validation-message
    [form]="form"
    [controlName]="field.formControlName"
    [isRequired]="field.isRequired"
  ></jaro-kit-validation-message>
</div>
