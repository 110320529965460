<halo-global-popup [dialogRef]="dialogRef" [isHideClose]="true">
  <div class="image-carousel-container">
    <div class="image-carousel-top">
      <div (click)="closeDialog()" class="image-carousel-close mouse-cursor-pointer">
        <em class="fal fa-times"></em>
      </div>
    </div>
    <div class="image-carousel">
      <ngx-gallery
        #ngxGallery
        [options]="galleryOptions"
        [images]="galleryImages"
        class="ngx-gallery"
        (change)="changeSelectPhoto()"
      ></ngx-gallery>
    </div>
  </div>
</halo-global-popup>
