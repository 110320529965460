import { Component, Inject, Input } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { ReportWithExtraAttributes } from 'src/app/features/report/shared/models/report-input-extra-atributes.model';
import { ContentServiceInterface } from 'src/app/interface/content/content-service-interface';
import { ItemData } from 'src/app/shared/components/multiple-combo-box/multiple-combo-box.component';
import InjectionSymbol from 'src/app/shared/injection/injection-symbol';

@Component({
  selector: 'jaro-kit-occupancy',
  templateUrl: './occupancy.component.html',
  styleUrls: ['./occupancy.component.scss'],
})
export class OccupancyComponent {
  @Input() form: FormGroup;
  @Input() reportDto: ReportWithExtraAttributes;
  occupantOptions: ItemData[] = [];

  constructor(
    @Inject(InjectionSymbol.ContentService)
    public contentService: ContentServiceInterface
  ) {
    this.occupantOptions = this.contentService.getContentByKey('occupancy.occupant');
  }
}
