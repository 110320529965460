import { Component, EventEmitter, Inject, Input, OnInit, Output } from '@angular/core';
import { ReportVersionDto, ValidationResultDto } from '@proxy/appraisal/report/reports/v1';
import { OrderDetailDto } from '@proxy/ascent/order/order-assignment/order-assignments/v2/orderDetailDto';
import { ReportVersionWithLinksDto } from '@proxy/bff/activity/reports/v1';
import { ReportServiceInterface } from 'src/app/interface/appraisal/report/report-service-interface';
import InjectionSymbol from 'src/app/shared/injection/injection-symbol';
import { EventService } from 'src/app/shared/services/event.service';
import { MessageService } from 'src/app/shared/services/message.service';

@Component({
  selector: 'jaro-kit-report-input-header',
  templateUrl: './report-input-header.component.html',
  styleUrls: ['./report-input-header.component.scss'],
})
export class ReportInputHeaderComponent implements OnInit {
  @Input() mainPicture: string;
  @Input() orderAssignmentId: string;
  @Input() productName: string;
  @Input() orderData: OrderDetailDto;
  @Input() reportVersionDto: ReportVersionWithLinksDto;
  @Input() isActiveQualityCheck: boolean;
  @Input() isActivePreviewReport: boolean;
  @Input() showBoostDataViewButton: boolean;

  @Output() onToggleQualityCheck = new EventEmitter<boolean>();

  errorCount: number = 0;
  warningCount: number = 0;
  timeToRefreshQualityCheck = 15 * 60 * 1000;
  refreshInterval: ReturnType<typeof setInterval>;
  isQCLoaded: boolean = false;
  isLoading: boolean;

  constructor(
    @Inject(InjectionSymbol.ReportService)
    private reportService: ReportServiceInterface,
    @Inject(InjectionSymbol.EventService)
    private eventService: EventService,
    private msgService: MessageService,
  ) {
    this.eventService.onRefreshQualityCheck.subscribe((isRefresh) =>  {
      if(isRefresh) {
        this.isLoading = true;
      }
    });

    this.eventService.onUpdatedQualityCheck.subscribe((reportValidation) =>  {
      if(reportValidation) {
        this.refreshQCIndidator(reportValidation);
      }
    });

    this.eventService.onSaveSection.subscribe((isSave) => {
      if (isSave) {
        this.refreshQualityCheck();
      }
    });

    this.eventService.onUpdateAppraiserInfo.subscribe((reportVersion: ReportVersionDto) => {
      if(reportVersion !== null) {
        this.refreshQualityCheck();
      }
    });

    this.eventService.onOpenQualityCheck.subscribe(() => {
      this.openQualityCheck();
    })
  }

  ngOnInit(): void {

    console.log("isActivePreviewReport = " + this.isActivePreviewReport.toString());
    setTimeout(() => {
      // This should still refresh if QC is not active as it will then be
      // available when the user switches tabs to a tab where QC is active.
      this.refreshQualityCheck();
    }, 2000);
  }

  openQualityCheck() {
    this.onToggleQualityCheck.emit(true);
  }

  refreshQualityCheck() {
    this.isLoading = true;
    this.eventService.refreshQualityCheck(true);
    const isUseMainFormName = this.reportVersionDto.productType?.toLocaleLowerCase() === '1004d';
    const mainFormName = isUseMainFormName
      ? this.productName?.toLocaleLowerCase()?.replace('&', 'and')
      : null;

    setTimeout(() => {
      this.reportService
        .reportValidations(this.orderAssignmentId, 'kit-submit', mainFormName)
        .subscribe({
          next: (response: ValidationResultDto) => {
            this.eventService.updateQualityCheck(response);
          },
          error: (err) => this.logActionError(err)
        }) }, 100);
    }

  private refreshQCIndidator(validation: ValidationResultDto) {
      if (validation?.results?.length > 0) {
        const validations = validation.results;
        const validationsQC = validations.find(item => (item.rule || '').toLocaleLowerCase() === 'comparables');
        this.errorCount = validations.filter((item) => item.severity === 'error').length + (validationsQC?.comparables?.length || 0);
        this.warningCount = validations.filter((item) => item.severity === 'warning').length;
      } else {
        this.errorCount = 0;
        this.warningCount = 0;
      }
      this.isQCLoaded = true;
      this.resetRefreshInterval();
      this.isLoading = false;
  }

  private resetRefreshInterval() {
    if (this.isActiveQualityCheck) {
      if (this.refreshInterval) {
        clearInterval(this.refreshInterval);
      }
      this.refreshInterval = setInterval(() => {
        this.refreshQualityCheck();
      }, this.timeToRefreshQualityCheck);
    }
  }

  private logActionError(err: any): void {
    this.isLoading = false;
    this.msgService.error(err).subscribe(() => {});
  }
}
