<div class="general-information-detail" *ngIf="!isEditMode">
  <div class="override-comps">
    <div class="report-input-form-field">
      <span class="report-input-label">Override Comps statistics</span>
      <span class="report-input-value">{{
        marketCondition.isOverrideCompsStatistics | yesNoFormat
      }}</span>
    </div>

    <div class="report-input-form-field">
      <span class="report-input-label">Include in report</span>
      <span class="report-input-value">{{ marketCondition.isIncludeInReport | yesNoFormat }}</span>
    </div>
  </div>

  
  <jaro-kit-inventory-analysis [(marketCondition)]="marketCondition" [(isEditMode)]="isEditMode" *ngIf="marketCondition?.inventoryAnalysis"></jaro-kit-inventory-analysis>
  <jaro-kit-median-comparable [(marketCondition)]="marketCondition" [isEditMode]="isEditMode" *ngIf="marketCondition?.medianSalesAnalysis"></jaro-kit-median-comparable>

  <div class="report-input-label-container">
    <span class="report-input-label"
      >Seller concession trends for the past 12 months</span
    >
    <span class="report-input-value">{{
      marketCondition.sellerConcessionTrendsForPast12Months | defaultValue
    }}</span>
  </div>

  <div class="report-input-label-container">
    <span class="report-input-label"
      >Are foreclosure sales (REO sales) a factor in the market?</span
    >
    <span class="report-input-value">{{
      marketCondition.areForeclosureSalesAFactorInMarket | yesNoFormat | defaultValue
    }}</span>
  </div>

  <div
    class="report-input-label-container"
    *ngIf="marketCondition.areForeclosureSalesAFactorInMarket"
  >
    <span class="report-input-label"
      >Explain (including the trends in listings and sales of foreclosed properties)</span
    >
    <span class="report-input-value">{{
      marketCondition.foreclosureSalesExplanationIncludingTrendsInListings | defaultValue
    }}</span>
  </div>

  <div class="report-input-label-container">
    <span class="report-input-label">Data sources</span>
    <span class="report-input-value">{{ marketCondition.dataSources | defaultValue }}</span>
  </div>

  <div class="report-input-label-container">
    <span class="report-input-label"
      >Summary</span
    >
    <span class="report-input-value">{{ marketCondition.summary | defaultValue }}</span>
  </div>
</div>

<div class="general-information-detail general-information-detail-edit-mode" *ngIf="isEditMode && marketCondition">
  <div class="override-comps">
    <div class="report-input-form-field">
      <mat-checkbox
        [(ngModel)]="marketCondition.isOverrideCompsStatistics"
        class="check-box-label"
        value="true"
        >Override Comps statistics</mat-checkbox
      >
    </div>
    <div class="report-input-form-field">
      <mat-checkbox
        [(ngModel)]="marketCondition.isIncludeInReport"
        class="check-box-label"
        value="true"
        >Include in report</mat-checkbox
      >
    </div>
  </div>

  <jaro-kit-inventory-analysis [(marketCondition)]="marketCondition" [(isEditMode)]="isEditMode" *ngIf="marketCondition?.inventoryAnalysis"></jaro-kit-inventory-analysis>
  <jaro-kit-median-comparable [(marketCondition)]="marketCondition" [isEditMode]="isEditMode" *ngIf="marketCondition?.medianSalesAnalysis"></jaro-kit-median-comparable>

  <div class="report-input-form-field mt-15">
    <mat-form-field appearance="outline" class="jaro-kit-textarea">
      <mat-label>Seller concession trends for the past 12 months</mat-label>
      <textarea
        type="text"
        [(ngModel)]="marketCondition.sellerConcessionTrendsForPast12Months"
        placeholder="Seller concession trends for the past 12 months"
        matInput
        rows="4"
      ></textarea>
    </mat-form-field>
  </div>

  <div class="report-input-form-field">
    <p class="report-input-label report-input-radio-label">
      Are foreclosure sales (REO sales) a factor in the market?
    </p>

    <mat-radio-group
      [(ngModel)]="marketCondition.areForeclosureSalesAFactorInMarket"
      class="mt-15"
      (change)="marketCondition.foreclosureSalesExplanationIncludingTrendsInListings = null"
    >
      <mat-radio-button [value]="true" class="report-input-radio-button">Yes</mat-radio-button>
      <mat-radio-button [value]="false" class="report-input-radio-button">No</mat-radio-button>
    </mat-radio-group>
  </div>

  <div class="report-input-form-field" *ngIf="marketCondition.areForeclosureSalesAFactorInMarket">
    <mat-form-field appearance="outline" class="jaro-kit-textarea">
      <mat-label
        >Explain (including the trends in listings and sales of foreclosed properties)</mat-label
      >
      <textarea
        type="text"
        [(ngModel)]="marketCondition.foreclosureSalesExplanationIncludingTrendsInListings"
        placeholder="If yes, explain (including the trends in listings and sales of foreclosed properties)."
        matInput
        rows="4"
      ></textarea>
    </mat-form-field>
  </div>

  <div class="report-input-form-field">
    <mat-form-field appearance="outline" class="jaro-kit-textarea">
      <mat-label>Data sources</mat-label>
      <textarea
        type="text"
        [(ngModel)]="marketCondition.dataSources"
        placeholder="Data sources"
        matInput
        rows="4"
      ></textarea>
    </mat-form-field>
  </div>

  <div class="report-input-form-field">
    <mat-form-field appearance="outline" class="jaro-kit-textarea">
      <mat-label
        >Summary</mat-label
      >
      <textarea
        type="text"
        [(ngModel)]="marketCondition.summary"
        placeholder="Summary"
        matInput
        rows="4"
      ></textarea>
    </mat-form-field>
  </div>
</div>
