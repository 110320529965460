import { Type } from '@angular/core';
import { ErrorDto } from '@proxy/property/property-job/property-jobs/v1';
import { FeatureName } from 'src/app/shared/enums/report-input.enum';

export interface ReportInputTemplate {
  id?: string;
  title: string;
  feature?: FeatureName;
  productType?: string;
  sections?: ReportInputTemplateSectionModel[];
  displaydataStatus?: string;
}

export class ReportInputTemplateSectionModel implements ISubSectionManager {
  id?: string;
  routeSegment: string;
  title: string;
  jsonPath: string;
  isJaroBoost?: boolean;
  isDisabled?: boolean;
  layoutHint?: string;
  subSections?: ReportInputTemplateSubSectionModel[];
  isEditMode?: boolean;
  isCompleted?: boolean;
  eventsOnSave?: string[];
  eventsOnCancel?: string[];
  dependentFields?: string[];
  allow?: ReportInputDataFieldVisibility[];
  isFormBuilder?: boolean;
  isCustom?: boolean;
  errors?: ErrorDto[];

  public insertSubSectionBeforeComponent(
    componentName: string,
    subSection: ReportInputTemplateSubSectionModel
  ): void {
    const componentIndex = this.subSections.findIndex(
      (item) => item.componentName === componentName
    );
    this.subSections.splice(componentIndex, 0, subSection);
  }
}

export interface ISubSectionManager {
  insertSubSectionBeforeComponent(
    componentName: string,
    subSection: ReportInputTemplateSubSectionModel
  ): void;
}

export interface ReportInputTemplateSubSectionModel {
  id?: string;
  title?: string;
  isShowTitle?: boolean;
  groupTitle?:string;
  isShowGroupTitle?:boolean;
  isVisible?: boolean;
  isCustomComponent?: boolean;
  componentName?: string;
  layoutHint?: string;
  isEditMode?: boolean;
  fields?: ReportInputTemplateField[];
  dynamicFields?: ReportInputTemplateField[];
  isDynamicSubSection?: boolean;
  metadata?: any;
}

export interface ReportInputTemplateField {
  formControlName?: string;
  type?: ReportInputFieldType;
  label?: string;
  value?: any;
  path?: string;
  componentName?: string;
  layoutHint?: string;
  isVisible?: boolean;
  isDisabled?: boolean;
  isRequired?: boolean;
  validators?: ReportInputValidators;
  allow?: ReportInputDataFieldVisibility[];
  deny?: ReportInputDataFieldVisibility[];
  mapToFieldFunc?: Function;
  mapToModelFunc?: Function;
  mapToPartialUpdateDto?: Function;
  format?: ReportInputDataFieldFormat;
  dateConfig?: ReportInputDateConfig;
  isIgnoreChange?: boolean;
  updatePath?: string;
  metadata?: any;
  isPublishFieldChange?: boolean;
  rowIndex?: number;
  events?: ReportInputEvent[];
}

export interface ReportInputDateConfig {
  dataFormat?: string;
  displayFormat?: string;
  isFormatUtcAsLocalTimeWithTimeZoneSuffix?: boolean;
  isFormatUtcAsLocalTime?: boolean;
}

export interface ReportInputEvent {
  key?: string;
  action?: Function;
}

export interface ReportInputDataFieldVisibility {
  key: string;
  values: string[];
  isFormControl?: boolean;
}

export interface ReportInputDataFieldFormat {
  pipeType?: Type<any>;
  pipeArgs?: any[];
}

export class ReportInputValidators {
  public isRequired?: boolean;
  public maxLength?: number;
  public minLength?: number;
  public min?: number;
}

export enum ReportInputFieldType {
  Checkbox = 'checkbox',
  Text = 'text',
  Array = 'array',
  Date = 'date',
  DateString = 'datestring',
  ComboBox = 'combo_box',
  Currency = 'currency',
  Label = 'label',
  Integer = 'integer',
  Custom = 'custom'
}

export class PublishFieldChange {
  fieldName: string;
  value: any;
}
