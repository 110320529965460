<div *ngIf="form" [formGroup]="form">
  <jaro-kit-date-picker
    [form]="form"
    [formatDate]="field.dateConfig?.displayFormat"
    [dateControl]="field.formControlName"
    [titleDate]="field.label"
    [isRequired]="field.isRequired"
    [id]="field.formControlName"
  ></jaro-kit-date-picker>
</div>
