<ng-container *ngIf="form" [formGroup]="form">
  <p class="report-input-title pb-15">Narrative Comments</p>

  <mat-form-field appearance="outline" class="jaro-kit-textarea">
    <mat-label>Neighborhood Boundaries</mat-label>
    <textarea
      type="text"
      formControlName="neighborhoodBoundaries"
      placeholder="Neighborhood Boundaries"
      matInput
      rows="4"
    ></textarea>
  </mat-form-field>

  <mat-form-field appearance="outline" class="jaro-kit-textarea">
    <mat-label>Neighborhood Description</mat-label>
    <textarea
      type="text"
      formControlName="neighborhoodDescription"
      placeholder="Neighborhood Description"
      matInput
      rows="4"
    ></textarea>
  </mat-form-field>

  <mat-form-field appearance="outline" class="jaro-kit-textarea">
    <mat-label>Market Conditions</mat-label>
    <textarea
      type="text"
      formControlName="neighborhoodMarketConditions"
      placeholder="Market Conditions (including support for the above conclusions)"
      matInput
      rows="4"
    ></textarea>
  </mat-form-field>
</ng-container>
