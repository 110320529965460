import { Component, Inject, Input, OnInit } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { ReportDto } from '@proxy/appraisal/report/v1/form1004';
import { ReportVersionWithLinksDto } from '@proxy/bff/activity/reports/v1';
import {
  ReportInputTemplateField,
  ReportInputTemplateSectionModel,
  ReportInputTemplateSubSectionModel,
} from 'src/app/features/shared/forms/models/report-input-layout.model';
import { ReportInputTemplateService } from 'src/app/features/shared/forms/providers/report-input-template.service';
import InjectionSymbol from 'src/app/shared/injection/injection-symbol';
import { EventService } from 'src/app/shared/services/event.service';

export class FieldOrderGroup {
  public rowIndex: number;
  public fields: ReportInputTemplateField[];
}

@Component({
  selector: 'jaro-kit-view-mode-sub-section-formbuilder',
  templateUrl: './view-mode-sub-section-formbuilder.component.html',
  styleUrls: ['./view-mode-sub-section-formbuilder.component.scss'],
})
export class ViewModeSubSectionFormBuilderComponent implements OnInit {
  @Input() section: ReportInputTemplateSectionModel;
  @Input() subSection: ReportInputTemplateSubSectionModel;
  @Input() reportDto: ReportDto;
  @Input() timeZoneId: string;
  @Input() reportInputSectionForm: FormGroup;
  @Input() orderAssignmentId: string;
  @Input() feature: string;
  @Input() reportVersionDto: ReportVersionWithLinksDto;

  public fieldGroups: FieldOrderGroup[];

  constructor(

    @Inject(InjectionSymbol.EventService)
    protected eventService: EventService,

    @Inject(InjectionSymbol.ReportInputTemplateService)
    protected reportInputTemplateService: ReportInputTemplateService
  ) {}

  ngOnInit(): void {
    this.reportInputTemplateService.updateVisibleForSubSection(
      this.subSection,
      this.reportDto,
      this.reportInputSectionForm
    );
    this.fieldGroups = this.getFieldGroups();
    this.eventService.onChangeReportData.subscribe((field: ReportInputTemplateField) => {
      if (field) {
        this.reportInputTemplateService.updateVisibleForSubSection(
          this.subSection,
          this.reportDto,
          this.reportInputSectionForm
        );
      }
    });
  }

  private getFieldGroups(): FieldOrderGroup[] {
    const groupList: FieldOrderGroup[] = [];
    const sortFields = (this.subSection.fields || []).sort((a, b) =>
      a.rowIndex < b.rowIndex ? -1 : 1
    );

    sortFields.forEach((field) => {
      let group = groupList.find((item) => item.rowIndex === field.rowIndex);
      if (group) {
        group.fields.push(field);
      } else {
        group = {
          rowIndex: field.rowIndex,
          fields: [field],
        };
        groupList.push(group);
      }
    });
    return groupList;
  }

  getLayoutHintField(field: ReportInputTemplateField) {
    return field.layoutHint?.includes('col-') === true
      ? field.layoutHint
      : `col-lg-12 col-md-12 ${field.layoutHint}`;
  }
}
