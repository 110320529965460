import { Component, EventEmitter, Input, Output } from '@angular/core';
import { OrderAssignmentDto } from '@proxy/ascent/order/order-assignment/order-assignments';

@Component({
  selector: 'jaro-kit-order-search-results-panel',
  templateUrl: './order-search-results-panel.component.html',
  styleUrls: ['./order-search-results-panel.component.scss'],
})
export class OrderSearchResultsPanelComponent {
  @Input() isLoading: boolean;
  @Input() searchResults: OrderAssignmentDto[];

  @Output() onClick = new EventEmitter<boolean>();
}
