<div class="order-summary-banner">
  <div class="summary-line">
    <span class="order-number">#{{ orderData?.customerOrderId }}</span>
    <a *ngIf="showBoostDataViewButton" class="nostyle" href="{{rawDataUrl}}" target="_blank"><span class="address"> {{ subjectProperty | orderSummaryAddressFormat : orderData }}</span></a>
    <span *ngIf="!showBoostDataViewButton" class="address"> {{ subjectProperty | orderSummaryAddressFormat : orderData }}</span>
    <a href="{{ orderData | googleSearchAddressUrlFormat }}" target="_blank"><span class="google-search-icon-span"><i class="google-search-icon"></i></span></a>    
    <a href="{{ orderData | zillowSearchAddressUrlFormat }}" target="_blank"><span class="zillow-icon-span"><i class="zillow-icon"></i></span></a>
    <a href="{{ orderData | googleMapAddressUrlFormat }}" target="_blank"><span class="google-map-icon-span"><i class="google-map-icon"></i></span></a>
  </div>
  <div class="summary-line">
    <span class="salient-details">{{ assignmentType | orderSummarySailentDetailsFormat : orderData }}</span>
  </div>
</div>

