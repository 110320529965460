import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { cloneDeep } from 'lodash-es';
import * as moment from 'moment';
import { Moment } from 'moment-timezone';
import { Observable, map, of } from 'rxjs';

export interface CacheModel {
  lastTimeToCache: Moment,
  dataCache: any;
}

@Injectable({
  providedIn: 'root',
})
export class CacheService {
  private mapJsonFile: Map<string, CacheModel> = new Map();
  private mapData: Map<string, CacheModel> = new Map();
  
  constructor(private http: HttpClient) {}

  public cacheDataWithKey(key: string, data: any) {
    this.mapData.set(key, {
      lastTimeToCache: moment.utc(),
      dataCache: cloneDeep(data)
    } as CacheModel);
  }

  public getDataCacheByKey(key: string) {
    const obj = this.mapData.get(key);
    return obj?.lastTimeToCache && obj.lastTimeToCache.diff(moment.utc(), 'minutes') < 15 ? cloneDeep(obj.dataCache) : null;
  }

  private saveJsonFile(key: string, data: any) {
    this.mapJsonFile.set(key, {
      lastTimeToCache: moment.utc(),
      dataCache: cloneDeep(data)
    } as CacheModel);
  }

  private getJsonFile(key: string) {
    const obj = this.mapJsonFile.get(key);
    return obj?.lastTimeToCache && obj.lastTimeToCache.diff(moment.utc(), 'minutes') < 15 ? cloneDeep(obj.dataCache) : null;
  }

  checkAndGetJsonFile(jsonPath: string): Observable<any> {
    const dataCache = this.getJsonFile(jsonPath);
    if (dataCache) {
      return of(dataCache);
    }

    return this.http.get(jsonPath).pipe(
      map((data) => {
        this.saveJsonFile(jsonPath, data);
        return data;
      })
    );
  }
}
