<halo-global-popup [dialogRef]="dialogRef" [isHideClose]="true">
  <div
    [ngClass]="{
      'translucent-loading-overlay': isLoading
    }"
  >&nbsp;</div>

    <div class="workfile-export-container">
      <div class="workfile-export-block">
        <div class="workfile-export-left">
          <span class="workfile-export-title" >
            Export Workfile
          </span>
        </div>       
      </div>      
      <div class="workfile-export-container" style="background-color: rgb(242, 242, 243)">      
        <div class="workfile-export-block" *ngIf="!isError">
            <span  class="workfile-export-left">
              <p>Please wait while the <strong>ZIP</strong> file is being generated.</p>
            </span>            
            <a #exportLink [href]="exportUrl" style="display: none" [disabled]="isLoading" target="_self" download><em class="fas fa-download"></em>Download</a>
        </div>
        <div class="workfile-export-block" *ngIf="isError">
          <span class="workfile-export-title">
            <span class="buttonDescription"><p>Failed to generate the <strong>ZIP</strong> file.</p></span>
            <button class="workfile-export-cancel" (click)="cancelButton()" >Close</button>
          </span>
        </div>                
      </div>
    </div>
  
</halo-global-popup>
