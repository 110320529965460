import { Component, Inject, Input, OnInit } from '@angular/core';
import { FormGroup } from '@angular/forms';
import InjectionSymbol from 'src/app/shared/injection/injection-symbol';
import { CacheService } from 'src/app/shared/services/cache.service';

@Component({
  selector: 'halo-global-utilities',
  templateUrl: './utilities.component.html',
  styleUrls: ['./utilities.component.scss'],
})
export class UtilitiesComponent implements OnInit {
  @Input() form: FormGroup;
  utilities = [];

  constructor(
    @Inject(InjectionSymbol.CacheService)
    private cacheService: CacheService
  ) {}

  ngOnInit(): void {
    this.cacheService
      .checkAndGetJsonFile('/assets/json/template/report/1004/utilities.config.json')
      .subscribe((data: any) => {
        this.utilities = data;
      });
  }
}
