<div *ngFor="let section of documentSections" class="report-documents">
  <span class="section-name report-input-title mat-headline" *ngIf="!section.isEditSection && isEditSectionEnabled(section)" (click)="editSectionNameClick(section)"
    >{{ section.name }} ({{ section.reportDocuments?.length || 0 }})</span
  >
  <span class="section-name report-input-title mat-headline" *ngIf="!isEditSectionEnabled(section)"
    >{{ section.name }} ({{ section.reportDocuments?.length || 0 }})</span
  >

  <div class="add-section-container" *ngIf="section.isEditSection && isEditSectionEnabled(section)">
    <jaro-kit-edit-section-name
      [sections]="documentSections"
      [sectionName]="section.name"
      [msgDelete]="msgDelete"
      [section]="section"
      (onEditSection)="editSection($event)"
      (onCancelEditSection)="cancelEditSection(section)"
      (onDeleteSection)="deleteSection($event)"
    ></jaro-kit-edit-section-name>
  </div>

  <jaro-kit-upload-document
    [orderAssignmentId]="orderAssignmentId"
    [isMultiple]="false"
    [fileTypeAccept]="getfileTypeAccept(section)"
    reportResourceType="Document"
    title="document"
    (onFileUploadCompleted)="addNewDocumentToSection($event, section.id)"
    [documentsUploading]="getSectionDocumentUpload(section.id)"
  ></jaro-kit-upload-document>

  <jaro-kit-document-items
    [orderAssignmentId]="orderAssignmentId"
    [documents]="section.reportDocuments"
    [documentsUploading]="section.documentsUploading"
    (onRemoveDocument)="removeDocument($event, section.id)"
    [documentsUploading]="getSectionDocumentUpload(section.id)"
    (onAddDocument)="addExistingDocumentToSection($event, section.id)"
    (onReselectedPages)="reselectedPages($event, section.id)"
  >
  </jaro-kit-document-items>
</div>
