import { Component, Input } from '@angular/core';
import { FormGroup } from '@angular/forms';

@Component({
  selector: 'jaro-kit-property-size',
  templateUrl: './property-size1004.component.html',
  styleUrls: ['./property-size1004.component.scss'],
})
export class PropertySizeComponent {
  @Input() form: FormGroup;
}
