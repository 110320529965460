import { CommonModule } from '@angular/common';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { NgModule } from '@angular/core';
import { OrderSearchResultsComponent } from './order-search-results.component';
import { SharedModule } from 'src/app/shared/shared.module';
import { OrderSearchResultsItemComponent } from './order-search-results-item/order-search-results-item.component';
import { OrderSearchResultsPanelComponent } from './order-search-results-panel/order-search-results-panel.component';

@NgModule({
  declarations: [
    OrderSearchResultsComponent,
    OrderSearchResultsItemComponent,
    OrderSearchResultsPanelComponent,
  ],
  imports: [
    CommonModule,
    MatFormFieldModule,
    MatInputModule,
    MatProgressSpinnerModule,
    SharedModule,
  ],
  exports: [OrderSearchResultsComponent],
})
export class OrderSearchResultsModule {}
