import { Component, Input } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { ReportDto } from '@proxy/appraisal/report/v1/form1004';
import { ReportVersionWithLinksDto } from '@proxy/bff/activity/reports/v1';
import {
  ReportInputTemplateSectionModel,
  ReportInputTemplateSubSectionModel,
} from 'src/app/features/shared/forms/models/report-input-layout.model';

@Component({
  selector: 'jaro-kit-edit-mode-sub-section',
  templateUrl: './edit-mode-sub-section.component.html',
  styleUrls: ['./edit-mode-sub-section.component.scss'],
})
export class EditModeSubSectionComponent {
  @Input() section: ReportInputTemplateSectionModel;
  @Input() subSection: ReportInputTemplateSubSectionModel;
  @Input() reportDto: ReportDto;
  @Input() reportInputSectionForm: FormGroup;
  @Input() orderAssignmentId: string;
  @Input() feature: string;
  @Input() reportVersionDto: ReportVersionWithLinksDto;
}
