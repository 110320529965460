import { Injectable, Pipe, PipeTransform } from '@angular/core';
import * as moment from 'moment';
@Injectable({
  providedIn: 'root',
})
@Pipe({
  name: 'dateTimeFormat',
})
export class DateTimeFormatPipe implements PipeTransform {
  transform(utcDateStr: string | Date, formatDate?: string): any {
    const format = formatDate ? formatDate : 'MM/DD/YYYY';
    return utcDateStr ? moment.utc(utcDateStr).format(format) : null;
  }
}
