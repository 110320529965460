<div class="market-cleanup-property-details">
  <div class="market-cleanup-property-details-left">
    <jaro-kit-market-cleanup-property-visual-media
      [property]="property"
      [isLoading]="isLoading"
    ></jaro-kit-market-cleanup-property-visual-media>
  </div>
  <div class="market-cleanup-property-details-right">
    <div class="sale-price">
      <span class="price-label">Sale Price: </span>
      <span *ngIf="price" class="price-value">{{ price | currency: 'USD':'symbol':'1.0-0' }}</span>
      <span *ngIf="!price" class="price-value">No Value</span>
    </div>

    <div class="general-details">
      <span class="report-input-title">General Details</span>
      <div class="row">
        <div class="report-input-form-field col-md-4">
          <span class="report-input-label">MLS Status</span>
          <span class="report-input-value"> - </span>
        </div>

        <div class="report-input-form-field col-md-4">
          <span class="report-input-label">Association Fee</span>
          <span class="report-input-value"> - </span>
        </div>

        <div class="report-input-form-field col-md-4">
          <span class="report-input-label">Postal Code</span>
          <span class="report-input-value"> - </span>
        </div>
      </div>

      <div class="row">
        <div class="report-input-form-field col-md-4">
          <span class="report-input-label">Is Distressed</span>
          <span class="report-input-value"> - </span>
        </div>

        <div class="report-input-form-field col-md-4">
          <span class="report-input-label">Is in Association</span>
          <span class="report-input-value"> - </span>
        </div>

        <div class="report-input-form-field col-md-4">
          <span class="report-input-label">HOA Monthly Assessment</span>
          <span class="report-input-value"> - </span>
        </div>
      </div>

      <div class="row">
        <div class="report-input-form-field col-md-4">
          <span class="report-input-label">Distress Type</span>
          <span class="report-input-value"> - </span>
        </div>

        <div class="report-input-form-field col-md-4">
          <span class="report-input-label">Association Name</span>
          <span class="report-input-value"> - </span>
        </div>

        <div class="report-input-form-field col-md-4">
          <span class="report-input-label">Association Details</span>
          <span class="report-input-value"> - </span>
        </div>
      </div>
    </div>

    <div class="general-details">
      <span class="report-input-title">Listing Details</span>
      <div class="row">
        <div class="report-input-form-field col-md-4">
          <span class="report-input-label">MLS Status</span>
          <span class="report-input-value"> - </span>
        </div>

        <div class="report-input-form-field col-md-4">
          <span class="report-input-label">Association Fee</span>
          <span class="report-input-value"> - </span>
        </div>

        <div class="report-input-form-field col-md-4">
          <span class="report-input-label">Postal Code</span>
          <span class="report-input-value"> - </span>
        </div>
      </div>

      <div class="row">
        <div class="report-input-form-field col-md-4">
          <span class="report-input-label">Is Distressed</span>
          <span class="report-input-value"> - </span>
        </div>

        <div class="report-input-form-field col-md-4">
          <span class="report-input-label">Is in Association</span>
          <span class="report-input-value"> - </span>
        </div>

        <div class="report-input-form-field col-md-4">
          <span class="report-input-label">HOA Monthly Assessment</span>
          <span class="report-input-value"> - </span>
        </div>
      </div>

      <div class="row">
        <div class="report-input-form-field col-md-4">
          <span class="report-input-label">Distress Type</span>
          <span class="report-input-value"> - </span>
        </div>

        <div class="report-input-form-field col-md-4">
          <span class="report-input-label">Association Name</span>
          <span class="report-input-value"> - </span>
        </div>

        <div class="report-input-form-field col-md-4">
          <span class="report-input-label">Association Details</span>
          <span class="report-input-value"> - </span>
        </div>
      </div>
    </div>

    <div class="general-details">
      <span class="report-input-title">Sale History</span>
      <div class="row market-cleanup-property-sale-history">
        <jaro-kit-market-cleanup-property-sale-history [property]="property" *ngIf="!isLoading">
        </jaro-kit-market-cleanup-property-sale-history>
      </div>
    </div>
  </div>
</div>
