import { RestService } from '@abp/ng.core';
import { Injectable } from '@angular/core';
import { ReportService } from '@proxy/appraisal/report/reports/v1';
import { ReportVersionWithLinksDto } from '@proxy/bff/activity/reports/v1';
import { ReportServiceInterface } from 'src/app/interface/appraisal/report/report-service-interface';

@Injectable({
  providedIn: 'root',
})
export class ReportServiceAdapter extends ReportService implements ReportServiceInterface {
  constructor(private restServiceAPI: RestService) {
    super(restServiceAPI);
  }

  getActiveSendReportWithUserRole(
    appraiserGuid: string,
    reportVersionDto: ReportVersionWithLinksDto
  ): boolean {
    const inspectorAppraiser = reportVersionDto.inspectorAppraiser;
    const primaryAppraiser = reportVersionDto.primaryAppraiser;
    const reviewerAppraiser = reportVersionDto.reviewerAppraiser;

    // Permission to submit the report.
    return [inspectorAppraiser.appraiserId, primaryAppraiser.appraiserId, reviewerAppraiser.appraiserId].includes(appraiserGuid);
  }
}
