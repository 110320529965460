import { HttpErrorResponse } from '@angular/common/http';
import { Inject } from '@angular/core';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import {
  ComparableDto,
  ComparablesAggregateDto,
} from '@proxy/appraisal/comparables/comparables/v1/models';
import { ComparablesService } from '@proxy/appraisal/comparables/v1';
import { OrderAssignmentDto } from '@proxy/ascent/order/order-assignment/order-assignments';
import { ReportVersionWithLinksDto } from '@proxy/bff/activity/reports/v1';
import { GeoPolygon } from '@proxy/property/property-job/property-jobs';
import { PropertyJobResponseDto } from '@proxy/property/property-job/property-jobs/v1';
import { PropertyRecordDto } from '@proxy/property/property-record/property-records/v1/models';
import { BehaviorSubject, Subscription, forkJoin, interval, of } from 'rxjs';
import { Observable } from 'rxjs/internal/Observable';
import { PropertyJobErrorCode, RunStatus } from 'src/app/features/market/market-shared/market-constants';
import { ReportsServiceInterface } from 'src/app/interface/bff/activity/reports-service-interface';
import { FeatureNames } from 'src/app/shared/enums/report-input.enum';
import InjectionSymbol from 'src/app/shared/injection/injection-symbol';
import { MenuItemModel } from 'src/app/shared/models/menuItem.model';
import { AddressStateTranslations } from 'src/app/shared/pipes/translate-data/address-state.prototype';
import { ReportInputTranslationModel } from 'src/app/shared/pipes/translate-data/translate-data.prototype';
import { OrderAssignmentServiceInterface } from '../../../interface/ascent/order-assignment-service-interface';
import { PropertyJobServiceInterface } from '../../../interface/property/property-job/property-job-service-interface';
import { PropertyRecordServiceInterface } from '../../../interface/property/property-job/property-record-service-interface';
import {
  PropertySearchModel,
  PropertySearchResponse,
} from '../../../proxy-adapter/property/property-job/models';
import { MessageService } from '../../../shared/services/message.service';
import { MarketCleanupPropertyModalRequest } from './market-cleanup-property-modal/market-cleanup-property-modal-request';
import { MarketCleanupPropertyModalResult } from './market-cleanup-property-modal/market-cleanup-property-modal-result';
import { MarketCleanupPropertyModalComponent } from './market-cleanup-property-modal/market-cleanup-property-modal.component';
import { FacetFilterModel } from './models/facet/facet-filter-model';
import { MarketSectionModel } from './models/market-section-model';

export class Coordinate{
  latitude: number;
  longitude: number;
}

export class MarketBaseComponent{

  subscription : Subscription;
  orderAssignmentId: string;
  geolocation: Coordinate;
  addressLine: string;
  city: string;
  stateShortName: string;
  zip: string;
  reportId: string;
  isLoading: boolean = true;
  isCompsInprogressMessageDisplayed = false;
  areDefaultSearchResultsLoading: boolean = true;
  modalSaveRefresh: boolean = false;
  areFilteredSearchResultsLoading: boolean = false;
  isFacetFilterPanelVisible: boolean = true;
  areDefaultMapMarkersVisible: boolean = true;
  areFilteredMapMarkersVisible: boolean = true;
  facetFilterRecord: Record<string, Partial<FacetFilterModel>> = null;
  selectedFacetFilterRecord: Record<string, Partial<FacetFilterModel>> = null;
  selectedPolygons: GeoPolygon[] = [];
  defaultPropertySearchResponse: PropertySearchResponse;
  localSelectedComparables: [PropertySearchModel, ComparableDto][] = [] as [
    PropertySearchModel,
    ComparableDto
  ][];
  addressStateOptions: ReportInputTranslationModel[] = AddressStateTranslations;
  remoteSelectedComparables: ComparableDto[] = [] as ComparableDto[];
  selectedComparables = new BehaviorSubject<[PropertySearchModel, ComparableDto][]>(null);
  hasSelectedComparableChanges: boolean = false;

  defaultSearchCount: number;
  filteredSearchCount: number;

  defaultSearchResponse = new BehaviorSubject<PropertySearchResponse>(null);
  filteredSearchResponse = new BehaviorSubject<PropertySearchResponse>(null);

  feature = FeatureNames.Market;
  readonly menuItems: MenuItemModel[] = [
    new MenuItemModel('market', 'Subject', false, true),
    new MenuItemModel('market', 'Property Map', false, false),
    new MenuItemModel('market', 'Comparables', false, true)
  ];

  // Using an array here instead of inlining in the template as we may want to allow customisation per product or tenant later
  readonly sections: Record<string, Partial<MarketSectionModel>> = {
    subject: { id: 'subject', title: 'Subject', layoutHint: '' },
    propertyMap: { id: 'propertyMap', title: 'Property Map', layoutHint: '' },
    comparables: { id: 'comparables', title: 'Comparables', layoutHint: '' },
  };

  // TODO: Remove this later - this is not mapped used for some reason needs to be here and mapped for the UX to work ???
  get properties(): Observable<PropertySearchModel[]> {
    return of([] as PropertySearchModel[]);
  }

  public wasComparableAggregateUpdated: boolean;

  constructor(
    @Inject(InjectionSymbol.ActivityReportsService)
    private activityReportsService: ReportsServiceInterface,
    @Inject(InjectionSymbol.ComparableService) private comparablesService: ComparablesService,
    @Inject(InjectionSymbol.OrderAssignmentService) private orderAssignmentService: OrderAssignmentServiceInterface,
    @Inject(InjectionSymbol.PropertyService)
    @Inject(InjectionSymbol.PropertyJobService)
    private propertyJobService: PropertyJobServiceInterface,
    @Inject(InjectionSymbol.PropertyRecordService)
    private propertyRecordService: PropertyRecordServiceInterface,
    private msgService: MessageService,
    public matDialog: MatDialog,
    public reportVersionDto?: ReportVersionWithLinksDto
  ) {}
  protected onInit(): void {
    if (!this.reportVersionDto) {
      this.activityReportsService.getLatest(this.orderAssignmentId).subscribe({
        next: (response: ReportVersionWithLinksDto) => {
          this.reportVersionDto = response;
        },
        error: (err) => {
          this.logActionError(err);
        },
      });
    }
    this.wasComparableAggregateUpdated = false;
  }

  filterSelectionUpdated(selectedFacetFilterRecord: Record<string, Partial<FacetFilterModel>>) {
    this.selectedFacetFilterRecord = selectedFacetFilterRecord;
    this.areFilteredSearchResultsLoading = true;
    this.getPropertyJobSearchData();
  }

  polygonsUpdated(polygons: GeoPolygon[]) {
    this.selectedPolygons = polygons;
    this.areFilteredSearchResultsLoading = true;
    this.getPropertyJobSearchData();
  }

  addLocalComparable(id: string) {
    const matchingProps = this.defaultPropertySearchResponse.properties
      .filter((p) => p.id === id)
      .filter((p) => this.localSelectedComparables.filter((c) => c[0].id === p.id).length === 0);

    if (matchingProps.length === 0) {
      return;
    }

    const matchingRemoteComps = this.remoteSelectedComparables.filter((c) => c.upi === id);
    if (matchingRemoteComps.length > 0) {
      return;
    }

    const matchingLocalComps = this.localSelectedComparables.filter((c) => c[0].id === id);
    if (matchingLocalComps.length > 0) {
      return;
    }

    for (let prop of matchingProps) {
      this.localSelectedComparables.push([prop, null] as [PropertySearchModel, ComparableDto]);
    }

    this.updateSelectedComparables();
    this.hasSelectedComparableChanges = true;
  }

  removeComparable(id: string) {
    const oldCompCount =
      this.localSelectedComparables.length + this.remoteSelectedComparables.length;
    this.localSelectedComparables = this.localSelectedComparables.filter((c) => c[0].id !== id);
    this.remoteSelectedComparables = this.remoteSelectedComparables.filter((c) => c.upi !== id);
    const newCompCount =
      this.localSelectedComparables.length + this.remoteSelectedComparables.length;

    if (oldCompCount !== newCompCount) {
      this.updateSelectedComparables();
      this.hasSelectedComparableChanges = true;
    }
  }

  saveComparables(): void {
    this.areFilteredSearchResultsLoading = true;
    this.comparablesService.getLatest(this.orderAssignmentId).subscribe({
      next: (compAgg: ComparablesAggregateDto) => {
        this.remoteSelectedComparables = compAgg?.selectedComparables || [];
        this.saveSelectedComparables();
      },
      error: () => {
        this.saveSelectedComparables();
        this.areFilteredSearchResultsLoading = false;
      },
    });
  }

  reSelectedComparables(): void {
    this.getSelectedComparableData(this.orderAssignmentId, true);
  }

  editProperty(upi: string) {
    const dialogConfig = new MatDialogConfig();
    dialogConfig.data = {
      orderAssignmentId: this.orderAssignmentId,
      upi: upi,
    } as MarketCleanupPropertyModalRequest;
    dialogConfig.panelClass = 'market-cleanup-property-modal';
    let dialogRef = this.matDialog.open(MarketCleanupPropertyModalComponent, dialogConfig);
    dialogRef.afterClosed().subscribe({
      next: (result: MarketCleanupPropertyModalResult) => {
        // verify the right type of response
        if (result.isOpenFailed) {
          this.showErrorMsg('The cleanup property modal cannot be opened. Please retry later!');
          return;
        }

        if (result.isSaveRequested) {
          this.propertyRecordService.postUpdatedProperty(result.property).subscribe({
            next: (response: PropertyRecordDto) => {
              result.property=response;
              let compsToUpdate = this.remoteSelectedComparables.filter(
                (c) => result.property.id === c.upi
            );


              if (compsToUpdate.length > 0) {
                // Call postSelectedComparables as if the use has unselected the comp and re-selected again and then clicked to save
                // => Remove the compToUpdate from remoteSelectedComparables and instead re-post the property as if newly selected
                const compsToReselect = this.remoteSelectedComparables.filter(
                  (c) => result.property.id !== c.upi
                );

                const savedProps = [[result.property, compsToUpdate[0].index]] as [
                  PropertyRecordDto,
                  number
                ][];

                this.comparablesService
                  .saveSelectedComparables(this.orderAssignmentId, compsToReselect, savedProps)
                  .subscribe({
                    next: (response: ComparablesAggregateDto) => {
                      this.remoteSelectedComparables = response.selectedComparables;
                      this.wasComparableAggregateUpdated = true;
                      this.hasSelectedComparableChanges = false;
                      this.finishedEditProperty();
                    },
                    complete:() => {
                      this.modalWindowAfterSave();
                    },
                    error: (err) => {
                      this.showErrorMsg(
                        `The comparable for the cleaned up property could not be updated.`
                      );
                    },
                  });
              }
            },
            error: (err) => {
              this.showErrorMsg(`Internal error. Please try again later`);
            },
          });
        } else {
          this.finishedEditProperty();
        }

      },
    });
  }

  private modalWindowAfterSave() {
    setTimeout(() => {
      this.modalSaveRefresh = true;
      this.getPropertyJobSearchData();
    }, 500);
  }

  finishedEditProperty() {
    // do nothing
  }

  private saveSelectedComparables(): void {
    const propsToSelect = [] as [PropertyRecordDto, number][];
    const compsToReselect = this.remoteSelectedComparables.filter(
      (c) => this.selectedComparables.value.filter((sc) => sc[0].id === c.upi).length > 0
    );

    const compsToSelect = this.localSelectedComparables.filter(
      (c) => this.remoteSelectedComparables.filter((sc) => sc.upi === c[0].id).length === 0
    );

    const propObservers1 = compsToReselect.map((comp) => this.propertyRecordService.get(comp.upi));
    const propObservers2 = compsToSelect.map((comp) => this.propertyRecordService.get(comp[0].id));
    const propObservers = propObservers1.concat(propObservers2);
    if (propObservers.length === 0) {
      this.postSelectedComparables(compsToReselect, []);
      return;
    }

    forkJoin(propObservers).subscribe((props) => {
      let index = compsToReselect.length;
      for (let prop of props) {
          propsToSelect.push([prop, index++] as [
          PropertyRecordDto,
          number
        ]);
      }
      this.postSelectedComparables([], propsToSelect);
    });
  }

  private postSelectedComparables(
    compsToReselect: ComparableDto[],
    propsToSelect: [PropertyRecordDto, number][]
  ) {
    if (compsToReselect.length === 0) {
      // Required as getSelectedComparableData() will not process any comps
      this.areFilteredSearchResultsLoading = false;
    }

    this.comparablesService
      .saveSelectedComparables(this.orderAssignmentId, compsToReselect, propsToSelect)
      .subscribe({
        next: (response: ComparablesAggregateDto) => {
          this.remoteSelectedComparables = response.selectedComparables;
          this.wasComparableAggregateUpdated = true;
          this.hasSelectedComparableChanges = false;
          this.finishedEditProperty();
          this.comparableAggregatedUpdated();
        },
        error: (e) => {
          this.logActionError(e);
          this.hasSelectedComparableChanges = false;
          this.areFilteredSearchResultsLoading = false;
        },
      });
  }

  private updateSelectedComparables(isReselectedComps?: boolean) {
    // Try again later if the default response hasn't loaded yet.
    if (!this.defaultPropertySearchResponse) {
      setTimeout(() => this.updateSelectedComparables(), 500);
      return;
    }

    const comps: [PropertySearchModel, ComparableDto][] = [];
    for (let comp of this.remoteSelectedComparables) {
      const matchedProps = this.defaultPropertySearchResponse.properties.filter(
        (p) => p.id === comp.upi
      );
      if (matchedProps.length == 0) {
        this.logActionError(`Property record not returned from api for comparable UPI ${comp.upi}`);
        continue;
      }
      comps.push([matchedProps[0], comp]);
    }

    if (!isReselectedComps) {
      // Remove any local comps that are already remote.
      this.localSelectedComparables = this.localSelectedComparables.filter(
        (c) => this.remoteSelectedComparables.filter((sc) => sc.upi === c[0].id).length === 0
      );

      for (let comp of this.localSelectedComparables) {
        comps.push([comp[0], comp[1]]);
      }
    } else {
      this.localSelectedComparables = [];
    }
    this.selectedComparables.next(comps);
    this.isLoading = false;
    this.hasSelectedComparableChanges = false;
    this.areFilteredSearchResultsLoading = false;
  }

  // Calling Property Job Service API
  protected getPropertyJobDataIncludingSearch() {
    this.propertyJobService.get(this.orderAssignmentId).subscribe({
      next: (getJobResponse: PropertyJobResponseDto) => {
        if (getJobResponse?.orderAssignmentId) {
          this.getPropertyJobSearchData();

          // Required as getSelectedComparableData() may not process any comps
          this.isLoading = false;
          setTimeout(() => this.getSelectedComparableData(this.orderAssignmentId), 500);
        } else {
          this.logActionError("Failed to load property search data.");
        }
      },
      error: (err: HttpErrorResponse) => {
        if (err.status === 404) {
          this.logActionError(err);
        }
      },
    });
  }


  private getPropertyJobSearchData() {
    this.subscription = interval(3000).subscribe(()=>{
      this.propertyJobService
      .searchAndMap(this.orderAssignmentId, this.selectedPolygons, this.selectedFacetFilterRecord)
      .subscribe({
        next: (searchResponse: PropertySearchResponse) => {
          if (this.areDefaultSearchResultsLoading || this.modalSaveRefresh) {
            this.defaultPropertySearchResponse = searchResponse;
            this.defaultSearchCount = searchResponse.totalProperties;
            this.defaultSearchResponse.next(searchResponse);
          }

          this.filteredSearchCount = searchResponse.totalProperties;
          this.filteredSearchResponse.next(searchResponse);

          if(this.modalSaveRefresh) {
            this.modalSaveRefresh = false;
            this.getSelectedComparableData(this.orderAssignmentId);
          }

          this.areFilteredSearchResultsLoading = false;
        },
        complete : () =>{

            this.showCompsNotifications();
            if(this.defaultPropertySearchResponse.lastRunStatus == RunStatus.completed)
            {
              this.areDefaultSearchResultsLoading = false;
              this.subscription.unsubscribe();
            }
        },
        error: (error) => {
          this.logActionError(error);
          this.areDefaultSearchResultsLoading = false;
          this.areFilteredSearchResultsLoading = false;
          this.subscription.unsubscribe();
        },
      });

    })
  }

  // Calling OrderAssignment Service API
  protected getOrderAssignmentData(orderAssignmentId: string) {
    this.orderAssignmentService.get(orderAssignmentId).subscribe({
      next: (response: OrderAssignmentDto) => {
        // Street address should just be street
        // e.g. "2023 East Bethany Home Road"
        this.addressLine = response.orderData.address.street;
        this.city = response.orderData.address.city;
        // Cannot use state here as is the long version
        this.zip = response.orderData.address.zip;
        this.geolocation = {
          latitude: response.orderData.address.latitude,
          longitude: response.orderData.address.longitude
        } as Coordinate;
        this.stateShortName = this.addressStateOptions?.find( state => state.name == response.orderData.address.state)?.value;
      },
      error: (error) => {
        this.logActionError(error);
      },
    });
  }

  // Calling Appraisal Comparable DC Service API
  private getSelectedComparableData(orderAssignmentId: string, isReselectedComps?: boolean) {
    this.comparablesService.getLatest(orderAssignmentId).subscribe({
      next: (response: ComparablesAggregateDto) => {
        this.remoteSelectedComparables = response.selectedComparables;
        this.updateSelectedComparables(isReselectedComps);
      },
      error: (error) => {
        this.isLoading = false;
        this.areFilteredSearchResultsLoading = false;
        this.hasSelectedComparableChanges = false;
        this.logActionError(error);
      },
    });
  }

  protected comparableAggregatedUpdated() {
    // Do nothing. Override in sub classes.
  }

  protected logActionError(err: any): void {
    this.isLoading = false;
    this.msgService.error(err).subscribe(() => {});
  }

  protected showErrorMsg(msg: string, title: string = null, isKeepPreviousDialouge = false) {
    const options = {
      hideYesBtn: true,
      cancelText: 'Close',
    };
    this.msgService.error(msg, title, null, options, isKeepPreviousDialouge).subscribe();
  }

  protected showCompsNotifications()
  {
    if(this.defaultPropertySearchResponse.lastRunStatus == RunStatus.new && !this.isCompsInprogressMessageDisplayed)
    {
      this.showSuccessMsg('Finding comparables for the subject property. This may take a few seconds.', 'Searching comparables');
      this.isCompsInprogressMessageDisplayed= true;
    }
    if(!this.defaultPropertySearchResponse.lastRunError && this.defaultPropertySearchResponse.lastRunStatus == RunStatus.completed && this.defaultSearchCount == 0)
    {
      this.showNotificationMsg('There are no comparables found for the subject property.', 'No comparables found');
    }
    if(this.defaultPropertySearchResponse.lastRunError == PropertyJobErrorCode.incorrectAddress && this.defaultPropertySearchResponse.lastRunStatus == RunStatus.completed)
    {
      this.showErrorMsg('Unable to find comparables for the subject property due to incorrect address.', 'Address not found');
    }
    if(this.defaultPropertySearchResponse.lastRunError && this.defaultPropertySearchResponse.lastRunError != PropertyJobErrorCode.incorrectAddress && this.defaultPropertySearchResponse.lastRunStatus == RunStatus.completed)
    {
      this.showErrorMsg('Error occurred while importing data. Try again later.', 'Something went wrong');
    }
  }

  protected showNotificationMsg(msg: string, title: string , isKeepPreviousDialouge = false ) {
    this.msgService.notifyMessage(title, msg, isKeepPreviousDialouge).subscribe();
  }

  protected showSuccessMsg(msg: string, title: string , isKeepPreviousDialouge = false ) {
    this.msgService.successAlert(title, msg, isKeepPreviousDialouge).subscribe();
  }

}
