import {
  ListService,
  LocalizationPipe,
} from '@abp/ng.core';
import { Component, EventEmitter, HostListener, Input, OnInit, Output } from '@angular/core';
import { ComparableDto } from '@proxy/appraisal/comparables/comparables/v1/models';
import { BehaviorSubject } from 'rxjs';
import { PropertySearchModel, PropertySearchResponse } from 'src/app/proxy-adapter/property/property-job/models';

@Component({
  selector: 'jaro-kit-market-comparables-search-grid',
  templateUrl: './market-comparables-search-grid.component.html',
  styleUrls: ['./market-comparables-search-grid.component.scss'],
  providers: [ListService, LocalizationPipe],
})
export class MarketComparablesSearchGridComponent implements OnInit {
  @Input() selectedComparables: BehaviorSubject<[PropertySearchModel, ComparableDto][]>;
  @Input() defaultSearchResponse: BehaviorSubject<PropertySearchResponse>;
  @Input() filteredSearchResponse: BehaviorSubject<PropertySearchResponse>;
  @Input() isLoading: boolean = true;
  @Output() onPropertyEditRequested = new EventEmitter<string>();
  @Output() onComparableAdded = new EventEmitter<string>();
  @Output() onComparableRemoved = new EventEmitter<string>();

  readonly minWidthToFitAllColumns = 1625;
  readonly minAddressColumnWidth = 200;
  footerHeight = 80;
  offset = 0;
  pageSize = 10;
  measuredGridWidth: number = this.minWidthToFitAllColumns;
  defaultSort = [{ prop: 'similarityScore', dir: 'desc' }];
  sortKey: string = "";
  sortOrder: string = "";
  isDefaultClass = true;
  filteredProperties: PropertySearchModel[] = [];
  defaultPropertyCount: number = 0;
  lastRunError: string;
  lastRunStatus: string;
  selectedProperties: PropertySearchModel[] = [];

  @HostListener('window:resize', ['$event'])
  getScreenSize(_event?) {
    this.measuredGridWidth = window.innerWidth;
  }

  get addressColumnWidth(): number
  {
     return this.measuredGridWidth > this.minWidthToFitAllColumns ? this.minAddressColumnWidth + (this.measuredGridWidth - this.minWidthToFitAllColumns) : this.minAddressColumnWidth;
  }

  ngOnInit(): void {
    this.measuredGridWidth = window.innerWidth;

    this.defaultSearchResponse.subscribe((response: PropertySearchResponse) =>
    {
      if(response.properties !== null) {
        this.defaultPropertyCount = response.properties.length;
        this.lastRunError = response.lastRunError;
        this.lastRunStatus = response.lastRunStatus;
      }
    });

    this.filteredSearchResponse.subscribe((response: PropertySearchResponse) =>
    {
      if(response.properties !== null) {
        this.filteredProperties = JSON.parse(JSON.stringify(response.properties)) as PropertySearchModel[];
        this.updatePropertySelection();
      }
    });

    this.selectedComparables.subscribe((comps: [PropertySearchModel, ComparableDto][]) =>
    {
      if(comps !== null) {
        this.selectedProperties = JSON.parse(JSON.stringify(comps.map(c => c[0]))) as PropertySearchModel[];
        this.updatePropertySelection();
      }
    });
  }

  public onPage(event): void {
    this.offset = event.offset;
  }

  public onSort(event): void {
    const sort = event.sorts[0];
    this.sortKey = sort.prop;
    this.sortOrder = sort.dir;
    this.offset = 0;
  }

  getRowCssClasses = (_row: PropertySearchModel): any => {
    const classes = {
    };
    this.isDefaultClass = !this.isDefaultClass;
    return classes;
  };

  editProperty(id: string): void {
    this.onPropertyEditRequested.emit(id);
  }

  addComparable(id: string): void {
    this.onComparableAdded.emit(id);
  }

  removeComparable(id: string): void {
    this.onComparableRemoved.emit(id);
  }

  private updatePropertySelection() {
    const selectedPropIds = [] as string[];
    for(let selectedProp of (this.selectedProperties || []))
    {
      selectedPropIds.push(selectedProp.id);
      const props = (this.filteredProperties || []).filter(p => p.id === selectedProp.id);
      for(let prop of props) {
        prop.isSelected = true;
      }
    }

    for(let prop of (this.filteredProperties || []).filter(p => selectedPropIds.filter(id => id === p.id).length === 0)) {
      prop.isSelected = false;
    }
  }
}
