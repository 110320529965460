import { PartyType } from '@proxy/appraisal/report/reports/party-type.enum';
import { ReportDto } from '@proxy/appraisal/report/v1/form1004/models';
import { get, set } from 'lodash-es';
import { ReportInputDataFormFieldModel } from 'src/app/features/shared/forms/models/report-input-data-field-form-model/report-input-date-field-form-model';
import { ReportInputTemplateField } from 'src/app/features/shared/forms/models/report-input-layout.model';
import { YesNoFormatPipe } from 'src/app/shared/pipes/yes-no-format/yes-no-format.pipe';

export const JaroBoostTemplateConfigSubjectSection: ReportInputDataFormFieldModel[] = [
  {
    formControlName: 'subjectBorrower',
    updatePath: 'valuation.parties.borrower.name',
    mapToFieldFunc: (value) => {
      const data = (value || []).find((item) => item.partyType === PartyType.Borrower);
      return data?.name;
    },
    mapToModelFunc: (value, reportDto: ReportDto, formFieldConfig: ReportInputTemplateField) => {
      const data = get(reportDto, formFieldConfig.path) || [];
      let borrower = data.find((item) => item.partyType === PartyType.Borrower);
      if (borrower) {
        borrower.name = value;
      } else {
        // Not found the borrower
        borrower = {
          name: value,
          partyType: PartyType.Borrower,
        };
        data.push(borrower);
        set(reportDto, formFieldConfig.path, data);
      }
    },
  },
  {
    formControlName: 'subjectAssociation',
    format: {
      pipeType: YesNoFormatPipe,
    }
  },
  {
    formControlName: 'subjectPUD',
    format: {
      pipeType: YesNoFormatPipe,
    },
  },
  {
    formControlName: 'siteIsFemaSpecial',
    format: {
      pipeType: YesNoFormatPipe,
    }
  },
];
