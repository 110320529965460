<ng-container *ngIf="form" [formGroup]="form" >
  <div class="row">
    <div class="col-md-6 pl-0 pr-15">
      <halo-global-neighborhood-characteristics
        [form]="form"
      ></halo-global-neighborhood-characteristics>
      <halo-global-one-unit-housing-trends [form]="form"> </halo-global-one-unit-housing-trends>
    </div>

    <div class="col-md-6 pl-15 pr-0">
      <halo-global-one-unit-housing [form]="form"></halo-global-one-unit-housing>
      <halo-global-present-land-use [form]="form"></halo-global-present-land-use>
      <halo-global-narrative-comments [form]="form"></halo-global-narrative-comments>
    </div>
  </div>
</ng-container>
