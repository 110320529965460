import { UpdateCustomAttributeDto } from "./v1/models";

export class GeoLocationDto {
  latitude: number;
  longitude: number;
}

export class MlsListingStdAddressDto{
  unparsedAddress: string;
  city: string;
  stateOrProvince: string;
  postalCode: string;
}

export class PropertyRecordDataDto {
  conflict: true;
  custom: object;
  mls: object;
  pubrec: object;
  selected: "mls" | "pubrec" | "custom" | "jaroAi" | null;
}

export class UpdateCustomRecordDto {
    condition: UpdateCustomAttributeDto;
    quality: UpdateCustomAttributeDto;
    closePrice: UpdateCustomAttributeDto;
    concessions: UpdateCustomAttributeDto;
    concessionsAmount: UpdateCustomAttributeDto;
    listingContractDate: UpdateCustomAttributeDto;
    expirationDate: UpdateCustomAttributeDto;
    withdrawnDate: UpdateCustomAttributeDto;
    onMarketDate: UpdateCustomAttributeDto;
    listingContractMonthYear: UpdateCustomAttributeDto;
    closeDate: UpdateCustomAttributeDto;
    firePlaceCount: UpdateCustomAttributeDto;
    lotSizeSquareFeet: UpdateCustomAttributeDto;
    livingArea: UpdateCustomAttributeDto;
    bedroomsTotal: UpdateCustomAttributeDto;
    standardStatus: UpdateCustomAttributeDto;
    distressedYn: UpdateCustomAttributeDto;
    distressType: UpdateCustomAttributeDto;
    poolFeatures: UpdateCustomAttributeDto;
    pool: UpdateCustomAttributeDto;
    propertySubType: UpdateCustomAttributeDto;
    publicRemarks: UpdateCustomAttributeDto;
    privateRemarks: UpdateCustomAttributeDto;
    associationYn: UpdateCustomAttributeDto;
    associationName: UpdateCustomAttributeDto;
    associationFee: UpdateCustomAttributeDto;
    elementarySchool: UpdateCustomAttributeDto;
    middleSchool: UpdateCustomAttributeDto;
    highSchool: UpdateCustomAttributeDto;
    schoolDistrict: UpdateCustomAttributeDto;
    postalCode: UpdateCustomAttributeDto;
    listPrice: UpdateCustomAttributeDto;
    yearBuilt: UpdateCustomAttributeDto;
    pricePerSquareFeet: UpdateCustomAttributeDto;
    subDivisionName: UpdateCustomAttributeDto;
    propertyType: UpdateCustomAttributeDto;
    leaseholdFeeSimple: UpdateCustomAttributeDto;
    site: UpdateCustomAttributeDto;
    siteValue: UpdateCustomAttributeDto;
    functionalUtility: UpdateCustomAttributeDto;
    bathroomsTotal: UpdateCustomAttributeDto;
    basement: UpdateCustomAttributeDto;
    basementType: UpdateCustomAttributeDto;
    basementEntry: UpdateCustomAttributeDto;
    location: UpdateCustomAttributeDto;
    locationFactors: UpdateCustomAttributeDto;
    view: UpdateCustomAttributeDto;
    viewFactors: UpdateCustomAttributeDto;
    heating: UpdateCustomAttributeDto;
    cooling: UpdateCustomAttributeDto;
    storiesTotal: UpdateCustomAttributeDto;
    style: UpdateCustomAttributeDto;
    garageSpaces: UpdateCustomAttributeDto;
    attachedGarageSpaces: UpdateCustomAttributeDto;
    detachedGarageSpaces: UpdateCustomAttributeDto;
    builtinGarageSpaces: UpdateCustomAttributeDto;
    openParking: UpdateCustomAttributeDto;
    carportSpaces: UpdateCustomAttributeDto;
    adu: UpdateCustomAttributeDto;
    porchPatioDeck: UpdateCustomAttributeDto;
    projectName: UpdateCustomAttributeDto;
    phase: UpdateCustomAttributeDto;
    commonElementsAndRecFacilities: UpdateCustomAttributeDto;
    unitNumber: UpdateCustomAttributeDto;
    actualAge: UpdateCustomAttributeDto;
    aboveGradeFinishedArea: UpdateCustomAttributeDto;
    belowGradeTotalArea: UpdateCustomAttributeDto;
    belowGradeFinishedArea: UpdateCustomAttributeDto;
    aboveGradeBedrooms: UpdateCustomAttributeDto;
    belowGradeBedrooms: UpdateCustomAttributeDto;
    aboveGradeFullBath: UpdateCustomAttributeDto;
    belowGradeFullBath: UpdateCustomAttributeDto;
    aboveGradePartialBath: UpdateCustomAttributeDto;
    belowGradePartialBath: UpdateCustomAttributeDto;
    aboveGradeRoomCount: UpdateCustomAttributeDto;
    comparableSaleType: UpdateCustomAttributeDto;
    hoaMonthlyAssessment: UpdateCustomAttributeDto;
    attachmentType: UpdateCustomAttributeDto;
    floorLocation: UpdateCustomAttributeDto;
    belowGradeRecRoomCount: UpdateCustomAttributeDto;
    belowOther: UpdateCustomAttributeDto;
    greenEnergyEfficient: UpdateCustomAttributeDto;
    mlsListingNumber: UpdateCustomAttributeDto;
    daysOnMarket: UpdateCustomAttributeDto;
    dataSourceName: UpdateCustomAttributeDto;
    verificationSources: UpdateCustomAttributeDto;
}
