import { Type } from '@angular/core';
import { AdditionalObservationsSection1004Component } from '../../products/1004/additional-observations-section1004/additional-observations-section1004.component';
import { AmenitiesEditModeComponent } from '../../products/1004/additional-observations-section1004/amenities-edit-mode/amenities-edit-mode.component';
import { AppliancesEditModeComponent } from '../../products/1004/additional-observations-section1004/appliances-edit-mode/appliances-edit-mode.component';
import { DamageNotesComponent } from '../../products/1004/additional-observations-section1004/damage-notes/damage-notes.component';
import { AmenitiesViewModeComponent } from '../../products/1004/amenities-view-mode/amenities-view-mode.component';
import { AppraiserComponent } from '../../products/1004/appraiser/appraiser.component';
import { CertificationComponent } from '../../products/1004/appraiser/certification/certification.component';
import { CarStorageSection1004Component } from '../../products/1004/car-storage-section1004/car-storage-section1004.component';
import { CostApproachSection1004Component } from '../../products/1004/cost-approach-section1004/cost-approach-section1004.component';
import { ExteriorSection1004Component } from '../../products/1004/exterior-section1004/exterior-section1004.component';
import { FoundationSection1004Component } from '../../products/1004/foundation-section1004/foundation-section1004.component';
import { AboveGradeAreaComponent } from '../../products/1004/interior-features/above-grade-area/above-grade-area.component';
import { InteriorFeaturesComponent } from '../../products/1004/interior-features/interior-features.component';
import { OccupancyComponent } from '../../products/1004/interior-features/occupancy/occupancy.component';
import { RoomComponent } from '../../products/1004/interior-features/room/room.component';
import { LocationRatingAndFactorsComponent } from '../../products/1004/location-rating-and-factors/location-rating-and-factors.component';
import { AdditionalCommentsViewModeComponent } from '../../products/1004/narrative-comments-section1004/additional-comments-view-mode/additional-comments-view-mode.component';
import { AdditionalCommentsComponent } from '../../products/1004/narrative-comments-section1004/additional-comments/additional-comments.component';
import { NarrativeCommentsSection1004Component } from '../../products/1004/narrative-comments-section1004/narrative-comments-section1004.component';
import { NeighborhoodSection1004Component } from '../../products/1004/neighborhood-section1004/neighborhood-section1004.component';
import { PudInformationComponent } from '../../products/1004/pud-information-section1004/pud-information.component';
import { PUDInformationTotalComponent } from '../../products/1004/pud-information-total/pud-information-total.component';
import { ReconciliationSection1004Component } from '../../products/1004/reconciliation-section1004/reconciliation-section1004.component';
import { HighestAndBestUseComponent } from '../../products/1004/site-section1004/highest-and-best-use/highest-and-best-use.component';
import { ImprovementsTypeComponent } from '../../products/1004/site-section1004/improvements-type/improvements-type.component';
import { UtilitiesComponent } from '../../products/1004/site-section1004/utilities/utilities.component';
import { ViewComponent } from '../../products/1004/site-section1004/view/view.component';
import { SubjectSection1004Component } from '../../products/1004/subject-section1004/subject-section1004/subject-section1004.component';
import { UtilitiesViewModeComponent } from '../../products/1004/utilities-view-mode/utilities-view-mode.component';
import { AppraisalUpdateComponent } from '../../products/1004d/appraisal-update/appraisal-update.component';
import { CertificationCompletionComponent } from '../../products/1004d/certification-completion/certification-completion.component';
import { InspectionDetailsComponent } from '../../products/1004d/inspection-details/inspection-details.component';
import { SubjectSection1004DComponent } from '../../products/1004d/subject-section1004d/subject-section1004d.component';
import { SupervisorCertificationComponent } from '../../products/1004d/supervisor-certification/supervisor-certification.component';
import { UspapComponent } from '../../products/1004d/uspap/uspap.component';
import { AppraisalUpdateFHAHUD92051Component } from '../../products/fha_hud_92051/appraisal-update/appraisal-update.component';
import { InspectionDetailsFHAHUD92051Component } from '../../products/fha_hud_92051/inspection-details/inspection-details.component';
import { SubjectSectionFHAHUD92051Component } from '../../products/fha_hud_92051/subject-section/subject-section.component';
import { SubjectPropertyAddressComponent } from '../components/subject-property-address/subject-property-address.component';

export class ReportInputDataComponentMapping {
  public componentName: string;
  public componentType: Type<any>;
}

export const ReportComponentMapping: ReportInputDataComponentMapping[] = [
  {
    componentName: 'subject1004d',
    componentType: SubjectSection1004DComponent,
  },
  {
    componentName: 'appraisalUpdate',
    componentType: AppraisalUpdateComponent,
  },
  {
    componentName: 'certificationCompletion',
    componentType: CertificationCompletionComponent,
  },
  {
    componentName: 'uspap',
    componentType: UspapComponent,
  },
  {
    componentName: 'subject1004',
    componentType: SubjectSection1004Component,
  },
  {
    componentName: 'neighborhood1004',
    componentType: NeighborhoodSection1004Component,
  },  
  {
    componentName: 'view1004',
    componentType: ViewComponent,
  },
  {
    componentName: 'highestAndBestUse1004',
    componentType: HighestAndBestUseComponent,
  },
  {
    componentName: 'utilities1004',
    componentType: UtilitiesComponent,
  },
  {
    componentName: 'improvementsType1004',
    componentType: ImprovementsTypeComponent,
  },
  {
    componentName: 'foundation1004',
    componentType: FoundationSection1004Component,
  },
  {
    componentName: 'exterior1004',
    componentType: ExteriorSection1004Component,
  },
  {
    componentName: 'carStorage1004',
    componentType: CarStorageSection1004Component,
  },
  {
    componentName: 'occupancy1004',
    componentType: OccupancyComponent,
  },
  {
    componentName: 'aboveGrade1004',
    componentType: AboveGradeAreaComponent,
  }, 
  {
    componentName: 'room1004',
    componentType: RoomComponent,
  },
  {
    componentName: 'additionalObservations1004',
    componentType: AdditionalObservationsSection1004Component,
  },
  {
    componentName: 'amenities1004',
    componentType: AmenitiesEditModeComponent,
  },
  {
    componentName: 'appliances1004',
    componentType: AppliancesEditModeComponent,
  },
  {
    componentName: 'narrativeComments1004',
    componentType: NarrativeCommentsSection1004Component,
  },
  {
    componentName: 'reconciliation1004',
    componentType: ReconciliationSection1004Component,
  },
  {
    componentName: 'supervisorCertification1004D',
    componentType: SupervisorCertificationComponent,
  }, 
  {
    componentName: 'appraiser1004',
    componentType: AppraiserComponent,
  },
  {
    componentName: 'certification1004',
    componentType: CertificationComponent,
  },
  {
    componentName: 'additionalComments',
    componentType: AdditionalCommentsComponent,
  }, 
  {
    componentName: 'costApproach1004',
    componentType: CostApproachSection1004Component,
  },
  {
    componentName: 'PUDInformationTotal',
    componentType: PUDInformationTotalComponent,
  },
  {
    componentName: 'locationRatingFactors1004',
    componentType: LocationRatingAndFactorsComponent,
  },
  {
    componentName: 'damageNotes1004',
    componentType: DamageNotesComponent,
  },
  {
    componentName: 'PUDInformation1004',
    componentType: PudInformationComponent,
  },
  {
    componentName: 'inspectionDetails1004d',
    componentType: InspectionDetailsComponent,
  },
  {
    componentName: 'subjectFHAHUD92051',
    componentType: SubjectSectionFHAHUD92051Component,
  },
  {
    componentName: 'inspectionDetailsFHAHUD92051',
    componentType: InspectionDetailsFHAHUD92051Component,
  },
  {
    componentName: 'appraisalUpdateFHAHUD92051',
    componentType: AppraisalUpdateFHAHUD92051Component,
  },
];

export const ViewModeComponentMapping: ReportInputDataComponentMapping[] = [
  {
    componentName: 'utilities1004',
    componentType: UtilitiesViewModeComponent,
  },
  {
    componentName: 'interior1004',
    componentType: InteriorFeaturesComponent,
  },
  {
    componentName: 'amenities1004',
    componentType: AmenitiesViewModeComponent,
  },
  {
    componentName: 'additionalComments',
    componentType: AdditionalCommentsViewModeComponent,
  },  
  {
    componentName: 'PUDInformationTotal',
    componentType: PUDInformationTotalComponent,
  },
  {
    componentName: 'damageNotes1004',
    componentType: DamageNotesComponent,
  },
  {
    componentName: 'subjectAddress',
    componentType: SubjectPropertyAddressComponent,
  }
];
