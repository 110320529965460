import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { ErrorDto } from '@proxy/property/property-job/property-jobs/v1';
import { cloneDeep,get, isEmpty } from 'lodash-es';
import { ReportWithExtraAttributes } from 'src/app/features/report/shared/models/report-input-extra-atributes.model';


@Component({
  selector: 'jaro-kit-section-panel',
  templateUrl: './section-panel.component.html',
  styleUrls: ['./section-panel.component.scss'],
})
export class SectionPanelComponent implements OnInit {
  @Input() title: string;
  @Input() reportDto: ReportWithExtraAttributes;
  @Input() isEditMode: boolean;
  @Input() isDisabled: boolean;
  @Input() isInvalidData: boolean;
  @Input() hasViewMode: boolean = true;
  @Input() isCustom: boolean;
  @Input() boostEnabled: boolean;
  @Input() sectionErrors: ErrorDto[];
  @Input() reportInputSectionForm: FormGroup;

  @Output() onSaveSection = new EventEmitter<boolean>();
  @Output() onToggleModeSection = new EventEmitter<boolean>();

  errorSection: ErrorDto;

  ngOnInit(): void {
    if (this.reportInputSectionForm) {
      this.getErrors();
      this.isInvalidData = this.isValidForm();
      this.reportInputSectionForm.valueChanges.subscribe((_value) => {
        setTimeout(() => {
          this.isInvalidData = this.isValidForm();
        }, 100);
      });
    }
  }

  getErrors() {
    let errorMessage = '';
    if (this.sectionErrors) {
      this.sectionErrors.forEach((error) => {
        if (get(this.reportDto, error.isError) === true) {
          this.errorSection = {
            isError: true
          } as ErrorDto;

          let resolvedMsg = get(this.reportDto, error.errorMessage) ?? "";
          if(resolvedMsg !== "") 
            errorMessage = (errorMessage == '') ? resolvedMsg : errorMessage + ', ' + resolvedMsg;
        }
      });
      this.errorSection = { ...this.errorSection, errorMessage: errorMessage } as ErrorDto;
    } else {
      this.errorSection = {
        isError: false,
      } as ErrorDto;
    }
  }

  toggleMode() {
    this.isEditMode = !this.isEditMode;
    setTimeout(() => {
      this.onToggleModeSection.emit(true);
    }, 100);
  }

  saveButtonClick() {
    this.onSaveSection.emit(true);
  }

  isValidForm() {
    if (this.reportInputSectionForm.invalid) {
      return Object.keys(this.reportInputSectionForm.controls).some((controlName) => {
        const currentControl = this.reportInputSectionForm.controls[controlName];

        if (currentControl.dirty && currentControl.invalid && currentControl.errors) {
          const errors = cloneDeep(currentControl.errors);
          delete errors['required'];
          return !isEmpty(errors);
        }
        return false;
      });
    }

    return false;
  }
}
