import { Confirmation, ConfirmationService } from '@abp/ng.theme.shared';
import { Component, Inject, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { OrderAssignmentDto } from '@proxy/ascent/order/order-assignment/order-assignments';
import { WorkfileRecordDto } from '@proxy/workfile/workfile/v1';
import { WorkfileServiceInterface } from 'src/app/interface/workfile/workfile-interface.service';
import { FeatureNames } from 'src/app/shared/enums/report-input.enum';
import InjectionSymbol from 'src/app/shared/injection/injection-symbol';
import { EventService } from 'src/app/shared/services/event.service';
import { MessageService } from 'src/app/shared/services/message.service';

@Component({
  selector: 'jaro-kit-workfile',
  templateUrl: './workfile.component.html',
  styleUrls: ['./workfile.component.scss'],
})
export class WorkfileComponent implements OnInit {
  currentOrderAssignment: OrderAssignmentDto;
  orderAssignmentId: string;
  isLoading: boolean = false;
  feature = FeatureNames.Workfile;

  workfiles: WorkfileRecordDto[] = [];

  constructor(
    @Inject(InjectionSymbol.WorkfileService)
    private workfileService: WorkfileServiceInterface,
    @Inject(InjectionSymbol.EventService)
    private eventService: EventService,
    private msgService: MessageService,
    public matDialog: MatDialog,
    public confirmation: ConfirmationService
  ) {
    this.eventService.onSubmitReport.subscribe((reportGenerationRequestId: string) => {
      if(!reportGenerationRequestId)
        return;

      setTimeout(() => {
        this.getAllWorkfiles();
      }, 3000); // Wait 3s for the workfile record to be uploaded async after submission.
    })
  }

  ngOnInit(): void {
    this.getAllWorkfiles();
  }

  getAllWorkfiles() {
    this.isLoading = true;
    this.workfileService.getList(this.currentOrderAssignment.id)
    .subscribe({
      next: (results) => {
        this.workfiles = results
        this.isLoading = false;
      },
      error: (err) => this.logActionError(err)
    });
  }

  private logActionError(err: any): void {
    this.isLoading = false;
    this.msgService.error(err).subscribe((_status: Confirmation.Status) => {});
  }

  deleteWorkfile(workfile: WorkfileRecordDto): void {
    this.isLoading = true;
    this.workfileService.delete(this.currentOrderAssignment.id, workfile.id)
    .subscribe({
      next: (result) => {
        this.getAllWorkfiles();
      },
      error: (err) => this.logActionError(err)
    });
  }

}
