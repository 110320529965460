<ng-container *ngIf="form" [formGroup]="form">
  <mat-form-field color="accent" class="date-wrapper" appearance="outline">
    <mat-label *ngIf="titleDate">{{ titleDate }}</mat-label>
    <input
      matInput
      [matDatepicker]="datePicker"
      [formControl]="dateFormControl"
      (dateChange)="changeDate($event)"
      [id]="id || dateFormControl"
    />
    <mat-datepicker-toggle matSuffix [for]="datePicker"></mat-datepicker-toggle>
    <mat-datepicker #datePicker color="primary"></mat-datepicker>
  </mat-form-field>

  <jaro-kit-validation-message [form]="form" [controlName]="dateControl" [isRequired]="true"></jaro-kit-validation-message>
</ng-container>
