<ng-container *ngIf="form" [formGroup]="form">
  <div class="row report-input-sub-section">
    <div class="col-md-6 ps-0 pe-15">
      <mat-form-field appearance="outline">
        <mat-label>Dimensions</mat-label>
        <input matInput formControlName="siteDimensions" autocomplete="off" />
      </mat-form-field>
    </div>

    <div class="col-md-6 pa-15 pe-0">
      <mat-form-field appearance="outline">
        <mat-label>Area</mat-label>
        <input
          matInput
          id="siteArea"
          formControlName="siteArea"
          autocomplete="off"
          mask="separator.0"
          thousandSeparator=""
          separatorLimit="999999999"
          [allowNegativeNumbers]="false"
        />
      </mat-form-field>
      <jaro-kit-validation-message [form]="form" [controlName]="'siteArea'" [isRequired]="true"></jaro-kit-validation-message>
    </div>
    <div class="col-md-6 ps-0 pe-15">
      <mat-form-field appearance="outline">
        <mat-label>Shape</mat-label>
        <input matInput formControlName="siteShape" autocomplete="off" />
      </mat-form-field>
    </div>
  </div>
</ng-container>
