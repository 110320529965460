import { Component, Input } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { ReportDto } from '@proxy/appraisal/report/v1/form1004';
import { ReportVersionWithLinksDto } from '@proxy/bff/activity/reports/v1';
import {
  ReportInputTemplateSectionModel,
  ReportInputTemplateSubSectionModel,
} from 'src/app/features/shared/forms/models/report-input-layout.model';

@Component({
  selector: 'jaro-kit-sub-section-formbuilder',
  templateUrl: './sub-section-formbuilder.component.html',
  styleUrls: ['./sub-section-formbuilder.component.scss'],
})
export class SubSectionFormBuilderComponent {
  @Input() section: ReportInputTemplateSectionModel;
  @Input() subSection: ReportInputTemplateSubSectionModel;
  @Input() reportDto: ReportDto;
  @Input() timeZoneId: string;
  @Input() reportInputSectionForm: FormGroup;
  @Input() orderAssignmentId: string;
  @Input() feature: string;
  @Input() reportVersionDto: ReportVersionWithLinksDto;
}
