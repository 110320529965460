import { LocalizationPipe } from '@abp/ng.core';
import { CommonModule } from '@angular/common';
import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core';
import { SharedModule } from '../shared/shared.module';
import { LoginFailedComponent } from './login-failed.component';

@NgModule({
  declarations: [LoginFailedComponent],
  providers: [LocalizationPipe],
  imports: [
    CommonModule,
    SharedModule,
  ],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
})
export class LoginFailedModule {}
