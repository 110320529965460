<div
  *ngIf="comparableDto"
  [ngClass]="{
    'comparables-grid-page-header': true,
    'comparables-grid-page-header-subject': comparableDto.isSubject
  }"
  cdkDrag
  [cdkDragData]="comparableDto"
  [id]="comparableDto.upi"
>
  <ng-container *ngIf="form" [formGroup]="form">
    <div class="comparables-grid-top">
      <div class="comparables-grid-top-left">
        <em class="fa fa-th" aria-hidden="true" *ngIf="!comparableDto.isSubject"></em>
      </div>

      <div *ngIf="!comparableDto.isSubject" class="comparables-grid-action">
        <button (click)="hideComparable()">
          <em class="active-button fa-regular fa-eye-slash" aria-hidden="false"></em>
        </button>
        <button [disabled]="true"><em class="fa-regular fa-copy" aria-hidden="true"></em></button>
        <button (click)="deleteComparable()">
          <em class="active-button fa-regular fa-trash-can" aria-hidden="true"></em>
        </button>
      </div>
    </div>

    <div
      [ngClass]="{
        'comparables-grid-detail': true,
        'comparables-grid-detail-view-more': !isViewMore
      }"
    >
      <div class="comparables-grid-information">
        <div class="comparables-title">
          <div *ngIf="comparableDto?.isSubject">
            <p>Subject</p>
            <p></p>
          </div>
          <div *ngIf="!comparableDto?.isSubject">
            <p (click)="editComparable()" style="cursor: pointer">
              Comparable {{ comparableIndex }}
            </p>
            <p class="comparables-proximity-subject">{{ comparableDto.proximityToSubject }}</p>
          </div>
        </div>

        <div class="comparables-grid-photo" *ngIf="photo && !isLoading">
          <img
            [src]="photo"
            (mouseover)="photo = comparableDto.largePhoto"
            (mouseout)="photo = comparableDto.photo"
            (error)="photo = null"
            alt="img"
          />
        </div>
      </div>

      <div class="comparables-grid-address">
        <div class="comparables-grid-address-left">
          <p>{{ comparableDto.street }}</p>
          <p>{{ comparableDto.city }}, {{ comparableDto.state }} {{ comparableDto.zip }}</p>
        </div>

        <div class="comparables-grid-price">
          <span class="sale-price">{{
            comparableDto.price | currency: 'USD':'symbol':'1.0-2' | defaultValue
          }}</span>

          <div class="sale-price-per-squareFeet" *ngIf="salePricePerGrossLivingArea">
            <span class="sale-price-unit"
              >{{
                salePricePerGrossLivingArea | currency: 'USD':'symbol':'1.0-2' | defaultValue
              }}/sqft</span
            >
          </div>
        </div>
      </div>

      <div class="adjusted-price-block" *ngIf="comparableDto.isSubject">
        <span style="flex: 1" (click)="onToggleIndicatedValue()">Indicated Value</span>
        <span
          *ngIf="!isIndicatedValueSelected"
          class="adjusted-price"
          (click)="onToggleIndicatedValue()"
          >{{
            subjectProperty.salesComparisonApproach?.indicatedValueBySalesComparisonApproach
              | currency: 'USD':'symbol':'1.0-0'
          }}</span
        >
        <div *ngIf="isIndicatedValueSelected" class="comparable-grid-cell-subject-edit">
          <mat-form-field appearance="outline">
            <mat-label>Indicated Value</mat-label>
            <input
              matInput
              id="indicatedValueBySalesComparisonApproach"
              formControlName="indicatedValueBySalesComparisonApproach"
              currencyMask
              mask="separator.0"
              thousandSeparator=","
              [allowNegativeNumbers]="false"
              [(ngModel)]="
                subjectProperty.salesComparisonApproach.indicatedValueBySalesComparisonApproach
              "
              autocomplete="off"
              (change)="onChangeIndicatedValue()"
              [required]="true"
            />
          </mat-form-field>
        </div>
      </div>
      <div
        class="adjusted-price-block-reverse"
        *ngIf="comparableDto.isSubject && isIndicatedValueSelected"
      >
        <jaro-kit-validation-message
          [form]="form"
          [controlName]="'indicatedValueBySalesComparisonApproach'"
          [isRequired]="true"
        ></jaro-kit-validation-message>
      </div>
      <div class="adjusted-price-block" *ngIf="!comparableDto.isSubject">
        <span style="flex: 1">Adjusted Price</span>
        <span class="adjusted-price">{{
          comparableDto.adjustedSalePrice | currency: 'USD':'symbol':'1.0-0'
        }}</span>
      </div>

      <div class="view-more" *ngIf="isViewMore" (click)="onToggle()">
        <p>View More</p>
        <button><em class="fa fa-angle-down" aria-hidden="true"></em></button>
      </div>

      <div
        [ngClass]="{
          'comparables-grid-view-more': true,
          'comparables-grid-view-more-of-subject': comparableDto.isSubject
        }"
        *ngIf="!isViewMore"
      >
      <div class="comparables-grid-line" *ngIf="comparableDto.isSubject"> 
        <div class="comparables-grid-line-item">
          <p class="comparables-grid-label"></p>
          <p class="comparables-grid-value"></p>           
        </div>       
      </div>
      
      <div class="comparables-grid-line" *ngIf="comparableDto.isSubject">
        <div class="comparables-grid-line-item">
          <p class="comparables-grid-label"></p>
          <p class="comparables-grid-value"></p>           
        </div>        
      </div>

        <div class="comparables-grid-line" *ngIf="!comparableDto.isSubject">
          <div class="comparables-grid-line-item net-adjustment-total">
            <p class="comparables-grid-label">Net Adjustment Total</p>
            <p
              [ngClass]="{
                'comparables-grid-value': true,
                'comparable-grid-cell-adjustment-positive-value':
                  comparableDto.netAdjustmentTotal > 0,
                'comparable-grid-cell-adjustment-negative-value':
                  comparableDto.netAdjustmentTotal < 0
              }"
            >
              {{
                comparableDto.netAdjustmentTotal | currency: 'USD':'symbol':'1.0-0' | defaultValue
              }}
            </p>
          </div>

          <div class="comparables-grid-line-item">
            <p class="comparables-grid-label">Net Adj</p>
            <p class="comparables-grid-value">
              {{ comparableDto.netAdjPercent | number: '1.1-1' | defaultValue }}%
            </p>
          </div>

          <div class="comparables-grid-line-item">
            <p class="comparables-grid-label">Gross Adj</p>
            <p class="comparables-grid-value">
              {{ comparableDto.grossAdjPercent | number: '1.1-1' | defaultValue }}%
            </p>
          </div>
        </div>

        
        <div class="comparables-grid-line" *ngIf="!comparableDto.isSubject">
          <div class="comparables-grid-line-item">
            <p class="comparables-grid-label">Data Source(s)</p>
            <p
              class="comparables-grid-value"
              *ngIf="comparableDto.dataSources && comparableDto.dataSources?.length > 0"
            >
              <ng-container *ngFor="let source of comparableDto.dataSources; let last = last">
                {{
                  source +
                    (last
                      ? comparableDto.daysOnMarket
                        ? ' | DOM ' + comparableDto.daysOnMarket
                        : ''
                      : comparableDto.daysOnMarket
                      ? ' | DOM ' + comparableDto.daysOnMarket
                      : '' + ', ')
                }}
              </ng-container>
            </p>
            <p
              class="comparables-grid-value"
              *ngIf="!comparableDto.dataSources || comparableDto.dataSources?.length === 0"
            >
              {{ comparableDto.daysOnMarket ? 'DOM ' + comparableDto.daysOnMarket : '-' }}
            </p>
          </div>

          <div class="comparables-grid-line-item">
            <p class="comparables-grid-label">Verification Source(s)</p>
            <p class="comparables-grid-value">
              {{
                comparableDto.verificationSources | arrayFormat
              }}
            </p>
          </div>
        </div>        

        <div class="comparables-grid-line">
          <div class="comparables-grid-line-item">
            <p class="comparables-grid-label">Date of Prior Sale/Transfer</p>
            <p class="comparables-grid-value">
              {{
                comparableDto.saleHistory?.transactionContractDate | dateTimeFormat | defaultValue
              }}
            </p>
          </div>

          <div class="comparables-grid-line-item">
            <p class="comparables-grid-label">Price of Prior Sale/Transfer</p>
            <p class="comparables-grid-value">
              {{
                comparableDto.saleHistory?.closePrice
                  | currency: 'USD':'symbol':'1.0-0'
                  | defaultValue
              }}
            </p>
          </div>
        </div>

        <div class="comparables-grid-line">
          <div class="comparables-grid-line-item" *ngIf="!comparableDto.isSubject">
            <p class="comparables-grid-label">Data Source(s) of Prior Sale</p>
            <p class="comparables-grid-value" *ngIf="comparableDto.saleHistory?.source">
              {{
                comparableDto.saleHistory?.source  
              }}
            </p> 
            <p
            class="comparables-grid-value"
            *ngIf="!comparableDto.saleHistory?.source">
            MLS, Assessor
          </p> 
          </div>

          <div class="comparables-grid-line-item" *ngIf="comparableDto.isSubject">
            <p class="comparables-grid-label">Data Source(s)</p>
            <p
              class="comparables-grid-value"
              *ngIf="comparableDto.dataSources && comparableDto.dataSources?.length > 0"
            >
             {{comparableDto.dataSources | arrayFormat}}
            </p>
            <p
              class="comparables-grid-value"
              *ngIf="!comparableDto.dataSources || comparableDto.dataSources?.length === 0"
            >
              {{ comparableDto.daysOnMarket ? 'DOM ' + comparableDto.daysOnMarket : '-' }}
            </p>
           
          </div>


          <div class="comparables-grid-line-item">
            <p class="comparables-grid-label">Effective Date of Data Source(s)</p>
            <p class="comparables-grid-value">
              {{
                comparableDto.saleHistory?.transactionRecordingDate | dateTimeFormat | defaultValue
              }}
            </p>
          </div>
        </div>            

        <div class="view-less" (click)="onToggle()">
          <p>View Less</p>
          <button><em class="fa fa-angle-up" aria-hidden="true"></em></button>
        </div>
      </div>
    </div>
  </ng-container>
</div>
