<div
    [ngClass]="{
      'loading-panel': true
    }"
  >
  <mat-spinner
    *ngIf="isLoading || isPrepareConfigurationTemplateData || isPrepareReportData"
    value="100"
    diameter="100"
    strokeWidth="5"
    class="action-loading-spinner"
  ></mat-spinner>

  <mat-sidenav-container
    autosize
    class="report-input-details-container"
    id="report-input-details-container"
    *ngIf="currentOrderAssignment && reportVersionDto">

    <jaro-kit-report-input-header
        #jaroKitHeader
        [addressDto]="currentOrderAssignment.orderData.address"
        [orderAssignmentId]="orderAssignmentId"
        [productName]="currentOrderAssignment.orderData.mainForm"
        [mainPicture]="mainPicture"
        [orderData]="currentOrderAssignment?.orderData"
        [reportVersionDto]="reportVersionDto"
        [isActiveQualityCheck]="isActiveQualityCheck"
        [isActivePreviewReport]="isActivePreviewReport"
        [showBoostDataViewButton] = "showBoostDataViewButton"
        (onToggleQualityCheck)="toggleQualityCheck($event)"
        *ngIf="currentOrderAssignment && reportVersionDto"
      >
    </jaro-kit-report-input-header>

    <mat-sidenav
      #sidenav
      fixedInViewport="true"
      mode="side"
      opened="{{ isExpanded }}"
      *ngIf="menuItems"
    >


      <jaro-kit-report-input-sidebar
        *ngIf="!isPrepareConfigurationTemplateData && !isPrepareReportData && menuItems && featureRouteSegment"
        (toggleMenu)="toggleMenu($event)"
        [isExpanded]="isExpanded"
        [menuItems]="menuItems"
        [featureRouteSegment]="featureRouteSegment"
        (onSelectMenuItem)="onClickMenuLeft($event)"
        [menuUpdateModel]="menuUpdateModel"
      ></jaro-kit-report-input-sidebar>
    </mat-sidenav>

    <mat-sidenav-content [style.margin-left.px]="!isExpanded ? 56 : 256" *ngIf="!isPrepareConfigurationTemplateData && !isPrepareReportData">
      <div
        [ngClass]="{
          'report-input-main-panel': true,
          'report-input-main-panel-expanded': isExpanded,
          'report-input-main-panel-with-quality-check': showQualityCheck && isActiveQualityCheck
        }"
      >
        <div class="feature-heading-panel" *ngIf="reportTemplate?.title">
          <div class="feature-heading-panel-label">{{ reportTemplate?.title }}
            <span class="feature-heading-panel-notification" *ngIf="displayDataStatus()">
              <i class="fa-regular fa-circle-info"></i>  {{ notificationMessage }}
            </span>
          </div>
        </div>
        <div class="report-input-detail-page" id="report-input-detail-page">
          <ng-container class="report-input-main-page">
            <router-outlet *ngIf="!isLoading && !isPrepareConfigurationTemplateData && !isPrepareReportData && !isLoadingPropertyData" (activate)="loadAndPrepareData($event)"></router-outlet>
          </ng-container>

          <div
            class="report-input-quality-check-list"
            *ngIf="showQualityCheck && isActiveQualityCheck"
          >
            <jaro-kit-quality-check-list
              [orderAssignmentId]="orderAssignmentId"
              [productName]="currentOrderAssignment.orderData.mainForm"
              [reportVersionDto]="reportVersionDto"
              [featureRouteSegment]="featureRouteSegment"
              (onToggleQualityCheck)="toggleQualityCheck($event)"
              (onRefreshQualityCheck)="refreshQualityCheck()"
              (onGoToPage)="onClickMenuLeft($event)"
            ></jaro-kit-quality-check-list>
          </div>
        </div>
      </div>
    </mat-sidenav-content>
  </mat-sidenav-container>
</div>
