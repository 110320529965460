import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from '@angular/material/dialog';
import { ComparablesService } from '@proxy/appraisal/comparables/v1';
import { OrderAssignmentServiceInterface } from 'src/app/interface/ascent/order-assignment-service-interface';
import { ReportsServiceInterface } from 'src/app/interface/bff/activity/reports-service-interface';
import InjectionSymbol from 'src/app/shared/injection/injection-symbol';
import { MessageService } from 'src/app/shared/services/message.service';
import { PropertyJobServiceInterface } from '../../../interface/property/property-job/property-job-service-interface';
import { PropertyRecordServiceInterface } from '../../../interface/property/property-job/property-record-service-interface';
import { MarketModalRequest } from '../market-modal/market-modal-request';
import { MarketModalResult } from '../market-modal/market-modal-result';
import { MarketBaseComponent } from './market-base.component';

@Component({
  selector: 'jaro-kit-market-modal',
  templateUrl: './market-modal.component.html',
  styleUrls: ['./market-modal.component.scss'],
})
export class MarketModalComponent extends MarketBaseComponent implements OnInit {
  constructor(
    public dialogRef: MatDialogRef<MarketModalComponent, MarketModalResult>,
    @Inject(MAT_DIALOG_DATA)
    public data: MarketModalRequest,
    @Inject(InjectionSymbol.ActivityReportsService) activityReportService: ReportsServiceInterface,
    @Inject(InjectionSymbol.ComparableService) comparablesService: ComparablesService,
    @Inject(InjectionSymbol.OrderAssignmentService) orderAssignmentService: OrderAssignmentServiceInterface,
    @Inject(InjectionSymbol.PropertyJobService) propertyJobService: PropertyJobServiceInterface,
    @Inject(InjectionSymbol.PropertyRecordService)
    propertyRecordService: PropertyRecordServiceInterface,
    msgService: MessageService,
    matDialog: MatDialog
  ) {
    super(
      activityReportService,
      comparablesService,
      orderAssignmentService,
      propertyJobService,
      propertyRecordService,
      msgService,
      matDialog,
      data.reportVersionDto
    );
    this.orderAssignmentId = this.data.orderAssignmentId;
    this.reportVersionDto = this.data.reportVersionDto;
  }

  ngOnInit(): void {
    super.onInit();
    this.areDefaultSearchResultsLoading = true;
    this.isLoading = true;
    this.getOrderAssignmentData(this.orderAssignmentId);
    this.getPropertyJobDataIncludingSearch();
  }

  saveComparables(): void {
    this.dialogRef.disableClose = true;
    super.saveComparables();
  }

  editProperty(upi: string) {
    this.dialogRef.disableClose = true;
    super.editProperty(upi);
  }

  finishedEditProperty() {
    this.dialogRef.disableClose = false;
  }

  protected comparableAggregatedUpdated() {
    const result = {
      isOpenFailed: false,
      wasComparableAggregateUpdated: true,
    } as MarketModalResult;
    this.dialogRef.close(result);
  }

  protected logActionError(err: any): void {
    this.dialogRef.disableClose = false;
    super.logActionError(err);
  }

  protected showErrorMsg(msg: string) {
    this.dialogRef.disableClose = false;
    super.showErrorMsg(msg);
  }
}
