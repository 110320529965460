import { RestService } from '@abp/ng.core';
import { Injectable } from '@angular/core';
import { ContentDto, ContentListDto } from '@proxy/content/content/v1';
import * as moment from 'moment';
import { Moment } from 'moment-timezone';
import { Observable, map, of } from 'rxjs';
import { ContentServiceInterface } from 'src/app/interface/content/content-service-interface';
import { ItemData } from 'src/app/shared/components/multiple-combo-box/multiple-combo-box.component';

@Injectable()
export class ContentService implements ContentServiceInterface {
  apiName = 'Content';
  contentLists: ContentListDto[];
  lastTimeToGetContentList: Moment;

  public init(contentScope: string, contentContext: Record<string, string>): Observable<boolean> {
    if (
      this.lastTimeToGetContentList &&
      this.lastTimeToGetContentList.diff(moment.utc(), 'minutes') < 15
    ) {
      return of(true);
    }
    const url = `/api/v1/content/search?scope=${contentScope}`; // e.g. "/content/api/v1/content?context[product.name]=1004"

    return this.getRemoteContent(url, contentContext).pipe(
      map((res: any) => {
        this.contentLists = res.lists;
        this.lastTimeToGetContentList = moment.utc();
        return true;
      })
    );
  }

  private getRemoteContent = (url: string, contentContext: Record<string, string>): Observable<ContentDto> =>
    this.restService.request(
      {
        method: 'POST',
        url: url,
        body: contentContext
      },
      { apiName: this.apiName }
    );

  getContentByKey(key: string, hasOtherOption?: boolean, hasNone?: boolean): ItemData[] {
    let options: ItemData[] = [];
    if (this.contentLists) {
      const contentItem = this.contentLists.find((item) => item.name === key);
      if (contentItem?.items) {
        options = contentItem.items.map((item) => {
          return {
            name: item.value,
            value: item.value.toString(),
            selected: false,
          };
        });
      }
    }
    if (hasOtherOption) {
      const otherOption = {
        name: 'Other',
        value: 'other',
        selected: false,
      };
      options.push(otherOption);
    }

    if (hasNone) {
      const noneOption = {
        name: 'None',
        value: 'none',
        selected: false,
      };
      options.unshift(noneOption);
    }
    return options;
  }

  constructor(private restService: RestService) {}
}
