import { Injectable } from '@angular/core';
import { WorkfileService } from '@proxy/workfile/workfile/v1';
import { WorkfileServiceInterface } from 'src/app/interface/workfile/workfile-interface.service';

@Injectable({
  providedIn: 'root',
})
export class WorkfileServiceAdapter
  extends WorkfileService
  implements WorkfileServiceInterface {}
