import { Component, EventEmitter, Inject, Input, Output } from '@angular/core';
import { ReportInputPhotoSectionDto } from '@proxy/bff/activity/report-input-photos/v1';
import { ReportInputSectionModel } from 'src/app/features/report/shared/models/report-input-section.model';
import { ReportServiceInterface } from 'src/app/interface/appraisal/report/report-service-interface';
import { ReportPhotoResourceModel } from 'src/app/shared/dtos/report-input-resource/report-photo-resource.model';
import InjectionSymbol from 'src/app/shared/injection/injection-symbol';
import { EditSectionModel } from 'src/app/shared/models/photo/edit-section.model';
import { PartialUpdatePhotoModel } from 'src/app/shared/models/photo/partial-update-photo.model';
import { SectionModel } from 'src/app/shared/models/photo/section.model';
import { FileUploading } from 'src/app/shared/services/file-uploading';
import { ReportInputPhotoResourceService } from 'src/app/shared/services/photo-resource.service';

@Component({
  selector: 'jaro-kit-photo-sections',
  templateUrl: './photo-sections.component.html',
  styleUrls: ['./photo-sections.component.scss'],
})
export class PhotoSectionsComponent {
  @Input() photoSections: EditSectionModel[];
  @Input() galleryPhotos: ReportPhotoResourceModel[];
  @Input() orderAssignmentId: string;
  @Input() reportId: string;
  @Input() reportVersion: number;
  @Input() sections: ReportInputSectionModel[];
  @Input() maximumOfFileToUpload: number;

  @Output() onUpdatePhotoSections = new EventEmitter<ReportInputPhotoSectionDto[]>();
  @Output() onAddPhotosToSection = new EventEmitter<ReportPhotoResourceModel[]>();
  @Output() onRemovePhotoFromSection = new EventEmitter<ReportPhotoResourceModel>();
  @Output() onMakeCoverPhoto = new EventEmitter<ReportPhotoResourceModel>();
  @Output() onMovePhoto = new EventEmitter<ReportPhotoResourceModel>();
  @Output() onUpdatePhotoTitle = new EventEmitter<ReportPhotoResourceModel>();
  @Output() onUpdateSection = new EventEmitter<EditSectionModel>();
  @Output() onDeleteSection = new EventEmitter<EditSectionModel>();

  sectionUploadPhotos: Map<string, FileUploading[]> = new Map();

  get sectionOptions(): SectionModel[] {
    return this.photoSections.map((section) => {
      return {
        id: section.id,
        name: section.name,
      };
    });
  }

  constructor(
    public reportInputService: ReportInputPhotoResourceService,
    @Inject(InjectionSymbol.ReportService) public reportService: ReportServiceInterface
  ) {}

  makeCoverPhoto(photo) {
    this.onMakeCoverPhoto.emit(photo);
  }

  updateSectionPhotos(sections: ReportInputPhotoSectionDto[]) {
    this.onUpdatePhotoSections.emit(sections);
  }

  uploadPhotosToSection(photos: PartialUpdatePhotoModel[], sectionId: string) {
    const section = this.photoSections.find((item) => item.id === sectionId);
    photos = photos.filter(
      (photo) => !section.photos.some((item) => item.documentId === photo.documentId)
    );
    section.photos = [...section.photos, ...photos];
    this.addPhotosSection(photos, sectionId);
  }

  addPhotosSection(photos: PartialUpdatePhotoModel[], sectionId: string) {
    photos.forEach((photo) => {
      photo.currentSectionId = sectionId;
      this.reportInputService.mapPhotoDtoToUI(photo);
      photo.isInReport = true;
      if (!photo.sectionIds) {
        photo.sectionIds = [sectionId];
      } else if (!photo.sectionIds.includes(sectionId)) {
        photo.sectionIds.push(sectionId);
      }
    });
    this.onAddPhotosToSection.emit(photos);
  }

  uploadPhotos(photosUploading: FileUploading[], sectionId: string) {
    this.sectionUploadPhotos.set(sectionId, photosUploading);
  }

  getSectionPhotoUpload(sectionId: string) {
    return this.sectionUploadPhotos.get(sectionId) || [];
  }

  removePhotoFromSection(photo: PartialUpdatePhotoModel, sectionId: string) {
    photo.currentSectionId = sectionId;
    this.onRemovePhotoFromSection.emit(photo);
  }

  movePhoto(photo: PartialUpdatePhotoModel, sectionId: string) {
    photo.newSectionId = sectionId;
    this.onMovePhoto.emit(photo);
  }

  updatePhotoTitle(photo: ReportPhotoResourceModel) {
    this.onUpdatePhotoTitle.emit(photo);
  }

  editSectionNameClick(section: EditSectionModel) {
    section.isEditSection = true;
  }

  editSection(section: EditSectionModel) {
    this.onUpdateSection.emit(section);
  }

  cancelEditSection(section: EditSectionModel) {
    section.isEditSection = false;
  }

  deleteSection(section: EditSectionModel) {
    this.onDeleteSection.emit(section);
  }
}
