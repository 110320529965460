import { Component, EventEmitter, Input, Output } from '@angular/core';
import { ReportPhotoOwnerType } from '@proxy/appraisal/report/report-photos';
import { ReportInputPhotoSectionDto } from '@proxy/bff/activity/report-input-photos/v1';
import { ReportPhotoResourceModel } from 'src/app/shared/dtos/report-input-resource/report-photo-resource.model';
import { SectionModel } from 'src/app/shared/models/photo/section.model';

@Component({
  selector: 'jaro-kit-report-photo',
  templateUrl: './report-photo.component.html',
  styleUrls: ['./report-photo.component.scss'],
})
export class ReportPhotoComponent {
  @Input() orderAssignmentId: string;
  @Input() reportId: string;
  @Input() reportVersion: number;
  @Input() photo: ReportPhotoResourceModel;
  @Input() photos: ReportPhotoResourceModel[] = [];
  @Input() galleryPhotos: ReportPhotoResourceModel[] = [];
  @Input() photoSections: ReportInputPhotoSectionDto[];

  @Output() onUpdatePhotoSections = new EventEmitter<ReportInputPhotoSectionDto[]>();

  photoOwnerType = ReportPhotoOwnerType;

  get sectionOptions(): SectionModel[] {
    return this.photoSections.map((section) => {
      return {
        id: section.id,
        name: section.name,
      };
    });
  }

  updateSectionPhotos(sections: ReportInputPhotoSectionDto[]) {
    this.onUpdatePhotoSections.emit(sections);
  }
}
