<div class="report-input-gallery-container">
  <div>
    <div
      class="photos"
      *ngIf="itemsFiltered?.length > 0"
      infinite-scroll
      [infiniteScrollDistance]="scrollDistance"
      [infiniteScrollUpDistance]="scrollUpDistance"
      [infiniteScrollThrottle]="throttle"
      [scrollWindow]="false"
      [infiniteScrollContainer]="'.report-input-gallery-container'"
      [fromRoot]="true"
      (scrolled)="onScrollDown()"
    >
      <div
        cdkDropList
        #photoList="cdkDropList"
        [cdkDropListData]="itemsFiltered"
        class="photo-list"
        [cdkDropListAutoScrollDisabled]="true"
      >
        <div
          *ngFor="let item of itemsLoaded"
          cdkDrag
          [cdkDragData]="item"
          [cdkDragBoundary]="reportResourceType"
          cdkDragPreviewClass="dragPreviewPhoto"
          [ngClass]="{
            'photo-container': reportResourceType === type.Photo
          }"
        >
          <jaro-kit-report-photo
            *ngIf="reportResourceType === type.Photo"
            [photo]="item"
            [photos]="itemsFiltered"
            [galleryPhotos]="items"
            [orderAssignmentId]="orderAssignmentId"
            [reportId]="reportId"
            [reportVersion]="reportVersion"
            [isOpenByReportPhoto]="false"
            [photoSections]="photoSections"
            (onUpdatePhotoSections)="updateSectionPhotos($event)"
          >
          </jaro-kit-report-photo>
        </div>
      </div>
    </div>

    <div *ngIf="!itemsFiltered || itemsFiltered.length === 0" class="empty-container">
      <span class="empty-photos">No Photo from the {{ source }}</span>
    </div>
  </div>
</div>
