import { Component, Inject, Input, OnInit } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { SubjectSaleDto } from '@proxy/appraisal/report/reports/v1/form1004';
import { ColumnMode } from '@swimlane/ngx-datatable';
import { cloneDeep, isEmpty } from 'lodash-es';
import { ReportWithExtraAttributes } from 'src/app/features/report/shared/models/report-input-extra-atributes.model';
import { ReportInputTemplateService } from 'src/app/features/shared/forms/providers/report-input-template.service';
import InjectionSymbol from 'src/app/shared/injection/injection-symbol';
import { EventService } from 'src/app/shared/services/event.service';
@Component({
  selector: 'jaro-kit-sale-history-edit-mode',
  templateUrl: './sale-history-edit-mode.component.html',
  styleUrls: ['./sale-history-edit-mode.component.scss'],
})
export class SaleHistoryEditModeComponent implements OnInit {
  @Input() form: FormGroup;
  @Input() reportDto: ReportWithExtraAttributes;

  saleHistory: SubjectSaleDto[];
  ColumnMode = ColumnMode;
  latestTransactionContractDate: string;

  constructor(
    @Inject(InjectionSymbol.ReportInputTemplateService)
    private reportInputTemplateService: ReportInputTemplateService,
    @Inject(InjectionSymbol.EventService)
    private eventService: EventService
  ) {}

  ngOnInit(): void {
    this.initValue();
    this.reportInputTemplateService.onSave.subscribe((eventsOnSave) => {
      if (eventsOnSave?.includes('saleHistory')) {
        this.updateSaleHistory();
      }
    });

    this.eventService.onGoToReportField.subscribe((field) => {
      if (field != null) {
        if (field.sectionId === 'section-property-history') {
          setTimeout(() => {
            const elementRef = document.getElementById(field.fieldId+this.latestTransactionContractDate);
            if (elementRef) {
              // Show error message
              elementRef.scrollIntoView({ behavior: 'smooth', block: 'center' });
              elementRef.focus();
              elementRef.blur();
              elementRef.focus();
            }
          }, 500);
        }
      }
    });
  }

  private initValue() {
    this.saleHistory = cloneDeep(this.form?.value['saleHistory'] || []);
    this.saleHistory.forEach((item) => {
      item.transactionContractDate = this.reportInputTemplateService.getDateValue(
        item.transactionContractDate
      );
      item.transactionRecordingDate = this.reportInputTemplateService.getDateValue(
        item.transactionRecordingDate
      );
    });
    this.latestTransactionContractDate = [...this.saleHistory].filter(e => e.transactionContractDate).map(e => e.transactionContractDate).sort((a, b) => b.localeCompare(a))[0];
  }

  addRow(rowIndex?: number) {
    rowIndex = typeof rowIndex == 'number' ? rowIndex : this.saleHistory.length;
    const newSaleHistory = {
      transactionContractDate: null,
      transactionRecordingDate: null,
      transactionDocumentType: null,
      transactionRecordingDocumentId: null,
      seller1FullName: null,
      seller2FullName: null,
      buyer1FullName: null,
      buyer2FullName: null,
      closePrice: null,
      source: null,
    } as SubjectSaleDto;
    this.saleHistory.splice(rowIndex + 1, 0, newSaleHistory);
    this.saleHistory = [...this.saleHistory];
    this.updateSaleHistory();
  }

  removeRow(rowIndex: number) {
    this.saleHistory.splice(rowIndex, 1);
    this.saleHistory = [...this.saleHistory];
    this.updateSaleHistory();
    this.latestTransactionContractDate = [...this.saleHistory].filter(e => e.transactionContractDate).map(e => e.transactionContractDate).sort((a, b) => b.localeCompare(a))[0];
  }

  changeNewDate(date, rowIndex, path) {
    const saleHistory = this.saleHistory[rowIndex];
    saleHistory[path] = this.reportInputTemplateService.getDateValue(date);
    this.latestTransactionContractDate = [...this.saleHistory].filter(e => e.transactionContractDate).map(e => e.transactionContractDate).sort((a, b) => b.localeCompare(a))[0];
  }

  updateSaleHistory() {
    const saleHistoryControl = this.form.controls['saleHistory'];
    if (saleHistoryControl) {
      const saleHistory = (this.saleHistory || []).filter((item) =>
        Object.keys(item).some((key) => !isEmpty(item[key]))
      );
      saleHistoryControl.setValue(saleHistory);
      saleHistoryControl.markAsDirty();
      this.form.updateValueAndValidity();
    }
  }

  isRequired(saleHistory: SubjectSaleDto){
    return saleHistory?.transactionContractDate === this.latestTransactionContractDate;
  }
}
