import { Confirmation } from '@abp/ng.theme.shared';
import { Component, Inject, Input, OnInit } from '@angular/core';
import { ReportVersionDto } from '@proxy/appraisal/report/reports/v1';
import { SalesComparisonApproachDto } from '@proxy/appraisal/report/reports/v1/form1004';
import { PartialUpdateReportDto } from '@proxy/appraisal/report/v1';
import { ReportVersionWithLinksDto } from '@proxy/bff/activity/reports/v1';
import { cloneDeep, get, set } from 'lodash-es';
import { ReportsServiceInterface } from 'src/app/interface/bff/activity/reports-service-interface';
import InjectionSymbol from 'src/app/shared/injection/injection-symbol';
import { MessageService } from 'src/app/shared/services/message.service';
@Component({
  selector: 'jaro-kit-comparable-totals',
  templateUrl: './comparable-totals.component.html',
  styleUrls: ['./comparable-totals.component.scss'],
})
export class ComparableTotalsComponent implements OnInit {
  @Input() reportVersionDto: ReportVersionWithLinksDto;
  salesComparisonApproach: SalesComparisonApproachDto;

  isEditMode: boolean;
  isLoading: boolean;
  isValidData: boolean = true;

  constructor(
    @Inject(InjectionSymbol.ActivityReportsService)
    public activityReportsService: ReportsServiceInterface,
    private msgService: MessageService
  ) {}

  ngOnInit(): void {
    this.salesComparisonApproach =
    cloneDeep(get(this.reportVersionDto, 'report.property.salesComparisonApproach') || {});
  }

  toggleMode() {
    this.salesComparisonApproach =
    cloneDeep(get(this.reportVersionDto, 'report.property.salesComparisonApproach') || {});
    this.isEditMode = !this.isEditMode;
  }

  saveSection() {
    if (this.isEditMode) {
      const updates = {
        'property.salesComparisonApproach.overrideTotals':
          this.salesComparisonApproach.overrideTotals,
        'property.salesComparisonApproach.numberOfComparableListings':
          this.salesComparisonApproach.numberOfComparableListings,
        'property.salesComparisonApproach.comparablesListingPriceRangeLow':
          this.salesComparisonApproach.comparablesListingPriceRangeLow,
        'property.salesComparisonApproach.comparablesListingPriceRangeHigh':
          this.salesComparisonApproach.comparablesListingPriceRangeHigh,
        'property.salesComparisonApproach.numberOfComparableSales':
          this.salesComparisonApproach.numberOfComparableSales,
        'property.salesComparisonApproach.comparablesSalePriceRangeLow':
          this.salesComparisonApproach.comparablesSalePriceRangeLow,
        'property.salesComparisonApproach.comparablesSalePriceRangeHigh':
          this.salesComparisonApproach.comparablesSalePriceRangeHigh,
      };
      this.saveComparableTotals(updates);
    }
  }

  private saveComparableTotals(updates: Record<string, any>) {
    this.isLoading = true;
    const updateReportVersionRequest = {
      orderAssignmentId: this.reportVersionDto.orderAssignmentId,
      updates: updates,
    } as PartialUpdateReportDto;
    this.activityReportsService
      .partialUpdate(
        this.reportVersionDto.reportId,
        this.reportVersionDto.version,
        updateReportVersionRequest
      )
      .subscribe({
        next: (_response: ReportVersionDto) => {
          this.isEditMode = !this.isEditMode;
          this.isLoading = false;
          set(this.reportVersionDto, 'report.property.salesComparisonApproach', this.salesComparisonApproach)
        },
        error: (err) => this.logActionError(err),
      });
  }

  private logActionError(err: any): void {
    this.isLoading = false;
    this.msgService.error(err).subscribe((_status: Confirmation.Status) => {});
  }

  hasError(value) {
    return this.salesComparisonApproach.overrideTotals && !value && value !== 0;
  }
}
