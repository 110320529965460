<div class="add-section-container">
  <mat-form-field class="section-name" appearance="outline">
    <mat-label>Section name</mat-label>
    <input
      matInput
      id="inputSection"
      #inputSection
      [(ngModel)]="sectionName"
      autocomplete="off"
      maxlength="200"
      name="sectionName"
    />
  </mat-form-field>

  <button type="submit" class="button-save jaro-kit-primary-button" (click)="saveNewSection()">
    Save
  </button>

  <button type="submit" class="button-discard jaro-kit-secondary-button" (click)="discardAddNewSection()">Discard</button>

  <em
    *ngIf="section?.isEditSection"
    class="fa-regular fa-trash-can jaro-kit-primary-icon delete-icon mouse-cursor-pointer"
    (click)="deleteSection()"
  ></em>
</div>
