<div class="property-commentary-panel">
  <div class="property-commentary-panel-left">
    <jaro-kit-market-cleanup-property-visual-media
      [property]="property"
      [isLoading]="isLoading"
    ></jaro-kit-market-cleanup-property-visual-media>
  </div>
  <div class="property-commentary-panel-right">
    <jaro-kit-market-cleanup-property-commentary-article
    *ngFor="let attr of attributes"
    [attribute]="attr"
  ></jaro-kit-market-cleanup-property-commentary-article>
  </div>
</div>
