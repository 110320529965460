<div class="comparable-totals-page">
  <jaro-kit-section-panel
    title="Comparable Totals"
    [isEditMode]="isEditMode"
    [isInvalidData]="!isValidData || isLoading"
    (onSaveSection)="saveSection()"
    (onToggleModeSection)="toggleMode()"
  ></jaro-kit-section-panel>

  <div class="comparable-totals-detail" *ngIf="!isEditMode">
    <span class="report-input-label">Override Totals</span>
    <span class="report-input-value">{{
      salesComparisonApproach.overrideTotals | yesNoFormat
    }}</span>

    <table
      class="comparable-totals-table"
      [ngClass]="{ 'comparable-totals-table-disabled': !salesComparisonApproach.overrideTotals }"
    >
      <caption style="display: none">
        Comparable Totals Table
      </caption>
      <tr>
        <th class="col-comparable">COMPARABLE</th>
        <th>Count</th>
        <th>Lowest Price</th>
        <th>Highest Price</th>
      </tr>
      <tr>
        <td>Active Listings</td>
        <td>{{ salesComparisonApproach.numberOfComparableListings | number | defaultValue }}</td>
        <td>
          {{
            salesComparisonApproach.comparablesListingPriceRangeLow
              | currency: 'USD':'symbol':'1.0-0' | defaultValue 
          }}
        </td>
        <td>
          {{
            salesComparisonApproach.comparablesListingPriceRangeHigh
              | currency: 'USD':'symbol':'1.0-0' | defaultValue 
          }}
        </td>
      </tr>
      <tr>
        <td>Past 1 Year Listings</td>
        <td>{{ salesComparisonApproach.numberOfComparableSales | number | defaultValue  }}</td>
        <td>
          {{
            salesComparisonApproach.comparablesSalePriceRangeLow | currency: 'USD':'symbol':'1.0-0' | defaultValue 
          }}
        </td>
        <td>
          {{
            salesComparisonApproach.comparablesSalePriceRangeHigh | currency: 'USD':'symbol':'1.0-0' | defaultValue 
          }}
        </td>
      </tr>
    </table>
  </div>

  <div class="comparable-totals-detail comparable-totals-detail-edit-mode" *ngIf="isEditMode">
    <div class="report-input-form-field subject-pud">
      <mat-checkbox
        [(ngModel)]="salesComparisonApproach.overrideTotals"
        class="photo-checkbox check-box-label"
        value="true"
        >Override Totals</mat-checkbox
      >
    </div>

    <table
      class="comparable-totals-table"
      [ngClass]="{ 'comparable-totals-table-disabled': !salesComparisonApproach.overrideTotals }"
    >
      <caption style="display: none">
        Comparable Totals Table
      </caption>
      <tr>
        <th class="col-comparable">COMPARABLE</th>
        <th>Count</th>
        <th>Lowest Price</th>
        <th>Highest Price</th>
      </tr>
      <tr>
        <td>Active Listings</td>
        <td>
          <input
            matInput
            [(ngModel)]="salesComparisonApproach.numberOfComparableListings"
            autocomplete="off"
            numberMask
            mask="separator.0"
            thousandSeparator=","
            separatorLimit="9999999999"
            [allowNegativeNumbers]="false"
            [disabled]="!salesComparisonApproach.overrideTotals"
          />
        </td>
        <td>
          <input
            matInput
            [(ngModel)]="salesComparisonApproach.comparablesListingPriceRangeLow"
            autocomplete="off"
            currencyMask
            mask="separator.0"
            thousandSeparator=","
            separatorLimit="9999999999"
            [allowNegativeNumbers]="false"
            [disabled]="!salesComparisonApproach.overrideTotals"
          />
        </td>
        <td>
          <input
            matInput
            [(ngModel)]="salesComparisonApproach.comparablesListingPriceRangeHigh"
            autocomplete="off"
            currencyMask
            mask="separator.0"
            thousandSeparator=","
            separatorLimit="9999999999"
            [allowNegativeNumbers]="false"
            [disabled]="!salesComparisonApproach.overrideTotals"
          />
        </td>
      </tr>
      <tr>
        <td>Past 1 Year Listings</td>
        <td>
          <input
            matInput
            [(ngModel)]="salesComparisonApproach.numberOfComparableSales"
            autocomplete="off"
            numberMask
            mask="separator.0"
            thousandSeparator=","
            separatorLimit="9999999999"
            [allowNegativeNumbers]="false"
            [disabled]="!salesComparisonApproach.overrideTotals"
          />
        </td>
        <td>
          <input
            matInput
            [(ngModel)]="salesComparisonApproach.comparablesSalePriceRangeLow"
            autocomplete="off"
            currencyMask
            mask="separator.0"
            thousandSeparator=","
            separatorLimit="9999999999"
            [allowNegativeNumbers]="false"
            [disabled]="!salesComparisonApproach.overrideTotals"
          />
        </td>
        <td>
          <input
            matInput
            [(ngModel)]="salesComparisonApproach.comparablesSalePriceRangeHigh"
            autocomplete="off"
            currencyMask
            mask="separator.0"
            thousandSeparator=","
            separatorLimit="9999999999"
            [allowNegativeNumbers]="false"
            [disabled]="!salesComparisonApproach.overrideTotals"
          />
        </td>
      </tr>
    </table>
  </div>
</div>
