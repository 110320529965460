<ng-container *ngIf="form" [formGroup]="form">
  <div class="row">
    <div class="col-md-12 pl-0 pr-0">
      <div class="report-input-form-field mb-15">
        <p class="report-input-radio-label">USPAP reporting option</p>

        <mat-radio-group formControlName="uspap_reportingOption">
          <mat-radio-button
            *ngFor="let option of uspapReportingOptions"
            [value]="option.value"
            class="report-input-radio-button"
            >{{ option.name }}</mat-radio-button
          >
        </mat-radio-group>
      </div>

      <div class="report-input-form-field mt-15">
        <p class="report-input-radio-label">
          Have you performed services regarding the property within the last 3 years?
        </p>

        <mat-radio-group formControlName="uspap_areTherePerformedServicesLast3Years" class="mt-15">
          <mat-radio-button [value]="true" class="report-input-radio-button">Yes</mat-radio-button>
          <mat-radio-button [value]="false" class="report-input-radio-button">No</mat-radio-button>
        </mat-radio-group>
      </div>
    </div>
  </div>
</ng-container>
