import { Component, Inject, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute } from '@angular/router';
import { ComparablesService } from '@proxy/appraisal/comparables/v1';
import { combineLatestWith } from 'rxjs/operators';
import { OrderAssignmentServiceInterface } from 'src/app/interface/ascent/order-assignment-service-interface';
import { ReportsServiceInterface } from 'src/app/interface/bff/activity/reports-service-interface';
import InjectionSymbol from 'src/app/shared/injection/injection-symbol';
import { PropertyJobServiceInterface } from '../../../interface/property/property-job/property-job-service-interface';
import { PropertyRecordServiceInterface } from '../../../interface/property/property-job/property-record-service-interface';
import { MessageService } from '../../../shared/services/message.service';
import { MarketBaseComponent } from './market-base.component';

@Component({
  selector: 'jaro-kit-market',
  templateUrl: './market.component.html',
  styleUrls: ['./market.component.scss'],
})
export class MarketComponent extends MarketBaseComponent implements OnInit {

  constructor(
    private activatedRoute: ActivatedRoute,
    @Inject(InjectionSymbol.ActivityReportsService) activityReportService: ReportsServiceInterface,
    @Inject(InjectionSymbol.ComparableService) comparablesService: ComparablesService,
    @Inject(InjectionSymbol.OrderAssignmentService) orderAssignmentService: OrderAssignmentServiceInterface,
    @Inject(InjectionSymbol.PropertyJobService) propertyJobService: PropertyJobServiceInterface,
    @Inject(InjectionSymbol.PropertyRecordService) propertyRecordService: PropertyRecordServiceInterface,
    msgService: MessageService,
    matDialog: MatDialog
  ) {
    super(activityReportService, comparablesService, orderAssignmentService, propertyJobService, propertyRecordService, msgService, matDialog);
  }

  ngOnInit(): void {
    super.onInit();
    this.areDefaultSearchResultsLoading = true;
    this.activatedRoute.params
      .pipe(combineLatestWith(this.activatedRoute.queryParams))
      .subscribe((paramsArray) => {
        this.isLoading = true;
        this.orderAssignmentId = paramsArray[0].orderAssignmentId;
        this.getOrderAssignmentData(this.orderAssignmentId);
        this.getPropertyJobDataIncludingSearch();
      });
  }
}
