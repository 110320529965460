import { Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
  selector: 'halo-global-button-photo-gallery',
  templateUrl: './photo-gallery-button.component.html',
  styleUrls: ['./photo-gallery-button.component.scss'],
})
export class PhotoGalleryButtonComponent {
  @Input() isLoading: boolean;
  @Output() onOpenPhotoGallery = new EventEmitter<boolean>();


  openGallery() {
    this.onOpenPhotoGallery.emit(true);
  }
}
