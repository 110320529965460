import { RestService } from '@abp/ng.core';
import { Injectable } from '@angular/core';
import type { GenerateReportRequest, ReportGenerationRequestResponseDto, ReportGenerationStatusDto, ReportSubmissionRequest, ReportSubmissionRequestResponseDto, ReportSubmissionStatusDto } from './models';

@Injectable({
  providedIn: 'root',
})
export class ReportGenerationService {
  apiName = 'Appraisal';

  generate = (reportRequest: GenerateReportRequest) =>
    this.restService.request<any, ReportGenerationRequestResponseDto>({
      method: 'POST',
      url: `/appraisal/api/v2/reports/generate-requests`,
      body: reportRequest,
    },
    { apiName: this.apiName });

  getGenerateReportFileStatus = (reportGenerationRequestId: string) =>
    this.restService.request<any, ReportGenerationStatusDto>({
      method: 'GET',
      url: `/appraisal/api/v2/reports/generate-requests/${reportGenerationRequestId}`,
    },
    { apiName: this.apiName });


  submit = (reportRequest: ReportSubmissionRequest) =>
    this.restService.request<any, ReportSubmissionRequestResponseDto>({
      method: 'POST',
      url: `/appraisal/api/v2/reports/submit-requests`,
      body: reportRequest,
    },
    { apiName: this.apiName });

  getSubmitReportStatus = (reportGenerationRequestId: string) =>
    this.restService.request<any, ReportSubmissionStatusDto>({
      method: 'GET',
      url: `/appraisal/api/v2/reports/submit-requests/${reportGenerationRequestId}`,
    },
    { apiName: this.apiName });
  constructor(private restService: RestService) {}
}
