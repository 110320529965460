import { Confirmation, ConfirmationService } from '@abp/ng.theme.shared';
import { Component, Inject, OnInit, ViewChild } from '@angular/core';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import {
  CreateReportDocumentSectionDto,
  ReportDocumentSectionDto
} from '@proxy/appraisal/report/report-documents/v1';
import { OrderAssignmentDto } from '@proxy/ascent/order/order-assignment/order-assignments';
import { ReportDocumentPositionType } from '@proxy/bff/activity/report-documents';
import {
  AddReportDocumentToSectionDto,
  ReportDocumentSectionWithFilesDto,
  ReportDocumentWithFileDto
} from '@proxy/bff/activity/report-documents/v1';
import { Observable, forkJoin, timer } from 'rxjs';
import { ReportInputSectionModel } from 'src/app/features/report/shared/models/report-input-section.model';
import { PartialUpdatePdfDocumentModel } from 'src/app/features/shared/forms/models/report-input-document/partial-update-pdf-document.model';
import { EditSectionNameComponent } from 'src/app/features/shared/report-resource/edit-section-name/edit-section-name.component';
import { ReportDocumentServiceInterface } from 'src/app/interface/bff/activity/report-document-interface.service';
import { ReportDocumentSectionServiceInterface } from 'src/app/interface/bff/activity/report-document-section-interface.service';
import { DocumentsServiceInterface } from 'src/app/interface/publication/document/documents/documents-interface.service';
import { FeatureNames } from 'src/app/shared/enums/report-input.enum';
import InjectionSymbol from 'src/app/shared/injection/injection-symbol';
import { MessageService } from 'src/app/shared/services/message.service';
import { DocumentLibraryComponent } from './document-library/document-library.component';
import { DocumentPreviewModalComponent } from './document-preview-modal/document-preview-modal.component';
import { DocumentAddedToSectionEvent } from './document-sections/document-added-to-section-event';

@Component({
  selector: 'jaro-kit-documents',
  templateUrl: './documents.component.html',
  styleUrls: ['./documents.component.scss'],
})
export class DocumentsComponent implements OnInit {
  @ViewChild('createNewSection') createNewSection: EditSectionNameComponent;
  @ViewChild('documentLibrary') documentLibraryComponent: DocumentLibraryComponent;
  orderAssignmentId: string;
  reportVersion: number;
  reportId: string;
  isLoading: boolean = false;
  feature = FeatureNames.Documents;
  currentOrderAssignment: OrderAssignmentDto;

  isShowDocumentLibrary: boolean = false;
  documentsLibrary: PartialUpdatePdfDocumentModel[] = [];
  isAddSection: boolean = false;
  documentSections: ReportDocumentSectionWithFilesDto[] = [];



  constructor(
    @Inject(InjectionSymbol.ReportDocumentSectionService)
    public reportDocumentSectionService: ReportDocumentSectionServiceInterface,
    @Inject(InjectionSymbol.ReportDocumentService)
    public reportDocumentService: ReportDocumentServiceInterface,
    @Inject(InjectionSymbol.DocumentsService)
    private documentsService: DocumentsServiceInterface,
    private msgService: MessageService,
    public matDialog: MatDialog,
    public confirmation: ConfirmationService
  ) { }

  ngOnInit(): void {
    this.loadDocuments(this.reportId, this.reportVersion, this.orderAssignmentId);
  }

  refreshDocuments(document: PartialUpdatePdfDocumentModel) {
    this.documentsLibrary = this.documentsLibrary.filter(
      (item) => item.documentId !== document.documentId
    );
    this.documentLibraryComponent?.refreshLibrary(this.documentsLibrary);
  }

  loadDocuments(reportId: string, reportVersion: number, orderAssignmentId: string) {
    this.isLoading = true;
    const listOfDocuments = this.reportDocumentService.getAllDocumentsLibrary(
      reportId,
      reportVersion,
      orderAssignmentId
    );

    const getSectionDocuments = this.reportDocumentSectionService.getList(
      reportId,
      reportVersion,
      orderAssignmentId
    );

    const subscribedDocuments = {
      documents: listOfDocuments,
      sections: getSectionDocuments,
    };

    forkJoin(subscribedDocuments)
      .subscribe({
        next: (results: {
          documents: PartialUpdatePdfDocumentModel[];
          sections: ReportDocumentSectionWithFilesDto[];
        }) => {
          this.documentsLibrary = results.documents;
          this.documentLibraryComponent?.refreshLibrary(this.documentsLibrary);
          this.documentSections = results.sections;
          this.syncReportIndicator();
          this.isLoading = false;
        },
        error: (err) =>
          this.logActionError(err)
      });
  }


  loadDocumentLibrary() : Observable<undefined>{
    return new Observable(subscriber =>
      this.reportDocumentService.getAllDocumentsLibrary(
        this.reportId,
        this.reportVersion,
        this.orderAssignmentId
      )
      .subscribe((results: PartialUpdatePdfDocumentModel[]) => {
        this.documentsLibrary.splice(0);
        results.forEach(result => this.documentsLibrary.push(result));
        this.documentLibraryComponent?.refreshLibrary(this.documentsLibrary);
        this.syncReportIndicator();
        subscriber.next();
        subscriber.complete();
      }));
  }


  openDocumentLibrary(isShow: boolean) {
    this.isShowDocumentLibrary = isShow;
  }

  private logActionError(err: any): void {
    this.isLoading = false;
    this.msgService.error(err).subscribe((_status: Confirmation.Status) => { });
  }

  addNewSectionClick() {
    this.isAddSection = true;
    setTimeout(() => {
      this.createNewSection.scrollAndFocusInputSection();
    }, 0);
  }

  addNewSection(sectionName: string) {
    const sectionToCreate: CreateReportDocumentSectionDto = {
      reportId: this.reportId,
      reportVersion: this.reportVersion,
      name: sectionName,
    };
    this.reportDocumentSectionService.create(this.orderAssignmentId, sectionToCreate).subscribe({
      next: (documentSection: ReportDocumentSectionDto) => {
        this.documentSections.push(this.createNewDocumentSection(documentSection));
        this.isAddSection = false;
      },
      error: (err) => this.logActionError(err),
    });
  }

  cancelAddNewSection() {
    this.isAddSection = false;
  }

  updateSection(section: ReportInputSectionModel) {
    const updates = {};
    updates['name'] = section.name;
    const updateSection = {
      updates: updates,
    };
    this.reportDocumentSectionService
      .partialUpdate(this.reportId, this.reportVersion, section.id, updateSection)
      .subscribe({
        next: (_sectionDto: ReportDocumentSectionDto) => {
          section.isEditSection = false;
        },
        error: (err) => this.logActionError(err),
      });
  }

  deleteSection(section: ReportInputSectionModel) {
    this.reportDocumentSectionService
      .delete(this.reportId, this.reportVersion, section.id)
      .subscribe({
        next: () => {
          section.isEditSection = false;
          section.reportDocuments.forEach((document) => {
            this.updateDocumentIndicator(document.documentId, false);
          });
          const index = this.documentSections.findIndex((item) => item.id === section.id);
          this.documentSections.splice(index, 1);
        },
        error: (err) => this.logActionError(err),
      });
  }

  private createNewDocumentSection(
    documentSection: ReportDocumentSectionDto
  ): ReportDocumentSectionWithFilesDto {
    return {
      id: documentSection.id,
      reportId: this.reportId,
      reportVersion: this.reportVersion,
      name: documentSection.name,
      displayOrder: documentSection.displayOrder,
      reportDocuments: [],
    };
  }



  removeDocument(document: PartialUpdatePdfDocumentModel) {
    this.reportDocumentService
      .deleteFromSection(this.reportId, this.reportVersion, document.currentSectionId, document.id)
      .subscribe({
        next: () => {
          const section = this.documentSections.find(
            (item) => item.id === document.currentSectionId
          );
          const index = section.reportDocuments.findIndex((item) => item.id === document.id);
          section.reportDocuments.splice(index, 1);

          this.updateDocumentIndicator(document.documentId, false);
        },
        error: (err) => this.logActionError(err),
      });
  }

  private syncReportIndicator() {
    this.documentsLibrary.forEach(document => {
      if(this.documentSections.find(ds => ds.reportDocuments.find(rd => rd.documentId === document.documentId))){
          this.updateDocumentIndicator(document.documentId, true);
        }
        else{
          this.updateDocumentIndicator(document.documentId, false);
        }
      });
  }

  private updateDocumentIndicator(documentId: string, isInReport: boolean) {
    const documentIsInLibrary = this.documentsLibrary.find((item) => item.documentId === documentId);
    if (documentIsInLibrary) {
      documentIsInLibrary.isInReport = isInReport;
    }
  }


  /**
   * Adds a newly uploaded document to the selected document section
   */
  addNewDocumentToSection(event: DocumentAddedToSectionEvent) {
    // first create ReportDocument
    this.documentsService.getDocumentWithFile(event.documentId, this.orderAssignmentId)
      .subscribe(document => {
        this.reportDocumentService.create(this.orderAssignmentId, {
          reportId: this.reportId,
          reportVersion: this.reportVersion,
          title: document.title,
          source: document.source,
          documentCategory: document.documentCategory,
          documentId: document.id
        })
          .subscribe((reportDocument) => {
            this.loadDocumentLibrary()
            .subscribe(() =>
              // Then add document to section
              this.addNewReportDocumentToSection(reportDocument, event.sectionId)
              .subscribe()
            )
          })
        });
  }


  private isPdf(reportDocument: ReportDocumentWithFileDto): boolean {
    return reportDocument?.fileExtension === 'pdf';
  }

  openExtractDocumentModal(reportDocument: PartialUpdatePdfDocumentModel) {
    const dialogConfig = new MatDialogConfig();;
    reportDocument.pages = [];
    dialogConfig.data = {
      document: reportDocument,
    };
    dialogConfig.panelClass = 'document-review-modal';
    let dialogRef = this.matDialog.open(DocumentPreviewModalComponent, dialogConfig);
    dialogRef.afterClosed().subscribe({
      next: (response: PartialUpdatePdfDocumentModel | boolean) => {
        if (response && typeof response === 'object') {
          this.reselectedPages(reportDocument);
        } else if (response === false) {
          const options = {
            hideYesBtn: true,
            cancelText: 'Close',
          };
          this.msgService.error(
            null,
            null,
            'The document cannot be opened. Please try it again!',
            options
          );
        } else {
          // Nothing should be done. The user closes the dialog.
        }
      },
    });
  }


  openDocumentPreview(document: PartialUpdatePdfDocumentModel) {
    const dialogConfig = new MatDialogConfig();
    dialogConfig.data = {
      document: document,
    };
    dialogConfig.panelClass = 'document-review-modal';
    document.pages = [];
    let dialogRef = this.matDialog.open(DocumentPreviewModalComponent, dialogConfig);
    dialogRef.afterClosed().subscribe({
      next: (response: PartialUpdatePdfDocumentModel | boolean) => {
        if (response === false) {
          const options = {
            hideYesBtn: true,
            cancelText: 'Close',
          };
          this.msgService.error(
            null,
            null,
            'The document cannot be opened. Please try it again!',
            options
          );
        } else {
          // Nothing should be done. The user closes the dialog.
        }
      },
    });
  }


  /**
   * Adds a ReportDocument to the given section
   * @param reportDocument
   * @param sectionId
   * @returns
   */
  addNewReportDocumentToSection(
    reportDocument: ReportDocumentWithFileDto,
    sectionId: string
  ): Observable<undefined> {

    const defaultPosition = {
      positionType: ReportDocumentPositionType.Last,
    };

    const addReportDocumentToSectionDto = {
      reportDocumentId: reportDocument.id,
      selectedPages: reportDocument.latestExtractedVersion?.pages,
      position: defaultPosition,
    } as AddReportDocumentToSectionDto;

    return new Observable(subscriber => {
      this.reportDocumentService
        .addDocumentToSection(
          this.orderAssignmentId,
          this.reportId,
          this.reportVersion,
          sectionId,
          addReportDocumentToSectionDto
        )
        .subscribe({
          next: () => {
            // TODO: The response above doesn't contain enough data - re-fetch again
            this.reportDocumentService
              .get(this.orderAssignmentId, this.reportId, this.reportVersion, reportDocument.id)
              .subscribe((newReportDocument: PartialUpdatePdfDocumentModel) => {
                newReportDocument.currentSectionId = sectionId;
                const section = this.documentSections.find((item) => item.id === sectionId);
                section.reportDocuments.push(newReportDocument);
                this.syncReportIndicator();

                newReportDocument.countPolling = 0;

                if (this.isPdf(newReportDocument)) {
                  this.waitForExtractedFile(newReportDocument)
                    .subscribe(() => {
                      this.openExtractDocumentModal(newReportDocument);
                    });
                } else {
                  this.waitForDocumentUrl(newReportDocument)
                  .subscribe(() => {
                    this.loadDocuments(this.reportId, this.reportVersion, this.orderAssignmentId);
                    subscriber.next();
                    subscriber.complete();
                  });;
                }
              })
          },
          error: (err) => this.logActionError(err),
        });
    });
  }

  /**
   * Recursively waits for the extracted file to be ready
   * @param reportDocument
   * @returns
   */
  private waitForExtractedFile(reportDocument: PartialUpdatePdfDocumentModel): Observable<undefined> {
    return new Observable(subscriber => {
      this.reportDocumentService
        .get(this.orderAssignmentId, this.reportId, this.reportVersion, reportDocument.id)
        .subscribe({
          next: (response: PartialUpdatePdfDocumentModel) => {
            if (reportDocument.countPolling > 20) {
              return console.error('Can not get the extracted document');
            }
            const latestExtractedVersion = response.latestExtractedVersion;
            if (latestExtractedVersion && latestExtractedVersion?.links?.length > 0) {
              const link = latestExtractedVersion.links[0];
              if (!link?.href || !latestExtractedVersion.isFileAvailable) {
                timer(1000).subscribe(() => {
                  reportDocument.countPolling++;
                  this.waitForExtractedFile(reportDocument)
                    .subscribe();
                });
              } else {
                reportDocument.url = response.documentFileLocation.href;
                reportDocument.extractedURL = link.href;
                reportDocument.isProcessing = false;
                subscriber.next();
                subscriber.complete();
              }
            } else {
              timer(2000).subscribe(() => {
                reportDocument.countPolling++;
                this.waitForExtractedFile(reportDocument)
                  .subscribe();
              });
            }
          },
          error: (err) => this.logActionError(err),
        });
    });
  }


  private waitForDocumentUrl(reportDocument: PartialUpdatePdfDocumentModel): Observable<undefined> {
    return new Observable(subscriber => this.reportDocumentService
      .get(this.orderAssignmentId, this.reportId, this.reportVersion, reportDocument.id)
      .subscribe({
        next: (response: PartialUpdatePdfDocumentModel) => {
          if (reportDocument.countPolling > 20) {
            return console.error('Can not get the image');
          }
          if (response?.thumbnails && response?.documentFileLocation?.href) {
            reportDocument.url = response.documentFileLocation.href;
            reportDocument.isProcessing = false;
            subscriber.next();
            subscriber.complete();
          } else {
            timer(2000).subscribe(() => {
              reportDocument.countPolling++;
              this.waitForDocumentUrl(reportDocument)
                .subscribe();
            });
          }
        },
        error: (err) => this.logActionError(err),
      }));
  }

  /**
   * Updates the selected page range for a reportDocument
   * @param reportDocument
   */
  reselectedPages(reportDocument: PartialUpdatePdfDocumentModel) {
    reportDocument.isProcessing = true;
    reportDocument.isSelectedAllPage = (reportDocument.pages || []).length === 0;
    this.reportDocumentService
      .selectPages(this.orderAssignmentId, this.reportId, this.reportVersion, reportDocument.id, { selectedPages: reportDocument.pages })
      .subscribe({
        next: (newReportDocument: ReportDocumentWithFileDto) => {
          reportDocument.isProcessing = true;
          reportDocument.updatedTime = newReportDocument.updatedTime;
          reportDocument.latestExtractedVersion = newReportDocument.latestExtractedVersion;
          this.waitForExtractedFile(reportDocument)
            .subscribe();
        },
        error: (err) => {
          this.logActionError(err);
        }
      });
  }
}
