<halo-global-popup [dialogRef]="dialogRef" [isHideClose]="false">
    <div class="report-validation-message">
        <span>Report cannot be submitted. Please clear QC errors</span>
        <div class="action-buttons">
            <span style="flex: 1">&nbsp;</span>
            <button class="jaro-kit-secondary-button" (click)="dialogRef.close('Close')">Return to Report</button>
            <button class="jaro-kit-primary-button" (click)="dialogRef.close('Preview')">Close</button>
            <button class="jaro-kit-primary-button" (click)="dialogRef.close('OpenQC')">Open QC</button>
        </div>
    </div>
  </halo-global-popup>
