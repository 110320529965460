import { Injectable } from '@angular/core';
import { GeoLocation, GeoPolygon } from '@proxy/property/property-job/property-jobs/models';
import { GeoLocationDto, GeoPolygonDto, PropertySearchAggregationDto, PropertySearchBucketAggregateDto, PropertySearchBucketDto, PropertySearchModelDto, PropertySearchRequestDto, PropertySearchRequestRangeDto, PropertySearchResponseDto, PropertySearchStatisticsAggregateDto } from '@proxy/property/property-job/property-jobs/v1';
import { PropertyJobService } from '@proxy/property/property-job/v1';
import { Observable, map } from 'rxjs';
import { FacetFilterModel } from 'src/app/features/market/market-shared/models/facet/facet-filter-model';
import { PropertyJobServiceInterface } from 'src/app/interface/property/property-job/property-job-service-interface';
import { DataTableModel, PropertySearchAggregation, PropertySearchModel, PropertySearchResponse, ScoreModel, StatisticsModel } from 'src/app/proxy-adapter/property/property-job/models';
import { PropertySearchRequestDtoFactory } from './property-search-request-dto-factory';

@Injectable({
    providedIn: 'root',
  })
@Injectable()
export class PropertyJobServiceAdapter extends PropertyJobService implements PropertyJobServiceInterface
{
    readonly tickSymbol = '\u2713';

    readonly currencyFormatter = new Intl.NumberFormat('en-US', {
        style: 'currency',
        currency: 'USD',

        // These options are needed to round to whole numbers if that's what you want.
        minimumFractionDigits: 0, // (this suffices for whole numbers, but will print 2500.10 as $2,500.1)
        maximumFractionDigits: 1, // (causes 2500.99 to be printed as $2,501)
    });

    // Use test data when mapping the property api as this is still under construction.
    // To use the test data replace
    // <<comment in test code>> with
    // return this.getTestSearchResults(internalOrderId);
    searchAndMap = (orderAssignmentId: string, polygons: GeoPolygon[], facetFilterRecord: Record<string, Partial<FacetFilterModel>>) => this.searchAndMapResults(orderAssignmentId, polygons, facetFilterRecord);

    public searchAndMapResults(orderAssignmentId: string, polygons: GeoPolygon[], facetFilterRecord: Record<string, Partial<FacetFilterModel>>): Observable<PropertySearchResponse>
    {
        let request = JSON.parse(JSON.stringify(PropertySearchRequestDtoFactory.getEmptyRequest())) as PropertySearchRequestDto;
        request.polygons = this.mapPolygonsToDtos(polygons);
        request = this.mapFacetFilterRecordToSearchRequest(facetFilterRecord, request);

        const responseDto = this.search(orderAssignmentId, request);
        return responseDto.pipe(map(response => this.mapSearchResponseDtoToResponse(response)));
    }

    private mapPolygonsToDtos(polygons: GeoPolygon[]) : GeoPolygonDto[]
    {
        if(!polygons || polygons.length === 0)
          return [] as GeoPolygonDto[]

        const geoPolyDtos = [] as GeoPolygonDto[];
        for(let poly of polygons) {
          const geoPolyDto = this.mapPolygonToDto(poly);

          if(geoPolyDto.geoPoints.length === 0)
            continue;

          geoPolyDtos.push(geoPolyDto);
        }

        return geoPolyDtos;
      }

      private mapPolygonToDto(polygon: GeoPolygon): GeoPolygonDto {
        const geoPolyDto = {
          geoPoints: []
        } as GeoPolygonDto;

        if(!polygon || polygon?.geoPoints?.length === 0)
            return geoPolyDto;

        for(let point of polygon.geoPoints) {
          if(!point)
            continue;

          const geoLocDto = this.mapGeoLocationToDto(point);
          if(!geoLocDto)
            continue;

          geoPolyDto.geoPoints.push(geoLocDto);
        }

        return geoPolyDto;
      }

      private mapGeoLocationToDto(point: GeoLocation) : GeoLocationDto {
        if(!point)
          return null;

        const geoLocDto = {
          latitude: point.latitude,
          longitude: point.longitude
        } as GeoLocationDto;

        return geoLocDto;
      }

    private mapFacetFilterRecordToSearchRequest(facetFilterRecord: Record<string, Partial<FacetFilterModel>>,
                                                    request: PropertySearchRequestDto) : PropertySearchRequestDto
    {
        for(let key in facetFilterRecord)
        {
            if(Array.isArray(request[key]))
            {
                request[key] = facetFilterRecord[key].scores.filter((score) => score.isSelected)
                                                            .map(score => score.title);
            }
            else
            {
                request[key] = { min: facetFilterRecord[key].statistics.min,
                                    max: facetFilterRecord[key].statistics.max } as PropertySearchRequestRangeDto;
            }
        };
        return request;
    }

    private mapSearchResponseDtoToResponse(dto: PropertySearchResponseDto): PropertySearchResponse
    {
        const response =
        {
            lastRunError: dto.lastRunError,
            lastRunStatus: dto.lastRunStatus,
            totalProperties: dto.totalProperties,
            properties: dto.properties.map((prop) => this.mapDtoToPropertySearchModel(prop)),
            attributeAggregations: this.mapDtoToPropertySearchAggregations(dto.attributeAggregations)
        } as PropertySearchResponse;

        return response;
    }

    private mapDtoToPropertySearchModel(dto: PropertySearchModelDto): PropertySearchModel
    {
        const prop =
        {
            id: dto.id,
            address: dto.address,
            geoLocation: dto.geolocation,
            similarityScore: parseInt(dto.similarity),
            contractDate: this.mapDtoToContractDate(dto),
            closePrice: dto.closePrice,
            listPrice: dto.listPrice,
            price: this.isSold(dto.standardStatus) ? dto.closePrice : dto.listPrice,
            priceFormatted: this.isSold(dto.standardStatus) ? `${this.currencyFormatter.format((dto.closePrice || 0) / 1000)}k` : `${this.currencyFormatter.format((dto.listPrice || 0) / 1000)}k`,
            tickedPriceFormatted: this.isSold(dto.standardStatus) ? `${this.tickSymbol} ${this.currencyFormatter.format((dto.closePrice || 0) / 1000)}k` : `${this.tickSymbol} ${this.currencyFormatter.format((dto.listPrice || 0) / 1000)}k`,
            bedroomCount: dto.bedroomsTotal,
            bathroomCount: dto.bedroomsTotal,
            lotAreaSize: dto.lotSizeSquareFeet,
            attachmentType: dto.attachmentType,
            style: dto.style,
            yearBuilt: dto.yearBuilt,
            subjectProximity: dto.subjectProximity
        } as PropertySearchModel

        return prop;
    }

    private isSold(status: string) {
      if (status?.trim()) {
        return (status.toLowerCase() === "settled sale");
      } else {
        return false;
      }

    }

    private mapDtoToContractDate(dto: PropertySearchModelDto): Date {
        if(!(dto.listingContractMonthYear || "").includes("-"))
        {
            return null;
        }

        const contractDateParts = dto.listingContractMonthYear.split("-"); // e.g. "2022-10"
        const contractYear = parseInt(contractDateParts[0], 10);
        const contractMonth = parseInt(contractDateParts[1], 10);
        return new Date(contractYear, contractMonth, 1);
    }

    private mapDtoToPropertySearchAggregations(dto: PropertySearchAggregationDto): PropertySearchAggregation
    {
        const agg: PropertySearchAggregation =
        {
           dataTables: [] as DataTableModel[],
           statistics: [] as StatisticsModel[]
        };

        for(let prop in dto)
        {
            if (!Object.prototype.hasOwnProperty.call(dto, prop)) {
                continue;
            }

            // If has items then must be PropertySearchBucketAggregateDto
            if(dto[prop]?.items)
            {
                const data = this.mapBucketDtoToDataTableModel(prop, dto[prop] as PropertySearchBucketAggregateDto);
                agg.dataTables.push(data);
            }
            else
            // Otherwise must be PropertySearchStatisticsAggregateDto
            {
                const stat = this.mapStatisticsDtoToStatisticsModel(prop, dto[prop] as PropertySearchStatisticsAggregateDto);
                agg.statistics.push(stat);
            }
        }

        return agg;
    }

    private mapBucketDtoToDataTableModel(id: string, dto: PropertySearchBucketAggregateDto): DataTableModel {
        const data: DataTableModel =
        {
            id: id,
            scores: [] as ScoreModel[]
        };

        (dto.items || []).forEach(bucket => {
            const score = this.mapBucketToScore(bucket);
            data.scores.push(score);
        });

        return data;
    }

    private mapBucketToScore(bucket: PropertySearchBucketDto): ScoreModel
    {
        const score: ScoreModel =
        {
           title: bucket?.key || "Blank",
           frequency: bucket?.docCount || 0
        };

        return score;
    }

    private mapStatisticsDtoToStatisticsModel(id: string, dto: PropertySearchStatisticsAggregateDto): StatisticsModel
    {
        const stat: StatisticsModel =
        {
            id: id,
            average: dto?.average || 0,
            count: dto?.count || 0,
            max: dto?.max || 0,
            min: dto?.min || 0
        };

        return stat;
    }
}
