import { Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
  selector: 'jaro-kit-quality-check',
  templateUrl: './quality-check.component.html',
  styleUrls: ['./quality-check.component.scss'],
})
export class QualityCheckComponent {
  @Input() isLoading: boolean = false;
  @Input() isQCLoaded: boolean = false;
  @Input() errorCount: number = 0;
  @Input() warningCount: number = 0;
  @Output() onOpenQualityCheck = new EventEmitter<boolean>();

  openQualityCheck() {
    this.onOpenQualityCheck.emit(true);
  }
}
