import { Component, EventEmitter, Input, Output } from '@angular/core';
import { FormGroup } from '@angular/forms';

@Component({
  selector: 'jaro-kit-material-select-list-condition',
  templateUrl: './material-select-list-condition.component.html',
  styleUrls: ['./material-select-list-condition.component.scss']
})
export class MaterialSelectListConditionComponent  {

  @Input() form: FormGroup;
  @Input() formControlName: string;
  @Input() title: string;
  @Input() options = [];

  @Output() onSourceChange = new EventEmitter<any>();

  sourceChange($event) {
    this.onSourceChange.emit($event.value);
  }

}
