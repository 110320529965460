import { RestService } from '@abp/ng.core';
import { Injectable } from '@angular/core';
import { ReportVersionWithLinksDto } from '@proxy/bff/activity/reports/v1/models';
import { ReportServiceInterface } from 'src/app/interface/appraisal/report/report-service-interface';
import type { BulkPartialComparableUpdateDto, PartialComparableUpdateDto, PartialUpdateReportDto } from '../../v1/models';
import type { CreateReportVersionDto, ReportVersionDto, SignReportDto, UpdateReportListingHistoryDto, UpdateSketchAreaCalculationsDto, ValidationResultDto } from './models';

@Injectable({
  providedIn: 'root',
})
export class ReportService implements ReportServiceInterface {
  apiName = 'Appraisal';

  createVersion = (input: CreateReportVersionDto) =>
    this.restService.request<any, ReportVersionDto>({
      method: 'POST',
      url: `/api/v1/reports`,
      body: input,
    },
    { apiName: this.apiName });

  get = (orderAssignmentId: string, reportId: string, reportVersion: number) =>
    this.restService.request<any, ReportVersionDto>({
      method: 'GET',
      url: `/api/v1/reports/order-assignment/${orderAssignmentId}/report/${reportId}/report-version/${reportVersion}`,
    },
    { apiName: this.apiName });

  getLatest = (orderAssignmentId: string) =>
    this.restService.request<any, ReportVersionDto>({
      method: 'GET',
      url: `/api/v1/reports/order-assignment/${orderAssignmentId}/latest`,
    },
    { apiName: this.apiName });

  getList = (orderAssignmentId: string) =>
    this.restService.request<any, ReportVersionDto[]>({
      method: 'GET',
      url: `/api/v1/reports/order-assignment/${orderAssignmentId}`,
    },
    { apiName: this.apiName });


  mergeSketchToReport = (updateSketchCalculationAreasDto: UpdateSketchAreaCalculationsDto) =>
    this.restService.request<any, ReportVersionDto>({
      method: 'PATCH',
      url: `/api/v1/reports/area-calculations`,
      body: updateSketchCalculationAreasDto,
    },
    { apiName: this.apiName });

  patch = (reportId: string, reportVersion: number, partialUpdateReportRequest: PartialUpdateReportDto) =>
    this.restService.request<any, ReportVersionDto>({
      method: 'PATCH',
      url: `/api/v1/reports/report/${reportId}/report-version/${reportVersion}`,
      body: partialUpdateReportRequest,
    },
    { apiName: this.apiName });

  signReport = (input: SignReportDto) =>
    this.restService.request<any, ReportVersionDto>({
      method: 'PUT',
      url: `/api/v1/reports/sign-report`,
      body: input,
    },
    { apiName: this.apiName });


  updateListingHistory = (updateReportListingHistoryDto: UpdateReportListingHistoryDto ) =>
    this.restService.request<any, ReportVersionDto>({
      method: 'PUT',
      url: `/api/v1/reports/listing-history`,
      body: updateReportListingHistoryDto,
    },
    { apiName: this.apiName });


  partialUpdateComparable = (orderAssignmentId: string, reportId: string, reportVersion: number, upi: string, input: PartialComparableUpdateDto) =>
    this.restService.request<any, ReportVersionDto>({
      method: 'PATCH',
      url: `/api/v1/report-comparables/order-assignment/${orderAssignmentId}/report/${reportId}/report-version/${reportVersion}/comparable/${upi}`,
      body: input,
    },
    { apiName: this.apiName });

  bulkPartialUpdateAsync = (orderAssignmentId: string, reportId: string, reportVersion: number, input: BulkPartialComparableUpdateDto) =>
    this.restService.request<any, ReportVersionDto>({
      method: 'PATCH',
      url: `/api/v1/report-comparables/order-assignment/${orderAssignmentId}/report/${reportId}/report-version/${reportVersion}/comparable/bulk`,
      body: input,
    },
    { apiName: this.apiName });

  reportValidations = (orderAssignmentId: string, scope: string = "kit-submit", mainFormName: string = "", intent: string = "") => {
      const url = (mainFormName) ? `/api/v1/report-validations/order-assignment/${orderAssignmentId}?scope=${scope}&mainFormName=${mainFormName}&intent=${intent}` : `/api/v1/report-validations/order-assignment/${orderAssignmentId}?scope=${scope}`;
      return this.restService.request<any, ValidationResultDto>({
          method: 'POST',
          url: url,
          body: null,
        },{ apiName: this.apiName });
    }

  updateAppraiserInfo = (orderAssignmentId: string) => {
      const url = `/api/v1/reports/order-assignment/${orderAssignmentId}/appraiser-info`;
      return this.restService.request<any, ReportVersionDto>({
          method: 'POST',
          url: url,
          body: null,
        },{ apiName: this.apiName });
    }


  getActiveSendReportWithUserRole(appraiserGuid: string,
    reportVersionDto: ReportVersionWithLinksDto): boolean {
      throw new Error('Method not implemented.');
    }

    constructor(private restService: RestService) {}
}
