import { Injectable, Pipe, PipeTransform } from '@angular/core';
import { SubjectPropertyDto } from '@proxy/appraisal/report/reports/v1/form1004/models';
import { OrderDetailDto } from '@proxy/ascent/order/order-assignment/order-assignments';

@Injectable({
  providedIn: 'root',
})
@Pipe({
  name: 'orderSummaryAddressFormat',
})
export class OrderSummaryAddressFormatPipe implements PipeTransform {
  transform(property: SubjectPropertyDto, ...args: any[]): string {    
    let fullAddressFormatted: string;    
    if(args.length > 0 && !!args[0])
    {
          const orderData = args[0] as OrderDetailDto;
          fullAddressFormatted =  orderData?.address?.fullAddressFormatted;                    
    }
    const county = property?.county ? `(${property?.county})` : '';    
    
    const addressLine = [
      fullAddressFormatted,
      county,
    ].filter(s => !!s).join(" ");
    return addressLine;
  }
}
