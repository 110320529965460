<ng-container *ngIf="form" [formGroup]="form">
  <div class="row report-input-sub-section">
    <div class="col-md-6 pl-0 pr-15">
      <mat-form-field appearance="outline">
        <mat-label class="report-input-label">Overall View Rating</mat-label>
        <mat-select
          formControlName="siteViewRating"
          id="siteViewRating"
          disableOptionCentering
          panelClass="report-input-drop-down"
          placeholder="Select from list"
          floatLabel="never"
        >
          <mat-option *ngFor="let item of viewRatingOptions" [value]="item.value">{{
            item.name
          }}</mat-option>
        </mat-select>
      </mat-form-field>
      <jaro-kit-validation-message [form]="form" [controlName]="'siteViewRating'" [isRequired]="true"></jaro-kit-validation-message>

      <mat-form-field appearance="outline">
        <mat-label class="report-input-label">View Factor 1</mat-label>
        <mat-select
          formControlName="siteViewFactor1"
          id="siteViewFactor1"
          disableOptionCentering
          panelClass="report-input-drop-down"
          placeholder="Select from list"
          floatLabel="never"
          (selectionChange)="onChangeViewFactor1($event.value)"
        >
          <mat-option *ngFor="let item of viewFactorOptions" [value]="item.value">{{
            item.name
          }}</mat-option>
        </mat-select>
      </mat-form-field>
      <jaro-kit-validation-message [form]="form" [controlName]="'siteViewFactor1'" [isRequired]="true"></jaro-kit-validation-message>

      <ng-container *ngIf="isShowViewFactor1Description">
        <mat-form-field appearance="outline">
          <mat-label>View Factor 1 Other Description</mat-label>
          <input matInput formControlName="siteViewFactor1Desc" id="siteViewFactor1Desc" autocomplete="off" />
        </mat-form-field>
        <jaro-kit-validation-message [form]="form" [controlName]="'siteViewFactor1Desc'" [isRequired]="true"></jaro-kit-validation-message>
      </ng-container>

      <div class="view-factor">
        <mat-form-field appearance="outline">
          <mat-label class="report-input-label">View Factor 2</mat-label>
          <mat-select
            formControlName="siteViewFactor2"
            disableOptionCentering
            panelClass="report-input-drop-down"
            placeholder="Select from list"
            floatLabel="never"
            (selectionChange)="onChangeViewFactor2($event.value)"
          >
            <mat-option *ngFor="let item of viewFactorOptions" [value]="item.value">{{
              item.name
            }}</mat-option>
          </mat-select>
        </mat-form-field>

        <button
          (click)="removeViewFactor2()"
          *ngIf="form.value.siteViewFactor1 && form.value.siteViewFactor2"
        >
          <em class="fa fa-trash-o" aria-hidden="true"></em>Delete
        </button>
      </div>

      <ng-container *ngIf="isShowViewFactor2Description">
        <mat-form-field *ngIf="isShowViewFactor2Description" appearance="outline">
          <mat-label>View Factor 2 Other Description</mat-label>
          <input matInput formControlName="siteViewFactor2Desc" autocomplete="off" />
        </mat-form-field>
        <jaro-kit-validation-message [form]="form" [controlName]="'siteViewFactor2Desc'" [isRequired]="true"></jaro-kit-validation-message>
      </ng-container>

    </div>   
  </div>
</ng-container>
