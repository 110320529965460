import { RestService } from '@abp/ng.core';
import { Injectable } from '@angular/core';
import { CreateJobDto, PropertyJobResponseDto, PropertySearchRequestDto, PropertySearchResponseDto } from '../property-jobs/v1/models';

@Injectable({
  providedIn: 'root',
})
export class PropertyJobService {
  apiName = 'Property';

  getJobPropertyDetails = (orderAssignmentId: string) =>
  this.restService.request<any, PropertyJobResponseDto>({
    method: 'GET',
    url: `/${this.apiName.toLowerCase()}/api/v1/property-jobs/${orderAssignmentId}/property-details`
  },
  { apiName: this.apiName, skipHandleError: true }); 
  get = (orderAssignmentId: string) =>
  this.restService.request<any, PropertyJobResponseDto>({
    method: 'GET',
    url: `/${this.apiName.toLowerCase()}/api/v1/property-jobs/${orderAssignmentId}`
  },
  { apiName: this.apiName, skipHandleError: true }); 

  post = (orderAssignmentId: string, subjectStreetAddress: string, subjectCity: string, subjectState: string, subjectZipCode: string, latitude:number, longitude:number) =>
  this.restService.request<any, PropertyJobResponseDto>({
    method: 'POST',
    url: `/${this.apiName.toLowerCase()}/api/v1/property-jobs`,
    body: {
      orderAssignmentId: orderAssignmentId,
      subjectStreetAddress: subjectStreetAddress,
      subjectCity: subjectCity,
      subjectState: subjectState,
      subjectZipCode: subjectZipCode,
      latitude:latitude,
      longitude:longitude
    } as CreateJobDto
  },
  { apiName: this.apiName });

  search = (orderAssignmentId: string, searchRequest: PropertySearchRequestDto) =>
    this.restService.request<any, PropertySearchResponseDto>({
      method: 'POST',
      url: `/${this.apiName.toLowerCase()}/api/v1/property-jobs/${orderAssignmentId}/search`,
      body: searchRequest
    },
    { apiName: this.apiName, skipHandleError: true });

  constructor(private restService: RestService) {}  
}
