import { Component, Inject, Input, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { ContentServiceInterface } from 'src/app/interface/content/content-service-interface';
import { ItemData } from 'src/app/shared/components/multiple-combo-box/multiple-combo-box.component';
import InjectionSymbol from 'src/app/shared/injection/injection-symbol';

@Component({
  selector: 'jaro-kit-additional-observations-section1004',
  templateUrl: './additional-observations-section1004.component.html',
  styleUrls: ['./additional-observations-section1004.component.scss'],
})
export class AdditionalObservationsSection1004Component implements OnInit {
  @Input() form: FormGroup;

  isShowAttic = false;
  atticOption: ItemData[] = [];

  heating = null;
  heatingOption: ItemData[] = [];
  cooling = null;
  coolingOption: ItemData[] = [];

  constructor(
    @Inject(InjectionSymbol.ContentService)
    public contentService: ContentServiceInterface
  ) {
    this.atticOption = this.contentService.getContentByKey('attic');
    this.heatingOption = this.contentService.getContentByKey('mechanicalSystem.heating');
    this.coolingOption = this.contentService.getContentByKey('mechanicalSystem.cooling');
  }

  ngOnInit(): void {
    const atticValue = (this.form.value.additionalAttic || []);
    const additionalAtticField = this.form.controls['additionalAtticCheckbox'];
    const atticCheckBox = atticValue?.length > 0 ? atticValue.includes('none') ? false : true : null;
    if (!additionalAtticField) {
      this.form.addControl('additionalAtticCheckbox', new FormControl(atticCheckBox));
    }

    this.atticOption.forEach((item) => {
      this.form.addControl(
        'additionalAttic' + item.name,
        new FormControl(atticValue.includes(item.value))
      );
    });
    this.isShowAttic = atticCheckBox || this.form?.value?.additionalAtticCheckbox;
  }

  onChangeAttic(event) {
    const atticField = this.form.controls['additionalAttic'];
    if (!event.value) {
      this.isShowAttic = false;
      this.atticOption.forEach((item) => {
        item.selected = false;
        this.form.controls['additionalAttic' + item.name].setValue(null);
      });
      atticField.setValue(['none']);
      atticField.clearValidators();
      atticField.setErrors(null);
    } else {
      this.isShowAttic = true;
      atticField.setValue([]);
      atticField.setErrors({'required': true});
      atticField.setValidators([Validators.required]);
    }
    atticField.markAsTouched();
    atticField.markAsDirty();
    this.form.updateValueAndValidity();
  }

  toggleCheckbox(event, option) {
    const atticField = this.form.controls['additionalAttic'];
    if (atticField) {
      const atticValue = (atticField.value || []);
      if (event.checked) {
        atticValue.push(option.value);
      } else {
        const index = atticValue.indexOf(option.value, 0);
        if (index > -1) {
          atticValue.splice(index, 1);
        }
      }

      atticField.setValue(atticValue);
      atticField.markAsDirty();
    }
    this.form.updateValueAndValidity();
  }

  public hasError = (controlName: string, errorName: string) => {
    if (this.form) {
      const control = this.form.controls[controlName];
      return (
        control?.invalid &&
        (control?.dirty || control?.touched) &&
        control?.errors[errorName] != undefined
      );
    }
    return false;
  };
}
