import { Injectable } from '@angular/core';
import { ReportVersionDto, ValidationResultDto } from '@proxy/appraisal/report/reports/v1';
import { BehaviorSubject } from 'rxjs';
import { ReportInputTemplateField } from 'src/app/features/shared/forms/models/report-input-layout.model';

export class ReportFieldDto {
  public sectionId: string;
  public fieldId: string;
}
@Injectable({
  providedIn: 'root',
})
export class EventService {
  public onToggleSidebar: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(null);
  public onGoToReportField: BehaviorSubject<ReportFieldDto> = new BehaviorSubject<ReportFieldDto>(null);
  public onSaveSection: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(null);
  public onChangeReportData: BehaviorSubject<ReportInputTemplateField> = new BehaviorSubject<ReportInputTemplateField>(null);
  public onUpdateAppraiserInfo: BehaviorSubject<ReportVersionDto> = new BehaviorSubject<ReportVersionDto>(null);
  public onUpdateAssignmentType: BehaviorSubject<string> = new BehaviorSubject<string>(null);
  public onClickSaveSection: BehaviorSubject<string> = new BehaviorSubject<string>(null);
  public onShowSinglePdfViewer: BehaviorSubject<string> = new BehaviorSubject<string>(null);
  public onGoToSection: BehaviorSubject<string> = new BehaviorSubject<string>(null);
  public onRefreshQualityCheck: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(null);
  public onUpdatedQualityCheck: BehaviorSubject<ValidationResultDto> = new BehaviorSubject<ValidationResultDto>(null);
  public onOpenQualityCheck: BehaviorSubject<void> = new BehaviorSubject<void>(null);
  public onSubmitReport: BehaviorSubject<string> = new BehaviorSubject<string>(null);

  toggleSidebar(isExpanded: boolean) {
    this.onToggleSidebar.next(isExpanded);
  }

  goToReportField(field: ReportFieldDto) {
    this.onGoToReportField.next(field);
  }

  saveSection(isSave: boolean){
    this.onSaveSection.next(isSave);  
  }

  changeReportData(data: ReportInputTemplateField){
    this.onChangeReportData.next(data);
  }

  updateAppraiserInfo(reportVersion: ReportVersionDto){
    this.onUpdateAppraiserInfo.next(reportVersion);
  }

  updateAssignmentType(assignmentType: string){
    this.onUpdateAssignmentType.next(assignmentType);
  }

  clickSaveSection(sectionId: string){
    this.onClickSaveSection.next(sectionId);
  }

  showSinglePdfViewer(paginationAttributeSuffix: string) {
    this.onShowSinglePdfViewer.next(paginationAttributeSuffix);
  }

  goToSection(featureRouteSegment: string) {
    this.onGoToSection.next(featureRouteSegment);
  }

  refreshQualityCheck(isRefresh: boolean) {
    this.onRefreshQualityCheck.next(isRefresh);
  }

  updateQualityCheck(reportValidation: ValidationResultDto) {
    this.onUpdatedQualityCheck.next(reportValidation);
  }

  openQualityCheck() {
    this.onOpenQualityCheck.next();
  }

  submitReport(reportGenerationRequestId: string) {
    this.onSubmitReport.next(reportGenerationRequestId);
  }
}
