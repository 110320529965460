import { Component, OnDestroy } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { ReportInputTemplateField } from 'src/app/features/shared/forms/models/report-input-layout.model';
import { ViewModeSubSectionFormBuilderComponent } from '../view-mode-sub-section-formbuilder/view-mode-sub-section-formbuilder.component';

@Component({
  selector: 'jaro-kit-edit-mode-sub-section-formbuilder',
  templateUrl: './edit-mode-sub-section-formbuilder.component.html',
  styleUrls: ['./edit-mode-sub-section-formbuilder.component.scss'],
})
export class EditModeSubSectionFormBuilderComponent extends ViewModeSubSectionFormBuilderComponent implements OnDestroy {

  ngOnDestroy(): void {
    this.eventService.onChangeReportData = new BehaviorSubject<ReportInputTemplateField>(null);
  }
}
