import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { ReportVersionDto } from '@proxy/appraisal/report/reports/v1';
import { PropertyRecordDataDto } from '@proxy/property/property-record/property-records';
import { PropertyRecordDto } from '@proxy/property/property-record/property-records/v1';
import { PropertyRecordServiceInterface } from 'src/app/interface/property/property-job/property-record-service-interface';
import { ReportServiceAdapter } from 'src/app/proxy-adapter/appraisal/report/report-adapter.service';
import { ReportComparableDto } from 'src/app/proxy-adapter/report/comparables/models';
import { ComparableDateTimeFormatPipe } from 'src/app/proxy-adapter/shared/pipes/comparable-date-time-format.pipe';
import InjectionSymbol from 'src/app/shared/injection/injection-symbol';
import { MessageService } from 'src/app/shared/services/message.service';
import { MarketSectionModel } from '../models/market-section-model';
import { AdjustableAttributeModel } from '../models/property-attribute/adjustable-attribute-model';
import { AttributeValueType } from '../models/property-attribute/attribute-value-type';
import { MarketCleanupPropertyModalRequest } from './market-cleanup-property-modal-request';
import { MarketCleanupPropertyModalResult } from './market-cleanup-property-modal-result';

@Component({
  selector: 'jaro-kit-market-cleanup-property-modal',
  templateUrl: './market-cleanup-property-modal.component.html',
  styleUrls: ['./market-cleanup-property-modal.component.scss'],
})
export class MarketCleanupPropertyModalComponent implements OnInit {
  property: PropertyRecordDto;
  reportComparable: ReportComparableDto;
  isLoading: boolean = true;
  orderAssignmentId: string;
  hasPropertyAttributeChanges: boolean = false;
  hasPropertyAttributeHasErrors: boolean = false;
  comparableDateTimeFormatPipe: ComparableDateTimeFormatPipe;

  // Using an array here instead of inlining in the template as we may want to allow customisation per product or tenant later
  readonly sections: Record<string, Partial<MarketSectionModel>> = {
    main: { id: 'main', isShowTop: false },
  };

  constructor(
    public dialogRef: MatDialogRef<
      MarketCleanupPropertyModalComponent,
      MarketCleanupPropertyModalResult
    >,
    @Inject(MAT_DIALOG_DATA)
    public data: MarketCleanupPropertyModalRequest,
    @Inject(InjectionSymbol.PropertyRecordService)
    private propertyRecordService: PropertyRecordServiceInterface,
    @Inject(InjectionSymbol.ReportService) private reportService: ReportServiceAdapter,
    private msgService: MessageService
  ) {
    this.orderAssignmentId = this.data.orderAssignmentId;
    this.comparableDateTimeFormatPipe = new ComparableDateTimeFormatPipe();
  }

  ngOnInit(): void {
    this.isLoading = true;
    this.propertyRecordService.get(this.data.upi).subscribe({
      next: (prop: PropertyRecordDto) => {
        this.property = prop;
        this.reportService.getLatest(this.data.orderAssignmentId).subscribe({
          next: (reportVersion: ReportVersionDto) => {
            const reportComps = reportVersion.report.comparables.filter(
              (rc) => this.data.upi === rc.upi
            );
            if (reportComps.length > 0) {
              this.reportComparable = reportComps[0];
            }

            this.isLoading = false;
          },
          error: (err: Error) => {
            // Stay open as the property can be edited without the comparable data
            this.logActionError(err.message);
          },
        });
      },
      error: (err: Error) => {
        this.closeForOpenFailed(err.message);
      },
    });
  }

  changeCalculatedValues(attribute: AdjustableAttributeModel) {
    if(attribute.hasErrors) return;
    if(attribute.id == "closePrice" || attribute.id == "listPrice" || attribute.id == "standardStatus" || attribute.id == "livingArea") {
        let listingPrice = this.property.cleanedListing["listPrice"];
        let closePrice = this.property.cleanedListing["closePrice"];
        let status = this.property.cleanedListing["standardStatus"];

        let selectedStatus = this.getSelectedValue(status);
        let price : number = this.isSold(selectedStatus?.toString()) ? parseFloat(this.getSelectedValue(closePrice)?.toString()) : parseFloat(this.getSelectedValue(listingPrice)?.toString());
        let livingArea : number = parseFloat(this.getSelectedValue(this.property.cleanedListing["livingArea"])?.toString());

        if(price && !isNaN(price) && livingArea &&  !isNaN(livingArea)) {
            let calcPrice = (price/livingArea).toFixed(2);
            this.property.cleanedListing["pricePerSquareFeet"].selected = "custom";
            this.property.cleanedListing["pricePerSquareFeet"].custom = this.convertAttributeValueToObject(
              calcPrice,
              false,
              "float"
            );
        }
    }
  }

  getSelectedValue(test: PropertyRecordDataDto) {
    switch(test.selected) {
      case "custom": {
         return test.custom;
      }
      case "mls": {
         return test.mls;
      }
      case "pubrec": {
        return test.pubrec;
      }
      default: {
         return test.mls;
      }
    }
  }

  private isSold(status: string) {
    if (status?.trim()) {
      return (status.toLowerCase() === "settled sale");
    } else {
      return false;
    }

  }

  changeAttributeValue(attribute: AdjustableAttributeModel) {
    const dto = this.property.cleanedListing[attribute.id];
    if (dto instanceof PropertyRecordDataDto || dto?.custom || dto?.selected) {
      this.mapAttributeModelToPropertyDto(attribute, dto);
    } else {
      this.property.cleanedListing[attribute.id] = attribute.custom;
    }

    this.hasPropertyAttributeChanges = true;
    this.hasPropertyAttributeHasErrors = attribute.hasErrors;
    this.changeCalculatedValues(attribute);
  }

  save(): void {
    const result = {
      isOpenFailed: false,
      isSaveRequested: true,
      property: this.property,
    } as MarketCleanupPropertyModalResult;
    this.dialogRef.close(result);
  }

  cancel(): void {
    const result = {
      isOpenFailed: false,
      isSaveRequested: false,
      property: null,
    } as MarketCleanupPropertyModalResult;
    this.dialogRef.close(result);
  }

  private mapAttributeModelToPropertyDto(
    attribute: AdjustableAttributeModel,
    dto: PropertyRecordDataDto
  ): void {
    if (attribute.isCustomSelected) {
      dto.selected = 'custom';
      dto.custom = this.convertAttributeValueToObject(
        attribute.custom,
        attribute.isDate,
        attribute.valueTypeName
      );
      return;
    } else if (attribute.isMlsSelected) {
      dto.selected = 'mls';
      return;
    } else if (attribute.isPubRecSelected) {
      dto.selected = 'pubrec';
    }else if (attribute.isJaroAiSelected) {
      dto.selected = 'jaroAi';
    }
    dto.custom = this.convertAttributeValueToObject(
      attribute.custom,
      false,
      attribute.valueTypeName
    );
  }

  private convertAttributeValueToObject(
    value: string,
    isDate: boolean,
    valueTypeName: AttributeValueType
  ): any {
    switch (valueTypeName) {
      case 'string':
        value = isDate ? this.comparableDateTimeFormatPipe.transform(value) : value;
        return <string>(value || <string>null);
      case 'string[]':
       return value ? JSON.parse(value) : [];
      case 'integer':
        return <number>(value && !isNaN(parseInt(value, 10)) ? parseInt(value, 10) : null);
      case 'float':
        return <number>(value && !isNaN(parseFloat(value)) ? parseFloat(value) : null);
      case 'boolean':
        return JSON.parse(value || 'false');
      case 'GeoLocation':
        return JSON.parse(value || '{}');
    }
  }

  private closeForOpenFailed(errorMessage: string): void {
    this.logActionError(errorMessage);

    const result = {
      isOpenFailed: true,
      isSaveRequested: false,
      property: null,
    } as MarketCleanupPropertyModalResult;
    this.dialogRef.close(result);
  }

  private logActionError(errorMessage: string): void {
    this.isLoading = false;
    this.msgService.error(errorMessage).subscribe(() => {});
  }
}
