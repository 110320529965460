<div class="report-input-layout-page">
    <mat-spinner
      *ngIf="isLoading"
      value="100"
      diameter="100"
      strokeWidth="5"
      class="action-loading-spinner"
    ></mat-spinner>

    <ng-container *ngIf="reportTemplate">
      <div class="report-input-form">
        <jaro-kit-section-list
          [sections]="reportTemplate.sections"
          [(reportDto)]="reportDto"
          [timeZoneId]="currentOrderAssignment.orderData.timezone"
          [orderAssignmentId]="orderAssignmentId"
          [boostEnabled]="boostEnabled()"
          [reportVersionDto]="reportVersionDto"
          [feature]="feature"
          (onSaveSectionForm)="partialUpdateReportInput($event)"
        >
        </jaro-kit-section-list>
      </div>
    </ng-container>
  </div>
