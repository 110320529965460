import { RestService } from '@abp/ng.core';
import { Injectable } from '@angular/core';
import { PropertyRecordService } from '@proxy/property/property-record/v1/property-record.service';
import { PropertyRecordServiceInterface } from 'src/app/interface/property/property-job/property-record-service-interface';
import { UpdateCustomRecordDtoFactory } from './update-custom-record-dto-factory';
import { Observable } from 'rxjs/internal/Observable';
import { PropertyRecordDto } from '@proxy/property/property-record/property-records/v1';

@Injectable({
    providedIn: 'root',
  })
@Injectable()
export class PropertyRecordServiceAdapter extends PropertyRecordService implements PropertyRecordServiceInterface
{
  readonly dtoFactory: UpdateCustomRecordDtoFactory;

  constructor (private restService1: RestService) {
    super(restService1);
    this.dtoFactory = new UpdateCustomRecordDtoFactory();
  }

  postUpdatedProperty = (property: PropertyRecordDto): Observable<PropertyRecordDto> => {
    const dto = this.dtoFactory.getCustomUpdateDto(property);    
    return this.post(property.id, dto);
  }
}