
import { Component, Input } from '@angular/core';
import { MarketSectionModel } from '../models/market-section-model';
@Component({
  selector: 'jaro-kit-market-section',
  templateUrl: './market-section.component.html',
  styleUrls: ['./market-section.component.scss'],
})
export class MarketSectionComponent {
  @Input() section: MarketSectionModel;

  isLoading: boolean = false;

  get layoutHint(): string {
    return `market-section ${this.section.layoutHint}`;
  }
}
