import { Component, Input } from '@angular/core';
import { DocumentService } from 'src/app/shared/services/document.service';

@Component({
  selector: 'halo-global-document-icon',
  templateUrl: './document-icon.component.html',
  styleUrls: ['./document-icon.component.scss'],
})
export class DocumentIconComponent {
  @Input() set fileExtension(extension: string) {
    this.iconClass = this.documentService.getIconClass(extension);
    this.iconColor = this.documentService.getIconColor(extension);
  }

  iconClass: string; // The font-awesome class of the image to display as an icon for the file.
  iconColor: string;

  constructor(public documentService: DocumentService) {}
}
