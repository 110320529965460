import { LocalizationPipe } from '@abp/ng.core';
import { CommonModule } from '@angular/common';
import { CUSTOM_ELEMENTS_SCHEMA, NgModule, NO_ERRORS_SCHEMA } from '@angular/core';
import { MatIconModule } from '@angular/material/icon';
import { MatMenuModule } from '@angular/material/menu';
import { MatTreeModule } from '@angular/material/tree';
import { IConfig } from 'ngx-mask';
import { ReportsServiceAdapter } from '../proxy-adapter/bff/activity/reports-service-adapter';

import { MatButtonModule } from '@angular/material/button';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { ReportGenerationService } from '@proxy/appraisal/report/reports/v2';
import { OrderAssignmentService } from '@proxy/ascent/order/order-assignment/controllers/v2/order-assignment.service';
import { ReportInputTemplateService } from 'src/app/features/shared/forms/providers/report-input-template.service';
import { ReportServiceAdapter } from '../proxy-adapter/appraisal/report/report-adapter.service';
import { AscentReportAdapter } from '../proxy-adapter/bff/activity/ascent-report.adapter';
import { ContentService } from '../proxy-adapter/content/content-service';
import { WorkfileServiceAdapter } from '../proxy-adapter/workfile/workfile-adapter.service';
import InjectionSymbol from '../shared/injection/injection-symbol';
import { PipesModule } from '../shared/pipes/pipes.module';
import { EventService } from '../shared/services/event.service';
import { SharedModule } from '../shared/shared.module';
import { QualityCheckListComponent } from './quality-check-list/quality-check-list.component';
import { ExportReportButtonComponent } from './report-input-header/export-report-button/export-report-button.component';
import { ExportReportComponent } from './report-input-header/export-report/export-report.component';
import { ExportWorkfileButtonComponent } from './report-input-header/export-workfile-button/export-workfile-button.component';
import { ExportWorkfileComponent } from './report-input-header/export-workfile/export-workfile.component';
import { OrderSummaryBannerComponent } from './report-input-header/order-summary-banner/order-summary-banner.component';
import { PreviewReportButtonComponent } from './report-input-header/preview-report-button/preview-report-button.component';
import { PreviewReportQualityCheckComponent } from './report-input-header/preview-report/preview-report-quality-check/preview-report-quality-check.component';
import { PreviewReportComponent } from './report-input-header/preview-report/preview-report.component';
import { QualityCheckComponent } from './report-input-header/quality-check/quality-check.component';
import { ReportInputHeaderComponent } from './report-input-header/report-input-header.component';
import { ReportValidationMessageComponent } from './report-input-header/report-validation-message/report-validation-message.component';
import { ReportInputRoutingModule } from './report-input-routing.module';
import { ReportInputSidebarComponent } from './report-input-sidebar/report-input-sidebar.component';
import { ReportInputComponent } from './report-input.component';
export const options: Partial<IConfig> | (() => Partial<IConfig>) = null;

@NgModule({
  declarations: [
    ReportInputHeaderComponent,
    ReportInputComponent,
    ReportInputSidebarComponent,
    OrderSummaryBannerComponent,
    PreviewReportButtonComponent,
    ExportReportButtonComponent,
    QualityCheckComponent,
    PreviewReportComponent,
    PreviewReportQualityCheckComponent,
    ReportValidationMessageComponent,
    QualityCheckListComponent,
    ExportReportComponent,
    ExportWorkfileButtonComponent,
    ExportWorkfileComponent
  ],
  providers: [
    LocalizationPipe,
    { provide: InjectionSymbol.ActivityReportsService, useClass: ReportsServiceAdapter },
    { provide: InjectionSymbol.ReportInputTemplateService, useClass: ReportInputTemplateService },
    { provide: InjectionSymbol.WorkfileService, useClass: WorkfileServiceAdapter },
    { provide: InjectionSymbol.ContentService, useClass: ContentService },
    { provide: InjectionSymbol.ReportGenerationService, useClass: ReportGenerationService },
    { provide: InjectionSymbol.OrderAssignmentService, useClass: OrderAssignmentService },
    { provide: InjectionSymbol.AscentReportService, useClass: AscentReportAdapter },
    { provide: InjectionSymbol.ReportService, useClass: ReportServiceAdapter },
    { provide: InjectionSymbol.EventService, useClass: EventService },
  ],
  imports: [
    CommonModule,
    ReportInputRoutingModule,
    PipesModule,
    SharedModule,
    MatTreeModule,
    MatIconModule,
    MatMenuModule,
    MatButtonModule,
    MatProgressSpinnerModule
  ],
  schemas: [CUSTOM_ELEMENTS_SCHEMA, NO_ERRORS_SCHEMA],
})
export class ReportInputModule {}
