<div class="listing-history-item">
  <div class="row title">
    <div class="col-md-6 pl-0 pr-15">
      <span
        >Source: {{ subjectListingHistoryDto?.dataSource }} #{{
          subjectListingHistoryDto?.listingNumber
        }}</span
      >
    </div>

    <div class="col-md-6 pl-15 pr-0" *ngIf="hasListingHistory">
      <span>DOM: {{ DOM }}</span>
    </div>
  </div>
  <div class="grid-page-container">
    <div class="grid-description-row" *ngIf="hasListingHistory">
      <ngx-datatable
        class="material"
        [rows]="subjectListingHistoryDto.listingHistory"
        [columnMode]="ColumnMode.force"
        [headerHeight]="50"
        [footerHeight]="50"
        rowHeight="auto"
      >
        <ngx-datatable-column [name]="'Date'" [prop]="'date'" [sortable]="true">
          <ng-template let-row="row" ngx-datatable-cell-template>
            <div>{{ row.date | dateTimeFormat | defaultValue }}</div>
          </ng-template>
        </ngx-datatable-column>

        <ngx-datatable-column [name]="'Event'" [prop]="'event'" [sortable]="true">
          <ng-template let-row="row" ngx-datatable-cell-template>
            <div>{{ row.event | defaultValue }}</div>
          </ng-template>
        </ngx-datatable-column>

        <ngx-datatable-column [name]="'Price'" [prop]="'price'" [sortable]="true">
          <ng-template let-row="row" ngx-datatable-cell-template>
            <div>{{ row.price | currency: 'USD':'symbol':'1.0-0' | defaultValue }}</div>
          </ng-template>
        </ngx-datatable-column>
      </ngx-datatable>
    </div>

    <div class="empty-panel" *ngIf="!hasListingHistory">
      <em class="fas fa-arrow-right" aria-hidden="true"></em>
      <div class="detail">
        <span class="title">No Listing History</span>
        <span class="description">No known listing for past 12 months</span>
      </div>
    </div>
  </div>
</div>
