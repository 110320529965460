import { Confirmation } from '@abp/ng.theme.shared';
import { Location } from '@angular/common';
import { Component, Inject, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { CreateReportVersionDto } from '@proxy/appraisal/report/reports/v1';
import { OrderAssignmentDto } from '@proxy/ascent/order/order-assignment/order-assignments';
import { ReportVersionWithLinksDto } from '@proxy/bff/activity/reports/v1';
import { PropertyJobResponseDto, SourceStatusDto } from '@proxy/property/property-job/property-jobs/v1';
import { get, isEmpty } from 'lodash-es';
import { BehaviorSubject, Observable, forkJoin, of } from 'rxjs';
import { combineLatestWith } from 'rxjs/operators';
import { ComparablesGridComponent } from 'src/app/features/comparables/comparables-grid/comparables-grid.component';
import { DocumentsComponent } from 'src/app/features/documents/documents.component';
import { MarketComponent } from 'src/app/features/market/market-shared/market.component';
import { PhotosComponent } from 'src/app/features/photos/photos.component';
import { ReportInputDataComponent } from 'src/app/features/report/report.component';
import {
  ReportInputTemplate,
  ReportInputTemplateSectionModel,
} from 'src/app/features/shared/forms/models/report-input-layout.model';
import { ReportInputTemplateInterface } from 'src/app/features/shared/forms/providers/report-input-template.interface';
import { SketchComponent } from 'src/app/features/sketch/sketch.component';
import { ToolsComponent } from 'src/app/features/tools/tools/tools.component';
import { AssignmentComponent } from '../features/assignment/assignment/assignment.component';
import { JaroBoostComponent } from '../features/jaro-boost/jaro-boost/jaro-boost.component';
import { RunStatus } from '../features/market/market-shared/market-constants';
import { WorkfileComponent } from '../features/workfile/workfile.component';
import { OrderAssignmentServiceInterface } from '../interface/ascent/order-assignment-service-interface';
import { ReportsServiceInterface } from '../interface/bff/activity/reports-service-interface';
import { ContentServiceInterface } from '../interface/content/content-service-interface';
import { PropertyJobServiceInterface } from '../interface/property/property-job/property-job-service-interface';
import { ErrorCode } from '../shared/enums/error-code.enum';
import { FeatureName, FeatureNames } from '../shared/enums/report-input.enum';
import InjectionSymbol from '../shared/injection/injection-symbol';
import { MenuItemModel } from '../shared/models/menuItem.model';
import { MenuUpdateModel } from '../shared/models/menuUpdate.model';
import { CacheService } from '../shared/services/cache.service';
import { EventService } from '../shared/services/event.service';
import { MessageService } from '../shared/services/message.service';
import { Feature } from '../shared/utils/feature/feature';
import { ReportInputHeaderComponent } from './report-input-header/report-input-header.component';

@Component({
  selector: 'jaro-kit-report-input',
  templateUrl: './report-input.component.html',
  styleUrls: ['./report-input.component.scss'],
})
export class ReportInputComponent implements OnInit {
  @ViewChild('jaroKitHeader') jaroKitHeader: ReportInputHeaderComponent;

  menuUpdateModel = new BehaviorSubject<MenuUpdateModel>(null);
  isBoostOnly: boolean;
  isExpanded: boolean = true;
  isLoading: boolean;
  isLoadingPropertyData: boolean;
  isPrepareConfigurationTemplateData: boolean = true;
  isPrepareReportData: boolean = true;
  orderAssignmentId: string;
  currentOrderAssignment: OrderAssignmentDto;
  appraiserId: string;
  reportVersionDto: ReportVersionWithLinksDto;
  mainPicture: string;
  featureRouteSegment: string;
  menuItems: MenuItemModel[];
  reportInputTemplate: ReportInputTemplate[];
  reportTemplate: ReportInputTemplate;
  showQualityCheck: boolean;
  showBoostDataViewButton: boolean;
  sourceStatus: SourceStatusDto;
  notificationMessage: string;
  propertyJobDetailsResult: PropertyJobResponseDto;
  get isActiveQualityCheck() {
    return (
      Feature.isEnabledFeatureWithProductType(
        'ReportTemplate.QualityCheck',
        this.currentOrderAssignment.orderData.mainForm
      ) &&
      (this.featureRouteSegment === FeatureNames.Assignment ||
        this.featureRouteSegment === FeatureNames.JaroBoost ||
        this.featureRouteSegment === FeatureNames.Report||
        this.featureRouteSegment === FeatureNames.Comparables) &&
      !this.isBoostOnly
    );
  }

  get isActivePreviewReport() {
    return (
      Feature.isEnabledFeatureWithProductType(
        'ReportTemplate.PreviewReport',
        this.currentOrderAssignment.orderData.mainForm
      ) && !this.isBoostOnly
    );
  }

  constructor(
    private router: Router,
    private activatedRoute: ActivatedRoute,
    @Inject(InjectionSymbol.OrderAssignmentService)
    private orderAssignmentService: OrderAssignmentServiceInterface,
    @Inject(InjectionSymbol.ActivityReportsService)
    private activityReportsService: ReportsServiceInterface,
    private location: Location,

    @Inject(InjectionSymbol.ReportInputTemplateService)
    private reportInputTemplateService: ReportInputTemplateInterface,
    private msgService: MessageService,
    @Inject(InjectionSymbol.ContentService)
    private contentService: ContentServiceInterface,

    @Inject(InjectionSymbol.PropertyJobService)
    private propertyJobsService: PropertyJobServiceInterface,

    @Inject(InjectionSymbol.EventService)
    private eventService: EventService,
    @Inject(InjectionSymbol.CacheService)
    private cacheService: CacheService
  ) {
    this.eventService.onSaveSection.subscribe((isSave) => {
      if (isSave) {
        this.getConfigurationTemplate(true);
      }
    });
  }

  ngOnInit(): void {
    let router = this.activatedRoute;
    while (router.firstChild) {
      router = router.firstChild;
    }
    this.showBoostDataViewButton = false;

    // Set timeout to allow for the loading spinner to display
    setTimeout(() => {
      router.params.pipe(combineLatestWith(router.queryParams)).subscribe((paramsArray) => {
        this.orderAssignmentId = paramsArray[0].orderAssignmentId;
        const isBoostOnlyString = paramsArray[0].isBoostOnly ? paramsArray[0].isBoostOnly : 'false';
        this.isBoostOnly = JSON.parse(isBoostOnlyString.toLowerCase());
        this.getOrderAssignmentData(this.orderAssignmentId);
      });
    }, 100);
  }

  public toggleMenu(isExpanded: boolean) {
    this.isExpanded = isExpanded;
  }

  private getOrderAssignmentData(orderAssignmentId: string) {
    this.orderAssignmentService.get(orderAssignmentId).subscribe({
      next: (response: OrderAssignmentDto) => {
        this.currentOrderAssignment = response;
        if (!this.reportInputTemplateService.canActivateTemplate(this.currentOrderAssignment.orderData.mainForm, null)) {
          this.router.navigateByUrl('/404');
        } else {
          this.getReportData(orderAssignmentId);
        }
      },
      error: (err) => this.logActionError(err),
    });
  }

  private getReportData(
    orderAssignmentId: string,
    isNavigateNewSection?: boolean
  ) {
    this.isLoading = true;

    this.activityReportsService.getLatest(orderAssignmentId, true).subscribe({
      next: (response: ReportVersionWithLinksDto) => {    
        this.fetchPropertyDetails();    
        const orderAssignmentObservable = this.orderAssignmentService.getOrderAssignment(this.orderAssignmentId);
        const contentObservable = this.contentService.init(response.contentScope, response.contentContext);       
        const subscribedObservables = {
          orderAssignment: orderAssignmentObservable,
          content: contentObservable         
        };
        forkJoin(subscribedObservables).subscribe({
          next: (results: { orderAssignment: OrderAssignmentDto; content: boolean;}) => {
            this.currentOrderAssignment = results.orderAssignment;
            this.reportVersionDto = response;
            this.eventService.updateAssignmentType(
              this.reportVersionDto.report?.property?.assignmentType
            );
            this.getConfigurationTemplate();
            if (isNavigateNewSection) {
              this.isPrepareReportData = false;
              this.isLoading = false;
              this.navigateNewSection();
            }            
            this.isPrepareReportData = false;
            this.isLoading = false;
          },
          error: (err) => this.logActionError(err),
        });
      },
      error: (err) => {
        this.handleReportInitError(err);
      },
    });
  }

  private fetchPropertyDetails()
  {
    this.isLoadingPropertyData = true;
    this.notificationMessage = "Data retrieval in progress";
    //property job details 
    this.propertyJobsService.getJobPropertyDetails(this.orderAssignmentId).subscribe({
      next: (propertyJobResults: PropertyJobResponseDto) => {
        this.propertyJobDetailsResult = propertyJobResults;
        this.sourceStatus = propertyJobResults?.propertyDetails?.sourceStatus;
        this.showBoostDataViewButton = this.validPropertyDetailsAvailable(propertyJobResults);
        this.isLoadingPropertyData = false;
      },
      complete: () => {
        if (this.propertyJobDetailsResult.lastRunStatus == RunStatus.completed) {
          this.notificationMessage = 'Data retrieval complete';
        }
      },
      error: (err) => {
        this.showBoostDataViewButton = false;
        this.isLoadingPropertyData = false; 
      },
    });
  }

  displayDataStatus() {
    if(this.currentOrderAssignment?.extraProperties == null) return false;
    let extras = new Map(Object.entries(this.currentOrderAssignment.extraProperties));
    return this.reportTemplate?.displaydataStatus && extras.get("BoostEnabled") == true;
  }

  private validPropertyDetailsAvailable(propertyJob: PropertyJobResponseDto) {
    if(propertyJob && propertyJob?.propertyDetails &&
      (propertyJob?.propertyDetails?.pubRec?.pmxPropertyId)
        || (propertyJob?.propertyDetails?.pubRec?.streetAddress) 
        || (propertyJob?.propertyDetails?.mls?.listingId)
        || (propertyJob?.propertyDetails?.mls?.address)
        || (propertyJob?.propertyDetails?.maps?.aerialMapDetail?.aerialMapDocumentId) 
        || (propertyJob?.propertyDetails?.maps?.floodMapDetail?.floodMapDocumentId) 
        || (propertyJob?.propertyDetails?.maps?.platMapDetail?.platMapDocumentId)
        || (propertyJob?.propertyDetails?.zoneomics?.zoneCode)
        || (propertyJob?.propertyDetails?.transferHistories?.length > 0)
      ) {
        return true;
      }
      return false;
  }

  private handleReportInitError(err) {
    if (err?.error?.error?.code == ErrorCode.ReportInputNotFound) {
      const options: Partial<Confirmation.Options> = {
        yesText: 'Yes',
        cancelText: 'No',
      };
      this.msgService
        .confirm(
          'Initialize report data',
          'The report data has not been initialized for this job. Do you want to initialize it?',
          options
        )
        .subscribe((status: Confirmation.Status) => {
          if (status === Confirmation.Status.confirm) {
            this.generateReportInputData();
          } else if (status === Confirmation.Status.reject) {
            this.location.back();
          }
        });
    } else {
      this.logActionError(err);
    }
  }

    private getConfigurationTemplate(isPublishMenuItemModel: boolean = false) {
      this.reportInputTemplateService.getConfigurationMenuLeft(this.currentOrderAssignment.orderData.mainForm, this.isBoostOnly).subscribe({
      next: (menuItemsResult: MenuItemModel[]) => {
        const menuItems = menuItemsResult.filter(
          (item) => !(item.deny || []).includes(this.currentOrderAssignment.orderData.mainForm) &&
                    ((item.accept || []).length === 0 || (item.accept || []).includes(this.currentOrderAssignment.orderData.mainForm))
        );


        this.menuItems = menuItems;

          const templateObservables = {
            assignment: {} as Observable<ReportInputTemplate>,
            'jaro-boost': {} as Observable<ReportInputTemplate>,
            report: {} as Observable<ReportInputTemplate>,
          };

        for (let featureName of ['assignment', 'jaro-boost', 'report']) {
          const isFeatureEnabled = this.menuItems
            .map((mi) => mi.routeSegment)
            .some((rs) => featureName === rs);
          const templateObservable = isFeatureEnabled
            ? this.reportInputTemplateService.getTemplateByProductType(
              this.currentOrderAssignment.orderData.mainForm,
                featureName
              )
            : of({} as ReportInputTemplate);
          templateObservables[featureName] = templateObservable;
        }

        forkJoin(templateObservables).subscribe({
          next: (templates: {
            assignment: ReportInputTemplate;
            'jaro-boost': ReportInputTemplate;
            report: ReportInputTemplate;
            }) => {
              this.reportInputTemplate = [];
              if (this.isBoostOnly) {
                this.configureSectionTemplate(
                  FeatureNames.JaroBoost,
                  templates['jaro-boost'],
                  menuItems,
                  0
                );
              } else {

                let timerToLoadTemplate = 0;
                this.configureSectionTemplate(
                  FeatureNames.Assignment,
                  templates.assignment,
                  menuItems,
                  timerToLoadTemplate
                );
                timerToLoadTemplate += 3000;
                this.configureSectionTemplate(
                  FeatureNames.JaroBoost,
                  templates['jaro-boost'],
                  menuItems,
                  timerToLoadTemplate
                );
                timerToLoadTemplate += 3000;
                this.configureSectionTemplate(
                  FeatureNames.Report,
                  templates.report,
                  menuItems,
                  timerToLoadTemplate
                );
              }

              if (isPublishMenuItemModel) {
                // Required for detecting Boost/Contract section visibility for assignment type changes.
                this.menuUpdateModel.next( { menuModel: this.menuItems, selectedFeatureRouteSegment: this.featureRouteSegment} as MenuUpdateModel);
              }
              this.isPrepareConfigurationTemplateData = false;
            },
            error: (err) => this.logActionError(err),
          });
        },
        error: (err) => this.logActionError(err),
      });
  }

  private configureSectionTemplate(
    feature: FeatureName,
    template: ReportInputTemplate,
    menuItems: MenuItemModel[],
    timerToLoadTemplate: number
  ): void {
    const sections = [];
    (template?.sections || []).forEach((item) => {
      const section = new ReportInputTemplateSectionModel();

      Object.keys(item).forEach((propertyName) => {
        section[propertyName] = item[propertyName];
      });

      sections.push(section);
    });

    if (template) {
      template.sections = [...sections];
    }

    this.initSubMenu(feature, template, menuItems);
    template.feature = feature;
    this.initTemplateSection(template, timerToLoadTemplate);
    this.reportInputTemplate.push(template);
  }

  private initTemplateSection(template: ReportInputTemplate, timerToLoadTemplate: number){
    if(template.feature === this.featureRouteSegment) {
      template.sections.forEach(section => {
        this.cacheService.checkAndGetJsonFile(section.jsonPath).subscribe();
      });
    } else {
      setTimeout(() => {
        template.sections.forEach(section => {
          this.cacheService.checkAndGetJsonFile(section.jsonPath).subscribe();
        });
      }, timerToLoadTemplate);
    }
  }

  private initSubMenu(
    feature: FeatureName,
    template: ReportInputTemplate,
    menuItems: MenuItemModel[]
  ) {
    const sectionMenu = menuItems.find((item) => item.routeSegment === feature);

    if (sectionMenu) {
      template.sections = (template?.sections || []).filter((item) => {
        if (!isEmpty(item.allow) && this.reportVersionDto) {
          return item.allow.some((item) => {
            const valueItem = get(this.reportVersionDto.report, item.key);
            return item.values.some(
              (value) =>
                value && valueItem && value.toLocaleLowerCase() === valueItem.toLocaleLowerCase()
            );
          });
        }
        return true;
      });
      const sectionMenuSubBar = template.sections.map((section) => {
        return {
          title: section.title,
          id: section.id,
          isParent: false,
          isSelectedByDefault: false,
          parentTitle: feature,
          routeSegment: section.title,
        } as MenuItemModel;
      });
      sectionMenu.children = sectionMenuSubBar?.length > 1 ? sectionMenuSubBar : [];
    }
  }

  private generateReportInputData() {
    const data = {
      orderAssignmentId: this.orderAssignmentId
    } as CreateReportVersionDto;
    this.activityReportsService.appendVersion(data).subscribe({
      next: (response: ReportVersionWithLinksDto) => {
        this.reportVersionDto = response;
        this.isPrepareReportData = false;
        this.isLoading = false;
      },
      error: (err) => this.logActionError(err),
    });
  }

  private logActionError(err: any): void {
    this.isLoading = false;
    this.isPrepareConfigurationTemplateData = false;
    this.isPrepareReportData = false;
    this.msgService.error(err).subscribe({
      next: (_status: Confirmation.Status) => {},
    });
  }

  onClickMenuLeft(featureRouteSegment: string) {
    if (this.featureRouteSegment !== featureRouteSegment) {
      this.featureRouteSegment = featureRouteSegment;
      this.getReportData(this.orderAssignmentId, true);
    }
  }

  private navigateNewSection() {
    return this.router.navigate(
        [
          this.featureRouteSegment.toLocaleLowerCase(),
          { orderAssignmentId: this.orderAssignmentId }
        ],
      { relativeTo: this.activatedRoute, queryParamsHandling: 'merge' }
    );
  }

  loadAndPrepareData(
    component:
      | AssignmentComponent
      | JaroBoostComponent
      | ReportInputDataComponent
      | DocumentsComponent
      | PhotosComponent
      | SketchComponent
      | ComparablesGridComponent
      | MarketComponent
      | ToolsComponent
      | WorkfileComponent
  ) {
    this.featureRouteSegment = component.feature;
    this.reportTemplate = this.reportInputTemplate.find(
      (item) => item.feature === component.feature
    );
    component.isLoading = this.isLoading;
    component.orderAssignmentId = this.orderAssignmentId;

    switch (component?.constructor) {
      case AssignmentComponent:    
      case JaroBoostComponent:
      case ReportInputDataComponent:
        (component as ReportInputDataComponent).reportInputTemplate = this.reportInputTemplate;
        (component as ReportInputDataComponent).currentOrderAssignment = this.currentOrderAssignment;
        (component as ReportInputDataComponent).isExpanded = this.isExpanded;
        (component as ReportInputDataComponent).reportVersionDto = this.reportVersionDto;
        (component as ReportInputDataComponent).reportVersion = this.reportVersionDto.version;
        (component as ReportInputDataComponent).reportId = this.reportVersionDto.reportId;
        (component as ReportInputDataComponent).sourceStatus = this.sourceStatus;
          break;
      case SketchComponent:
        this.reportTemplate = { title: 'Sketch' } as ReportInputTemplate;
        (component as SketchComponent).currentOrderAssignment = this.currentOrderAssignment;
        (component as SketchComponent).reportVersionDto = this.reportVersionDto;
        (component as SketchComponent).reportVersion = this.reportVersionDto.version;
        (component as SketchComponent).reportId = this.reportVersionDto.reportId;
        break;
      case DocumentsComponent:
        this.reportTemplate = { title: 'Documents' } as ReportInputTemplate;
        (component as DocumentsComponent).currentOrderAssignment = this.currentOrderAssignment;
        (component as DocumentsComponent).reportVersion = this.reportVersionDto.version;
        (component as DocumentsComponent).reportId = this.reportVersionDto.reportId;
        break;
      case PhotosComponent:
        this.reportTemplate = { title: 'Photos' } as ReportInputTemplate;
        (component as PhotosComponent).currentOrderAssignment = this.currentOrderAssignment;
        (component as PhotosComponent).reportVersion = this.reportVersionDto.version;
        (component as PhotosComponent).reportId = this.reportVersionDto.reportId;
        break;
      case ComparablesGridComponent:
        this.reportTemplate = { title: 'Comparables' } as ReportInputTemplate;
        (component as ComparablesGridComponent).isExpanded = this.isExpanded;
        (component as ComparablesGridComponent).reportVersionDto = this.reportVersionDto;
        break;
      case MarketComponent:
        this.reportTemplate = { title: 'Market' } as ReportInputTemplate;
        (component as MarketComponent).reportVersionDto = this.reportVersionDto;
        (component as MarketComponent).reportId = this.reportVersionDto.reportId;
        break;
      case ToolsComponent:
        this.reportTemplate = { title: 'Tools' } as ReportInputTemplate;
        (component as ToolsComponent).currentOrderAssignment = this.currentOrderAssignment;
        (component as ToolsComponent).reportVersionDto = this.reportVersionDto;
        break;
      case WorkfileComponent:
        this.reportTemplate = { title: "Workfile" } as ReportInputTemplate;
        (component as WorkfileComponent).currentOrderAssignment = this.currentOrderAssignment;
        break;
      default:
        break;
    }
  }

  toggleQualityCheck(isOpen?: boolean) {
    if(this.isActiveQualityCheck) {
      if (isOpen != undefined && isOpen != null) {
        this.showQualityCheck = isOpen;
      } else {
        this.showQualityCheck = !this.showQualityCheck;
      }
    } else {
      this.featureRouteSegment = FeatureNames.Report;
      this.navigateNewSection().then(() => {
          this.featureRouteSegment = FeatureNames.Report;
          this.showQualityCheck = true;
          if(!this.menuItems) {
            return;
          }
          this.menuUpdateModel.next( { menuModel: this.menuItems, selectedFeatureRouteSegment: this.featureRouteSegment} as MenuUpdateModel);
      });
    }
  }

  refreshQualityCheck() {
    this.jaroKitHeader.refreshQualityCheck();
  }
}
