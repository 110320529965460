<ng-container *ngIf="form" [formGroup]="form">
    <div class="row">
      <div class="col-md-12 pl-0 pr-0">
        <div class="row pb-15">
          <span
            >Have the improvements been completed in accordance with the requirements and conditions stated in the original appraisal report?</span
          >
        </div>
  
        <mat-radio-group formControlName="certificationCompletion_propertyImprovementsCompleted" class="mt-15">
          <mat-radio-button [value]="true" class="report-input-radio-button">Yes</mat-radio-button>
          <mat-radio-button [value]="false" class="report-input-radio-button">No</mat-radio-button>
        </mat-radio-group>
  
        <mat-form-field  appearance="outline" class="jaro-kit-textarea">
          <mat-label>Additional commentary</mat-label>
          <textarea
            type="text"
            formControlName="certificationCompletion_comment"
            placeholder="Additional commentary"
            matInput
            rows="4"
          ></textarea>
        </mat-form-field>
      </div>
    </div>
  </ng-container>
  