import { Component, Input } from '@angular/core';
import { FormGroup } from '@angular/forms';

@Component({
  selector: 'halo-global-highest-and-best-use',
  templateUrl: './highest-and-best-use.component.html',
  styleUrls: ['./highest-and-best-use.component.scss'],
})
export class HighestAndBestUseComponent {
  @Input() form: FormGroup;
}
