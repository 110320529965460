<div
class="contract-document-selected"
*ngFor="let document of contractDocuments"
>
<div class="contract-document-title">
  <span class="document-title mouse-cursor-pointer" (click)="openContractDocumentForExtraction(document)"
    >{{ document.title }}.pdf</span
  >
  <span *ngIf="document.reportDocumentId" class="document-page-range mouse-cursor-pointer contract-document-page-range" (click)="openDocumentPreview(document)">
    {{
    document.latestExtractedVersion?.pages | pageRange
    }}</span>
</div>
<button mat-icon-button [matMenuTriggerFor]="moreAction" class="button-action">
  <em class="fa-regular fa-ellipsis-vertical jaro-kit-primary-icon" aria-hidden="true"></em>
</button>

<mat-menu #moreAction="matMenu" xPosition="before" class="photo-menu">
  <button mat-menu-item (click)="downloadContractDocument(document)">Download Document</button>
  <button *ngIf="isInReport(document)" mat-menu-item (click)="downloadExtractContractDocument(document)">Download Extract Document</button>
  <button *ngIf="isInReport(document)" mat-menu-item (click)="removeContractDocument(document)">Remove from report</button>
</mat-menu>
</div>
