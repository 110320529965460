<div class="report-input-template-sub-section-container">
  <p
    *ngIf="subSection.isShowGroupTitle"
    class="report-input-sub-group-title"
    >{{ subSection.groupTitle}}</p
  >
  <span
    *ngIf="subSection.isShowTitle"
    [ngClass]="{
      'report-input-title': true,
      'report-input-title-view-mode': !subSection.isEditMode
    }"
    >{{ subSection.title }}</span
  >
  <jaro-kit-view-mode-sub-section-formbuilder
    *ngIf="!subSection.isEditMode"
    [(section)]="section"
    [(subSection)]="subSection"
    [(reportInputSectionForm)]="reportInputSectionForm"
    [reportDto]="reportDto"
    [timeZoneId]="timeZoneId"
    [orderAssignmentId]="orderAssignmentId"
    [feature]="feature"
    [reportVersionDto]="reportVersionDto"
  >
  </jaro-kit-view-mode-sub-section-formbuilder>

  <jaro-kit-edit-mode-sub-section-formbuilder
    *ngIf="subSection.isEditMode"
    [(section)]="section"
    [(subSection)]="subSection"
    [(reportInputSectionForm)]="reportInputSectionForm"
    [reportDto]="reportDto"
    [orderAssignmentId]="orderAssignmentId"
    [feature]="feature"
    [reportVersionDto]="reportVersionDto"
  ></jaro-kit-edit-mode-sub-section-formbuilder>
</div>
