<div class="container" [ngClass]="{ 'translucent-loading-overlay': isLoading }">  
  <div class="row">
    <div class="col-6">
      <div *ngFor="let filterTuple of leftColumnFacetFilters">
        <jaro-kit-checkbox-list-facet-filter  *ngIf="FacetFilterComponentType.checkboxList == filterTuple.filteredSearchModel.componentType" [facetFilterSubject]="facetFilterSubject" [defaultFilterModel]="filterTuple.defaultSearchModel" [filteredFilterModel] = "filterTuple.filteredSearchModel" [selectedFilterModel]="filterTuple.selectedSearchModel"
        (onScoreToggled)="scoreToggled($event)"></jaro-kit-checkbox-list-facet-filter>
        <jaro-kit-range-slider-facet-filter *ngIf="FacetFilterComponentType.rangeSlider == filterTuple.filteredSearchModel.componentType" [facetFilterSubject]="facetFilterSubject" [defaultFilterModel]="filterTuple.defaultSearchModel" [filteredFilterModel] = "filterTuple.filteredSearchModel" [selectedFilterModel]="filterTuple.selectedSearchModel"
        (onSelectedRangeUpdated)="selectedRangeUpdated($event)"></jaro-kit-range-slider-facet-filter>
      </div>
    </div>
    <div class="col-6">
      <div *ngFor="let filterTuple of rightColumnFacetFilters">
        <jaro-kit-checkbox-list-facet-filter  *ngIf="FacetFilterComponentType.checkboxList === filterTuple.filteredSearchModel.componentType" [facetFilterSubject]="facetFilterSubject" [defaultFilterModel]="filterTuple.defaultSearchModel" [filteredFilterModel] = "filterTuple.filteredSearchModel" [selectedFilterModel]="filterTuple.selectedSearchModel"
        (onScoreToggled)="scoreToggled($event)"></jaro-kit-checkbox-list-facet-filter>
        <jaro-kit-range-slider-facet-filter  *ngIf="FacetFilterComponentType.rangeSlider == filterTuple.filteredSearchModel.componentType" [facetFilterSubject]="facetFilterSubject" [defaultFilterModel]="filterTuple.defaultSearchModel" [filteredFilterModel] = "filterTuple.filteredSearchModel" [selectedFilterModel]="filterTuple.selectedSearchModel"
        (onSelectedRangeUpdated)="selectedRangeUpdated($event)"></jaro-kit-range-slider-facet-filter>
      </div>
    </div>
  </div>
</div> 