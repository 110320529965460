import { Component } from '@angular/core';
import { FormGroup } from '@angular/forms';

@Component({
  selector: 'jaro-kit-supervisor-certification',
  templateUrl: './supervisor-certification.component.html',
  styleUrls: ['./supervisor-certification.component.scss'],
})
export class SupervisorCertificationComponent {
  form: FormGroup;

  onChangePropertyInspected($event) {
    if ($event.value === false) {
      this.form.controls['subjectTypeOfInspection'].setValue(null);
      this.form.controls['subjectTypeOfInspection'].markAsDirty();
      this.form.controls['subjectCertificationInspectionDate'].setValue(null);
      this.form.controls['subjectCertificationInspectionDate'].markAsDirty();
    }
    this.form.controls['subjectPropertyInspected'].setValue($event.value);
    this.form.updateValueAndValidity();
  }
}
