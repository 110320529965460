import { Component, Input } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { ItemData } from 'src/app/shared/components/multiple-combo-box/multiple-combo-box.component';

@Component({
  selector: 'jaro-kit-material-combo-box-condition',
  templateUrl: './material-combo-box-condition.component.html',
  styleUrls: ['./material-combo-box-condition.component.scss']
})
export class MaterialComboBoxConditionComponent {
  @Input() formGroup: FormGroup;
  @Input() title: string;
  @Input() condition: string;
  @Input() materialOptions: ItemData[];
  @Input() value: string[];
  @Input() materialFormControlName: string;
  @Input() conditionFormControlName: string;
  @Input() conditionOptions = [];
  
}
