<div [ngClass]="{ 'translucent-loading-overlay': isLoading, 'comparables-panel': true }">
  <div class="grid-count-panel"><span [ngClass]="{ 'selected-grid-count': selectedProperties.length !== 0 }">{{ selectedProperties.length }}</span>&nbsp;/&nbsp;<span [ngClass]="{ 'filtered-search-grid-count': filteredProperties?.length !== defaultPropertyCount, 'default-search-grid-count': filteredProperties?.length === defaultPropertyCount }">{{ filteredProperties?.length }}</span><span>&nbsp;Results</span></div>
  <div class="report-input-headline mat-headline">Search</div>
  <ngx-datatable
    (page)="onPage($event)"
    (sort)="onSort($event)"
    [count]="filteredProperties?.length"
    [externalPaging]="false"
    [externalSorting]="false"
    [footerHeight]="footerHeight"
    [headerHeight]="48"
    [limit]="pageSize"
    [offset]="offset"
    [rowClass]="getRowCssClasses"
    [rows]="filteredProperties"
    [selectionType]="'single'"
    [sorts]="defaultSort"
    default
  >
  <ngx-datatable-column [name]="'Edit'" [maxWidth]="50">
    <ng-template let-row="row" ngx-datatable-cell-template>
      <em class="grid-edit-icon grid-edit-column fas fa-pencil-alt" (click)="editProperty(row.id)"></em>
    </ng-template>
  </ngx-datatable-column>
  <ngx-datatable-column [name]="'Add'" [maxWidth]="50">
    <ng-template let-row="row" ngx-datatable-cell-template><em *ngIf="!row.isSelected" class="grid-selectable-icon grid-add-column fas fa-plus-circle" (click)="addComparable(row.id)" ></em><em *ngIf="row.isSelected" class="grid-selected-icon grid-remove-column fas fa-minus-circle" (click)="removeComparable(row.id)"></em></ng-template>
  </ngx-datatable-column>
    <ngx-datatable-column [name]="'Address'" [prop]="'address'" [width]="addressColumnWidth">
      <ng-template let-row="row" ngx-datatable-cell-template>
        {{ row.address }}
      </ng-template>
    </ngx-datatable-column>
    <ngx-datatable-column [name]="'Similarity'" [prop]="'similarityScore'" [maxWidth]="100">
      <ng-template let-row="row" ngx-datatable-cell-template>
        {{ row.similarityScore }}
      </ng-template>
    </ngx-datatable-column>
    <ngx-datatable-column [name]="'Contract Date'" [prop]="'contractDate'" [maxWidth]="100">
      <ng-template let-row="row" ngx-datatable-cell-template>
        {{ row.contractDate | dateTimeFormat: 'MM/YYYY' }}
      </ng-template>
    </ngx-datatable-column>
    <ngx-datatable-column [name]="'Price'" [prop]="'price'" [maxWidth]="100">
      <ng-template let-row="row" ngx-datatable-cell-template>
        {{ row.price | currency: 'USD':'symbol':'1.0-0' }}
      </ng-template>
    </ngx-datatable-column>    
    <ngx-datatable-column [name]="'Bedrooms'" [prop]="'bedroomCount'" [maxWidth]="100">
      <ng-template let-row="row" ngx-datatable-cell-template>
        {{ row.bedroomCount }}
      </ng-template>
    </ngx-datatable-column>
    <ngx-datatable-column [name]="'Bathrooms'" [prop]="'bathroomCount'" [maxWidth]="100">
      <ng-template let-row="row" ngx-datatable-cell-template>
        {{ row.bathroomCount }}
      </ng-template>
    </ngx-datatable-column>
    <ngx-datatable-column [name]="'Square Feet'" [prop]="'lotAreaSize'" [maxWidth]="100">
      <ng-template let-row="row" ngx-datatable-cell-template>
        {{ row.lotAreaSize }}
      </ng-template>
    </ngx-datatable-column>
    <ngx-datatable-column [name]="'Style (Design)'" [prop]="'style'" [width]="300">
      <ng-template let-row="row" ngx-datatable-cell-template>
        {{ row.style }}
      </ng-template>
    </ngx-datatable-column>
    <ngx-datatable-column [name]="'Year Built'" [prop]="'yearBuilt'" [maxWidth]="100">
      <ng-template let-row="row" ngx-datatable-cell-template>
        {{ row.yearBuilt }}
      </ng-template>
    </ngx-datatable-column>
  </ngx-datatable>
</div>
