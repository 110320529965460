import { Component, Inject, Input } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { ContentServiceInterface } from 'src/app/interface/content/content-service-interface';
import { ItemData } from 'src/app/shared/components/multiple-combo-box/multiple-combo-box.component';
import InjectionSymbol from 'src/app/shared/injection/injection-symbol';

@Component({
  selector: 'halo-global-one-unit-housing-trends',
  templateUrl: './one-unit-housing-trends.component.html',
  styleUrls: ['./one-unit-housing-trends.component.scss'],
})
export class OneUnitHousingTrendsComponent {
  @Input() form: FormGroup;

  propertyOptions: ItemData[] = [];
  demandOptions: ItemData[] = [];
  marketingTimeOptions: ItemData[] = [];
  constructor(
    @Inject(InjectionSymbol.ContentService)
    public contentService: ContentServiceInterface
  ) {
    this.propertyOptions = this.contentService.getContentByKey('propertyValues');

    this.demandOptions = this.contentService.getContentByKey('demandSupply');

    this.marketingTimeOptions = this.contentService.getContentByKey('marketingTime');
  }
}
