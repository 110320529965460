import { Component } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { AddressStateTranslations } from 'src/app/shared/pipes/translate-data/address-state.prototype';
import { ReportInputTranslationModel } from 'src/app/shared/pipes/translate-data/translate-data.prototype';

@Component({
  selector: 'jaro-kit-subject-section1004d',
  templateUrl: './subject-section1004d.component.html',
  styleUrls: ['./subject-section1004d.component.scss'],
})
export class SubjectSection1004DComponent {
  form: FormGroup;

  addressStateOptions: ReportInputTranslationModel[] = AddressStateTranslations;
  
}
