import { Component } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { ItemData } from 'src/app/shared/components/multiple-combo-box/multiple-combo-box.component';

@Component({
  selector: 'jaro-kit-uspap',
  templateUrl: './uspap.component.html',
  styleUrls: ['./uspap.component.scss'],
})
export class UspapComponent {
  readonly uspapReportingOptions: ItemData[] = [ { name:  "Appraisal Report", value: "appraisal report", selected: false }, 
                                                 { name: "Restricted Appraisal Report", value: "restricted appraisal report", selected: false } ];

  form: FormGroup;
  reportingOption: string;
  areTherePerformedServicesLast3Years: boolean;
}