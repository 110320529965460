import { DatePipe } from '@angular/common';
import { Injectable, Pipe, PipeTransform } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
@Pipe({
  name: 'mainDateTimeFormat',
})

export class MainDateTimeFormatPipe extends DatePipe implements PipeTransform {
  transform(utcDateStr: string | Date, timeZone?: string, formatDate?: string): any {
    const timeZoneAbbreviations = !!timeZone ? ` ${timeZone}` : '';
    const localDate = new Date(utcDateStr);
    const year = localDate.getFullYear();
    const currentYear = new Date().getFullYear();
    const formatYear = year != currentYear ? ', ' + year : '';
    const format = formatDate ? `${formatDate} ${formatYear}` : `h:mmaaaaa'm', MMM d ${formatYear}`;
    return super.transform(localDate, format + timeZoneAbbreviations); //1:00pm, Aug 2,
  }
}
