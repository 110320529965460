import { Injectable, Pipe, PipeTransform } from '@angular/core';
import * as moment from 'moment';
import { DateUtils } from '../../utils/date/date.utils';

@Injectable({
  providedIn: 'root',
})
@Pipe({
  name: 'toUtcForTimeZone',
})
export class ToUtcForTimeZonePipe implements PipeTransform {  
  transform(localDateTimeOrString: string | Date, dateFormat?: string, timeZoneId?: string): any {
    if(!localDateTimeOrString)
      return null;

    const localDateTime = moment(localDateTimeOrString).toDate();
    const dateFormatToUse = dateFormat || 'MM/DD/YYYY';

    // Abbreviates 'America/Phoenix' to 'MST'
    // Return UTC if no time zone id applied rather than null or an exception.    
    if(!timeZoneId)
    {
      return localDateTime ? `${moment(localDateTime).format(dateFormatToUse)}` : null;
    }

    // Offset the date and time for the timezone difference
    // e.g. 
    // 01-Jan-2023 06:15 UTC America/Phoenix -> 31-Dec-2022 23:15
    // 01-Jan-2023 08:15 UTC America/Phoenix -> 01-Jan-2023 01:15 
    console.log(moment(localDateTime).format("YYYY-MM-DD HH:mm:ss"));
    const utc = localDateTime && timeZoneId ? DateUtils.timeZoneDateToUtc(`${moment(localDateTime).format("YYYY-MM-DD HH:mm:ss")}`, timeZoneId) : null;

    return utc ? `${moment(utc).format(dateFormatToUse)}` : null;
  }
}
