<div class="raw-data-view-page-header">
  <div class="order-summary-banner">
    <div class="summary-line">
      <span *ngIf="titleHeader() != ''" class="order-number">{{ titleHeader() }}</span>
      <span class="address">{{ subjectProperty | orderSummaryAddressFormat: orderData }}</span>
    </div>
    <div class="summary-line">
      <span class="order-number">Subject Property Data Reference</span>
    </div>
    
  </div>
</div>
<div *ngIf="showErrors" class="error-panel">
  <span class="error-title">
    <div *ngFor="let item of sectionErrors" class="error-item">
      <i class="fa-light fa-circle-exclamation"></i> {{ item }}
    </div>
  </span>
</div>
<div>
  <div
    [ngClass]="{
      'loading-panel': true
    }"
  >
    <mat-spinner
      *ngIf="isLoading"
      value="100"
      diameter="100"
      strokeWidth="5"
      class="loading-spinner"
    ></mat-spinner>
  </div>
</div>

<jaro-kit-raw-data
  [propertyDetails]="subjectPropertyRecordDto?.propertyDetails"
  [isSubject]="true"
  *ngIf="!isLoading && subjectPropertyRecordDto?.propertyDetails"
></jaro-kit-raw-data>
