<div class="report-resource-collection">
  <div class="report-resource-collection-container-top">
    <div class="report-resource-collection-top">
      <span class="report-resource-collection-title">{{ title }}</span>
      <div (click)="closeDialog()" class="report-resource-collection-close mouse-cursor-pointer">
        <em class="fal fa-times"></em>Close
      </div>
    </div>

    <jaro-kit-report-resource-filter
      #reportResourceFilter
      [items]="items"
      [reportResourceType]="reportResourceType"
      (onUpdateSource)="updateSource($event)"
    ></jaro-kit-report-resource-filter>
  </div>

  <jaro-kit-photo-gallery
    *ngIf="reportResourceType === type.Photo"
    #reportResourceItems
    [itemsFiltered]="itemsFiltered"
    [items]="items"
    [orderAssignmentId]="orderAssignmentId"
    [reportId]="reportId"
    [reportVersion]="reportVersion"
    [photoSections]="photoSections"
    [reportResourceType]="reportResourceType"
    [source]="source"
    (onUpdateReportResources)="updateSectionPhotos($event)"
  >
  </jaro-kit-photo-gallery>

  <jaro-kit-document-library
    *ngIf="reportResourceType === type.Document"
    #reportResourceItems
    [itemsFiltered]="itemsFiltered"
    [items]="items"
    [orderAssignmentId]="orderAssignmentId"
    [reportId]="reportId"
    [reportVersion]="reportVersion"
    [photoSections]="photoSections"
    [reportResourceType]="reportResourceType"
    [source]="source"
    (onUpdateReportResources)="updateSectionPhotos($event)"
    (onDeleteDocument)="onDeleteDocument($event)"

  >
  </jaro-kit-document-library>
</div>
