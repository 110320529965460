<div class="market-conditions-page">
  <jaro-kit-section-panel
    title="Market Conditions"
    [isEditMode]="isEditMode"
    [isInvalidData]="!isValidData || isLoading"
    (onSaveSection)="saveSection()"
    (onToggleModeSection)="toggleMode()"
  ></jaro-kit-section-panel>

  <div class="market-conditions-detail">
    <jaro-kit-general-information
      [(isEditMode)]="isEditMode"
      [(marketCondition)]="marketCondition"
    ></jaro-kit-general-information>
  </div>
</div>
