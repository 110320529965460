import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'facetRangeFormat'
})
export class FacetRangeFormatPipe implements PipeTransform {
  transform(num: number): string {    
    return (num) ? this.formatNumberUsingMetricSuffix(num): "";
  }

  formatNumberUsingMetricSuffix(num: number): string {
    if(num === 0)
    {
      return "0";
    }

    const absNum = Math.abs(num);
    if(absNum > 999999)
    {
       return `${(Math.sign(num)*(absNum/1000000)).toFixed(1)}M`
    }
    
    if(absNum > 999)
    {
      return `${(Math.sign(num)*(absNum/1000)).toFixed(1)}k`;
    } 
    
    return (Math.sign(num)*absNum).toFixed(0);
  }
}