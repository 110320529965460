import { Component, Inject } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { MatRadioChange } from '@angular/material/radio';
import { ContentServiceInterface } from 'src/app/interface/content/content-service-interface';
import { ItemData } from 'src/app/shared/components/multiple-combo-box/multiple-combo-box.component';
import InjectionSymbol from 'src/app/shared/injection/injection-symbol';

@Component({
  selector: 'jaro-kit-subject-section1004',
  templateUrl: './subject-section1004.component.html',
  styleUrls: ['./subject-section1004.component.scss'],
})
export class SubjectSection1004Component {
  form: FormGroup;
  
  assignmentTypeOptions: ItemData[] = [];  
  isShowAssignmentTypeDescribe: boolean;

  constructor(
    @Inject(InjectionSymbol.ContentService)
    public contentService: ContentServiceInterface
  ) {    
    this.assignmentTypeOptions = this.contentService.getContentByKey('assignmentType', true);
  }

  onChangeAssignmentType($event: MatRadioChange) {
    if ($event.value !== 'other') {
      this.form.controls['subjectAssignmentTypeDescription'].setValue(null);
      this.form.controls['subjectAssignmentTypeDescription'].markAsPristine();
      this.form.updateValueAndValidity();
    }
    this.isShowAssignmentTypeDescribe = $event.value === 'other';
  }
}
