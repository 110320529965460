import { Injectable } from '@angular/core';
import { ClockServiceInterface } from '../../interface/shared/utils/clock-service-interface';

@Injectable()
export class LocalClockService implements ClockServiceInterface {
  public now(timeZoneId?: string): Date {
    if (!timeZoneId) return new Date();

    return new Date(timeZoneId);
  }
}
