import { Component, ElementRef, HostListener } from '@angular/core';
import { OidcSecurityService } from 'angular-auth-oidc-client';

@Component({
  selector: 'jaro-kit-admin-sidebar',
  templateUrl: './admin-sidebar.component.html',
  styleUrls: ['./admin-sidebar.component.scss'],
})
export class AdminSidebarComponent {
  expanded = false;
  activeMenuName = '';

  @HostListener('document:click', ['$event'])
  clickout(event) {
    if (!this.eRef.nativeElement.contains(event.target)) {
      this.closePanel();
    }
  }

  constructor(
    private eRef: ElementRef,
    private oidcSecurityService: OidcSecurityService
  ) {}

  closePanel() {
    this.expanded = false;
    this.activeMenuName = '';
  }

  toggle(menuName) {
    //if there is click on same menu button
    if (this.activeMenuName == menuName) {
      this.expanded = !this.expanded;
    }

    //if new menu was clicked
    if (!this.activeMenuName) {
      this.activeMenuName = menuName;
      this.expanded = true;
    }

    if (!this.expanded) this.activeMenuName = '';
  }

  logout() {
    console.log('start logoff');
    this.oidcSecurityService.logoff();
  }
}
