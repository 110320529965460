import { get } from 'lodash-es';
import { ReportInputDataFormFieldModel } from 'src/app/features/shared/forms/models/report-input-data-field-form-model/report-input-date-field-form-model';
import { YesNoFormatPipe } from 'src/app/shared/pipes/yes-no-format/yes-no-format.pipe';

export const ReportInputTemplateConfigFoundationSection: ReportInputDataFormFieldModel[] = [
  {
    formControlName: 'foundationConcreteSlab',
    format: {
      pipeType: YesNoFormatPipe,
    },
  },
  {
    formControlName: 'foundationCrawlSpace',
    format: {
      pipeType: YesNoFormatPipe,
    },
  },
  {
    formControlName: 'foundationFullBasement',
    format: {
      pipeType: YesNoFormatPipe,
    },
  },
  {
    formControlName: 'foundationPartialBasement',
    format: {
      pipeType: YesNoFormatPipe,
    },
  },
  {
    formControlName: 'foundationOutsideEntryExit',
    format: {
      pipeType: YesNoFormatPipe,
    },
  },
  {
    formControlName: 'foundationSumpPump',
    format: {
      pipeType: YesNoFormatPipe,
    },
  },
  {
    formControlName: 'interiorTotalBathroomCount',
    mapToFieldFunc: (totalFullBathroomAboveGradeCount, objectDto) => {
      let totalHalfBathroomAboveGradeCount = get(
        objectDto,
        'property.improvement.totalHalfBathroomAboveGradeCount'
      );
      if (
        !totalFullBathroomAboveGradeCount &&
        totalFullBathroomAboveGradeCount !== 0 &&
        !totalHalfBathroomAboveGradeCount &&
        totalHalfBathroomAboveGradeCount !== 0
      ) {
        return null;
      } else {
        totalFullBathroomAboveGradeCount = totalFullBathroomAboveGradeCount | 0;
        totalHalfBathroomAboveGradeCount = totalHalfBathroomAboveGradeCount | 0;
        return `${totalFullBathroomAboveGradeCount}.${totalHalfBathroomAboveGradeCount}`;
      }
    },
  }
];
