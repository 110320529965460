<ng-container *ngIf="property" class="commentaries-container">
  <div class="row">
    <div class="report-input-form-field col-md-12 mt-15">
      <p class="report-input-radio-label">Sale/Transfer History Researched Done</p>

      <mat-radio-group
        [(ngModel)]="property.isSaleOrTransferHistoryResearchedDone"
        class="mt-15"
        (change)="onChangeSaleOrTransferHistoryResearchedDone($event)"
      >
        <mat-radio-button [value]="true" class="report-input-radio-button">Yes</mat-radio-button>
        <mat-radio-button [value]="false" class="report-input-radio-button">No</mat-radio-button>
      </mat-radio-group>
    </div>

    <div class="col-md-12">
      <mat-form-field *ngIf="property.isSaleOrTransferHistoryResearchedDone === false" appearance="outline" class="jaro-kit-textarea">
        <mat-label>Reason</mat-label>
        <textarea
          type="text"
          [(ngModel)]="property.saleOrTransferHistoryResearchedDoneReason"
          placeholder="Reason"
          matInput
          rows="4"
        ></textarea>
      </mat-form-field>
    </div>

    <div class="col-md-12">
      <mat-form-field appearance="outline" class="jaro-kit-textarea">
        <mat-label>Analysis of Prior Sale or Transfer History</mat-label>
        <textarea
          type="text"
          [(ngModel)]="property.analysisOfPriorSaleOrTransferHistory"
          placeholder="Analysis of Prior Sale or Transfer History"
          matInput
          rows="4"
        ></textarea>
      </mat-form-field>
    </div>

    <div class="col-md-12">
      <mat-form-field appearance="outline" class="jaro-kit-textarea">
        <mat-label>Summary of Sales Comparison Approach</mat-label>
        <textarea
          type="text"
          [(ngModel)]="property.salesComparisonApproachSummary"
          placeholder="Summary of Sales Comparison Approach"
          matInput
          rows="4"
        ></textarea>
      </mat-form-field>
    </div>
 
  </div>
</ng-container>
