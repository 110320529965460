import { Component, Inject, OnInit } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { RoomDto } from '@proxy/appraisal/report/reports/v1/form1004';
import { ReportDto } from '@proxy/appraisal/report/v1/form1004';
import { cloneDeep, get } from 'lodash-es';
import {
    ReportInputTemplateSectionModel,
    ReportInputTemplateSubSectionModel,
} from 'src/app/features/shared/forms/models/report-input-layout.model';
import { ReportInputTemplateService } from 'src/app/features/shared/forms/providers/report-input-template.service';
import InjectionSymbol from 'src/app/shared/injection/injection-symbol';

@Component({
  selector: 'halo-global-interior-features',
  templateUrl: './interior-features.component.html',
  styleUrls: ['./interior-features.component.scss'],
})
export class InteriorFeaturesComponent implements OnInit {
  reportDto: ReportDto;
  form: FormGroup;
  section: ReportInputTemplateSectionModel;
  subSection: ReportInputTemplateSubSectionModel;
  previousLevelIndex: number;

  roomForms: {
    roomDto: RoomDto;
    roomTemplate: ReportInputTemplateSubSectionModel;
  }[] = [];

  constructor(
    @Inject(InjectionSymbol.ReportInputTemplateService)
    public reportInputTemplateService: ReportInputTemplateService
  ) {}

  ngOnInit(): void {
    this.roomForms = [];
    let rooms = get(this.reportDto, 'property.improvement.interior.rooms') || [];
    rooms = rooms.sort((a, b) => (a.levelIndex < b.levelIndex ? -1 : 1));
    if (rooms?.length > 0) {
      rooms.forEach((room) => {
        this.initConfigRoomFields(room);
      });
    } else {
      // remove the config if list rooms is empty
      this.section.subSections.splice(
        this.section.subSections.findIndex(function (i) {
          return i.componentName == 'interior1004';
        }),
        1
      );
    }
  }

  private initConfigRoomFields(roomDto: RoomDto) {
    // For each room, add a new subsection.
    const subSection = this.section.subSections.find((item) => item.title === roomDto.areaName);
    if (subSection) {
      this.reportInputTemplateService.updateVisibleForSubSection(subSection, roomDto);
      return;
    }
    const newRoomForm = {
      title: roomDto.areaName,
      isShowTitle: true,
      groupTitle: roomDto.levelName,
      isShowGroupTitle: this.previousLevelIndex !== roomDto.levelIndex,
      isCustomComponent: false,
      componentName: 'room1004',
      fields: [],
      layoutHint: 'room-detail',
      isDynamicSubSection: true,
      metadata: {
        roomDto: roomDto,
      },
    } as ReportInputTemplateSubSectionModel;
    this.previousLevelIndex = roomDto.levelIndex;

    const roomFields = cloneDeep(this.subSection.dynamicFields) || [];
    roomFields.forEach((field) => {
      const originalFormControlName = field.formControlName;
      field.formControlName = field.formControlName.replace('_RoomName_', roomDto.areaName);
      field.updatePath = field.updatePath.replace('_RoomName_', roomDto.areaName);
      field.metadata = {
        roomName: roomDto.areaName,
      };
      const newFormControl = this.reportInputTemplateService.getDynamicFormControl(
        field,
        roomDto,
        originalFormControlName
      );
      this.form.addControl(field.formControlName, newFormControl);
    });
    newRoomForm.fields = roomFields;

    // add item with index
    this.section.insertSubSectionBeforeComponent(this.subSection.componentName, newRoomForm);

    this.roomForms.push({
      roomDto: roomDto,
      roomTemplate: newRoomForm,
    });
  }
}
