import { Component, Inject, Input, OnInit } from '@angular/core';
import { FormGroup, Validators } from '@angular/forms';
import { MatRadioChange } from '@angular/material/radio';
import { ReportWithExtraAttributes } from 'src/app/features/report/shared/models/report-input-extra-atributes.model';
import { ContentServiceInterface } from 'src/app/interface/content/content-service-interface';
import { ItemData } from 'src/app/shared/components/multiple-combo-box/multiple-combo-box.component';
import InjectionSymbol from 'src/app/shared/injection/injection-symbol';

@Component({
  selector: 'halo-global-foundation-section1004',
  templateUrl: './foundation-section1004.component.html',
  styleUrls: ['./foundation-section1004.component.scss'],
})
export class FoundationSection1004Component implements OnInit {
  @Input() form: FormGroup;
  @Input() reportDto: ReportWithExtraAttributes;

  public isShowBasementFinishedAreaSqFt: boolean;
  public isShowBasementExit: boolean;
  public isShowTotalRecreationRoomBelowGradeCount: boolean;
  public isShowTotalBedroomBelowGradeCount: boolean;
  public isShowFullAndHalfBathroomBelowGradeCount: boolean;
  public isShowTotalOtherRoomBelowGradeCount: boolean;

  basementExitOptions: ItemData[] = [];

  constructor(
    @Inject(InjectionSymbol.ContentService)
    public contentService: ContentServiceInterface
  ) {
    this.basementExitOptions = this.contentService.getContentByKey('internalArea.basementExit');
  }

  ngOnInit(): void {
    this.configureVisibilityOfTotalAreaSizeDependentFields();
    this.toggleValidatorsForFieldsRequiredForTotalArea(true);
    this.toggleValidatorsForFieldsRequiredForFinishedArea(true);
  }

  onChangeBasementArea($event) {
    const basementAreaSqFt = $event.target.value ? parseInt($event.target.value) : 0;
    const basementAreaSqFtField = this.form.controls['basementAreaSqFt'];
    if (basementAreaSqFtField) {
      basementAreaSqFtField.setValue(basementAreaSqFt);
      basementAreaSqFtField.markAsDirty();
      this.form.updateValueAndValidity();
    }

    // Default Total Area Size dependent fields to null when Total area is null or == 0
    if(!basementAreaSqFt)
    {
      this.setFieldValueToValue("basementFinishedAreaSqFt", null);
      this.setFieldValueToValue("totalRecreationRoomBelowGradeCount", null);
      this.setFieldValueToValue("totalBedroomBelowGradeCount", null);
      this.setFieldValueToValue("fullAndHalfBathroomBelowGradeCount", null);
      this.onChangeTotalBathroomCount();
      this.setFieldValueToValue("totalOtherRoomBelowGradeCount", null);
    }

    this.configureVisibilityOfTotalAreaSizeDependentFields();
    this.toggleValidatorsForFieldsRequiredForTotalArea();
    this.toggleValidatorsForFieldsRequiredForFinishedArea();
  }

  onChangeOutsideEntryExit($event: MatRadioChange) {
    if ($event.value === false) {
      this.form.controls['foundationBasementExit'].setValue(null);
      this.form.controls['foundationBasementExit'].markAsDirty();
    }
    this.form.controls['foundationOutsideEntryExit'].setValue($event.value);
    this.form.updateValueAndValidity();
  }

  onChangeTotalBathroomCount() {
    const fullAndHalfBathroomBelowGradeCount = this.form.value['fullAndHalfBathroomBelowGradeCount'];
    let totalFullBathroomBelowGradeCount = null;
    let totalHalfBathroomBelowGradeCount = null;
    if (fullAndHalfBathroomBelowGradeCount) {
      const arr = fullAndHalfBathroomBelowGradeCount.split('.');
      totalFullBathroomBelowGradeCount = arr[0];
      totalHalfBathroomBelowGradeCount = arr[1];
    }
    let isInvalid = false;
    if(totalFullBathroomBelowGradeCount?.length > 1) {
      this.form.controls['totalFullBathroomBelowGradeCount'].setValue(null);
      isInvalid = true;
    } else {
      this.form.controls['totalFullBathroomBelowGradeCount'].setValue(totalFullBathroomBelowGradeCount);
    }
    this.form.controls['totalFullBathroomBelowGradeCount'].markAsDirty();

    if(totalHalfBathroomBelowGradeCount?.length > 1) {
      this.form.controls['totalHalfBathroomBelowGradeCount'].setValue(null);
      isInvalid = true;
    } else {
      this.form.controls['totalHalfBathroomBelowGradeCount'].setValue(totalHalfBathroomBelowGradeCount);
    }
    this.form.controls['totalHalfBathroomBelowGradeCount'].markAsDirty();
    this.form.updateValueAndValidity();
    if(isInvalid) {
      setTimeout(() => {
        this.form.controls['fullAndHalfBathroomBelowGradeCount'].setErrors({maxLengthOfNumber: true});
      }, 100);
    }
  }

  onChangeBasementFinishedAreaSqFt($event) {
    this.form.controls['basementFinishedAreaSqFt'].setValue($event.target.value);
    this.form.controls['basementFinishedAreaSqFt'].updateValueAndValidity();
    this.toggleValidatorsForFieldsRequiredForFinishedArea();
    this.form.updateValueAndValidity();
  }

  private setFieldValueToValue(fieldName: string, fieldValue: any) {
    const field = this.form.controls[fieldName];
    if (field) {
      field.setValue(fieldValue);
      field.markAsDirty();
      this.form.updateValueAndValidity();
    }
  }

  private configureVisibilityOfTotalAreaSizeDependentFields(): void {
    const basementAreaSqFt = this.form.value['basementAreaSqFt'] ? parseInt(this.form.value['basementAreaSqFt']) : null;

    // For zero or blank total area hide the finished area unless it is populated (which shouldn't happen unless the BE has a bug).
    const basementFinishedAreaSqFt = this.form.value['basementFinishedAreaSqFt'] ? parseInt(this.form.value['basementFinishedAreaSqFt']) : null;
    this.isShowBasementFinishedAreaSqFt = !!basementAreaSqFt || !!basementFinishedAreaSqFt;

    this.isShowBasementExit = !!basementAreaSqFt;

    // Similarly for the room count fields
    const totalRecreationRoomBelowGradeCount = this.form.value['totalRecreationRoomBelowGradeCount'] ? parseInt(this.form.value['totalRecreationRoomBelowGradeCount']) : null;
    this.isShowTotalRecreationRoomBelowGradeCount = !!basementAreaSqFt || !!totalRecreationRoomBelowGradeCount;

    const totalBedroomBelowGradeCount = this.form.value['totalBedroomBelowGradeCount'] ? parseInt(this.form.value['totalBedroomBelowGradeCount']) : null;
    this.isShowTotalBedroomBelowGradeCount = !!basementAreaSqFt || !!totalBedroomBelowGradeCount;

    const fullAndHalfBathroomBelowGradeCount = this.form.value['fullAndHalfBathroomBelowGradeCount'] ? parseFloat(this.form.value['fullAndHalfBathroomBelowGradeCount']) : null;
    this.isShowFullAndHalfBathroomBelowGradeCount = !!basementAreaSqFt || !!fullAndHalfBathroomBelowGradeCount;

    const totalOtherRoomBelowGradeCount = this.form.value['totalOtherRoomBelowGradeCount'] ? parseInt(this.form.value['totalOtherRoomBelowGradeCount']) : null;
    this.isShowTotalOtherRoomBelowGradeCount = !!basementAreaSqFt || !!totalOtherRoomBelowGradeCount;
  }

  private toggleValidatorsForFieldsRequiredForTotalArea(isInitCall = false) {
    const basementAreaSqFt = this.form?.value?.basementAreaSqFt;
    const isBasementAreaSqFtValid = basementAreaSqFt !== null && basementAreaSqFt !== "" &&
      parseInt(basementAreaSqFt) > 0;

    setTimeout(() => {
        const basementExitField = this.form.controls['basementExit'];
        if(isBasementAreaSqFtValid) {
          basementExitField.setValidators(Validators.required);
          if(!isInitCall) {
            basementExitField.updateValueAndValidity();
          }
        }
        else {
          basementExitField.clearValidators();
          basementExitField.setErrors(null);
        }
      this.form.updateValueAndValidity();
    }, 100);
  }

  private toggleValidatorsForFieldsRequiredForFinishedArea(isInitCall = false) {
    const basementFinishedAreaSqFt = this.form?.value?.basementFinishedAreaSqFt;
    const isBasementFinishedAreaSqFtValid = basementFinishedAreaSqFt !== null && basementFinishedAreaSqFt !== "" &&
      parseInt(basementFinishedAreaSqFt) > 0;

    setTimeout(() => {
      const fieldNames = ['totalRecreationRoomBelowGradeCount', 'totalBedroomBelowGradeCount',
        'fullAndHalfBathroomBelowGradeCount', 'totalOtherRoomBelowGradeCount'];
      fieldNames.forEach(fieldName => {
        const field = this.form.controls[fieldName];
        if(isBasementFinishedAreaSqFtValid) {
          field.setValidators(Validators.required);
          if(!isInitCall) {
            field.updateValueAndValidity();
          }
        }
        else {
          field.clearValidators();
          field.setErrors(null);
        }
      });
      this.form.updateValueAndValidity();
    }, 100);
  }
}
