<ng-container *ngIf="property">
  <div class="row">
    <div class="report-input-form-field col-md-12 mt-15">
      <span class="report-input-label">Sale/Transfer History Researched Done</span>
      <span class="report-input-value">
        {{ property.isSaleOrTransferHistoryResearchedDone | yesNoFormat | defaultValue }}
      </span>
    </div>

    <div
      class="report-input-form-field col-md-12 mt-15"
      *ngIf="property.isSaleOrTransferHistoryResearchedDone === false"
    >
      <span class="report-input-label">Reason</span>
      <span class="report-input-value">
        {{ property.saleOrTransferHistoryResearchedDoneReason | defaultValue }}
      </span>
    </div>

    <div class="report-input-form-field col-md-12 mt-15">
      <span class="report-input-label">Analysis of Prior Sale or Transfer History</span>
      <span class="report-input-value">
        {{ property.analysisOfPriorSaleOrTransferHistory | defaultValue }}
      </span>
    </div>

    <div class="report-input-form-field col-md-12 mt-15">
      <span class="report-input-label">Summary of Sales Comparison Approach</span>
      <span class="report-input-value">
        {{ property.salesComparisonApproachSummary | defaultValue }}
      </span>
    </div>
    
  </div>
</ng-container>
