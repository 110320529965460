import { Component, EventEmitter, Input, Output } from '@angular/core';
import { ReportDto } from '@proxy/appraisal/report/v1/form1004';
import { ReportVersionWithLinksDto } from '@proxy/bff/activity/reports/v1';
import { ReportInputTemplateSectionModel } from '../../models/report-input-layout.model';

@Component({
  selector: 'jaro-kit-section-list',
  templateUrl: './section-list.component.html',
  styleUrls: ['./section-list.component.scss'],
})
export class SectionListComponent {
  @Input() sections: ReportInputTemplateSectionModel[];
  @Input() reportDto: ReportDto;
  @Input() timeZoneId: string;
  @Input() orderAssignmentId: string;
  @Input() boostEnabled: boolean;
  @Input() feature: string;
  @Input() reportVersionDto: ReportVersionWithLinksDto;

  @Output() onSaveSectionForm = new EventEmitter<any>();

  ngOnInit(): void {
  }
  saveSectionForm(reportInputDto) {
    this.onSaveSectionForm.emit(reportInputDto);
  }
}
