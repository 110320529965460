import { Component, Inject, OnInit } from '@angular/core';
import { FormGroup } from '@angular/forms';
import {
  ReportInputTemplateField,
  ReportInputTemplateSubSectionModel,
} from 'src/app/features/shared/forms/models/report-input-layout.model';
import InjectionSymbol from 'src/app/shared/injection/injection-symbol';
import { CacheService } from 'src/app/shared/services/cache.service';

@Component({
  selector: 'halo-global-amenities-view-mode',
  templateUrl: './amenities-view-mode.component.html',
  styleUrls: ['./amenities-view-mode.component.scss'],
})
export class AmenitiesViewModeComponent implements OnInit {
  form: FormGroup;
  subSection: ReportInputTemplateSubSectionModel;
  otherField: ReportInputTemplateField;
  amenities = [];

  constructor(
    @Inject(InjectionSymbol.CacheService)
    private cacheService: CacheService
  ) {}

  getFormFieldConfig(formControlName: string) {
    return this.subSection.fields.find((item) => item.formControlName === formControlName);
  }

  ngOnInit(): void {
    this.otherField = this.getFormFieldConfig('additionalOtherAmenitiesDescription');
    this.cacheService
      .checkAndGetJsonFile('/assets/json/template/report/1004/amenities.config.json')
      .subscribe((data: any) => {
        this.amenities = data;
      });
  }
}
