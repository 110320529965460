import { Component, Inject, OnInit } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { MatRadioChange } from '@angular/material/radio';
import { ContentServiceInterface } from 'src/app/interface/content/content-service-interface';
import { ItemData } from 'src/app/shared/components/multiple-combo-box/multiple-combo-box.component';
import InjectionSymbol from 'src/app/shared/injection/injection-symbol';
import { AddressStateTranslations } from 'src/app/shared/pipes/translate-data/address-state.prototype';
import { ReportInputTranslationModel } from 'src/app/shared/pipes/translate-data/translate-data.prototype';

@Component({
  selector: 'jaro-kit-subject-section1004',
  templateUrl: './subject-section1004.component.html',
  styleUrls: ['./subject-section1004.component.scss'],
})
export class SubjectSection1004Component implements OnInit {
  form: FormGroup;
  addressStateOptions: ReportInputTranslationModel[] = AddressStateTranslations;
  occupantOptions: ItemData[] = [];
  rightsAppraisedOptions: ItemData[] = [];

  isShowHOADetails: boolean;  
  isShowRightsAppraisedOther: boolean;
  isShowAssignmentTypeDescribe: boolean;

  constructor(
    @Inject(InjectionSymbol.ContentService)
    public contentService: ContentServiceInterface
  ) {
    this.occupantOptions = this.contentService.getContentByKey('occupancy.occupant');
    this.rightsAppraisedOptions = this.contentService.getContentByKey(
      'propertyRightAppraised',
      true
    );

  }

  ngOnInit(): void {
    this.isShowHOADetails = this.form.controls['subjectAssociation'].value?.toString() === 'true';
    this.isShowRightsAppraisedOther = this.form.value.subjectRightsAppraised === 'other';
    this.isShowAssignmentTypeDescribe = this.form.value.subjectAssignmentType === 'other';
  }

  onChangeRightsAppraised($event: MatRadioChange) {
    if ($event.value !== 'other') {
      this.form.controls['subjectRightsAppraisedOther'].setValue(null);
      this.form.controls['subjectRightsAppraisedOther'].markAsDirty();
      this.form.updateValueAndValidity();
    }
    this.isShowRightsAppraisedOther = $event.value === 'other';
  }

  onChangeSubjectPUD($event): void {
    const subjectHomeownerAssociation = this.form.controls['subjectHomeownerAssociation'];
    const subjectFrequency = this.form.controls['subjectFrequency'];

    subjectHomeownerAssociation.setValue(null);
    subjectHomeownerAssociation.markAsDirty();
    subjectFrequency.setValue(null);
    subjectFrequency.markAsDirty();
    this.form.updateValueAndValidity();
    this.isShowHOADetails = $event.value?.toString() === 'true';
  }
}
