import { Component, Input } from '@angular/core';
import { SubjectPropertyDto } from '@proxy/appraisal/report/reports/v1/form1004';

@Component({
  selector: 'jaro-kit-commentaries-edit-mode',
  templateUrl: './commentaries-edit-mode.component.html',
  styleUrls: ['./commentaries-edit-mode.component.scss'],
})
export class CommentariesEditModeComponent {
  @Input() property: SubjectPropertyDto;

  onChangeSaleOrTransferHistoryResearchedDone($event) {
    if ($event.value === false) {
      this.property.saleOrTransferHistoryResearchedDoneReason = null;
    }
  }
}
