<ng-container *ngIf="form" [formGroup]="form">
  <div class="row">
    <div class="col-md-6 pl-0 pr-15">
      <div class="mt-15">
        <halo-global-multiple-combo-box
          label="Appliances"
          placeholder="New Appliances..."
          [options]="appliancesOptions"
          [(value)]="appliances"
          [formGroup]="form"
          formControlName="additionalAppliances"
        >
        </halo-global-multiple-combo-box>
      </div>
      <p class="report-input-title pb-15">Functional Utility</p>
      <mat-form-field appearance="outline">
        <mat-label>Functional Utility</mat-label>
        <input
          matInput
          formControlName="additionalFunctionalUtility"
          autocomplete="off"
        />
      </mat-form-field>
    </div>

    <div class="col-md-6 pl-15 pr-0">
      <mat-form-field appearance="outline" class="jaro-kit-textarea">
        <mat-label>Additional Features</mat-label>
        <textarea
          type="text"
          formControlName="additionalFeatures"
          placeholder="Additional Features"
          matInput
          rows="4"
        ></textarea>
      </mat-form-field>

      <mat-form-field appearance="outline">
        <mat-label>Energy Efficient Items</mat-label>
        <input
          matInput
          id="additionalEnergyEfficientItems"
          formControlName="additionalEnergyEfficientItems"
          autocomplete="off"
        />
      </mat-form-field>
      <jaro-kit-validation-message [form]="form" controlName="additionalEnergyEfficientItems" [isRequired]="true"></jaro-kit-validation-message>
    </div>
  </div>
</ng-container>
