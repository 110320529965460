import { Component, Input, OnInit } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { ReportDto } from '@proxy/appraisal/report/v1/form1004';
import {
  ReportInputFieldType,
  ReportInputTemplateField,
  ReportInputTemplateSectionModel,
  ReportInputTemplateSubSectionModel,
} from '../../../features/shared/forms/models/report-input-layout.model';
@Component({
  selector: 'jaro-kit-report-input-label',
  templateUrl: './report-input-label.component.html',
  styleUrls: ['./report-input-label.component.scss'],
})
export class ReportInputLabelComponent implements OnInit {
  @Input() field: ReportInputTemplateField;
  @Input() timeZoneId: string;
  @Input() reportInputForm: FormGroup;
  @Input() reportDto: ReportDto;
  @Input() section: ReportInputTemplateSectionModel;
  @Input() subSection: ReportInputTemplateSubSectionModel;
  @Input() feature: string;

  reportInputFieldType = ReportInputFieldType;

  get isEmptyValue() {
    return this.field.type !== ReportInputFieldType.Custom && (
      this.field.value === null ||
      this.field.value === undefined ||
      this.field.value === '' ||
      this.field.value?.length == 0
    );
  }

  get isWhitespaceLabel() {
    return this.field?.type !== ReportInputFieldType.Custom &&
       this.field?.label !== undefined &&
       this.field?.label !== '' &&
       this.field?.value?.length !== 0 &&
       (this.field?.label as string)?.trim().length === 0;    
  }

  ngOnInit(): void {
    this.field.value = this.field.value;
  }
}
