import { FormGroup } from '@angular/forms';
import { ReportInputDataFormFieldModel } from 'src/app/features/shared/forms/models/report-input-data-field-form-model/report-input-date-field-form-model';
import { YesNoFormatPipe } from 'src/app/shared/pipes/yes-no-format/yes-no-format.pipe';

export const AssignmentTemplateConfigCommon: ReportInputDataFormFieldModel[] = [
  {
    formControlName: 'certificationPropertyInspected',
    format: {
      pipeType: YesNoFormatPipe,
    },
    events: [
      {
        key: 'change',
        action: ($event, form: FormGroup) => {
          if ($event.value === false) {
            form.controls['certificationInspectionDate'].setValue(null);
            form.controls['certificationInspectionDate'].markAsDirty();
            form.controls['certificationInspectionType'].setValue(null);
            form.controls['certificationInspectionType'].markAsDirty();
            form.updateValueAndValidity();
          }
        }
      }
    ]
  },
  {
    formControlName: 'certificationComparableSalesInspected',
    format: {
      pipeType: YesNoFormatPipe,
      pipeArgs: ['Yes, exterior', 'No'],
    },
    events: [
      {
        key: 'change',
        action: ($event, form: FormGroup) => {
          if ($event.value === false) {
            form.controls['certificationComparableSalesInspectionDate'].setValue(null);
            form.controls['certificationComparableSalesInspectionDate'].markAsDirty();
            form.updateValueAndValidity();
          }
        }
      }
    ]
  },
  {
    formControlName: 'subjectAssignmentType',
    mapToFieldFunc: (value) => {
      const options = ['purchase', 'refinance'];
      if (value === '' || (value && !options.includes(value.toLowerCase()))) {
        return 'other';
      }
      return value && typeof value === 'string' ? value.toLowerCase().trim() : value;
    },
    events: [
      {
        key: 'change',
        action: ($event, form: FormGroup) => {
          if ($event.value !== 'other') {
            form.controls['subjectAssignmentTypeDescription'].setValue(null);
            form.controls['subjectAssignmentTypeDescription'].markAsPristine();
            form.updateValueAndValidity();
          }
        }
      }
    ]
  },
  {
    formControlName: 'subjectAssignmentTypeDescription',
    mapToFieldFunc: (value) => {
      const options = ['purchase', 'refinance', 'other'];
      return value && options.includes(value.toLowerCase()) ? '' : value;
    },
  },
  {
    formControlName: 'uspap_areTherePerformedServicesLast3Years',
    format: {
      pipeType: YesNoFormatPipe,
    },
  }
];

export const AssignmentTemplateFormFieldConfigs: ReportInputDataFormFieldModel[] = [
  ...AssignmentTemplateConfigCommon,
];
