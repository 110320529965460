import { Component, Input, OnInit } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { ReportInputTemplateField } from 'src/app/features/shared/forms/models/report-input-layout.model';

@Component({
  selector: 'halo-global-amenity-card-view-mode',
  templateUrl: './amenity-card-view-mode.component.html',
  styleUrls: ['./amenity-card-view-mode.component.scss'],
})
export class AmenityCardViewModeComponent implements OnInit {
  @Input() form: FormGroup;
  @Input() icon: string;
  @Input() description: string;
  @Input() controlName: string;
  @Input() descriptionControlName: string;
  @Input() field: ReportInputTemplateField;
  fields: ReportInputTemplateField[];

  value: string;
  descriptionValue: string;

  ngOnInit(): void {
    this.value = this.form.value[this.controlName];
    this.descriptionValue = this.form.value[this.descriptionControlName];
  }
}
