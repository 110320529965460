import { Confirmation } from '@abp/ng.theme.shared';
import { Component, ElementRef, EventEmitter, Input, Output, ViewChild } from '@angular/core';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { DocumentUploadModalComponent } from 'src/app/features/documents/document-upload-modal/document-upload-modal.component';
import { FileUploading } from 'src/app/shared/services/file-uploading';
import { FilesUploaderService } from 'src/app/shared/services/files-uploader.service';
import { MessageService } from 'src/app/shared/services/message.service';
import { DocumentSettings } from './document-settings';
import { DocumentUploadedEvent } from './document-uploaded-event';

@Component({
  selector: 'jaro-kit-upload-document',
  templateUrl: './upload-document.component.html',
  styleUrls: ['./upload-document.component.scss'],
})
export class UploadDocumentComponent {
  @ViewChild('fileInput') fileInput: ElementRef;

  @Input() orderAssignmentId: string;
  @Input() fileTypeAccept: string;
  @Input() isMultiple: boolean = false;
  @Input() maximumOfFileToUpload: number = 10;

  @Input() documentSettings: DocumentSettings;

  @Input() documentsUploading: FileUploading[] = [];

  @Output() onFileUploadCompleted = new EventEmitter<DocumentUploadedEvent>();

  filesUploading: FileUploading[] = [];
  isUploading: boolean = false;
  fileType: string = '';
  get typeAccept(): string {
    switch (this.fileTypeAccept) {
      case 'image':
        return 'image/*';
      case 'map':
        return 'application/pdf, image/png, image/jpeg, image/jpg';
      case 'pdf':
        return 'application/pdf';
      default:
        return '*';
    }
  }

  constructor(
    private filesUploaderService: FilesUploaderService,
    private msgService: MessageService,
    public matDialog: MatDialog,
  ) {}


  uploadFileClick() {
    this.fileInput.nativeElement.dispatchEvent(new MouseEvent('click'));
  }

  public uploadFiles(files: FileList) {
    if (files && files.length > 0) {
      const fileUploads = this.getFileUploads(files);
      this.filesUploading = fileUploads;
      this.filesUploading.forEach(i => this.documentsUploading.push(i));

      this.filesUploaderService.onUploadingCompletion.subscribe({
        next: (filesUploaded: FileUploading[]) => {
          this.filesUploaderService.onUploadingCompletion.unsubscribe();
          this.filesUploaderService.resetEvent();
          this.documentsUploading.splice(0, this.documentsUploading.length);
          this.isUploading = false;
          filesUploaded.forEach(element => {
            this.onFileUploadCompleted.emit(
              {
                documentId: element.docId,
                title: element.context.title,
                source: element.context.source,
                fileType: element.context.fileType,
                documentCategory: element.context.documentCategory,
                labels: element.context.labels
              });
          });
        },
        error: (err) => this.logActionError(err),
      });

      this.isUploading = true;
      for(let i = 0; i < fileUploads.length; i++){
        let fileUpload = fileUploads[i];
        if(this.documentSettings){
          fileUpload.context = this.documentSettings;
          this.filesUploaderService.uploadFiles([fileUpload], this.orderAssignmentId, this.documentSettings.title, this.documentSettings.source, this.documentSettings.fileType, this.documentSettings.documentCategory, this.documentSettings.labels);
        }
        else{
          // show dialog to capture settings
          const dialogConfig = new MatDialogConfig();
          dialogConfig.data = {
            settings: {
              filename: fileUpload.file.name,
              documentCategory: '',
              source: 'User',
              fileType: 'document',
              title: this.getTitle(fileUpload.file),
              labels: []
            } as DocumentSettings
          };
          dialogConfig.panelClass = 'document-settings-modal';
          let dialogRef = this.matDialog.open(DocumentUploadModalComponent, dialogConfig);
          dialogRef.afterClosed().subscribe({
            next: (response: DocumentSettings | boolean) => {
              if (response && typeof response === 'object') {
                fileUpload.context = response;
                this.filesUploaderService.uploadFiles([fileUpload], this.orderAssignmentId, response.title, response.source, response.fileType, response.documentCategory, response.labels);
              } else {
                // The user has closed the dialog.
                this.isUploading = false;
              }
            },
          });
        }

      }
    }
    this.fileInput.nativeElement.value = null;
  }

  private getFileUploads(files: FileList): FileUploading[] {
    const fileUploads = Array.from(files)
      .filter((file) => this.checkFileType(file))
      .map((file: File) => {
        const fileUpload = {
          file: file,
          isUploading: false,
          progress: 0,
          isSuccessful: false,
          docId: '',
          error: '',
          localUrl: '',
          fileName: file.name
        } as FileUploading;
        const reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = (_event) => {
          fileUpload.localUrl = reader.result;
        };
        return fileUpload;
      });
    return this.isMultiple
      ? fileUploads.splice(0, this.maximumOfFileToUpload)
      : fileUploads.splice(0, 1);
  }


  private logActionError(err: any): void {
    this.isUploading = false;
    this.msgService.error(err).subscribe((_status: Confirmation.Status) => {});
  }

  private checkFileType(file: File): boolean {
    if (this.fileTypeAccept) {
      let allowedFileExtensionRegexes = [];
      switch (this.fileTypeAccept) {
        case 'image':
          allowedFileExtensionRegexes.push(/image\//);
          break;
        case 'map':
          allowedFileExtensionRegexes.push(/image\//);
          allowedFileExtensionRegexes.push(/(application\/pdf)$/i);
          break;
        case 'pdf':
          allowedFileExtensionRegexes.push(/(application\/pdf)$/i);
          break;
        default:
          return true; // Return true as there is no file type checking required.
      }

      for (let regex of allowedFileExtensionRegexes) {
        if (regex.exec(file.type)) {
          return true;
        }
      }
    }
    return false;
  }

  private getTitle(file: File): string {
    const fileName = file.name;
    return fileName.substring(0, fileName.lastIndexOf('.'));
  }
}




