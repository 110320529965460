import { COMMA, ENTER } from '@angular/cdk/keycodes';
import {
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnInit,
  Output,
  ViewChild,
} from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { MatAutocompleteTrigger } from '@angular/material/autocomplete';
import { MatChipInputEvent } from '@angular/material/chips';
import { Observable } from 'rxjs';
import { map, startWith } from 'rxjs/operators';
export interface ItemData {
  name: string;
  value: string;
  selected: boolean;
}

@Component({
  selector: 'halo-global-multiple-combo-box',
  templateUrl: './multiple-combo-box.component.html',
  styleUrls: ['./multiple-combo-box.component.scss'],
})
export class MultipleComboBoxComponent implements OnInit {
  @ViewChild('autoCompleteInput') searchText: ElementRef<HTMLInputElement>;

  @ViewChild('autoCompleteInput', { read: MatAutocompleteTrigger })
  autoCompleteInput: MatAutocompleteTrigger;

  @Input() formGroup: FormGroup;
  @Input() formControlName: string;
  @Input() label: string = '';
  @Input() placeholder: string = '';
  @Input() options: Array<ItemData> = [];
  @Input() value: Array<string> = [];
  @Input() isAllowAddNewItem: boolean = true;


  @Output() result = new EventEmitter<Array<string>>();

  filterControl = new FormControl();
  rawData: Array<ItemData> = [];
  selectData: Array<ItemData> = [];
  filteredData: Observable<Array<ItemData>>;
  filterString: string = '';
  separatorKeyCodes: number[] = [ENTER, COMMA];
  isOpenDropdown: boolean = false;
  isTouched: boolean;
  isFocus: boolean;

  constructor() {
    this.filteredData = this.filterControl.valueChanges.pipe(
      startWith<string>(''),
      map((value) => (typeof value === 'string' ? value : this.filterString)),
      map((filter) => this.filter(filter))
    );
  }

  ngOnInit(): void {
    setTimeout(() => {
      this.setDefaultValue();
    }, 0);
  }

  private setDefaultValue() {
    if ((!this.value || this.value.length === 0) && this.formGroup) {
      this.value = this.formGroup.value[this.formControlName];
    }
    this.rawData = [...this.options];
    this.value = this.value && Array.isArray(this.value) ? this.value : [];
    this.value.forEach((item) => {
      const option = this.options.find(
        (option) => option.value.toLowerCase() === item.toLowerCase()
      );
      this.selectData.push({
        name: option ? option.name : item,
        value: item,
        selected: true,
      });
    });
    this.clearFilter();
  }

  addItem(event: MatChipInputEvent): void {
    const value = (event.value || '').trim();
    const selectedItem = this.selectData.find(
      (item) => item.value.toLowerCase() === value.toLowerCase()
    );
    if (value && !selectedItem && this.isAllowAddNewItem) {
      const item = {
        name: value,
        value: value,
        selected: true,
      };

      this.selectNewItem(item);
    }

    // Clear the input value
    event.chipInput.clear();
    this.clearFilter();

    this.updateValueAndEmitAdjustedData();
  }

  filter = (filter: string): Array<ItemData> => {
    let filerOption = [];
    this.filterString = filter;
    if (filter.length > 0) {
      filerOption = this.rawData.filter((option) => {
        return option.value.toLowerCase().indexOf(filter.toLowerCase()) >= 0;
      });
    } else {
      filerOption = this.rawData.slice();
    }

    return filerOption.map((option) => {
      option.selected = !!this.selectData.find(
        (item) => item.value.toLowerCase() === option.value.toLowerCase()
      );
      return option;
    });
  };

  displayFn = (): string => '';

  optionClicked = (event: Event, data: ItemData): void => {
    event.stopPropagation();
    this.toggleChip(data);
  };

  toggleChip = (data: ItemData): void => {
    this.isTouched = true;
    data.selected = !data.selected;
    if (data.selected === true) {
      this.selectNewItem(data);
    } else {
      const i = this.selectData.findIndex(
        (item) => item.value.toLowerCase() === data.value.toLowerCase()
      );
      this.selectData.splice(i, 1);
    }

    this.clearFilter();
    this.updateValueAndEmitAdjustedData();
  };

  private selectNewItem(data: ItemData) {
    if (data.value === 'none') {
      // Uncheck selected option
      this.selectData = [];
    } else {
      // Uncheck none option
      const noneIndex = this.selectData.findIndex((item) => item.value.toLowerCase() === 'none');
      if (noneIndex > -1) {
        this.selectData.splice(noneIndex, 1);
      }
    }
    this.selectData.push(data);
  }

  private clearFilter() {
    this.filterString = '';
    this.searchText.nativeElement.value = '';
    this.filterControl.setValue(null);
  }

  private updateValueAndEmitAdjustedData = (): void => {
    const results: Array<string> = [];
    this.selectData.forEach((data: ItemData) => {
      results.push(data.value);
    });
    this.value = results;
    this.result.emit(results);

    if (this.formGroup) {
      const field = this.formGroup.controls[this.formControlName];
      if (field) {
        field.setValue(results);
        field.markAsDirty();
      }
      this.formGroup.updateValueAndValidity();
    }
  };

  openedAutoComplete() {
    this.isOpenDropdown = true;
    this.isTouched = true;
    this.isFocus = true;
  }

  closedAutoComplete() {
    this.isOpenDropdown = false;
    this.isFocus = false;
  }

  openAutoComplete(event: Event) {
    event.stopPropagation();
    this.autoCompleteInput.openPanel();
    this.isTouched = true;
    this.isFocus = true;
  }

  closeAutoComplete(event: Event) {
    event.stopPropagation();
    this.autoCompleteInput.closePanel();
    this.isFocus = false;
  }
}
