import { Confirmation } from '@abp/ng.theme.shared';
import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { AlertType } from 'src/app/shared/providers/alert-type/alert-type';

@Component({
  selector: 'jaro-kit-alert',
  templateUrl: './alert.component.html',
  styleUrls: ['./alert.component.scss'],
})
export class AlertComponent {
  constructor(
    public dialogRef: MatDialogRef<AlertComponent>,
    @Inject(MAT_DIALOG_DATA)
    public data: {
      type?: AlertType;
      title?: string;
      message?: string;
    }
  ) {}

  closeDialog() {
    this.dialogRef.close(Confirmation.Status.dismiss);
  }
}
