<div [ngClass]="{ 'photo-container': true, 'section-photo-container': isOpenByReportPhoto }">
  <img
    [src]="photo.smallSizedUrl"
    class="photo"
    *ngIf="!isFileNotAvailable"
    (click)="clickOnPhoto(photo)"
    alt="photo"
    (error)="isFileNotAvailable = true"
  />
  <div *ngIf="isFileNotAvailable" class="photo-failed-to-load photo" (click)="clickOnPhoto(photo)">

  </div>
  <div class="tag-photo-container">
    <p class="tag-cover" *ngIf="photo.isCoverPhoto">
      <em class="fa fa-pencil" aria-hidden="true"></em> Cover
    </p>
    <p
      [ngClass]="{ 'tag-source': true, 'tag-source-upload': photo.source === 3 }"
      *ngIf="photo.source"
    >
      {{ photo.source | PhotoSourcePipe }}
    </p>
    <em
      class="fa fa-check-circle"
      aria-hidden="true"
      *ngIf="photo.isInReport && !isOpenByReportPhoto"
    ></em>
  </div>
</div>
