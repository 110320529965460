<halo-global-popup [dialogRef]="dialogRef" [isHideClose]="true">
  <div
    [ngClass]="{
      'alert-container': true,
      'error-alert-container': data.type === 'error',
      'notify-alert-container': data.type === 'notify',
      'success-alert-container': data.type === 'success'
    }"
  >
    <em
      [ngClass]="{
        'fa-regular': true,
        'fa-circle-exclamation': data.type === 'error',
        'fa-circle-info': data.type === 'notify',
        'fa-circle-check': data.type === 'success'
      }"
    ></em>
    <div class="alert-detail">
      <p class="alert-title">{{ data.title }}</p>
      <p class="alert-message">{{ data.message }}</p>
    </div>
    <em class="fa-regular fa-xmark-large" (click)="closeDialog()"></em>
  </div>
</halo-global-popup>
