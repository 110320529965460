import { Component, Input, OnInit } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { ReportDto } from '@proxy/appraisal/report/v1/form1004';
import { ReportVersionWithLinksDto } from '@proxy/bff/activity/reports/v1';
import {
  ReportInputTemplateField,
  ReportInputTemplateSectionModel,
  ReportInputTemplateSubSectionModel,
} from 'src/app/features/shared/forms/models/report-input-layout.model';

export class FieldGroup {
  public spanAllField?: ReportInputTemplateField;
  public leftFields: ReportInputTemplateField[];
  public middleFields: ReportInputTemplateField[];
  public rightFields: ReportInputTemplateField[];
}

@Component({
  selector: 'jaro-kit-view-mode-sub-section',
  templateUrl: './view-mode-sub-section.component.html',
  styleUrls: ['./view-mode-sub-section.component.scss'],
})
export class ViewModeSubSectionComponent implements OnInit {
  @Input() section: ReportInputTemplateSectionModel;
  @Input() subSection: ReportInputTemplateSubSectionModel;
  @Input() reportDto: ReportDto;
  @Input() timeZoneId: string;
  @Input() reportInputSectionForm: FormGroup;
  @Input() orderAssignmentId: string;
  @Input() feature: string;
  @Input() reportVersionDto: ReportVersionWithLinksDto;

  public fieldGroups: FieldGroup[]

  ngOnInit(): void {
    this.fieldGroups = this.getFieldGroups();
  }

  public resetFieldGroups(){
    this.fieldGroups = this.getFieldGroups();
  }

  private getFieldGroups(): FieldGroup[] {
    const groupList: FieldGroup[] = [];
    let group: FieldGroup = null;

    const visibleFields = this.getVisibleFields();
    for(let field of visibleFields) {
      group = group || {
        spanAllField: null,
        leftFields: [],
        middleFields: [],
        rightFields: []
      } as FieldGroup;

      if(this.isSpanAllColumnsField(field)) {
        if(group.spanAllField === null) {
          group.spanAllField = field;
        }
        else {
          groupList.push(group);
          group = {
            spanAllField: field,
            leftFields: [],
            middleFields: [],
            rightFields: []
          } as FieldGroup;
        }
        continue;
      }

      if(this.isLeftField(field)) {
        group.leftFields.push(field);
        continue;
      }

      if(this.isMiddleField(field)) {
        group.middleFields.push(field);
        continue;
      }

      if(this.isRightField(field)) {
        group.rightFields.push(field);
      }
    }

    if(group !== null) {
      groupList.push(group);
    }

    return groupList;
  }

  private getVisibleFields(): ReportInputTemplateField[] {
    return this.subSection.fields.filter((field) => field.isVisible) || [];
  }

  // Fields with layout hint 'span-all-columns' and no clashing layout hint
  private isSpanAllColumnsField(field: ReportInputTemplateField): boolean {
    return (!this.isClashingLayoutHint(field.layoutHint, 'span-all-columns') &&
              field.layoutHint?.includes('span-all-columns') === true);
  }

  // Fields with no matching layout hint i.e. the default layout is 'left-column',
  // however for clashes no layout is applied so that this is picked up in dev testing
  // Or has a layout hint 'left-column' and no clashing layout hint
  private isLeftField(field: ReportInputTemplateField): boolean {
    return !this.isClashingLayoutHint(field.layoutHint, 'left-column');
  }

  // Fields with layout hint 'middle-column' and no clashing layout hint
  private isMiddleField(field: ReportInputTemplateField): boolean {
    return (!this.isClashingLayoutHint(field.layoutHint, 'middle-column') &&
              field.layoutHint?.includes('middle-column') === true);
  }

  // Fields with layout hint 'right-column' and no clashing layout hint
  private isRightField(field: ReportInputTemplateField): boolean {
    return (!this.isClashingLayoutHint(field.layoutHint, 'right-column') &&
              field.layoutHint?.includes('right-column') === true);
  }

  // If there is no bootstrap css col class then add default classes.
  getLayoutHintField(field: ReportInputTemplateField) {
    return field.layoutHint?.includes('col-') === true
      ? field.layoutHint
      : `col-lg-12 col-md-12 ${field.layoutHint}`;
  }

  // Checks if the layout hint for the rule clashes with another
  // known layout hint inside the layout hint attribute for the field
  //
  // On checking the list of known layout hints is first filtered to
  // remove the rule layout hint so as to not check for a clash against
  // the same layout hint.
  isClashingLayoutHint(fieldLayoutHint: string, ruleLayoutHint: string) {
    fieldLayoutHint = fieldLayoutHint?.toLowerCase() ?? '';
    ruleLayoutHint = ruleLayoutHint?.toLowerCase() ?? '';

    return [ 'left-column', 'middle-column', 'right-column', 'span-all-columns' ]
              .filter(hint => ruleLayoutHint !== hint)
              .some(hint => fieldLayoutHint.indexOf(hint) >= 0);
  }
}
