export class FacetScoreModel {
    title: string;

    // Not nullable as otherwise the score would not be included in the facet filter.
    frequency: number;

    // Default to not selected.
    isSelected: boolean = false;    

    constructor(title: string, frequency: number)
    {
        this.title = title;
        this.frequency = frequency;
    }
}