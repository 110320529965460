import { ReportInputDataFormFieldModel } from 'src/app/features/shared/forms/models/report-input-data-field-form-model/report-input-date-field-form-model';
import { JaroBoostTemplateConfigOrderDetailsSection } from './jaro-boost-template-config-order-details-section.prototype';
import { JaroBoostTemplateConfigSubjectSection } from './jaro-boost-template-config-subject-section.prototype';

export const JaroBoostTemplateConfigCommon: ReportInputDataFormFieldModel[] = [  
];

export const JaroBoostTemplateFormFieldConfigs: ReportInputDataFormFieldModel[] = [
  ...JaroBoostTemplateConfigOrderDetailsSection, 
  ...JaroBoostTemplateConfigSubjectSection,
  ...JaroBoostTemplateConfigCommon,
];
