import { CdkDragDrop, moveItemInArray } from '@angular/cdk/drag-drop';
import { Component, HostListener, Inject, OnInit, ViewChildren } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { ComparablesAggregateDto } from '@proxy/appraisal/comparables/comparables/v1/models';
import { ReportVersionDto } from '@proxy/appraisal/report/reports/v1';
import {
  BulkPartialComparableUpdateDto,
  BulkPartialComparableUpdateItemDto,
  PartialUpdateReportDto,
} from '@proxy/appraisal/report/v1';
import { ReportPhotoWithThumbnailDto } from '@proxy/bff/activity/report-photos/v1';
import {
  ReportComparableWithThumbnailDto,
  ReportVersionWithLinksDto,
} from '@proxy/bff/activity/reports/v1';
import { PropertyRecordDto } from '@proxy/property/property-record/property-records/v1';
import { cloneDeep } from 'lodash-es';
import { Subject, debounceTime, fromEvent, map } from 'rxjs';
import { ComparablesGridCellComponent } from 'src/app/features/comparables/comparables-grid/comparables-grid-cell/comparables-grid-cell.component';
import { MarketModalRequest } from 'src/app/features/market/market-modal/market-modal-request';
import { MarketModalResult } from 'src/app/features/market/market-modal/market-modal-result';
import { MarketCleanupPropertyModalRequest } from 'src/app/features/market/market-shared/market-cleanup-property-modal/market-cleanup-property-modal-request';
import { MarketCleanupPropertyModalResult } from 'src/app/features/market/market-shared/market-cleanup-property-modal/market-cleanup-property-modal-result';
import { MarketCleanupPropertyModalComponent } from 'src/app/features/market/market-shared/market-cleanup-property-modal/market-cleanup-property-modal.component';
import { MarketModalComponent } from 'src/app/features/market/market-shared/market-modal.component';
import { ComparablesServiceInterface } from 'src/app/interface/appraisal/comparables/comparables-service-interface';
import { ReportComparableServiceInterface } from 'src/app/interface/appraisal/report/comparables/report-comparable-service-interface';
import { ReportServiceInterface } from 'src/app/interface/appraisal/report/report-service-interface';
import { ReportInputPhotoSectionServiceInterface } from 'src/app/interface/bff/activity/report-input-photo-section-interface.service';
import { ReportsServiceInterface } from 'src/app/interface/bff/activity/reports-service-interface';
import { PropertyRecordServiceInterface } from 'src/app/interface/property/property-job/property-record-service-interface';
import {
  ReportComparableFieldConfig,
  ReportComparableFieldType,
  ReportComparableModel,
} from 'src/app/proxy-adapter/report/comparables/models';
import { FeatureNames } from 'src/app/shared/enums/report-input.enum';
import InjectionSymbol from 'src/app/shared/injection/injection-symbol';
import { EventService } from 'src/app/shared/services/event.service';
import { MessageService } from 'src/app/shared/services/message.service';

@Component({
  selector: 'jaro-kit-comparables-grid',
  templateUrl: './comparables-grid.component.html',
  styleUrls: ['./comparables-grid.component.scss'],
})
export class ComparablesGridComponent implements OnInit {
  readonly SubjectColumnIndex = 0;

  horizontalColumns: ReportComparableFieldConfig[];
  dataSource: ReportComparableModel[];
  visibleDataSource: ReportComparableModel[];
  verticalRowIndex: string[];
  isLoading: boolean;
  rowSelected: string;
  comparableFormGroup: FormGroup;
  reportVersionDto: ReportVersionWithLinksDto;
  orderAssignmentId: string;
  isViewMore: boolean = true;
  isVisible: boolean = true;
  feature = FeatureNames.Comparables;
  isExpanded: boolean;

  comparableViewModelMap: Map<string, Record<string, string>> = new Map();
  comparableUpdateRecordMap: Map<string, Record<string, string>> = new Map();
  lastComparableSavedRecordMap: Map<string, Record<string, string>> = new Map();
  private saveComparableAction = new Subject<string>();

  startComp: number = 0;
  endComp: number = 0;
  totalComp: number = 0;
  measuredGridWidth: number = window.innerWidth;
  lastScrollTopPosition: number;
  readonly widthOfComparable = 400;
  widthOfPadding = 70;
  @ViewChildren(ComparablesGridCellComponent) children;

  @HostListener('window:resize', ['$event'])
  getScreenSize(_event?) {
    this.measuredGridWidth = window.innerWidth;
    this.calculateEndComp();
  }

  constructor(
    @Inject(InjectionSymbol.ReportComparableService)
    private reportComparableService: ReportComparableServiceInterface,
    @Inject(InjectionSymbol.ReportService) private reportService: ReportServiceInterface,
    @Inject(InjectionSymbol.ReportInputPhotoSectionService)
    public reportInputPhotoSectionService: ReportInputPhotoSectionServiceInterface,
    @Inject(InjectionSymbol.ActivityReportsService)
    public activityReportsService: ReportsServiceInterface,
    @Inject(InjectionSymbol.PropertyRecordService)
    private propertyRecordService: PropertyRecordServiceInterface,
    @Inject(InjectionSymbol.ComparableService)
    private comparableService: ComparablesServiceInterface,
    private msgService: MessageService,
    public matDialog: MatDialog,
    @Inject(InjectionSymbol.EventService)
    private eventService: EventService
  ) {}

  ngAfterViewInit() {
    this.calculateChildrenAdjustmnentTotals();
    this.children.changes.subscribe((_value) => {
      this.calculateChildrenAdjustmnentTotals();
    });
  }

  calculateChildrenAdjustmnentTotals() {
    this.children.forEach((gridCell) => {
      if (gridCell.gridCellType == 'TotalAdjustment') {
        gridCell.calculateAdjustmentTotal();
      }
    });
  }

  ngOnInit() {
    this.isLoading = true;
    this.getComparableData();

    setTimeout(() => {
      const contentReportInput = document.getElementById('report-input-detail-page');
      const scrollEvent = fromEvent(contentReportInput, 'scroll').pipe(
        map(() => contentReportInput)
      );

      scrollEvent.subscribe(() => {
        if (contentReportInput.scrollWidth > contentReportInput.clientWidth) {
          const isShowSubject = contentReportInput.scrollLeft < this.widthOfComparable;
          if (isShowSubject) {
            this.startComp = 1;
          } else {
            this.startComp = Math.floor(contentReportInput.scrollLeft / this.widthOfComparable) + 1;
          }

          this.calculateEndComp();
        }
      });
    }, 1000);

    this.saveComparableAction.pipe(debounceTime(2000)).subscribe((_lock) => {
      let saveSubject = false;
      let saveComparables = false;
      [...this.comparableUpdateRecordMap.keys()].forEach((key, index) => {
        if (key === 'saveSubject') {
          saveSubject = true;
        } else {
          saveComparables = true;
        }
      });

      let context = this;
      if (saveSubject && !saveComparables) {
        let promise = context.saveSubjectPromise(context);
        promise.then(
          function (_value) {
            this.eventService.saveSection(true);
          },
          function (error) {
            context.logActionError(error);
          }
        );
      } else if (saveComparables && saveSubject) {
        let promise = context.saveSubjectPromise(context);
        promise.then(
          function (_value) {
            context.saveAllComparables();
          },
          function (error) {
            context.logActionError(error);
          }
        );
      } else if (saveComparables) {
        this.saveAllComparables();
      }
    });

    this.eventService.onToggleSidebar.subscribe((isExpanded) => {
      if (isExpanded != null) {
        this.widthOfPadding = isExpanded ? 250 : 70;
        this.calculateEndComp();
      }
    });

    this.eventService.onGoToReportField.subscribe((field) => {
      if (field.sectionId === 'comparables') {
        setTimeout(() => {
          const elementRef = document.getElementById(field.fieldId);
          if (elementRef) {
            const index = this.dataSource.findIndex((item) => item.upi === field.fieldId);
            if (index && index < this.startComp) {
              this.scrollLeft(this.startComp + 1 - index);
            } else {
              elementRef.scrollIntoView({ behavior: 'smooth', block: 'center' });
            }
          }
        }, 500);
      }
    });
  }

  getComparableData() {
    this.reportComparableService
      .getList(
        this.orderAssignmentId,
        this.reportVersionDto.reportId,
        this.reportVersionDto.version
      )
      .subscribe({
        next: (comparables: ReportComparableWithThumbnailDto[]) => {
          const oldDataSource: ReportComparableModel[] = this.dataSource
            ? JSON.parse(JSON.stringify(this.dataSource))
            : [];

          this.dataSource = comparables || [];

          this.dataSource.forEach((comparable) => {
            this.getComparablePhoto(comparable);

            // Re-apply isVisible attribute for UI only hiding of comparables
            const oldComp = oldDataSource.find((c) => comparable.upi === c.upi);
            comparable.isVisible = oldComp?.isVisible ?? true;

           comparable.saleHistory = comparable?.transactionHistory?.reduce((latest, current) => {
             if (!latest) return current;
             return current.transactionContractDate > latest.transactionContractDate
               ? current
               : latest;
           }, null);
          });

          const subject = this.reportComparableService.getSubjectFromReport(this.reportVersionDto);
          subject.isVisible = true;
          subject.isSubject = true;
          this.dataSource.unshift(subject);
          this.getComparableConfig(subject);
          this.isLoading = false;

          this.visibleDataSource = this.dataSource.filter((item) => !!item.isVisible);
          this.totalComp = this.visibleDataSource.length - 1;
          this.startComp = this.visibleDataSource.length > 0 ? 1 : 0;

          setTimeout(() => {
            this.calculateEndComp();
          }, 500);
        },
        error: (error) => {
          this.logActionError(error);
        },
      });
  }

  private calculateEndComp() {
    const contentReportInput = document.getElementById('report-input-detail-page');
    if (contentReportInput.scrollWidth > contentReportInput.clientWidth) {
      const isShowSubject = contentReportInput.scrollLeft < this.widthOfComparable;
      const scrollLeft = contentReportInput.scrollLeft;
      if (isShowSubject) {
        const widthOfStartComp = this.widthOfComparable - scrollLeft;
        this.endComp =
          this.startComp +
          Math.ceil(
            (this.measuredGridWidth -
              widthOfStartComp -
              this.widthOfComparable -
              this.widthOfPadding) /
              this.widthOfComparable
          );
      } else {
        const widthOfStartComp = this.startComp * this.widthOfComparable - scrollLeft;
        this.endComp =
          this.startComp +
          Math.ceil(
            (this.measuredGridWidth -
              widthOfStartComp -
              this.widthOfComparable -
              this.widthOfPadding) /
              this.widthOfComparable
          );
      }
      this.endComp = this.endComp <= this.totalComp ? this.endComp : this.totalComp;
    } else {
      this.endComp = this.totalComp;
    }
  }

  private getComparablePhoto(comparableDto: ReportComparableModel) {
    const reportPhotoWithThumbnailDto =
      comparableDto.photos?.length > 0 ? comparableDto.photos[0] : null;
    comparableDto.photo = this.getThumbnail(reportPhotoWithThumbnailDto);
    comparableDto.largePhoto = this.getThumbnail(reportPhotoWithThumbnailDto, 'large');
  }

  private getThumbnail(photo: ReportPhotoWithThumbnailDto, size = 'small') {
    const thumbnail = (photo?.thumbnails || []).find((item) => item.size == size);
    return thumbnail?.links?.length > 0 ? thumbnail?.links[0].href : null;
  }

  getComparableConfig(subject: ReportComparableModel) {
    this.reportComparableService.getComparableConfig().subscribe({
      next: (response: ReportComparableFieldConfig[]) => {
        const horizontalColumns = response;

        this.horizontalColumns = [...horizontalColumns].sort((a, b) =>
          a.indexGroup < b.indexGroup ? -1 : 1
        );
        this.initCustomFieldName(subject);
        this.initDataTable();
        this.isLoading = false;
      },
      error: (error) => {
        this.logActionError(error);
      },
    });
  }

  private initCustomFieldName(subject: ReportComparableModel) {
    this.horizontalColumns
      .filter((field) => field.type === ReportComparableFieldType.Custom)
      .forEach((field) => {
        const customItem = subject.customItems.find((item) => item.name == field.key);
        field.label = customItem?.displayName || field.label;
      });
  }

  private initDataTable() {
    let index = 0;
    for (let item of this.dataSource) {
      item.comparableIndex = index++;
    }

    this.visibleDataSource = this.dataSource.filter((item) => !!item.isVisible);
    this.verticalRowIndex = this.visibleDataSource.map((item) => item.comparableIndex.toString());
  }

  getComparableItemData(index: string): ReportComparableModel {
    const comparable = this.dataSource[parseInt(index)];
    return comparable;
  }

  isSelectedRow(field: ReportComparableFieldConfig) {
    return this.rowSelected === field.key;
  }

  addComparable() {
    const dialogConfig = new MatDialogConfig();
    dialogConfig.data = {
      orderAssignmentId: this.orderAssignmentId,
      reportVersionDto: this.reportVersionDto,
    } as MarketModalRequest;
    dialogConfig.panelClass = 'market-modal';
    let dialogRef = this.matDialog.open(MarketModalComponent, dialogConfig);
    dialogRef.afterClosed().subscribe({
      next: (result: MarketModalResult) => {
        // verify the right type of response
        if (result?.isOpenFailed) {
          this.showErrorMsg('The market modal cannot be opened. Please retry later!');
          return;
        }

        // Todo: code must be refactored to remove componentInstance
        if (
          result?.wasComparableAggregateUpdated ||
          dialogRef.componentInstance.wasComparableAggregateUpdated
        ) {
          this.isLoading = true;
          this.checkAndGetLatestComparables();
        }
      },
    });
  }

  clickedRow(row: ReportComparableFieldConfig) {
    if (row.key !== 'valueAdjustment') this.rowSelected = row.key;
  }

  drop(event: CdkDragDrop<string[]>) {
    if (event.previousIndex !== 0 && event.currentIndex !== 0) {
      moveItemInArray(this.verticalRowIndex, event.previousIndex, event.currentIndex);

      const dataSource = cloneDeep(this.dataSource);
      const comp = event.item.data;
      const previousIndex = comp ? comp.comparableIndex : event.previousIndex;
      const invisibleDataSource = dataSource
        .filter((item) => !item.isVisible)
        .sort((a, b) => (a.comparableIndex < b.comparableIndex ? -1 : 1));
      let currentIndex = event.currentIndex;

      invisibleDataSource.forEach((item) => {
        if (item.comparableIndex == currentIndex) {
          currentIndex++;
        }
      });
      moveItemInArray(dataSource, previousIndex, currentIndex);
      dataSource.forEach((comp, index) => {
        comp.comparableIndex = index;
      });

      const sortComparablesDto = {
        SortedUpis: dataSource
          .filter((comp) => !comp.isSubject)
          .sort((a, b) => (a.comparableIndex < b.comparableIndex ? -1 : 1))
          .map((comp) => comp.upi),
      };
      this.isLoading = true;
      this.comparableService
        .sortComparables(this.orderAssignmentId, sortComparablesDto)
        .subscribe({
          next: (_response: ComparablesAggregateDto) => {
            this.checkAndGetLatestComparables();
          },
          error: (err) => this.logActionError(err),
        });
    }
  }

  private logActionError(err: any): void {
    this.isLoading = false;
    this.msgService.error(err).subscribe(() => {});
  }

  partialUpdate(comparableUpdateDto) {
    if (comparableUpdateDto?.isSubject) {
      this.comparableUpdateRecordMap.set('saveSubject', comparableUpdateDto.updates);
      this.saveComparableAction.next(null);
      this.eventService.saveSection(true);
    } else if (comparableUpdateDto?.upi && comparableUpdateDto?.updates) {
      const comparableUpdate = this.comparableUpdateRecordMap.get(comparableUpdateDto.upi) || {};
      Object.keys(comparableUpdateDto.updates).forEach((key) => {
        comparableUpdate[key] = comparableUpdateDto.updates[key];
      });
      this.comparableUpdateRecordMap.set(comparableUpdateDto.upi, comparableUpdate);
      this.saveComparableAction.next('Bulk');
    }
  }

  partialUpdateReport(reportUpdates: Record<string, string>) {
    const updateReportVersionRequest = {
      orderAssignmentId: this.orderAssignmentId,
      updates: reportUpdates,
    } as PartialUpdateReportDto;
    this.activityReportsService
      .partialUpdate(
        this.reportVersionDto.reportId,
        this.reportVersionDto.version,
        updateReportVersionRequest
      )
      .subscribe({
        next: () => this.eventService.saveSection(true),
        error: (err) => this.logActionError(err),
      });
  }

  hideComparable(comparableDto: ReportComparableModel) {
    if (comparableDto.isSubject) {
      return;
    }

    const comp = this.dataSource.find((comp) => comp.upi === comparableDto.upi);
    comp.isVisible = false;

    this.visibleDataSource = this.dataSource.filter((item) => !!item.isVisible);
    this.verticalRowIndex.splice(this.verticalRowIndex.indexOf(comp.comparableIndex.toString()), 1);
    setTimeout(() => {
      this.totalComp = this.visibleDataSource.length - 1;
      this.calculateEndComp();
    }, 100);
  }

  showAllHiddenComparables() {
    const compsToShow = this.dataSource.filter((item) => !item.isVisible);
    for (let comp of compsToShow) {
      comp.isVisible = true;
    }

    this.visibleDataSource = this.dataSource.filter((item) => !!item.isVisible);
    this.verticalRowIndex.length = 0;
    this.verticalRowIndex.push(
      ...this.visibleDataSource.map((item) => item.comparableIndex.toString())
    );
    setTimeout(() => {
      this.totalComp = this.visibleDataSource.length - 1;
      this.calculateEndComp();
    }, 100);
  }

  saveAllComparables() {
    let requestItems = [];
    [...this.comparableUpdateRecordMap.keys()].forEach((key, _index) => {
      let comparableUpdateRecord = this.comparableUpdateRecordMap.get(key);
      if (comparableUpdateRecord) {
        const updateComparableRequestItem = {
          orderAssignmentId: this.orderAssignmentId,
          updates: comparableUpdateRecord,
          upi: key,
        } as BulkPartialComparableUpdateItemDto;
        if (
          Object.keys(updateComparableRequestItem).length > 0 &&
          updateComparableRequestItem.updates &&
          Object.keys(updateComparableRequestItem.updates).length > 0
        )
          requestItems.push(updateComparableRequestItem);
      }
    });

    if (requestItems.length == 0) return;

    const updateComparableRequest = {
      partialUpdateItems: requestItems,
    } as BulkPartialComparableUpdateDto;

    this.reportService
      .bulkPartialUpdateAsync(
        this.orderAssignmentId,
        this.reportVersionDto.reportId,
        this.reportVersionDto.version,
        updateComparableRequest
      )
      .subscribe({
        next: (_response: ReportVersionDto) => {
          // Clear old data
          updateComparableRequest.partialUpdateItems.forEach((_e, index) => {
            let upi = updateComparableRequest.partialUpdateItems[index].upi;
            let requestUpdateRecord = updateComparableRequest.partialUpdateItems[index];

            let comparableUpdateRecord = this.comparableUpdateRecordMap.get(upi);
            Object.keys(comparableUpdateRecord).forEach((key) => {
              if (comparableUpdateRecord[key] == requestUpdateRecord.updates[key]) {
                delete comparableUpdateRecord[key];
              }
            });
            this.comparableUpdateRecordMap.set(upi, comparableUpdateRecord);
            this.eventService.saveSection(true);
          });
        },
        error: (err) => this.logActionError(err),
      });
  }

  deleteComparable(comparableDto: ReportComparableModel) {
    const upi = comparableDto.upi;
    this.isLoading = true;
    this.comparableService
      .delete(this.reportVersionDto.orderAssignmentId, upi)
      .subscribe({
        next: () => {
          // Remove deleted comparable
          this.comparableViewModelMap.delete(upi);
          this.comparableUpdateRecordMap.delete(upi);
          this.lastComparableSavedRecordMap.delete(upi);

          // Refresh comparables and re-apply isVisible attribute for UI only hiding of comparables
          this.checkAndGetLatestComparables();
        },
        error: (err) => this.logActionError(err),
      });
  }

  saveSubjectPromise(pageContext): Promise<void> {
    const subject = this.dataSource[0];
    const customFields = subject.customItems.map((item) => {
      return {
        key: item.name,
        displayName: item.displayName,
        value: item.value,
      };
    });
    const reportInputUpdates: Record<string, string> = {};
    reportInputUpdates['property.customFields'] = JSON.stringify(customFields);

    const updateReportVersionRequest = {
      orderAssignmentId: this.reportVersionDto.orderAssignmentId,
      updates: reportInputUpdates,
    } as PartialUpdateReportDto;

    let promise = new Promise<void>(function (resolve, reject) {
      try {
        pageContext.activityReportsService
          .partialUpdate(
            pageContext.reportVersionDto.reportId,
            pageContext.reportVersionDto.version,
            updateReportVersionRequest
          )
          .subscribe({
            next: (_response: ReportVersionDto) => {
              pageContext.comparableUpdateRecordMap.delete('saveSubject');
              resolve();
            },
            error: (err) => reject(err),
          });
      } catch (ex) {
        reject(ex);
      }
    });

    return promise;
  }

  changeViewMore($event) {
    this.isViewMore = $event;
  }

  openCleanupModal(comparableDto: ReportComparableModel) {
    const dialogConfig = new MatDialogConfig();
    dialogConfig.data = {
      orderAssignmentId: this.orderAssignmentId,
      upi: comparableDto.upi,
    } as MarketCleanupPropertyModalRequest;
    dialogConfig.panelClass = 'market-cleanup-property-modal';
    let dialogRef = this.matDialog.open(MarketCleanupPropertyModalComponent, dialogConfig);
    dialogRef.afterClosed().subscribe({
      next: (result: MarketCleanupPropertyModalResult) => {
        // verify the right type of response
        if (result.isOpenFailed) {
          this.showErrorMsg('The cleanup property modal cannot be opened. Please retry later!');
          return;
        }
        if (result.isSaveRequested) {
          this.isLoading = true;
          this.propertyRecordService.postUpdatedProperty(result.property).subscribe({
            next: (response: PropertyRecordDto) => {
              this.comparableService.getLatest(this.orderAssignmentId).subscribe({
                next: (compAgg: ComparablesAggregateDto) => {
                  this.comparableService
                    .updateComparable(comparableDto.upi, compAgg, response)
                    .subscribe({
                      next: () => {
                        this.checkAndGetLatestComparables();
                      },
                      error: (err) => {
                        this.logActionError(err);
                      },
                    });
                },
                error: (err) => {
                  this.logActionError(err);
                },
              });
            },
            error: (err) => {
              this.showErrorMsg(`The cleanup of the property could not be saved. ${err}}`);
            },
          });
        }
      },
    });
  }

  private checkAndGetLatestComparables() {
    this.activityReportsService.getLatest(this.orderAssignmentId).subscribe({
      next: (response: ReportVersionWithLinksDto) => {
        if (response.lastModificationTime != this.reportVersionDto.lastModificationTime) {
          // Reload Comparables
          this.getComparableData();
        } else {
          setTimeout(() => {
            this.checkAndGetLatestComparables();
          }, 1000);
        }
      },
      error: (err) => {
        this.logActionError(err);
      },
    });
  }

  private showErrorMsg(msg: string) {
    const options = {
      hideYesBtn: true,
      cancelText: 'Close',
    };
    this.msgService.error(msg, null, null, options).subscribe();
  }

  scrollRight() {
    const outsider = document.getElementById('report-input-detail-page');
    const distance = 400;
    outsider.scrollBy({
      left: distance,
      behavior: 'smooth',
    });
  }

  scrollLeft(steps: number = 1) {
    const outsider = document.getElementById('report-input-detail-page');
    const distance = steps * 400;
    outsider.scrollBy({
      left: -distance,
      behavior: 'smooth',
    });
  }
}
