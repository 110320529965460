import { Component, Input, OnInit } from '@angular/core';
import { PropertyRecordDataDto } from '@proxy/property/property-record/property-records';
import { CleanedListingDto, PropertyRecordDto } from '@proxy/property/property-record/property-records/v1';
import { SelectedPropertyRecordValuePipe } from 'src/app/proxy-adapter/shared/pipes/selected-property-record-value.pipe';
import { CommentaryAttributeModel } from '../../models/property-attribute/commentary-attribute-model';
import { AttributeConfig, AttributeRecordFactory } from '../market-cleanup-property-attribute-config';

@Component({
  selector: 'jaro-kit-market-cleanup-property-commentary',
  templateUrl: './market-cleanup-property-commentary.component.html',
  styleUrls: ['./market-cleanup-property-commentary.component.scss'],
})
export class MarketCleanupPropertyCommentaryComponent implements OnInit {
  @Input() property: PropertyRecordDto;
  @Input() isLoading: boolean = true;

  selectedValuePipe: SelectedPropertyRecordValuePipe;
  attributeRecord: Record<keyof CleanedListingDto, AttributeConfig> = AttributeRecordFactory.getConfig();

  attributes: CommentaryAttributeModel[];
  readonly nameof = <T>(name: Extract<keyof T, string>): string => name;

  constructor(
  ) {
    this.selectedValuePipe = new SelectedPropertyRecordValuePipe();
  }

  ngOnInit(): void {
    const listing = this.property.cleanedListing;

    this.attributes = Object.entries(this.attributeRecord)
                            .filter(attr => attr[1].isCommentary)
                            .map<CommentaryAttributeModel>(attr => {
                                return this.mapListingToCommentaryAttributeModel(listing, attr[0] as keyof CleanedListingDto, attr[1].title);
                            });
  }

  private mapListingToCommentaryAttributeModel(listing: CleanedListingDto, name: keyof CleanedListingDto, title: string): CommentaryAttributeModel {
    const data = listing[name] as PropertyRecordDataDto;
    const model = new CommentaryAttributeModel(this.nameof<CleanedListingDto>(name), title || name.toString(), this.selectedValuePipe.transform(data));
    return model;
  }
}
