import { RestService } from '@abp/ng.core';
import { Injectable } from '@angular/core';
import type { AddressDto, AddressLineDto, PropertyDto, TimeZoneDto } from '../properties/v1/models';

@Injectable({
  providedIn: 'root',
})
export class PropertyService {
  apiName = 'Appraisal';

  findByGoogleMapsPlaceId = (googleMapPlaceId: string) =>
    this.restService.request<any, PropertyDto>({
      method: 'GET',
      url: `/api/v1/properties/google-place-id/${googleMapPlaceId}`,
    },
    { apiName: this.apiName });

  get = (id: string) =>
    this.restService.request<any, PropertyDto>({
      method: 'GET',
      url: `/api/v1/properties/${id}`,
    },
    { apiName: this.apiName });

  getBulkProperties = (ids: string[]) =>
    this.restService.request<any, PropertyDto[]>({
      method: 'POST',
      url: `/api/v1/properties/bulk`,
      body: ids,
    },
    { apiName: this.apiName });

  getByAddressLine = (addressDto: AddressLineDto) =>
    this.restService.request<any, PropertyDto>({
      method: 'GET',
      url: `/api/v1/properties/by-address`,
      body: addressDto,
    },
    { apiName: this.apiName });

  getList = () =>
    this.restService.request<any, PropertyDto[]>({
      method: 'GET',
      url: `/api/v1/properties`,
    },
    { apiName: this.apiName });

  getOrCreate = (input: AddressLineDto) =>
    this.restService.request<any, PropertyDto>({
      method: 'POST',
      url: `/api/v1/properties`,
      body: input,
    },
    { apiName: this.apiName });

  getTimeZoneByAddressDtoAndTimestamp = (addressDto: AddressDto, timestamp: number) =>
    this.restService.request<any, TimeZoneDto>({
      method: 'GET',
      url: `/api/v1/properties/timezone`,
      params: { timestamp: timestamp },
      body: addressDto,
    },
    { apiName: this.apiName });

  constructor(private restService: RestService) {}
}
