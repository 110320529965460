import { Confirmation } from '@abp/ng.theme.shared';
import { HttpErrorResponse } from '@angular/common/http';
import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialog, MatDialogConfig, MatDialogRef } from '@angular/material/dialog';
import { SignReportDto, ValidationResultDto } from '@proxy/appraisal/report/reports/v1';
import {
  ReportSubmissionStatusDto
} from '@proxy/appraisal/report/reports/v2';
import { ReportVersionWithLinksDto } from '@proxy/bff/activity/reports/v1';
import { JaroDeskUserDto } from '@proxy/integration/ascent/order/v1';
import { ReportGenerationInterface } from 'src/app/interface/appraisal/report/report-generation.interface';
import { ReportServiceInterface } from 'src/app/interface/appraisal/report/report-service-interface';
import { AscentReportInterface } from 'src/app/interface/bff/activity/ascent.report.interface';
import { ReportsServiceInterface } from 'src/app/interface/bff/activity/reports-service-interface';
import InjectionSymbol from 'src/app/shared/injection/injection-symbol';
import { EventService } from 'src/app/shared/services/event.service';
import { MessageService } from 'src/app/shared/services/message.service';
import { Feature } from 'src/app/shared/utils/feature/feature';
import { ReportValidationMessageComponent } from '../report-validation-message/report-validation-message.component';
import { PreviewReportQualityCheckComponent } from './preview-report-quality-check/preview-report-quality-check.component';

@Component({
  selector: 'jaro-kit-preview-report',
  templateUrl: './preview-report.component.html',
  styleUrls: ['./preview-report.component.scss'],
})
export class PreviewReportComponent implements OnInit {
  urlPDF: string;
  orderAssignmentId: string;
  productName: string; // job.form.main
  reportVersionDto: ReportVersionWithLinksDto;
  isLoading: boolean = true;
  isActiveSendReport: boolean;
  isDisabledSendReport: boolean;
  isSubmitting: boolean;

  constructor(
    @Inject(InjectionSymbol.AscentReportService)
    private ascentReportService: AscentReportInterface,
    @Inject(InjectionSymbol.ReportGenerationService)
    private reportGenerationService: ReportGenerationInterface,
    @Inject(InjectionSymbol.ReportService)
    private reportService: ReportServiceInterface,
    @Inject(InjectionSymbol.ActivityReportsService)
    private activityReportService: ReportsServiceInterface,
    @Inject(InjectionSymbol.EventService)
    private eventService: EventService,
    private msgService: MessageService,
    private matDialog: MatDialog,
    public dialogRef: MatDialogRef<PreviewReportComponent>,
    @Inject(MAT_DIALOG_DATA)
    public previewDocumentData: {
      orderAssignmentId: string;
      urlPDF: string;
      reportVersionDto: ReportVersionWithLinksDto;
      productName;
    }
  ) {}

  ngOnInit(): void {
    this.urlPDF = this.previewDocumentData.urlPDF;
    this.orderAssignmentId = this.previewDocumentData.orderAssignmentId;
    this.reportVersionDto = this.previewDocumentData.reportVersionDto;
    this.productName = this.previewDocumentData.productName;
    this.getUserInfoAndCheckActiveSendReport();    
    this.checkReportValidations();
  }

  private checkReportValidations() {
    this.isDisabledSendReport = true;

    const isUseMainFormName = this.reportVersionDto.productType?.toLocaleLowerCase() === "1004d";
    const mainFormName = isUseMainFormName ? this.productName?.toLocaleLowerCase()?.replace("&","and") : null;

    this.reportService
      .reportValidations(
        this.orderAssignmentId,
        "kit-submit",
        mainFormName,
        "submit-report"
      )
      .subscribe({
        next: (response: ValidationResultDto) => {
          if (response?.results?.filter(r => r.severity?.toLowerCase() === "error")?.length > 0) {
            this.isDisabledSendReport = true;
            const dialogConfig = new MatDialogConfig();
            dialogConfig.data = {
              validations: response.results
            };
            dialogConfig.panelClass = 'report-validation-message-modal';
            dialogConfig.disableClose = false;
            if(Feature.isEnabledFeatureWithProductType("ReportTemplate.PreviewReportQualityCheck", this.reportVersionDto.productType)) {
              let dialogRef = this.matDialog.open(PreviewReportQualityCheckComponent, dialogConfig);
              dialogRef.afterClosed().subscribe({
                next: (result: string) => {
                  switch(result) {
                    case "OpenQC": {
                      setTimeout(() => this.eventService.openQualityCheck(), 100);
                      this.dialogRef.close();
                      return;
                    }

                    case "Close": {
                      this.dialogRef.close();
                      return;
                    }

                    default: {
                      // Includes "Preview" action
                      // Do nothing
                    }
                  }
                }
              });
            }
            else {
              this.matDialog.open(ReportValidationMessageComponent, dialogConfig);
            }
          } else {
            this.isDisabledSendReport = false;
          }
        },
        error: (err) => this.logActionError(err),
      });
  }

  private getUserInfoAndCheckActiveSendReport() {
    this.isActiveSendReport = false;
    if (
      Feature.isEnabledFeatureWithProductType(
        'ReportTemplate.SendReport',
        this.previewDocumentData.productName
      )
    ) {
      this.ascentReportService.getUserInfo().subscribe({
        next: (userInfo: JaroDeskUserDto) => {
          this.activityReportService.getLatest(this.reportVersionDto.orderAssignmentId, false).subscribe({
            next: (reportVersion: ReportVersionWithLinksDto) => {
              this.isActiveSendReport = this.reportService.getActiveSendReportWithUserRole(
                userInfo.appraiserGuid,
                reportVersion
            )},
            error: (err) => this.logActionError(err)
          })
        },
        error: (err) => this.logActionError(err)      
      });
    }
  }

  cancelSubmit() {
    this.dialogRef.close();
  }

  submitReportToJaroDesk() {
    const option = {
      hideCancelBtn: true,
      dismissible: true,
    };
    this.msgService
      .confirm(
        'Final Submission',
        'Have you checked and included all the relevant information in the report? Note that report will be auto-signed on your behalf upon submission.',
        option
      )
      .subscribe({
        next: (status: Confirmation.Status) => {
          if (status === Confirmation.Status.confirm) {
            this.validateSignatureAndSubmitReport();
          }
        },
      });
  }

  private validateSignatureAndSubmitReport() {
    const signReportDto = {
      orderAssignmentId: this.orderAssignmentId,
      reportId: this.reportVersionDto.reportId,
      version: this.reportVersionDto.version,
      concurrencyStamp: null,
    } as SignReportDto;

    this.reportService.signReport(signReportDto).subscribe({
      next: (_response) => {
        const request = {
          orderAssignmentId: this.orderAssignmentId
        };
        this.isSubmitting = true;
        this.reportGenerationService.submit(request).subscribe({
          next: (_response) => {
            this.notifyWhenCompleted(_response.reportSubmissionId);
          },
          error: (err) => {
            this.isSubmitting = false;
            this.logActionError(err)
          },
        });
      },
      error: (err: HttpErrorResponse) => {
        const optionMsg = {
          hideYesBtn: true,
          cancelText: 'Close',
          dismissible: true,
        };
        this.msgService.error(err, 'Final Submission', err.error.error.message, optionMsg, true);
      },
    });
  }


  private notifyWhenCompleted(reportGenerationRequestId: string) {
    this.reportGenerationService.getSubmitReportStatus(reportGenerationRequestId).subscribe({
      next: (response: ReportSubmissionStatusDto) => {
        switch (response.status) {
          case 'New':
            if(this.isSubmitting) {
            // Delay polling
              setTimeout(() => {
                this.notifyWhenCompleted(reportGenerationRequestId);
              }, 1000);
            }
            break;
          case 'Error':
            this.isSubmitting = false;
            this.msgService.error('Error sending report.\n' + (response.errors || []).join('\n'));
            break;
          case 'Completed':
            this.isSubmitting = false;
            this.eventService.submitReport(reportGenerationRequestId); // This must be before the dialog is closed.
            this.showMsgSucceededAndCloseDialog();            
            break;
        }
      },
      error: (err) => this.logActionError(err),
    });
  }



  private logActionError(err: any): void {
    this.msgService.error(err).subscribe((_status: Confirmation.Status) => {});
  }

  private showMsgSucceededAndCloseDialog() {
    this.dialogRef.close();
    const optionMsg = {
      hideYesBtn: true,
      cancelText: 'Close',
      dismissible: true,
    };
    this.msgService
      .confirm(
        'Final Submission',
        'Report has been submitted to JaroDesk',
        optionMsg
      )
      .subscribe({
        next: (_status: Confirmation.Status) => {},
      });
  }
}
