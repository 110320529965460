import { Confirmation } from '@abp/ng.theme.shared';
import { Component, Inject, OnInit } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { ReportVersionDto } from '@proxy/appraisal/report/reports/v1';
import { PartialUpdateReportDto } from '@proxy/appraisal/report/v1';
import { OrderAssignmentDto } from '@proxy/ascent/order/order-assignment/order-assignments';
import { ReportVersionWithLinksDto } from '@proxy/bff/activity/reports/v1';
import { SourceStatusDto } from '@proxy/property/property-job/property-jobs/v1';
import { ReportWithExtraAttributes } from 'src/app/features/report/shared/models/report-input-extra-atributes.model';
import { ReportsServiceInterface } from 'src/app/interface/bff/activity/reports-service-interface';
import { FeatureNames } from 'src/app/shared/enums/report-input.enum';
import InjectionSymbol from 'src/app/shared/injection/injection-symbol';
import { MenuItemModel } from 'src/app/shared/models/menuItem.model';
import { EventService } from 'src/app/shared/services/event.service';
import { MessageService } from 'src/app/shared/services/message.service';
import { ReportInputTemplate } from '../../models/report-input-layout.model';
import { ReportInputTemplateService } from '../../providers/report-input-template.service';
@Component({
  selector: 'jaro-kit-report-common-feature-layout',
  templateUrl: './common-feature.component.html',
  styleUrls: ['./common-feature.component.scss'],
})
export class CommonFeatureComponent implements OnInit {
  orderAssignmentId: string;
  currentOrderAssignment: OrderAssignmentDto;
  reportVersion: number;
  reportId: string;
  reportVersionDto: ReportVersionWithLinksDto;
  reportDto: ReportWithExtraAttributes;
  isLoading: boolean;
  feature = FeatureNames.Report;
  isExpanded: boolean;
  sourceStatus: SourceStatusDto;
  reportInputForm: FormGroup;
  reportInputTemplate: ReportInputTemplate[];
  reportTemplate: ReportInputTemplate;
  menuItems: MenuItemModel[];
  notificationMessage: string;

  constructor(
    @Inject(InjectionSymbol.ReportInputTemplateService)
    protected reportInputTemplateService: ReportInputTemplateService,
    @Inject(InjectionSymbol.ActivityReportsService)
    protected activityReportsService: ReportsServiceInterface,
    protected msgService: MessageService,

    @Inject(InjectionSymbol.EventService)
    protected eventService: EventService
  ) {}

  ngOnInit(): void {
    this.reportVersion = this.reportVersionDto.version;
    this.reportId = this.reportVersionDto.reportId;

    this.initExtraValue(this.reportVersionDto);
    this.reportTemplate = this.reportInputTemplate.find(item => item.feature === this.feature);
    (this.reportTemplate?.sections || []).forEach((section) => {
      section.isEditMode = false;
      (section.subSections || []).forEach((subSection) => {
        subSection.isEditMode = false;
      });
    });
  }

  private initExtraValue(reportVersionDto: ReportVersionWithLinksDto) {
    if (reportVersionDto && reportVersionDto.reportId) {
      const report = reportVersionDto.report as ReportWithExtraAttributes;
      report.subjectListingHistory = reportVersionDto.subjectListingHistory;
      const improvement = report.property.improvement;
      const reconciliationAsIs =
        improvement &&
        improvement.areTherePartsUnderConstruction === false &&
        improvement.isDwellingSubjectToRepairs === false &&
        improvement.isThereInspectionRequiredOrRecommended === false;
      report.reconciliationAsIs = reconciliationAsIs;
      report.isSupervisor = !!report.supervisorInfo;
      this.reportVersionDto = reportVersionDto;
      this.reportDto = this.reportVersionDto.report;
      this.reportDto.productType = this.reportVersionDto.productType;
      this.reportDto.productName = this.currentOrderAssignment.orderData.mainForm;
      this.reportDto.sourceStatus = this.sourceStatus;
    }
  }

  public boostEnabled() {
    if(this.currentOrderAssignment?.extraProperties == null) return false;
    try {
      let extras = new Map(Object.entries(this.currentOrderAssignment.extraProperties));
      return (extras.get("BoostEnabled") == true); 
    } catch {
      return false;
    }
  }

  partialUpdateReportInput(reportInputDto) {
    const reportInputUpdates: Record<string, string> = reportInputDto.reportInputUpdates;
    const updateReportVersionRequest = {
      orderAssignmentId: this.orderAssignmentId,
      updates: reportInputUpdates,
    } as PartialUpdateReportDto;
    this.activityReportsService
      .partialUpdate(
        this.reportVersionDto.reportId,
        this.reportVersionDto.version,
        updateReportVersionRequest
      )
      .subscribe({
        next: (_response: ReportVersionDto) => {
          // After a successful API call, switch to view mode.
          reportInputDto.toggleMode();
          setTimeout(() => {
            this.eventService.saveSection(true);
          }, 100);
        },
        error: (err) => this.logActionError(err),
      });
  }

  private logActionError(err: any): void {
    this.isLoading = false;
    this.msgService.error(err).subscribe((_status: Confirmation.Status) => {});
  }
}
