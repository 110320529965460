import { Component, Inject, Input } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { ContentServiceInterface } from 'src/app/interface/content/content-service-interface';
import { ItemData } from 'src/app/shared/components/multiple-combo-box/multiple-combo-box.component';
import InjectionSymbol from 'src/app/shared/injection/injection-symbol';

@Component({
  selector: 'halo-global-improvements-type',
  templateUrl: './improvements-type.component.html',
  styleUrls: ['./improvements-type.component.scss']
})
export class ImprovementsTypeComponent  {

  @Input() form: FormGroup;

  alleyRoadSurfaceOptions:ItemData[] = [];
  streetRoadSurfaceOptions:ItemData[] = [];
  constructor(
    @Inject(InjectionSymbol.ContentService)
    public contentService: ContentServiceInterface
  ) {
    this.alleyRoadSurfaceOptions = this.contentService.getContentByKey('utilityType.alleySurface');
    this.streetRoadSurfaceOptions = this.contentService.getContentByKey('utilityType.streetSurface');
  }

}
