<halo-global-popup [dialogRef]="dialogRef" [isHideClose]="true">
  <div class="photo-review-container">
    <div class="photo-review-top">
      <div (click)="closeDialog()" class="photo-review-close mouse-cursor-pointer">
        <em class="fal fa-times"></em>
      </div>
    </div>
    <div class="photo-carousel">
      <ngx-gallery
        [options]="galleryOptions"
        [images]="galleryImages"
        class="ngx-gallery"
        (change)="changeSelectPhoto()"
      ></ngx-gallery>
    </div>

    <div class="photo-preview-footer">
      <ng-container [formGroup]="form">
        <mat-form-field appearance="none" class="photo-section">
          <mat-label class="report-input-label">Section</mat-label>
          <mat-select formControlName="photoSection" multiple>
            <mat-option
              *ngFor="let option of photoGalleryData.sectionOptions"
              [value]="option.id"
              >{{ option.name }}</mat-option
            >
          </mat-select>
        </mat-form-field>

        <mat-form-field class="photo-title">
          <mat-label>Title</mat-label>
          <input matInput formControlName="photoTitle" autocomplete="off" maxlength="150" />
        </mat-form-field>

        <button
          type="submit"
          class="photo-button-save jaro-kit-primary-button"
          *ngIf="form.dirty"
          (click)="upsertReportPhoto()"
        >
          Save
        </button>

        <button
          type="submit"
          class="photo-button-discard gray-scale-5-background-color"
          (click)="discardPhotoDetail()"
          *ngIf="form.dirty"
        >
          Discard
        </button>

        <jaro-kit-photo-button-actions
          *ngIf="
            currentPhoto.sectionIds &&
            currentPhoto.sectionIds.length > 0 &&
            !currentPhoto.isCoverPhoto
          "
          (onMakeCoverPhoto)="makeCoverPhoto()"
        ></jaro-kit-photo-button-actions>

        <div class="photo-preview-footer-right"></div>
      </ng-container>
    </div>
  </div>
</halo-global-popup>
