import { Component, OnInit } from '@angular/core';
import { VirtualKeypadService } from 'src/app/shared/providers/virtual-keypad/virtual-keypad.factory';
import { VirtualKeypadActionItem } from 'src/app/shared/providers/virtual-keypad/virtual-keypad.model';

@Component({
  selector: 'jaro-kit-sketch-virtual-keypad',
  templateUrl: './sketch-virtual-keypad.component.html',
  styleUrls: ['./sketch-virtual-keypad.component.scss'],
})
export class SketchVirtualKeypadComponent implements OnInit {
  virtualKeypadActionItems: VirtualKeypadActionItem[] = [];

  constructor(private virtualKeypadService: VirtualKeypadService) {}

  ngOnInit(): void {
    this.virtualKeypadActionItems = this.virtualKeypadService.getVirtualKeypadActionItems();
  }
}
