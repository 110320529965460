import { Component, OnInit } from '@angular/core';
import { FeatureNames } from 'src/app/shared/enums/report-input.enum';
import { CommonFeatureComponent } from '../../shared/forms/components/common-feature/common-feature.component';


@Component({
  selector: 'jaro-kit-assignment',
  templateUrl: '../../shared/forms/components/common-feature/common-feature.component.html',
  styleUrls: ['../../shared/forms/components/common-feature/common-feature.component.scss'],
})
export class AssignmentComponent extends CommonFeatureComponent implements OnInit {
  feature = FeatureNames.Assignment;

    ngOnInit(): void {
      super.ngOnInit();
      
      this.eventService.onSaveSection.subscribe(() => {
        this.eventService.updateAssignmentType(this.reportDto.property.assignmentType);
    });
  }
}