import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import {
  LineItemDto,
  ReportComparableFieldConfig,
  ReportComparableFieldType,
  ReportComparableModel,
} from 'src/app/proxy-adapter/report/comparables/models';

@Component({
  selector: 'jaro-kit-comparable-grid-input-edit-mode',
  templateUrl: './comparable-grid-input-edit-mode.component.html',
  styleUrls: ['./comparable-grid-input-edit-mode.component.scss'],
})
export class ComparableGridInputEditModeComponent implements OnInit {
  @Input() field: ReportComparableFieldConfig;
  @Input() comparableDto: ReportComparableModel;
  @Input() value: string;
  @Input() lineItems: LineItemDto[];
  @Input() isAdjustment: boolean;
  @Input() isNestedField: boolean;

  @Output() onChangeValue = new EventEmitter<string>();
  @Output() onChangeAdjustmentValue = new EventEmitter<string>();
  @Output() onChangeNestedItemAdjustmentValue = new EventEmitter<{key: string, value: string}>();
  @Output() partialUpdate = new EventEmitter<any>();

  nestedItems: LineItemDto[] = [];

  get isDisabled() {
    return this.field.isDisabled || this.comparableDto.isSubject;
  }

  ngOnInit(): void {
    this.isNestedField = this.field.type === ReportComparableFieldType.NestedItems;
    if(this.isNestedField) {
      this.nestedItems = this.comparableDto.nestedItems[this.field.key];
    }
    this.nestedItems = this.nestedItems || [];
    this.lineItems = this.lineItems || [];
  }

  changeAdjustmentValue() {
    this.onChangeAdjustmentValue.emit(this.value);
    const reportInputUpdates: Record<string, string> = {};
    if (this.field.adjustmentType === 'totals' || this.field.adjustmentOverridden) {
      reportInputUpdates[`${this.field.path}.totalAdjustment`] = this.value;
      reportInputUpdates[`${this.field.path}.adjustmentOverridden`] = 'true';
    } else {
      reportInputUpdates[`${this.field.path}.adjustment`] = this.value;
    }
    this.partialUpdate.emit(reportInputUpdates);
  }

  changeItemValue(lineItem: LineItemDto) {
    this.value = this.getAdjustmentValue();
    this.onChangeAdjustmentValue.emit(this.value);
    const reportInputUpdates: Record<string, string> = {};
    reportInputUpdates[`${this.field.path}.${lineItem.path}.adjustment`] = lineItem.adjustment
      ? lineItem.adjustment.toString()
      : '';
    reportInputUpdates[`${this.field.path}.totalAdjustment`] = this.value?.toString();
    reportInputUpdates[`${this.field.path}.adjustmentOverridden`] = "true";
    this.partialUpdate.emit(reportInputUpdates);
  }

  changeNestedAdjustmentValue(nestedItem: LineItemDto) {
    this.value = nestedItem.adjustment.toString() ?? "";
    this.onChangeNestedItemAdjustmentValue.emit({key: nestedItem.path, value: this.value});
    const reportInputUpdates: Record<string, string> = {};
    reportInputUpdates[`${nestedItem.path}.totalAdjustment`] = this.value;
    reportInputUpdates[`${nestedItem.path}.adjustmentOverridden`] = "true";
    this.partialUpdate.emit(reportInputUpdates);
  }

  private getAdjustmentValue() {
    if (this.lineItems?.length > 0) {
      const value = this.lineItems
        .map((item) =>
          (item.adjustment || item.adjustment === 0) && !isNaN(item.adjustment)
            ? parseFloat(item.adjustment.toString())
            : null
        )
        .reduce((a, b) =>  (a !== null || b !== null) ? a + b : null, null);
      return value || value == 0 ? value.toString() : null;
    }
    return this.value;
  }
}
