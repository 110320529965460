<div class="container filter-panel">
    <div class="row">
        <span class="col-10 facet-title">{{filteredFilterModel.title}}</span>
        <span class="col-2"><mat-icon
            matSuffix
            class="mat-suffix-icon"
            *ngIf="!isOpenDropdown"
            (click)="toggleOpenDropdown($event)"
            >arrow_drop_down</mat-icon>
        <mat-icon
            matSuffix
            class="mat-suffix-icon"
            *ngIf="isOpenDropdown"
            (click)="toggleOpenDropdown($event)"
            >arrow_drop_up</mat-icon>
        </span>
    </div>
    <div *ngIf="isOpenDropdown" class="scores-panel">
        <div *ngFor="let score of firstScores" class="row">
            <span class="col-6 score-title">{{score.title}}</span>
            <span *ngIf="isScoreUpdated(score)" class="col-3">
                <mat-chip-list>
                    <mat-chip [ngClass]="{ 'mat-standard-chip': true, 'score-frequency-updated-non-zero': updatedFrequency(score) > 0, 'score-frequency-updated-zero': updatedFrequency(score)  === 0 }">{{updatedFrequency(score)}}</mat-chip>
                </mat-chip-list>
            </span>
            <span *ngIf="!isScoreUpdated(score)" class="col-3">
                &nbsp;
            </span>
            <span class="col-3">
                <mat-chip-list>                        
                    <mat-chip [ngClass]="{ 'mat-standard-chip': true, 'score-frequency-selected': score.isSelected }" (click)="toggleScoreSelection(score)">{{score.frequency}}</mat-chip>                        
                </mat-chip-list>
            </span>
        </div>
        <div class="row" *ngIf="isShowMoreScoresLink">
            <span class="col-9">&nbsp;</span>
            <span class="toggle-scores"><a class="toggle-scores" (click)="showMoreScores()">more</a></span>
        </div>
        <div *ngIf="isShowMoreScores" >
            <div *ngFor="let score of moreScores" class="row">
                <span class="col-6 score-title">{{score.title}}</span>
                <span *ngIf="isScoreUpdated(score)" class="col-3">
                    <mat-chip-list>
                        <mat-chip [ngClass]="{ 'mat-standard-chip': true, 'score-frequency-updated-non-zero': updatedFrequency(score) > 0, 'score-frequency-updated-zero': updatedFrequency(score)  === 0 }">{{updatedFrequency(score)}}</mat-chip>
                    </mat-chip-list>
                </span>
                <span *ngIf="!isScoreUpdated(score)" class="col-3">
                    &nbsp;
                </span>
                <span class="col-3">
                    <mat-chip-list>                        
                        <mat-chip [ngClass]="{ 'mat-standard-chip': true, 'score-frequency-selected': score.isSelected }" (click)="toggleScoreSelection(score)">{{score.frequency}}</mat-chip>                        
                    </mat-chip-list>
                </span>
            </div>
        </div>
        <div class="row" *ngIf="isShowLessScoresLink">
            <span class="col-9">&nbsp;</span>
            <span class="toggle-scores"><a class="toggle-scores" (click)="showLessScores()">less</a></span>
        </div>
    </div>    
</div>   